import * as React from 'react';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '../../theming/jssTypes';
import { WarningTwoTone } from '@ant-design/icons';
import { Modal, Table, Button, Typography } from 'antd';
import { humanize } from '../../utils/utils';
import {} from 'antd';

const styles = (theme: ThemeType): CssType => ({
  confirmationModal: {
    '& .ant-modal-body': {
      padding: 0,
    },
  },
  buttonRow: {
    padding: '5px',
    display: 'flex',
  },
  buttonStyle: {
    width: '120px',
    margin: '5px',
  },
  textStyle: {
    alignSelf: 'center',
    marginRight: 'auto',
    marginLeft: '10px',
  },
  warningIconStyle: {
    fontSize: '18px',
  },
});

const ConfirmationModal = (props: any) => {
  const {
    classes,
    dataArray,
    isVisible,
    dataColumns,
    width,
    title,
    showFooterSection,
    footerText,
    primaryButtonText,
    onClickPrimaryButton,
    secondaryButtonText,
    onClickSecondaryButton,
    onModalClose,
  } = props;

  const defaultColumns = [
    {
      title: 'Consignment Number',
      dataIndex: 'reference_number',
      width: '200px',
    },
    {
      title: 'Message',
      dataIndex: 'message',
    },
  ];

  const generateColumnForTable = () => {
    const columnData: object[] = [];
    if (dataArray == null || dataArray.length === 0) {
      return columnData;
    }
    const indexList = dataColumns ? dataColumns : defaultColumns;
    indexList.forEach((item) => {
      const itemToPush = {
        title: item.title,
        dataIndex: item.dataIndex,
        key: item.key,
        width: item.width,
        render: (text, record) => {
          return record[item.dataIndex]
            ? humanize(record[item.dataIndex])
            : null;
        },
      };

      columnData.push(itemToPush);
    });

    return columnData;
  };

  const handleModalClose = () => {
    onModalClose();
  };

  return (
    <Modal
      title={
        <div>
          <WarningTwoTone
            twoToneColor="#eb2f96"
            className={classes.warningIconStyle}
          />{' '}
          {title || 'Confirmation'}
        </div>
      }
      className={classes.confirmationModal}
      visible={isVisible}
      onCancel={handleModalClose}
      destroyOnClose
      maskClosable={false}
      width={width || 600}
      okText="Ok"
      footer={null}
    >
      <div>
        <Table
          pagination={false}
          scroll={{ y: '400px' }}
          columns={generateColumnForTable()}
          dataSource={dataArray}
          rowKey={(record: any) => record['row_num']}
        ></Table>
      </div>
      {showFooterSection ? (
        <div className={classes.buttonRow}>
          <Typography.Text className={classes.textStyle}>
            {' '}
            {footerText}{' '}
          </Typography.Text>
          <Button
            type="primary"
            className={classes.buttonStyle}
            onClick={onClickPrimaryButton}
          >
            {' '}
            {primaryButtonText}{' '}
          </Button>
          <Button
            type="default"
            className={classes.buttonStyle}
            onClick={onClickSecondaryButton}
          >
            {' '}
            {secondaryButtonText}{' '}
          </Button>
        </div>
      ) : null}
    </Modal>
  );
};

export default withStyles(styles, { injectTheme: true })(ConfirmationModal);
