import Icon from '@ant-design/icons';
import * as React from 'react';

const actionButtonSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M2.6665 8.99967V2.66634C2.6665 2.31272 2.80698 1.97358 3.05703 1.72353C3.30708 1.47348 3.64622 1.33301 3.99984 1.33301H9.6665L13.3332 4.99967V13.333C13.3332 13.6866 13.1927 14.0258 12.9426 14.2758C12.6926 14.5259 12.3535 14.6663 11.9998 14.6663H8.33317" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.33317 1.33301V5.33301H13.3332M6.9465 8.40634C7.07651 8.27633 7.23086 8.1732 7.40072 8.10284C7.57058 8.03248 7.75264 7.99627 7.9365 7.99627C8.12036 7.99627 8.30242 8.03248 8.47229 8.10284C8.64215 8.1732 8.7965 8.27633 8.9265 8.40634C9.05651 8.53635 9.15964 8.69069 9.23 8.86056C9.30036 9.03042 9.33657 9.21248 9.33657 9.39634C9.33657 9.5802 9.30036 9.76226 9.23 9.93213C9.15964 10.102 9.05651 10.2563 8.9265 10.3863L5.29984 13.9997L2.6665 14.6663L3.3265 12.033L6.9465 8.40634Z" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};

export const ActionButtonIcon = props => (
  <Icon component={actionButtonSvg} {...props} />
);
