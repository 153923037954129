export interface gaType {
  actionType: HUB_SEARCH | MULTI_FILTER | TABLE_ACTION | INPUT_FILTER | CONSIGNMENT_TAB | DATE_SEARCH | CUSTOMISE_COLUMNS | ROW_ACTIONS | BUTTON_CLICK
}

export const HUB_SEARCH = "hubSearch";
export const MULTI_FILTER = "multiFilter";
export const TABLE_ACTION = "tableAction";
export const INPUT_FILTER = "inputFilter";
export const CONSIGNMENT_TAB = "consignmentTab";
export const DATE_SEARCH = "dateSearch";
export const CUSTOMISE_COLUMNS = "customiseColumns";
export const ROW_ACTIONS = "rowActions";
export const BUTTON_CLICK = "buttonClick";