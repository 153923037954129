import {
  FETCH_VIEW_DATA,
  SET_PAGE_FOR_EMPLOYEE,
  SET_VIEW_DATA,
  FETCH_VIEW_DATA_OBJECTS,
  SET_VIEW_DATA_OBJECTS,
  VIEW_TAB_CHANGE,
  GLOBAL_FILTER_CHANGE,
  VIEW_SET_SELECTED_ROW_KEYS,
} from './constants';
import { fetchObjectPagesForEmployee } from 'src/api/objectPage';
import { fetchObjectViewById, fetchObjectViewDataObjects } from 'src/api/objectView';
import { PageData, PageDataResponseType } from 'src/types/page.types';
import { ViewData } from 'src/types/view.types';

export const fetchPageForEmployee = () => async (dispatch) => {
  const response: any = await fetchObjectPagesForEmployee();

  if (response.isSuccess) {
    const pages: PageDataResponseType[] = response.data;
    dispatch({
      data: pages,
      type: SET_PAGE_FOR_EMPLOYEE,
    });
  }
};

export const fetchViewData = (data: { viewIndex, pageId, sectionIndex, viewId }) => async (dispatch, getState) => {
  dispatch({
    data,
    type: FETCH_VIEW_DATA,
  });

  // fetch data
  const response = await fetchObjectViewById(data.viewId);

  const dataToSet = response.isSuccess ? {
    viewData: response.data,
    viewIndex: data.viewIndex,
    pageId: data.pageId,
    sectionIndex: data.sectionIndex,
  } : {
    error: response.errorMessage,
    viewIndex: data.viewIndex,
    pageId: data.pageId,
    sectionIndex: data.sectionIndex,
  };

  dispatch({
    data: dataToSet,
    type: SET_VIEW_DATA,
  });
};

export const fetchViewDataObjects = (
  data: {
    viewData: ViewData,
    viewIndex: number,
    pageId: string,
    sectionIndex: number,
    tabChange?: boolean,
    globalFilters?: any,
  },
) => async (dispatch, getState) => {
  dispatch({
    data,
    type: FETCH_VIEW_DATA_OBJECTS,
  });

  const pageData: PageData = getState().genericPageReducer[data.pageId];
  const currHub = getState().cachedData.currHub;
  const globalFiltersData = {
    hubIdList: data.globalFilters?.hubIdList || [ currHub.id ],
    timeFilters: data.globalFilters?.timeFilters || [
      {
        ...(pageData.appliedGlobalTimeFilter || {}),
      },
    ]
  };

  // fetch data
  const response = await fetchObjectViewDataObjects(data.viewData.getFetchViewDataObjectsRequest(globalFiltersData));

  const dataToSet = response.isSuccess ? {
    data: response.data.page_data,
    isNextPresent: response.data.isNextPresent,
    viewIndex: data.viewIndex,
    pageId: data.pageId,
    sectionIndex: data.sectionIndex,
    tabChange: data.tabChange,
  } : {
    error: response.errorMessage,
    viewIndex: data.viewIndex,
    pageId: data.pageId,
    sectionIndex: data.sectionIndex,
    tabChange: data.tabChange,
  };

  dispatch({
    data: dataToSet,
    type: SET_VIEW_DATA_OBJECTS,
  });
};

export const setSelectedRowKeys = (data: {
  viewId: string,
  pageId: string,
  sectionIndex: number,
  viewIndex: number,
  keys: string[],
}) => (dispatch, getState) => {
  dispatch({
    data: {
      selectedRowKeys: data.keys,
      viewIndex: data.viewIndex,
      pageId: data.pageId,
      sectionIndex: data.sectionIndex,
    },
    type: VIEW_SET_SELECTED_ROW_KEYS,
  });
}

export const handleViewChange = (data: {
  viewId: string,
  pageId: string,
  sectionIndex: number,
}) => async (dispatch, getState) => {
  const { viewId, pageId, sectionIndex } = data;
  const pageData: PageData = getState().genericPageReducer[pageId];
  const viewIndex = pageData.metadata.sectionList[sectionIndex].findIndex((v) => v.id === viewId);
  const viewData = pageData.metadata.sectionList[sectionIndex][viewIndex];

  dispatch({
    type: VIEW_TAB_CHANGE,
    data: {
      pageId,
      sectionIndex,
      viewId,
    },
  });

  if (!viewData.metadata) {
    fetchViewData({
      viewIndex,
      sectionIndex,
      pageId,
      viewId,
    })(dispatch, getState);
  }

  if (viewData.shouldFetchDataOnTabChange) {
    fetchViewDataObjects({
        viewIndex,
        sectionIndex,
        pageId,
        viewData,
        tabChange: true,
    })(dispatch, getState);
  }
};

export const handleGlobalFilterChanges  = (data: {
  pageId: string,
  hub?: any,
  timeFilter?: {
    columnName: string,
    fromDateString: string,
    toDateString: string,
  },
}) => async (dispatch, getState) => {
  const { pageId, timeFilter, hub } = data;
  const pageData: PageData = getState().genericPageReducer[pageId];

  dispatch({
    type: GLOBAL_FILTER_CHANGE,
    data: {
      pageId,
      timeFilter,
    },
  });

  pageData.metadata.sectionList.forEach((sectionData, sectionIndex) => {
    const activeView = pageData.activeViews[sectionIndex];
    const viewIndex = sectionData.findIndex(v => v.id === activeView);
    const globalFilters: any = {};

    if (hub) {
      globalFilters.hubIdList = [hub.id];
    }

    if (timeFilter) {
      globalFilters.timeFilters = [{
        ...timeFilter,
      }];
    }

    fetchViewDataObjects({
      viewIndex,
      sectionIndex,
      pageId,
      viewData: sectionData[viewIndex],
      globalFilters,
    })(dispatch, getState);
  });
};
