import { USE_HARDCODED_MASTER_DATA, HARDCODED_MASTER_DATA,
  applicationType } from '../globals';
import { getMasterData } from '../api/bootstrap';
import { matchPath } from 'react-router-dom';
import {
  SET_MASTER_DATA,
  INITIALIZE_COMPLETE,
  DASHBOARD_SET_COLUMNS,
  SET_CURRENT_HUB,
  SET_CURRENT_HUB_ANALYTICS,
  SET_VIEW_TYPE,
  SET_SEARCHED_HUBS_CRM,
  SET_DISPATCH_SCREEN_FOR_HUB,
} from './constants';
import { getTmsDashboardBaseRoute } from '../routing/utils';
import { disableMasterDataApi } from '../utils/utils';
import { showDispatchV2ForHub } from '@api/FoodDashboardApi';
import { fetchPageForEmployee } from './genericPageActions';
import { getDashboardGroupId } from 'src/api/landingPageDashboard';
import { LocalStorageKeys, LocalStorageService } from 'src/services/localStorage';

const getViewType = async (masterData, pathname) => {
  const isViewTypeTms = matchPath(pathname, getTmsDashboardBaseRoute());
  const { show_retail_options, show_ltl_options, show_ondemand_options,
    show_logistics_express_options } = masterData;
  let viewType;
  if (applicationType === 'ops') {
    const redirectToViewType = await LocalStorageService.get(LocalStorageKeys.__REDIRECT_TO_VIEW_TYPE__);
    if (redirectToViewType === 'ondemand' && show_ondemand_options) {
      // default viewType in case more than one are possible
      viewType = 'ondemand';
    } else if (show_logistics_express_options) {
      viewType = 'logistics_express';
    } else if (isViewTypeTms) {
      viewType = 'tms';
    } else if (show_retail_options) {
      viewType = 'retail';
    } else if (show_ltl_options) {
      viewType = 'domestic';
    } else if (show_ondemand_options) {
      viewType = 'ondemand';
    } else {
      viewType = 'logistics';
    }
  } else {
    viewType = 'crm';
  }
  return viewType;
};

const fetchAllEmployeePages = () => async (dispatch, getState) => {
  if(!disableMasterDataApi()) {
    await dispatch(fetchPageForEmployee());
  }
};

export const loadInitialData = (pathname:string) => async (dispatch, getState) => {
  let masterData: any = null;
  const disableMasterData = disableMasterDataApi();
  dispatch(fetchAllEmployeePages());
  if (USE_HARDCODED_MASTER_DATA) {
    masterData = HARDCODED_MASTER_DATA;
  } else {
    let masterDataResponse: any = null;
    if (disableMasterData) {
      masterDataResponse =  {
        masterData:{
          analyticTopBar: disableMasterData,
        }, isSuccess: true};
    } else {
      masterDataResponse = await getMasterData();
    }
  // rest api call
    if (masterDataResponse.isSuccess) {
      masterData = masterDataResponse.masterData;
      const viewType = await getViewType(masterDataResponse.masterData, pathname);
      masterData.viewType = viewType;
    }
  }
  if (masterData) {
    if (masterData.ops_login_redirect_to === 'landingPageNew') {
      try {
        const response = await getDashboardGroupId();
        const { success, landingPageDashboardGroupId } = response.data;
        if (success && landingPageDashboardGroupId) {
          masterData.showDashboardGroupLandingPage = true;
          masterData.landingDashboardGroup = {
            dashboardGroupId: landingPageDashboardGroupId,
          };
        } else {
          throw new Error('dashboardGroupId not found');
        }
      } catch (e) {
        masterData.showDashboardGroupLandingPage = false;
      }
    }

    dispatch({
      type: SET_MASTER_DATA,
      data: masterData,
    });

  // Load intial columns to show from masterdata
    dispatch({
      type: DASHBOARD_SET_COLUMNS,
      data: masterData.crm_dashboard_column_list,
    });

    await dispatch(setCurrentHub());
    dispatch({
      type: INITIALIZE_COMPLETE,
    });
  }
};

export const setCurrentHub = () => async (dispatch, getState) => {
  const listOfHubs = getState().masterData.ops_hub.hub_list;
  let currentHubFromCache = null;
  let currentHubFromApi = null;
  const cachedHubJson = await LocalStorageService.get(LocalStorageKeys.__CURRENT_HUB__);
  const redirectToHubFromLocal = await LocalStorageService.get(LocalStorageKeys.__REDIRECT_TO_HUB__);
  const redirectToHub = (redirectToHubFromLocal && redirectToHubFromLocal !== 'undefined')
    ? JSON.parse(redirectToHubFromLocal)
    : undefined;

  if (cachedHubJson) {
    currentHubFromCache = JSON.parse(cachedHubJson);
  }

  if (listOfHubs && listOfHubs.length > 0) {
    currentHubFromApi = listOfHubs[0];
  }

  const currentHub = currentHubFromCache ?
    currentHubFromCache : (redirectToHub || currentHubFromApi);

  // Cache current hub
  await LocalStorageService.set(LocalStorageKeys.__CURRENT_HUB__, JSON.stringify(currentHub));

  dispatch({
    type: SET_CURRENT_HUB,
    data: {
      currHub: currentHub,
    },
  });

};

export const setViewType = (viewType) => async (dispatch, getState) => {
  dispatch({
    type: SET_VIEW_TYPE,
    data: viewType,
  });
};

export const setCurrentHubAnalytics = data => async (dispatch, getState) => {
  dispatch({
    data,
    type: SET_CURRENT_HUB_ANALYTICS,
  });
};

export const setSearchedHubsCRM = data => async (dispatch) => {
  dispatch({
    data,
    type: SET_SEARCHED_HUBS_CRM,
  });
};

export const setDispatchScreenForHub = (hubId) => async (dispatch, getState) => {
  const { masterData } = getState();
  const response = await showDispatchV2ForHub({
    hubId,
  });
  dispatch({
    type: SET_DISPATCH_SCREEN_FOR_HUB,
    data: response.data?.show_enhanced_dispatch_screen,
  });
};
