import Icon from '@ant-design/icons';
import * as React from 'react';

const PasswordPolicySvg = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Setup_Tooltip" transform="translate(-16.000000, -794.000000)">
                <g id="Group-6" transform="translate(0.000000, 58.000000)">
                    <g id="Password_Policy" transform="translate(16.000000, 736.000000)">
                        <rect id="Rectangle" fillRule="nonzero" x="0" y="0" width="24" height="24"></rect>
                        <path d="M12.086,3.6234 C13.2299,2.79768 14.6304,2.40618 16.0367,2.51904 C17.443,2.6319 18.7631,3.24173 19.7607,4.23931 C20.7583,5.23689 21.3681,6.55705 21.481,7.96332 C21.5938,9.36959 21.2023,10.7701 20.3766,11.914 C19.5509,13.0579 18.3449,13.8705 16.9746,14.2062 C15.6043,14.5419 14.1593,14.3787 12.8984,13.746 C12.5024,13.5473 12.3425,13.0653 12.5412,12.6693 C12.7399,12.2734 13.2219,12.1134 13.6179,12.3121 C14.5382,12.7739 15.5928,12.893 16.5929,12.648 C17.593,12.403 18.4732,11.8099 19.0758,10.9751 C19.6785,10.1402 19.9642,9.11802 19.8819,8.09166 C19.7995,7.0653 19.3544,6.10178 18.6263,5.3737 C17.8982,4.64562 16.9347,4.20054 15.9084,4.11817 C14.882,4.0358 13.8598,4.32153 13.025,4.92418 C12.1901,5.52683 11.597,6.40703 11.352,7.40712 C11.107,8.40721 11.2261,9.46185 11.6879,10.3822 C11.8866,10.7781 11.7267,11.2602 11.3307,11.4588 C10.9348,11.6575 10.4527,11.4976 10.254,11.1016 C9.6213,9.8407 9.45816,8.39567 9.79385,7.0254 C10.1295,5.65512 10.9421,4.44912 12.086,3.6234 Z" id="Path" fill="#666666"></path>
                        <path d="M3.30213,17.5768 C3.74514,17.5768 4.10427,17.936 4.10427,18.379 L4.10427,19.8957 L6.46776,19.8957 C6.91076,19.8957 7.26989,20.2548 7.26989,20.6978 C7.26989,21.1408 6.91076,21.4999 6.46776,21.4999 L3.30213,21.4999 C2.85913,21.4999 2.5,21.1408 2.5,20.6978 L2.5,18.379 C2.5,17.936 2.85913,17.5768 3.30213,17.5768 Z" id="Path" fill="#666666"></path>
                        <path d="M11.3956,10.2855 C11.7088,10.5988 11.7088,11.1066 11.3956,11.4199 L3.86933,18.9462 C3.55608,19.2594 3.04819,19.2594 2.73494,18.9462 C2.42169,18.6329 2.42169,18.125 2.73494,17.8118 L10.2612,10.2855 C10.5745,9.97225 11.0823,9.97225 11.3956,10.2855 Z" id="Path" fill="#666666"></path>
                        <path d="M13.8133,12.4502 C14.133,12.7568 14.1436,13.2646 13.8369,13.5843 L11.7951,15.7132 C11.4885,16.0329 10.9807,16.0435 10.661,15.7369 C10.3412,15.4302 10.3306,14.9224 10.6373,14.6027 L12.6791,12.4738 C12.9858,12.1541 13.4935,12.1435 13.8133,12.4502 Z" id="Path" fill="#666666"></path>
                        <path d="M16.5158,8.01507 C16.5403,8.00493 16.5639,7.99238 16.5861,7.97751 C16.6307,7.94772 16.6687,7.90955 16.6982,7.86538 C16.713,7.84335 16.7256,7.81983 16.7358,7.79511 C16.7563,7.74557 16.7667,7.6927 16.7667,7.63958 C16.7667,7.61308 16.7642,7.58653 16.7589,7.56029 C16.7433,7.48145 16.7045,7.40903 16.6477,7.35219 C16.5909,7.29535 16.5184,7.25664 16.4396,7.24096 C16.4134,7.23574 16.3868,7.23315 16.3603,7.23315 C16.3072,7.23315 16.2543,7.24356 16.2048,7.26409 C16.1801,7.27432 16.1565,7.28692 16.1345,7.30164 C16.0903,7.33115 16.0522,7.36918 16.0224,7.41378 C16.0075,7.43602 15.995,7.45957 15.9848,7.48404 C15.9645,7.53312 15.9539,7.58595 15.9539,7.63958 C15.9539,7.6664 15.9565,7.69295 15.9617,7.71887 C15.9773,7.79713 16.0157,7.86971 16.0729,7.92697 C16.1302,7.98423 16.2028,8.02263 16.281,8.0382 C16.3069,8.04336 16.3335,8.04601 16.3603,8.04601 C16.4139,8.04601 16.4668,8.0354 16.5158,8.01507 Z M15.6948,6.64361 C15.8918,6.51199 16.1234,6.44174 16.3603,6.44174 C16.678,6.44174 16.9827,6.56794 17.2073,6.79258 C17.432,7.01722 17.5582,7.32189 17.5582,7.63958 C17.5582,7.87649 17.4879,8.10808 17.3563,8.30506 C17.2247,8.50205 17.0376,8.65558 16.8187,8.74624 C16.5998,8.8369 16.359,8.86062 16.1266,8.8144 C15.8943,8.76818 15.6808,8.6541 15.5133,8.48658 C15.3458,8.31906 15.2317,8.10562 15.1855,7.87327 C15.1393,7.6409 15.163,7.40006 15.2537,7.18119 C15.3443,6.96231 15.4978,6.77523 15.6948,6.64361 Z" id="Shape" fill="#666666"></path>
                        <path d="M6.46766,17.5214 C6.91067,17.5214 7.2698,17.8806 7.2698,18.3236 L7.2698,20.6978 C7.2698,21.1408 6.91067,21.4999 6.46766,21.4999 C6.02466,21.4999 5.66553,21.1408 5.66553,20.6978 L5.66553,18.3236 C5.66553,17.8806 6.02466,17.5214 6.46766,17.5214 Z" id="Path" fill="#666666"></path>
                        <path d="M5.66553,18.3236 C5.66553,17.8806 6.02466,17.5214 6.46766,17.5214 L8.84188,17.5214 C9.28488,17.5214 9.64401,17.8806 9.64401,18.3236 C9.64401,18.7666 9.28488,19.1257 8.84188,19.1257 L6.46766,19.1257 C6.02466,19.1257 5.66553,18.7666 5.66553,18.3236 Z" id="Path" fill="#666666"></path>
                        <path d="M8.03979,15.9493 C8.03979,15.5063 8.39892,15.1472 8.84193,15.1472 L11.2161,15.1472 C11.6592,15.1472 12.0183,15.5063 12.0183,15.9493 C12.0183,16.3923 11.6592,16.7515 11.2161,16.7515 L8.84193,16.7515 C8.39892,16.7515 8.03979,16.3923 8.03979,15.9493 Z" id="Path" fill="#666666"></path>
                        <path d="M8.84193,15.1472 C9.28494,15.1472 9.64406,15.5063 9.64406,15.9493 L9.64406,18.3235 C9.64406,18.7665 9.28494,19.1257 8.84193,19.1257 C8.39892,19.1257 8.03979,18.7665 8.03979,18.3235 L8.03979,15.9493 C8.03979,15.5063 8.39892,15.1472 8.84193,15.1472 Z" id="Path" fill="#666666"></path>
                        <path d="M11.2162,14.3558 C11.6592,14.3558 12.0183,14.7149 12.0183,15.1579 L12.0183,15.9493 C12.0183,16.3924 11.6592,16.7515 11.2162,16.7515 C10.7732,16.7515 10.4141,16.3924 10.4141,15.9493 L10.4141,15.1579 C10.4141,14.7149 10.7732,14.3558 11.2162,14.3558 Z" id="Path" fill="#666666"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    );
};

export const PasswordPolicyIcon = props => (
  <Icon component={PasswordPolicySvg} {...props} />
);
