import * as React from 'react';
import withStyles, { WithStylesProps } from 'react-jss';
import { connect } from 'react-redux';
import styles from './TripMapUpdateTripDataModal.style';
import { useTripManagerMapEditContext } from '../../StateManagement/TripManagerMapEdit.context';
import {
  fetchTripUpdateData,
  updateTripDataOffline,
} from '../../StateManagement/TripManagerMapEdit.actions';
import { Input, Modal, Radio, Space, message } from 'antd';
import {
  ACTION_VIEW_CONFIG,
  TRIP_OFFLINE_ACTIONS,
} from '../../TripManagerMapEditUtils';
import Loader from 'src/components/common/Loader';

interface IProps extends WithStylesProps<typeof styles> {
  tripData: any;
  visible: boolean;
  onClose: () => void;
  currHub: any;
  actionType: TRIP_OFFLINE_ACTIONS;
}

const TripMapUpdateTripDataModal: React.FC<IProps> = function (props) {
  const { classes, tripData, visible, onClose, currHub, actionType } = props;
  const actionViewConfig = ACTION_VIEW_CONFIG[actionType];
  const { tripManagerMapEditState, tripManagerMapEditDispatch } =
    useTripManagerMapEditContext();
  const { tripUpdateData, allTrips, editActions, allUnplannedCNs } =
    tripManagerMapEditState;
  const { loading, list, error } = tripUpdateData;
  const [dataList, setDataList] = React.useState<any>([]);
  React.useState(false);
  const [selectedID, setSelectedID] = React.useState(null);

  React.useEffect(() => {
    setDataList([...list]);
  }, [list]);

  React.useEffect(() => {
    fetchTripUpdateData(
      tripManagerMapEditDispatch,
      actionViewConfig.getRequestPayload(currHub?.id, tripData),
      actionViewConfig.fetchData,
      actionViewConfig.responseDataMapper,
    );
  }, []);

  const handleModalClose = () => {
    onClose();
  };

  const handleSave = async () => {
    updateTripDataOffline(tripManagerMapEditDispatch, {
      actionType,
      tripId: tripData.id,
      allTrips,
      newData: dataList.find((l) => l.id === selectedID),
      editActions,
      allUnplannedCNs,
    });
    message.success('Updated');
    onClose();
  };

  const handleSearch = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const query = e.target.value;

    const filteredData = query
      ? list?.filter((item) =>
          item.name.toLowerCase().includes(query.toLowerCase()),
        )
      : [...(list ?? [])];
    setDataList(filteredData);
    setSelectedID(null);
  };

  const handleSelectData = (e) => {
    setSelectedID(e.target.value);
  };

  return actionType ? (
    <Modal
      width={400}
      bodyStyle={{ padding: 0 }}
      title={actionViewConfig.title}
      visible={visible}
      onCancel={handleModalClose}
      destroyOnClose
      closable={false}
      maskClosable={false}
      okText="Save"
      onOk={handleSave}
      cancelButtonProps={{
        disabled: loading,
      }}
      confirmLoading={loading}
    >
      {loading ? (
        <div className={classes.loadingContainer}>
          <Loader zIndex={10} />
        </div>
      ) : (
        <div className={classes.container}>
          <Input
            className={classes.search}
            placeholder={actionViewConfig.searchPlaceholder}
            onChange={handleSearch}
          />
          <div className={classes.options}>
            <Radio.Group onChange={handleSelectData} value={selectedID}>
              <Space direction="vertical">
                {dataList.map((data) => {
                  return (
                    <Radio key={data.id} value={data.id}>
                      {data.name}
                    </Radio>
                  );
                })}
              </Space>
            </Radio.Group>
          </div>
        </div>
      )}
    </Modal>
  ) : (
    <></>
  );
};

const TripMapUpdateTripDataModalStyled = withStyles(styles, {
  injectTheme: true,
})(TripMapUpdateTripDataModal);

const mapStateToProps = ({ cachedData }, ownProps) => {
  return {
    currHub: cachedData.currHub,
  };
};

export default connect(mapStateToProps)(TripMapUpdateTripDataModalStyled);
