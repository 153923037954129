import Icon from '@ant-design/icons';
import * as React from 'react';

const ADMVehiclesSvg = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Setup_Tooltip" transform="translate(-16.000000, -650.000000)">
                <g id="Group-6" transform="translate(0.000000, 58.000000)">
                    <g id="ADM-Vehicles" transform="translate(16.000000, 592.000000)">
                        <rect id="Rectangle" fillRule="nonzero" x="0" y="0" width="24" height="24"></rect>
                        <g id="Group" transform="translate(0.200195, 3.299990)" fill="#666666">
                            <path d="M9.539745,1.94119 C9.539745,1.48279 9.911305,1.11119 10.369705,1.11119 L13.081305,1.11119 C13.283605,1.11402 13.483305,1.15674 13.669105,1.23676 C13.854805,1.31677 14.023005,1.4326 14.164005,1.57762 C14.305005,1.72264 14.416005,1.89401 14.490805,2.08195 C14.564705,2.26772 14.601705,2.46608 14.599705,2.66595 L14.599705,13.92641 C14.601705,14.12631 14.564705,14.32461 14.490805,14.51041 C14.416005,14.69841 14.305005,14.86971 14.164005,15.01471 C14.023005,15.15981 13.854805,15.27561 13.669105,15.35561 C13.483305,15.43561 13.283605,15.47831 13.081305,15.48111 L13.069705,15.48131 L9.069745,15.48131 C8.611355,15.48131 8.239745,15.10961 8.239745,14.65121 C8.239745,14.19281 8.611355,13.82121 9.069745,13.82121 L12.939705,13.82121 L12.939705,2.77119 L10.369705,2.77119 C9.911305,2.77119 9.539745,2.39958 9.539745,1.94119 Z" id="Path"></path>
                            <path d="M2.039545,9.91121 C2.497935,9.91121 2.869545,10.28281 2.869545,10.74121 L2.869545,13.82121 L4.869545,13.82121 C5.327935,13.82121 5.699545,14.19281 5.699545,14.65121 C5.699545,15.10961 5.327935,15.48121 4.869545,15.48121 L2.749625,15.48121 C2.749595,15.48121 2.749575,15.48121 2.749545,15.48121 C2.545495,15.48121 2.343485,15.44071 2.155235,15.36191 C1.966975,15.28321 1.796225,15.16781 1.652945,15.02251 C1.509655,14.87721 1.396675,14.70491 1.320565,14.51551 C1.245325,14.32831 1.207615,14.12821 1.209545,13.92651 L1.209545,10.74121 C1.209545,10.28281 1.581145,9.91121 2.039545,9.91121 Z" id="Path"></path>
                            <path d="M13.770005,8.95121 C14.228405,8.95121 14.600005,9.32281 14.600005,9.78121 L14.600005,13.82121 L15.980005,13.82121 C16.438405,13.82121 16.810005,14.19281 16.810005,14.65121 C16.810005,15.10961 16.438405,15.48121 15.980005,15.48121 L14.510105,15.48121 C14.510105,15.48121 14.510005,15.48121 14.510005,15.48121 C14.097305,15.48121 13.701205,15.31871 13.407405,15.02891 C13.113505,14.73901 12.945705,14.34511 12.940105,13.93241 L12.939905,13.92121 L12.940005,13.92121 L12.940005,9.78121 C12.940005,9.32281 13.311605,8.95121 13.770005,8.95121 Z" id="Path"></path>
                            <path d="M22.589605,8.95121 C23.048005,8.95121 23.419605,9.32281 23.419605,9.78121 L23.419605,13.92121 C23.419605,14.33491 23.255205,14.73171 22.962705,15.02431 C22.670105,15.31681 22.273305,15.48121 21.859605,15.48121 L20.389605,15.48121 C19.931205,15.48121 19.559605,15.10961 19.559605,14.65121 C19.559605,14.19281 19.931205,13.82121 20.389605,13.82121 L21.759605,13.82121 L21.759605,9.78121 C21.759605,9.32281 22.131205,8.95121 22.589605,8.95121 Z" id="Path"></path>
                            <path d="M12.939905,3.90121 C12.939905,3.44281 13.311505,3.07121 13.769905,3.07121 L20.389905,3.07121 C20.736105,3.07121 21.046005,3.2861 21.167305,3.61036 L23.367305,9.49031 C23.527905,9.91971 23.310105,10.39791 22.880805,10.55861 C22.451505,10.71921 21.973205,10.50141 21.812605,10.07211 L19.814305,4.73121 L13.769905,4.73121 C13.311505,4.73121 12.939905,4.35961 12.939905,3.90121 Z" id="Path"></path>
                            <path d="M8.89941564,14.65121 C8.89941564,14.25561 8.782115,13.86901 8.562355,13.54011 C8.342595,13.21121 8.030235,12.95481 7.664785,12.80341 C7.299335,12.65211 6.897195,12.61251 6.509235,12.68961 C6.121275,12.76681 5.764905,12.95731 5.485205,13.23701 C5.205495,13.51671 5.015015,13.87311 4.937845,14.26101 C4.860675,14.64901 4.900285,15.05111 5.051655,15.41661 C5.203035,15.78201 5.459375,16.09441 5.788275,16.31411 C6.117175,16.53391 6.503855,16.65121 6.89941564,16.65121 C7.429845,16.65121 7.938555,16.44051 8.313625,16.06541 C8.688705,15.69031 8.89941564,15.18161 8.89941564,14.65121 Z" id="Path" fillRule="nonzero"></path>
                            <path d="M6.392575,12.10121 C6.896925,12.00081 7.419695,12.05231 7.894785,12.24911 C8.369875,12.44591 8.775935,12.77911 9.061625,13.20671 C9.347315,13.63431 9.499805,14.13701 9.499805,14.65121 C9.499805,15.34081 9.225875,16.00211 8.738285,16.48971 C8.250685,16.97731 7.589365,17.25121 6.899805,17.25121 C6.385575,17.25121 5.882895,17.09871 5.455325,16.81301 C5.027755,16.52731 4.694505,16.12131 4.497715,15.64621 C4.300935,15.17111 4.249445,14.64831 4.349765,14.14401 C4.450085,13.63961 4.697715,13.17631 5.061325,12.81271 C5.424945,12.44911 5.888225,12.20151 6.392575,12.10121 Z M7.435565,13.35781 C7.179745,13.25181 6.898255,13.22411 6.626675,13.27811 C6.355105,13.33211 6.105655,13.46551 5.909855,13.66121 C5.714065,13.85701 5.580725,14.10651 5.526705,14.37811 C5.472685,14.64961 5.500415,14.93111 5.606375,15.18701 C5.712335,15.44281 5.891775,15.66141 6.122005,15.81531 C6.352235,15.96911 6.622915,16.05121 6.899805,16.05121 C7.271105,16.05121 7.627205,15.90371 7.889755,15.64111 C8.152305,15.37861 8.299805,15.02251 8.299805,14.65121 C8.299805,14.37431 8.217695,14.10361 8.063865,13.87341 C7.910025,13.64321 7.691375,13.46371 7.435565,13.35781 Z" id="Shape"></path>
                            <path d="M20.629905,14.65121 C20.629905,14.25561 20.512605,13.86901 20.292805,13.54011 C20.073105,13.21121 19.760705,12.95481 19.395205,12.80341 C19.029805,12.65211 18.627705,12.61251 18.239705,12.68961 C17.851705,12.76681 17.495405,12.95731 17.215705,13.23701 C16.936005,13.51671 16.745505,13.87311 16.668305,14.26101 C16.591105,14.64901 16.630705,15.05111 16.782105,15.41661 C16.933505,15.78201 17.189805,16.09441 17.518705,16.31411 C17.847605,16.53391 18.234305,16.65121 18.629905,16.65121 C19.160305,16.65121 19.669005,16.44051 20.044105,16.06541 C20.419205,15.69031 20.629905,15.18161 20.629905,14.65121 Z" id="Path" fillRule="nonzero"></path>
                            <path d="M18.122505,12.10121 C18.626905,12.00081 19.149705,12.05231 19.624805,12.24911 C20.099805,12.44591 20.505905,12.77911 20.791605,13.20671 C21.077305,13.63431 21.229805,14.13701 21.229805,14.65121 C21.229805,15.34081 20.955905,16.00211 20.468305,16.48971 C19.980705,16.97731 19.319305,17.25121 18.629805,17.25121 C18.115605,17.25121 17.612905,17.09871 17.185305,16.81301 C16.757705,16.52731 16.424505,16.12131 16.227705,15.64621 C16.030905,15.17111 15.979405,14.64831 16.079705,14.14401 C16.180105,13.63961 16.427705,13.17631 16.791305,12.81271 C17.154905,12.44911 17.618205,12.20151 18.122505,12.10121 Z M19.165505,13.35781 C18.909705,13.25181 18.628205,13.22411 18.356705,13.27811 C18.085105,13.33211 17.835605,13.46551 17.639805,13.66121 C17.444005,13.85701 17.310705,14.10651 17.256705,14.37811 C17.202705,14.64961 17.230405,14.93111 17.336405,15.18701 C17.442305,15.44281 17.621805,15.66141 17.852005,15.81531 C18.082205,15.96911 18.352905,16.05121 18.629805,16.05121 C19.001105,16.05121 19.357205,15.90371 19.619705,15.64111 C19.882305,15.37861 20.029805,15.02251 20.029805,14.65121 C20.029805,14.37431 19.947705,14.10361 19.793805,13.87341 C19.640005,13.64321 19.421405,13.46371 19.165505,13.35781 Z" id="Shape"></path>
                            <path d="M17.689905,3.07121 C18.148305,3.07121 18.519905,3.44281 18.519905,3.90121 L18.519905,8.95121 L21.609905,8.95121 C22.068305,8.95121 22.439905,9.32281 22.439905,9.78121 C22.439905,10.23961 22.068305,10.61121 21.609905,10.61121 L17.689905,10.61121 C17.231505,10.61121 16.859905,10.23961 16.859905,9.78121 L16.859905,3.90121 C16.859905,3.44281 17.231505,3.07121 17.689905,3.07121 Z" id="Path"></path>
                            <polygon id="Path" fillRule="nonzero" points="5.428145 0 3.765625 0 3.765625 1.83277 5.428145 1.83277"></polygon>
                            <polygon id="Path" fillRule="nonzero" points="5.428145 7.35111 3.765625 7.35111 3.765625 9.18391 5.428145 9.18391"></polygon>
                            <polygon id="Path" fillRule="nonzero" points="9.183535 3.76565 7.480955 3.76565 7.480955 5.42817 9.183535 5.42817"></polygon>
                            <polygon id="Path" fillRule="nonzero" points="7.215375 0.78869 6.046875 1.95718 7.222455 3.13276 8.390945 1.96426"></polygon>
                            <polygon id="Path" fillRule="nonzero" points="1.938835 0.76395 0.763672 1.93994 1.932575 3.10803 3.107745 1.93204"></polygon>
                            <polygon id="Path" fillRule="nonzero" points="7.264035 6.08231 6.088865 7.25831 7.257775 8.42641 8.432935 7.25041"></polygon>
                            <polygon id="Path" fillRule="nonzero" points="1.897495 6.12038 0.729004 7.28891 1.904585 8.46441 3.073075 7.29591"></polygon>
                            <polygon id="Path" fillRule="nonzero" points="1.832775 3.76565 0 3.76565 0 5.42817 1.832775 5.42817"></polygon>
                            <path d="M4.596655,8.23251 C3.887525,8.23251 3.194325,8.02221 2.604705,7.62821 C2.015085,7.23421 1.555525,6.67428 1.284155,6.01912 C1.012785,5.36397 0.941785,4.64306 1.080125,3.94756 C1.218465,3.25205 1.559945,2.61319 2.061375,2.11176 C2.562815,1.61033 3.201675,1.26885 3.897175,1.13051 C4.592685,0.99216 5.313595,1.06317 5.968745,1.33454 C6.623895,1.60591 7.183865,2.06546 7.577835,2.65508 C7.971805,3.24471 8.182085,3.93791 8.182085,4.64704 C8.182085,5.59796 7.804335,6.50992 7.131935,7.18231 C6.459545,7.85471 5.547575,8.23251 4.596655,8.23251 L4.596655,8.23251 Z M4.596655,2.72413 C4.216345,2.72413 3.844565,2.83691 3.528345,3.0482 C3.212125,3.25949 2.965665,3.55981 2.820125,3.91117 C2.674585,4.26254 2.636505,4.64917 2.710695,5.02218 C2.784895,5.39519 2.968035,5.73782 3.236955,6.00674 C3.505885,6.27567 3.848505,6.45881 4.221515,6.533 C4.594525,6.6072 4.981155,6.56912 5.332525,6.42358 C5.683895,6.27804 5.984205,6.03157 6.195505,5.71535 C6.406795,5.39913 6.519565,5.02736 6.519565,4.64704 C6.526255,4.39034 6.481465,4.1349 6.387825,3.89579 C6.294185,3.65668 6.153605,3.43875 5.974375,3.25485 C5.795145,3.07095 5.580905,2.92482 5.344275,2.82507 C5.107655,2.72532 4.853445,2.67397 4.596655,2.67404991 L4.596655,2.72413 Z" id="Shape" fillRule="nonzero"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
  );
};

export const ADMVehiclesIcon = props => (
  <Icon component={ADMVehiclesSvg} {...props} />
);
