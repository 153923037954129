import * as React from 'react';
import withStyles from 'react-jss';
import { CssType,
  ThemeType} from '../../../../theming/jssTypes';
import { CloseOutlined, ScanOutlined } from '@ant-design/icons';
import { Modal, message, Input, Table, Button } from 'antd';
import SearchTagsInput from '../../../common/SearchSelectTagsInput';
import { outscanAtHub, searchHubData } from '../../../../api/genericConsignmentView';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  fetchConsignments,
  fetchSummaryMetrics,
} from '../../../../actions/genericConsignmentActions';
import FailureArrayHandler from '../../../common/FailureArrayHandler';
import { bagOutscanAtHub } from '../../../../api/TMSDashboard/Bagview';
const styles = (theme: ThemeType): CssType => ({
  scanCN: {
    margin: '10px 0',
  },
  tableContainer: {
    flex: '1 1 0',
    overflow: 'auto',
    border: '1px solid #D9D9D9',
    maxHeight: 300,
    '& .ant-table-tbody > tr > td, .ant-table-thead > tr > th' : {
      padding: 9,
      wordBreak: 'break-word',
    },
  },
});

class OutscanModal extends React.Component<any, any> {

  state: any = {
    confirmLoading: false,
    inputValue : '',
    cnList: new Set(),
    searchedHubs: [],
    originHub: undefined,
    destHub: undefined,
    isErrorModalVisible: false,
    failureArray: [],
  };

  minimumHubSearchLength = this.props.minSearchLength || 2;

  componentDidMount = () => {
    const { consignmentList } = this.props;
    const cnlist = new Set();
      consignmentList?.forEach((ele) => {
        cnlist.add(ele);
      });
      this.setState({
        cnList: cnlist,
      });
  };

  handleModalClose = () => {
    this.props.onModalClose(false);
  };
  getDestinationHubCode = () => {
    const { destHub } = this.state;
    const arr = destHub?.label?.split(',');
    return arr?.length > 1 ? arr[1] : undefined;
  };
  handleOutscanSubmit = async () => {
    const { destHub, cnList } = this.state;
    const { originHub, isBagOutscan, warning, success } = this.props;
    if (!destHub) {
      message.warning('Please select destination hub');
      return;
    }
    if (cnList.size === 0) {
      message.warning(warning || 'Please enter a valid consignemnt number');
      return;
    }
    const body = {
      consignments: Array.from(cnList),
      hub_id: originHub?.id,
      destination_hub_id: destHub.key,
    };
    const requestBodyBagOutscan = {
      bags: Array.from(cnList)?.map((cn) => {
        return({
          reference_number: cn,
          origin_hub_code: originHub?.code,
          destination_hub_code: this.getDestinationHubCode(),
        });
      }),
    };
    this.setState({ confirmLoading: true });
    const response = isBagOutscan ? await bagOutscanAtHub(requestBodyBagOutscan) :
      await outscanAtHub(body);
    if (response.isSuccess) {
      if (response?.data?.failures?.length) {
        this.setState({
          failureArray: response.data.failures,
          isErrorModalVisible: true,
        });
      } else {
        message.success(success || 'Selected consignments have been marked outscan at hub');
        this.props.fetchSummaryMetrics();
        this.props.onModalClose(true);
      }
    } else {
      message.error(response.errorMessage);
      this.props.onModalClose(false);
    }
    this.setState({
      confirmLoading: false,
    });
  };
  handleHubChange = (values, options) => {
    this.setState({ originHub: values, searchedHubs: [] });
  };
  handleDestHubChange = (values, options) => {
    this.setState({ destHub: values, searchedHubs: [] });
  };
  handleSearchedHubsearch = async (queryString: string) => {
    if (!queryString.trim()) {
      this.setState({ searchedhubs: [] });
      return;
    }
    if (queryString.length >= this.minimumHubSearchLength) {
      const response = await searchHubData(queryString);
      let searchedHubs = response.data;
      searchedHubs = searchedHubs ? searchedHubs.map((hub: any) => ({
        key: hub.id, label: `${hub.name}, ${hub.code}`,
      })) : [],
        this.setState({ searchedHubs });
    }
  };
  getReferenceNoTitle = () => {
    const { isBagOutscan } = this.props;
    return isBagOutscan ? 'Bag#' : 'CN No.';
  };
  columns = [{
    title: 'S.No.',
    key: 'index',
    render : (text, record, index) => index + 1,
  }, {
    title: this.getReferenceNoTitle(),
    key: 'reference_number',
    render : (text, record) => {
      return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {record}
            <CloseOutlined style={{ color: 'red' }} onClick={() => this.handleDeleteCNClick(record)} />
        </div>
      );
    },
  },
  ];
  handleAddCNClick = (e) => {
    const { inputValue, cnList } = this.state;
    const { warning } = this.props;
    if (inputValue === '') {
      message.warning(warning || 'Please enter a valid consignemnt number');
      return;
    }
    cnList.add(inputValue);
    this.setState({
      cnList,
      inputValue: '',
    });
  };

  handleDeleteCNClick = (cnNumber) => {
    const { cnList } = this.state;
    cnList.delete(cnNumber);
    this.setState({
      cnList,
    });
  };
  onInputChange = (e) => {
    this.setState({
      inputValue: e.target.value,
    });
  };
  handleFailureModalClose = () => {
    const { onModalClose } = this.props;
    this.setState({
      isErrorModalVisible: false,
    });
    onModalClose(false);
  };
  render() {
    const { isVisible, classes, placeholder } = this.props;
    const { cnList, confirmLoading, inputValue, isErrorModalVisible, failureArray } = this.state;
    return (
      <Modal
          width={360}
          confirmLoading={confirmLoading}
          title="Outscan at hub"
          visible={isVisible}
          onOk={this.handleOutscanSubmit}
          onCancel={this.handleModalClose}
          okText={'Outscan'}
      >
        <div style={{ marginTop: 10 }}>
          <SearchTagsInput
            labelInValue
            mode={'single'}
            size={'default'}
            placeholder="Select Destination Hub"
            value={this.state.destHub}
            thresholdLength={this.minimumHubSearchLength}
            searchText="Search for destination hubs"
            options={this.state.searchedHubs}
            onChange={this.handleDestHubChange}
            searchFunction={this.handleSearchedHubsearch}
            style={{ width: '100%' }}
            className={classes.noLeftBorder}
          />
        </div>
        <div className={classes.scanCN}>
          <Input
            addonAfter={<ScanOutlined />}
            placeholder={placeholder || 'Scan/Type CN No.'}
            onChange={this.onInputChange}
            onPressEnter={this.handleAddCNClick}
            value={inputValue}/>
        </div>
        <div className={classes.tableContainer}>
          <Table
              locale={{ emptyText: 'No data' }}
              pagination={false}
              rowKey={(record: any) => record['reference_number']}
              dataSource={Array.from(cnList) as any}
              columns={this.columns} />
        </div>
        {
          isErrorModalVisible &&
          <FailureArrayHandler
          failureArray={failureArray}
          isVisible={isErrorModalVisible}
          onModalClose={this.handleFailureModalClose}
          ></FailureArrayHandler>

        }
    </Modal>
    );
  }
}

const mapStateToProps = ({ cachedData }) => {
  return {
    originHub: cachedData.currHub,
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    fetchConsignments,
    fetchSummaryMetrics,
  }, dispatch);
};

const OutscanModalStyled = withStyles(styles, { injectTheme: true })(OutscanModal);
export default connect(mapStateToProps, mapDispatchToProps)(OutscanModalStyled);
