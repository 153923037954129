import { Input } from 'antd';
import { createUseStyles } from 'react-jss';
import { Col, InputNumber, Row, Slider, Space } from 'antd';
import React, { useState } from 'react';

const useStyle = createUseStyles({
    select: {
        width: '50%',
    },
});

const NumberSlider = (props) => {
    const classes = useStyle();
    const [inputValue, setInputValue] = useState(1);
    const onChange = (value) => {
        setInputValue(value);
    };
    return (
        <Col span={24}>
        <Slider
          min={0}
          max={100}
          onChange={onChange}
          value={inputValue}
        />
        </Col>
    );
};

export default NumberSlider;
