import React from 'react';
import withStyles, { WithStylesProps } from 'react-jss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PageData } from 'src/types/page.types';
import { handleGlobalFilterChanges } from 'src/actions/genericPageActions';
import { DatePicker, Select } from 'antd';
import { getSelectStyleForSelectWithInput } from 'src/theming/cssSnippets/commonSnippets';
import moment from 'moment';
import { CssType, ThemeType } from 'src/theming/jssTypes';

const styles = (theme: ThemeType): CssType => ({
    datePicker: {
        fontSize: '12px',
        background: `${theme.colors.globalHeader}`,
        color: `${theme.colors.textOnDarkBg}`,
        height: 28,
        width: 240,
        border: 'none',
        backgroundColor: `${theme.colors.surfaceBg}0F`,
        '& .ant-calendar-range-picker-separator': {
          background: `${theme.colors.globalHeader}`,
          color: `${theme.colors.textOnDarkBg}`,
          verticalAlign: 'baseline',
          fontSize: '12px',
        },
        '& .ant-calendar-range-picker-input': {
          background: `${theme.colors.globalHeader}`,
          color: `${theme.colors.textOnDarkBg}`,
          width: '40%',
          fontSize: '12px',
        },
        '& .ant-picker-input > input': {
          color: `${theme.colors.textOnDarkBg}`,
          fontSize: '12px',
        },
        '& .ant-picker-separator': {
          color: `${theme.colors.textOnDarkBg}`,
          fontSize: '12px',
        },
        '& .ant-picker-suffix': {
          color: `${theme.colors.textOnDarkBg}`,
          fontSize: '12px',
        },
        borderTopLeftRadius: '0 !important',
        borderBottomLeftRadius: '0 !important',
      },
      dropdownActions: {
        marginTop: '12px',
        background: theme.colors.globalHeader,
        color: theme.colors.textOnDarkBg,
        width: '100%',
        padding: '20px',
      },
      dropdownMenuClass: {
        backgroundColor: theme.colors.globalHeader,
        color: theme.colors.textOnDarkBg,
        '& .ant-select-item-option': {
          backgroundColor: theme.colors.globalHeader,
          color: theme.colors.textOnDarkBg,
        },
        '& .ant-select-item-option:hover': {
          backgroundColor: `${theme.colors.hyperlink}4D !important`,
        },
        '& .ant-select-item-empty': {
          backgroundColor: theme.colors.globalHeader,
          color: theme.colors.textOnDarkBg,
          fontWeight: 'normal',
          fontSize: theme.sizes.bodyText,
          fontFamily: theme.fonts.fontFamily,
        },
        '& .ant-select-item-option-content': {
          fontWeight: 'normal',
          fontSize: theme.sizes.bodyText,
          fontFamily: theme.fonts.fontFamily,
        }
      },
      datePickerDropDown: {
        backgroundColor: theme.colors.globalHeader,
        color: theme.colors.textOnDarkBg,
        fontSize: theme.sizes.bodyText,
        fontFamily: theme.fonts.fontFamily,
        '& .ant-picker-ranges .ant-picker-preset > .ant-tag-blue': {
          background: `${theme.colors.primaryColor}`,
          color: `${theme.colors.textOnDarkBg}`,
        },
        '& .ant-picker-panel-container': {
          background: `${theme.colors.globalHeader}`,
          color: `${theme.colors.textOnDarkBg}`,
        },
        '& .ant-picker-header, & .ant-picker-date-panel .ant-picker-content th': {
          color: `${theme.colors.textOnDarkBg}`,
        },
        '& .ant-picker-header > button': {
          color: `${theme.colors.textOnDarkBg}`,
        },
        '& .ant-picker-cell .ant-picker-cell-inner': {
          color: `${theme.colors.textOnDarkBg}`,
        },
        '& .ant-picker-cell-in-view.ant-picker-cell-in-range::before': {
          background: `${theme.colors.primaryColor}`,
        },
        '& .ant-picker-cell-inner:hover': {
          backgroundColor: `${theme.colors.HoverOnWhiteBg} !important`,
          color: `${theme.colors.textOnLightBg} !important`,
        },
      },
      dateFilterSelectStyle: {
        ...getSelectStyleForSelectWithInput({height: '28px', theme}),
    },
});

interface IProps extends WithStylesProps<
    ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps> {
    
}

const GenericDateRangeFilter = (props: IProps) => {

    const { classes, pageData, handleGlobalFilterChanges } = props;

    const timeFiltersList = pageData.metadata.globalFilters || [];

    if (!timeFiltersList.length) {
        return <></>;
    }

    const appliedTimeFilter = pageData.appliedGlobalTimeFilter;

    const handleDateChange = (dates) => {
        if (dates?.length === 2) {
            handleGlobalFilterChanges({
                pageId: pageData.id,
                timeFilter: {
                    ...appliedTimeFilter,
                    fromDateString: dates[0].format('YYYY-MM-DD'),
                    toDateString: dates[1].format('YYYY-MM-DD'),
                },
            });
        }
    };

    const handleDateFilterTypeChange = (filterType) => {
        handleGlobalFilterChanges({
            pageId: pageData.id,
            timeFilter: {
                ...appliedTimeFilter,
                columnName: filterType,
            },
        });
    };

    return (
        <div>
          <Select
            dropdownClassName={classes.dropdownMenuClass}
            onChange={handleDateFilterTypeChange}
            className={classes.dateFilterSelectStyle}
            value={appliedTimeFilter.columnName}>
            {timeFiltersList.map(t => (
                <Select.Option key={t.id} value={t.id} >{t.name}</Select.Option>
            ))}
          </Select>
          <DatePicker.RangePicker
            dropdownClassName={classes.datePickerDropDown}
            className={classes.datePicker}
            value={[moment(appliedTimeFilter.fromDateString), moment(appliedTimeFilter.toDateString)]}
            format={'DD/MM/YYYY'}
            onChange={handleDateChange}
            ranges={{
                Today: [moment(), moment()],
                'Last 7 days' : [moment().subtract(6, 'day'), moment()],
                'Last 30 days' :[moment().subtract(29, 'day'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
            }}
          >
          </DatePicker.RangePicker>
    </div>
    );
};

const mapStateToProps = ({ masterData, genericPageReducer }, ownProps) => {
    const pageId = ownProps.pageId;
    return {
        pageData: pageId ? genericPageReducer[pageId] as PageData : null,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        handleGlobalFilterChanges,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(
    withStyles(styles, { injectTheme: true })(GenericDateRangeFilter),
);
