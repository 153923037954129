import { CssType, ThemeType } from '@theming/jssTypes';

export const styles = (theme: ThemeType) => ({
  wrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  chatComponent: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    padding: '15px',
    height: '100%',
  },
  chats: {
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: 'white',
  },
  files: {
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  addChat: {
    border: '1px solid lightgray',
    width: '100%',
  },
  textArea: {
    padding: '10px',
    width: '100%',
    border: '0px',
    height: '60%',
    resize: 'none',
    outline: 'none',
    '& ant-input:focus': {
      border: '0 none #FFF',
      overflow: 'hidden',
      outline: 'none !important',
      boxShadow: 'none !important',
    },
  },
  chatMetaData: {
    display: 'flex',
    alignItems: 'center',
  },
  chatAuthor: {
    fontSize: '12px',
    fontWeight: 600,
  },
  separator: {
    fontSize: '8px',
    color: '#999999',
    marginLeft: '5px',
    marginRight: '5px',
  },
  chatTime: {
    fontSize: '12px',
    color: '#666666',
    fontWeight: 400,
  },
  chatWrapper: {
    fontFamily: 'Open Sans',
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
  },
  text: {
    fontSize: '12px',
    marginTop: '5px',
    lineHeight: '16px',
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  chatAttachments: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  fileComponent: {
    width: '40%',
    // overflowY: 'scroll',
    backgroundColor: '#f5f5f5',
    padding: '15px',
    marginBottom: '16px',
    display: 'flex',
    flexDirection: 'column',
  },
  fileComponentHeader: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '10px',
  },
  tag: {
    fontSize: '9px',
    backgroundColor: 'lightgray',
    height: '18px',
    width: '68px',
    borderRadius: '2px',
    lineHeight: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 600,
  },
  emptyFileWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    backgroundColor: 'white',
  },
  fileDescription: {
    color: '#666666',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
  },
  emptyChatWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  chatDescription: {
    color: '#666666',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
  },
  privateModeWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    maxHeight: '100%',
  },
  commentButton: {
    height: '22px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: '16px',
    fontSize: '12px',
    paddingTop: '0px',
  },
  fileCountWrapper: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '14px',
    color: '#111111',
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    borderBottom: '1px solid lightgray',
    marginBottom: '10px',
  },
});
