import { getShelfTableData } from '../../api/shelf';
const viewControl = {
  pageTitle: 'Shelf',
  buckets: ['shelf'],
  navBar: {
    left: [],
    right: [],
  },
  fetchData: getShelfTableData,
  sampleExcelLink: 'https://shipsy-public-assets.s3-us-west-2.amazonaws.com/shipsy/' +
  'sample-downloads/ops/bulk_scan_sample.xlsx',
  dateFilterOptions: [
    { key: 'last_main_event_time', val: 'Last Updated Date' },
    { key: 'created_at', val: 'Creation Date' },
  ],
};
const buckets = {
  shelf: {
    name: 'Shelf',
    filters: [],
    actions: [],
    status_filter: [],
    isSubBucket: false,
  },
};
export default { viewControl, buckets };
