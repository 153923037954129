export const getLastSortedColumnValue = (pagination) => {
  switch (pagination.nextOrPrev) {
    case 'prev':
      return pagination.lastSortedColumnValuePrev;
    case 'next':
      return pagination.lastSortedColumnValueNext;
    default:
      return null;
  }
};

export const getLastTrackingColumnId = (pagination) => {
  switch (pagination.nextOrPrev) {
    case 'prev':
      return pagination.lastTrackingRequestIdPrev;
    case 'next':
      return pagination.lastTrackingRequestIdNext;
    default:
      return null;
  }
};
