import * as moment from 'moment';
import {
  RETAIL_DASHBOARD_ENABLE_LOADING,
  RETAIL_DASHBOARD_DISABLE_LOADING,
  RETAIL_DASHBOARD_UPDATE_FILTER,
  RETAIL_DASHBOARD_UPDATE_DATA,
  RETAIL_DASHBOARD_UPDATE_FILTERED_DATA,
  RETAIL_DASHBOARD_RESET_FILTER,
 } from '../actions/constants';

const defaultState = {
  isLoading: false,
  dashboardData:[],
  filteredData:[],
  appliedFilters: {
    searchValue:'',
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    dateFilterType: 'hub_arrival_time',
    tripType: 'unassigned',
  },
};

export default function (state:any = defaultState, action) {

  switch (action.type) {

    case RETAIL_DASHBOARD_UPDATE_DATA: {
      return {
        ...state,
        dashboardData: action.data,
      };
    }
    case RETAIL_DASHBOARD_UPDATE_FILTERED_DATA: {
      return {
        ...state,
        filteredData: action.data,
      };
    }
    case RETAIL_DASHBOARD_ENABLE_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case RETAIL_DASHBOARD_DISABLE_LOADING: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case RETAIL_DASHBOARD_UPDATE_FILTER: {
      return {
        ...state,
        appliedFilters: {
          ...state.appliedFilters,
          ...action.data,
        },
      };
    }
    case RETAIL_DASHBOARD_RESET_FILTER: {
      return {
        ...state,
        appliedFilters: defaultState.appliedFilters,
      };
    }
    default: return state;
  }
}
