import React from 'react';
import CreditNoteUrlTableAction from './components/CreditNoteUrlTableAction';
import UploadCNDocuments from './components/UploadCNDocuments';
import HandDeliveryModal from 'src/components/pages/retail/dashboard/HandDeliveryModal';
import DownloadLabelComponent from './components/DownloadLabelComponent';
import { printDeliveryNote } from 'src/api/dashboard';
import { downloadFileData } from 'src/utils/utils';
import { printCNCopyLabel } from 'src/api/genericConsignmentView';

const tableActionsConfig = {
    cn_label: {
        labelComponent: DownloadLabelComponent,
    },
    cn_copy_label: {
        label: 'cn_copy',
        handleClick: async (params) => {
            const { record, message } = params;
            const { reference_number } = record;
            const requestParams = {
                reference_number,
            };
            const response = await printCNCopyLabel(requestParams);
            if (response.isSuccess) {
                downloadFileData(response.data, 'cn_copy', '.pdf');
            }else {
                message.error(response.errorMessage);
            }
        },
    },
    upload_documents: {
        label: 'upload_documents',
        modalComponent: UploadCNDocuments,
    },
    hand_delivery: {
        label: 'hand_delivery',
        modalComponent: HandDeliveryModal,
    },
    delivery_note: {
        label: 'delivery_note',
        handleClick: async (params) => {
            const { record, message } = params;
            const { reference_number, customer_reference_number: shipment_id } = record;
            message.success('Downloading Delivery Note');

            const response = await printDeliveryNote({
            reference_number,
            shipment_id,
            });

            if (response.isSuccess) {
                downloadFileData(response?.data, reference_number, '.pdf');
                message.success('Delivery Note successfully downloaded');
            }
            else {
                message.error('Unable to download Delivery Note');
            }
        },
    },
    credit_note: {
        labelComponent: CreditNoteUrlTableAction, 
    },
};

export default tableActionsConfig;
