import Icon from '@ant-design/icons';
import * as React from 'react';
const EmptyChatconSvg = (props: any) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={22}
    viewBox="0 0 24 22"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_26_5788)">
      <path
        d="M9.5 15.9855H7.8C7.53478 15.9855 7.28043 15.8802 7.09289 15.6928C6.90536 15.5055 6.8 15.2514 6.8 14.9864C6.8 14.7214 6.90536 14.4673 7.09289 14.2799C7.28043 14.0925 7.53478 13.9873 7.8 13.9873H9.5C13.64 13.9873 17 11.2997 17 7.99273C17 4.68574 13.64 1.99818 9.5 1.99818C5.36 1.99818 2 4.68574 2 7.99273C2 10.4605 3.93 11.9891 5.08 12.6785C5.28021 12.8239 5.4193 13.0384 5.47035 13.2805C5.52139 13.5225 5.48077 13.7748 5.35634 13.9886C5.23192 14.2025 5.03252 14.3626 4.79675 14.438C4.56098 14.5134 4.30559 14.4988 4.08 14.3969C2.89663 13.7818 1.89642 12.8659 1.18014 11.7416C0.463855 10.6173 0.0568068 9.32427 0 7.99273C0 3.58674 4.26 0 9.5 0C14.74 0 19 3.58674 19 7.99273C19 12.3987 14.74 15.9855 9.5 15.9855Z"
        fill="#666666"
      />
      <path
        d="M3.47002 17.9836C3.27028 17.9981 3.06976 17.9981 2.87002 17.9836C2.6724 17.9563 2.48749 17.8705 2.33905 17.7373C2.19062 17.6041 2.08545 17.4296 2.03706 17.2363C1.98867 17.0429 1.99928 16.8395 2.06753 16.6522C2.13577 16.4649 2.25853 16.3023 2.42002 16.1852C2.78204 15.8355 3.06962 15.4162 3.26551 14.9527C3.4614 14.4891 3.56158 13.9909 3.56002 13.4877C3.57593 13.2227 3.69655 12.9749 3.89534 12.7988C4.09413 12.6227 4.3548 12.5327 4.62002 12.5486C4.88524 12.5644 5.13327 12.685 5.30955 12.8836C5.48584 13.0822 5.57593 13.3426 5.56002 13.6076C5.52084 14.3485 5.35863 15.0778 5.08002 15.7656C5.84209 15.5001 6.50958 15.0173 7.00002 14.3769C7.16211 14.168 7.40044 14.0318 7.66284 13.9981C7.92524 13.9644 8.19031 14.0359 8.40002 14.1971C8.61065 14.3575 8.74895 14.5949 8.78457 14.8572C8.82019 15.1194 8.75022 15.385 8.59002 15.5958C7.97643 16.3587 7.19573 16.9708 6.30805 17.3848C5.42038 17.7988 4.44944 18.0036 3.47002 17.9836Z"
        fill="#666666"
      />
      <path
        d="M19.44 18.5332C19.22 18.5353 19.0055 18.465 18.8296 18.333C18.6537 18.201 18.5263 18.0148 18.4671 17.8032C18.4079 17.5915 18.4202 17.3663 18.502 17.1623C18.5839 16.9583 18.7308 16.787 18.92 16.6749C20.07 15.9855 22 14.4569 22 11.9891C21.9923 11.3761 21.8473 10.7725 21.5756 10.2227C21.3039 9.67292 20.9125 9.1909 20.43 8.81203C20.2275 8.6552 20.0929 8.42669 20.0542 8.17365C20.0154 7.92061 20.0753 7.66235 20.2215 7.45216C20.3678 7.24196 20.5892 7.09587 20.8401 7.04402C21.091 6.99218 21.3523 7.03853 21.57 7.17352C22.3182 7.73561 22.9267 8.46248 23.3482 9.29766C23.7696 10.1328 23.9926 11.0539 24 11.9891C23.9481 13.3119 23.5519 14.5984 22.8504 15.7214C22.1489 16.8445 21.1665 17.7654 20 18.3933C19.8298 18.4908 19.636 18.5392 19.44 18.5332Z"
        fill="#666666"
      />
      <path
        d="M16.2 19.9818H14.5C12.7858 20.0055 11.09 19.6256 9.55003 18.8728C9.42308 18.82 9.3084 18.7416 9.21316 18.6425C9.11791 18.5434 9.04414 18.4258 8.99647 18.2969C8.94879 18.168 8.92823 18.0307 8.93607 17.8935C8.94392 17.7564 8.97999 17.6223 9.04204 17.4997C9.10408 17.3771 9.19078 17.2686 9.2967 17.181C9.40261 17.0933 9.52548 17.0285 9.65762 16.9905C9.78976 16.9524 9.92833 16.942 10.0647 16.9599C10.201 16.9778 10.3322 17.0236 10.45 17.0944C11.7124 17.7018 13.0992 18.0062 14.5 17.9836H16.2C16.4652 17.9836 16.7196 18.0889 16.9071 18.2763C17.0947 18.4636 17.2 18.7178 17.2 18.9827C17.2 19.2477 17.0947 19.5018 16.9071 19.6892C16.7196 19.8766 16.4652 19.9818 16.2 19.9818Z"
        fill="#666666"
      />
      <path
        d="M20.53 21.98C19.5473 21.9992 18.5734 21.792 17.6837 21.3744C16.7941 20.9569 16.0127 20.3402 15.4 19.5722C15.2398 19.3615 15.1699 19.0958 15.2055 18.8336C15.2411 18.5713 15.3794 18.3339 15.59 18.1735C15.7998 18.0123 16.0648 17.9408 16.3272 17.9745C16.5896 18.0082 16.828 18.1444 16.99 18.3533C17.4846 18.9916 18.1556 19.471 18.92 19.7321C18.6414 19.0443 18.4792 18.315 18.44 17.574C18.4322 17.4428 18.4502 17.3113 18.4932 17.1871C18.5362 17.0629 18.6032 16.9483 18.6905 16.85C18.7778 16.7517 18.8836 16.6715 19.0019 16.614C19.1202 16.5565 19.2487 16.5229 19.38 16.515C19.5114 16.5071 19.643 16.5252 19.7673 16.5681C19.8916 16.611 20.0063 16.678 20.1047 16.7652C20.2032 16.8524 20.2834 16.9582 20.341 17.0764C20.3985 17.1946 20.4322 17.3229 20.44 17.4541C20.4358 17.9639 20.5366 18.4692 20.7362 18.9384C20.9357 19.4076 21.2298 19.8308 21.6 20.1816C21.7661 20.297 21.8932 20.46 21.9646 20.6491C22.0361 20.8382 22.0484 21.0445 22 21.2407C21.9488 21.4303 21.8427 21.6006 21.6951 21.7303C21.5475 21.8601 21.3648 21.9434 21.17 21.97C20.9573 21.9898 20.7433 21.9931 20.53 21.98Z"
        fill="#666666"
      />
      <path
        d="M12.87 7.99269H6.87C6.60478 7.99269 6.35042 7.88743 6.16289 7.70006C5.97535 7.5127 5.87 7.25857 5.87 6.9936C5.87 6.72862 5.97535 6.4745 6.16289 6.28713C6.35042 6.09977 6.60478 5.99451 6.87 5.99451H12.87C13.1352 5.99451 13.3896 6.09977 13.5771 6.28713C13.7646 6.4745 13.87 6.72862 13.87 6.9936C13.87 7.25857 13.7646 7.5127 13.5771 7.70006C13.3896 7.88743 13.1352 7.99269 12.87 7.99269Z"
        fill="#666666"
      />
      <path
        d="M10.87 10.99H6.87C6.60478 10.99 6.35042 10.8847 6.16289 10.6974C5.97535 10.51 5.87 10.2559 5.87 9.99091C5.87 9.72594 5.97535 9.47181 6.16289 9.28445C6.35042 9.09708 6.60478 8.99182 6.87 8.99182H10.87C11.1352 8.99182 11.3896 9.09708 11.5771 9.28445C11.7646 9.47181 11.87 9.72594 11.87 9.99091C11.87 10.2559 11.7646 10.51 11.5771 10.6974C11.3896 10.8847 11.1352 10.99 10.87 10.99Z"
        fill="#666666"
      />
    </g>
    <defs>
      <clipPath id="clip0_26_5788">
        <rect width={24} height={22} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const EmptyChatcon = (props: any) => (
  <Icon component={EmptyChatconSvg} {...props} />
);

export default EmptyChatcon;
