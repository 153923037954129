import React from 'react';
import withStyles, { WithStylesProps } from 'react-jss';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styles from './GenericDashboard.styles';
import GenericNavbarComponent from './components/GenericNavbar/GenericNavbar.component';
import { PageData } from 'src/types/page.types';
import { ViewData } from 'src/types/view.types';
import { handleGlobalFilterChanges, handleViewChange } from 'src/actions/genericPageActions';
import HubSearch from 'src/components/common/HubSearch';
import GenericDateRangeFilter from './components/GenericDateRangeFilter';
import GenericSection from './components/GenericSection';

interface IProps extends WithStylesProps<
    ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps> {
    
}

const GenericDashboard = (props: IProps) => {
    const { classes, pageData, handleGlobalFilterChanges, handleViewChange } = props;

    const title = pageData?.name || 'SHIPSY';

    const renderSection = (sectionData: ViewData[], sectionIndex) => {
        return <GenericSection key={sectionIndex} sectionIndex={sectionIndex} pageId={pageData?.id} />;
    };

    const renderPageData = () => {
        return <div className={classes.sectionParent}>
            {pageData.metadata.sectionList.map(renderSection)}
        </div>;
    };

    const onHubChange = (hub) => {
        handleGlobalFilterChanges({
            pageId: pageData.id,
            hub,
        });
    };

    return <div className={classes.container}>
        <DocumentTitle title={title} />
        <GenericNavbarComponent
          pageTitle={title}
          leftComponents={[
            <HubSearch
                withType
                onHubChange={(hub) => onHubChange(hub)}
            />,
            pageData ? <GenericDateRangeFilter pageId={pageData.id} /> : <></>
          ]}
        />
        {
            pageData ? renderPageData() : <></>
        }
    </div>;
};

const mapStateToProps = ({ masterData, genericPageReducer }, ownProps) => {
    const pageId = ownProps.match?.params?.pageId;
    return {
        masterData,
        pageData: pageId ? genericPageReducer[pageId] as PageData : null,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        handleGlobalFilterChanges,
        handleViewChange,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(
    withStyles(styles, { injectTheme: true })(GenericDashboard),
);
