import React, { useState } from 'react';
import {
  Upload,
  message,
  Modal,
  Button,
  Typography,
  Select,
  Row,
  Col,
} from 'antd';
import {
  getVehicleDocumentImageURL,
  putImageS3,
} from '@api/TMSDashboard/Setup';
import {
  UploadOutlined,
  DeleteOutlined,
  LinkOutlined,
  PlusOutlined,
  InfoCircleFilled,
} from '@ant-design/icons';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '../../../../theming/jssTypes';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import lodash from 'lodash';

const { Text } = Typography;

const styles = (theme: ThemeType): CssType => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ulStyle: {
    paddingLeft: '0px',
    listStyle: 'none',
  },
  liStyle: {
    marginBottom: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  grayCircle: {
    width: '32px',
    height: '32px',
    fontSize: '14px',
    backgroundColor: '#F5F5F5',
    borderRadius: '64px',
    marginRight: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  customFlex: {
    display: 'flex', alignItems: 'center',
  }
});

const DocumentUploaderModal = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const {
    record,
    documentType,
    maxCount,
    title,
    isVisible,
    onClose,
    classes,
    postUpload,
  } = props;

  const currentDocDetails = [];
  const recordDocumentDetails = record.document_details || []; 
  const documentTypeWiseDetails = lodash.groupBy(recordDocumentDetails, 'type');
  for (const key in documentTypeWiseDetails || {}) {
    const data = documentTypeWiseDetails[key];
    currentDocDetails.push({ document_type: key, existing_document_details: data, new_files_uploaded: [] });
  }

  const [documentDetails, setDocumentDetails] =
    useState<{ document_type: string, existing_document_details: {type: string, url: string}[], new_files_uploaded: any }[]>(lodash.cloneDeep(currentDocDetails));

  const availableDocumentTypesList = props.availableDocumentTypesList.map(
    (documentType) => {
      return {
        label: documentType.name,
        value: documentType.id,
      };
    },
  );

  let currentTotalFiles = 0;
  for (const data of documentDetails) {
    currentTotalFiles += data?.new_files_uploaded?.length || 0;
    currentTotalFiles += data?.existing_document_details?.length || 0;
  }

  const handleUpload = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);

    // computing total new files uploaded
    const newFileList = [];
    documentDetails.forEach((data, index) => {
      let newFiles = data.new_files_uploaded || [];
      const documentType = data.document_type;
      newFiles = newFiles.map((newFile) => {
        return {
          ...newFile,
          document_type: documentType,
          index,
        };
      });
      newFileList.push(...newFiles);
    });

    // promise to upload new file to s3 by computing its url from backend first
    const promises = newFileList.map(async (file, index) => {
      const bodyGetUrl = {
        type: documentType,
        pocContentType: file.type?.split('/')?.[1],
        referenceNumber: record.reference_number,
      };

      const resGetUrl = await getVehicleDocumentImageURL(bodyGetUrl);

      const s3UrlToUpload = resGetUrl?.data?.pocSignedUrl;
      const s3UrlToReturn = resGetUrl?.data?.pocImageUrl;

      const fileType = file.type;
      const fileItem = new Blob([file.originFileObj as BlobPart], {
        type: fileType,
      });

      const res: any = await putImageS3(s3UrlToUpload, fileItem, fileType);
      if (!res.isSuccess) {
        message.error('Could not upload file');
      }

      return { url: s3UrlToReturn, document_type: file.document_type, index: file.index };
    });

    try {
      const fileUrls = await Promise.all(promises);
      const newlyUploaded = fileUrls.filter((file) => !!file.url);
      const newlyUploadedFiles = lodash.groupBy(newlyUploaded, 'index');

      // generating api payload for each document type
      const apiPayload = [];

      documentDetails.forEach((data, index) => {
        const documentType = data.document_type;
        if (documentType) {
          const existingDocumentDetails = data.existing_document_details || [];
          const newFileObjects = newlyUploadedFiles[index] || [];
  
          for (const file of [...existingDocumentDetails, ...newFileObjects]) {
            apiPayload.push({
              type: documentType,
              url: file.url,
            })
          }
        }
      });
      await postUpload(apiPayload);
    } catch (error) {
      setDocumentDetails(currentDocDetails);
      message.error(error);
    }
    setLoading(false);
  };

  const removeRow = (index) => {
    const newDocumentDetails = [...documentDetails];
    newDocumentDetails.splice(index, 1);
    setDocumentDetails(newDocumentDetails);
  }
  const DocumentTypeWiseFile = React.memo((props: any) => {
    const { index, currentDocumentTypeObject = {} } = props;
    const currentDocumentType = currentDocumentTypeObject.document_type;
    const existingDocumentDetails = currentDocumentTypeObject.existing_document_details || [];
    const newDocumentsInCurrentRow =
      currentDocumentTypeObject.new_files_uploaded || [];

    const handleDocumentTypeChange = (value) => {
      const newDocumentDetails = [...documentDetails];
      newDocumentDetails[index].document_type = value;
      setDocumentDetails(newDocumentDetails);
    };

    const handleChange = (info: any) => {
      const file = info.file;
      if (file && file.size / 1024 / 1024 < 2) {
        const newDocumentDetails = [...documentDetails];
        newDocumentDetails[index].new_files_uploaded = info.fileList;
        setDocumentDetails(newDocumentDetails);
      }
    };

    const handleRemove = (file: any) => {
      const newDocumentDetails = [...documentDetails];
      newDocumentDetails[index].new_files_uploaded =
        newDocumentsInCurrentRow.filter((f) => f.uid !== file.uid);
      setDocumentDetails(newDocumentDetails);
    };

    const removeUploadedFile = (file) => {
      const newDocumentDetails = [...documentDetails];
      if (file.new_uploaded) {
        newDocumentDetails[index].new_files_uploaded = newDocumentsInCurrentRow.filter(
          (f) => f.name !== file.url,
        );
      }
      else {
        newDocumentDetails[index].existing_document_details = existingDocumentDetails.filter(
          (doc) => doc.url !== file.url,
        );
      }
      setDocumentDetails(newDocumentDetails);
    };

    const rows = [];
    const totalFiles = []; 
    newDocumentsInCurrentRow.map(newDocument => totalFiles.push({ url: newDocument.name, new_uploaded: true }));
    existingDocumentDetails.map(currentDocument => totalFiles.push({ url: currentDocument.url, new_uploaded: false }));
    for (let i = 0; i < totalFiles.length; i += 3) {
      const rowData = totalFiles.slice(i, i + 3);
      rows.push(rowData);
    }

    return (
      <>
        <div
          style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', marginTop: '10px' }}
        >
          <div className={classes.grayCircle}>{index + 1}</div>
          <Select
            allowClear
            style={{ width: '40%', marginRight: '20px' }}
            placeholder="Choose Document Type"
            value={currentDocumentType}
            onChange={handleDocumentTypeChange}
            options={availableDocumentTypesList}
          />
          <Upload
            name="file"
            disabled={!currentDocumentType || currentTotalFiles >= maxCount}
            fileList={newDocumentsInCurrentRow}
            onChange={handleChange}
            onRemove={handleRemove}
            beforeUpload={(file) => {
              const isLt2M = file.size / 1024 / 1024 < 2;
              if (!isLt2M) {
                message.error('File must be smaller than 2MB!');
              }
              return isLt2M;
            }}
            multiple
            showUploadList={false}
          >
            <Button
              disabled={!currentDocumentType || currentTotalFiles >= maxCount}
              icon={<UploadOutlined />}
            >
              Upload Files
            </Button>
          </Upload>
          <div style={{ flexGrow: '1' }}></div>
          <DeleteOutlined
            style={{ color: '#666666', fontSize: '22px' }}
            onClick={() => removeRow(index)}
          />
        </div>
        {rows.length > 0 && (
          <div style={{ marginLeft: '52px' }}>
            <>
              {rows.map((row, index) => (
                <Row
                  key={index}
                  gutter={[16, 16]}
                  style={{ marginBottom: '20px' }}
                >
                  {row.map((item, i) => (
                    <Col
                      key={i}
                      span={8}
                      style={{ maxHeight: '22px', height: '22px' }}
                    >
                      <div key={i} className={classes.customFlex}>
                        <a
                          href={item.url}
                          target="_blank"
                          style={
                            item?.new_uploaded
                              ? { pointerEvents: 'none', cursor: 'not-allowed' }
                              : {}
                          }
                        >
                          <div key={i} className={classes.customFlex}>
                            <LinkOutlined style={{ color: '#666666' }} />
                            <Text
                              style={{
                                textAlign: 'center',
                                marginLeft: '10px',
                                marginRight: '10px',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                width: '135px',
                                color: item.new_uploaded
                                  ? '#666666'
                                  : '#006EC3',
                              }}
                            >
                              {item?.url}
                            </Text>
                          </div>
                        </a>
                        <DeleteOutlined
                          style={{ color: '#666666' }}
                          onClick={() => removeUploadedFile(item)}
                        />
                      </div>
                    </Col>
                  ))}
                </Row>
              ))}
            </>
          </div>
        )}
      </>
    );
  });

  const handleAddDocumentType = () => {
    const newDocumentDetails = [...documentDetails];
    newDocumentDetails.push({ document_type: '', existing_document_details: [], new_files_uploaded: [] });
    setDocumentDetails(newDocumentDetails);
  };

  const textString = `Maximum ${maxCount} files can be uploaded. Formats supported are pdf, jpg, jpeg, excel.`
  return (
    <Modal
      title={title}
      width={'50%'}
      visible={isVisible}
      onCancel={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClose(false);
      }}
      destroyOnClose
      footer={[
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <InfoCircleFilled style={{ color: '#006EC3', marginRight: '10px' }} />
          <div style={{ fontSize: '14px', opacity: '70%', color: '#232529', textAlign: 'left' }}>
            {textString}
          </div>
          <div style={{ flexGrow: 1, marginRight: '10px' }}></div>
          <Button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClose(false);
            }}
          >
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleUpload}
          >
            Submit
          </Button>
        </div>,
      ]}
    >
      <>
        {documentDetails.map((doc, index) => (
          <DocumentTypeWiseFile index={index} currentDocumentTypeObject={doc} />
        ))}
        {documentDetails.length < maxCount && (
          <div
            style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}
            onClick={handleAddDocumentType}
          >
            <div className={classes.grayCircle}>
              {(documentDetails?.length || 0) + 1}
            </div>
            <PlusOutlined style={{ color: '#006EC3', marginRight: '5px' }} />
            <div
              style={{ color: '#006EC3', cursor: 'pointer', fontSize: '12px' }}
            >
              Add Document Type
            </div>
          </div>
        )}
      </>
    </Modal>
  );
};

const mapStateToProps = ({ masterData }, ownProps) => {
  return {
    availableDocumentTypesList: masterData.available_document_types || [],
  };
};

const DocumentUploaderModalStyled = withStyles(styles, { injectTheme: true })(
  DocumentUploaderModal,
);
const DocumentUploaderConnected = connect(mapStateToProps)(
  DocumentUploaderModalStyled,
);
export default withRouter(
  DocumentUploaderConnected,
) as React.ComponentType<any>;
