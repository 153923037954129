import Icon from '@ant-design/icons';
import * as React from 'react';

const EmployeesSvg = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Setup_Tooltip" transform="translate(-16.000000, -218.000000)">
                <g id="Group-6" transform="translate(0.000000, 58.000000)">
                    <g id="Employees" transform="translate(16.000000, 160.000000)">
                        <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                        <g transform="translate(1.000000, 2.300000)" fill="#666666" id="Path">
                            <path d="M12.0182,12.55889 C12.2962,12.90639 12.2399,13.41349 11.8924,13.69149 L7.33113,17.34049 L7.33113,17.97609 L20.3883,17.97609 L20.3883,17.36969 L15.8271,13.72069 C15.4795,13.44269 15.4232,12.93559 15.7012,12.58799 C15.9792,12.24049 16.4863,12.18419 16.8338,12.46219 L21.6975,16.35309 C21.8887,16.50599 21.9999,16.73759 21.9999,16.98239 L21.9999,18.18839 C21.9999,18.18849 21.9999,18.18849 21.9999,18.18849 C21.9999,18.37419 21.963,18.55799 21.8913,18.72919 C21.8195,18.90049 21.7144,19.05579 21.582,19.18599 C21.4497,19.31619 21.2927,19.41879 21.1203,19.48779 C20.9503,19.55569 20.7687,19.58969 20.5858,19.58769 L7.13365,19.58769 C6.95068,19.58969 6.76907,19.55569 6.59912,19.48779 C6.4267,19.41879 6.26974,19.31619 6.13737,19.18599 C6.00501,19.05579 5.89989,18.90049 5.82814,18.72919 C5.75641,18.55799 5.71948,18.37419 5.71948,18.18849 C5.71948,18.18849 5.71948,18.18849 5.71948,18.18839 L5.71948,16.95319 C5.71948,16.70839 5.83076,16.47689 6.02191,16.32389 L10.8856,12.43299 C11.2331,12.15499 11.7402,12.21129 12.0182,12.55889 Z"></path>
                            <path d="M10.3032,1.14987 C11.3332,0.43743 12.6268,-8.8817842e-16 13.8792,-8.8817842e-16 C15.1197,-8.8817842e-16 16.4038,0.45194 17.4284,1.16612 C18.4473,1.87632 19.3049,2.91573 19.5352,4.16107 C19.5442,4.20942 19.5487,4.25849 19.5487,4.30766 L19.5487,7.78032 L19.5485,7.79729 C19.525,8.91079 19.2709,10.00729 18.802,11.01759 C18.3332,12.02779 17.6599,12.92979 16.8246,13.66659 C16.4909,13.96099 15.9817,13.92909 15.6873,13.59529 C15.3929,13.26159 15.4248,12.75239 15.7585,12.45799 C16.4266,11.86859 16.9651,11.14719 17.3401,10.33909 C17.714,9.53349 17.9172,8.65939 17.937,7.77157 L17.937,4.38783 C17.7879,3.70931 17.277,3.0251 16.5068,2.48827 C15.7173,1.9379 14.7495,1.61165 13.8792,1.61165 C12.9819,1.61165 12.0042,1.93295 11.22,2.47534 C10.4526,3.00611 9.9539,3.68692 9.8214,4.37786 L9.8214,7.77036 C9.8439,8.65329 10.0482,9.52209 10.4217,10.32239 C10.7966,11.12569 11.3336,11.84259 11.9991,12.42809 C12.3332,12.72199 12.3658,13.23119 12.0718,13.56539 C11.7779,13.89949 11.2687,13.93209 10.9345,13.63809 C10.102,12.90559 9.4302,12.00879 8.96129,11.00399 C8.49238,9.99919 8.23656,8.90819 8.20995,7.79966 L8.20972,7.78032 L8.20972,4.30766 C8.20972,4.26456 8.21317,4.22153 8.22006,4.17898 C8.42606,2.90551 9.278,1.85895 10.3032,1.14987 Z"></path>
                            <path d="M6.29873,12.55889 C6.57675,12.90639 6.5204,13.41349 6.17288,13.69149 L1.61165,17.34049 L1.61165,17.97609 L3.35438,17.97609 C3.79943,17.97609 4.16021,18.33689 4.16021,18.78189 C4.16021,19.22699 3.79943,19.58769 3.35438,19.58769 L1.41417,19.58769 C1.23119,19.58969 1.04959,19.55569 0.879630002,19.48779 C0.707220002,19.41879 0.550250002,19.31619 0.417890002,19.18599 C0.285530002,19.05579 0.180410002,18.90049 0.108660002,18.72919 C0.0369300025,18.55799 8.8817842e-16,18.37419 8.8817842e-16,18.18849 C8.8817842e-16,18.18849 8.8817842e-16,18.18849 8.8817842e-16,18.18839 L8.8817842e-16,16.95319 C8.8817842e-16,16.70839 0.111280002,16.47689 0.302430002,16.32389 L5.16609,12.43299 C5.51361,12.15499 6.02071,12.21129 6.29873,12.55889 Z"></path>
                            <path d="M8.27785,0.75059 C8.33522,1.19192 8.02396,1.5962 7.58263,1.65357 C6.75559,1.76109 5.90498,2.11906 5.23395,2.64086 C4.58205,3.14778 4.15619,3.76238 4.024,4.38528 L4.024,7.77037 C4.04649,8.65329 4.25086,9.52209 4.62437,10.32249 C4.99921,11.12569 5.53627,11.84259 6.20175,12.42809 C6.53588,12.72209 6.56844,13.23119 6.27446,13.56539 C5.98049,13.89949 5.47131,13.93209 5.13718,13.63809 C4.30468,12.90569 3.63283,12.00879 3.16392,11.00399 C2.69501,9.99919 2.4392,8.90819 2.41259,7.79966 L2.41235,7.78032 L2.41235,4.30767 C2.41235,4.25997 2.41659,4.21237 2.42501,4.16542 C2.63035,3.0205 3.3554,2.06006 4.24463,1.36859 C5.13726,0.67448 6.25643,0.20077 7.37486,0.05537 C7.81619,-0.002 8.22047,0.30926 8.27785,0.75059 Z"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    );
};

export const EmployeesIcon = props => (
  <Icon component={EmployeesSvg} {...props} />
);
