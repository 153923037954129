import { TableModalColumns } from './types';

export const givenPaymentDetailsColumns: TableModalColumns[] = [
  {
    pretty_name: 'Mode',
    column_id: 'mode',
  },
  {
    pretty_name: 'Value',
    column_id: 'value',
  },
];
