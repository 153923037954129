import apiProvider from './provider';
import {
  getAuthenticationHeaders,
  getErrorMessage,
} from './utils';

import { API_BASE_URL } from '../globals';
import {
  GET_CRM_SUMMARY_METRICS_DATA,
  GET_CRM_SUMMARY_METRICS_AGGREGATED_DATA,
  GET_SUMMARY_METRICS_DOWNLOAD_DATA,
} from './routes';
import { requestModelSummaryMetricsDataCRM,
  requestModelAggregatedSummmaryMetrics,
  responseModelAggregatedSummaryMetrics,
  responseModelSummaryMetricsCN,
  requestModelSummmaryMetricsDownloads,
 } from '../model/summaryMetricsView';
import { morphism } from 'morphism';

export const getCRMSummaryMetricsData = async (
  body,
) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${GET_CRM_SUMMARY_METRICS_DATA}`,
      { ...morphism(requestModelSummaryMetricsDataCRM, body) },
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: morphism(responseModelSummaryMetricsCN, response.data.data),
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getaggregatedMetricsCRM = async (
  body,
) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${GET_CRM_SUMMARY_METRICS_AGGREGATED_DATA}`,
      morphism(requestModelAggregatedSummmaryMetrics, body),
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: morphism(responseModelAggregatedSummaryMetrics, response.data),
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const fetchDownloadsSummaryMetricsData =  async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${GET_SUMMARY_METRICS_DOWNLOAD_DATA}`,
       morphism(requestModelSummmaryMetricsDownloads, body),
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    const data = response.data.data;
    return  {
      data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
