import {
  getCRMSummaryMetricsData,
  fetchDownloadsSummaryMetricsData,
  getaggregatedMetricsCRM,
} from '../../api/summaryMetricsView';
const viewControl = {
  pageTitle : 'Summary Metrics',
  buckets : [
    'crm_summary_metrics',
  ],
  navBar : {
    left : [],
    right : [],
  },
  fetchData : getCRMSummaryMetricsData,
  requestDownload: fetchDownloadsSummaryMetricsData,
  fetchSubBucket: getaggregatedMetricsCRM,
  dateFilterOptions : [
    { key: 'completion_time', val: 'Delivered Date' },
    { key: 'created_at', val: 'Created Date' },
  ],
};
const buckets = {
  crm_summary_metrics: {
    name: 'Summary Metrics',
    filters : [
      'hub',
      'client_code',
      'status',
      'service_type',
      'payment_type',
      'attempt_count',
      'movement_type',
      'payment_type',

    ],
    actions: [
    ],
    status_filter : [
      'softdata_upload',
      'pickup_completed',
      'on_hold',
      'inscan_at_hub',
      'inscan_at_origin_hub',
      'intransittohub',
      'outfordelivery',
      'reachedathub',
      'delivered',
      'attempted',
      'rescheduled',
    ],
    isSubBucket : true,
  },
};
export default { viewControl, buckets };
