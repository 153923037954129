import { API_BASE_URL } from '../globals';
import { morphism } from 'morphism';
import {
  FETCH_SUPPLEMENTARY_SERVICE_MASTER,
  CREATE_SUPPLEMENTARY_SERVICE_MASTER,
  DOWNLOAD_SUPPLEMENTARY_SERVICE_MASTER,
  DELETE_SUPPLEMENTARY_SERVICE_MASTER_SINGLE,
  UPDATE_SUPPLEMENTARY_SERVICE_MASTER,
} from './routes';
import { GET, POST } from './apiHandler';
import {
  requestModelSupplementaryServiceChange,
} from '@model/consignmentView';

export const fetchSupplementaryServiceMaster = async (params) => {
  const body = {
    ...morphism(requestModelSupplementaryServiceChange, params),
  };
  return GET(`${API_BASE_URL}${FETCH_SUPPLEMENTARY_SERVICE_MASTER}`, body);
};

export const downloadSupplementaryServiceMaster = async (params) => {
  return GET(`${API_BASE_URL}${DOWNLOAD_SUPPLEMENTARY_SERVICE_MASTER}`);
};

export const createSupplementaryServiceMaster = async (params) => {
  return POST(`${API_BASE_URL}${CREATE_SUPPLEMENTARY_SERVICE_MASTER}`, params);
};

export const deleteSupplementaryServiceMasterSingle = async (params) => {
  return POST(`${API_BASE_URL}${DELETE_SUPPLEMENTARY_SERVICE_MASTER_SINGLE}`, params);
};

export const updateSupplementaryServiceMaster = async (params) => {
  return POST(`${API_BASE_URL}${UPDATE_SUPPLEMENTARY_SERVICE_MASTER}`, params);
};
