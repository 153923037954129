import Icon from '@ant-design/icons';
import * as React from 'react';

const RoutingPlayGroundSvg = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_764_21)">
        <rect width="24" height="24"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.012 7.8402C12.0121 7.83939 12.0122 7.83885 12.0122 7.83867C12.0122 7.83895 12.0122 7.83835 12.012 7.83714C12.0117 7.83599 12.0114 7.83481 12.0109 7.83373C12.01 7.83168 12.0091 7.83065 12.0087 7.83017C12.0082 7.8297 12.0072 7.82881 12.0051 7.82794C12.004 7.82749 12.0029 7.82712 12.0017 7.82688C12.0009 7.82672 12.0004 7.82667 12.0002 7.82666C12.0002 7.82666 12.0002 7.82665 12.0002 7.82665C12.0002 7.82665 12.0002 7.82665 12.0002 7.82666C12.0001 7.82665 12.0001 7.82665 12.0001 7.82665C12.0001 7.82665 12.0001 7.82666 12.0002 7.82666C12 7.82667 11.9995 7.82672 11.9986 7.82688C11.9975 7.82712 11.9963 7.82749 11.9952 7.82794C11.9932 7.82881 11.9921 7.8297 11.9917 7.83017C11.9912 7.83065 11.9903 7.83168 11.9894 7.83373C11.989 7.83481 11.9886 7.83599 11.9884 7.83714C11.9882 7.83795 11.9882 7.83849 11.9881 7.83867C11.9881 7.83863 11.9881 7.83861 11.9881 7.83861C11.9881 7.83861 11.9881 7.83863 11.9881 7.83867M12.012 7.8402C12.0117 7.84135 12.0114 7.84253 12.0109 7.84361ZM12.0109 7.84361C12.01 7.84566 12.0091 7.8467 12.0087 7.84717ZM12.0087 7.84717C12.0082 7.84765 12.0072 7.84854 12.0051 7.8494ZM12.0051 7.8494C12.004 7.84986 12.0029 7.85023 12.0017 7.85046ZM12.0017 7.85046C12.0005 7.85071 11.9999 7.8507 12.0002 7.8507ZM10.3235 7.83867C10.3235 6.91266 11.0742 6.16199 12.0002 6.16199C12.9262 6.16199 13.6769 6.91266 13.6769 7.83867C13.6769 8.76468 12.9262 9.51536 12.0002 9.51536C11.0742 9.51536 10.3235 8.76468 10.3235 7.83867Z" fill="#666666"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M2.35065 2.15981C2.57083 2.00111 2.85388 1.95793 3.11136 2.04376L5.60836 2.87609C6.05075 3.02356 6.28984 3.50173 6.14237 3.94413C5.99491 4.38652 5.51673 4.62561 5.07434 4.47814L3.68871 4.01627V18.1023L9.00328 20.38C9.4319 20.5637 9.63045 21.0601 9.44676 21.4887C9.26306 21.9173 8.76669 22.1159 8.33807 21.9322L2.51175 19.4352C2.20129 19.3021 2 18.9969 2 18.6591V2.84479C2 2.57338 2.13047 2.31851 2.35065 2.15981Z" fill="#666666"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M17.8575 4.24234C18.005 3.79995 18.4831 3.56086 18.9255 3.70832L21.4225 4.54065C21.7673 4.65558 21.9999 4.97824 21.9999 5.34168V21.156C21.9999 21.4398 21.8573 21.7046 21.6203 21.8609C21.3834 22.0171 21.0838 22.0439 20.8229 21.9321L14.9966 19.4351C14.568 19.2514 14.3694 18.755 14.5531 18.3264C14.7368 17.8978 15.2332 17.6992 15.6618 17.8829L20.3112 19.8755V5.95025L18.3915 5.31037C17.9491 5.16291 17.71 4.68473 17.8575 4.24234Z" fill="#666666"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M16.12 18.3626C16.2837 18.7992 16.0625 19.2859 15.6259 19.4496L8.96723 21.9466C8.53059 22.1104 8.0439 21.8891 7.88016 21.4525C7.71642 21.0159 7.93765 20.5292 8.37428 20.3654L15.0329 17.8684C15.4696 17.7047 15.9563 17.9259 16.12 18.3626Z" fill="#666666"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12 2.0003C13.5277 1.98478 14.9996 2.57478 16.0937 3.64154C17.1903 4.71064 17.8177 6.17121 17.8382 7.70251L17.8384 7.71384C17.8384 10.154 16.4356 12.4577 15.1588 14.0696C14.5068 14.8928 13.8568 15.5737 13.3701 16.0489C13.1263 16.287 12.9222 16.4747 12.7775 16.6041C12.7052 16.6688 12.6476 16.719 12.6073 16.7538C12.5872 16.7712 12.5713 16.7847 12.5601 16.7942L12.5467 16.8055L12.5427 16.8088L12.5414 16.8099C12.5412 16.8101 12.5405 16.8107 12 16.162C11.4594 16.8107 11.4592 16.8105 11.459 16.8103L11.4572 16.8088L11.4532 16.8055L11.4398 16.7942C11.4286 16.7847 11.4128 16.7712 11.3926 16.7538C11.3523 16.719 11.2948 16.6688 11.2224 16.6041C11.0778 16.4747 10.8736 16.287 10.6298 16.0489C10.1431 15.5737 9.49309 14.8928 8.84109 14.0696C7.56433 12.4577 6.16162 10.154 6.16162 7.71384V7.70251C6.18217 6.1712 6.80967 4.71064 7.90618 3.64154C9.0003 2.57478 10.4722 1.98478 12 2.0003ZM12 16.162L11.459 16.8103C11.7722 17.0713 12.2274 17.0716 12.5405 16.8107L12 16.162ZM12 15.024C12.0597 14.9673 12.1233 14.9061 12.1904 14.8406C12.6401 14.4015 13.2386 13.7742 13.8351 13.0211C15.0541 11.4821 16.1473 9.56412 16.1496 7.71956C16.1336 6.63769 15.6897 5.6061 14.9149 4.85066C14.1387 4.0939 13.0939 3.6761 12.01 3.689H11.9899C10.906 3.6761 9.86123 4.0939 9.08507 4.85066C8.31026 5.6061 7.86634 6.63767 7.85033 7.71954C7.85258 9.56411 8.94587 11.4821 10.1648 13.0211C10.7613 13.7742 11.3598 14.4015 11.8095 14.8406C11.8766 14.9061 11.9403 14.9673 12 15.024Z" fill="#666666"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M8.67077 16.15C9.13709 16.15 9.51512 16.528 9.51512 16.9943V21.156C9.51512 21.6223 9.13709 22.0004 8.67077 22.0004C8.20445 22.0004 7.82642 21.6223 7.82642 21.156V16.9943C7.82642 16.528 8.20445 16.15 8.67077 16.15Z" fill="#666666"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M15.3292 16.15C15.7955 16.15 16.1736 16.528 16.1736 16.9943V18.659C16.1736 19.1253 15.7955 19.5034 15.3292 19.5034C14.8629 19.5034 14.4849 19.1253 14.4849 18.659V16.9943C14.4849 16.528 14.8629 16.15 15.3292 16.15Z" fill="#666666"/>
      </g>
      <defs>
      <clipPath id="clip0_764_21">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  );
};

export const RoutingPlayGroundNavIcon = (props) => (
  <Icon component={RoutingPlayGroundSvg} {...props} />
);
