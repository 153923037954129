import React from 'react';
import withStyles, { WithStylesProps } from 'react-jss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PageData } from 'src/types/page.types';
import GenericViewComponent from './GenericView/GenericView.component';
import { handleViewChange } from 'src/actions/genericPageActions';
import GenericTabsWithSummaryMetric from 'src/components/common/GenericTabsWithSummaryMetric';

const styles = () => ({
    sectionContainer: {
        flex: 1,
        height: '100%',
    },
});

interface IProps extends WithStylesProps<
    ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps> {
        pageId: string;
        sectionIndex: number;
}

const GenericSection = (props: IProps) => {
    const { classes, pageData, sectionIndex, handleViewChange } = props;

    const renderView = (viewId, viewIndex, sectionIndex) => {
        return <GenericViewComponent
            key={viewId}
            viewId={viewId}
            viewIndex={viewIndex}
            sectionIndex={sectionIndex}
            pageId={pageData.id} />;
    };

    const handleViewTabChange = (tabId, sectionIndex) => {
        handleViewChange({
            viewId: tabId,
            pageId: pageData.id,
            sectionIndex,
        });
    };

    React.useEffect(() => {
        if (pageData) {
            const sectionData = pageData.metadata.sectionList[sectionIndex] || [];
            const defaultLandingView = sectionData.find(v => v.isDefault) || sectionData[0];
            if (defaultLandingView) {
                handleViewTabChange(defaultLandingView?.id, sectionIndex);
            }
        }
    }, []);

    return <div className={classes.sectionContainer} style={{
        marginLeft: sectionIndex > 0 ? '5px' : null,
    }}>
        <GenericTabsWithSummaryMetric
            activeTab={pageData.activeViews[sectionIndex]}
            tabs={pageData.getTabData(sectionIndex, renderView)}
            onChange={(tabId) => handleViewTabChange(tabId, sectionIndex)}
        />
    </div>;
};

const mapStateToProps = ({ masterData, genericPageReducer }, ownProps) => {
    const pageId = ownProps.pageId;
    return {
        masterData,
        pageData: genericPageReducer[pageId] as PageData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        handleViewChange,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(
    withStyles(styles, { injectTheme: true })(GenericSection),
);
