import {
  getVehicleAttendance,
  downloadTMSVehicleAttendanceData,
} from '../../api/TMSDashboard/VehicleView';

const viewControl = {
  pageTitle: 'Vehicle Attendance',
  buckets: ['tms_vehicle_attendance'],
  navBar: {
    left: ['single_hub_search', 'date_filter'],
    right: ['search_redirect'],
  },
  globalActions: [],
  dateFilterOptions: [{ key: 'attendance_date', val: 'Date Range' }],
  searchFilterOptions: [],
  fetchData: getVehicleAttendance,
  downloadData: downloadTMSVehicleAttendanceData,
};
const buckets = {
  tms_vehicle_attendance: {
    name: 'Attendance',
    filters: ['reference_number_search'],
  },
};

const bucketColor = {
  tms_vehicle_attendance: '#DA1A83',
};

export default { viewControl, buckets, bucketColor };
