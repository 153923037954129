import Icon from '@ant-design/icons';
import * as React from 'react';

const ShelfManagementSvg = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 27" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>Shelf</title>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Group-11-Copy" fill="currentColor">
                <path d="M0,0 L0,26.4 L24,26.4 L24,25.2583784 L1.16363636,25.2583784 L1.16363636,11.987027 L24,11.987027 L24,10.8454054 L1.16363636,10.8454054 L1.16363636,0 L0,0 Z M15.7090909,10.8697297 L4.07272727,10.8697297 L15.7090909,10.8697297 Z" id="Fill-1"></path>
                <g id="Group-10" transform="translate(2.400000, 1.200000)">
                    <path d="M5.29511817,2.80898131 C5.29511817,2.89904486 5.1981655,2.95828224 5.11440366,2.91935888 L4.3679487,2.4907757 C4.29898992,2.45881308 4.21889221,2.45881308 4.15000672,2.4907757 L3.40355176,2.91935888 C3.31971664,2.95828224 3.22276397,2.89904486 3.22276397,2.80898131 L3.22276397,0.394951402 L5.29511817,0.394951402 L5.29511817,2.80898131 Z M2.19847328e-05,8.26222991 L8.51793344,8.26222991 L8.51793344,0.00635700935 L2.19847328e-05,0.00635700935 L2.19847328e-05,8.26222991 Z" id="Fill-2"></path>
                    <path d="M15.9125203,2.80898131 C15.9125203,2.89904486 15.8155676,2.95828224 15.7317325,2.91935888 L14.9853508,2.4907757 C14.9163921,2.45881308 14.8362211,2.45881308 14.7674089,2.4907757 L14.0209539,2.91935888 C13.9371188,2.95828224 13.8401661,2.89904486 13.8401661,2.80898131 L13.8401661,0.394951402 L15.9125203,0.394951402 L15.9125203,2.80898131 Z M10.6173508,8.26222991 L19.1352623,8.26222991 L19.1352623,0.00635700935 L10.6173508,0.00635700935 L10.6173508,8.26222991 Z" id="Fill-4"></path>
                    <path d="M5.29511817,17.2840336 C5.29511817,17.3740972 5.1981655,17.4333346 5.11440366,17.3944822 L4.3679487,16.965828 C4.29898992,16.9337944 4.21889221,16.9337944 4.15000672,16.965828 L3.40355176,17.3944822 C3.31971664,17.4333346 3.22276397,17.3740972 3.22276397,17.2840336 L3.22276397,14.8699327 L5.29511817,14.8699327 L5.29511817,17.2840336 Z M2.19847328e-05,22.7372822 L8.51793344,22.7372822 L8.51793344,14.4814093 L2.19847328e-05,14.4814093 L2.19847328e-05,22.7372822 Z" id="Fill-6"></path>
                    <path d="M15.9125203,17.2840336 C15.9125203,17.3740972 15.8155676,17.4333346 15.7317325,17.3944822 L14.9853508,16.965828 C14.9163921,16.9337944 14.8362211,16.9337944 14.7674089,16.965828 L14.0209539,17.3944822 C13.9371188,17.4333346 13.8401661,17.3740972 13.8401661,17.2840336 L13.8401661,14.8699327 L15.9125203,14.8699327 L15.9125203,17.2840336 Z M10.6173508,22.7372822 L19.1352623,22.7372822 L19.1352623,14.4814093 L10.6173508,14.4814093 L10.6173508,22.7372822 Z" id="Fill-8"></path>
                </g>
            </g>
        </g>
    </svg>
  );
};

export const ShelfManagementNavIcon = props => (
  <Icon component={ShelfManagementSvg} {...props} />
);
