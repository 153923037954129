import { Userpilot } from 'userpilot';
import { IS_ENV_PRODUCTION, USERPILOT_TOKEN, USERPILOT_BLACKLISTED_ORGS } from '../globals'
import { LocalStorageKeys, LocalStorageService } from 'src/services/localStorage';

export function userpilotIdentify(user_data) {
  const orgId: any = LocalStorageService.getRaw(LocalStorageKeys.__ORGANISATION_ID__);
  if(!IS_ENV_PRODUCTION || user_data.username.slice(0,14) === 'SHIPSY_SUPPORT' || USERPILOT_BLACKLISTED_ORGS.includes(orgId)) return
  if(!orgId) return
  Userpilot.identify(
    user_data.username,
    {
      name: user_data.name ? user_data.name : null,
      email: user_data.email ? user_data.email : null,
      company:  // optional 
      {
        id: orgId, // Company Unique ID
      }
    }
  )
}

export function userpilotReload(user_data){
  const orgId: any = LocalStorageService.getRaw(LocalStorageKeys.__ORGANISATION_ID__);
  if(!IS_ENV_PRODUCTION || user_data.username.slice(0,14) === 'SHIPSY_SUPPORT' || USERPILOT_BLACKLISTED_ORGS.includes(orgId)) return
  Userpilot.reload();
}

export function userpilotInitialize(username){
  const orgId: any = LocalStorageService.getRaw(LocalStorageKeys.__ORGANISATION_ID__);
  if(!username) return
  if(username.slice(0,14) === 'SHIPSY_SUPPORT' || USERPILOT_BLACKLISTED_ORGS.includes(orgId)) return
  Userpilot.initialize(USERPILOT_TOKEN);
}
