import Icon from '@ant-design/icons';
import * as React from 'react';

const AnalyseSvg = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Menu" transform="translate(-24.000000, -535.000000)">
                <g id="Analyse" transform="translate(24.000000, 535.000000)">
                    <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                    <g transform="translate(1.510000, 1.500000)" fill="#666666" fillRule="nonzero">
                        <path d="M5.20636,20.9709006 L0.80471,20.9709006 C0.59129,20.9709006 0.38661,20.8861006 0.23569,20.7352006 C0.08478,20.5843006 8.8817842e-16,20.3796006 8.8817842e-16,20.1662006 L8.8817842e-16,7.84350064 C8.8817842e-16,7.63007064 0.08478,7.42539064 0.23569,7.27448064 C0.38661,7.12357064 0.59129,7.03879064 0.80471,7.03879064 L5.20636,7.03879064 C5.41979,7.03879064 5.62447,7.12357064 5.77538,7.27448064 C5.92629,7.42539064 6.01107,7.63007064 6.01107,7.84350064 L6.01107,20.1662006 C6.01107,20.3796006 5.92629,20.5843006 5.77538,20.7352006 C5.62447,20.8861006 5.41979,20.9709006 5.20636,20.9709006 Z M1.60942,19.3906006 L4.40166,19.3906006 L4.40166,8.72580064 L1.60942,8.72580064 L1.60942,19.3906006 Z" id="Shape"></path>
                        <path d="M12.24498,20.9709006 L7.84328,20.9709006 C7.62986,20.9709006 7.42518,20.8861006 7.27427,20.7352006 C7.12336,20.5843006 7.03857,20.3796006 7.03857,20.1662006 L7.03857,13.1274006 C7.03857,12.9140006 7.12336,12.7093006 7.27427,12.5584006 C7.42518,12.4075006 7.62986,12.3227006 7.84328,12.3227006 L12.24498,12.3227006 C12.45838,12.3227006 12.66308,12.4075006 12.81398,12.5584006 C12.96488,12.7093006 13.04968,12.9140006 13.04968,13.1274006 L13.04968,20.1662006 C13.04968,20.3796006 12.96488,20.5843006 12.81398,20.7352006 C12.66308,20.8861006 12.45838,20.9709006 12.24498,20.9709006 Z M8.64798,19.3906006 L11.44028,19.3906006 L11.44028,13.9612006 L8.64798,13.9612006 L8.64798,19.3906006 Z" id="Shape"></path>
                        <path d="M19.28348,21.0000006 L14.88188,21.0000006 C14.66848,21.0000006 14.46378,20.9152006 14.31288,20.7643006 C14.16188,20.6134006 14.07718,20.4087006 14.07718,20.1953006 L14.07718,16.6468006 C14.07718,16.4334006 14.16188,16.2287006 14.31288,16.0778006 C14.46378,15.9269006 14.66848,15.8421006 14.88188,15.8421006 L19.28348,15.8421006 C19.49698,15.8421006 19.70158,15.9269006 19.85248,16.0778006 C20.00348,16.2287006 20.08818,16.4334006 20.08818,16.6468006 L20.08818,20.1953006 C20.08818,20.4087006 20.00348,20.6134006 19.85248,20.7643006 C19.70158,20.9152006 19.49698,21.0000006 19.28348,21.0000006 Z M15.68658,19.3906006 L18.47878,19.3906006 L18.47878,17.4515006 L15.68658,17.4515006 L15.68658,19.3906006 Z" id="Shape"></path>
                        <path d="M13.12738,10.4127006 C12.09768,10.4127006 11.09108,10.1074006 10.23488,9.53530064 C9.37868,8.96320064 8.71138,8.15010064 8.31733,7.19876064 C7.92328,6.24742064 7.82017,5.20059064 8.02106,4.19066064 C8.22195,3.18072064 8.71778,2.25303064 9.44598,1.52491064 C10.17408,0.796790636 11.10178,0.300930636 12.11168,0.100040636 C13.12158,-0.100849364 14.16848,0.00226063565 15.11978,0.396310636 C16.07108,0.790370636 16.88428,1.45768064 17.45628,2.31387064 C18.02838,3.17005064 18.33378,4.17665064 18.33378,5.20637064 C18.33118,6.58639064 17.78188,7.90916064 16.80598,8.88500064 C15.83018,9.86080064 14.50738,10.4102006 13.12738,10.4127006 Z M13.12738,1.60942064 C12.41598,1.60942064 11.72058,1.82038064 11.12898,2.21561064 C10.53748,2.61085064 10.07648,3.17262064 9.80428,3.82987064 C9.53198,4.48713064 9.46078,5.21036064 9.59958,5.90810064 C9.73838,6.60584064 10.08088,7.24675064 10.58398,7.74979064 C11.08698,8.25284064 11.72788,8.59540064 12.42568,8.73420064 C13.12338,8.87300064 13.84658,8.80180064 14.50388,8.52950064 C15.16118,8.25727064 15.72288,7.79624064 16.11818,7.20472064 C16.51338,6.61321064 16.72438,5.91778064 16.72438,5.20637064 C16.72438,4.73401064 16.63128,4.26628064 16.45058,3.82987064 C16.26978,3.39347064 16.00478,2.99695064 15.67078,2.66294064 C15.33678,2.32893064 14.94028,2.06398064 14.50388,1.88322064 C14.06748,1.70246064 13.59978,1.60942064 13.12738,1.60942064 Z" id="Shape"></path>
                        <path d="M20.16628,13.9321006 C20.05968,13.9316006 19.95418,13.9100006 19.85598,13.8683006 C19.75778,13.8267006 19.66878,13.7660006 19.59428,13.6897006 L15.19258,9.29780064 C15.11748,9.22260064 15.05788,9.13350064 15.01728,9.03530064 C14.97658,8.93720064 14.95568,8.83200064 14.95568,8.72570064 C14.95568,8.61950064 14.97658,8.51430064 15.01728,8.41616064 C15.05788,8.31802064 15.11748,8.22884064 15.19258,8.15372064 C15.34438,8.00201064 15.55008,7.91678064 15.76468,7.91678064 C15.87088,7.91678064 15.97608,7.93770064 16.07418,7.97836064 C16.17238,8.01901064 16.26158,8.07860064 16.33668,8.15372064 L20.73828,12.6039006 C20.88808,12.7546006 20.97218,12.9585006 20.97218,13.1710006 C20.97218,13.3835006 20.88808,13.5874006 20.73828,13.7382006 C20.66048,13.8067006 20.56988,13.8591006 20.47168,13.8923006 C20.37348,13.9256006 20.26968,13.9391006 20.16628,13.9321006 Z" id="Path"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    );
};

export const AnalyseIcon = props => (
  <Icon component={AnalyseSvg} {...props} />
);
