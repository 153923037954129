import {
  fetchManifestRequestList,
  fetchSummaryMetricsForManifest,
} from '../../api/genericConsignmentView';
const defaultRetailStatusFilterOptions = [
  'manifest_submitted',
  'manifest_rejected',
  'manifest_accepted',
  'declaration_submitted',
  'declaration_rejected',
  'declaration_accepted',
  'archive_submitted',
  'archive_rejected',
  'approval_pending',
  'archive_created',
  'pdf_submitted',
  'archive_saved',
  'archive_sent',
  'rejected',
];

const viewControl = {
  pageTitle: 'Manifest & Declaration',
  buckets: ['all', 'pending'],
  navBar: {
    left: [],
    right: [],
  },
  globalActions: [],
  dateFilterOptions: [{ key: 'departureDate', val: 'Flight Date' }],
  searchFilterOptions: [],
  hubSearchOptions: [],
  fetchData: fetchManifestRequestList,
  fetchSummaryMetrics: fetchSummaryMetricsForManifest,
};
const buckets = {
  all: {
    name: 'All',
    hidePagination: false,
    hidePerPageCount: false,
    filters: ['flightStartDate', 'flightEndDate', 'status'],
    status_filter: defaultRetailStatusFilterOptions,
  },
  pending: {
    name: 'Pending',
    hidePagination: false,
    hidePerPageCount: false,
    filters: ['flightStartDate', 'flightEndDate', 'status'],
    status_filter: defaultRetailStatusFilterOptions,
  },
};

const bucketColor = {
  all: '#EB2F96',
  pending: '#722ED1',
};

export default { viewControl, buckets, bucketColor };
