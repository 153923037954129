import * as React from 'react';
import withStyles from 'react-jss';
import { CssType,
  ThemeType} from '../../theming/jssTypes';
import { WarningTwoTone } from '@ant-design/icons';
import { Modal, Table, Button } from 'antd';
import { humanize } from '../../utils/utils';
import lodash from 'lodash';
import { withTranslation } from 'react-i18next';

const styles = (theme: ThemeType): CssType => ({
  failureModal: {
    '& .ant-modal-body': {
      padding: 0,
    },
  },
  downloadRow: {
    padding: '5px',
    display: 'flex',
  },
  downloadButton: {
    margin: 'auto',
  },
  downloadText: {
    color: 'white',
  },
});

class FailureArrayHandler extends React.PureComponent<any, any> {

  state:any = {
    columnNameForTable: [],
  };

  defaultColumns = [
    {
      title: 'Consignment Number',
      dataIndex: 'reference_number',
    },
    {
      title: 'Reason',
      dataIndex: 'message',
    },
  ];

  componentDidMount() {
    const { failureColumns } = this.props;
    this.setState({
      columnNameForTable: failureColumns ? failureColumns : this.defaultColumns,
    });
  }

  generateColumnForTable = () => {
    const { classes, failureArray, t } = this.props;
    const { columnNameForTable } = this.state;
    const columnData: any = [];
    if (failureArray == null || failureArray.length === 0) {
      return columnData;
    }
    const indexList = columnNameForTable;
    indexList.forEach((item) => {
      const itemToPush: any = {};
      itemToPush['title'] = t(item.title);
      itemToPush['dataIndex'] = item.dataIndex;
      itemToPush['key'] = item.dataIndex;
      itemToPush['width'] = 150;
      itemToPush['render'] = (text, record) => {
        return typeof (lodash.get(record, item.dataIndex)) !== 'string' ? text :
          lodash.get(record, item.dataIndex) ? humanize(lodash.get(record, item.dataIndex)) : null;
      };

      columnData.push(itemToPush);
    });

    return columnData;
  };

  handleModalClose = () => {
    this.props.onModalClose();
  };

  render() {
    const { classes, failureArray, isVisible, showDownloadButton,
      errorFileLink, width, t } = this.props;
    return (
      <Modal title={
          <div>
            <WarningTwoTone twoToneColor="#eb2f96" style={{ fontSize: 18 }} /> {t('errors')}
          </div>
        } className={classes.failureModal}
        visible={isVisible}
        onCancel={this.handleModalClose}
        destroyOnClose maskClosable={false}
        width={width || 800} okText="Ok"
        footer={null}
        >
        <div>
          <Table
            pagination={false}
            scroll={{ y: '400px' }}
            columns={this.generateColumnForTable()}
            dataSource={failureArray}
            rowKey={(record: any) => record['row_num']}
          >
          </Table>
        </div>
        {showDownloadButton ? (
          <div className={classes.downloadRow}>
            <Button type="primary" className={classes.downloadButton}>
              <a
                href={errorFileLink}
                className={classes.downloadText}
                target={'_blank'}
              >
                {t('download_error')}
              </a>
            </Button>
          </div>
        ) : null}
      </Modal>
    );
  }
}

const StyledComp = withTranslation('translation')(
  withStyles(styles, { injectTheme: true })(FailureArrayHandler));
export default StyledComp;
