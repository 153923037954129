/* eslint-disable react/no-danger */
import React, { memo } from 'react';
import { createUseStyles } from 'react-jss';
import visualisation from '../ChartVisualisations';
import classNames from 'classnames';
import { Empty } from 'antd';
import { isReportTypeHtmlEditior } from 'src/utils/utils';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { API_STATUS } from 'src/components/common/utils/constant';

const useStyle = createUseStyles({
  chartContainer: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0px',
    overflow: 'auto',
    scrollbarWidth: 'none',
    '& .antv-s2-pagination': {
      zIndex: 1,
      width: 'calc(100% - 20px)',
    },
  },
  contentDisplay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  previewHTML: {
    overflow: 'scroll',
    height: '100%',
  },
});

const ChartPane = (props) => {
  const classes = useStyle();
  const {
    type,
    chartData,
    formData,
    chartStyle,
    apiStatus,
    pageSize,
    visualizeMetaData,
    reportId,
    chartsFromDashboard = false,
    chartConfig = null,
    formatting,
    columnWidth = null,
    dataMartType,
    htmlData,
    reportType,
  } = props;
  const ChartComponent = visualisation[type || 'bar'];
  let chartPadding = ['pivotTable', 'table', 'number'].includes(type)
    ? ''
    : '5px';
  chartPadding = type === 'bar' ? '5px 15px' : chartPadding;
  const enclosingDiv = React.useRef(null as any);

  let width;
  if (enclosingDiv.current) {
    width = enclosingDiv.current.clientWidth;
  }

  if (chartsFromDashboard && isReportTypeHtmlEditior(reportType)) {
    return (
      <div
        className={classes.previewHTML}
        dangerouslySetInnerHTML={{ __html: htmlData }}
      />
    );
  }

  const isSingleBigNumber = () => {
    return type === 'number' && chartData.length === 1;
  };
  return (
    <>
      {chartData.length === 0 && apiStatus === API_STATUS.SUCCESS && (
        <div className={classes.contentDisplay}>
          <p>-</p>
        </div>
      )}
      {apiStatus === API_STATUS.LOADING && (
        <div style={{ height: '100%', width: '100%', padding: '0.5rem' }}>
          <Skeleton height={'95%'} />
        </div>
      )}
      {apiStatus === API_STATUS.SUCCESS && (
        <div
          ref={enclosingDiv}
          style={{
            ...chartStyle,
            padding: chartPadding,
            display: isSingleBigNumber() ? 'flex' : '',
          }}
          className={classNames('charts-container', classes.chartContainer)}
        >
          <ChartComponent
            classes={classes}
            containerWidth={width}
            visualizeMetaData={visualizeMetaData}
            chartData={chartData}
            chartConfig={chartConfig}
            formatting={formatting}
            formData={formData}
            dashboardPageSize={pageSize}
            dashboardColumnWidth={columnWidth}
            reportId={reportId}
            chartsFromDashboard={chartsFromDashboard}
            singleNumber={isSingleBigNumber()}
            dataMartType={dataMartType}
          />
        </div>
      )}
      {apiStatus === API_STATUS.ERROR && (
        <div
          ref={enclosingDiv}
          style={{
            ...chartStyle,
            padding: chartPadding,
            display: isSingleBigNumber() ? 'flex' : '',
          }}
          className={classNames('charts-container', classes.chartContainer)}
        >
          <div className={classes.contentDisplay}>Waiting to fetch ... </div>
        </div>
      )}
    </>
  );
};

export default memo(ChartPane);
