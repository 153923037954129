import Icon from '@ant-design/icons';
import * as React from 'react';

const RiderPayoutSvg = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Setup_Tooltip" transform="translate(-16.000000, -266.000000)">
                <g id="Group-6" transform="translate(0.000000, 58.000000)">
                    <g id="Rider_Payout" transform="translate(16.000000, 208.000000)">
                        <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                        <g transform="translate(1.500000, 1.500000)" fill="#666666">
                            <path d="M10.5,10.9145012 C10.9445,10.9145012 11.3047,11.2748012 11.3047,11.7192012 L11.3047,14.1043012 C11.3047,14.5487012 10.9445,14.9090012 10.5,14.9090012 C10.0556,14.9090012 9.6953,14.5487012 9.6953,14.1043012 L9.6953,11.7192012 C9.6953,11.2748012 10.0556,10.9145012 10.5,10.9145012 Z" id="Path"></path>
                            <path d="M10.5,12.9796012 C10.9445,12.9796012 11.3047,13.3399012 11.3047,13.7844012 L11.3047,16.6348012 C11.3047,17.0792012 10.9445,17.4395012 10.5,17.4395012 C10.0556,17.4395012 9.6953,17.0792012 9.6953,16.6348012 L9.6953,13.7844012 C9.6953,13.3399012 10.0556,12.9796012 10.5,12.9796012 Z" id="Path"></path>
                            <path d="M18.4307,13.2123012 C18.8751,13.2123012 19.2354,13.5726012 19.2354,14.0170012 L19.2354,16.6348012 C19.2354,17.0792012 18.8751,17.4395012 18.4307,17.4395012 C17.9863,17.4395012 17.626,17.0792012 17.626,16.6348012 L17.626,14.0170012 C17.626,13.5726012 17.9863,13.2123012 18.4307,13.2123012 Z" id="Path"></path>
                            <path d="M2.56912,13.2123012 C3.01355,13.2123012 3.37383,13.5726012 3.37383,14.0170012 L3.37383,16.6348012 C3.37383,17.0792012 3.01355,17.4395012 2.56912,17.4395012 C2.12469,17.4395012 1.7644,17.0792012 1.7644,16.6348012 L1.7644,14.0170012 C1.7644,13.5726012 2.12469,13.2123012 2.56912,13.2123012 Z" id="Path"></path>
                            <path d="M1.7644,14.0170012 C1.7644,13.5726012 2.12469,13.2123012 2.56912,13.2123012 L18.4307,13.2123012 C18.8751,13.2123012 19.2354,13.5726012 19.2354,14.0170012 C19.2354,14.4615012 18.8751,14.8217012 18.4307,14.8217012 L2.56912,14.8217012 C2.12469,14.8217012 1.7644,14.4615012 1.7644,14.0170012 Z" id="Path"></path>
                            <path d="M15.8616,16.6542012 C15.8616,16.2097012 16.2219,15.8495012 16.6663,15.8495012 L20.1954,15.8495012 C20.6398,15.8495012 21.0001,16.2097012 21.0001,16.6542012 L21.0001,20.1833012 C21.0001,20.6277012 20.6398,20.9880012 20.1954,20.9880012 L16.6663,20.9880012 C16.2219,20.9880012 15.8616,20.6277012 15.8616,20.1833012 L15.8616,16.6542012 Z M17.471,17.4589012 L17.471,19.3786012 L19.3907,19.3786012 L19.3907,17.4589012 L17.471,17.4589012 Z" id="Shape"></path>
                            <path d="M7.93066,16.6542012 C7.93066,16.2097012 8.29095,15.8495012 8.7354,15.8495012 L12.2645,15.8495012 C12.7089,15.8495012 13.0692,16.2097012 13.0692,16.6542012 L13.0692,20.1833012 C13.0692,20.6277012 12.7089,20.9880012 12.2645,20.9880012 L8.7354,20.9880012 C8.29095,20.9880012 7.93066,20.6277012 7.93066,20.1833012 L7.93066,16.6542012 Z M9.5401,17.4589012 L9.5401,19.3786012 L11.4598,19.3786012 L11.4598,17.4589012 L9.5401,17.4589012 Z" id="Shape"></path>
                            <path d="M0,16.6542012 C0,16.2097012 0.36028,15.8495012 0.80471,15.8495012 L4.33382,15.8495012 C4.77825,15.8495012 5.13854,16.2097012 5.13854,16.6542012 L5.13854,20.1833012 C5.13854,20.6277012 4.77825,20.9880012 4.33382,20.9880012 L0.80471,20.9880012 C0.36028,20.9880012 0,20.6277012 0,20.1833012 L0,16.6542012 Z M1.60943,17.4589012 L1.60943,19.3786012 L3.52911,19.3786012 L3.52911,17.4589012 L1.60943,17.4589012 Z" id="Shape"></path>
                            <path d="M7.61889,0.724841168 C8.8597,0.0583511684 10.2932,-0.157348832 11.6752,0.114481168 C13.0572,0.386321168 14.3022,1.12888117 15.1981,2.21566117 C16.094,3.30244117 16.5855,4.66622117 16.5887171,6.07469117 C16.5906,6.87496117 16.4346,7.66774117 16.1298,8.40769117 C15.825,9.14760117 15.3773,9.82020117 14.8124,10.3870012 C13.8181,11.3846012 12.5087,12.0066012 11.1072,12.1470012 C9.7058,12.2875012 8.29895,11.9377012 7.12645,11.1573012 C5.95395,10.3769012 5.08827,9.21410117 4.67688,7.86708117 C4.2655,6.52002117 4.33385,5.07201117 4.87029,3.76969117 C5.40674,2.46738117 6.37809,1.39133117 7.61889,0.724841168 Z M11.3646,1.69365117 C10.3479,1.49367117 9.2933,1.65236117 8.38048,2.14267117 C7.46766,2.63299117 6.75306,3.42461117 6.35842,4.38268117 C5.96377,5.34075117 5.91349,6.40601117 6.21613,7.39699117 C6.51878,8.38798117 7.15563,9.24340117 8.0182,9.81750117 C8.8808,10.3916012 9.9157,10.6489012 10.9467,10.5456012 C11.9777,10.4423012 12.941,9.98470117 13.6725,9.25090117 C14.0881,8.83390117 14.4175,8.33906117 14.6417,7.79470117 C14.8659,7.25035117 14.9807,6.66712117 14.9793127,6.07839117 C14.9769,5.04223117 14.6154,4.03893117 13.9563,3.23942117 C13.2972,2.43991117 12.3812,1.89363117 11.3646,1.69365117 Z" id="Shape"></path>
                            <path d="M9.7338,4.46847117 C9.568,4.59157117 9.5401,4.70098117 9.5401,4.75796117 C9.5401,4.81493117 9.568,4.92434117 9.7338,5.04744117 C9.901,5.17161117 10.1697,5.27181117 10.4999,5.27181117 C10.9444,5.27181117 11.3046,5.63209117 11.3046,6.07652117 C11.3046,6.52096117 10.9444,6.88124117 10.4999,6.88124117 C9.8606,6.88124117 9.247,6.69057117 8.7744,6.33967117 C8.3003,5.98770117 7.93066,5.43783117 7.93066,4.75796117 C7.93066,4.07808117 8.3003,3.52821117 8.7744,3.17624117 C9.247,2.82534117 9.8606,2.63467117 10.4999,2.63467117 L12.2645,2.63467117 C12.7089,2.63467117 13.0692,2.99496117 13.0692,3.43939117 C13.0692,3.88382117 12.7089,4.24410117 12.2645,4.24410117 L10.4999,4.24410117 C10.1697,4.24410117 9.901,4.34430117 9.7338,4.46847117 Z" id="Path"></path>
                            <path d="M9.6954,6.07653117 C9.6954,5.63210117 10.0557,5.27182117 10.5001,5.27182117 C11.1425,5.27182117 11.7569,5.46666117 12.2288,5.82058117 C12.7013,6.17495117 13.0694,6.72641117 13.0694,7.40480117 C13.0694,8.08266117 12.7041,8.63150117 12.2273,8.98310117 C11.7536,9.33250117 11.1398,9.51840117 10.5001,9.51840117 L8.7259,9.51840117 C8.28143,9.51840117 7.92114,9.15810117 7.92114,8.71370117 C7.92114,8.26924117 8.28143,7.90896117 8.7259,7.90896117 L10.5001,7.90896117 C10.8396,7.90896117 11.1081,7.80879117 11.2719,7.68796117 C11.4326,7.56942117 11.4599,7.46378117 11.4599,7.40480117 C11.4599,7.34634117 11.4305,7.23367117 11.2631,7.10812117 C11.0951,6.98212117 10.8273,6.88125117 10.5001,6.88125117 C10.0557,6.88125117 9.6954,6.52097117 9.6954,6.07653117 Z" id="Path"></path>
                            <path d="M10.5,0.928311168 C10.9445,0.928311168 11.3047,1.28860117 11.3047,1.73303117 L11.3047,3.17764117 C11.3047,3.62207117 10.9445,3.98235117 10.5,3.98235117 C10.0556,3.98235117 9.6953,3.62207117 9.6953,3.17764117 L9.6953,1.73303117 C9.6953,1.28860117 10.0556,0.928311168 10.5,0.928311168 Z" id="Path"></path>
                            <path d="M10.5,8.32584117 C10.9445,8.32584117 11.3047,8.68610117 11.3047,9.13060117 L11.3047,10.5461012 C11.3047,10.9905012 10.9445,11.3508012 10.5,11.3508012 C10.0556,11.3508012 9.6953,10.9905012 9.6953,10.5461012 L9.6953,9.13060117 C9.6953,8.68610117 10.0556,8.32584117 10.5,8.32584117 Z" id="Path"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    );
};

export const RiderPayoutIcon = props => (
  <Icon component={RiderPayoutSvg} {...props} />
);
