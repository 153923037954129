import { getBookingMastersTableData } from '../../api/BookingMasters';

const checkPermissions = (masterData, bucket) => {
  switch (bucket) {
    case 'tat':
      return masterData.ops_dashboard_config?.parts_to_show
        ?.setup_permission_obj?.booking_master_v2?.tat?.update;
    case 'serviceability':
      return masterData.ops_dashboard_config?.parts_to_show
        ?.setup_permission_obj?.booking_master_v2?.serviceability?.update;
    case 'destinationType':
      return masterData.ops_dashboard_config?.parts_to_show
        ?.setup_permission_obj?.booking_master_v2?.destination?.update;
    default:
      return true;
  }
};

const viewControl = {
  pageTitle: 'Setup',
  buckets: ['serviceability', 'destinationType', 'tat'],
  globalActions: [],
  fetchData: getBookingMastersTableData,
  filters: [],
  actions: [
    {
      key: 'update',
      name: 'Update',
      isAllowed: (masterData, bucket) => checkPermissions(masterData, bucket),
    },
    {
      key: 'add',
      name: 'Add New',
      isAllowed: (masterData, bucket) => checkPermissions(masterData, bucket),
    },
    {
      key: 'pagination',
      name: 'Pagination',
    },
    {
      key: 'download',
      name: 'Download',
    },
    {
      key: 'reload',
      name: 'Reload',
    },
  ],
};
const buckets = {
  serviceability: {
    name: 'Serviceability Master',
    filters: [],
    actions: [],
  },
  destinationType: {
    name: 'Destination Type Master',
    filters: [],
    actions: [],
  },
  tat: {
    name: 'TAT Master',
    filters: [],
    actions: [],
  },
};

const bucketColor = {};

export default { viewControl, buckets, bucketColor };
