import * as React from 'react';
import withStyles from 'react-jss';
import '@ant-design/compatible/assets/index.css';
import { styles } from './ChatComponentStyle';
import { Form, Select } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import ImageUpload from '@components/pages/TMSDashboard/Setup/ImageUpload';

const AttachFiles = (props: any) => {
  const FileUploadWithFileType = (props: any) => {
    const { onChange, value = {}, key } = props;
    const onChangeFileType = (fileType) => {
      onChange({ ...value, fileType });
    };

    const [filesList, setFilesList] = React.useState({});
    const [docUrlsList, setDocUrlsList] = React.useState({});
    const [fieldValues, setFieldValues] = React.useState([]);

    React.useEffect(() => {
      if (filesList) {
        const files = filesList?.chat_document || [];
        const url = docUrlsList?.chat_document;
        onChange({
          ...value,
          name: files[0]?.name,
          size: files[0]?.size,
          contentType: files[0]?.type,
          type: files[0]?.name?.split('.')[1],
          url,
          id: files[0]?.uid,
        });
      }
    }, [filesList, docUrlsList]);

    return (
      <div style={{ display: 'flex', width: '90%' }}>
        <div style={{ width: '50%', marginRight: '20px' }}>
          <Select
            placeholder="File Type"
            onChange={onChangeFileType}
            style={{ flexGrow: 1 }}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            value={value.fileType}
            options={props.fileTypeOptions}
          />
        </div>
        {value.fileType && (
          <div style={{ width: '50%' }}>
            <ImageUpload
              documentId={'chat_document'}
              filesList={filesList}
              setFilesList={setFilesList}
              setDocUrlsList={setDocUrlsList}
              docUrlsList={docUrlsList}
              fieldValues={fieldValues}
              setFieldValues={setFieldValues}
              documentType={'chat_document'}
              hideUploadButton={true}
              restrictFileSize
              fileSizeLimit={3}
              {...props}
              viewConfig={{
                uploadProps: {
                  accept:
                    '.xlsx, .xls, .csv, .pdf, .png, .jpeg, .docx, .doc, .jpg',
                },
              }}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <div style={{ marginBottom: '5px', fontWeight: 600, fontSize: '12px' }}>
        File Type
      </div>
      <Form.List name="attachments" initialValue={[{ key: 0, name: 0 }]}>
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '10px',
                }}
              >
                <Form.Item
                  style={{
                    marginBottom: '0px',
                    flexGrow: 1,
                    display: 'flex',
                  }}
                  required={false}
                  key={index}
                  name={[field.name, 'file']}
                >
                  <FileUploadWithFileType {...props}/>
                </Form.Item>
                {fields.length > 1 ? (
                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                  />
                ) : null}
              </div>
            ))}
            <Form.Item>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
    </div>
  );
};

export default withStyles(styles, {
  injectTheme: true,
})(React.memo(AttachFiles, () => { return true; }));
