import Icon from '@ant-design/icons';
import * as React from 'react';

const ApiPlaygroundSvg = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Menu" transform="translate(-24.000000, -391.000000)">
              <g id="API_Playground" transform="translate(24.000000, 391.000000)">
                  <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                  <g transform="translate(1.500000, 2.500000)" fill="#666666">
                      <path d="M1.6861,1.60942 C1.64375,1.60942 1.60942,1.64375 1.60942,1.6861 L1.60942,17.55108 C1.60942,17.59348 1.64375,17.62778 1.6861,17.62778 L19.3139,17.62778 C19.3563,17.62778 19.3906,17.59348 19.3906,17.55108 L19.3906,1.6861 C19.3906,1.64375 19.3563,1.60942 19.3139,1.60942 L1.6861,1.60942 Z M0,1.6861 C0,0.7549 0.75489,0 1.6861,0 L19.3139,0 C20.2451,0 21,0.7549 21,1.6861 L21,17.55108 C21,18.48238 20.2451,19.23718 19.3139,19.23718 L1.6861,19.23718 C0.75489,19.23718 0,18.48238 0,17.55108 L0,1.6861 Z" id="Shape"></path>
                      <path d="M0,4.33029 C0,3.88586 0.36028,3.52558 0.80471,3.52558 L20.1953,3.52558 C20.6397,3.52558 21,3.88586 21,4.33029 C21,4.77472 20.6397,5.135 20.1953,5.135 L0.80471,5.135 C0.36028,5.135 0,4.77472 0,4.33029 Z" id="Path"></path>
                      <path d="M9.3062,8.16818 C9.6205,8.48248 9.6205,8.99198 9.3062,9.30628 L7.23107,11.38138 L9.3062,13.45658 C9.6205,13.77078 9.6205,14.28038 9.3062,14.59458 C8.992,14.90888 8.48245,14.90888 8.16819,14.59458 L5.52402,11.95038 C5.20977,11.63618 5.20977,11.12668 5.52402,10.81238 L8.16819,8.16818 C8.48245,7.85398 8.992,7.85398 9.3062,8.16818 Z" id="Path"></path>
                      <path d="M11.6937,8.16818 C12.008,7.85398 12.5175,7.85398 12.8317,8.16818 L15.4759,10.81238 C15.7902,11.12668 15.7902,11.63618 15.4759,11.95038 L12.8317,14.59458 C12.5175,14.90888 12.008,14.90888 11.6937,14.59458 C11.3794,14.28038 11.3794,13.77078 11.6937,13.45658 L13.7689,11.38138 L11.6937,9.30628 C11.3794,8.99198 11.3794,8.48248 11.6937,8.16818 Z" id="Path"></path>
                  </g>
              </g>
          </g>
      </g>
    </svg>
  );
};

export const ApiPlaygroundNavIcon = (props) => (
  <Icon component={ApiPlaygroundSvg} {...props} />
);
