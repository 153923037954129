import * as React from 'react';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '../../../theming/jssTypes';
import { Modal, Upload, message, Row, Col, Form, InputNumber,Input, Select, Button, Tooltip, DatePicker } from 'antd';
import { Spin } from 'antd';
import {
  CloseOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { getImageURL, markDelivered } from '../../../api/dashboard';
import * as moment from 'moment';
import { putImageS3 } from '../../../api/genericTransactions';
import { fetchOne } from '../../../api/genericConsignmentView';

import FailureArrayHandler from '../../common/FailureArrayHandler';
import * as lodash from 'lodash';

const uploadIcon = require('../../../assets/images/upload.svg');
const Dragger = Upload.Dragger;
const styles = (theme: ThemeType): CssType => ({
  removeIconStyle: {
    '& .ant-upload-list-item .anticon-close': {
      color: 'rgb(237 56 51)',
      top: '-2px',
    },
  },
});

const PodUploadModal = (props: any) => {
  const {
    classes,
    isVisible,
    onModalClose,
    referenceNumber,
    handleMarkDeliverySubmit,
    cnData = {},
    doNotShowSuccessMsg = false,
    isEditMode = false,
    mandatoryPaymentDetails = false,
    allowPartialCod = false,
    collectionModeOptions = [],
    showInputTimestampForDelivery,
    isPODUploadOptional,
  } = props;
  const [ deliveryTimeStamp, setdeliveryTimeStamp ] = React.useState('');
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [fileList, setFileList] = React.useState([] as UploadFile[]);
  const [isErrorModalVisible, setIsErrorModalVisible] = React.useState(false);
  const [failureArray, setFailureArray] = React.useState([]);
  const addButtonRef = React.useRef();
  const [form] = Form.useForm();
  const [cnDataForDetail, setCnDataForDetail] = React.useState({});
  const [amountToBeCollected, setAmountToBeCollected] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);

  type CustomerRequestArgs = {
    onSuccess?: any;
    onError?: any;
    file?: any;
  };

  React.useEffect(() => {
    getAddPaymentDetailButtonClick();
    getConsignmentDetails();
    console.log("collectionModeOptions: ",collectionModeOptions)
  }, []);

  async function getConsignmentDetails(){
    setIsLoading(true);
    const body = {
      reference_number: referenceNumber
    };
    const response = await fetchOne(referenceNumber);
    setIsLoading(false);
    if (response.isSuccess) {
      const {
        data,
      } = response;

      const totalAmount = (
          data['cod_amount'] +
          lodash.get(data, 'extra_details.duty_charges', 0) +
          lodash.get(data, 'extra_details.vat', 0)
      ) || 0;
      setCnDataForDetail(data);
      setAmountToBeCollected(totalAmount);
    } else {
      message.warning(response.errorMessage);
      handleModalClose();
    }

  }

  function getAddPaymentDetailButtonClick() {
    setTimeout(() => {
      const shouldSelfClick = !!props.cnData;
      if (addButtonRef && addButtonRef?.current && !shouldSelfClick) {
        (addButtonRef?.current as any)?.click();
      }
      populateFormOnEdit();
    }, 100);
  }

  function populateFormOnEdit() {
    const data = props.cnData;
    if (!data) return;

    form.setFieldsValue({
      paymentDetails: lodash.get(data, 'payment_details', []),
    });
  }

  const customRequest = (args: CustomerRequestArgs) => {
    const { onSuccess, file } = args;
    setTimeout(() => {
      onSuccess(null, file);
    }, 100);
  };

  const uploadProps = {
    accept: '.png, .jpeg, .pdf',
    name: 'file',
    multiple: false,
    customRequest,
    showUploadList: {
      showPreviewIcon: false,
      removeIcon: <CloseOutlined />,
    },
  };

  const handleModalClose = () => {
    setIsErrorModalVisible(false);
    onModalClose(false);
  };

  const displaySuccessModal = () => {
    Modal.success({
      title: 'Hurray!',
      content: `Successfully marked CN# ${referenceNumber} as delivered`,
    });
  };

  const disabledDate = (current) => {
    return current && current > moment();
  };

  const handledeliveryTimeStampChange = (date) => {
    if(date){
      const dateMoment = moment(date).format('YYYY-MM-DD HH:mm:ss');
      setdeliveryTimeStamp(dateMoment);
    }
  };

  const handleSubmit = async () => {
    if (mandatoryPaymentDetails && getTotalAmount() <= 0 && amountToBeCollected > 0) {
      message.error('Please add payment details');
      return;
    }

    setConfirmLoading(true);

    let imageUrl = '';
    if(fileList.length){
      const fileItem = fileList[0];
      const bodyGetUrl = {
        status: 'delivered',
        pocContentType: fileItem.type?.split('/')?.[1],
      };
      const resGetUrl = await getImageURL(bodyGetUrl);
      const signedImageUrl = resGetUrl?.data?.pocSignedUrl || '';
      imageUrl = resGetUrl?.data?.pocImageUrl || '';
      if (!resGetUrl.isSuccess) {
        message.error(resGetUrl.errorMessage);
        setConfirmLoading(false);
        return;
      }
      if (signedImageUrl) {
        const file = new Blob([fileItem.originFileObj as BlobPart], {
          type: fileItem.type,
        });
        const res: any = await putImageS3(signedImageUrl, file, fileItem.type);
        if (!res.isSuccess) {
          message.error('Could not upload file');
          setConfirmLoading(false);
          return;
        }
      }
    } else {
      imageUrl = cnData.poc_image_url;
    }

    const paymentDetailsArray = form.getFieldValue('paymentDetails').filter((item) => !!item);
    const body = {
      consignments: [
        {
          reference_number: referenceNumber,
          poc_image_url: imageUrl,
          payment_details: paymentDetailsArray || [],
          delivery_timestamp: deliveryTimeStamp
        },
      ],
    };
    const response = await handleMarkDeliverySubmit(body);
    setConfirmLoading(false);
    if (response.isSuccess) {
      if (response.data?.failures?.length) {
        setFailureArray(response.data.failures);
        setIsErrorModalVisible(true);
      } else {
        if (!doNotShowSuccessMsg) {
          displaySuccessModal();
        }
        onModalClose(true);
      }
    } else {
      message.error(response.errorMessage);
      handleModalClose();
    }
    setConfirmLoading(false);
  };

  const handleUploadChange = async (info: UploadChangeParam) => {
    const files = info.fileList;
    const filesToShow = files.length ? files.slice(-1) : [];
    setFileList(filesToShow);
  };

  function getTotalAmount() {
    const allPaymentFieldsValues = form.getFieldValue('paymentDetails') || [];

    return allPaymentFieldsValues.reduce((acc, curVal) => {
      acc += curVal && curVal.cod_amount ? curVal.cod_amount : 0;
      return acc;
    }, 0);
  }

  const shouldUpdateTotalAmount = (oldState, newState) => {
    const oldPaymentValues = oldState['paymentDetails'] || [];
    const newPaymentValues = newState['paymentDetails'] || [];

    const oldPaymentValuesSize = oldPaymentValues.length;
    const newPaymentValuesSize = newPaymentValues.length;

    if (oldPaymentValuesSize !== newPaymentValuesSize) return true;

    let diffValue = false;
    let i = 0;

    while (i < oldPaymentValuesSize) {
      if (oldPaymentValues[i] !== newPaymentValues[i]) {
        diffValue = true;
        break;
      }
      i++;
    }

    return diffValue;
  };

  const isModeCash = (field) => {
    const val = form.getFieldValue('paymentDetails')[field.name];
    return val?.cod_collection_mode === 'cash';
  };

  const renderPaymentDetails = () => {
    return (
      <>
        <Form form={form}>
          <Row>
            <Col span={24}>Payment Details:</Col>
          </Row>
          <Form.List name="paymentDetails">
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map((field, idx) => (
                    <Row key={field.name}>
                      <Col span={6}>
                        <Form.Item
                          name={[field.name, 'cod_collection_mode']}
                          fieldKey={[field.fieldKey, 'cod_collection_mode']}
                        >
                          <Select
                            placeholder="Mode"
                            options={collectionModeOptions}
                            onChange={(value) => {
                              const val = form.getFieldValue('paymentDetails');
                              val[field.name] = {
                                ...val[field.name],
                                cod_collection_mode: value,
                                transaction_id: '',
                              }
                              form.setFieldsValue({
                                paymentDetails: val,
                              });
                            }}
                          ></Select>
                        </Form.Item>
                      </Col>
                      <Col span={6} offset={1}>
                        <Form.Item
                          name={[field.name, 'cod_amount']}
                          fieldKey={[field.fieldKey, 'cod_amount']}
                        >
                          <InputNumber min={0} step="0.00" placeholder="Amount" />
                        </Form.Item>
                      </Col>
                      <Col span={8} offset={1}>
                        <Form.Item
                          name={[field.name, 'transaction_id']}
                          fieldKey={[field.fieldKey, 'transaction_id']}
                          shouldUpdate={() => true}
                        >
                          <Input 
                            placeholder="Transaction id" 
                            disabled={isModeCash(field)}
                          />
                        </Form.Item>
                      </Col>
                      {fields.length > 1 ? (
                        <Col span={1} offset={1}>
                          <Tooltip placement="right" title={'Remove'}>
                            <Button
                              type="link"
                              onClick={() => remove(field.name)}
                              icon={<MinusCircleOutlined />}
                            />
                          </Tooltip>
                        </Col>
                      ) : null}
                    </Row>
                  ))}

                  {((!allowPartialCod && fields.length < 1) || (allowPartialCod && fields.length < 3)) && (
                    <Row>
                      <Col>
                        <Form.Item>
                          <Button
                            type="link"
                            ref={addButtonRef}
                            onClick={() => add()}
                          >
                            <PlusOutlined /> Add Entry
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </>
              );
            }}
          </Form.List>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) =>
              shouldUpdateTotalAmount(prevValues, curValues)
            }
          >
            {() => (
              <Row>
                <Col span={24}>COD Amount: {amountToBeCollected}</Col>
              </Row>
            )}
          </Form.Item>
        </Form>
      </>
    );
  };

  const shouldButtonBeDisabled = () => {
    //If PODUpload is optinal then don't check for fileList.length
    return (
      !isPODUploadOptional && 
      (
        !fileList.length ||
        !(fileList[0].status === 'done')
      ) || confirmLoading
    );
  };

  return (
    <Modal
      confirmLoading={confirmLoading}
      width={400}
      title={'Confirm Action'}
      visible={isVisible}
      maskClosable={false}
      okText="Submit"
      onOk={handleSubmit}
      okButtonProps={{
        disabled: shouldButtonBeDisabled()
      }}
      onCancel={handleModalClose}
      className={classes.removeIconStyle}
    >
      <div>
        Please upload POD to mark the selected consignment as DELIVERED
        <br />
        <br />
      </div>
      <Dragger
        fileList={fileList}
        style={{ padding: '16px' }}
        onChange={handleUploadChange}
        {...uploadProps}
      >
        <p
          style={{ marginBottom: 20, fontSize: '14px' }}
          className="ant-upload-hint"
        ></p>
        <p className="ant-upload-drag-icon">
          <img style={{ height: '3em' }} src={uploadIcon} />
        </p>
        <p
          style={{
            color: '#525252',
            marginBottom: 30,
            fontSize: '14px',
            fontWeight: 400,
          }}
        >
          <b>
            Drag and drop to upload <br /> or
            <span style={{ color: '#457CA9' }}> browse</span> to choose file
          </b>
        </p>
      </Dragger>
      {!fileList.length && cnData.poc_image_url ? (
        <div>Already Uploded</div>
      ) : null}
      <br />
      {isLoading && <Spin />}
      {renderPaymentDetails()}
      {showInputTimestampForDelivery && (
        <div style={{marginTop: '15px', alignItems: 'center'}}>
          <Row align="middle">
            Delivery Timestamp:
            <DatePicker
              format="YYYY-MM-DD HH:mm:ss"
              showTime
              disabledDate={disabledDate}
              onChange={handledeliveryTimeStampChange}
            />
          </Row>          
        </div>
      )}
      {isErrorModalVisible && (
        <FailureArrayHandler
          failureArray={failureArray}
          isVisible={isErrorModalVisible}
          onModalClose={handleModalClose}
        />
      )}
    </Modal>
  );
};

export default withStyles(styles, { injectTheme: true })(PodUploadModal);
