import { downloadAllocationRules } from 'src/api/CarrierPartner';
import { getBookingMastersTableData } from '../../api/BookingMasters';
const viewControl = {
  pageTitle: 'Setup',
  buckets: ['routing'],
  globalActions: [],
  fetchData: getBookingMastersTableData,
  downloadData: downloadAllocationRules,
  redirectToDownloadsPageOnDownload: true,
  filters: [],
  actions: [
    {
      key: 'update',
      name: 'Update',
      isAllowed: (masterData) =>
        masterData.ops_dashboard_config?.parts_to_show?.setup_permission_obj
          ?.transport?.routing?.update,
    },
    {
      key: 'add',
      name: 'Add New',
      isAllowed: (masterData) =>
        masterData.ops_dashboard_config?.parts_to_show?.setup_permission_obj
          ?.transport?.routing?.update,
    },
    {
      key: 'pagination',
      name: 'Pagination',
    },
    {
      key: 'download',
      name: 'Download',
    },
    {
      key: 'reload',
      name: 'Reload',
    },
  ],
  bucketFiltersToResetOnPageLoad: {
    property_currentHubId: undefined,
    property_destinationHubId: undefined,
    next_hub: undefined,
    property_serviceType: undefined,
  },
  getAllEnabledDisabledHubsForHubFilters: true,
};
const buckets = {
  routing: {
    name: 'Routing',
    filters: [],
    actions: [],
  },
};

const bucketColor = {};

export default { viewControl, buckets, bucketColor };
