import Icon from '@ant-design/icons';
import * as React from 'react';

const HelpdeskNotificationSvg = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Menu" transform="translate(-24.000000, -439.000000)">
              <g id="Helpdesk_Notification" transform="translate(24.000000, 439.000000)">
                  <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                  <g id="Helpdesk_Notifications" transform="translate(2.000000, 0.600000)" fill="#666666">
                      <path d="M2.20629,0 L10.4351,0 C11.0202,0.00191 11.5807,0.235684 11.9938,0.6501 C12.4069,1.06452 12.6388,1.62578 12.6388,2.2109 L12.6388,4.93917 C12.6388,5.38014 12.2813,5.73762 11.8404,5.73762 C11.3994,5.73762 11.0419,5.38014 11.0419,4.93917 L11.0419,2.21089 C11.0419,2.04839 10.9775,1.89252 10.8628,1.77743 C10.7483,1.66259 10.5931,1.5977 10.431,1.5969 L2.20786,1.5969 C2.04574,1.5977 1.8905,1.66259 1.77603,1.77743 C1.66131,1.89252 1.5969,2.04839 1.5969,2.21089 L1.5969,20.5497 C1.5969,20.7122 1.66131,20.8681 1.77603,20.9832 C1.89052,21.098 2.04581,21.1629 2.20798,21.1637 L10.4308,21.1637 C10.593,21.1629 10.7483,21.098 10.8628,20.9832 C10.9775,20.8681 11.0419,20.7122 11.0419,20.5497 L11.0419,16.4412 C11.0419,16.0002 11.3994,15.6427 11.8404,15.6427 C12.2813,15.6427 12.6388,16.0002 12.6388,16.4412 L12.6388,20.5497 C12.6388,21.1348 12.4069,21.6961 11.9938,22.1105 C11.5807,22.5249 11.0202,22.7587 10.4351,22.7606 L10.4325,22.7606 L2.20629,22.7606 L2.20369,22.7606 C1.61858,22.7587 1.05807,22.5249 0.64501,22.1105 C0.23194,21.6961 0,21.1348 0,20.5497 L0,2.2109 C0,2.2109 0,2.2109 0,2.2109 C0,1.62578 0.23194,1.06452 0.64501,0.6501 C1.05807,0.235684 1.61858,0.00191 2.20369,0 L2.20629,0 Z" id="Path"></path>
                      <path d="M4.14087,3.55895 C4.14087,3.11798 4.49835,2.7605 4.93932,2.7605 L7.6998,2.7605 C8.1408,2.7605 8.4982,3.11798 8.4982,3.55895 C8.4982,3.99992 8.1408,4.35739 7.6998,4.35739 L4.93932,4.35739 C4.49835,4.35739 4.14087,3.99992 4.14087,3.55895 Z" id="Path"></path>
                      <path d="M6.3197,19.2017 C6.57379,19.2017 6.77978,18.9957 6.77978,18.7416 C6.77978,18.4875 6.57379,18.2815 6.3197,18.2815 C6.0656,18.2815 5.85962,18.4875 5.85962,18.7416 C5.85962,18.9957 6.0656,19.2017 6.3197,19.2017 Z" id="Path" fillRule="nonzero"></path>
                      <path d="M6.31981,19.08 C6.50668,19.08 6.65818,18.9285 6.65818,18.7416 C6.65818,18.5547 6.50668,18.4032 6.31981,18.4032 C6.13293,18.4032 5.98144,18.5547 5.98144,18.7416 C5.98144,18.9285 6.13293,19.08 6.31981,19.08 Z M5.06128,18.7416 C5.06128,18.0465 5.62474,17.4831 6.31981,17.4831 C7.01487,17.4831 7.57834,18.0465 7.57834,18.7416 C7.57834,19.4367 7.01487,20.0001 6.31981,20.0001 C5.62474,20.0001 5.06128,19.4367 5.06128,18.7416 Z" id="Shape"></path>
                      <path d="M11.4191,13.5173 C11.7634,13.7928 11.8192,14.2952 11.5437,14.6395 L9.7034,16.94 C9.4916,17.2047 9.1356,17.3068 8.8157,17.1946 C8.4957,17.0824 8.2815,16.7803 8.2815,16.4412 L8.2815,14.1408 C8.2815,13.6998 8.639,13.3422766 9.0799,13.3422766 C9.5209,13.3422766 9.8784,13.6998 9.8784,14.1408 L9.8784,14.165 L10.2968,13.642 C10.5723,13.2976 11.0747,13.2418 11.4191,13.5173 Z" id="Path"></path>
                      <path d="M10.4602,8.03803 C10.3059,8.03803 10.1579,8.09932 10.0488,8.20843 C9.9397,8.31754 9.8784,8.46552 9.8784,8.61982 L9.8784,14.1408 C9.8784,14.5818 9.5209,14.9392 9.0799,14.9392 C8.639,14.9392 8.2815,14.5818 8.2815,14.1408 L8.2815,8.61982 C8.2815,8.042 8.511,7.48784 8.9196,7.07925 C9.3282,6.67067 9.8824,6.44113 10.4602,6.44113 L17.8215,6.44113 C18.3993,6.44113 18.9534,6.67067 19.362,7.07925 C19.7706,7.48784 20.0002,8.042 20.0002,8.61982 L20.0002,12.7605 C20.0002,13.3384 19.7706,13.8925 19.362,14.3011 C18.9534,14.7097 18.3993,14.9392 17.8215,14.9392 L10.9203,14.9392 C10.4793,14.9392 10.1218,14.5818 10.1218,14.1408 C10.1218,13.6998 10.4793,13.3423 10.9203,13.3423 L17.8215,13.3423 C17.9758,13.3423 18.1237,13.281 18.2329,13.1719 C18.342,13.0628 18.4033,12.9148 18.4033,12.7605 L18.4033,8.61982 C18.4033,8.46552 18.342,8.31754 18.2329,8.20843 C18.1237,8.09932 17.9758,8.03803 17.8215,8.03803 L10.4602,8.03803 Z" id="Path"></path>
                  </g>
              </g>
          </g>
      </g>
    </svg>
    );
};

export const HelpdeskNotificationIcon = props => (
  <Icon component={HelpdeskNotificationSvg} {...props} />
);
