import { CssType, ThemeType } from '../../../../../../theming/jssTypes';

const styles = (theme: ThemeType): CssType => ({
  mainDiv: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 0',
    overflow: 'auto',
    '& .ant-table-tbody td .editLink': {
      opacity: 0,
      marginLeft: 8,
      fontSize: 16,
    },
    '& .ant-table-tbody td:hover .editLink': {
      opacity: 1,
    },
  },
});

export default styles;
