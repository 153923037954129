import {
  CARRIER_MANAGEMENT_FETCH_DATA,
  CARRIER_MANAGEMENT_FETCH_ENABLE_LOADING,
  CARRIER_MANAGEMENT_FETCH_DISABLE_LOADING,
  CARRIER_MANAGEMENT_FETCH_ERROR,
  CARRIER_MANAGEMENT_FETCH_SERVICE_TYPES,
  CARRIER_MANAGEMENT_FETCH_VIRTUAL_SERIES,
} from '../actions/constants';

const defaultState = {
  isCarriersDataLoading: false,
  carrierDataError: null,
  carriersData: [],
  carrierServiceTypes: [],
};
export default function (state: any = defaultState, action) {
  switch (action.type) {
    case CARRIER_MANAGEMENT_FETCH_ENABLE_LOADING:
      return {
        ...state,
        isCarriersDataLoading: true,
      };
    case CARRIER_MANAGEMENT_FETCH_DISABLE_LOADING:
      return {
        ...state,
        isCarriersDataLoading: false,
      };
    case CARRIER_MANAGEMENT_FETCH_DATA:
      return {
        ...state,
        carriersData: action.data,
        isCarriersDataError: false,
        carriersDataError: null,
      };
    case CARRIER_MANAGEMENT_FETCH_ERROR:
      return {
        ...state,
        carrierDataError: action.data,
      };
    case CARRIER_MANAGEMENT_FETCH_SERVICE_TYPES:
      return {
        ...state,
        carrierServiceTypes: action.data,
      };
    case CARRIER_MANAGEMENT_FETCH_VIRTUAL_SERIES:
      return {
        ...state,
        carrierVirtualSeries:action.data,
      };
    default:
      return state;
  }
}
