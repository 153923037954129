import * as React from 'react';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '../../theming/jssTypes';
import DocumentTitle from 'react-document-title';
import Navbar from '../navbar/Navbar';
import { getViewControl } from '../../utils/genericConfig/utils';
const styles = (theme: any): any => ({
  message: {
    textAlign: 'center',
    color: theme.colors.primaryColor,
    fontSize: '1.0rem',
    fontFamily: 'Roboto, sans-serif',
    width: '100%',
    marginTop: '20%',
  },
});
const Unauthorized = (props) => {
  const { classes, title } = props;
  return (
    <div className={classes.mainDiv}>
      <DocumentTitle title={title} />
      <Navbar pageTitle={title} />
      <div className={classes.message}>
        You don’t have permission to view this page. Please contact admin for
        assistance
      </div>
    </div>
  );
};

export default withStyles(styles, { injectTheme: true })(Unauthorized);
