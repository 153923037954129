import { getRiderWiseDetails } from '../../api/riderReconciliation';

const viewControl = {
  buckets: ['rider_reconciliation'],
  fetchData: getRiderWiseDetails,
};
const buckets = {
  rider_reconciliation: {},
};
const bucketColor = {};

export default { viewControl, buckets, bucketColor };
