import * as React from 'react';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '../../theming/jssTypes';
import { Select } from 'antd';
import { escapeRegExp, removeArrayDuplicates } from '../../utils/utils';
import classNames from 'classnames';
const styles = (theme: ThemeType): CssType => ({
  searchWrapper: {
    '& .ant-select-selection--multiple': {
      maxHeight: (props) =>
        props.maxHeight ? `${props.maxHeight}px` : 'unset',
      overflowY: 'auto',
      borderRadius: (props) => (props.borderRadius ? props.borderRadius : 4),
      borderColor: (props) =>
        props.borderColor ? props.borderColor : '#d9d9d9',
    },
  },
});
class TagsInput extends React.PureComponent<any, any> {
  state = {
    selectedValues: [] as string[],
    searchInput: '',
  };
  tokeniseRegex: RegExp | null = null;
  constructor(props) {
    super(props);
    const { tokenSeparators } = props;
    if (tokenSeparators && tokenSeparators.length) {
      this.tokeniseRegex = new RegExp(
        tokenSeparators.map(escapeRegExp).join('|'),
        'gi',
      );
    }
  }
  getSelectedValues = () => {
    const { selectedValues: selectedValuesFromState } = this.state;
    const selectedValues =
      'value' in this.props ? this.props.value || [] : selectedValuesFromState;
    return selectedValues;
  };
  handleChange = (values) => {
    const selectedValues = this.getSelectedValues();
    let newValues: String[] = [];
    if (values.length > selectedValues.length) {
      const lastOption = values[values.length - 1];
      const optionsUnfiltered = this.tokeniseRegex
        ? lastOption.split(this.tokeniseRegex)
        : [lastOption];
      const newOptionsToAdd = removeArrayDuplicates(
        optionsUnfiltered.filter(
          (elem) => elem && selectedValues.indexOf(elem) === -1,
        ),
      );
      newValues = [...selectedValues, ...newOptionsToAdd];
    } else {
      newValues = values;
    }
    this.setState({ selectedValues: newValues, searchInput: '' });
    this.props.onChange(newValues);
  };
  handleInputChange = (val) => {
    const { tokenSeparators } = this.props;
    this.setState({ searchInput: val });
    if (this.props.onSearch) {
      this.props.onSearch(val);
    }
  };
  render() {
    const {
      mode,
      onChange,
      onSearch,
      tokenSeparators,
      autoFocus,
      notFoundContent,
      maxHeight,
      className,
      classes,
      theme,
      value,
      allowClear,
      disabled,
      ...otherProps
    } = this.props;
    const { searchInput } = this.state;
    const selectedValues = this.getSelectedValues();
    const classesToAdd = classNames(className, classes.searchWrapper);
    return (
      <Select
        mode={'multiple'}
        notFoundContent={''}
        autoFocus={autoFocus}
        onChange={this.handleChange}
        onSearch={this.handleInputChange}
        value={selectedValues}
        className={classesToAdd}
        allowClear={
          allowClear === undefined || allowClear === null ? true : allowClear
        }
        disabled={disabled}
        {...otherProps}
      >
        {searchInput && selectedValues.indexOf(searchInput) === -1 ? (
          <Select.Option key={searchInput} value={searchInput}>
            {searchInput}
          </Select.Option>
        ) : null}
      </Select>
    );
  }
}
export default withStyles(styles, { injectTheme: true })(TagsInput);
