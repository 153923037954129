import { getReasonsForAction } from 'src/api/dashboard';
import {
  PRINT_LABEL_SCAN_CLICK,
  PRINT_LABEL_UPLOAD_CLICK,
  CN_VERIFICATION_UPLOAD_CLICK,
  SET_GENERIC_SCAN_SELECT_MODAL_VISIBLE,
  SET_GENERIC_BULK_UPLOAD_MODAL_VISIBLE,
  SET_ACTION_TYPE,
  CREATE_ROUTE_ALL_CLICK,
  CREATE_ROUTE_FILTERED_CLICK,
  DOWNLOAD_CHILD_CN_CLICK,
  CUSTOM_TRIP_CLICK,
  CUSTOM_TRIP_UPLOAD_CLICK,
  CUSTOM_BULK_TRIP_UPLOAD_CLICK,
  CLOSE_ALL_MODALS,
  SET_ACTION_CONFIG,
  SWITCH_MODAL_VISIBILITY,
} from './constants';

import { SET_ACTION_REASON, GET_ACTION_REASON } from 'src/actions/constants';
import { message } from 'antd';

export const printLabelScanClick = (val?: any) => {
  return {
    data: val,
    type: PRINT_LABEL_SCAN_CLICK,
  };
};
export const printLabelUploadClick = (val?: any) => {
  return {
    data: val,
    type: PRINT_LABEL_UPLOAD_CLICK,
  };
};
export const cnVerificationUploadClick = () => {
  return {
    type: CN_VERIFICATION_UPLOAD_CLICK,
  };
};
export const setGenericScanSelectModalVisible = (val?: any) => {
  return {
    data: val,
    type: SET_GENERIC_SCAN_SELECT_MODAL_VISIBLE,
  };
};
export const setGenericBulkUploadModalVisible = (val?: any) => {
  return {
    data: val,
    type: SET_GENERIC_BULK_UPLOAD_MODAL_VISIBLE,
  };
};
export const setActionType = (val?: any) => {
  return {
    data: val,
    type: SET_ACTION_TYPE,
  };
};
export const createRouteAllClick = () => {
  return {
    type: CREATE_ROUTE_ALL_CLICK,
  };
};
export const createRouteFilteredClick = () => {
  return {
    type: CREATE_ROUTE_FILTERED_CLICK,
  };
};
export const downloadChildCnClick = () => {
  return {
    type: DOWNLOAD_CHILD_CN_CLICK,
  };
};
export const customTripClick = (val?: any) => {
  return {
    data: val,
    type: CUSTOM_TRIP_CLICK,
  };
};
export const customTripUploadClick = (val?: any) => {
  return {
    data: val,
    type: CUSTOM_TRIP_UPLOAD_CLICK,
  };
};
export const customBulkTripUploadClick = (val?: any) => {
  return {
    data: val,
    type: CUSTOM_BULK_TRIP_UPLOAD_CLICK,
  };
};
export const closeAllModals = () => {
  return {
    type: CLOSE_ALL_MODALS,
  };
};
export const setActionConfig = (val?: any) => {
  return {
    data: val,
    type: SET_ACTION_CONFIG,
  };
};
export const switchModalVisibility = (modal, value) => {
  return {
    data: {
      modal,
      value,
    },
    type: SWITCH_MODAL_VISIBILITY,
  };
};
export const fetchActionReason = (actionReason: string) => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    if (state.reasonsDataReducer[actionReason]) {
      dispatch({
        type: GET_ACTION_REASON,
        actionReason
      });
    } else {
      try {
        const data = await getReasonsForAction({ action_type: actionReason, is_active: true });
        if(data.isSuccess) {
          dispatch({
            type: SET_ACTION_REASON,
            actionReason,
            data
          });
        } else {
          message.error(data.errorMessage);
        }
      } catch (error) {
        message.error(error);
      }
    }
  };
};