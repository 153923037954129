import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { getPlanningTypePrettyName, getJobStatusPrettyName } from 'src/utils/utils';
import StyledTableModal from 'src/components/common/TableModal';

interface IProps {
    jobs: any;
    record: any;
    handleReload: any;
}

const JobDetailsCell = (props: IProps) => {
    const { jobs, record, handleReload } = props;
    const [ modalVisible, setModalVisible ] = React.useState(false);

    const getCombinedJobStatus = (record) => {
        let combinedJobStatus = '';
        let completedJobsCount = 0;
        let cancelledJobsCount = 0;
        const jobDetails = record?.job_details || [];
        const jobDetailsLength = jobDetails.length;
        if(Array.isArray(jobDetails) && jobDetailsLength){
          for(const job of jobDetails){
            if(job?.task_status === 'cancelled') cancelledJobsCount++;
            else if(job?.task_status === 'success') completedJobsCount++;
          }
         if(completedJobsCount === jobDetailsLength){
            combinedJobStatus = 'Completed';
          }else if(cancelledJobsCount === jobDetailsLength){
            combinedJobStatus = 'Cancelled';
          }else if(completedJobsCount > 0 && completedJobsCount < jobDetailsLength){
            combinedJobStatus = 'Partially Completed';
          }else{
              combinedJobStatus = 'Created';
          }
        }
        return combinedJobStatus;
    };

    const renderModal = () => {
        if (modalVisible) {
            const jobDetailsData = record?.job_details?.map((ele) => ({
                task_reference_number: ele?.task_reference_number || null,
                task_type: getPlanningTypePrettyName(ele?.task_type) || null,
                task_status: getJobStatusPrettyName(ele?.task_status) || null,
            })) || [];
            const jobDetails = {
                jobDetailsData,
                selectedReferenceNumber: record.reference_number,
            };
            return <StyledTableModal
                columnsToShow={jobDetailColumns}
                header="Job Details"
                dataSource={jobDetails.jobDetailsData}
                handleModalClose={(isSuccess) => {
                    if (isSuccess) {
                        handleReload();
                    }
                    setModalVisible(false);
                }}
                showDescription={true}
                descriptionText={`Consignment #:  ${jobDetails.selectedReferenceNumber}`}
                isVisible={modalVisible}
            />
        }
        return <></>;
    };

    return jobs?.length > 0 ?
    <div>
        {getCombinedJobStatus(record)}
        <InfoCircleOutlined
            style={{ marginLeft: 8, color: '#085492', fontSize: 14 }}
            onClick={() => {
                setModalVisible(true);
            }}
        />
        {renderModal()}
    </div>
    : <></>;
};

export default JobDetailsCell;
