import Icon from '@ant-design/icons';
import * as React from 'react';

const BookingMastersSvg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 29 32">
        <title>Masters</title>
        <defs></defs>
        <g id="ICONS" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Artboard" transform="translate(-434.000000, -534.000000)"
               fillRule="nonzero" fill="currentColor">
                <g id="Masters" transform="translate(434.000000, 534.000000)">
                    <path d="M20.7754098,13.0865672 C22.8672131,13.0865672 24.5786885,14.8059701 24.6262295,17.0029851 C24.6262295,19.1044776 22.9147541,20.8238806 20.8229508,20.8238806 L7.46393443,20.8238806 C5.8,20.8238806 4.46885246,19.4865672 4.46885246,17.8149254 C4.46885246,17.6238806 4.46885246,17.4328358 4.51639344,17.2895522 L4.51639344,17.1940299 C4.80163934,15.8089552 5.99016393,14.8059701 7.41639344,14.8059701 L7.65409836,14.8059701 C7.8442623,13.3253731 9.08032787,12.1791045 10.6016393,12.1791045 C11.1721311,12.1791045 11.742623,12.3701493 12.2180328,12.6567164 C12.6934426,11.080597 14.1672131,9.98208955 15.8311475,9.98208955 C17.7327869,9.98208955 19.3491803,11.4149254 19.5868852,13.2776119 C19.9672131,13.1343284 20.347541,13.0865672 20.7754098,13.0865672 Z M15.7836066,11.4626866 C15.2131148,11.4626866 14.6901639,11.6537313 14.2622951,12.0358209 L14.7377049,12.561194 C15.3557377,12.0358209 16.3540984,12.0835821 16.9721311,12.6567164 C17.304918,12.9910448 17.447541,13.3731343 17.447541,13.8029851 L18.1606557,13.8029851 C18.1606557,13.1820896 17.9229508,12.561194 17.495082,12.1313433 C17.0196721,11.7014925 16.4491803,11.4626866 15.7836066,11.4626866 Z" id="Combined-Shape"></path>
                    <path d="M12.7409836,2.72238806 C12.9786885,3.48656716 13.6442623,4.10746269 14.5,4.10746269 C15.4983607,4.10746269 16.3540984,3.29552239 16.3540984,2.24477612 C16.3540984,1.24179104 15.5459016,0.382089552 14.5,0.382089552 C13.5491803,0.382089552 12.7885246,1.09850746 12.6934426,2.00597015 C9.5557377,2.3880597 6.60819672,3.86865672 4.42131148,6.11343284 L4.9442623,6.59104478 C6.98852459,4.48955224 9.79344262,3.10447761 12.7409836,2.72238806 Z" id="Shape"></path>
                    <path d="M0.998360656,20.0119403 L1.66393443,19.8208955 C1.28360656,18.5791045 1.14098361,17.3373134 1.14098361,16.0477612 C1.14098361,14.280597 1.47377049,12.5134328 2.13934426,10.8895522 C2.28196721,10.9373134 2.47213115,10.9373134 2.6147541,10.9373134 C3.28032787,10.9373134 3.89836066,10.6029851 4.23114754,10.0298507 C4.46885246,9.6 4.56393443,9.12238806 4.42131148,8.64477612 C4.27868852,8.16716418 3.99344262,7.78507463 3.56557377,7.49850746 C3.28032787,7.35522388 2.94754098,7.25970149 2.6147541,7.25970149 C1.94918033,7.25970149 1.33114754,7.59402985 0.998360656,8.16716418 C0.808196721,8.54925373 0.713114754,9.07462687 0.855737705,9.55223881 C0.950819672,9.93432836 1.18852459,10.2686567 1.52131148,10.5074627 C0.808196721,12.2268657 0.427868852,14.0895522 0.427868852,16 C0.427868852,17.3373134 0.618032787,18.7223881 0.998360656,20.0119403 Z" id="Shape"></path>
                    <path d="M3.85081967,24.119403 C4.08852459,23.880597 4.23114754,23.641791 4.32622951,23.3074627 C4.46885246,22.8298507 4.37377049,22.3522388 4.13606557,21.9223881 C3.80327869,21.3492537 3.1852459,20.9671642 2.51967213,20.9671642 C2.18688525,20.9671642 1.90163934,21.0626866 1.61639344,21.2059701 C1.18852459,21.4447761 0.855737705,21.8268657 0.760655738,22.3044776 C0.618032787,22.7820896 0.713114754,23.2597015 0.950819672,23.6895522 C1.28360656,24.2626866 1.90163934,24.6447761 2.56721311,24.6447761 C2.80491803,24.6447761 3.04262295,24.5970149 3.28032787,24.5014925 C5.13442623,26.9373134 7.70163934,28.7522388 10.6491803,29.5641791 L10.8393443,28.8955224 C8.08196721,28.1313433 5.60983607,26.4119403 3.85081967,24.119403 Z" id="Shape"></path>
                    <path d="M16.2590164,29.3253731 C16.0688525,28.561194 15.3557377,27.9402985 14.5,27.9402985 C13.5016393,27.9402985 12.6459016,28.7522388 12.6459016,29.7552239 C12.6459016,30.758209 13.4540984,31.6179104 14.452459,31.6179104 L14.452459,31.6179104 C15.4032787,31.6179104 16.1639344,30.9014925 16.2590164,29.9940299 C19.3491803,29.6119403 22.2016393,28.1791045 24.4360656,25.9343284 L23.9131148,25.4089552 C21.9163934,27.558209 19.1590164,28.8955224 16.2590164,29.3253731 Z" id="Shape"></path>
                    <path d="M25.1967213,7.9761194 C24.9590164,8.21492537 24.8163934,8.45373134 24.7213115,8.7880597 C24.5786885,9.26567164 24.6262295,9.74328358 24.8639344,10.2208955 C25.1967213,10.7940299 25.8147541,11.1761194 26.4803279,11.1761194 C26.8131148,11.1761194 27.0983607,11.080597 27.3836066,10.9373134 C27.8114754,10.6985075 28.1442623,10.3164179 28.2393443,9.83880597 C28.3819672,9.36119403 28.3344262,8.88358209 28.0967213,8.40597015 C27.7639344,7.83283582 27.1459016,7.45074627 26.4803279,7.45074627 C26.242623,7.45074627 26.004918,7.49850746 25.8147541,7.59402985 C23.8655738,4.96716418 21.0606557,3.05671642 17.9229508,2.29253731 L17.7327869,3.00895522 C20.7278689,3.72537313 23.3901639,5.49253731 25.1967213,7.9761194 Z" id="Shape"></path>
                    <path d="M27.4311475,21.5880597 C28.1918033,19.8208955 28.5721311,17.9104478 28.5721311,16 C28.5721311,14.8059701 28.4295082,13.5641791 28.0967213,12.4179104 L27.3836066,12.6089552 C27.6688525,13.7074627 27.8114754,14.8537313 27.8114754,16 C27.8114754,17.8149254 27.4311475,19.5820896 26.7180328,21.2537313 C26.5754098,21.2059701 26.3852459,21.2059701 26.195082,21.2059701 C25.5295082,21.2059701 24.9590164,21.5402985 24.6262295,22.1134328 C24.3885246,22.5432836 24.2934426,23.0208955 24.4360656,23.4985075 C24.5786885,23.9761194 24.8639344,24.4059701 25.2918033,24.6447761 C25.5770492,24.8358209 25.9098361,24.8835821 26.242623,24.8835821 C26.9081967,24.8835821 27.4786885,24.5492537 27.8114754,23.9761194 C28.0491803,23.5462687 28.1442623,23.0686567 28.0016393,22.5910448 C28.0016393,22.2089552 27.7639344,21.8746269 27.4311475,21.5880597 Z" id="Shape"></path>
                </g>
            </g>
        </g>
    </svg>
  );
};

export const BookingMastersNavIcon = props => (
  <Icon component={BookingMastersSvg} {...props} />
);
