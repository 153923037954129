import { getDownloadsRoute } from '../../routing/utils';
import { getAllConsignmentsData, getSummaryMetricsData } from '../../api/genericConsignmentView';

const viewControl = {
  pageTitle : 'Consignments',
  applyDateFilterSort: true,
  buckets : [
    'crm_default',
  ],
  navBar : {
    left : ['multi_hub_search', 'date_filter'],
    right: ['logistics_search'],
  },
  searchFilterOptions: [
    { key: 'referenceNumber', val: 'Consignment No.' },
    { key: 'senderPhone', val: 'Sender Phone Number' },
    { key: 'destinationPhone', val: 'Destination Phone Number' },
    { key: 'customerReferenceNumber', val: 'Customer Reference Number' },
    { key: 'awbNumber', val: 'Carrier AWB Number' },
  ],
  extraSearchOptions: [
    {
      key: 'customerCivilId',
      val: 'Customer Civil ID',
    },
    {
      key: 'manifestNumber',
      val: 'Manifest No.',
    },
  ],
  dateFilterOptions: [
    { key: 'created_at', val: 'Created At' },
    { key: 'last_main_event_time', val: 'Last Updated' },
  ],
  fetchData : getAllConsignmentsData,
  fetchSummaryMetrics : null,
};
const buckets = {
  crm_default: {
    name: 'All CRM',
    filters : [
      'status',
      'attempt_count',
      'pickup_attempt_count',
      'movement_type',
      'service_type',
      'load_type',
      'customer_code',
      'rescheduled_reason',
      'exceptions',
      'is_address_updated',
      'destinationHubIdList',
      'consigneeCodeList',
      'consignorCodeList',
      'nextHubIdList',
      'destinationPincodeList',
      'isCod',
      'consignment_type',
      'last_call_logs_status',
      'typeOfDelivery',
    ],
    actions: [
      {
        key:'set_rto',
        name:'Set RTO',
        type:'modal',
        modal:'set_rto',
        requireRows : true,
      },
      {
        key:'set_onhold',
        name:'Set Onhold',
        type:'modal',
        modal:'set_onhold',
        requireRows : true,
      },
      {
        key:'release_onhold',
        name:'Release Onhold',
        type:'modal',
        modal:'release_onhold',
        requireRows : true,
      },
      {
        key:'download',
        name:'Download',
        children : [
          {
            key:'request_download',
            name:'Request Download',
            type:'modal',
            modal:'downloads',
            requireRows : false,
          },
          {
            key:'view_request',
            name:'View Requests',
            type:'route',
            route: getDownloadsRoute,
          },
        ],
      },
    ],
    status_filter : [
    ],
  },
};

const bucketColor = {
  crm_default : '#EB2F96',
};
export default { viewControl, buckets , bucketColor };
