import * as React from 'react';
import { Tooltip } from 'antd';
import * as moment from 'moment';
import store from '@store/appStore';

export const getSortByDateFilterMapping = {
  order_pickup_time: 'last_main_event_time',

};
export function renderPrettyDateTime(text) {
  return (
    text ?
    <div>
      <div>{moment(text).format('DD MMM, YYYY')}</div>
      <div>{moment(text).format('hh:mm A')}</div>
    </div> : null
  );
}
export function ellipsisWithTooltip(text, args?: {
  style?: React.CSSProperties;
}) {
  const { style } = args || {};
  return (
    <Tooltip title={text}>
      <div style={{
        maxWidth: '170px',
        display: 'inline-block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: 'pointer',
        ...style,
      }}
      >{text}</div>
    </Tooltip>);
}
export function ellipsisWithoutTooltip(text, props?: {
  style?: React.CSSProperties;
}) {
  const { style } = props || {};
  return (
    <div style={{
      maxWidth: '170px',
      display: 'inline-block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      cursor: 'pointer',
      ...style,
    }}>
      {text}
    </div>);
}
export function getHubKeyLabel(hub) {
  return { key: hub.id, label: `${hub.name}, ${hub.code} (${hub.category_name})` };
}

export function getHubCodeLabel(hub) {
  return { key: hub?.code, label: hub?.code };
}

export function getSelectedHubList(key?) {
  const state = store?.getState();
  const { masterData, cachedData } = state;
  const {
    allow_multiple_hub_selection_in_dispatch: allowMultipleHubSelection,
  } = masterData;
  const {
    selectedHubsList: selectedHubs,
    currHub,
  } = cachedData;

  if (key && key.trim().length) {
    return (allowMultipleHubSelection && selectedHubs)
    ? selectedHubs.map(hub => hub[key])
    : [currHub[key]];
  }

  return (allowMultipleHubSelection && selectedHubs)
    ? selectedHubs : [currHub];
}
