import { getDetailsRoute } from '../../routing/utils';
import { getAllConsignmentsDataStoreViewRetail,
  getSummaryMetricsDataRetailStore, requestDownloadRetailStore } from '../../api/genericConsignmentView';
const defaultRetailStatusFilterOptions = [
  'reachedathub',
  'outfordelivery',
  'delivered',
  'attempted',
  'inscan_at_hub',
  'in_transit',
  'pickup_awaited',
  'pickup_scheduled',
  'out_for_pickup',
  'not_picked_up',
  'pickup_completed',
  'received',
  'not_received',
];

const viewControl = {
  buckets : [
    'retail_store_all',
    'retail_store_inbound',
    'retail_store_outbound',
    'retail_store_completed',
    'retail_store_failed',
  ],
  navBar : {
    left : ['hub_search_with_type', 'date_filter'],
    right : ['search_redirect'],
  },
  globalActions:[],
  dateFilterOptions : [
    { key:'created_at', val:'Creation Time' },
    { key:'last_main_event_time', val: 'Last Updated' },
  ],
  searchFilterOptions : [
    { key:'referenceNumber', val: 'Reference Number' },
  ],
  hubSearchOptions : [
    { key:'upcountry', val:'Store Hub' },
  ],
  detailsRoute:getDetailsRoute,
  fetchData : getAllConsignmentsDataStoreViewRetail,
  fetchSummaryMetrics : getSummaryMetricsDataRetailStore,
  requestDownload: requestDownloadRetailStore,
  pageTitle: 'Store Consignments',
  defaultHubType: 'upcountry',
  applyDateFilterSort: true,
};
const buckets = {
  retail_store_all : {
    name:'All',
    filters : [
      'status',
      'attempt_count',
      'pickup_attempt_count',
      'service_type',
      'load_type',
      'customer_code',
      'consignment_type',
      'proximity',
      'orderType',
      'purchasingGroup',
      'reference_number_search',
      'customer_reference_number_search',
      'so_number_search',
      'delivery_type',
    ],
    actions : [
    ],
    status_filter: defaultRetailStatusFilterOptions,

  },
  retail_store_completed : {
    name: 'Success',
    filters : [
      'status',
      'attempt_count',
      'pickup_attempt_count',
      'service_type',
      'load_type',
      'customer_code',
      'consignment_type',
      'proximity',
      'orderType',
      'purchasingGroup',
      'reference_number_search',
      'customer_reference_number_search',
      'so_number_search',
      'delivery_type',
    ],
    actions: [
    ],
    status_filter : defaultRetailStatusFilterOptions,
  },
  retail_store_failed : {
    name: 'Failed',
    filters : [
      'status',
      'attempt_count',
      'pickup_attempt_count',
      'service_type',
      'load_type',
      'customer_code',
      'consignment_type',
      'proximity',
      'orderType',
      'purchasingGroup',
      'reference_number_search',
      'customer_reference_number_search',
      'so_number_search',
      'delivery_type',
    ],
    actions: [
    ],
    status_filter : defaultRetailStatusFilterOptions,
  },
  retail_store_inbound : {
    name: 'Inbound',
    filters : [
      'status',
      'attempt_count',
      'pickup_attempt_count',
      'service_type',
      'load_type',
      'customer_code',
      'consignment_type',
      'proximity',
      'orderType',
      'purchasingGroup',
      'reference_number_search',
      'customer_reference_number_search',
      'so_number_search',
      'delivery_type',
    ],
    actions: [
    ],
    status_filter : defaultRetailStatusFilterOptions,
  },
  retail_store_outbound : {
    name: 'Outbound',
    filters : [
      'status',
      'attempt_count',
      'pickup_attempt_count',
      'service_type',
      'load_type',
      'customer_code',
      'consignment_type',
      'proximity',
      'orderType',
      'purchasingGroup',
      'reference_number_search',
      'customer_reference_number_search',
      'so_number_search',
      'delivery_type',
    ],
    actions: [
    ],
    status_filter : defaultRetailStatusFilterOptions,
  },
};

const bucketColor = {
  retail_store_all: '#EB2F96',
  retail_store_outbound: '#722ED1',
  retail_store_inbound: '#2F54EB',
  retail_store_completed: '#389E0D',
  retail_store_failed : '#FA541C',
};

export default { viewControl, buckets, bucketColor };
