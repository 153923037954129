import * as moment from 'moment';
import {
  TMS_OVERVIEW_DASHBOARD_UPDATE_GLOBAL_FILTER,
  TMS_OVERVIEW_DASHBOARD_UPDATE_CHART_DATA,
  TMS_OVERVIEW_DASHBOARD_CHART_ENABLE_LOADING,
  TMS_OVERVIEW_DASHBOARD_CHART_DISABLE_LOADING,
} from '../actions/constants';
const defaultChartState = {
  isLoading: true,
  data: {},
};
const getDefaultChartsState = () => {
  const charts = ['vehicle_summary', 'dock_summary', 'bag_summary'];
  const chartsState = {};
  charts.forEach((element) => {
    chartsState[element] = defaultChartState;
  });
  return chartsState;
};
const defaultState = {
  globalAppliedFilters: {
    searchType: 'referenceNumber',
    searchValue: '',
    startDate: moment().subtract(7, 'day').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    dateFilterType: 'last_status_change_time',
  },
  charts: getDefaultChartsState(),
};

export default function (state: any = defaultState, action) {
  switch (action.type) {
    case TMS_OVERVIEW_DASHBOARD_UPDATE_GLOBAL_FILTER:
      return {
        ...state,
        globalAppliedFilters: {
          ...state.globalAppliedFilters,
          ...action.data,
        },
      };
    case TMS_OVERVIEW_DASHBOARD_CHART_ENABLE_LOADING:
      return {
        ...state,
        charts: {
          ...state.charts,
          [action.chart]: {
            ...state.charts[action.chart],
            isLoading: true,
          },
        },
      };
    case TMS_OVERVIEW_DASHBOARD_CHART_DISABLE_LOADING:
      return {
        ...state,
        charts: {
          ...state.charts,
          [action.chart]: {
            ...state.charts[action.chart],
            isLoading: false,
          },
        },
      };
    case TMS_OVERVIEW_DASHBOARD_UPDATE_CHART_DATA:
      return {
        ...state,
        charts: {
          ...state.charts,
          [action.chart]: {
            ...state.charts[action.chart],
            isLoading: false,
            data: action.data,
          },
        },
      };
    default:
      return state;
  }
}
