import * as React from 'react';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '../../../theming/jssTypes';
import { Modal, Table } from 'antd';
import { Link } from 'react-router-dom';
import { getDetailsRoute } from '../../../routing/utils';
import { columnsToShowInCustomerReferenceList } from './utils';
import { getConsignmentStatusPrettyName } from '../../../utils/consignmentStatus';
import { withRouter } from 'react-router';

const styles = (theme: ThemeType): CssType => ({
  tableContainer: {
    flex: '1 1 0',
    overflow: 'auto',
    borderTop: '1px solid #D9D9D9',
    '& .ant-table-tbody > tr > td, .ant-table-thead > tr > th': {
      padding: 8,
      wordBreak: 'break-word',
    },
  },
  customStyle: {
    height: 300,
    overflow: 'auto',
    border: '1px solid #000000',
  },
  customBreak: {
    wordBreak: 'break-all',
  },
});

const CustomerReferenceListModal = (props: any) => {
  const { isVisible, classes, data, viewType, onModalClose } = props;
  const columns =
    columnsToShowInCustomerReferenceList &&
    columnsToShowInCustomerReferenceList.map((col) => {
      return {
        dataIndex: col.column_id,
        title: col.pretty_name,
        width: 170,
        render: (x, record, index) => {
          switch (col.column_id) {
            case 'reference_number':
              return (
                <Link
                  target={viewType === 'ondemand' ? null : '_blank'}
                  to={{
                    pathname: getDetailsRoute(
                      encodeURIComponent(record.reference_number),
                    ),
                    state: { viewType },
                  }}
                >
                  <span className={classes.customBreak}>{x}</span>
                </Link>
              );
            case 'status':
              return getConsignmentStatusPrettyName(x.toLowerCase());
            default:
              return <span className={classes.customBreak}>{x}</span>;
          }
        },
      };
    });
  return (
    <Modal
      title={'Consignments'}
      visible={isVisible}
      width={1000}
      cancelText={'Close'}
      onCancel={() => onModalClose()}
      maskClosable={false}
      okButtonProps={{ style: { display: 'none' } }}
      destroyOnClose
    >
      <div className={classes.tableContainer}>
        <Table
          bordered
          sticky
          className={classes.customStyle}
          locale={{ emptyText: 'No consignments found' }}
          dataSource={data}
          pagination={false}
          columns={columns}
        />
      </div>
    </Modal>
  );
};
const CustomerReferenceListModalStyled: any = withStyles(styles, {
  injectTheme: true,
})(CustomerReferenceListModal);
export default withRouter(
  CustomerReferenceListModalStyled,
) as React.ComponentType<any>;
