import * as React from 'react';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '@theming/jssTypes';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { Divider, message, Dropdown, Menu, Button, DatePicker } from 'antd';
import {
  ClockCircleOutlined,
  DownloadOutlined,
  ExclamationCircleFilled,
  CheckCircleFilled,
  CloseCircleFilled,
  ReloadOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import * as moment from 'moment';
import NavBar from './NavBar';
import { NAVBAR_HEIGHT } from '@src/globals';
import FixedHeaderTable from '../../../common/FixedHeaderTable/FixedHeaderTable';
import { getBulkUpdateOrderRequests } from '@api/FoodDashboardApi';
import { bulkRequestsColumns, requestTypeMapping } from './columns';
import { formatDateTime } from '@utils/utils';
import { FoodDashboard_DISPATCH_POLLING_FREQUENCY } from '@src/globals';
import { tablePaginationStyle } from '@theming/cssSnippets/commonSnippets';
import { getSelectedHubList } from '@components/common/utils';
const styles = (theme: ThemeType): CssType => ({
  navInfoDiv: {
    margin: '10px',
  },
  navInfoValue: {
    fontWeight: 'bolder',
  },
  mainDiv: {
    display: 'flex',
    flexDirection: 'column',
    height: `calc( 100vh - ${NAVBAR_HEIGHT}px)`,
    '& .ant-btn ant-dropdown-trigger': {
      borderColor: theme.primaryColor,
      color: theme.primaryColor,
    },
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: 10,
  },
  leftSpaceStatusIcon: {
    marginRight: '5px',
  },
  datePickerDiv: {
    margin: '10px 16px',
  },
  transparentButton: {
    background: 'transparent',
    border: 'none',
    color: theme.primaryColor,
    boxShadow: 'none',
    margin: '10px 0',
    '&:hover': {
      border: 'none',
      background: 'transparent !important',
      boxShadow: 'none',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  tableContainer: {
    flex: '1 1 0',
    overflow: 'auto',
    margin: '0 16px 16px 16px',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
    '& tr': {
      verticalAlign: 'top',
    },
    ...tablePaginationStyle(),
    '& .ant-table-tbody > tr > td, .ant-table-thead > tr > th': {
      padding: '16px 8px',
    },
  },
});

const BulkRequestsPage = (props: any) => {
  const {
    classes,
    currHub,
    location,
    dispatchPollingFrequency,
    selectedHubs,
    allowMultipleHubSelection,
  } = props;
  const [lastUpdatedTime, setLastUpdatedTime] = React.useState(moment());
  const [isLoading, setIsLoading] = React.useState(false);
  const [bulkRequests, setBulkRequests] = React.useState<any>([]);
  const [isBulkRequests, setIsBulkRequests] = React.useState(false);
  const [dateFilter, setDateFilter] = React.useState({
    start: moment().format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
  });
  const [currentTime, setCurrentTime] = React.useState(moment().format('lll'));
  let pollingAgent: any = null;

  React.useEffect(() => {
    fetchBulkRequests();
  }, []);

  React.useEffect(() => {
    startPoller();
    const interval = setInterval(() => {
      setCurrentTime(moment().format('lll'));
    }, dispatchPollingFrequency || FoodDashboard_DISPATCH_POLLING_FREQUENCY);
    return () => {
      stopPolling();
      clearInterval(interval);
    };
  }, [isBulkRequests]);

  const fetchBulkRequests = async (date?: any, withoutLoader?: Boolean) => {
    !withoutLoader && setIsLoading(true);
    const hubCodeList = getSelectedHubList('code');
    const body = {
      hub_code_list: hubCodeList,
      from_date: date ? date.start : dateFilter.start,
      to_date: date ? date.end : dateFilter.end,
    };
    const response = await getBulkUpdateOrderRequests(body);
    if (!response.isSuccess) {
      message.error(response.errorMessage);
      setIsLoading(false);
      return;
    }
    const requests = response.data?.requests || [];
    setBulkRequests(requests);
    !date && setIsBulkRequests(true);
    setLastUpdatedTime(moment());
    setIsLoading(false);
  };

  const pollApi = () => {
    const shouldPoll = bulkRequests.findIndex(
      (x) => x.status?.toLowerCase() === 'processing',
    );
    if (shouldPoll !== -1) {
      pollingAgent = setTimeout(async () => {
        await fetchBulkRequests(undefined, true);
        poller();
      }, dispatchPollingFrequency || FoodDashboard_DISPATCH_POLLING_FREQUENCY);
    } else {
      stopPolling();
    }
  };
  const poller = async () => {
    if (pollingAgent) {
      stopPolling();
      pollApi();
    }
  };
  const startPoller = () => {
    if (!pollingAgent && isBulkRequests) {
      pollApi();
    }
  };
  const stopPolling = () => {
    if (pollingAgent) {
      clearTimeout(pollingAgent);
      pollingAgent = undefined;
    }
  };

  const renderStoreIdAndTime = () => {
    return (
      <div style={{ display: 'flex' }}>
        {!allowMultipleHubSelection ? (
          <div className={classes.navInfoDiv}>
            <span>Store : </span>
            <span className={classes.navInfoValue}>{currHub.name}</span>
          </div>
        ) : null}
        <div className={classes.navInfoDiv}>
          <span>Employee : </span>
          <span className={classes.navInfoValue}>
            {location.state?.employeeName}
          </span>
        </div>
        <div className={classes.navInfoDiv}>
          <ClockCircleOutlined style={{ margin: '0 5px' }} />
          <span>Current Time - </span>
          <span className={classes.navInfoValue}>{currentTime}</span>
        </div>
        <Divider type="vertical" style={{ marginTop: 10, height: 25 }} />
        <div className={classes.navInfoDiv}>
          <span>Last Updated Time - </span>
          <span className={classes.navInfoValue}>
            {lastUpdatedTime.format('h:mm A')}
          </span>
        </div>
      </div>
    );
  };

  const getDownloadsMenu = (record) => {
    return (
      <Menu>
        {record.failure_url && (
          <Menu.Item>
            <a href={record.failure_url}>Download failures</a>
          </Menu.Item>
        )}
        {record.upload_data_url && (
          <Menu.Item>
            <a href={record.upload_data_url}>Download uploads</a>
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const renderStatus = (status) => {
    let statusItem;
    if (status === 'completed') {
      statusItem = (
        <span>
          <CheckCircleFilled
            className={classes.leftSpaceStatusIcon}
            style={{ color: '#008000' }}
          />
          Complete
        </span>
      );
    } else if (status === 'processing') {
      statusItem = (
        <span>
          <ExclamationCircleFilled
            className={classes.leftSpaceStatusIcon}
            style={{ color: '#faad14' }}
          />
          Processing
        </span>
      );
    } else if (status === 'error') {
      statusItem = (
        <span>
          <CloseCircleFilled
            className={classes.leftSpaceStatusIcon}
            style={{ color: 'red' }}
          />
          Error
        </span>
      );
    }
    return statusItem ? (
      statusItem
    ) : (
      <span style={{ textTransform: 'capitalize' }}>status</span>
    );
  };

  const generateDataColumns = () => {
    const columnData: any = [];
    const indexList = bulkRequestsColumns;
    indexList.forEach((item) => {
      const itemToPush = {};
      itemToPush['title'] = item.pretty_name;
      itemToPush['dataIndex'] = item.column_id;
      itemToPush['key'] = item.column_id;
      itemToPush['width'] = 1;
      if (item.column_id === 'actions') {
        itemToPush['render'] = (x, record) => {
          return (
            <div>
              {record.failure_url || record.upload_data_url ? (
                <Dropdown
                  overlay={getDownloadsMenu(record)}
                  trigger={['click']}
                >
                  <DownloadOutlined style={{ fontSize: '20px' }} />
                </Dropdown>
              ) : (
                <DownloadOutlined disabled style={{ fontSize: '20px' }} />
              )}
            </div>
          );
        };
      }
      if (item.column_id === 'created_at') {
        itemToPush['render'] = (x) => {
          return formatDateTime(x);
        };
      }
      if (item.column_id === 'status') {
        itemToPush['render'] = (x) => {
          return renderStatus(x);
        };
      }
      if (item.column_id === 'request_type') {
        itemToPush['render'] = (x) => {
          return (
            <span style={{ wordBreak: 'break-word' }}>
              {requestTypeMapping[x] || x}
            </span>
          );
        };
      }
      columnData.push(itemToPush);
    });
    return columnData;
  };

  const handleDatesSelect = (dateArr) => {
    const date: any = { start: moment(), end: moment() };
    if (dateArr?.length === 2) {
      date.start = dateArr[0];
      date.end = dateArr[1];
    }
    const dateObj = {
      start: date.start.format('YYYY-MM-DD'),
      end: date.end.format('YYYY-MM-DD'),
    };
    setDateFilter(dateObj);
    fetchBulkRequests(dateObj);
  };

  const onRefreshClick = async () => {
    await fetchBulkRequests();
  };

  const renderDefaultFilters = () => {
    return (
      <div className={classes.topBar}>
        <div className={classes.datePickerDiv}>
          <DatePicker.RangePicker
            value={[moment(dateFilter.start), moment(dateFilter.end)]}
            format={'DD/MM/YYYY'}
            onChange={handleDatesSelect}
            ranges={{
              Today: [moment(), moment()],
              'Last 7 days': [moment().subtract(6, 'day'), moment()],
              'Last 30 days': [moment().subtract(29, 'day'), moment()],
              'This Month': [
                moment().startOf('month'),
                moment().endOf('month'),
              ],
            }}
          ></DatePicker.RangePicker>
        </div>
        <div>
          <Button
            onClick={onRefreshClick}
            className={classes.transparentButton}
          >
            <ReloadOutlined />
          </Button>
          <Button
            icon={<LeftOutlined />}
            className={classes.transparentButton}
            style={{ fontWeight: 'bold' }}
            onClick={() => history.back()}
          >
            Back To Order View
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div>
      <DocumentTitle title="Dispatch Screen" />
      <NavBar leftComponents={[renderStoreIdAndTime()]} />
      <div className={classes.mainDiv}>
        <div>{renderDefaultFilters()}</div>
        <div className={classes.tableContainer}>
          <FixedHeaderTable
            loading={isLoading}
            dataSource={bulkRequests}
            columns={generateDataColumns()}
            rowKey={(record: any) => record['csvId']}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ cachedData, masterData }) => {
  return {
    currHub: cachedData.currHub,
    dispatchPollingFrequency: masterData.dispatch_polling_frequency_millis,
    selectedHubs: cachedData.selectedHubsList,
    allowMultipleHubSelection:
      masterData.allow_multiple_hub_selection_in_dispatch,
  };
};

const BulkRequestsPageStyled = withStyles(styles, { injectTheme: true })(
  BulkRequestsPage,
);
export default connect(mapStateToProps)(BulkRequestsPageStyled);
