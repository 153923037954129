import * as React from 'react';
import { ThemeProvider as JssThemeProvider } from 'react-jss';
import connectTheme from './connectTheme';

class ThemeProvider extends React.PureComponent<any, any> {

  render() {
    return (
      <JssThemeProvider theme={this.props.theme} >
        {this.props.children}
      </JssThemeProvider>
    );
  }
}

export default connectTheme(ThemeProvider);
