import consignmentColumns from './GenericData/consignment/columnsData';
import customerColumns from './GenericData/customer/columnsData';

const renderColumnDataMap = {
    consignment: consignmentColumns,
    customer: customerColumns,
};

export const columnRenderResolver = (field, value, record, objectType, otherParams) => {
    const columnData = renderColumnDataMap[objectType] || {};

    if (columnData[field.id]) {
        return columnData[field.id](field, value, record, otherParams);
    }

    return value || '-';
};
