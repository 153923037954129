import * as React from 'react';
import withStyles from 'react-jss';
import { CssType,
  ThemeType} from '../../../../theming/jssTypes';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import FixedHeaderTable from 'src/components/common/FixedHeaderTable/FixedHeaderTable';
import { Table } from 'antd';
import CustomTableCell from 'src/components/common/FixedHeaderTable/CustomTableCell';
import CustomTableTitle from 'src/components/common/FixedHeaderTable/CustomTableTitle';
const styles = (theme: ThemeType): CssType => ({
  mainTable: {
    tableLayout: 'fixed',
    '& .ant-table-row': {
      // backgroundColor: 'white',
      // boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
      // borderBottom: '0px solid',
      // transition: 'none',
      zIndex: '0 !important',
    },
    '& td': {
      width: '11%',
    },
    '& tr.dragging td': {
      backgroundColor: '#e5f7ff !important',
    },
    '& .ant-table-thead > tr > th' : {
      fontSize: '10px',
      fontWeight: '400',
      lineHeight: '12px',
      padding: '7px 12px',
      backgroundColor: '#F2F7FC',
      color: '#111111',

      '& .ant-table-column-title': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        width: '100%',
      },
    },
    '& .ant-table-thead > tr' : {
      backgroundColor: '#F2F7FC',
    },
    '& .ant-table-row > td' : {
      fontSize: '12px',
      lineHeight: '14px',
      padding: '10px 12px',
      color: '#111111',
      fontWeight: '500',
      backgroundColor: '#FFFFFF',
    },
    '& .ant-table-row-selected > td' : {
      backgroundColor: '#BAE7FF !important',

      '&:hover': {
        backgroundColor: '#BAE7FF !important',
      },
    },
    '& .ant-table-ping-left .ant-table-cell-fix-left-first::after, .ant-table-ping-left .ant-table-cell-fix-left-last::after': {
      boxShadow: 'none',
    },
    '& .ant-table-ping-right .ant-table-cell-fix-right-first::after, .ant-table-ping-right .ant-table-cell-fix-right-last::after': {
      boxShadow: 'none',
    },
    '& .ant-table-cell-row-hover': {
      backgroundColor: '#F5FDFF !important',
    },
  },
});

class DragableBodyRow extends React.Component<any, any> {
  render() {
    const {
      className,
      ...restProps
    } = this.props;
    const style = { ...restProps.style };

    const index = this.props['data-row-key'];
    const key = `drag${this.props['data-row-key']}`;
    return (
      <Draggable key={key} draggableId={key} index={index}>
        {(provided, snapshot) => {
          let rowClass = className;
          if (snapshot.isDragging) {
            rowClass += ' dragging';
          }
          return (
            <tr
              className={rowClass}
              style={style}
              draggableId={key}
              ref={provided.innerRef}
              {...restProps}
              {...provided.dragHandleProps}
              {...provided.draggableProps}
            />
          );
        }}
      </Draggable>);
  }
}
const reorder = (result: any, startIndex: any, endIndex: any) => {
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

class DragSortingTable extends React.Component<any, any> {
  state = {
    dataList: [],
  };

  components = {
    body: {
      cell: CustomTableCell,
      row: DragableBodyRowStyled,
    },
    header: {
      cell: CustomTableTitle,
    },
  };

  componentDidMount = () => {
    this.setState({ dataList: this.props.dataSource });
  };

  onDragEnd = (result: any) => {
    // dropped outside the list
    const { dataList } = this.state;
    if (!result.destination) {
      return;
    }
    const data = [...dataList];
    const newList = reorder(
      data,
      result.source.index,
      result.destination.index,
    );
    this.setState({ dataList: newList });
    this.props.handleDragSort(newList);
  };

  render() {
    const { classes } = this.props;
    const { dataList } = this.state;
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided: any) => {
            console.log('provided', provided);
            return <div ref={provided.innerRef}>
              <Table
                bordered
                className={classes.mainTable}
                columns={this.props.columns}
                dataSource={dataList}
                {...provided.droppableProps}
                components={this.components}
                pagination={false}
                rowKey={(record, index) => index}
              />
            </div>;
          }}
        </Droppable>
      </DragDropContext>
    );
  }
}
const DragableBodyRowStyled = withStyles(styles, { injectTheme: true })(DragableBodyRow);
const DragSortingTableStyled = withStyles(styles, { injectTheme: true })(DragSortingTable);
export default DragSortingTableStyled;
