import { Select } from 'antd';
import React from 'react';
import withStyles, { WithStylesProps } from 'react-jss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PageData } from 'src/types/page.types';
import { ViewFilterType } from 'src/types/view.types';
import { fetchViewDataObjects } from 'src/actions/genericPageActions';

const styles = () => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    select: {
        width: '100px',
    },
    label: {
        marginRight: '3px',
    },
});

interface IProps extends
    WithStylesProps<ReturnType<typeof styles>>,
    ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {
        filterData: ViewFilterType;
        options: {
            key: string,
            label: string,
            booleanValue?: boolean,
        }[];
        pageId: string;
        viewIndex: number;
        sectionIndex: number;
        handleFilterChange?: (value: any) => void;
        booleanFilter?: boolean;
}

const CustomerSelectFilter = (props: IProps) => {
    const {
        filterData,
        options,
        classes,
        pageId,
        viewIndex,
        sectionIndex,
        fetchViewDataObjects,
        viewData,
        handleFilterChange,
        booleanFilter,
    } = props;

    const onChange = (value) => {
        const filterObj = {
            [filterData.id]: value,
        };

        if (booleanFilter) {
            filterObj[filterData.id] = options.find(o => o.key === value).booleanValue;
        }

        console.log('FILTER VALUE: ', filterObj);
        if (filterData.showOnMainDashboard) {
            viewData.applyFilters(filterObj);
            fetchViewDataObjects({
                viewData,
                viewIndex,
                sectionIndex,
                pageId,
            });
        } else if(handleFilterChange) {
            // store the new filter value as it will get applied on clicking apply button
            handleFilterChange(filterObj);
        }
    };

    const getFilterValue = () => {
        let value = viewData.getFilterValue(filterData) || null;
        if (booleanFilter) {
            value = options.find(v => v.booleanValue === value).key;
        }

        return value;
    }

    return <div className={classes.container}>
        <div className={classes.label}>{filterData.name}</div>
        <Select
            defaultValue={getFilterValue()}
            onChange={onChange}
            className={classes.select}
        >
            {options?.map((option) => {
                return (
                    <Select.Option key={option.key} value={option.key}>
                        {option.label}
                    </Select.Option>
                );
            })}
        </Select>
    </div>;
};

const mapStateToProps = ({ genericPageReducer }, ownProps) => {
    const { pageId, viewIndex, sectionIndex } = ownProps;
    const pageData: PageData = genericPageReducer[pageId];
    const viewData = pageData.metadata.sectionList[sectionIndex][viewIndex];
    return {
        viewData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchViewDataObjects,
    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(
    withStyles(styles, { injectTheme: true })(CustomerSelectFilter),
);
