import { CssType, ThemeType } from '../../../../../theming/jssTypes';
const styles = (theme: ThemeType): CssType => ({
  tableMain: {
    '& .ant-table-thead > tr > th.ant-table-column-has-filters .ant-table-column-sorters': {
      paddingRight: '34px',
    },
    '& .ant-table-thead > tr > th .ant-table-column-sorters': {
      paddingRight: '8px',
    },
  },
  uploadDirection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    margin: '16px',
  },
  customHeaderLayout: {
    color: theme.errorColor,
    fontSize: '14px',
    marginLeft: '16px',
  },
  row: {
    display: 'flex',
  },
  uploadCol: {
    fontSize: '15px',
    padding: '16px',
    fontWeight: 'bold',
  },
  tableLayout: {
    marginLeft: '16px',
    marginRight: '16px',
    overflow: 'auto',
  },
  footerLayout: {
    margin: '16px',
    paddingBottom: '15px',
  },
  downloadButton: {
    float: 'left',
    color: `${theme.primaryColor} !important`,
    background: 'transparent !important',
    boxShadow: 'none !important',
    border: 'none !important',
    fontSize: '14px',
  },
});
export default styles;
