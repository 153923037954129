import Icon from '@ant-design/icons';
import * as React from 'react';

const AttendanceSvg = (props) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Setup_Tooltip" transform="translate(-16.000000, -842.000000)">
          <g id="Group-6" transform="translate(0.000000, 58.000000)">
            <g id="Attendance" transform="translate(16.000000, 784.000000)">
              <rect
                id="Rectangle"
                fillRule="nonzero"
                x="0"
                y="0"
                width="24"
                height="24"
              ></rect>
              <g
                id="Group-7"
                transform="translate(3.500000, 2.499613)"
                fill="#666666"
              >
                <path
                  d="M1.59796149,17.3961139 L15.4020385,17.3961139 L15.4020385,3.18573754 L1.59796149,3.18573754 L1.59796149,17.3961139 Z M16.2010193,19.0003866 L0.798980747,19.0003866 C0.358097395,19.0003866 0,18.6408749 0,18.1982503 L0,2.38360122 C0,1.9409766 0.358097395,1.5814649 0.798980747,1.5814649 L16.2010193,1.5814649 C16.6419026,1.5814649 17,1.9409766 17,2.38360122 L17,18.1982503 C17,18.6408749 16.6419026,19.0003866 16.2010193,19.0003866 L16.2010193,19.0003866 Z"
                  id="Fill-1"
                ></path>
                <path
                  d="M16.2011155,6.34905392 L1.51815968,6.34905392 C1.07727633,6.34905392 0.719178935,5.98954222 0.719178935,5.5469176 C0.719178935,5.10332655 1.07727633,4.74478128 1.51815968,4.74478128 L16.2011155,4.74478128 C16.6419989,4.74478128 17.0000963,5.10332655 17.0000963,5.5469176 C17.0000963,5.98954222 16.6419989,6.34905392 16.2011155,6.34905392"
                  id="Fill-3"
                ></path>
                <path
                  d="M5.41949604,3.97685656 C4.97861268,3.97685656 4.62051529,3.61734486 4.62051529,3.17472024 L4.62051529,0.802136317 C4.62051529,0.359511699 4.97861268,0 5.41949604,0 C5.86037939,0 6.21847678,0.359511699 6.21847678,0.802136317 L6.21847678,3.17472024 C6.21847678,3.61734486 5.86037939,3.97685656 5.41949604,3.97685656"
                  id="Fill-4"
                ></path>
                <path
                  d="M11.5806965,3.97685656 C11.1398131,3.97685656 10.7817157,3.61734486 10.7817157,3.17472024 L10.7817157,0.802136317 C10.7817157,0.359511699 11.1398131,0 11.5806965,0 C12.0215798,0 12.3796772,0.359511699 12.3796772,0.802136317 L12.3796772,3.17472024 C12.3796772,3.61734486 12.0215798,3.97685656 11.5806965,3.97685656"
                  id="Fill-5"
                ></path>
                <path
                  d="M7.6107248,14.2559919 C7.38835787,14.2559919 7.17465459,14.1622482 7.02352208,13.9979552 L4.83161948,11.6137742 C4.53320498,11.2880875 4.55342016,10.7797457 4.87782559,10.4801526 C5.2012684,10.1786267 5.70568516,10.1998881 6.00698754,10.5255748 L7.58280861,12.2400203 L10.9683692,8.19358087 C11.2523443,7.85339776 11.7557984,7.81087487 12.0946433,8.09500509 C12.4325255,8.38010173 12.4768063,8.88651068 12.1928313,9.22476094 L8.22295583,13.9699288 C8.07471121,14.1458189 7.85908267,14.2501933 7.62997735,14.2559919 C7.62323896,14.2559919 7.61746319,14.2559919 7.6107248,14.2559919"
                  id="Fill-6"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const AttendanceIcon = (props) => (
  <Icon component={AttendanceSvg} {...props} />
);
