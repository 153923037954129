import * as moment from 'moment';
import {
    CUSTOMER_FEEDBACK_DATA_FETCHED,
    CUSTOMER_FEEDBACK_LOADING_ENABLE,
    CUSTOMER_FEEDBACK_ERROR,
    CUSTOMER_FEEDBACK_SET_PAGINATION,
    CUSTOMER_FEEDBACK_FILTERS_APPLIED,
    CUSTOMER_FEEDBACK_RESET_PAGINATION,
    CUSTOMER_FEEDBACK_RESET_FILTER,
    CUSTOMER_FEEDBACK_REMOVE_SEARCH_FILTERS,
    CUSTOMER_ACTIVITY_FEEDBACK_SUMMARY_METRICS_DATA,
    CUSTOMER_ACTIVITY_TOGGLE_FEEDBACK_SELECTED_SUMMARY_METRIC,
    CUSTOMER_ACTIVITY_SUMMARY_METRICS_DISABLE_LOADING,
} from '../actions/constants';
const defaultState = {
  isFeedbackLoading:true,
  feedbackData: [],
  feedbackError: null,
  selectedFilters: {
    fromDate: moment().subtract(7, 'd').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
    customer: [],
    status: [],
    // origin: [],
    // destination: [],
    carrier: [],
    conditions:[],
    timeFilterKey:'last_feedback_completion_time',
    searchBy: 'courier_partner_reference_number',
    searchKey: '',
    ivr: undefined,
    feedbackStatus:undefined,
    feedbackAttemptStatus: undefined,
    rating:[],
    raiseDispute: undefined,
  },
  pagination: {
    currentPageNo:1,
    resultPerPage: 10,
    nextOrPrev:'first',
    isNextPresent: null,
  },
  selectedSummaryMetrics:[],
  feedSummaryMetricsData:[],
  summaryMetricsLoading:true,
  columnsToSort: [
    { column_id:'attempt_count', pretty_name:'Attempt Count' },
    { column_id:'false_attempt_count', pretty_name:'False Attempt Count' },
    { column_id:'feedback_count', pretty_name:'Feedback Count' },
    { column_id:'is_fake_delivery', pretty_name:'Fake Delivery' },
    { column_id:'feedback_time', pretty_name:'Feedback Time' },
  ],
};

export default function (state: any = defaultState, action) {
  switch (action.type){
    case CUSTOMER_FEEDBACK_LOADING_ENABLE: {
      return {
        ...state,
        isFeedbackLoading: true,
        feedbackData: [],
        feedbackError: null,
      };
    }
    case CUSTOMER_FEEDBACK_SET_PAGINATION : {
      return{
        ...state,
        pagination: {
          ...state.pagination,
          resultPerPage: 10,
          currentPageNo: action.data.currentPageNo,
          nextOrPrev: action.data.nextOrPrev,
        },
      };
    }
    case CUSTOMER_FEEDBACK_RESET_PAGINATION: {
      return {
        ...state,
        pagination: defaultState.pagination,
      };
    }
    case CUSTOMER_FEEDBACK_DATA_FETCHED: {
      return {
        ...state,
        isFeedbackLoading: false,
        feedbackData: action.data.feedback,
        feedbackError: null,
        pagination: {
          ...state.pagination,
          resultPerPage: 10,
          currentPageNo: action.data.page,
          isNextPresent: action.data.isNextAvailableForFeedback ||
           action.data.isNextAvailableForIvr,
          isNextAvailableForFeedback:action.data.isNextAvailableForFeedback,
          isNextAvailableForIvr: action.data.isNextAvailableForIvr,
          // lastTrackingRequestId: getLastTrackingColumnId(action.data),
        },
      };
    }
    case CUSTOMER_FEEDBACK_ERROR: {
      return{
        ...state,
        isFeedbackLoading: false,
        feedbackData: null,
        feedbackError: action.data,
      };
    }
    case CUSTOMER_FEEDBACK_RESET_FILTER: {
      return {
        ...state,
        selectedFilters: defaultState.selectedFilters,
        pagination: defaultState.pagination,
      };
    }
    case CUSTOMER_FEEDBACK_FILTERS_APPLIED: {
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          ...action.data,
        },
      };
    }

    case CUSTOMER_ACTIVITY_FEEDBACK_SUMMARY_METRICS_DATA: {
      return{
        ...state,
        feedSummaryMetricsData:action.data,
      };
    }
    case CUSTOMER_ACTIVITY_TOGGLE_FEEDBACK_SELECTED_SUMMARY_METRIC: {
      let selectedSummaryMetrics:any = null;
      if (action.data === state.selectedSummaryMetrics[0]) {
        selectedSummaryMetrics = [];
      }else {
        selectedSummaryMetrics = [action.data];
      }
      return{
        ...state,
        selectedSummaryMetrics,
      };
    }
    case CUSTOMER_ACTIVITY_SUMMARY_METRICS_DISABLE_LOADING: {
      return {
        ...state,
        summaryMetricsLoading:false,
      };
    }
    case CUSTOMER_FEEDBACK_REMOVE_SEARCH_FILTERS: {
      const { searchBy, searchKey, ...x } =  state.selectedFilters;
      return{
        ...state,
        selectedFilters: x,
      };
    }
    default: {
      return state;
    }
  }
}
