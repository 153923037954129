import { API_BASE_URL } from '../globals';
import {
  GET_SHELF_TABLE_DATA,
  ADD_CONSIGNMENTS_TO_SHELF,
  GET_ALL_CONSIGNMENTS_FROM_SHELF,
  REMOVE_CONSIGNMENTS_FROM_SHELF,
} from './routes';
import {
  requestModelShelf,
  responseModelShelf,
} from '../model/consignmentView';
import { morphism } from 'morphism';
import { GET, POST } from './apiHandler';

export const getShelfTableData = async (body) => {
  const params = {
    ...morphism(requestModelShelf, body),
  };
  const response = await GET(
    `${API_BASE_URL}${GET_SHELF_TABLE_DATA}`,
    params,
  );
  if (response.isSuccess) {
    return {
      data: morphism(responseModelShelf, response.data),
      isSuccess: true,
    };
  }

  return response;
};

export const addCNtoShelf = async (body) => {
  return POST(`${API_BASE_URL}${ADD_CONSIGNMENTS_TO_SHELF}`, body);
};

export const getAllConsignmentsFromShelf = async (params) => {
  return GET(`${API_BASE_URL}${GET_ALL_CONSIGNMENTS_FROM_SHELF}`, params);
};

export const removeCNFromShelf = async (body) => {
  return POST(`${API_BASE_URL}${REMOVE_CONSIGNMENTS_FROM_SHELF}`, body);
};
