import { Drawer, Table } from 'antd';
import React from 'react';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '@theming/jssTypes';
import * as moment from 'moment';

const styles = (theme: ThemeType): CssType  => ({
    headerCard:{
        border: '1px solid #D9D9D9',
        marginBottom:'25px',
        padding: '1rem',
        boxShadow: '0px 2px 8px 0 rgba(0, 0, 0, 0.15)',
        borderRadius: '8px',
        backgroundColor: '#ffffff',
    },
    componentDiv: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
    },
    componentItem: {
        margin: '0 1.25rem',
    },
    hint:{
        color: '#595959',
        fontSize: '12px',
        fontWeight: '400',
    },
    content: {
        color: '#262626',
        fontSize: '14px',
        fontWeight: '500',
    },
});

const SupplyRequestDetailsDrawer = (props) => {
    const {
      isVisible,
      onClose,
      title,
      classes,
      data,
      columns,
    } = props;

    const formatDate = (creationDate) => {
        const date = moment(creationDate).format('llll');
        return date.slice(9,11) + date.slice(4,8) + ', ' + date.slice(0,3) + ' | ' + date.slice(18);
    };

    const formatDestinationDetails = (data) => {
        return `${data.name}, 
        ${data.address_line_1}, 
        ${data.address_line_2 ? data.address_line_2 + ', ': ''}
        ${data.city}, 
        ${data.pincode}`;
    };

    const renderHeaderCard = () => {
        return (
            <div className={classes.headerCard}>
                <div className={classes.componentDiv}>
                    <div className={classes.componentItem}>
                        <div className={classes.hint}>Customer Code</div>
                        <div className={classes.content}>{data.customer_code}</div>
                    </div>
                    <div className={classes.componentItem}>
                        <div className={classes.hint}>Destination Details</div>
                        <div className={classes.content}>{formatDestinationDetails(data)}</div>
                    </div>
                    <div className={classes.componentItem}>
                        <div className={classes.hint}>Creation Date</div>
                        <div className={classes.content}>{formatDate(data.created_at)}</div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div>
            <Drawer
                title={data.supply_request_id}
                width={900}
                onClose={onClose}
                visible={isVisible}
                bodyStyle={{
                  paddingBottom: 80,
                }}
            >
             {renderHeaderCard()}
             <Table
                columns={columns}
                dataSource={data.pieces_detail}
                pagination={false}
                scroll={{
                y: 240,
                }}
            />
            </Drawer>
        </div>
    );

};

const SupplyRequestDetailsDrawerStyled =
    withStyles(styles, { injectTheme: true })(SupplyRequestDetailsDrawer);
export default SupplyRequestDetailsDrawerStyled;
