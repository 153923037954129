import * as React from 'react';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '../../../theming/jssTypes';
import { connect } from 'react-redux';
import { Modal } from 'antd';

const styles = (theme: ThemeType): CssType => ({
  image: {
    height: '450px',
    width: '450px',
  },
});
const OpenImageModal = (props: any) => {
  const {
    onModalClose,
    isVisible,
    classes,
    imageUrl,
    title = 'Sorter Image',
  } = props;

  const handleModalClose = () => {
    onModalClose(false);
  };

  return (
    <Modal
      title={title}
      visible={isVisible}
      onCancel={handleModalClose}
      destroyOnClose
      maskClosable={false}
      width={500}
      footer={null}
    >
      <div>
        <img className={classes.image} src={imageUrl} />
      </div>
    </Modal>
  );
};

export default withStyles(styles, { injectTheme: true })(OpenImageModal);
