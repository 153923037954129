import * as React from 'react';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '@theming/jssTypes';
import { Modal, Image } from 'antd';
import { ReadWithSignedUrl } from '@components/utilities/ReadWithSignedUrl'

const styles = (theme: ThemeType): CssType => ({
  image: {
    maxHeight: '450px',
    maxWidth: '450px',
  },
});
const PodImageModal = (props: any) => {
  const { handleModalClose, isVisible, classes, record, title } = props;

  return (
    <Modal
      title={title}
      visible={isVisible}
      onCancel={handleModalClose}
      destroyOnClose
      maskClosable={false}
      width={500}
      footer={null}
    >
      <Image.PreviewGroup>
        {record.pod_image_url?.map((url) => {
          return (
            <ReadWithSignedUrl
              url={url}
              renderingCb={
                (signedUrl) => <Image className={classes.image} src={signedUrl} placeholder={true} />
              }
            />
          );
        })}
      </Image.PreviewGroup>
    </Modal>
  );
};

export default withStyles(styles, { injectTheme: true })(PodImageModal);
