export const HUB_TYPE_MAPPING = {
    dc: 'Planning Hub',
    upcountry: 'Upcountry Hub',
    branch: 'Branch',
    franchisee: 'Franchisee',
    virtual: 'Virtual',
    adm_route: 'Adm Route',
    ltl: 'LTL',
    rto: 'RTO',
};
