import { API_BASE_URL } from '../globals';
import {
    FETCH_VEHICLE_REQUESTS,
    UPDATE_VEHICLE_REQUEST,
    CREATE_VEHICLE_REQUEST,
    HANDLE_ACTION_CHANGE_VEHICLE_REQUEST,
} from './routes';
import { POST } from './apiHandler';

export const fetchVehicleRequest = async (params) => {
    return POST(`${API_BASE_URL}${FETCH_VEHICLE_REQUESTS}`, params);
};

export const createVehicleRequest = async (body) => {
    return POST(`${API_BASE_URL}${CREATE_VEHICLE_REQUEST}`, body);
};

export const updateVehicleRequest = async (body) => {
    return POST(`${API_BASE_URL}${UPDATE_VEHICLE_REQUEST}`, body);
};

export const handleActionChangeVehicleRequest = async (body) => {
    return POST(`${API_BASE_URL}${HANDLE_ACTION_CHANGE_VEHICLE_REQUEST}`, body);
};
