import * as React from 'react';
import { connect } from 'react-redux';
import { generateDevrevAuthToken } from 'src/api/auth';
import { DEVREV_APP_ID } from 'src/globals';

type IProps = ReturnType<typeof mapStateToProps>;

const DevrevWidget = (props: IProps) => {
  const { use_devrev_for_customer_support } = props;

  const initializeScript = async () => {
    if (!DEVREV_APP_ID) return;
    const response = await generateDevrevAuthToken();
    const sessionToken = response.data?.access_token;
    const script = document.createElement('script');
    script.src = 'https://plug-platform.devrev.ai/static/plug.js';
    script.onload = () => {
      const plugSDK = window['plugSDK'];
      if (plugSDK) {
        plugSDK.init({
          app_id: DEVREV_APP_ID,
          session_token: sessionToken,
        });
        console.log('plugSDK initialised');
      } else {
        console.error('Error initialising plugSDK');
      }
    }
    document.body.appendChild(script);
  };

  React.useEffect(() => {
    if (use_devrev_for_customer_support) {
      initializeScript();
    }
  });

  return <></>;
};

const mapStateToProps = ({ masterData }) => {
  return {
    use_devrev_for_customer_support: masterData.use_devrev_for_customer_support,
  }
};
export default connect(mapStateToProps)(
  DevrevWidget,
);
