import { SET_UI_THEME } from './constants';

interface SetRTLAction {
  type: typeof SET_UI_THEME;
  data: {
    isRTL: boolean;
  };
}

interface SetUIThemeAction {
  type: typeof SET_UI_THEME;
  data: {
    primaryColor: string;
    fontFamily: string;
    fontSize: string;
  };
}

export const setRTL = (isRTL: boolean): SetRTLAction => ({
  type: SET_UI_THEME,
  data: {
    isRTL,
  },
});

export const setUITheme = (newTheme: {
  primaryColor: string;
  fontFamily: string;
  fontSize: string;
}): SetUIThemeAction => ({
  type: SET_UI_THEME,
  data: newTheme,
});
