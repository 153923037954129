import { getApprovalRequests, getApprovalMetrics } from '@api/dashboard';
import { searchHubWithrealFilter } from '@api/dashboard';

const viewControl = {
  pageTitle: '',
  buckets: ['pending', 'approved', 'rejected'],
  navBar: {
    left: ['single_hub_search', 'date_filter'],
    right: [],
  },
  dateFilterOptions: [
    { key: 'created_at', val: 'Created At' },
    { key: 'updated_at', val: 'Updated At' },
  ],
  fetchData: getApprovalRequests,
  fetchSummaryMetrics: getApprovalMetrics,
  searchHubData: searchHubWithrealFilter(true),
  singleHubSearchText: 'Hub',
  hubSearchPlaceholder: 'Select Hub',
  searchText: 'Search for hubs',
};
const buckets = {
  pending: {
    name: 'Pending',
    actions: ['reject', 'approve'],
  },
  approved: {
    name: 'Approved',
    actions: [],
  },
  rejected: {
    name: 'Rejected',
    actions: [],
  },
};

export default { viewControl, buckets };
