import * as React from 'react';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '@theming/jssTypes';
import { Modal, message, Select, Radio, Input } from 'antd';
import { connect } from 'react-redux';
import ExcelUpload from '@components/common/ExcelUpload';
import FailureArrayHandler from '@components/common/FailureArrayHandler';
import {
  getCancellationReasons,
  validateInScannedConsignments,
  downloadSampleCsvCNUpdate,
  getRTOReasons,
  updateConsignmentDestinationHub,
} from '@api/dashboard';
import { downloadFileData, downloadFileDataWithUrl } from '../../../../utils/utils';
import { fetchPublishedLabelList } from '@api/labelSetup';
import { useHistory } from 'react-router';
import { defaultSampleLink, sampleLinkWithNotes } from './utils';
import { getCodeFreeLabelType } from '@src/utils/utils';
import SearchTagsInput from '@components/common/SearchSelectTagsInput';
import { searchHubData } from '@api/genericConsignmentView';
import { getReverseNprReasons } from '@api/genericConsignmentView';
import * as lodash from 'lodash';
import ScanErrorModal from '@components/common/ScanErrorModal';
import { renameKeySources, headerKeyMapping } from '@components/pages/dashboard/UploadExcel/UploadExcelModal.utils';
import { GenericBulkUploadService } from 'src/components/common/GenericBulkUploadService';

const Option = Select.Option;
const RadioGroup = Radio.Group;
const styles = (theme: ThemeType): CssType => ({
  destinationHub: {
    width: '100%',
    marginBottom: '10px',
  },
  reason: {
    fontSize: '12px',
  },
  piecesDetailContainer: {
    display: 'flex',
    gap: '1rem',
    marginTop: '1.5rem',
  },
  customPrimaryButton: {
    backgroundColor: theme.colors.primaryColor,
    color: theme.colors.textOnDarkBg,
  },
});

const BulkUploadModal = (props) => {
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [notesList, setNotesList] = React.useState([]);
  const [reasonList, setReasonList] = React.useState<any>([]);
  const [labelConfig, setLabelConfig] = React.useState<any>({
    isLoading: true,
    labels: [],
  });
  const [reason, setReason] = React.useState<any>(undefined);
  const [cnList, setCNList] = React.useState<any>([]);
  const [destHub, setDestHub] = React.useState<any>(undefined);
  const [searchedHubs, setSearchedHubs] = React.useState([]);
  const [isErrorModalVisible, setErrorModalVisible] = React.useState(false);
  const [failureArray, setFailureArray] = React.useState([]);
  const [isSmallLabel, setSmallLabel] = React.useState(false);
  const [selectedLabel, setSelectedLabel] = React.useState(null);
  const [additionalFieldList, setAdditionalFieldList] = React.useState<any>([]);
  const [piecesList, setPiecesList] = React.useState<any>({
    completePieces: [],
    missingPieces: [],
    partialPieces: [],
    partialCN: [],
  });
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [validationLoader, setValidationLoader] = React.useState(false);
  const [labelList, setLabelList] = React.useState<any>([]);

  const {
    onModalClose,
    currHub,
    isVisible,
    classes,
    bulkLabelType,
    currentActionConfig,
    hideNotes,
    actionStatus,
    onOkClick,
    showA6Option,
    showCustomLabel,
    customLabelOptions,
    useCustomLabelOnly,
    enablePieceScanning,
    enableCodeFreeLabel,
    minSearchLength,
    showInputTimestampForDelivery,
  } = props;
  const history = useHistory();
  const minimumHubSearchLength = minSearchLength || 2;

  const pieceScanningActions = [
    'mark_delivered_bulk_upload',
    'mark_onhold_bulk_upload',
    'release_onhold_bulk_upload',
    'cancel_bulk_upload',
    'cn_label_bulk_upload',
  ];

  const enablePieceScanningForAction =
    enablePieceScanning &&
    pieceScanningActions.includes(currentActionConfig.id);

  React.useEffect(() => {
    if (currentActionConfig.requireReason) {
      fetchReasons();
    }
    if (
      ['cn_label_selected', 'cn_label_bulk_upload'].includes(
        currentActionConfig.id,
      ) &&
      enableCodeFreeLabel
    ) {
      fetchCodeFreeLabels();
    }
  }, []);

  const fetchReasons = async () => {
    let response;
    if (currentActionConfig.id === 'mark_npr_bulk_upload') {
      response = await getReverseNprReasons({ hub_id: currHub?.id });
    } else if (currentActionConfig.id === 'cancel_bulk_upload') {
      response = await getCancellationReasons();
    } else if (currentActionConfig.id === 'set_rto_bulk_upload') {
      response = await getRTOReasons();
    } else {
      response = { 
        isSuccess: true,
        data: {}
      };
    }
    if(response.isSuccess){
      setReasonList(response.data);
    } else {
      message.error(response.errorMessage);
    }
  };

  const fetchCodeFreeLabels = async () => {
    const response = await fetchPublishedLabelList();
    if (response.isSuccess) {
      const activeLabels = response.data?.metadata_list?.filter(
        (label) => label.is_active,
      );
      setLabelConfig({
        ...labelConfig,
        labels: activeLabels,
        loading: false,
      });
    } else {
      message.error(response.errorMessage);
      setLabelConfig({
        ...labelConfig,
        loading: false,
      });
    }
  };

  const getLabelOptions = () => {
    const arr = [];
    if (showA6Option && !useCustomLabelOnly) {
      arr.push('a4', 'a6');
    }
    if (showCustomLabel) {
      customLabelOptions.forEach((elem) => {
        arr.push(elem);
      });
    }
    return arr;
  };

  const getReasons = () => {
    const reasonlist = Object.keys(reasonList).length
      ? createOptionsFromObject(reasonList)
      : [];
    return generateOptionsFromList(reasonlist);
  };
  const generateOptionsFromList = (list) => {
    return (
      list?.map((ele) => (
        <Option key={ele.key} value={ele.key}>
          {ele.label}
        </Option>
      )) || []
    );
  };
  const createOptionsFromObject = (obj) => {
    return Object.keys(obj).map((d) => {
      return { key: d, label: obj[d] };
    });
  };

  const getPrintLabelParams = () => {
    return {
      reference_number_array: Array.from(cnList),
      hub_id: currHub?.id,
      label_type: bulkLabelType,
      is_small: isSmallLabel,
      showCustomLabel:
        customLabelOptions && customLabelOptions.includes(selectedLabel),
      customLabelSize: selectedLabel,
    };
  };

  const getDefaultParams = () => {
    return {
      consignments: Array.from(cnList),
      hub_id: currHub?.id,
      request_type: 'bulk_upload_via_excel',
    };
  };

  const getOutscanParams = () => {
    const defaultParams = getDefaultParams();
    return {
      ...defaultParams,
      destination_hub_id: destHub?.key,
    };
  };

  const getV1ActionsParams = () => {
    const defaultParams = getDefaultParams();
    const cnListWithNotes = cnList.map((ele, index) => {
      return {
        reference_number: ele,
        notes:
          currentActionConfig.showNotes && !hideNotes
            ? notesList[index]
            : undefined,
      };
    });
    return {
      ...defaultParams,
      status: actionStatus,
      consignments: cnListWithNotes,
    };
  };

  const getParamsWithReason = () => {
    const defaultParams = getDefaultParams();
    return {
      ...defaultParams,
      reason_code: reason?.key,
      reason: reason?.label,
    };
  };

  const getCancelParams = () => {
    return {
      consignments: cnList.map((consignment) => {
        return { reference_number: consignment };
      }),
      reason_code: reason?.key,
      reason: reason?.label,
      hub_id: currHub?.id,
      request_type: 'bulk_upload_via_excel',
    };
  };
  const getCnEligibleForLmPlanningParams = () => {
    return {
      consignments: cnList.map((consignment, index) => {
        const isEligible = String(notesList[index]).toLowerCase() === 'true';
        return {
          reference_number: consignment,
          is_eligible_for_lm_planning: isEligible,
        };
      }),
      hub_id: currHub?.id,
      request_type: 'bulk_upload_via_excel',
    };
  };

  const getExceptionActionsParams = () => {
    const defaultParams = getDefaultParams();

    const consignmentsData = cnList.map((ele, index) => {
      return currentActionConfig.showExceptionCodeWithNotes
        ? {
            reference_number: ele,
            notes:
              notesList[index] && !hideNotes ? notesList[index] : undefined,
            exception_code: additionalFieldList[index]
              ? additionalFieldList[index]
              : undefined,
          }
        : {
            reference_number: ele,
            notes:
              notesList[index] && !hideNotes ? notesList[index] : undefined,
          };
    });

    return {
      ...defaultParams,
      source: 'dashboard',
      consignments: consignmentsData,
    };
  };

  const getUpdateConsignmentDestinationHubParams = () => {
    return {
      consignments: additionalFieldList,
      hub_id: currHub?.id,
    };
  };

  const getMarkDeliveryParams = () => {
    return {
      consignments : additionalFieldList.map((ele) => {
        const amountCollected = !isNaN(Number(ele?.cod_amount_collected))
          ? Number(ele?.cod_amount_collected) : null;
        let paymentDetails = null;
        if(amountCollected){
          paymentDetails = [{
            cod_collection_mode : ele?.mode_of_payment,
            cod_amount: amountCollected,
            transaction_id: ele?.transaction_id,
          }];
        }
        return {
          reference_number: ele?.reference_number,
          payment_details: paymentDetails,
          notes: ele?.notes || null,
          delivery_timestamp: ele?.delivery_timestamp || null,
        };
      }),
      hub_id: currHub?.id,
    };
  };

  const getBody = () => {
    switch (currentActionConfig.id) {
      case 'cn_label_bulk_upload':
      case 'credit_note_bulk_upload':
        return getPrintLabelParams();
      case 'outscan_bulk_upload':
        return getOutscanParams();
      case 'inscan_bulk_upload':
      case 'mark_rto_bulk_upload':
      case 'pickup_and_delivery_bulk_upload':
      case 'revoke_pickup_and_delivery_bulk_upload':
      case 'mark_onhold_bulk_upload':
      case 'release_onhold_bulk_upload':
      case 'consignment_sieze_bulk_upload':
      case 'shipment_clear_successfully_bulk_upload':
      case 'pending_custom_bulk_upload':
      case 'mark_clearance_origin_bulk_upload':
      case 'mark_clearance_destination_bulk_upload':
      case 'inscan_at_gateway_bulk_upload':
      case 'pickup_completed_bulk_upload':
      case 'set_rto_bulk_upload':
      case 'revoke_delivery_bulk_upload':
      case 'mark_lost_bulk_upload':
        return getV1ActionsParams();
      case 'mark_npr_bulk_upload':
        return getParamsWithReason();
      case 'cancel_bulk_upload':
        return getCancelParams();
      case 'apply_exception_bulk_upload':
      case 'release_exception_bulk_upload':
        return getExceptionActionsParams();
      case 'mark_cn_eligible_for_lm_planning_bulk_upload':
        return getCnEligibleForLmPlanningParams();
      case 'mark_delivered_bulk_upload':
        return getMarkDeliveryParams();
      case 'update_consignment_destination_hub_bulk_upload':
        return getUpdateConsignmentDestinationHubParams();
      default:
        return getDefaultParams();
    }
  };

  const getValidConsignmentList = async (list) => {
    const completePieces = piecesList.completePieces.map((item) => {
      if (item) return item.reference_number;
    });

    const partialPieces = piecesList.partialPieces.map((item) => {
      if (item) return item.reference_number;
    });

    const res = await validateInScannedConsignments({
      hub_id: currHub?.id,
      consignments: [...completePieces, ...partialPieces],
    });

    const missingPieces = res.data?.missing_consignments;

    if (missingPieces?.length) {
      setPiecesList({
        ...piecesList,
        missingPieces,
      });
      return false;
    }
    return true;
  };
  const handleSampleFileDownload = async () => {
    const params = {
      action_type: currentActionConfig.id,
    };
    if(currentActionConfig.sampleFileDownloadUrl) {
      const url = await currentActionConfig.sampleFileDownloadUrl();
      downloadFileDataWithUrl(url, 'sample-file', null, true);
      return;
    }
    const response = await downloadSampleCsvCNUpdate(params);
    if (response.isSuccess) {
      downloadFileData(response.data, 'sample', '.csv');
    } else {
      message.error(response.errorMessage);
    }
  };

  const handleBulkUploadService = (body) => {
    const getBulkUploadParams = () => {
      switch(currentActionConfig.id){
        case 'update_consignment_destination_hub_bulk_upload':
          return {
            heading: 'Change Destination Hub',
            data: body.consignments,
            apiCallFn: updateConsignmentDestinationHub,
            getRequestBody: (arrData) => {
              return {
                consignments: arrData,
                hub_id: body?.hub_id,
              };
            },
            failureArrayKeysToShow: ['reference_number', 'message','reason'],
          };
        default:
          return {};
      }
    };

    const bulkUploadParams = getBulkUploadParams();
    GenericBulkUploadService.uploadData(bulkUploadParams);
  };

  const handleSubmit = async () => {
    if (cnList.length === 0) {
      message.warning('Please enter a valid consignment number');
      return;
    }
    if (!destHub && currentActionConfig.id === 'outscan_bulk_upload') {
      message.warning('Please select destination hub');
      return;
    }
    if (currentActionConfig.requireReason && !reason) {
      message.error('Please select a reason');
      return;
    }
    if (currentActionConfig.onOkClick) {
      onOkClick();
      return;
    }
    if (
      enableCodeFreeLabel &&
      (currentActionConfig.id === 'cn_label_selected' ||
        currentActionConfig.id === 'cn_label_bulk_upload') &&
      (!selectedLabel || labelConfig.loading)
    ) {
      message.error('Please select a label type');
      return;
    }
    if (enablePieceScanningForAction) {
      setConfirmLoading(true);
      const isValid = await getValidConsignmentList(cnList);
      if (!isValid) {
        setShowErrorModal(true);
        setConfirmLoading(false);
        return;
      }
    }
    const body = getBody();
    if (currentActionConfig.useGenericBulkUploaderService){
      handleBulkUploadService(body);
      onModalClose(false);
      return;
    }
    setConfirmLoading(true);
    const response = await currentActionConfig.actionApiCall(body);
    if (response.isSuccess) {
      if (response?.data?.failures?.length) {
        setFailureArray(response.data.failures);
        setErrorModalVisible(true);
      } else {
        message.success(currentActionConfig.successMessage);
        currentActionConfig.routeOnSuccess &&
          history.push(currentActionConfig.routeOnSuccess);
        onModalClose(true);
      }
    } else {
      onModalClose(false);
      message.error(response.errorMessage);
    }
    setConfirmLoading(false);
  };

  const handleParsedData = async (data) => {

    /**
     * If First row is header - we need to parse fields separately as it gives objects
     * This logic will be helpful when more number of dynamic key-based fields are to be parsed
    */

    const list: any = [];
    const notes: any = [];
    const additionalFields: any = [];
    const isFirstRowHeader = setIsFirstRowAsHeader(currentActionConfig);
    if(isFirstRowHeader) {
      // renameKeySources Eg: mark_delivered_upload_excel -> mark_delivered have same headers.
      // Instead of re-declaration just re-use it.
      const headerName = lodash.get(renameKeySources, currentActionConfig.id)
        || currentActionConfig.id;
      const headerMapping = headerKeyMapping[headerName];
      console.log(headerMapping);
      data?.forEach((ele) => {
        const parsedObject = {};
        Object.keys(ele).forEach((key) => {
          const mapKey = lodash.get(headerMapping, key) || null;
          if(mapKey){
            parsedObject[mapKey] = ele[key];
            switch(mapKey){
              case 'notes':
                notes.push(ele[key]);
                break;
              case 'reference_number':
                list.push(ele[key]);
                break;
            }
          }
        });
        additionalFields.push(parsedObject);
      });
    } else if (data?.length > 1) {
      const parsedData = data.slice(1);
      parsedData.map((d) => {
        if (d[0] || d[1] || d[2]) {
          list.push(d[0]);
          notes.push(d[1]);
          additionalFields.push(d[2]);
        }
      });
    }

      if (enablePieceScanningForAction) {
        setValidationLoader(true);

        const res = await validateInScannedConsignments({
          hub_id: currHub?.id,
          consignments: list,
        });

        if (!res.isSuccess) {
          setValidationLoader(false);
          message.error(res.errorMessage);
          return;
        }

        const validConsignments = res.data?.valid_consignments || [];
        const partialConsignments =
          res.data?.partial_scanned_consignments || [];

        const uniqueValidConsignments = lodash
          .uniqBy(validConsignments, 'parent_reference_number')
          .map((item: any) => item.parent_reference_number);
        const uniqueCompletePieces = lodash.uniqBy(
          validConsignments,
          'reference_number',
        );
        const uniquePartialPieces = lodash.uniqBy(
          partialConsignments,
          'reference_number',
        );
        const uniquePartialCN = lodash.uniqBy(
          partialConsignments,
          'parent_reference_number',
        );

        setPiecesList({
          ...piecesList,
          completePieces: uniqueCompletePieces,
          partialPieces: uniquePartialPieces,
          partialCN: uniquePartialCN,
        });
        setCNList(uniqueValidConsignments);
        setNotesList(notes);
        setAdditionalFieldList(additionalFields);
        setValidationLoader(false);
        return;
      }

    setCNList(list);
    setNotesList(notes);
    setAdditionalFieldList(additionalFields);
  };

  const handleFailureModalClose = () => {
    setErrorModalVisible(false);
    onModalClose(false);
  };

  const handleDestHubChange = (values, options) => {
    setDestHub(values);
    setSearchedHubs([]);
  };
  const handleSearchedHubsearch = async (queryString: string) => {
    if (!queryString.trim()) {
      setSearchedHubs([]);
      return;
    }
    if (queryString.length >= minimumHubSearchLength) {
      const response = await searchHubData(queryString);
      let searchedHubs = response.data;
      searchedHubs = searchedHubs
        ? searchedHubs.map((hub: any) => ({
            key: hub.id,
            label: `${hub.name}, ${hub.code}`,
          }))
        : [];
      setSearchedHubs(searchedHubs);
    }
  };
  const onLabelTypeChange = (e) => {
    e.target.value === 'a6' ? setSmallLabel(true) : setSmallLabel(false);
    setSelectedLabel(e.target.value);
  };

  const onLabelChange = (e) => {
    const labelValue = e.value;
    setSmallLabel(getCodeFreeLabelType(labelValue));
    setSelectedLabel(labelValue);
  };

  const getCodeFreeLabels = () => {
    return labelConfig.labels?.map((ele) => (
      <Option key={ele.id} value={ele.label_category}>
        {ele.label_category}
      </Option>
    ));
  };

  if (showErrorModal) {
    return (
      <ScanErrorModal
        isVisible={showErrorModal}
        data={piecesList.missingPieces}
        onModalClose={() => setShowErrorModal(false)}
      />
    );
  }

  const setIsFirstRowAsHeader = (currentActionConfig) => {
    switch(currentActionConfig.id){
      case 'mark_delivered':
      case 'mark_delivered_bulk_upload':
      case 'update_consignment_destination_hub_bulk_upload':
        return true;
      default:
        return false;
    }
  };

  const setIsDateTimeParameter = (currentActionConfig) => {
    switch(currentActionConfig.id){
      case 'mark_delivered':
      case 'mark_delivered_bulk_upload':
        return showInputTimestampForDelivery ? true : false;
      default:
        return false;
    }
  };

  return (
    <Modal
      width={360}
      confirmLoading={confirmLoading}
      title={currentActionConfig.title || 'Bulk Upload'}
      visible={isVisible}
      onOk={handleSubmit}
      onCancel={() => onModalClose(false)}
      okButtonProps={{ className: classes.customPrimaryButton }}
    >
      <div>
        {['cn_label_selected', 'cn_label_bulk_upload'].includes(
          currentActionConfig.id,
        ) &&
        (showA6Option || (showCustomLabel && customLabelOptions.length)) &&
        !enableCodeFreeLabel ? (
          <div>
            <span style={{ marginRight: '15px' }}>Label Type:</span>
            <RadioGroup onChange={onLabelTypeChange} defaultValue="a4">
              {getLabelOptions().map((item) => {
                return <Radio value={item}>{item}</Radio>;
              })}
            </RadioGroup>
          </div>
        ) : null}
        {['cn_label_selected', 'cn_label_bulk_upload'].includes(
          currentActionConfig.id,
        ) && enableCodeFreeLabel ? (
          <div>
            <div>Label Type:</div>
            <Select
              labelInValue
              defaultValue={labelList?.[0]?.label_category}
              placeholder="Select Label Type"
              onChange={onLabelChange}
              loading={labelConfig.loading}
              className={classes.destinationHub}
            >
              {getCodeFreeLabels()}
            </Select>
          </div>
        ) : null}
        {currentActionConfig.requireReason && (
          <div>
            <div className={classes.reason}>
              Select Reason <span>*</span>
            </div>
            <Select
              labelInValue
              placeholder="Select Reason"
              onChange={(val) => setReason(val)}
              className={classes.destinationHub}
            >
              {getReasons()}
            </Select>
          </div>
        )}
        {currentActionConfig.id === 'outscan_bulk_upload' && (
          <SearchTagsInput
            labelInValue
            mode={'single'}
            size={'default'}
            placeholder="Select Destination Hub"
            value={destHub}
            thresholdLength={minimumHubSearchLength}
            searchText="Search for destination hubs"
            options={searchedHubs}
            onChange={handleDestHubChange}
            searchFunction={handleSearchedHubsearch}
            className={classes.destinationHub}
          />
        )}
        {enablePieceScanningForAction && (
          <div className={classes.piecesDetailContainer}>
            <div>
              Parent CN Selected:{' '}
              <b>
                {cnList.length} / {cnList.length + piecesList.partialCN.length}
              </b>
            </div>
            <div>
              Pieces Scanned:{' '}
              <b>
                {piecesList.partialPieces.length +
                  piecesList.completePieces.length}
              </b>
            </div>
          </div>
        )}
        <ExcelUpload
          onDataParsed={handleParsedData}
          sampleLink={currentActionConfig.useSampleApiForSampleFile ? null :
            (currentActionConfig.showNotes
              ? hideNotes
                ? defaultSampleLink
                : sampleLinkWithNotes
              : currentActionConfig.sampleLink)
          }
          sampleApi={currentActionConfig.useSampleApiForSampleFile ?
            handleSampleFileDownload : null}
          firstRowAsHeader={setIsFirstRowAsHeader(currentActionConfig)}
          dateTime={setIsDateTimeParameter(currentActionConfig)}
        />
        {isErrorModalVisible && (
          <FailureArrayHandler
            failureArray={failureArray}
            isVisible={isErrorModalVisible}
            onModalClose={handleFailureModalClose}
          ></FailureArrayHandler>
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ masterData, cachedData }) => {
  return {
    hideNotes:
      masterData.ops_dashboard_config?.parts_to_show?.hide_event_level_notes,
    showA6Option:
      masterData.ops_dashboard_config?.config
        ?.allow_consignment_a6_print_label_request,
    showCustomLabel:
      masterData.ops_dashboard_config.parts_to_show?.custom_label_config
        ?.show_custom_label,
    customLabelOptions:
      masterData.ops_dashboard_config.parts_to_show?.custom_label_config
        ?.custom_label_sizes,
    useCustomLabelOnly:
      masterData.ops_dashboard_config.parts_to_show?.custom_label_config
        ?.use_custom_label_config_only,
    enablePieceScanning:
      masterData.ops_dashboard_config?.parts_to_show?.flm_trip_config
        ?.enable_piece_level_scanning,
    enableCodeFreeLabel: masterData.ops_dashboard_config?.parts_to_show
        ?.enable_code_free_label_generation,
    showInputTimestampForDelivery: masterData?.crm_config_master
        ?.mark_delivered?.show_input_timestamp || false,
    currHub: cachedData.currHub,
  };
};

const BulkUploadModalStyled = withStyles(styles, { injectTheme: true })(
  BulkUploadModal,
);
export default connect(mapStateToProps)(BulkUploadModalStyled);
