import { API_BASE_URL } from '../globals';
import { GET, POST } from '@api/apiHandler';
import {
  GET_DASHBOARD_GROUP,
  GET_DASHBOARD_REPORTS_RELOADING_DATA,
  GET_REPORT_VISALISATION,
  GET_DASHBOARD_GROUP_ID,
} from './routes';
import { getAuthenticationHeaders } from './utils';

export const getDashboardGroupId = async () => {
  const headers = await getAuthenticationHeaders();
  return GET(
    `${API_BASE_URL}${GET_DASHBOARD_GROUP_ID}`,
    {},
    null,
    headers,
  );
};

export const getDashboardGroup = async (params) => {
  const { dashboardGroupId } = params;
  const headers = await getAuthenticationHeaders();
  return GET(
    `${API_BASE_URL}${GET_DASHBOARD_GROUP}?dashboardGroupId=${dashboardGroupId}`,
    {},
    null,
    headers,
  );
};

export const getReportsReloadVisualizationData = async (params) => {
  const body = {
    dashboardGroupId: params.dashboardGroupId,
    reportIds: params.reportIds,
    globalFilters: params.globalFilters,
    autoReloadFrequency: params.autoReloadFrequency,
  };
  const headers = await getAuthenticationHeaders();
  return POST(
    `${API_BASE_URL}${GET_DASHBOARD_REPORTS_RELOADING_DATA}`,
    body,
    headers,
  );
};

export const getReportVisualization = async (params) => {
  const { reportId, dashboardGroupId, globalFilters, isDummyDataRequest } =
    params;
  const headers = await getAuthenticationHeaders();
  const body = {
    reportId,
    dashboardGroupId,
    globalFilters,
    isDummyDataRequest,
  };
  return POST(
    `${API_BASE_URL}${GET_REPORT_VISALISATION}`,
    body,
    headers,
  );
};
