import React, { memo, useState, useEffect } from 'react';
import { Bar, Column } from '@ant-design/charts';
import lodashUniqBy from 'lodash/uniqBy';
import BarConfigs from './ConfigCharts/configBar';
import { transformBarChartData } from 'src/utils/utils';

const BarViz = (props) => {
  const {
    formData,
    chartData,
    chartConfig,
    containerWidth,
    chartsFromDashboard,
  } = props;
  const [dimensions, setDimensions] = useState({
    height: 1000,
    width: 1000,
  });
  const XAxisGroups = lodashUniqBy(chartData, 'x');

  const barConfig = BarConfigs(
    dimensions,
    XAxisGroups,
    chartConfig,
    formData,
    chartsFromDashboard,
  );

  useEffect(() => {
    // caching dimensions because the dimensions from the props
    // is updated to 0 when the parent component is unmounted
    if (containerWidth) setDimensions({ width: containerWidth, height: 1000 });
  }, [containerWidth]);

  const finalData = transformBarChartData(
    chartData,
    barConfig?.othersThreshold ?? 0,
  );
  const config: any = {
    data: finalData,
    ...formData,
    ...barConfig,
  };

  return formData.bar_type === 'horizontal' ? (
    <Bar {...config} />
  ) : (
    <Column {...config} />
  );
};

const areEqual = (prevProps, nextProps) => {
  // additional props in parent like classes and chartOptions
  // were causing a re-render
  if (
    prevProps.formData === nextProps.formData &&
    prevProps.chartData === nextProps.chartData &&
    prevProps.containerWidth === nextProps.containerWidth
  ) {
    return true; // do not re-render
  }
  return false; // will re-render
};

export default memo(BarViz, areEqual);
