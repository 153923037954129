import lodash from 'lodash';

export type ViewFilterType = {
    id: string,
    name: string,
    showOnMainDashboard: boolean,
    label: string,
    mode: 'single' | 'multiple' | 'range',
    placeholder: string,
    type: 'input' | 'select' | 'search' | 'date' | 'time' | 'slider_range' | 'radio' | 'checkbox',
    tokenSeparators?: string[],
    ignoreDefaultSeparators?: boolean,
    show_time?: boolean,
    date_format?: string,
    time_format?: string,
    threshold_length?: number;
    search_strategy?: string;
    options?: {
        label: string,
        value: any,
    }[],
    step?: number,
    defaultMinValue?: number,
    defaultMaxValue?: number,
    minValue?: number,
    maxValue?: number,
};

export type ViewActionType = {
    id: string,
};

export type ViewDataResponseType = {
    id: string,
    object_type: string;
    description: string;
    metadata: {
        name: string;
        fieldList: {
            id: string,
            name: string,
        }[],
        filterList: ViewFilterType[],
        tableActions: {
            id: string,
        }[],
        actionList: ViewActionType[],
    };
    extra_detail: { [key: string]: any };
};

export class ViewData {
    id: string;
    object_type: string;
    name: string;
    metadata: ViewDataResponseType['metadata'];
    description: string;
    isDefault: boolean;
    isLoading: boolean = false;
    isFetchingData: boolean = false;
    shouldFetchDataOnTabChange: boolean = true;
    error?: string;
    fetchError?: string;
    appliedFilters: { [key: string]: any } = {};
    moreAppliedFilters: { [key: string]: any } = {};
    pagination: {
        pageNumber: number,
        isNextPresent: boolean,
        resultPerPage: '50',
    } = {
        pageNumber: 1,
        isNextPresent: true,
        resultPerPage: '50',
    };
    dataObjects = [];
    selectedRowKeys = [];
    
    constructor(viewData: { id: string, isDefault: boolean, name: string, objectType: string }) {
        this.id = viewData.id;
        this.name = viewData.name;
        this.object_type = viewData.objectType;
        this.isDefault = viewData.isDefault;
    }

    setViewData(viewData: ViewDataResponseType) {
        this.metadata = viewData.metadata;
        this.description = viewData.description;
    }

    getViewApiResponse() {
        return {
            id: this.id,
            isDefault: this.isDefault,
            name: this.name,
            objectType: this.object_type,
        };
    };

    clone() {
        return lodash.cloneDeep(this);
    }

    getFetchViewDataObjectsRequest(globalFilters: any) {
        return {
            viewId: this.id,
            filters: {
                ...this.appliedFilters,
                ...globalFilters,
            },
            pagination: {
                resultPerPage: +this.pagination.resultPerPage,
                pageNumber: this.pagination.pageNumber,
            },
        };
    }

    setViewDataObjects(data: any[]) {
        this.dataObjects = data;
        this.pagination.isNextPresent = (data?.length === Number.parseInt(this.pagination.resultPerPage));
    }

    getMainDashboardFilters() {
        const filtersData = this.metadata?.filterList || [];
        return filtersData.filter((f) => f.showOnMainDashboard);
    }

    getMoreFilters() {
        const filtersData = this.metadata?.filterList || [];
        return filtersData.filter((f) => !f.showOnMainDashboard);
    }

    getActionList() {
        return this.metadata?.actionList || [];
    }

    getFilterValue(filterData: ViewFilterType) {
        const filterKey = filterData.id;
        return this.appliedFilters[filterKey];
    }

    applyFilters(filterObj: any) {
        for (const key in filterObj) {
            this.appliedFilters[key] = filterObj[key];
        }
    }

    setSelectedRowKeys(keys) {
        this.selectedRowKeys = keys;
    }
};
