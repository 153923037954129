import { CssType, ThemeType } from '@theming/jssTypes';

export const styles = (theme: ThemeType): CssType => ({
  tableContainer: {
    boxSizing: 'border-box',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    margin: '1% 0.5%',
    border: '0',

    flex: '1 1 0',
    overflow: 'auto',
    '& .ant-table-tbody > tr > td, .ant-table-thead > tr > th': {
      padding: 9,
    },
  },
  timeline: {
    backgroundColor: '#FFFFFF',
    position: 'relative',
    padding: '1.5% 0 0 10%',
    border: '0',
    width: '100%',
  },
  eventTime: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '22px',
    color: 'rgba(0, 0, 0, 0.45)',
  },
  eventName: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '22px',
  },
  eventDetailsBlock: {
    width: '100%',
    borderRadius: '8px',
  },
  eventDetailsColumn: {
    paddingBottom: '1%',
    '& .ant-collapse > .ant-collapse-item > .ant-collapse-header': {
      padding: '12px 16px 0 16px',
    },
  },
  content: {
    fontSize: '14px',
    color: '#262626',
    fontWeight: 500,
    wordBreak: 'break-word',
    textTransform: 'capitalize',
  },
  column: {
    margin: '10px 0px',
  },
  hintHeader: {
    fontSize: '12px',
    color: '#595959',
    fontWeight: 400,
    margin: '10px 0px 10px 0',
  },
  expandBtn: {
    textAlign: 'right',
    paddingRight: '7.5%',
  },
  btn: {
    color: 'blue',
    cursor: 'default',
  },
  toggleDetails: {
    margin: '0 0 0 0.25rem',
  },
  timelineEvent: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'left',
  },
  timelineEventHeader: {
    color: 'rgba(0, 0, 0, 0.45)',
    width: '50%',
  },
  textIndent1dot7: {
    textIndent: '1.7em',
  },
  textIndent1dot8: {
    textIndent: '1.8em',
  },
  textIndent6: {
    textIndent: '6em',
  },
  textIndent8: {
    textIndent: '8em',
  },
  textIndent11: {
    textIndent: '11em',
  },
  textIndent12: {
    textIndent: '12em',
  },
  activeLink: {
    color: '#1890FF',
  },
  dark45: {
    color: 'rgba(0, 0, 0, 0.45)',
  },
  dark85: {
    color: 'rgba(0, 0, 0, 0.85)',
  },
  customCollapseHeader: {
    border: 'none !important',
    padding: '0 0 0 0',
  }
});
