import * as React from 'react';
import withStyles, { WithStylesProps } from 'react-jss';
import { connect } from 'react-redux';
import styles from './TripMapEditTripsFilterBar.style';
import { bindActionCreators } from 'redux';
import {
  MAP_EDIT_BUCKETS,
  MAP_EDIT_VIEW_TYPE,
  STATUS_LIST_OPTIONS,
  TRIP_TYPE_OPTIONS,
  VEHICLE_TYPE_OPTIONS,
} from '../../TripManagerMapEditUtils';
import {
  applyBucketFilters,
  applyOtherBucketFilters,
  reloadBucket,
} from 'src/actions/genericConsignmentActions';
import { SwitchMapListView } from 'src/components/common/SwitchMapListView';
import {
  switchMapView,
  resetMapData,
} from '../../StateManagement/TripManagerMapEdit.actions';
import { useTripManagerMapEditContext } from '../../StateManagement/TripManagerMapEdit.context';
import { SettingIcon } from 'src/components/common/icons/SettingsIcon';
import CustomColumnsModal from 'src/components/pages/dashboard/CustomColumnsModal';
import { getViewBuckets } from 'src/utils/genericConfig/utils';
import SideLabel from 'src/components/common/SideLabel';
import { Button, Input, Modal, Select } from 'antd';
import SearchSelectTagsInput from 'src/components/common/SearchSelectTagsInput';
import { getAllWorkerList } from 'src/api/trips';
import { ReloadOutlined } from '@ant-design/icons';

interface IProps extends WithStylesProps<typeof styles> {
  bucket: string;
  currHub: any;
  reload: any;
  applyOtherFilters: any;
  tripColumnsToShow: any;
  tripFullColumnList: any;
  appliedFilters: any;
  applyBucketFilters: any;
  allTrips: any;
  allUnplannedCNs: any;
}

const TripMapEditUnplannedTaskFilterBar: React.FC<IProps> = function (props) {
  const {
    classes,
    bucket,
    reload,
    tripColumnsToShow,
    tripFullColumnList,
    appliedFilters,
    applyOtherFilters,
    applyBucketFilters,
    currHub,
    allTrips,
    allUnplannedCNs,
  } = props;
  const { tripManagerMapEditState, tripManagerMapEditDispatch } =
    useTripManagerMapEditContext();
  const { isMapView, editActions } = tripManagerMapEditState;
  const [isCustomColumnModalVisible, setIsCustomColumnModalVisible] =
    React.useState(false);
  const bucketConfig = getViewBuckets(MAP_EDIT_VIEW_TYPE)[bucket];
  const [searchWorkerList, setSearchWorkerList] = React.useState([]);

  const setIsMapView = (flag) => {
    tripManagerMapEditDispatch(switchMapView(flag));
  };

  const onReloadClick = () => {
    if (editActions && editActions.length) {
      Modal.confirm({
        title: 'All changes will be lost if you reload',
        okText: 'Ok',
        cancelText: 'Cancel',
        width: 500,
        onOk: () => {
          resetMapData(tripManagerMapEditDispatch, {
            allTrips,
            allUnplannedCNs,
          });
          reload();
        },
      });
    } else {
      resetMapData(tripManagerMapEditDispatch, {
        allTrips,
        allUnplannedCNs,
      });
      reload();
    }
  };

  const handleCustomModalClick = () => {
    setIsCustomColumnModalVisible(true);
  };

  const handleCustomModalClose = () => {
    setIsCustomColumnModalVisible(false);
  };

  const checkFilterPresent = (key: string): boolean => {
    return bucketConfig.filters.includes(key);
  };

  const generateOptionsFromList = (list) => {
    const optionsList: any = [];
    if (list) {
      list.forEach((element) => {
        optionsList.push(
          <Select.Option key={element.value} value={element.value}>
            {element.label}
          </Select.Option>,
        );
      });
    }
    return optionsList;
  };

  const handleTripTypeFilterTypeChange = (value) => {
    applyOtherFilters({
      tripType: value,
    });
  };

  const handleVehicleTypeFilterTypeChange = (value) => {
    applyBucketFilters({
      ...appliedFilters,
      vehicleType: value,
    });
  };

  const handleStatusFilterTypeChange = (value) => {
    applyBucketFilters({
      ...appliedFilters,
      statusList: value === 'all' ? [] : [value],
    });
  };

  const handlePlanningIdFilterChange = (event) => {
    const planningId = event.target.value?.trim();
    applyBucketFilters({
      planningId,
    });
  };

  const renderPlanningId = () => {
    return (
      <div className={classes.childDiv}>
        <SideLabel text="Planning Id" />
        <Input
          className={classes.inputStyle}
          onBlur={handlePlanningIdFilterChange}
        />
      </div>
    );
  };

  const renderStatusListFilter = () => {
    return (
      <div className={classes.childDiv}>
        <SideLabel text="Trip Status" style={{ marginLeft: 10 }} />
        <Select
          placeholder="Trip Status"
          className={classes.selectStyle}
          value={
            appliedFilters.statusList ? appliedFilters.statusList[0] : 'all'
          }
          onChange={handleStatusFilterTypeChange}
        >
          {generateOptionsFromList(STATUS_LIST_OPTIONS)}
        </Select>
      </div>
    );
  };

  const renderTripTypeFilter = () => {
    return (
      <div className={classes.childDiv}>
        <SideLabel text="Trip Type" style={{ marginLeft: 10 }} />
        <Select
          placeholder="Trip Type"
          className={classes.selectStyle}
          value={appliedFilters.tripType || 'all'}
          onChange={handleTripTypeFilterTypeChange}
        >
          {generateOptionsFromList(TRIP_TYPE_OPTIONS)}
        </Select>
      </div>
    );
  };

  const renderVehicleTypeFilter = () => {
    return (
      <div className={classes.childDiv}>
        <SideLabel text="Vehicle Type" style={{ marginLeft: 10 }} />
        <Select
          placeholder="Vehicle Type"
          className={classes.selectStyle}
          value={appliedFilters.vehicleType || 'all'}
          onChange={handleVehicleTypeFilterTypeChange}
        >
          {generateOptionsFromList(VEHICLE_TYPE_OPTIONS)}
        </Select>
      </div>
    );
  };

  const handleWorkerSearch = async (value) => {
    let queryString: string = value;
    if (!queryString.trim() || !currHub?.id) {
      return;
    }
    queryString = queryString.toLowerCase();
    const response = await getAllWorkerList(currHub.id);
    let searchWorkers = response.data?.data;
    searchWorkers =
      searchWorkers?.map((wr: any) => ({
        key: wr.worker_id,
        label: `${wr.worker_name}, ${wr.worker_code}`,
      })) || [];
    setSearchWorkerList(searchWorkers);
  };

  const handleWorkerFilterChange = (value) => {
    applyBucketFilters({
      ...appliedFilters,
      workerId: value,
    });
  };

  const renderWorkerFilter = () => {
    return (
      <div className={classes.childDiv}>
        <SideLabel text="Worker" style={{ marginLeft: 10 }} />
        <SearchSelectTagsInput
          labelInValue
          allowClear={true}
          size={'default'}
          value={appliedFilters.workerId ?? null}
          mode={'single'}
          placeholder="Search Worker"
          thresholdLength={3}
          className={classes.selectStyle}
          options={searchWorkerList}
          searchFunction={handleWorkerSearch}
          onChange={handleWorkerFilterChange}
        />
      </div>
    );
  };

  const handleResetFilters = () => {
    applyBucketFilters({});
  };

  const renderFilters = () => {
    return (
      <div className={classes.containerDefaultFilter}>
        {checkFilterPresent('planning_id') && renderPlanningId()}
        {checkFilterPresent('status') && renderStatusListFilter()}
        {checkFilterPresent('trip_type') && renderTripTypeFilter()}
        {checkFilterPresent('vehicleType') && renderVehicleTypeFilter()}
        {checkFilterPresent('worker_id') && renderWorkerFilter()}
        <Button
          type="link"
          onClick={handleResetFilters}
          danger
          style={{ marginRight: '5px' }}
        >
          Reset All
        </Button>
      </div>
    );
  };

  const renderCustomColumnModal = () => {
    if (isCustomColumnModalVisible) {
      return (
        <CustomColumnsModal
          viewType={MAP_EDIT_VIEW_TYPE}
          bucket={bucket}
          isVisible={isCustomColumnModalVisible}
          onModalClose={handleCustomModalClose}
          columnsToShow={tripColumnsToShow}
          fullColumnList={tripFullColumnList}
        />
      );
    }
  };

  return (
    <div className={classes.containerDefaultFilter}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flex: 1,
          marginRight: '10px',
        }}
      >
        {renderFilters()}
        <SwitchMapListView isMapView={isMapView} onChange={setIsMapView} />
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button onClick={onReloadClick}>
          <ReloadOutlined />
        </Button>
        <a className={classes.settingButton}>
          <SettingIcon onClick={handleCustomModalClick} />
        </a>
        {renderCustomColumnModal()}
      </div>
    </div>
  );
};

const TripMapEditUnplannedTaskFilterBarStyled = withStyles(styles, {
  injectTheme: true,
})(TripMapEditUnplannedTaskFilterBar);

const mapStateToProps = (
  { genericConsignmentReducer, masterData, cachedData },
  ownProps,
) => {
  const viewType = MAP_EDIT_VIEW_TYPE;
  const { bucket } = ownProps;
  const currentViewReducer = genericConsignmentReducer[viewType];
  return {
    currHub: cachedData.currHub,
    tripColumnsToShow: masterData.ops_trip_column_list[bucket],
    tripFullColumnList: masterData.ops_full_trip_column_list_by_bucket[bucket],
    appliedFilters: currentViewReducer.buckets[bucket].appliedFilters,
    allTrips: currentViewReducer.buckets[bucket].data,
    allUnplannedCNs:
      currentViewReducer.buckets[MAP_EDIT_BUCKETS.MAP_UNPLANNED].data,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const viewType = MAP_EDIT_VIEW_TYPE;
  const { bucket } = ownProps;
  return bindActionCreators(
    {
      reload: reloadBucket(viewType)(bucket),
      applyOtherFilters: applyOtherBucketFilters(viewType)(bucket),
      applyBucketFilters: applyBucketFilters(viewType)(bucket),
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TripMapEditUnplannedTaskFilterBarStyled);
