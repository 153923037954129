import { getOpsDashboardBaseRoute } from '../../../routing/utils';

export const topLevelRoutes = {
  manage: {
    route: '/manage',
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.manage,
  },
  setup: {
    route: '/setup',
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup,
  },
};

export const getFullRouteForIdOpsDashboardTop = (routeId) => {
  const base = getOpsDashboardBaseRoute();
  return `${base}${topLevelRoutes[routeId].route}`;
};

export const shouldAllowTopOpsRoute = (masterData, routeId) => {
  const routeObj = topLevelRoutes[routeId];
  if (!routeObj || !routeObj.isAllowed) {
    return true;
  }
  return routeObj.isAllowed(masterData) || false;
};

export const defaultTopLevelOps = (masterData) => {
  const priorityList = ['manage', 'setup'];
  return priorityList.find(id => shouldAllowTopOpsRoute(masterData, id));
};
