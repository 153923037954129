import {
  fetchAllPackagingType,
  createPackagingType,
  updatePackagingType,
} from '@api/appFlowConfig';

const getKeyMappingPackagingTypeSingle = (masterData) => {
  return (
    masterData.ops_dashboard_config?.config?.packaging_type_config
      ?.csv_field_list || []
  );
};

const packagingTypeFormItemLayout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};

const viewControl = {
  pageTitle: 'Setup',
  buckets: ['packagingTypeMaster'],
  globalActions: [],
  addOptions: [
    {
      key: 'add_single',
      title: 'Single',
      action: 'add',
      type: 'single',
    },
  ],
  id: 'reason_id',
  fetchData: fetchAllPackagingType,
  onSingleAdd: createPackagingType,
  add: {
    sampleUrl: undefined,
    downloadSampleCsvFromApiCall: false,
    downloadSampleCsv: null,
    title: 'Add Packaging Type',
  },
  edit: {
    title: 'Edit Packaging Type',
    okText: 'Update',
  },
  warningMsg: 'No Packaging Type to upload',
  successMsg: 'Uploaded Successfully',
  updateMsg: 'Updated Successfully',
  modalHeading: 'Upload Packaging Type',
  singleUploadMapping: getKeyMappingPackagingTypeSingle,
  onSingleUpdate: updatePackagingType,
  formItemLayout: packagingTypeFormItemLayout,
};

const buckets = {
  packagingTypeMaster: {
    name: 'Packaging Type',
    filters: [],
    actions: [],
  },
};

const bucketColor = {};

export default { viewControl, buckets, bucketColor };
