import { getAuthenticationHeaders } from '@api/utils';
import {
  fetchTripChargesData,
  updateTripCharges,
  deleteSingleTripCharge,
  deleteBulkTripCharge,
} from '@api/rateMasters';
import { BUSINESS_BOOKING_API_URL } from '@src/globals';

const getSampleCSVDownloadUrl = async (filters) => {
  const searchParams = new URLSearchParams(filters);
  return `${BUSINESS_BOOKING_API_URL}/pricedata/getCSV?&header-access-token=${
    await getAuthenticationHeaders()?.['access-token']
  }&header-user-id=${
    await getAuthenticationHeaders()?.['user-id']
  }&header-organisation-id=${
    await getAuthenticationHeaders()?.['organisation-id']
  }&${searchParams.toString()}`;
};

const tripContractsFormItemLayout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};

const viewControl = {
  pageTitle: 'Setup',
  buckets: ['tripContracts'],
  globalActions: [],
  actions: [
    {
      key: 'bulk_delete',
      name: 'Delete',
      isAllowed: (masterData) => masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
        ?.rate_masters?.trip_contracts?.update,
    },
    {
      key: 'download',
      name: 'Download',
    },
    {
      key: 'addOptions',
      name: 'Add New',
      isAllowed: (masterData) => masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
        ?.rate_masters?.trip_contracts?.update,
    },
    {
      key: 'updateOptions',
      name: 'Update',
      isAllowed: (masterData) => masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
        ?.rate_masters?.trip_contracts?.update,
    },
    {
      key: 'pagination',
      name: 'Pagination',
    },
    {
      key: 'perPageCountChange',
      name: 'Pagination Count',
    },
    {
      key: 'reload',
      name: 'Reload',
    },
  ],
  id: '_id',
  filters: [],
  addOptions: [
    {
      key: 'Bulk',
      title: 'Bulk Upload',
      action: 'bulk_add',
      type: 'bulk',
    },
    {
      key: 'Add',
      title: 'Single',
      action: 'single_add',
      type: 'single',
    },
  ],
  updateOptions: [
    {
      key: 'Bulk',
      title: 'Bulk Upload',
      action: 'bulk_update',
      type: 'bulk',
    },
  ],
  params: 'data',
  fetchData: fetchTripChargesData,
  bulk_add: {
    sampleUrlWithParams: getSampleCSVDownloadUrl,
    downloadSampleCsvFromApiCall: false,
    downloadSampleCsv: undefined,
    title: 'Add Trip Charges',
    onBulkUpload: (payload) => updateTripCharges({...payload, actionType: 'ADD'}),
  },
  bulk_update: {
    sampleUrlWithParams: getSampleCSVDownloadUrl,
    downloadSampleCsvFromApiCall: false,
    downloadSampleCsv: undefined,
    title: 'Update Trip Charges',
    onBulkUpload: updateTripCharges,
  },
  warningMsg: 'No Trip Charges to upload',
  successMsg: 'Uploaded Successfully',
  updateMsg: 'Updated Successfully',
  deleteMsg: 'Deleted Successfully',
  modalHeading: 'Upload Trip Charges',
  formItemLayout: tripContractsFormItemLayout,
  singleUploadMapping: () => {
    return null;
  },
  transformRecordOnEdit: (record, columns) => {
    const toRet = { id: record._id };
    for (const key of Object.keys(record)) {
      const keyColumn = columns?.find(ele => ele.id === key);
      if (keyColumn) {
        toRet[keyColumn.name] = record[key];
      }
    }
    return toRet;
  },
  onBulkUpload: updateTripCharges,
  onSingleDelete: deleteSingleTripCharge,
  bulkDelete: deleteBulkTripCharge,
};

const buckets = {
  tripContracts: {
    name: 'Trip Contracts',
    filters: [],
    actions: [],
  },
};

const bucketColor = {};

export default { viewControl, buckets, bucketColor };
