import React from 'react';
import { connect } from 'react-redux';
import withStyles, { WithStylesProps } from 'react-jss';
import { CssType, ThemeType } from 'src/theming/jssTypes';
import { Carousel, Modal } from 'antd';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import PdfRenderer from 'src/components/common/PdfRenderer';

const styles = (theme: ThemeType): CssType => ({
    modalContainer: {
        padding: '1rem 0',
        paddingBottom: '3rem',
        minWidth: '40%',
    },
    carouselContainer: {
        padding: '1.5rem',
        maxWidth: '100%',
        '& span > svg': {
            width: '120%',
            height: '120%',
        },
    },
    btn: {
      color: 'blue',
      cursor: 'default',
    },
});

interface IProps extends WithStylesProps<ReturnType<typeof styles>>,ReturnType<typeof mapStateToProps> {
    record: any;
    values: any[];
    checkType?: boolean;
}

const ViewFilesBtn = (props: IProps) => {
    const { record, values, checkType, classes } = props;
    const [ modalVisible, setModalVisible ] = React.useState(false);

    let imagesToShow = [];
    let pdfToShow = [];
    let videosToShow = [];
    if (Array.isArray(values) && values.length) {
      if (checkType) {
        imagesToShow = values.filter(item => item.type !== 'pdf' && item.type !== 'video').map(item => item.url);
        pdfToShow = values.filter(item => item.type === 'pdf').map(item => item.url);
        videosToShow = values.filter(item => item.type === 'video').map(item => item.url);
      } else {
        imagesToShow = values;
      }
    }

    const renderModal = () => {
        if (modalVisible) {
            return <Modal
            className={classes.modalContainer}
            visible={modalVisible}
            onCancel={() => {
              setModalVisible(false);
            }}
            footer={null}
          >
            <Carousel
              className={classes.carouselContainer}
              prevArrow={<LeftCircleOutlined />}
              nextArrow={<RightCircleOutlined />}
              arrows
            >
              {imagesToShow?.map((url) => (
                <div key={url}>
                  <img  object-fit="cover" style={{ maxWidth: '100%' }} src={url} />
                </div>
              ))}
              {videosToShow?.map((url) => (
                <div key={url} style={{ maxWidth: '100%' }}>
                  <video controls style={{ maxWidth: '100%' }}>
                    <source src={url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ))}
              {pdfToShow?.map((data) => (
                <div key={data}>
                  <PdfRenderer url={data} height={400} isLoading={false} />
                </div>
              ))}
            </Carousel>
          </Modal>;
        }
        return <></>;
    };

    return (imagesToShow.length > 0 || pdfToShow.length > 0 || videosToShow.length > 0) ?
      <>
        <span
            className={classes.btn}
            onClick={() => {
                setModalVisible(true);
            }}
        >
            View
        </span>
        {renderModal()}
      </>
      : '-';
};

const mapStateToProps = (
    { masterData },
) => {
    return {};
};

export default connect(mapStateToProps)(withStyles(styles, { injectTheme: true })(ViewFilesBtn));
