import Line from './Line';
import Bar from './Bar';
import Pie from './Pie';
import Number from './Number';
import ProgressBar from './ProgressBar';

const visualisationMap = {
  line: Line,
  bar: Bar,
  pie: Pie,
  number: Number,
  progressBar: ProgressBar,
};

export default visualisationMap;
