import { fetchChartsDataOnDemand } from '../../../../api/genericAnalyticsView';
import { fetchRiderDphData, fetchReportingData, getFeedbackDataWithFilters,
         fetchReportingDataPunchingTable } from '../../../../api/FoodDashboardApi';
import {
  riderOrderDetails,
  riderExceptions,
  riderScore,
  riderScoreDetails,
  riderDPH,
  storeDph,
  allocationDetails,
  reassignDetails,
  unresolvedOrders,
  storeSummary,
  order_delivered_reachedgate_metrics,
  store_delivered_reachedgate_metrics,
  locality_summary,
  rider_score_summary,
  rider_gps_km,
  rider_distance_metrics,
  rider_trip_order_count,
  store_gps_km,
  store_trip_order_count,
  order_summary,
  store_distance_metrics,
  store_distance_exceptions,
  rider_distance_exceptions,
  store_incentive_table,
  store_partially_completed,
  rider_missing_checkout,
  rider_checkin_checkout,
} from '../../FoodDashboard/columns';
import store from '../../../../store/appStore';
export const viewControl = {
  buckets : [
    'order_summary',
  ],
  fetchChartData : fetchChartsDataOnDemand,
};
export const getOnDemandBuckets = () =>  {
  return {
  order_summary : {
    name: 'Order Summary',
    filters : [
      'dayPartFilter',
      'timeSlot',
      'nonRegularOrders',
      'employeeType',
      'finalConsignmentStatus',
      'daysOfWeek',
      'tentNumber',
      'excludeDays',
      'outlierOrderMinDistance',
      'outlierOrderMaxDistance',
      'excludeOutlierOrders',
    ],
    charts : [
      'total_orders',
      'dot_orders',
      'run_perc',
      'order_time_stats',
      'non_regular_orders',
      'exceptions',
      'dot_perc',
      'reason_for_reassignment',
      'type_of_orders_perc',
      'average_delivery_time',
    ],
  },
  order_summary_advance : {
    name: 'Order Summary Advance',
    filters : [
      'dayPartFilter',
      'timeSlot',
      'nonRegularOrders',
      'employeeType',
      'finalConsignmentStatus',
      'daysOfWeek',
      'tentNumber',
      'excludeDays',
      'outlierOrderMinDistance',
      'outlierOrderMaxDistance',
      'excludeOutlierOrders',
    ],
    charts : [
      'assignment_type_perc',
      'unresolved_order_count',
      'dph',
      'order_assignment_summary',
      'auto_swipes_perc',
      'suspicious_rider_punches_count',
      'worker_idle_time',
      'distance_travelled',
      'undelivered_order_count',
    ],
  },
  dph : {
    name: 'Rider Summary',
    fetchChartData : fetchRiderDphData,
    filters : [
      'employeeType',
    ],
    charts : [
      'dph',
    ],
  },
  rider_reporting: {
    name: 'Rider Reporting',
    fetchChartData : fetchReportingData,
    filters : [
      'dayPartFilter',
      'timeSlot',
      'nonRegularOrders',
      'employeeType',
      'finalConsignmentStatus',
      'daysOfWeek',
      'tentNumber',
      'excludeDays',
      'outlierOrderMinDistance',
      'outlierOrderMaxDistance',
      'excludeOutlierOrders',
    ],
    charts: [
      'rider_order_details',
      'rider_dph',
      'rider_score_summary',
      'rider_gps_km',
      'rider_distance_metrics',
      'rider_trip_order_count',
      'rider_missing_checkout',
    ],
  },
  rider_reporting_advance: {
    name: 'Rider Reporting Advance',
    fetchChartData : fetchReportingData,
    filters : [
      'dayPartFilter',
      'timeSlot',
      'nonRegularOrders',
      'employeeType',
      'finalConsignmentStatus',
      'daysOfWeek',
      'tentNumber',
      'excludeDays',
      'outlierOrderMinDistance',
      'outlierOrderMaxDistance',
      'excludeOutlierOrders',
    ],
    charts: [
      'rider_exceptions',
      'rider_score',
      'rider_score_details',
      'order_delivered_reachedgate_metrics',
      'rider_distance_exceptions',
      'rider_checkin_checkout',
      'rider_km_reading',
    ],
  },
  order_reporting: {
    name: 'Order Reporting',
    fetchChartData : fetchReportingData,
    filters : [
      'dayPartFilter',
      'timeSlot',
      'nonRegularOrders',
      'employeeType',
      'finalConsignmentStatus',
      'daysOfWeek',
      'tentNumber',
      'excludeDays',
      'outlierOrderMinDistance',
      'outlierOrderMaxDistance',
      'excludeOutlierOrders',
    ],
    charts: [
      'region_summary',
      'circle_summary',
      'city_summary',
      'store_dph',
      'store_summary',
      'locality_summary',
      'store_gps_km',
      'store_trip_order_count',
      'order_summary',
      'store_partially_completed',
    ],
  },
  order_reporting_advance: {
    name: 'Order Reporting Advance',
    fetchChartData : fetchReportingData,
    filters : [
      'dayPartFilter',
      'timeSlot',
      'nonRegularOrders',
      'employeeType',
      'finalConsignmentStatus',
      'daysOfWeek',
      'tentNumber',
      'excludeDays',
      'outlierOrderMinDistance',
      'outlierOrderMaxDistance',
      'excludeOutlierOrders',
    ],
    charts: [
      'allocation_details',
      'reassign_details',
      'unresolved_orders',
      'store_delivered_reachedgate_metrics',
      'store_distance_metrics',
      'vehicle_distance_metrics',
      'store_distance_exceptions',
      'store_incentive_table',
    ],
  },
  customer_feedback: {
    name: 'Customer Feedbacks',
    fetchChartData : getFeedbackDataWithFilters,
    filters : [
      'feedbackReasons',
      'rating',
      'riderCode',
    ],
    charts: [
      'customer_feedbacks',
    ],
    isSubBucket: true,
    bucketType: 'ondemand_customer_feedback',
  },
  };
};

function getColumnsForReport(chartId){
  let columns  = null;
  store?.getState().masterData.ondemand_rider_reports.map((elem) => {
    if(elem.chart_id === chartId){
      columns = elem.header_list.map((elem) => {
        return {
          dataIndex: elem.id,
          key: elem.id,
          title: elem.name,
        };
      });
    }
  });
  store?.getState().masterData.ondemand_order_reports.map((elem) => {
    if(elem.chart_id === chartId){
      columns = elem.header_list.map((elem) => {
        return {
          dataIndex: elem.id,
          key: elem.id,
          title: elem.name,
        };
      });
    }
  });
  return columns;
}
export function getOnDemandCharts(){
  return {
  total_orders: {},
  dot_orders: {},
  run_perc: {},
  order_time_stats: {},
  non_regular_orders: {},
  exceptions: {},
  dot_perc: {},
  // reason_for_undelivered: {},
  type_of_orders_perc: {},
  average_delivery_time: {},
  reason_for_reassignment: {},
  adt: {},
  assignment_type_perc: {},
  suspicious_rider_punches_count: {},
  order_assignment_summary: {},
  dph : { fetchChartData : fetchRiderDphData },
  worker_idle_time:  { fetchChartData : fetchRiderDphData },
  distance_travelled:  { fetchChartData : fetchRiderDphData },
  unresolved_order_count: {},
  undelivered_order_count:{},
  auto_swipes_perc: {},
  rider_order_details : {
    name: 'Rider Service Level',
    columns : getColumnsForReport('rider_order_details') || riderOrderDetails,
    type: 'table',
  },
  rider_exceptions : {
    name: 'Rider Exception Report',
    columns: getColumnsForReport('rider_exceptions') || riderExceptions,
    type: 'table',
    hideXScroll: true,
  },
  rider_score: {
    name: 'Rider Score Counts',
    columns: getColumnsForReport('rider_score') || riderScore,
    type: 'table',
    hideXScroll: true,
  },
  rider_score_details : {
    name: 'Rider Score',
    columns: getColumnsForReport('rider_score_details') || riderScoreDetails,
    type: 'table',
  },
  rider_dph : {
    name: 'Order Per Hour - Rider',
    columns: getColumnsForReport('rider_dph') || riderDPH,
    type: 'table',
  },
  store_dph : {
    name: 'Orders Per Hour - Store',
    columns : getColumnsForReport('store_dph') || storeDph,
    type: 'table',
  },
  allocation_details : {
    name: 'Single and Multi Run Order Table',
    columns: getColumnsForReport('allocation_details') || allocationDetails,
    type: 'table',
    hideXScroll: true,
  },
  reassign_details: {
    name: 'Order Reassignments Table',
    columns: getColumnsForReport('reassign_details') || reassignDetails,
    type: 'table',
    hideXScroll: true,
  },
  unresolved_orders : {
    name: 'Unresolved Orders',
    columns: getColumnsForReport('unresolved_orders') || unresolvedOrders,
    type: 'table',
    hideXScroll: true,
  },
  region_summary : {
    name: 'Region Service Level',
    columns: getColumnsForReport('region_summary') || storeSummary,
    type: 'table',
  },
  circle_summary : {
    name: 'Circle Service Level',
    columns: getColumnsForReport('circle_summary') || storeSummary,
    type: 'table',
  },
  city_summary : {
    name: 'City Service Level',
    columns: getColumnsForReport('city_summary') || storeSummary,
    type: 'table',
  },
  store_summary : {
    name: 'Store Service Level',
    columns: getColumnsForReport('store_summary') || storeSummary,
    type: 'table',
  },
  order_delivered_reachedgate_metrics : {
    name: 'Rider Punching Discipline',
    columns: getColumnsForReport('order_delivered_reachedgate_metrics') || order_delivered_reachedgate_metrics,
    type: 'table',
  },
  store_delivered_reachedgate_metrics : {
    name: 'Rider Punching Discipline',
    columns: getColumnsForReport('store_delivered_reachedgate_metrics') || store_delivered_reachedgate_metrics,
    type: 'table',
    fetchChartData : fetchReportingDataPunchingTable,
  },
  customer_feedbacks : {
    name: 'Customer Feedbacks',
    type: 'table',
  },
  locality_summary : {
    name: 'Locality Table',
    columns: getColumnsForReport('locality_summary') || locality_summary,
    type: 'table',
  },
  rider_score_summary: {
    name: 'Rider Score Analysis',
    columns: getColumnsForReport('rider_score_summary') || rider_score_summary,
    type: 'table',
  },
  rider_gps_km: {
    name: 'Rider GPS Km',
    columns: getColumnsForReport('rider_gps_km') || rider_gps_km,
    type: 'table',
  },
  rider_distance_metrics: {
    name: 'Distance Aggregate Table - Rider Level',
    columns: getColumnsForReport('rider_distance_metrics') || rider_distance_metrics,
    type: 'table',
  },
  rider_trip_order_count: {
    name: 'Trip Order Count - Rider Level',
    columns: getColumnsForReport('rider_trip_order_count') || rider_trip_order_count,
    type: 'table',
  },
  store_gps_km: {
    name: 'Store GPS Km',
    columns: getColumnsForReport('store_gps_km') || store_gps_km,
    type: 'table',
  },
  store_trip_order_count: {
    name: 'Trip Order Count - Store Level',
    columns: getColumnsForReport('store_trip_order_count') || store_trip_order_count,
    type: 'table',
  },
  order_summary: {
    name: 'Order Wise Service Time',
    columns: getColumnsForReport('order_summary') || order_summary,
    type: 'table',
  },
  store_distance_metrics : {
    name: 'Distance Aggregate Table',
    columns: getColumnsForReport('store_distance_metrics') || store_distance_metrics,
    type: 'table',
  },
  vehicle_distance_metrics : {
    name: 'Bike Wise Aggregated Distance',
    columns: getColumnsForReport('vehicle_distance_metrics') || [],
    type: 'table',
  },
  store_distance_exceptions : {
    name: 'Store Exception Metrics',
    columns: getColumnsForReport('store_distance_exceptions') || store_distance_exceptions,
    type: 'table',
  },
  rider_distance_exceptions : {
    name: 'Rider Exception Metrics',
    columns: getColumnsForReport('rider_distance_exceptions') || rider_distance_exceptions,
    type: 'table',
  },
  rider_checkin_checkout : {
    name: 'Rider Check In Check Out',
    columns: getColumnsForReport('rider_checkin_checkout') || rider_checkin_checkout,
    type: 'table',
  },
  store_incentive_table : {
    name: 'Incentive Table',
    columns: getColumnsForReport('store_incentive_table') || store_incentive_table,
    type: 'table',
  },
  store_partially_completed : {
    name: 'Partially Completed Orders',
    columns: getColumnsForReport('store_partially_completed') || store_partially_completed,
    type: 'table',
  },
  rider_missing_checkout : {
    name: 'Missing Checkout',
    columns: getColumnsForReport('rider_missing_checkout') || rider_missing_checkout,
    type: 'table',
  },
  rider_km_reading : {
    name: 'Rider Km Reading',
    columns: getColumnsForReport('rider_km_reading') || [],
    type: 'table',
  },
  };
}

export default { viewControl, getOnDemandBuckets, getOnDemandCharts };
