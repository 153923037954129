import { NAVBAR_HEIGHT } from 'src/globals';
import { CssType, ThemeType } from '../../../../../theming/jssTypes';

const styles = (theme: ThemeType): CssType => ({
    navbar: {
        height: `${NAVBAR_HEIGHT}px`,
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexShrink: 0,
        padding: '4px 16px',
        borderBottom: `1px solid ${theme.colors.primaryText}`,
        color: theme.colors.textOnDarkBg,
        backgroundColor: theme.colors.globalHeader,
    },
    hamburger: {
        '&:hover': {
            cursor: 'pointer',
        },
        color: theme.colors.textOnDarkBg,
        fontSize: theme.sizes.fontSize,
    },
    leftDiv: {
        display: 'flex',
        alignItems: 'center',
    },
    filterGroupToolsLeft: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-start',
        marginLeft: '20px',
    },
    filterGroupToolsRight: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
        flex: 1,
    },
    profileDropDown: {
        backgroundColor: theme.colors.globalHeader,
        boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.24)',
        borderRadius: 4,
        color: theme.colors.textOnDarkBg,
        '& .ant-menu ': {
            color: theme.colors.textOnDarkBg,
            backgroundColor: theme.colors.globalHeader
        },
        '.ant-menu-item a': {
            color: theme.colors.textOnDarkBg,
        },
        '& .ant-menu-item': {
            color: `${theme.colors.textOnDarkBg} !important`,
        },
        '& .ant-menu-item:hover': {
            color: theme.colors.textOnDarkBg,
            backgroundColor: `${theme.colors.HoverOnDarkBg} !important`,
        },
        '& .ant-menu-item-selected': {
            backgroundColor: `${theme.colors.selectionOnDarkBg} !important`,
        },
        '& .ant-menu-item, & .ant-menu-item-active': {
            border: 'none !important',
        },
        margin: '0px 4px',
    },
    toolContainer: {
        marginLeft: '10px',
        '& .ant-select-selector': {
            border: 'none !important',
        }
        // '&:not(:last-child)': {
        //   marginLeft: '8px',
        // },
    },
    profile: {
        height: 28,
        width: 28,
        borderRadius: 14,
        backgroundColor: theme.colors.globalHeader,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        paddingLeft: 10,
        marginLeft: 12,
        color: theme.colors.textOnDarkBg,
        fontWeight: 'bold',
    },
    circle: {
        width: '25%',
        marginLeft: 10,
    },
    trackIcon: {
        cursor: 'pointer',
        margin: '0 4px',
        marginTop: 4,
    },
    helpIcon: {
        fontSize: '21px',
        color: theme.colors.textOnDarkBg,
        margin: '0px 4px',
    },
    pageTitle: {
        fontSize: 16,
        marginLeft: '16px',
        fontWeight: 600,
    },
});

export default styles;
