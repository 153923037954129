import React from 'react';
import lodash from 'lodash';
import ViewDocumentModalButton from "./components/ViewDocumentModalButton";
import { getRetailConsignmentStatus } from 'src/utils/retailConsignment';
import { Tooltip } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { getTMSBagDetailsRoute, getTMSIstDetailsRoute } from 'src/components/pages/TMSDashboard/routes';
import { getDetailsRoute, getRetailTripDetailsRoute } from 'src/routing/utils';
import { formatDate, formatDateTime } from 'src/utils/utils';
import moment from 'moment';
import PieceDetailsCell from './components/PieceDetailsCell';
import JobDetailsCell from './components/JobDetailsCell';
import ConsignmentTypeCell from './components/ConsignmentTypeCell';
import { ellipsisWithTooltip } from 'src/components/common/utils';
import ViewFilesBtn from './components/ViewFilesBtn';
import CustomerFeedbackCell from './components/CustomerFeedbackCell';

const renderTripNumber = (field, value, record) => {
    const column_id = field.id;
    const tripNo = column_id === 'trip_assigned' ?
    record.trip_organisation_reference_number : record[column_id];
    const openInNewTab = (column_id === 'trip_assigned');
    return tripNo ?
        <Link to={column_id === 'middle_mile_trip_number' ?
            getTMSIstDetailsRoute(tripNo) : getRetailTripDetailsRoute(tripNo)}
            target={openInNewTab ? '_blank' : null}>
            {tripNo}
        </Link>
    : 'Unassigned';
};

const renderDateTime = (field, value, record) => {
    if (value) {
        return (formatDateTime(value));
    }
    return '-';
};

const renderTime = (field, value, record) => {
    if (value) {
        return (`${moment(parseInt(value,10)).format('HH:mm')}`);
    }
    return '-';
};

const renderDate = (field, value, record) => {
    if (value) {
        return (formatDate(value));
    }
    return '-';
};

const renderReferenceNumber = (field, value, record, otherParams, route = null) => {
    return <Link to={{
        pathname: route || getDetailsRoute(encodeURIComponent(value)),
    }}>{value}</Link>;
};

const renderAddress = (field, value, record) => {
    const col_line_1 = `${field.id}_line_1`;
    const col_line_2 = `${field.id}_line_2`;
    const addressText = [record[col_line_1], record[col_line_2] ?? ''].join(' ') || '-';
    return ellipsisWithTooltip(addressText, {
        style: {
            width: '100%',
            maxWidth: '100%',
        },
    });
};

const renderCapitalizedValue = (field, value, record) => {
    return <div style = {{ textTransform : 'capitalize' }}>{value || '-'}</div>;
};

const renderUppercaseValue = (field, value, record) => {
    return <div style = {{ textTransform : 'uppercase' }}>{value || '-'}</div>;
};

const renderBoolean = (trueVal, falseVal) => (field, value, record) => {
    const val = value ? trueVal : falseVal;
    return <>{val}</>;
}

const renderDimensions = (field, value, record) => {
    const prefix = field.id.includes('given') ? 'given_' : '';
    const length = record[prefix + 'length'];
    const width = record[prefix + 'width'];
    const height = record[prefix + 'height'];
    if(length && width && height) {
        return `${length}*${width}*${height}`;
    }
    return '-';
}

const columns = {
    document_link: (field, value, record) => {
        return <ViewDocumentModalButton documentDetails={record.document_details}/>;
    },
    status: (field, value, record) => {
        return (
            <div>
              {getRetailConsignmentStatus(value)}
              {record.is_partial_pickedup === 'Yes' ?
                <Tooltip title={'Partially Pickedup'}>
                  <WarningFilled
                    style={{ fontSize: 18, color: '#EC544A', marginLeft: 8 }}
                  />
              </Tooltip> : record.is_partial_delivery ?
                <Tooltip title={
                  !record.consignment_type || record.consignment_type === 'forward' ||  record.consignment_type === 'pickupanddelivery'?
                  'Partially Delivered' : 'Partially Pickedup'}>
                  <WarningFilled style={{ fontSize: 18, color: '#EC544A', marginLeft: 8 }} />
                </Tooltip> : null}
            </div>
          );
    },
    trip_assigned: renderTripNumber,
    delivery_trip_number: renderTripNumber,
    middle_mile_trip_number: renderTripNumber,
    pickup_trip_number: renderTripNumber,
    created_at: renderDateTime,
    updated_at: renderDateTime,
    last_main_event_time: renderDateTime,
    hub_arrival_time: renderDateTime,
    rescheduled_date: renderDateTime,
    payment_cleared_date: renderDateTime,
    first_inscan_at_hub_time: renderDateTime,
    delivery_time_slot_start: renderDateTime,
    delivery_time_slot_end: renderDateTime,
    ivr_call_date: renderDateTime,
    pickup_time_slot_start: renderTime,
    pickup_time_slot_end: renderTime,
    scheduled_at: renderDate,
    no_of_pieces: (field, value, record, otherParams) => {
        return <PieceDetailsCell
            pieces={record.pieces_detail}
            record={record}
            handleReload={otherParams.reload}/>;
    },
    job_details: (field, value, record, otherParams) => {
        return <JobDetailsCell
            jobs={value}
            record={record}
            handleReload={otherParams.reload}/>;
    },
    consignment_type: (field, value, record) => {
        return <ConsignmentTypeCell value={value} />;
    },
    reference_number: renderReferenceNumber,
    forward_reference_number: renderReferenceNumber,
    rto_reference_number: renderReferenceNumber,
    split_original_reference_number: renderReferenceNumber,
    is_cod: (field, value, record) => {
        const str = value ? 'True' : 'False';
        return <>{str}</>;
    },
    cod_amount_collected: (field, value, record) => {
        const sum = (value || []).reduce((acc, it) => {
            return acc + (it.cod_amount || 0);
        }, 0);
        return <>{sum}</>;
    },
    sender_address: renderAddress,
    destination_address: renderAddress,
    weight_retail: (field, value, record) => {
        const weight = record.weight;
        return <>{weight}</>;
    },
    proximity: renderCapitalizedValue,
    express_direction_type: renderCapitalizedValue,
    movement_type: renderUppercaseValue,
    leg_type: renderUppercaseValue,
    execution_status: (field, value, record, otherParams) => {
        const { masterData } = otherParams;
        const executionStatusList = masterData?.consignment_execution_status_list || [];
        const data = executionStatusList?.find((lis) => lis.code === value)?.name || '-';
        return <>{data}</>;
    },
    is_cnv_done: renderBoolean('Verified', 'Not Verified'),
    is_payment_done: renderBoolean('Yes', 'No'),
    is_dangerous_good: renderBoolean('Yes', 'No'),
    is_cold_storage: renderBoolean('Yes', 'No'),
    is_hand_delivery: renderBoolean('Yes', 'No'),
    is_partial_delivery: renderBoolean('Yes', 'No'),
    openboxrvp: renderBoolean('Yes', 'No'),
    dimension: renderDimensions,
    given_dimension: renderDimensions,
    attempt_proof: (field, value, record) => {
        const data = [];
        record.poc_image_list?.forEach((image) => {
            if(image?.url) {
                data.push({
                    url: image.url,
                });
            }
          });
          record.poc_video_list?.forEach((video) => {
            if(video?.url) {
                data.push({
                    url: video.url,
                    type: 'video',
                });
            }
          });

          // Piece level images/videos
          record.pieces_detail?.forEach((piece) => {
            if(piece?._internal?.image_url_list) {
                data.push(...(piece._internal.image_url_list?.map(url => ({ url })) || []));
            }
          });
          record.pieces_detail?.forEach((piece) => {
            if(piece?._internal?.video_url_list) {
                data.push(...(piece._internal.video_url_list?.map(url => ({ url, type: 'video' })) || []));
            }
          });

          return <ViewFilesBtn checkType={true} values={data} record={record}/>;
    },
    customer_pod: (field, value, record) => {
       return <ViewFilesBtn checkType={false} values={value} record={record}/>;
    },
    vendor_image_url_list: (field, value, record) => {
        return <ViewFilesBtn checkType={true} values={value} record={record}/>;
    },
    customer_feedback: (field, value, record, otherParams) => {
        return <CustomerFeedbackCell record={record} reload={otherParams.reload}/>;
    },
    bag_reference_number: (field, value, record, otherParams) => {
        return renderReferenceNumber(field, value, record, otherParams, getTMSBagDetailsRoute(value));
    },
    worker_name: (field, value, record, otherParams) => {
        return value || 'Unassigned';
    },
    job_status: (field, value, record, otherParams) => {
        const jobStatus = value;
        return jobStatus?.event_type ?
        <div>
            <div>{jobStatus.event_type}</div>
            <div style={{
                color: '#595959',
                paddingTop: '3px',
                fontSize: '12px',
                minHeight: '20px',
            }}>
                {formatDateTime(jobStatus.event_time)}
            </div>
        </div> : <>-</>;
    },
    latest_delivery_geocode_difference: (field, value, record, otherParams) => {
        const distance = lodash.get(record,'latest_delivery_event.geocode_location_difference') || '-';
        return <div>{distance}</div>;
    },
    latest_pickup_geocode_difference: (field, value, record, otherParams) => {
        const distance = lodash.get(record,'latest_pickup_event.geocode_location_difference') || '-';
        return <div>{distance}</div>;
    },
    latest_delivery_worker_name: (field, value, record, otherParams) => {
        const workerName = lodash.get(record,'latest_delivery_event.worker_name') || '-';
        return <div>{workerName}</div>;
    },
    latest_delivery_worker_code: (field, value, record, otherParams) => {
        const workerCode = lodash.get(record,'latest_delivery_event.worker_code') || '-';
        return <div>{workerCode}</div>;
    },
};

export default columns;