import * as React from 'react';
import withStyles from 'react-jss';
import { CssType,
  ThemeType} from '../../theming/jssTypes';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const styles = (theme: ThemeType): CssType => ({
  loaderContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: 0,
    zIndex: -1,
  },
});

const Loader = (props) => {
  const { classes, zIndex, top, size, backdropBlur, left, className } = props;
  return (
    <div className={className || classes.loaderContainer} style={{
        zIndex: (zIndex !== null || zIndex !== undefined) ? zIndex : -1,
        top: top ? top : 0,
        left: left ? left : 0,
        backdropFilter: backdropBlur ? 'blur(1px)' : null,
      }}>
      <Spin indicator={
        <LoadingOutlined style={{ fontSize: size ? size : 40 }} spin />
      } />
    </div>
  );
};
export default withStyles(styles, { injectTheme: true })(Loader);
