import * as React from 'react';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '../../theming/jssTypes';
import { Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import { checkPermissionsForNavigationShortcuts, checkPermission } from './utils/route-utils';
import { SearchOutlined } from '@ant-design/icons';
import Downshift from 'downshift';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { closeSideMenu } from 'src/actions/navigationActions';

const { useState, useEffect } = React;

const styles = (theme: ThemeType): CssType => ({
  searchResult: {
    textDecoration: 'none',
    color: theme.colors.textOnLightBg,
    backgroundColor: theme.colors.surfaceBg,
    cursor: 'pointer',
    margin: 0,
    border: 0,
    width: '100%',
    textAlign: 'left',
    padding: '10px 20px',
  },
  isCurrentlySelected: {
    backgroundColor: theme.colors.selectionOnDarkBg,
    color: theme.colors.textOnDarkBg,
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    maxHeight: '300px',
    overflowY: 'scroll',
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  inputFieldModal: {
    width: '90%',
    border: 'none',
    marginLeft: '10px',

    '&:focus': {
      outline: 'none',
    },
  },
});

const getReferenceNumberFromSearchVal = (searchValue) => {
  return searchValue ? searchValue.toUpperCase().replace('CN:', '').replace('TRIP:', '').trim() : '';
};

const QuickSearchModal = (props: any) => {
  const { classes, masterData, isModalVisible, setModalVisibility, closeSideMenu } = props;
  const [searchValue, setSearchValue] = useState('');
  const [allNavigationRoutes, setAllNavigationRoutes] = useState([]);
  const [currSearchResults, setCurrSearchResults] = useState([]);
  const [selectedSearchResult, setSelectedSearchResult] = useState(0);
  const history = useHistory();

  const handleKeyPressEvent = (event) => {
    if ((event.ctrlKey || event.metaKey) && event.code === 'KeyK') {
      event.preventDefault();
      closeSideMenu();
      setModalVisibility(!isModalVisible);
    }
  };

  const handleKeyboardInput = (event) => {
    setSearchValue(event.target.value);
  };

  const redirectToSelectedRoute = (path, clearSelection = null) => {
    if (clearSelection) {
      clearSelection();
    }
    if (path === '/analytics') {
      const currentURL = window.location.href;
      const indexTillBaseURL = currentURL.indexOf('/', 10);
      window.location.href = currentURL.slice(0, indexTillBaseURL) + path;
    } else {
      history.push(path);
    }
    setModalVisibility(false);
    setSearchValue('');
  };

  useEffect(() => {
    const navigationRoutesList = checkPermissionsForNavigationShortcuts(masterData);
    const allowedNavigationRoutes = navigationRoutesList.filter(navigationRouteObject => navigationRouteObject.isAllowed);

    setAllNavigationRoutes(allowedNavigationRoutes);
    setCurrSearchResults(allowedNavigationRoutes);
  }, []);

  const handleSearchKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (searchValue.toUpperCase().trim().startsWith('CN:')) {
        redirectToSelectedRoute(`/details/${getReferenceNumberFromSearchVal(searchValue)}`);
      } else if (searchValue.toUpperCase().trim().startsWith('TRIP:')) {
        redirectToSelectedRoute(`/retail/trip/details/${getReferenceNumberFromSearchVal(searchValue)}`);
      }
    }
  };

  useEffect(() => {
    if (isModalVisible) {
      setTimeout(() => document.getElementById('keyboard-shortcut-input')?.focus(), 200);
      if (searchValue !== '') {
        const currSearchResultsToBeSet = allNavigationRoutes.filter(
          searchResult => searchResult.prettyName?.toLowerCase().includes(searchValue?.toLowerCase()),
        );

        if (Array.isArray(currSearchResultsToBeSet) && !currSearchResultsToBeSet.length) {
          const referenceNumber = getReferenceNumberFromSearchVal(searchValue);
          currSearchResultsToBeSet.push({
            route: `/details/${referenceNumber}`,
            prettyName: `Search Consignment with Reference number: ${referenceNumber}`,
            checkRoutePermission: (masterData) =>
              checkPermission(masterData, 'consignments'),
            isAllowed: false,
          });
          currSearchResultsToBeSet.push({
            route: `/retail/trip/details/${referenceNumber}`,
            prettyName: `Search Trip with Reference number: ${referenceNumber}`,
            checkRoutePermission: (masterData) =>
              checkPermission(masterData, 'FLMSubmenuTripManager'),
            isAllowed: false,
          });

          checkPermissionsForNavigationShortcuts(masterData, currSearchResultsToBeSet);
        }

        setCurrSearchResults(currSearchResultsToBeSet);
        setSelectedSearchResult(0);
      } else {
        setCurrSearchResults(allNavigationRoutes);
        setSelectedSearchResult(0);
      }
    }
  }, [searchValue, isModalVisible]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPressEvent);

    return () => window.removeEventListener('keydown', handleKeyPressEvent);
  }, [isModalVisible, currSearchResults, selectedSearchResult]);

  return (
    <Downshift
      itemToString={item => item ? item.route : ''}
    >
      {({
        getInputProps,
        getMenuProps,
        getItemProps,
        getRootProps,
        highlightedIndex,
        clearSelection,
        selectedItem,
        isOpen,
      }) => (
        <div {...getRootProps({}, {suppressRefError: true})}>
          <Modal
            width={500}
            zIndex={1500}
            title={
              <>
            <SearchOutlined />
                <input
                  {...getInputProps({
                    id: 'keyboard-shortcut-input',
                    autoFocus: true,
                    className: classes.inputFieldModal,
                    placeholder: 'Jump To...',
                    onChange: handleKeyboardInput,
                    value: searchValue,
                    autoComplete: 'off',
                    onKeyDown: handleSearchKeyDown,
                  })}
                />
              </>
            }
            visible={isModalVisible}
            footer={null}
            onCancel={() => setModalVisibility(false)}
          >
            {selectedItem ? redirectToSelectedRoute(selectedItem.route, clearSelection) : null}
            <ul {...getMenuProps({
              id: 'search-results-modal',
              className: classes.modal,
            }, {
              suppressRefError: true,
            })}>
              {currSearchResults.map((item, idx) => (
                  <li
                    {...getItemProps({
                      item,
                      key: item.route,
                      className: idx === highlightedIndex ? [classes.searchResult, classes.isCurrentlySelected].join(' ') : classes.searchResult,
                    })}
                  >
                    {item.prettyName}
                    {idx === highlightedIndex && item.route.startsWith('/details/') && setSearchValue(`CN:${getReferenceNumberFromSearchVal(searchValue)}`)}
                    {idx === highlightedIndex && item.route.startsWith('/retail/trip/details/') && setSearchValue(`TRIP:${getReferenceNumberFromSearchVal(searchValue)}`)}
                  </li>
                ),
              )}
            </ul>
          </Modal>
        </div>
      )}
    </Downshift>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
      closeSideMenu,
  }, dispatch);
};

const mapStateToProps = ({ masterData }) => {
  return {
      masterData,
  };
};

const QuickSearchModalStyled = withStyles(styles, { injectTheme: true })(QuickSearchModal);
export default connect(mapStateToProps, mapDispatchToProps)(QuickSearchModalStyled);
