import * as React from 'react';
import withStyles from 'react-jss';
import { connect } from 'react-redux';
import { CssType,
  ThemeType} from '../../../../theming/jssTypes';
import { Modal, Table, Carousel, Tooltip } from 'antd';
import { pieceDetailColumns, pieceDetailColumnsExpress, extraPieceDetailColumns } from './masterData';
import { getConsignmentStatusPrettyName } from '../../../../utils/consignmentStatus';
import { LeftCircleOutlined, RightCircleOutlined, WarningFilled } from '@ant-design/icons';
import FixedHeaderTable from 'src/components/common/FixedHeaderTable/FixedHeaderTable';

const styles = (theme: ThemeType): CssType => ({
  tableContainer: {
    flex: '1 1 0',
    overflow: 'auto',
    height: 300,
  },
  modalContainer: {
    padding: 0,
    margin: 0,
  },
  carouselContainer: {
    padding: '1.5rem',
    maxWidth: '100%',
    '& span': {
      color: '#222021 !important',
    },
    '& span > svg': {
      width: '120%',
      height: '120%',
    },
  },
});

class PieceDetailsModal extends React.Component<any, any> {

  state = {
    isGalleryModalVisible: false,
    galleryData: {
      images: [],
      videos: [],
    },
  };

  getPieceDetailsColumnsToShow = () => {
    const { showLogisticsExpressOptions, showItemUnitInPieceDetails } = this.props;
    const columnList = showLogisticsExpressOptions ?
                      [...pieceDetailColumnsExpress] :[...pieceDetailColumns];
    if(showItemUnitInPieceDetails){
      columnList.push(...extraPieceDetailColumns);
    }
    return columnList;
  };

  generateDataColumns = () => {
    const { showLogisticsExpressOptions, classes } = this.props;
    const columnData: any = [
      { title: 'S.No.',
        key: 'index',
        render : (text, record, index) => index + 1,
        width: 50,
      },
    ];
    const indexList = this.getPieceDetailsColumnsToShow();
    indexList.forEach((item) => {
      const itemToPush = {};
      itemToPush['title'] = item.pretty_name;
      itemToPush['dataIndex'] = item.column_id;
      itemToPush['width'] = 80;
      itemToPush['key'] = item.column_id;

      if (item.column_id === 'status') {
        itemToPush['render'] = (x, record) => {
          return record._internal ? (
            <span>
              {getConsignmentStatusPrettyName(record._internal.status)}
              {record.is_partial_delivery ? (
                <Tooltip title={'Partially Delivered'}>
                  <WarningFilled
                    style={{ fontSize: 18, color: '#EC544A', marginLeft: 8 }}
                  />
                </Tooltip>
              ) : record.is_partial_pickedup === 'Yes' ? (
                <Tooltip title={'Partially Pickedup'}>
                  <WarningFilled
                    style={{ fontSize: 18, color: '#EC544A', marginLeft: 8 }}
                  />
                </Tooltip>
              ) : <></>}
            </span>
          ) : null;
        };
      }if (item.column_id === 'delivery_failure_reason') {
        itemToPush['render'] = (x, record) => {
          return (
            record._internal && (record._internal.status === 'attempted'
            || record._internal.status === 'not_picked_up') ?
              <span>{(this.props.pieceDetails.ndrReason)}</span> : null
          );
        };
      } if (item.column_id === 'volume') {
        itemToPush['title'] = <span>Volume(m<sup>3</sup>)</span>;
        itemToPush['render'] = (x, record) => {
          const volume = record.volume ? record.volume : '';
          return (
            <span>{`${volume}`}</span>
          );
        };
      } if (item.column_id === 'weight') {
        itemToPush['render'] = (x, record) => {
          const weight = record.weight ? record.weight : '';
          return (
            <span>{`${weight}`}</span>
          );
        };
      } if(item.column_id === 'quantity') {
        itemToPush['render'] = (x, record) => {
          const quantityOrdered = record.ordered_quantity ? record.ordered_quantity : x;
          return (
            <span>{`${quantityOrdered}`}</span>
          );
        }
      } if (item.column_id === 'quantity_delivered') {
        itemToPush['render'] = (x, record) => {
          const quantity_delivered = record.hasOwnProperty('_internal') &&
            (record._internal.quantity_delivered ?? '');
          return (
            <span>{`${quantity_delivered}`}</span>
          );
        };
      } if (item.column_id === 'cod_amount') {
        itemToPush['render'] = (x, record) => {
          return (
            <span>{x}</span>
          );
        };
      } if (item.column_id === 'is_cnv_done') {
        itemToPush['render'] = (x, record) => {
          return (
            x ? 'Verified' : 'Not Verified'
          );
        };
      } if (item.column_id === 'attempt_proof') {
        itemToPush['render'] = (x, record) => {
          return (
            <>
              {record._internal?.image_url_list?.length > 0
                || record._internal?.video_url_list?.length > 0 ?
                <a
                  onClick={() => {
                    this.setState({
                      isGalleryModalVisible: true,
                      galleryData: {
                        images: record._internal?.image_url_list,
                        videos: record._internal?.video_url_list,
                      },
                    });
                  }}
                  className={classes.btn}
                >
                  View
                </a>
                : 'Not Available'
              }
            </>
          );
        };
      }
      columnData.push(itemToPush);
    });
    return columnData;
  };
  render() {
    const { isVisible, classes, pieceDetails } = this.props;
    const { isGalleryModalVisible, galleryData } = this.state;
    const header = `${pieceDetails.selectedReferenceNumber} Piece Detail View`;
    return (
        <Modal
        title= {header}
        bodyStyle={{
          padding: 0,
        }}
        width={900}
        visible={isVisible}
        footer={null}
        onCancel={() => this.props.handleModalClose(false)}
        destroyOnClose
        >
          <div className={classes.tableContainer}>
            <FixedHeaderTable
                locale={{ emptyText: 'No pieces' }}
                pagination={false}
                dataSource={pieceDetails.pieceDetailsData}
                columns={this.generateDataColumns()}
              />
          </div>
          {isGalleryModalVisible && (
            <Modal
              className={classes.modalContainer}
              visible={isGalleryModalVisible}
              onCancel={() => {
                this.setState({
                  isGalleryModalVisible: false,
                });
              }}
              footer={null}
            >
              <Carousel
                className={classes.carouselContainer}
                prevArrow={<LeftCircleOutlined />}
                nextArrow={<RightCircleOutlined />}
                arrows
              >
                {galleryData?.images?.map((url) => (
                  <div key={url}>
                    <img object-fit="cover" style={{ width: '100%' }} src={url} />
                  </div>
                ))}
                {galleryData?.videos?.map((url) => (
                  <div key={url} style={{ maxWidth: '100%' }}>
                    <video controls style={{ maxWidth: '100%' }}>
                      <source src={url} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                ))}
              </Carousel>
            </Modal>
          )}
        </Modal>
    );
  }
}

const mapStateToProps = ({ masterData }) => {
  return {
    showLogisticsExpressOptions: masterData.show_logistics_express_options,
    showItemUnitInPieceDetails: masterData.show_item_unit_in_piece_details,
  };
};
const StyledPieceDetailsModal =
  withStyles(styles, { injectTheme: true })(PieceDetailsModal);
export default connect(mapStateToProps, null)(StyledPieceDetailsModal);
