const FETCH_ALL_LIVE_DASHBOARD_DATA = 'FETCH_ALL_LIVE_DASHBOARD_DATA';
const FETCH_ALL_LIVE_DASHBOARD_DATA_ERROR = 'FETCH_ALL_LIVE_DASHBOARD_DATA_ERROR';
const FETCH_ALL_LIVE_DASHBOARD_DATA_LOADING = 'FETCH_ALL_LIVE_DASHBOARD_DATA_LOADING';
const FETCH_LIVE_DASHBOARD_SUMMARY_METRICS_LOADING = 'FETCH_LIVE_DASHBOARD_SUMMARY_METRICS_LOADING';
const FETCH_LIVE_DASHBOARD_SUMMARY_METRICS_ERROR = 'FETCH_LIVE_DASHBOARD_SUMMARY_METRICS_ERROR';
const FETCH_LIVE_DASHBOARD_SUMMARY_METRICS = 'FETCH_LIVE_DASHBOARD_SUMMARY_METRICS';
const LIVE_DASHBOARD_APPLY_FILTERS = 'LIVE_DASHBOARD_APPLY_FILTERS';
const SET_TASK_DETAIL_DATA = 'SET_TASK_DETAIL_DATA';
const FETCH_ALL_RIDER_DATA = 'FETCH_ALL_RIDER_DATA';
const FETCH_ALL_RIDER_DATA_ERROR = 'FETCH_ALL_RIDER_DATA_ERROR';
const FETCH_ALL_RIDER_DATA_LOADING = 'FETCH_ALL_RIDER_DATA_LOADING';
const LIVE_DASHBOARD_SET_PAGINATION = 'LIVE_DASHBOARD_SET_PAGINATION';
const LIVE_DASHBOARD_RESET_PAGINATION = 'LIVE_DASHBOARD_RESET_PAGINATION';
const LIVE_DASHBOARD_SET_TRIP_DETAILS = 'LIVE_DASHBOARD_SET_TRIP_DETAILS';
const LIVE_DASHBOARD_SET_TRIP_DETAILS_ERROR = 'LIVE_DASHBOARD_SET_TRIP_DETAILS_ERROR';

export {
  FETCH_ALL_LIVE_DASHBOARD_DATA,
  FETCH_ALL_LIVE_DASHBOARD_DATA_ERROR,
  FETCH_ALL_LIVE_DASHBOARD_DATA_LOADING,
  FETCH_LIVE_DASHBOARD_SUMMARY_METRICS_LOADING,
  FETCH_LIVE_DASHBOARD_SUMMARY_METRICS_ERROR,
  FETCH_LIVE_DASHBOARD_SUMMARY_METRICS,
  LIVE_DASHBOARD_APPLY_FILTERS,
  SET_TASK_DETAIL_DATA,
  FETCH_ALL_RIDER_DATA,
  FETCH_ALL_RIDER_DATA_ERROR,
  FETCH_ALL_RIDER_DATA_LOADING,
  LIVE_DASHBOARD_SET_PAGINATION,
  LIVE_DASHBOARD_RESET_PAGINATION,
  LIVE_DASHBOARD_SET_TRIP_DETAILS,
  LIVE_DASHBOARD_SET_TRIP_DETAILS_ERROR,
};
