import React from 'react';
import DocumentDetailsModal from 'src/components/common/DocumentDetailsModal';

interface IProps {
    documentDetails: {
        type: string, 
        url: string,
    }[];
}

const ViewDocumentModalButton = (props: IProps) => {
    const { documentDetails } = props;
    const [ modalVisible, setModalVisible ] = React.useState(false);

    if (!documentDetails) {
        return <>Not Available</>;
    }

    const renderModal = () => {
        if (!modalVisible) return <></>;

        return <DocumentDetailsModal
            isVisible={modalVisible}
            onModalClose={() => {
                setModalVisible(false);
            }}
            documentDetails={documentDetails}
        >
        </DocumentDetailsModal>;
    };

    return <>
        <a
            onClick={(e) => {
                e.preventDefault();
                setModalVisible(true);
            }}
            style={{ color: '#1890FF', cursor:'pointer' }}
        >
            View
        </a>
        {renderModal()}
    </>;
};

export default ViewDocumentModalButton;
