import * as React from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Modal, message, Table, Input, Select, Radio } from 'antd';
import FailureArrayHandler from '../../../../common/FailureArrayHandler';
import { useTheme } from 'react-jss';
import {
  qualityCheckforCN,
  updateQcCheck,
} from '../../../../../api/genericConsignmentView';
import useStyles from './QcCheckModal.styles';
import { getColsQcCheckModal } from '../../columns';
import { connect } from 'react-redux';
import { rtoReasons } from '../masterData';
const { useState } = React;
const Option = Select.Option;

const QcCheckModal = (props) => {
  const {
    isVisible,
    onModalClose,
    handleModalClose,
    showQcStatus,
    showMarkRtoOptions,
  } = props;
  const theme = useTheme();
  const classes = useStyles({ ...props, theme });
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isErrorModalVisible, setErrorModalVisible] = useState(false);
  const [failureArray, setFailureArray] = useState([]);
  const [cnList, setCnList] = useState<Object[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [isTableLoading, setIsTableLoading] = useState(false);
  const getWidth = (key: string) => {
    switch (key) {
      case 'serial_number':
        return 100;
      case 'is_cnv_done':
        return 200;
      default:
        return 150;
    }
  };
  const renderSerialNumber = (text, record, index) => {
    return index + 1;
  };

  const renderQcCheckStatus = (x, record) => {
    return (
      <Select
        onChange={(val) => setBooleanFields(val, record, 'is_cnv_done')}
        className={
          x === true ? classes.selectQcChecked : classes.selectQcNotChecked
        }
        defaultValue={'checked'}
      >
        <Option className={classes.qcChecked} key={'checked'} value={'checked'}>
          Verified
        </Option>
        <Option
          className={classes.qcNotChecked}
          key={'unchecked'}
          value={'unchecked'}
        >
          Not Verified
        </Option>
      </Select>
    );
  };

  const renderActions = (record) => {
    return <EditOutlined onClick={() => editDimensions(record)} />;
  };

  const getRecordIndex = (record) => {
    return cnList.findIndex(
      (cn: any) => cn.reference_number === record.reference_number,
    );
  };

  const editDimensions = (record) => {
    const elementsIndex = getRecordIndex(record);
    const cnlist = [...cnList];
    cnlist[elementsIndex]['is_action_button_clicked'] = true;
    setCnList(cnlist);
  };

  const onWeigthAndDimensionsChange = (e, key, record) => {
    const elementsIndex = getRecordIndex(record);
    const cnlist = [...cnList];
    cnlist[elementsIndex][key] = e.target.value;
    setCnList(cnlist);
  };

  const setBooleanFields = (val, record, key) => {
    const elementsIndex = getRecordIndex(record);
    const cnlist = [...cnList];
    if (val === 'checked') {
      cnlist[elementsIndex][key] = true;
    } else if (val === 'unchecked') {
      cnlist[elementsIndex][key] = false;
    } else {
      cnlist[elementsIndex][key] = val;
    }
    setCnList(cnlist);
  };

  const renderWeightAndDimensions = (key, x, record) => {
    return record.is_action_button_clicked ? (
      <Input
        defaultValue={x}
        onChange={(e) => onWeigthAndDimensionsChange(e, key, record)}
      />
    ) : (
      x
    );
  };

  const renderQcStatus = (record) => {
    return (
      <Select
        onChange={(val) => {
          setBooleanFields(val, record, 'qc_status');
          if (val === 'checked') {
            setBooleanFields(undefined, record, 'mark_as_rto');
            onRtoReasonChange(undefined, record);
          }
        }}
        className={classes.qcStatus}
      >
        <Option className={classes.qcChecked} key={'checked'} value={'checked'}>
          Pass
        </Option>
        <Option
          className={classes.qcNotChecked}
          key={'unchecked'}
          value={'unchecked'}
        >
          Failed
        </Option>
      </Select>
    );
  };

  const renderMarkRto = (record) => {
    const cnObj = cnList[getRecordIndex(record)];
    return (
      <Radio.Group
        disabled={'qc_status' in cnObj ? cnObj['qc_status'] : true}
        onChange={(e) => {
          setBooleanFields(e.target.value, record, 'mark_as_rto');
          e.target.value === false
            ? onRtoReasonChange(undefined, record)
            : null;
        }}
        value={cnObj['mark_as_rto']}
      >
        <Radio value={true}>Yes</Radio>
        <Radio value={false}>No</Radio>
      </Radio.Group>
    );
  };

  const generateOptionsFromObjectList = (list) => {
    const optionsList: any = [];
    if (list) {
      list.forEach((element) => {
        optionsList.push(
          <Option key={element} value={element}>
            {element}
          </Option>,
        );
      });
    }
    return optionsList;
  };

  const onRtoReasonChange = (val, record) => {
    const elementsIndex = getRecordIndex(record);
    const cnlist = [...cnList];
    cnlist[elementsIndex]['rto_reason'] = val;
    setCnList(cnlist);
  };

  const renderRtoReason = (record) => {
    const cnObj = cnList[getRecordIndex(record)];
    return !('qc_status' in cnObj) ||
      cnObj['qc_status'] ||
      !cnObj['mark_as_rto'] ? (
      <div className={classes.disableRto}>-NA-</div>
    ) : (
      <Select
        onChange={(val) => onRtoReasonChange(val, record)}
        placeholder="Select Reason"
        className={classes.rtoReason}
      >
        {generateOptionsFromObjectList(rtoReasons)}
      </Select>
    );
  };

  const generateDataColumns = () => {
    const columnsToShow = getColsQcCheckModal(showQcStatus, showMarkRtoOptions);
    const columns = columnsToShow.map((col) => {
      return {
        dataIndex: col.column_id,
        title: col.pretty_name,
        width: getWidth(col.column_id),
        render: (x, record, index) => {
          switch (col.column_id) {
            case 'serial_number':
              return renderSerialNumber(x, record, index);
            case 'is_cnv_done':
              return renderQcCheckStatus(x, record);
            case 'length':
            case 'width':
            case 'height':
            case 'weight':
              return renderWeightAndDimensions(col.column_id, x, record);
            case 'action':
              return renderActions(record);
            case 'qc_status':
              return renderQcStatus(record);
            case 'mark_as_rto':
              return renderMarkRto(record);
            case 'rto_reason':
              return renderRtoReason(record);
            default:
              return x;
          }
        },
      };
    });
    return columns;
  };
  const handleAddCNClick = async (e) => {
    if (inputValue === '') {
      message.warning('Please enter a valid consignemnt number');
      return;
    }
    if (cnList.length) {
      const obj = cnList.find((obj: any) => {
        return obj.reference_number.toUpperCase() === inputValue.toUpperCase();
      });
      if (obj) {
        message.error('Consignment has already been scanned once');
        setInputValue('');
        return;
      }
    }
    setIsTableLoading(true);
    const params = {
      reference_number: inputValue,
    };
    const response = await qualityCheckforCN(params);
    if (response.isSuccess) {
      const data = response.data;
      //setting is_cnv_done to true as user open modal to do cnv only.
      //If user want to mark is is_cnv_done false -> select option will be there.
      data.is_cnv_done = true; 
      const cnlist = [...cnList, response.data];
      setCnList(cnlist);
      setInputValue('');
    } else {
      message.error(response.errorMessage);
      setInputValue('');
      setIsTableLoading(false);
      return;
    }
    setIsTableLoading(false);
  };
  const handleFailureModalClose = () => {
    setErrorModalVisible(false);
  };

  const onInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleUpdateQcCheck = async () => {
    setConfirmLoading(true);
    if (cnList.length === 0) {
      message.warning('No consignments to update');
      setConfirmLoading(false);
      return;
    }
    const body = {
      consignments: cnList,
    };
    const response = await updateQcCheck(body);
    if (response.isSuccess) {
      const data = response.data;
      if (data && data.failures && data.failures.length > 0) {
        setFailureArray(data.failures);
        setErrorModalVisible(true);
      } else {
        message.success('CN Verification Status Updated Successfully');
        onModalClose(true);
      }
    } else {
      message.error(response.errorMessage);
      onModalClose(false);
    }
    setConfirmLoading(false);
  };

  return (
    <Modal
      confirmLoading={confirmLoading}
      title={'CN Verification'}
      width={1100}
      visible={isVisible}
      onCancel={handleModalClose}
      onOk={handleUpdateQcCheck}
      destroyOnClose
    >
      <div>
        <div className={classes.addCNModal}>
          <label className={classes.label}>Consignment No.</label>
          <Input
            onChange={onInputChange}
            onPressEnter={handleAddCNClick}
            value={inputValue}
          />
        </div>
        <div className={classes.tableContainer}>
          <Table
            loading={isTableLoading}
            locale={{ emptyText: 'No consignments' }}
            pagination={false}
            dataSource={cnList}
            columns={generateDataColumns()}
            rowKey={(record: any) => record['reference_number']}
          />
        </div>
      </div>
      {isErrorModalVisible ? (
        <FailureArrayHandler
          failureArray={failureArray}
          isVisible={isErrorModalVisible}
          onModalClose={handleFailureModalClose}
        ></FailureArrayHandler>
      ) : null}
    </Modal>
  );
};

const mapStateToProps = ({ masterData, cachedData }) => {
  return {
    showQcStatus: masterData.cnv_config?.show_qc_status,
    showMarkRtoOptions: masterData?.cnv_config?.show_mark_rto_option,
    currHub: cachedData.currHub,
  };
};

export default connect(mapStateToProps)(QcCheckModal);
