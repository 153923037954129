import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { INITIALISE_DASHBOARD_GROUP } from 'src/actions/constants';
import { getDashboardGroup } from 'src/api/landingPageDashboard';

export const useDashboardGroup = () => {
  const dispatch = useDispatch();
  const dashboardGroupId = useSelector(
    (state) => state.masterData?.landingDashboardGroup?.dashboardGroupId,
  );
  const [dashboardApiStatus, setDashboardApiStatus] = useState('loading');
  const onRefetchDashboardData = async () => {
      try {
        if (dashboardGroupId) {
          const { data } = await getDashboardGroup({ dashboardGroupId });
          const dashboardTabIndex = 0;
          const dashboard = data?.dashboards;
          const reportIds = dashboard[
            dashboardTabIndex
          ].dashboardVisualisations.reports.map(
            (reports: any) => reports.reportId,
          );
          dispatch({
            type: INITIALISE_DASHBOARD_GROUP,
            payload: {
              dashboardGroupName: data?.name,
              dashboardGroupId: data?.id,
              dashboardGroupType: data?.type,
              dashboardType: data?.type,
              dashboardDescription: data?.description,
              dashboardCategory: data?.category,
              // autoReloadFrequency: data.autoReloadFrequency,
              autoReloadFrequency: 1314000000, // 1 year to temporarily disable auto reload
              reportsData: {},
              metaData: data,
              currentDashboardGroupTab: dashboardTabIndex,
              reportList: reportIds,
              dashboards: data?.dashboards,
              dashboardGridColumns: data?.dashboardGridColumns || 12,
            },
          });
          setDashboardApiStatus('success');
        } else {
          setDashboardApiStatus('error');
        }
      } catch (e) {
        console.log('error', e);
        setDashboardApiStatus('error');
      }
  };
  useEffect(() => {
    onRefetchDashboardData();
  }, [dashboardGroupId]);

  return {
    dashboardApiStatus,
    dashboardGroupId,
    onRefetchDashboardData,
  };
};
