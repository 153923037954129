import { CssType, ThemeType } from '@theming/jssTypes';

export const styles = (theme: ThemeType): CssType => ({
  tableContainer: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
  },
  timeline: {
    flex: '0.2 1 0%',
    margin: '10px',
    padding: '10px',
    border: `1px solid ${theme.colors.borderColor}`,
  },
  timelineWithLogs: {
    flex: '0.6 1 0%',
    marginLeft: '10px',
    marginTop: '10px',
    marginBottom: '10px',
    flexDirection: 'column',
  },
  download: {
    flex: '0.03 1 0%',
    border: `1px solid ${theme.colors.borderColor}`,
  },
  distanceTable: {
    flex: '0.97 1 0%',
    border: `1px solid ${theme.colors.borderColor}`,
    '& tr': {
      verticalAlign: 'top',
      height: '5px',
    },
    '& th': {
      verticalAlign: 'middle',
    },
    '& td': {
      verticalAlign: 'top',
    },
  },
  downloadText: {
    color: theme.colors.textOnLightBg,
    paddingLeft: '10px',
    paddingRight: '10px',
    fontSize: theme.sizes.bodyText,
    fontFamily: theme.fonts.fontFamily,
    fontStyle: 'italic',
    fontWeight: '400',
    wordWrap: 'break-word',
  },
  eventTime: {
    color: theme.colors.textOnLightBg,
    fontSize: theme.sizes.bodyText,
    fontFamily: theme.fonts.fontFamily,
    fontWeight: '400',
    wordWrap: 'break-word',
  },
  eventName: {
    color: theme.colors.textOnLightBg,
    fontSize: theme.sizes.bodyText,
    fontFamily: theme.fonts.fontFamily,
    fontWeight: '600',
    wordWrap: 'break-word',
  },
  distanceTableData: {

  },
});
