import { AES_ENCRYPTION_KEY, AUTH_RSA_PUBLIC_KEY } from "src/globals";
import lodash from 'lodash';

const getEncodedData = (data: string): Uint8Array => {
    const enc = new TextEncoder();
    return enc.encode(data);  
};

const getDecodedData = (data: ArrayBuffer): string => {
    const dec = new TextDecoder();
    return dec.decode(data);
}

const getKey = async () => {
    return window.crypto.subtle.importKey(
        "raw",
        getEncodedData(AES_ENCRYPTION_KEY),
        "AES-CBC",
        true,
        [
            "encrypt",
            "decrypt",
        ],
    );
}

const generateIV = (): string => {
    const iv = window.crypto.getRandomValues(new Uint8Array(8));
    const ivHex = Array.from(iv)
      .map(byte => ('0' + byte.toString(16)).slice(-2))
      .join('');
    return ivHex;
};

const encryptAES = async (data: string): Promise<string> => {
    let encoded = getEncodedData(data);
    const IV = generateIV();
    const encryptedData = await crypto.subtle.encrypt(
        {
          name: "AES-CBC",
          iv: getEncodedData(IV),
        },
        (await getKey()),
        encoded
    );

    const encryptedString = Array.from(new Uint8Array(encryptedData))
      .map(byte => ('0' + byte.toString(16)).slice(-2))
      .join('');

    // prefixing IV here to ensure the decrypting happens with same IV
    return (IV + encryptedString);
};

const decryptAES = async (data: string): Promise<string> => {
    const IV = data.substring(0, 16);
    const dataToDecryptString = data.substring(16);
    const dataToDecrypt = Uint8Array.from(Buffer.from(dataToDecryptString, 'hex'));
    const decryptedData = await window.crypto.subtle.decrypt(
        {
          name: "AES-CBC",
          iv: getEncodedData(IV),
        },
        (await getKey()),
        dataToDecrypt,
    );

    const decryptedString = getDecodedData(decryptedData);
    return decryptedString;
};

/////////////////////// RSA Encryption

const keyEnum = {
    authentication: AUTH_RSA_PUBLIC_KEY,
};

const generateArrayBufferFromRSA = (rsaKey: string) => {
    const replacedKey = rsaKey?.replace('-----BEGIN PUBLIC KEY-----', '')
        ?.replace('-----END PUBLIC KEY-----', '')?.replace(/\s+|\n\r|\n|\r$/gm, '');
    const binaryString = window.atob(replacedKey);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i += 1) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
};

const getPublicRSAKey = async (purpose: string) => {
    try {
        const key = lodash.get(keyEnum, purpose) || null;

        if (!key) {
            return null;
        }

        const arrayBufferKey = generateArrayBufferFromRSA(key);

        const publicKey = await window.crypto.subtle.importKey(
            'spki', arrayBufferKey,
            {
                name: 'RSA-OAEP',
                hash: { name: 'SHA-256' },
            },
            false,
            ['encrypt'],
        );

        return publicKey;
    } catch (err) {
        return null;
    }
};

const encryptRSA = async (purpose:string, password: string) => {
    try {
        const encoder = new TextEncoder();
        const data = encoder.encode(password);
        const publicKey = await getPublicRSAKey(purpose);

        if (!publicKey) {
            return null;
        }

        const encrypted = await window.crypto.subtle.encrypt(
            {
                name: 'RSA-OAEP',
            },
            publicKey,
            data,
        );

        const encryptedArray = Array.from(new Uint8Array(encrypted));
        return window.btoa(String.fromCharCode.apply(null, encryptedArray));
    } catch (err) {
        return null;
    }
};


export const CryptoUtils = {
    encryptAES,
    decryptAES,
    encryptRSA,
};
