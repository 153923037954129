import {
  PRINT_LABEL_SCAN_CLICK,
  PRINT_LABEL_UPLOAD_CLICK,
  CN_VERIFICATION_UPLOAD_CLICK,
  SET_GENERIC_SCAN_SELECT_MODAL_VISIBLE,
  SET_GENERIC_BULK_UPLOAD_MODAL_VISIBLE,
  SET_ACTION_TYPE,
  CREATE_ROUTE_ALL_CLICK,
  CREATE_ROUTE_FILTERED_CLICK,
  DOWNLOAD_CHILD_CN_CLICK,
  CUSTOM_TRIP_CLICK,
  CUSTOM_TRIP_UPLOAD_CLICK,
  CUSTOM_BULK_TRIP_UPLOAD_CLICK,
  CLOSE_ALL_MODALS,
  SET_ACTION_CONFIG,
  SWITCH_MODAL_VISIBILITY,
} from './constants';

const modalVisibility = {
  bulkUploadModal: false,
  genericBulkUploadModal: false,
  genericScanSelectModal: false,
  inventoryInscanModal: false,
  qcCheckModal: false,
  cnOutscanModal: false,
  createRouteModal: false,
  bulkCreateRouteModal: false,
  bulkTripModal: false,
  downloadsModal: false,
  moveCNModal: false,
  bulkDeleteCNModal: false,
  customTripModal: false,
  customColumnModal: false,
};

export const defaultState: any = {
  modalState: modalVisibility,
  cnVerificationViaExcel: false,
  inscanAction: undefined,
  bulkLabelType: undefined,
  actionType: undefined,
  createRouteForAll: false,
  createRouteFiltered: false,
  downloadHus: false,
  tripCreationType: undefined,
  actionStatus: undefined,
  currentActionConfig: {},
};

export const CustomActionsBarReducer = (state: any = defaultState, action) => {
  switch (action.type) {
    case SWITCH_MODAL_VISIBILITY: {
      return {
        ...state,
        modalState: {
          ...state.modalState,
          [action.data.modal]: action.data.value,
        },
      };
    }
    case PRINT_LABEL_SCAN_CLICK: {
      return {
        ...state,
        modalState: {
          ...state.modalState,
          genericScanSelectModal: true,
        },
        bulkLabelType: action.data,
      };
    }
    case PRINT_LABEL_UPLOAD_CLICK: {
      return {
        ...state,
        modalState: {
          ...state.modalState,
          genericBulkUploadModal: true,
        },
        bulkLabelType: action.data,
      };
    }
    case CN_VERIFICATION_UPLOAD_CLICK: {
      return {
        ...state,
        modalState: {
          ...state.modalState,
          bulkUploadModal: true,
        },
        cnVerificationViaExcel: true,
      };
    }
    case SET_GENERIC_SCAN_SELECT_MODAL_VISIBLE: {
      return {
        ...state,
        modalState: {
          ...state.modalState,
          genericScanSelectModal: true,
        },
        actionStatus: action.data,
      };
    }
    case SET_GENERIC_BULK_UPLOAD_MODAL_VISIBLE: {
      return {
        ...state,
        modalState: {
          ...state.modalState,
          genericBulkUploadModal: true,
        },
        actionStatus: action.data,
      };
    }
    case SET_ACTION_TYPE: {
      return {
        ...state,
        actionType: action.data,
      };
    }
    case CREATE_ROUTE_ALL_CLICK: {
      return {
        ...state,
        modalState: {
          ...state.modalState,
          createRouteModal: true,
        },
        createRouteForAll: true,
      };
    }
    case CREATE_ROUTE_FILTERED_CLICK: {
      return {
        ...state,
        modalState: {
          ...state.modalState,
          createRouteModal: true,
        },
        createRouteForAll: true,
        createRouteFiltered: true,
      };
    }
    case DOWNLOAD_CHILD_CN_CLICK: {
      return {
        ...state,
        modalState: {
          ...state.modalState,
          downloadsModal: true,
        },
        downloadHus: true,
      };
    }
    case CUSTOM_TRIP_CLICK: {
      return {
        ...state,
        modalState: {
          ...state.modalState,
          customTripModal: true,
        },
        tripCreationType: action.data,
      };
    }
    case CUSTOM_TRIP_UPLOAD_CLICK: {
      return {
        ...state,
        modalState: {
          ...state.modalState,
          bulkCreateRouteModal: true,
        },
        tripCreationType: action.data,
      };
    }
    case CUSTOM_BULK_TRIP_UPLOAD_CLICK: {
      return {
        ...state,
        modalState: {
          ...state.modalState,
          bulkTripModal: true,
        },
        tripCreationType: action.data,
      };
    }
    case CLOSE_ALL_MODALS:
      return defaultState;
    case SET_ACTION_CONFIG: {
      return {
        ...state,
        currentActionConfig: action.data,
      };
    }
    default:
      return state;
  }
};
