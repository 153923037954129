import Icon from '@ant-design/icons';
import * as React from 'react';

const PasswordConstructionSvg = (props) => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Password_Construction</title>
      <g
        id="Password_Construction"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
        stroke-linecap="round"
      >
        <g
          id="Group-13"
          transform="translate(4.000000, 3.000000)"
          stroke="#424242"
          stroke-width="1.5"
        >
          <path
            d="M15,16.3636364 C15,17.2636364 14.25,18 13.3333333,18 L1.66666667,18 C0.75,18 0,17.2636364 0,16.3636364 L0,8.18181818 C0,7.28181818 0.75,6.54545455 1.66666667,6.54545455 L13.3333333,6.54545455 C14.25,6.54545455 15,7.28181818 15,8.18181818 L15,16.3636364 Z"
            id="Stroke-1"
            stroke-linejoin="round"
          ></path>
          <path
            d="M8.33333333,10.6363636 C8.33333333,11.0888182 7.96083333,11.4545455 7.5,11.4545455 C7.03916667,11.4545455 6.66666667,11.0888182 6.66666667,10.6363636 C6.66666667,10.1847273 7.03916667,9.81818182 7.5,9.81818182 C7.96083333,9.81818182 8.33333333,10.1847273 8.33333333,10.6363636 Z"
            id="Stroke-3"
          ></path>
          <line
            x1="7.5"
            y1="11.4545455"
            x2="7.5"
            y2="13.9090909"
            id="Stroke-5"
          ></line>
          <line
            x1="11.6666667"
            y1="4.09090909"
            x2="11.6666667"
            y2="6.21327273"
            id="Stroke-7"
            stroke-linejoin="round"
          ></line>
          <line
            x1="3.33333333"
            y1="6.42878182"
            x2="3.33333333"
            y2="4.09123636"
            id="Stroke-9"
            stroke-linejoin="round"
          ></line>
          <path
            d="M3.33333333,4.09090909 C3.33333333,1.83354545 5.19833333,0 7.5,0 C9.80333333,0 11.6666667,1.83354545 11.6666667,4.09090909"
            id="Stroke-11"
            stroke-linejoin="round"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const PasswordConstruction = (props) => (
  <Icon component={PasswordConstructionSvg} {...props} />
);
