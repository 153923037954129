import { SET_ACTION_REASON, GET_ACTION_REASON } from '../actions/constants';

const cachedReasons: { [key: string]: any } = {};

const defaultState = {};

const reasonsDataReducer = function (state:any = defaultState, action: any) {
    switch (action.type) {
        case SET_ACTION_REASON: {
            const { actionReason, data } = action;
            cachedReasons[actionReason] = data;
            return {
                ...state,
                [actionReason]: data,
            };
        }
        case GET_ACTION_REASON: {
            const { actionReason } = action;
            return {
                ...state,
                [actionReason]: cachedReasons[actionReason],
            };
        }
        default:
            return state;
    }
};

export default reasonsDataReducer;