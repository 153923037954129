import * as React from 'react';
import withStyles, { WithStylesProps } from 'react-jss';
import { connect } from 'react-redux';
import styles from './TripMapEditUnplannedTaskFilterBar.style';
import { bindActionCreators } from 'redux';
import FilterBar from 'src/components/pages/GenericConsignment/CustomFilters/FilterBar';
import {
  MAP_EDIT_VIEW_TYPE,
  MAP_EDIT_BUCKETS,
} from '../../TripManagerMapEditUtils';
import CustomActionBar from 'src/components/pages/GenericConsignment/CustomActionBar/CustomActionBar';
import {
  reloadBucket,
  setSelectedRowKeys,
} from 'src/actions/genericConsignmentActions';
import { SwitchMapListView } from 'src/components/common/SwitchMapListView';
import {
  resetMapData,
  switchMapView,
} from '../../StateManagement/TripManagerMapEdit.actions';
import { useTripManagerMapEditContext } from '../../StateManagement/TripManagerMapEdit.context';
import { Modal } from 'antd';

interface IProps extends WithStylesProps<typeof styles> {
  bucket: string;
  currHub: any;
  reload: any;
  setSelectedRowKeys: any;
  allTrips: any;
  allUnplannedCNs: any;
}

const TripMapEditUnplannedTaskFilterBar: React.FC<IProps> = function (props) {
  const {
    classes,
    currHub,
    bucket,
    setSelectedRowKeys,
    reload,
    allTrips,
    allUnplannedCNs,
  } = props;
  const { tripManagerMapEditState, tripManagerMapEditDispatch } =
    useTripManagerMapEditContext();
  const { isMapView, editActions } = tripManagerMapEditState;

  const setIsMapView = (flag) => {
    tripManagerMapEditDispatch(switchMapView(flag));
  };

  const handleModalClose = (isSuccess) => {
    setSelectedRowKeys([]);
    if (isSuccess) {
      reload();
    }
  };

  const handleCustomColumnsModalClose = (isSuccess) => {
    if (isSuccess) {
      reload();
    }
  };

  const onReloadClick = () => {
    if (editActions && editActions.length) {
      Modal.confirm({
        title: 'All changes will be lost if you reload',
        okText: 'Ok',
        cancelText: 'Cancel',
        width: 500,
        onOk: () => {
          resetMapData(tripManagerMapEditDispatch, {
            allTrips,
            allUnplannedCNs,
          });
          reload();
        },
      });
    } else {
      resetMapData(tripManagerMapEditDispatch, {
        allTrips,
        allUnplannedCNs,
      });
      reload();
    }
  };

  return (
    <div className={classes.containerDefaultFilter}>
      <FilterBar
        viewType={MAP_EDIT_VIEW_TYPE}
        bucket={bucket}
        hubId={currHub.id}
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <SwitchMapListView isMapView={isMapView} onChange={setIsMapView} />
        <CustomActionBar
          style={{ width: 'unset' }}
          viewType={MAP_EDIT_VIEW_TYPE}
          bucket={bucket}
          handleModalClose={handleModalClose}
          handleCustomColumnsModalClose={handleCustomColumnsModalClose}
          onReloadClick={onReloadClick}
          showFiltersAndActions={true}
          hideActions={true}
        />
      </div>
    </div>
  );
};

const TripMapEditUnplannedTaskFilterBarStyled = withStyles(styles, {
  injectTheme: true,
})(TripMapEditUnplannedTaskFilterBar);

const mapStateToProps = (
  { cachedData, genericConsignmentReducer },
  ownProps,
) => {
  const viewType = MAP_EDIT_VIEW_TYPE;
  const { bucket } = ownProps;
  const currentViewReducer = genericConsignmentReducer[viewType];
  return {
    currHub: cachedData.currHub,
    allUnplannedCNs: currentViewReducer.buckets[bucket].data,
    allTrips: currentViewReducer.buckets[MAP_EDIT_BUCKETS.MAP_TRIPS].data,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const viewType = MAP_EDIT_VIEW_TYPE;
  const { bucket } = ownProps;
  return bindActionCreators(
    {
      reload: reloadBucket(viewType)(bucket),
      setSelectedRowKeys: setSelectedRowKeys(viewType)(bucket),
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TripMapEditUnplannedTaskFilterBarStyled);
