import { SET_UI_THEME } from '../actions/constants';

const defaultTheme = require('../theming/defaultTheme.json');
const themeToSet = defaultTheme;

const uiThemeReducer = function (state = themeToSet, action: { type: any; data: any; }) {
  switch (action.type) {
    case SET_UI_THEME: {
      return {
        ...state,
        ...action.data,
      };
    }
    default:
      return state;
  }
};

export default uiThemeReducer;