import Icon from '@ant-design/icons';
import * as React from 'react';
const SettingSvg = (props: any) => (
  // tslint:disable-next-line:max-line-length
  <svg width="14px" height="14px" viewBox="0 0 21 21">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="white" fillRule="evenodd">
      <g id="Trip-Manager" transform="translate(-1283.000000, -275.000000)"
         fill="white" fill-rule="nonzero" stroke="white" stroke-width="0.5">
          <g id="Group-4-Copy" transform="translate(1284.000000, 220.000000)">
              <g id="tracking" transform="translate(0.000000, 56.000000)">
                  <path d="M9.84897959,19.644898 C9.62355724,19.644898 9.44081633,19.462157 9.44081633,19.2367347 L9.44081633,17.3591837 C9.44081633,17.1337613 9.62355724,16.9510204 9.84897959,16.9510204 C10.0744019,16.9510204 10.2571429,17.1337613 10.2571429,17.3591837 L10.2571429,19.2367347 C10.2571429,19.462157 10.0744019,19.644898 9.84897959,19.644898 Z" id="Path"></path>
                  <path d="M9.84897959,2.75510204 C9.62355724,2.75510204 9.44081633,2.57236112 9.44081633,2.34693878 L9.44081633,0.46122449 C9.44081633,0.235802143 9.62355724,0.0530612245 9.84897959,0.0530612245 C10.0744019,0.0530612245 10.2571429,0.235802143 10.2571429,0.46122449 L10.2571429,2.34693878 C10.2571429,2.57236112 10.0744019,2.75510204 9.84897959,2.75510204 Z" id="Path"></path>
                  <path d="M19.2367347,10.2571429 L17.3591837,10.2571429 C17.1337613,10.2571429 16.9510204,10.0744019 16.9510204,9.84897959 C16.9510204,9.62355724 17.1337613,9.44081633 17.3591837,9.44081633 L19.2367347,9.44081633 C19.462157,9.44081633 19.644898,9.62355724 19.644898,9.84897959 C19.644898,10.0744019 19.462157,10.2571429 19.2367347,10.2571429 Z" id="Path"></path>
                  <path d="M2.34693878,10.2571429 L0.46122449,10.2571429 C0.235802143,10.2571429 0.0530612245,10.0744019 0.0530612245,9.84897959 C0.0530612245,9.62355724 0.235802143,9.44081633 0.46122449,9.44081633 L2.34693878,9.44081633 C2.57236112,9.44081633 2.75510204,9.62355724 2.75510204,9.84897959 C2.75510204,10.0744019 2.57236112,10.2571429 2.34693878,10.2571429 Z" id="Path"></path>
                  <path d="M9.84897959,17.6040816 C6.71233361,17.6040816 3.88454199,15.7146117 2.68419954,12.8167287 C1.48385709,9.91884564 2.14735071,6.58323799 4.36529435,4.36529435 C6.58323799,2.14735071 9.91884564,1.48385709 12.8167287,2.68419954 C15.7146117,3.88454199 17.6040816,6.71233361 17.6040816,9.84897959 C17.6040816,14.1320042 14.1320042,17.6040816 9.84897959,17.6040816 L9.84897959,17.6040816 Z M9.84897959,2.91020408 C6.0167997,2.91020408 2.91020408,6.0167997 2.91020408,9.84897959 C2.91020408,13.6811595 6.0167997,16.7877551 9.84897959,16.7877551 C13.6811595,16.7877551 16.7877551,13.6811595 16.7877551,9.84897959 C16.7877551,6.0167997 13.6811595,2.91020408 9.84897959,2.91020408 Z" id="Shape"></path>
                  <path d="M9.84897959,13.9306122 C7.59475612,13.9306122 5.76734694,12.1032031 5.76734694,9.84897959 C5.76734694,7.59475612 7.59475612,5.76734694 9.84897959,5.76734694 C12.1032031,5.76734694 13.9306122,7.59475612 13.9306122,9.84897959 C13.9306122,12.1032031 12.1032031,13.9306122 9.84897959,13.9306122 L9.84897959,13.9306122 Z M9.84897959,6.58367347 C8.04560082,6.58367347 6.58367347,8.04560082 6.58367347,9.84897959 C6.58367347,11.6523584 8.04560082,13.1142857 9.84897959,13.1142857 C11.6523584,13.1142857 13.1142857,11.6523584 13.1142857,9.84897959 C13.1142857,8.98296656 12.7702634,8.15242317 12.1578997,7.54005949 C11.545536,6.92769581 10.7149926,6.58367347 9.84897959,6.58367347 Z" id="Shape"></path>
              </g>
          </g>
        </g>
    </g>
  </svg>
);

export const TrackingIcon = props => (
   <Icon component={SettingSvg} {...props}/>
);
