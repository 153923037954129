import React from 'react';
import { createUseStyles } from 'react-jss';
import { ClockCircleOutlined, ClockCircleFilled } from '@ant-design/icons';
import { Popover } from 'antd';
import { DB_TYPES } from 'src/components/common/utils/constant';

const useStyle = createUseStyles({
  popOver: {
    '& .ant-popover-content': {
      '& .ant-popover-inner': {
        borderRadius: '5px !important',
        '& .ant-popover-inner-content': {
          padding: '8px !important',
        },
      },
    },
  },
  reportTitle: {
    lineHeight: 'normal',
    paddingLeft: '0.625rem',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    display: 'inline-block',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 400,
  },
  base: {
    padding: '0.3rem 0.3rem 0rem 0.3rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    color: '#262626',
    fontWeight: '400',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  popOverContent: {
    backgroundColor: 'black',
  },
  popOverHeading: {
    color: 'grey',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    cursor: 'auto',
    '&:hover': {
      textDecoration: 'none',
      color: 'grey',
      cursor: 'auto',
    },
  },
  popOverValue: {
    color: 'white',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    cursor: 'auto',
    '&:hover': {
      textDecoration: 'none',
      color: 'white',
      cursor: 'auto',
    },
  },
  iconStyle: {
    cursor: 'pointer',
    paddingLeft: '3px',
  },
  iconStyleFilled: {
    cursor: 'pointer',
    paddingLeft: '3px',
    color: 'rgb(154, 153, 153)',
  },
});

const ChartContainerTopBar = (props) => {
  const { reportName, lastRefreshTime, dbType } = props;
  const classes = useStyle();
  const LastRefreshTimeIcon = () => {
    const handleContentClick = (e) => {
      e.stopPropagation(); // Stop the click event from propagating to the underlying elements
    };
    if (lastRefreshTime) {
      const heading = 'Last Data Refresh: ';
      const content = (
        <div onClick={handleContentClick} className={classes.popOverContent}>
          <a className={classes.popOverHeading}>{heading}</a>
          <a className={classes.popOverValue}>{lastRefreshTime}</a>
        </div>
      );
      return (
        <Popover
          color="black"
          overlayClassName={classes.popOver}
          content={content}
          placement="right"
        >
          {dbType === DB_TYPES.POSTGRESQL ? (
            <ClockCircleFilled className={classes.iconStyleFilled} />
          ) : (
            <ClockCircleOutlined className={classes.iconStyle} />
          )}
        </Popover>
      );
    }
  };
  return (
    <div className={classes.base}>
      <div className={classes.title}>
        <div className={classes.reportTitle}>
          {reportName} {lastRefreshTime && <LastRefreshTimeIcon />}
        </div>
      </div>
    </div>
  );
};

export default ChartContainerTopBar;
