import { API_BASE_URL } from '../globals';
import { INTEGRATION_MARKETPLACE_FETCH_MASTER_DATA, INTEGRATION_MARKETPLACE } from './routes';
import { setupNotificationRequestSchema } from '../model/notifications';
import { POST, GET } from './apiHandler';
import morphism from 'morphism';

export const getMasterData = async (params = {}) =>
  GET(`${API_BASE_URL}${INTEGRATION_MARKETPLACE_FETCH_MASTER_DATA}`, params);

type Action = 'FETCH' | 'ADD' | 'EDIT' | 'DELETE' | 'VALIDATE' | 'SEARCH';
export const executeIntegrationAction = async (baseUrl: string, integrationId: string, action: Action, body={}) =>
  POST(`${baseUrl}/api${INTEGRATION_MARKETPLACE}/${integrationId}/${action}`, body);
