import React, { useState } from 'react';
import { Button, Radio, Tooltip } from 'antd';
import { ListNavIcon } from './icons/opsDashboard/ListNavIcon';
import { MapNavIcon } from './icons/opsDashboard/MapNavIcon';

export const SwitchMapListView = (props) => {
  const isMapView = props.isMapView;

  const optionsList = [
    { label: 'map', value: true },
    { label: 'list', value: false },
  ];
  const iconStyle = { fontSize: '20px', verticalAlign: 'middle' };

  const getIconComponent = (view) => {
    switch (view) {
      case 'map':
        return (
          <Tooltip placement="bottom" title={'Switch to Map View'}>
            <MapNavIcon style={iconStyle} />
          </Tooltip>
        );
      case 'list':
        return (
          <Tooltip placement="bottom" title={'Switch to List View'}>
            <ListNavIcon style={iconStyle} />
          </Tooltip>
        );
      default:
        return null;
    }
  };
  const handleOnClick = (value) => {
    // if same option is being clicked
    if (value === isMapView) {
      return;
    }
    if (props.onChange) {
      props.onChange(value);
    }
  };

  return (
    <div style={{ marginLeft: '8px' }}>
      <Button.Group>
        {optionsList.map((option) => (
          <Button
            onClick={() => handleOnClick(option.value)}
            type={option.value === isMapView ? 'primary' : 'default'}
            style={{ display: 'flex', alignItems: 'center' }}
            key={option.value.toString()}
          >
            {getIconComponent(option.label)}
          </Button>
        ))}
      </Button.Group>
    </div>
  );
};
