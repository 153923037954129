import * as React from 'react';
import PersistentIframeController from '../../common/persistentIframeRenderer/PersistentIframeController';
import { IframeApps } from '../../common/persistentIframeRenderer/constants';
import Navbar from '../../navbar/Navbar';
import DocumentTitle from 'react-document-title';
import { CssType, ThemeType } from '../../../theming/jssTypes';
import Button from 'antd/lib/button';
import withStyles from 'react-jss';
import { useHistory } from 'react-router-dom';
import { TrackingIcon } from 'src/components/common/icons/TrackingIcon';

const styles = (theme: ThemeType): CssType => ({
  mainDiv: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '& .ant-btn > span': {
      width: '108px',
    },
  },
});

const GenericOpsV1Page = (props) => {
  const {
    classes,
    title,
    route,
    showSwitchView,
    switchToRoute,
    switchViewText,
    showTrackingOption,
  } = props;
  const history = useHistory();
  const switchViewButton = (
    <Button onClick={() => history.push(switchToRoute)}>
      {switchViewText}
    </Button>
  );
  return (
    <div className={classes.mainDiv}>
      <DocumentTitle title={title} />
      <Navbar
        pageTitle={title}
        leftComponents={showSwitchView ? [switchViewButton] : []}
        showTrackOption={showTrackingOption}
      />

      <PersistentIframeController
        route={route}
        appName={IframeApps.OpsDashboard}
      />
    </div>
  );
};

export default withStyles(styles, { injectTheme: true })(GenericOpsV1Page);
