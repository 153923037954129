import * as React from 'react';
import { Tag } from 'antd';

const tagStyle = { marginTop: '4px' };

export const DefaultTag = () => {
  return (
    <Tag color="blue" style={tagStyle}>
      Default
    </Tag>
  );
};

export const EmptyTag = () => {
  return (
    <Tag color="volcano" style={tagStyle}>
      Empty
    </Tag>
  );
};

export const SimpleTag = ({ label }) => {
  return <Tag style={tagStyle}>{label}</Tag>;
};

export const OrgAliases = ({ tags }) => {
  return tags.map((tag, index) => {
    switch (tag) {
      case '':
        return <EmptyTag key={index} />;
      case null:
        return <DefaultTag key={index} />;
      default:
        return <SimpleTag label={tag} key={index} />;
    }
  });
};

export const PrettyOption = ({ label, description, labelFontSize = '12px', descriptionFontSize= '12px' }) => {
  return (
    <>
      <div style={{
        fontSize: labelFontSize,
      }}>{label}</div>
      <div
        style={{
          fontStyle: 'italic',
          fontSize: descriptionFontSize,
          color: 'gray',
          whiteSpace: 'initial',
        }}
      >
        {description}
      </div>
    </>
  );
};
