import Icon from '@ant-design/icons';
import * as React from 'react';

const SingleSignOnSvg = (props) => {
  return (
    <svg version="1.1" width="20px" height="20px" viewBox="0 0 16.0 16.0" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="i0">
          <path d="M1366,0 L1366,777 L0,777 L0,0 L1366,0 Z">
          </path>
        </clipPath>
        <clipPath id="i1">
          <path d="M8,0 C12.41875,0 16,3.58125 16,8 C16,12.41875 12.41875,16 8,16 C3.58125,16 0,12.41875 0,8 C0,3.58125 3.58125,0 8,0 Z M8,1.125 C7.071875,1.125 6.171875,1.30625 5.3234375,1.665625 C4.5046875,2.0109375 3.76875,2.5078125 3.1375,3.1390625 C2.50625,3.771875 2.0109375,4.50625 1.6640625,5.325 C1.30625,6.171875 1.125,7.071875 1.125,8 C1.125,8.928125 1.30625,9.828125 1.665625,10.6765625 C2.0109375,11.4953125 2.5078125,12.23125 3.1390625,12.8625 C3.771875,13.49375 4.50625,13.9890625 5.325,14.3359375 C6.171875,14.69375 7.071875,14.875 8,14.875 C8.928125,14.875 9.828125,14.69375 10.675,14.334375 C11.49375,13.9890625 12.2296875,13.4921875 12.8609375,12.8609375 C13.49375,12.2296875 13.9890625,11.49375 14.3359375,10.675 C14.69375,9.828125 14.875,8.928125 14.875,8 C14.875,7.071875 14.69375,6.171875 14.334375,5.3234375 C13.9890625,4.5046875 13.4921875,3.76875 12.8609375,3.1375 C12.228125,2.50625 11.49375,2.0109375 10.675,1.6640625 C9.828125,1.30625 8.928125,1.125 8,1.125 Z M12.4453125,7.4375 C12.7251562,7.4375 12.9569062,7.64126562 13.0004578,7.90869219 L13.0078125,8 C13.0078125,8.3109375 12.75625,8.5625 12.4453125,8.5625 L3.5546875,8.5625 C3.27484375,8.5625 3.04309375,8.35873437 2.99954219,8.09130781 L2.9921875,8 C2.9921875,7.6890625 3.24375,7.4375 3.5546875,7.4375 L12.4453125,7.4375 Z">
          </path>
        </clipPath>
      </defs>
      <g transform="translate(-68.0 -266.0)">
        <g clip-path="url(#i0)">
          <g transform="translate(68.0 282.0000000000005) rotate(-90.0)">
            <g clip-path="url(#i1)">
              <polygon points="2.27373675e-13,0 16,0 16,16 2.27373675e-13,16 2.27373675e-13,0" stroke="none" fill="#333333">
              </polygon>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const SingleSignOn = (props) => (
  <Icon component={SingleSignOnSvg} style={{ marginLeft: '2px' }} {...props} />
);
