import Icon from '@ant-design/icons';
import * as React from 'react';

const SetupSvg = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Menu" transform="translate(-24.000000, -583.000000)">
                <g id="Setup-Copy" transform="translate(24.000000, 582.999950)">
                    <rect id="Rectangle" x="0" y="0" width="24" height="23.9993727"></rect>
                    <g id="Setup" transform="translate(1.200000, 1.200050)" fill="#666666">
                        <path d="M2.61812,11.99999 C3.05994,11.99999 3.41812,12.35819 3.41812,12.79999 L3.41812,20.79999 C3.41812,21.24179 3.05994,21.59999 2.61812,21.59999 C2.17629,21.59999 1.81812,21.24179 1.81812,20.79999 L1.81812,12.79999 C1.81812,12.35819 2.17629,11.99999 2.61812,11.99999 Z" id="Path"></path>
                        <path d="M2.61812,8.8817842e-16 C3.05994,8.8817842e-16 3.41812,0.35817 3.41812,0.8 L3.41812,8.79999 C3.41812,9.24179 3.05994,9.59999 2.61812,9.59999 C2.17629,9.59999 1.81812,9.24179 1.81812,8.79999 L1.81812,0.8 C1.81812,0.35817 2.17629,8.8817842e-16 2.61812,8.8817842e-16 Z" id="Path"></path>
                        <path d="M18.98185,8 C19.42375,8 19.78185,8.35817 19.78185,8.79999 L19.78185,20.79999 C19.78185,21.24179 19.42375,21.59999 18.98185,21.59999 C18.54005,21.59999 18.18185,21.24179 18.18185,20.79999 L18.18185,8.79999 C18.18185,8.35817 18.54005,8 18.98185,8 Z" id="Path"></path>
                        <path d="M18.98185,1.33226763e-15 C19.42375,1.33226763e-15 19.78185,0.35817 19.78185,0.8 L19.78185,4.43637 C19.78185,4.87819 19.42375,5.23637 18.98185,5.23637 C18.54005,5.23637 18.18185,4.87819 18.18185,4.43637 L18.18185,0.8 C18.18185,0.35817 18.54005,1.33226763e-15 18.98185,1.33226763e-15 Z" id="Path"></path>
                        <path d="M10.80005,16.90909 C11.24185,16.90909 11.60005,17.26729 11.60005,17.70909 L11.60005,20.79999 C11.60005,21.24179 11.24185,21.59999 10.80005,21.59999 C10.35815,21.59999 10.00005,21.24179 10.00005,20.79999 L10.00005,17.70909 C10.00005,17.26729 10.35815,16.90909 10.80005,16.90909 Z" id="Path"></path>
                        <path d="M10.80005,8.8817842e-16 C11.24185,8.8817842e-16 11.60005,0.35817 11.60005,0.8 L11.60005,12.79999 C11.60005,13.24179 11.24185,13.59999 10.80005,13.59999 C10.35815,13.59999 10.00005,13.24179 10.00005,12.79999 L10.00005,0.8 C10.00005,0.35817 10.35815,8.8817842e-16 10.80005,8.8817842e-16 Z" id="Path"></path>
                        <path d="M18.98175,5.23635 C18.41935,5.23635 17.96355,5.69221 17.96355,6.25454 C17.96355,6.81686 18.41935,7.27272 18.98175,7.27272 C19.54405,7.27272 19.99985,6.81686 19.99985,6.25454 C19.99985,5.69221 19.54405,5.23635 18.98175,5.23635 Z M16.36355,6.25454 C16.36355,4.80855 17.53575,3.63635 18.98175,3.63635 C20.42765,3.63635 21.59985,4.80855 21.59985,6.25454 C21.59985,7.70052 20.42765,8.87269 18.98175,8.87269 C17.53575,8.87269 16.36355,7.70052 16.36355,6.25454 Z" id="Shape"></path>
                        <path d="M10.80005,14.32729 C10.23775,14.32729 9.78185,14.78309 9.78185,15.34549 C9.78185,15.90779 10.23775,16.36359 10.80005,16.36359 C11.36235,16.36359 11.81825,15.90779 11.81825,15.34549 C11.81825,14.78309 11.36235,14.32729 10.80005,14.32729 Z M8.18189,15.34549 C8.18189,13.89949 9.35405,12.72729 10.80005,12.72729 C12.24605,12.72729 13.41825,13.89949 13.41825,15.34549 C13.41825,16.79139 12.24605,17.96359 10.80005,17.96359 C9.35405,17.96359 8.18189,16.79139 8.18189,15.34549 Z" id="Shape"></path>
                        <path d="M2.61818,9.78179 C2.05586,9.78179 1.6,10.23769 1.6,10.79999 C1.6,11.36229 2.05586,11.81819 2.61818,11.81819 C3.18051,11.81819 3.63636,11.36229 3.63636,10.79999 C3.63636,10.23769 3.18051,9.78179 2.61818,9.78179 Z M4.4408921e-16,10.79999 C4.4408921e-16,9.35399 1.1722,8.18183 2.61818,8.18183 C4.06416,8.18183 5.23637,9.35399 5.23637,10.79999 C5.23637,12.24599 4.06416,13.41819 2.61818,13.41819 C1.1722,13.41819 4.4408921e-16,12.24599 4.4408921e-16,10.79999 Z" id="Shape"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    );
};

export const SetupIcon = props => (
  <Icon component={SetupSvg} {...props} />
);
