import apiProvider from '../provider';
import { getAuthenticationHeaders, getErrorMessage } from '../utils';
import { API_BASE_URL, BUSINESS_BOOKING_API_URL } from '../../globals';
import {
  TMS_UPLOAD_ROUTES_IN_SETUP,
  TMS_UPDATE_ROUTES_IN_SETUP,
  GET_ALL_TMS_DATA_SETUP,
  TMS_SETUP_SEARCH_ROUTES,
  TMS_SETUP_GET_ROUTE_DETAILS,
  GET_ALL_TMS_DATA_SETUP_CONNECTIONS,
  TMS_UPLOAD_CONNECTIONS_IN_SETUP,
  GET_ALL_TMS_DATA_SETUP_EXCEPTION_VEHICLES,
  GET_ALL_TMS_DATA_SETUP_EXCEPTION_BAGS,
  TMS_UPLOAD_VEHICLE_EXCEPTIONS_IN_SETUP,
  TMS_UPLOAD_BAG_EXCEPTIONS_IN_SETUP,
  TMS_UPDATE_VEHICLE_EXCEPTIONS_IN_SETUP,
  TMS_UPDATE_BAG_EXCEPTIONS_IN_SETUP,
  GET_ALL_TMS_DATA_SETUP_BAGGING,
  TMS_DOWNLOAD_SAMPLE_CSV_BAGGING_MASTER,
  TMS_UPLOAD_BAGGING_HUBS_IN_SETUP,
  TMS_DELETE_BAGGING_HUBS_IN_SETUP,
  TMS_DOWNLOAD_SAMPLE_CSV_CONNECTION_MASTER,
  TMS_DOWNLOAD_SAMPLE_CSV_GATE_MASTER,
  TMS_DOWNLOAD_SAMPLE_CSV_DOCK_MASTER,
  TMS_UPLOAD_GATES_IN_SETUP,
  TMS_UPLOAD_DOCKS_IN_SETUP,
  GET_ALL_TMS_DATA_SETUP_GATES,
  GET_ALL_TMS_DATA_SETUP_DOCKS,
  TMS_DOWNLOAD_DUMP_GATE_MASTER,
  TMS_DOWNLOAD_DUMP_DOCK_MASTER,
  TMS_DOWNLOAD_DUMP_BAGGING_MASTER,
  TMS_DOWNLOAD_CONNECTIONS_MASTER,
  TMS_DOWNLOAD_SAMPLE_CSV_ROUTE_MASTER,
  TMS_DOWNLOAD_SAMPLE_CSV_ROUTE_MASTER_ENABLE_DISABLE,
  UPLOAD_BULK_SETTLEMENT,
  DOWNLOAD_SAMPLE_BULK_SETTLEMENT,
  DOWNLOAD_BULK_SETTLEMENT,
  BULK_UPLOAD_VEHICLE,
  BULK_UPLOAD_VENDOR,
  GET_VEHICLE_CATEGORY_LIST,
  GET_VEHICLE_LIST,
  GET_VENDOR_LIST,
  GET_BAG_SERIES,
  ADD_BAG_SERIES,
  DELETE_BAG_SERIES,
  TMS_DOWNLOAD_SAMPLE_CSV_BAG_EXCEPTION_MASTER,
  GET_VEHICLE_DOCUMENTS,
  UPDATE_VEHICLE_DOCUMENT,
  ADD_VEHICLE_DOCUMENT,
  DOWNLOAD_VEHICLE_DOCUMENTS_CSV,
  GET_IMAGE_URL,
  CREATE_REASON_MULTIPLE,
  TMS_DOWNLOAD_SAMPLE_EWB_THRESHOLD_MASTER,
  FETCH_ALL_REASON,
  DOWNLOAD_ROUTE_MASTER,
} from '../routes';
import {
  requestModelTMSSetup,
  responseModelTMS,
  requestModelSetupException,
  requestModelTMSSetupBagging,
  requestModelTMSSetupGate,
  requestModelTMSSetupDock,
  requestModelTMSSetupVehicleDocuments,
  responseModelTMSSetupVehicleDocuments,
  responseModelEWBThresholdMaster,
} from '../../model/tmsView';
import { morphism } from 'morphism';
import { GET, POST } from '../apiHandler';
export const bulkUploadRoutes = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${TMS_UPLOAD_ROUTES_IN_SETUP}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    const data = response.data.data;
    return {
      data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSucces: false,
    };
  }
};
export const bulkUpdateRoutes = async (body) => {
  return POST(`${API_BASE_URL}${TMS_UPDATE_ROUTES_IN_SETUP}`, body);
};

export const getAllTmsDataSetup = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${GET_ALL_TMS_DATA_SETUP}`,
      { ...morphism(requestModelTMSSetup, body) },
      {
        headers: await getAuthenticationHeaders(),
      },
    );

    return {
      data: morphism(responseModelTMS, response.data.data),
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const tmsSetupSearchRoutes = async (queryString) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${TMS_SETUP_SEARCH_ROUTES}`,
      {
        params: {
          q: queryString,
        },
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const tmsSetupGetRouteDetails = async (routeId) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${TMS_SETUP_GET_ROUTE_DETAILS}`,
      {
        params: {
          route_id: routeId,
        },
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const getAllTmsDataSetupConnections = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${GET_ALL_TMS_DATA_SETUP_CONNECTIONS}`,
      { ...morphism(requestModelTMSSetup, body) },
      {
        headers: await getAuthenticationHeaders(),
      },
    );

    return {
      data: morphism(responseModelTMS, response.data.data),
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const bulkUploadConnections = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${TMS_UPLOAD_CONNECTIONS_IN_SETUP}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    const data = response.data.data;
    return {
      data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSucces: false,
    };
  }
};

export const getAllExceptionDataBags = async (body) => {
  try {
    const payload = { ...morphism(requestModelSetupException, body) };
    if(body.result_per_page){
      payload.result_per_page = body.result_per_page;
    }
    const response = await apiProvider.post(
      `${API_BASE_URL}${GET_ALL_TMS_DATA_SETUP_EXCEPTION_BAGS}`,
      payload,
      {
        headers: await getAuthenticationHeaders(),
      },
    );

    return {
      data: morphism(responseModelTMS, response.data.data),
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const getAllExceptionDataVehicles = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${GET_ALL_TMS_DATA_SETUP_EXCEPTION_VEHICLES}`,
      { ...morphism(requestModelSetupException, body) },
      {
        headers: await getAuthenticationHeaders(),
      },
    );

    return {
      data: morphism(responseModelTMS, response.data.data),
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const bulkUploadBagExceptions = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${TMS_UPLOAD_BAG_EXCEPTIONS_IN_SETUP}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    const data = response.data.data;
    return {
      data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSucces: false,
    };
  }
};
export const bulkUploadVehicleExceptions = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${TMS_UPLOAD_VEHICLE_EXCEPTIONS_IN_SETUP}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    const data = response.data.data;
    return {
      data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSucces: false,
    };
  }
};
export const bulkUpdateVehicleExceptions = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${TMS_UPDATE_VEHICLE_EXCEPTIONS_IN_SETUP}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    const data = response.data.data;
    return {
      data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSucces: false,
    };
  }
};
export const bulkUpdateBagExceptions = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${TMS_UPDATE_BAG_EXCEPTIONS_IN_SETUP}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    const data = response.data.data;
    return {
      data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSucces: false,
    };
  }
};
export const getBaggingMasterData = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${GET_ALL_TMS_DATA_SETUP_BAGGING}`,
      { ...morphism(requestModelTMSSetupBagging, body) },
      {
        headers: await getAuthenticationHeaders(),
      },
    );

    return {
      data: morphism(responseModelTMS, response.data.data),
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const downloadSampleCsvBaggingMaster = async (action) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${TMS_DOWNLOAD_SAMPLE_CSV_BAGGING_MASTER}`,
      {
        params: {
          action,
        },
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const bulkUploadBaggingHubs = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${TMS_UPLOAD_BAGGING_HUBS_IN_SETUP}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    const data = response.data.data;
    return {
      data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSucces: false,
    };
  }
};
export const bulkDeleteBaggingHubs = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${TMS_DELETE_BAGGING_HUBS_IN_SETUP}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    const data = response.data.data;
    return {
      data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSucces: false,
    };
  }
};
export const bulkUpdateBaggingHubs = async (body) => {
  body.action_type = 'update';
  return POST(`${API_BASE_URL}${TMS_UPLOAD_BAGGING_HUBS_IN_SETUP}`, body);
};
export const downloadSampleCsvConnectionMaster = async (action) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${TMS_DOWNLOAD_SAMPLE_CSV_CONNECTION_MASTER}`,
      {
        params: {
          action,
        },
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const downloadSampleCsvGateMaster = async () => {
  return GET(`${API_BASE_URL}${TMS_DOWNLOAD_SAMPLE_CSV_GATE_MASTER}`);
};
export const downloadDumpConnectionsMaster = async (body) => {
  return POST(`${API_BASE_URL}${TMS_DOWNLOAD_CONNECTIONS_MASTER}`, body);
};
export const downloadSampleCsvDockMaster = async () => {
  return GET(`${API_BASE_URL}${TMS_DOWNLOAD_SAMPLE_CSV_DOCK_MASTER}`);
};
export const bulkUploadGates = async (body) => {
  return POST(`${API_BASE_URL}${TMS_UPLOAD_GATES_IN_SETUP}`, body);
};
export const bulkUploadDocks = async (body) => {
  return POST(`${API_BASE_URL}${TMS_UPLOAD_DOCKS_IN_SETUP}`, body);
};
export const bulkUploadBulkSettlement = async (body) => {
  return POST(`${API_BASE_URL}${UPLOAD_BULK_SETTLEMENT}`, body);
};
export const bulkDownloadBulkSettlement = async (body) => {
  return POST(`${API_BASE_URL}${DOWNLOAD_BULK_SETTLEMENT}`, body);
};
export const downloadSampleBulkSettlement = async () => {
  return GET(`${API_BASE_URL}${DOWNLOAD_SAMPLE_BULK_SETTLEMENT}`);
};
export const getAllTmsDataSetupGates = async (body) => {
  const response = await POST(
    `${API_BASE_URL}${GET_ALL_TMS_DATA_SETUP_GATES}`,
    { ...morphism(requestModelTMSSetupGate, body) },
  );
  if (response.isSuccess) {
    return {
      data: morphism(responseModelTMS, response.data),
      isSuccess: true,
    };
  } else {
    return response;
  }
};
export const getAllTmsDataSetupDocks = async (body) => {
  const response = await POST(
    `${API_BASE_URL}${GET_ALL_TMS_DATA_SETUP_DOCKS}`,
    { ...morphism(requestModelTMSSetupDock, body) },
  );
  if (response.isSucces) {
    return {
      data: morphism(responseModelTMS, response.data),
      isSuccess: true,
    };
  } else {
    return response;
  }
};
export const downloadDumpGateMaster = async () => {
  return GET(`${API_BASE_URL}${TMS_DOWNLOAD_DUMP_GATE_MASTER}`);
};
export const downloadDumpDockMaster = async () => {
  return GET(`${API_BASE_URL}${TMS_DOWNLOAD_DUMP_DOCK_MASTER}`);
};
export const downloadDumpBaggingMaster = async (body) => {
  return POST(`${API_BASE_URL}${TMS_DOWNLOAD_DUMP_BAGGING_MASTER}`, body);
};
export const downloadRouteMaster = async (body) => {
  return POST(`${API_BASE_URL}${DOWNLOAD_ROUTE_MASTER}`, body);
};
export const downloadSampleCsvRouteMaster = async (params) => {
  return GET(`${API_BASE_URL}${TMS_DOWNLOAD_SAMPLE_CSV_ROUTE_MASTER}`, params);
};
export const downloadSampleCsvRouteMasterEnableDisable = async (params) => {
  return GET(`${API_BASE_URL}${TMS_DOWNLOAD_SAMPLE_CSV_ROUTE_MASTER_ENABLE_DISABLE}`, params);
};
export const bulkUploadVehicle = async (body) => {
  return POST(`${API_BASE_URL}${BULK_UPLOAD_VEHICLE}`, body);
};
export const bulkUploadVendor = async (body) => {
  return POST(`${API_BASE_URL}${BULK_UPLOAD_VENDOR}`, body);
};
export const getVehicleCategoryList = async () => {
  return GET(`${API_BASE_URL}${GET_VEHICLE_CATEGORY_LIST}`);
};

export const getVehicleList = async (params) => {
  return GET(`${API_BASE_URL}${GET_VEHICLE_LIST}`, params);
};

export const getVendorList = async () => {
  return GET(`${API_BASE_URL}${GET_VENDOR_LIST}`);
};

export const getBagSeries = async (params) => {
  return GET(`${BUSINESS_BOOKING_API_URL}${GET_BAG_SERIES}`, params);
};

export const addBagSeries = async (params) => {
  return POST(`${BUSINESS_BOOKING_API_URL}${ADD_BAG_SERIES}`, params);
};

export const deleteBagSeries = async (params) => {
  return POST(`${BUSINESS_BOOKING_API_URL}${DELETE_BAG_SERIES}`, params);
};

export const downloadSampleCsvBagExceptionMaster = async (params) => {
  return GET(
    `${API_BASE_URL}${TMS_DOWNLOAD_SAMPLE_CSV_BAG_EXCEPTION_MASTER}`,
    params,
  );
};

export const getAllTmsDataSetupVehicleDocuments = async (body) => {
  const response = await POST(`${API_BASE_URL}${GET_VEHICLE_DOCUMENTS}`, {
    ...morphism(requestModelTMSSetupVehicleDocuments, body),
  });
  if (response.isSucces) {
    return {
      data: morphism(responseModelTMSSetupVehicleDocuments, response.data),
      isSuccess: true,
    };
  } else {
    return response;
  }
};

export const addVehicleDocument = async (body) => {
  return POST(`${API_BASE_URL}${ADD_VEHICLE_DOCUMENT}`, body);
};

export const updateVehicleDocument = async (body) => {
  return POST(`${API_BASE_URL}${UPDATE_VEHICLE_DOCUMENT}`, body);
};

export const downloadCSVVechicleDump = async (params) => {
  return GET(`${API_BASE_URL}${DOWNLOAD_VEHICLE_DOCUMENTS_CSV}`, params);
};

export const getVehicleDocumentImageURL = async (body) => {
  return POST(`${API_BASE_URL}${GET_IMAGE_URL}`, body);
};

export const putImageS3 = async (url, file, content_type) => {
  try {
    const response = await apiProvider.put(url, file, {
      headers: {
        'Content-Type': content_type,
      },
    });
    return {
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const bulkUploadEWBThresholdMaster = async (body) => {
  return POST(`${API_BASE_URL}${CREATE_REASON_MULTIPLE}`, {
    ...body,
    type: 'eway_bill_threshold',
  });
};

export const downloadSampleCSVEWBThresholdMaster = async () => {
  return GET(`${API_BASE_URL}${TMS_DOWNLOAD_SAMPLE_EWB_THRESHOLD_MASTER}`);
};

export const fetchEWBThresholdMaster = async (params) => {
  const response = await GET(`${API_BASE_URL}${FETCH_ALL_REASON}`, {
    type: 'eway_bill_threshold',
  });
  if (response.isSucces) {
    return {
      ...response,
      data: morphism(responseModelEWBThresholdMaster, response?.data),
    };
  } else {
    return response;
  }
};
