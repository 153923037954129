export const PRINT_LABEL_SCAN_CLICK = 'PRINT_LABEL_SCAN_CLICK';
export const PRINT_LABEL_UPLOAD_CLICK = 'PRINT_LABEL_UPLOAD_CLICK';
export const CN_VERIFICATION_UPLOAD_CLICK = 'CN_VERIFICATION_UPLOAD_CLICK';
export const SET_GENERIC_SCAN_SELECT_MODAL_VISIBLE =
  'SET_GENERIC_SCAN_SELECT_MODAL_VISIBLE';
export const SET_GENERIC_BULK_UPLOAD_MODAL_VISIBLE =
  'SET_GENERIC_BULK_UPLOAD_MODAL_VISIBLE';
export const SET_ACTION_TYPE = 'SET_ACTION_TYPE';
export const CREATE_ROUTE_ALL_CLICK = 'CREATE_ROUTE_ALL_CLICK';
export const CREATE_ROUTE_FILTERED_CLICK = 'CREATE_ROUTE_FILTERED_CLICK';
export const DOWNLOAD_CHILD_CN_CLICK = 'DOWNLOAD_CHILD_CN_CLICK';
export const CUSTOM_TRIP_CLICK = 'CUSTOM_TRIP_CLICK';
export const CUSTOM_TRIP_UPLOAD_CLICK = 'CUSTOM_TRIP_UPLOAD_CLICK';
export const CUSTOM_BULK_TRIP_UPLOAD_CLICK = 'CUSTOM_BULK_TRIP_UPLOAD_CLICK';
export const CLOSE_ALL_MODALS = 'CLOSE_ALL_MODALS';
export const SET_ACTION_CONFIG = 'SET_ACTION_CONFIG';
export const SWITCH_MODAL_VISIBILITY = 'SWITCH_MODAL_VISIBILITY';
