import {
  IFRAME_SET_VISIBILITY,
  IFRAME_SET_DIMENSIONS,
  IFRAME_SET_LOADED,
  IFRAME_SET_RENDERED,
} from './constants';

export const setIframeVisibility = (appName, visibility) => {
  return {
    appName,
    visibility,
    type: IFRAME_SET_VISIBILITY,
  };
};

export const setIframeDimensions = (appName, dimensions) => {
  return {
    appName,
    dimensions,
    type: IFRAME_SET_DIMENSIONS,
  };
};

export const setIframeLoaded = (appName, isLoaded) => {
  console.log('inside loaded action');
  return {
    appName,
    isLoaded,
    type: IFRAME_SET_LOADED,
  };
};

export const setIframeRendered = (appName, isRendered) => {
  return {
    appName,
    isRendered,
    type: IFRAME_SET_RENDERED,
  };
};
