import * as React from 'react';
import { withRouter } from 'react-router';
import withStyles, {WithStylesProps} from 'react-jss';
import { connect } from 'react-redux';
import { message, Typography, Popconfirm, Modal, Button, Row, Col } from 'antd';
import { CssType, ThemeType } from '@theming/jssTypes';
import { getSoftdataAPIKey, reGenerateAPIKey } from '@src/api/customerManagement';
import Loader from '@components/common/Loader';
import CopyToClipboardWrapper from '@components/common/CopyToClipboard';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const styles = (theme: ThemeType): CssType => ({
  mainDiv: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: 'larger',
    marginTop: '20px',
    marginBottom: '20px',
  },
  subHeading: {
    fontSize: 'medium',
    marginBottom: '5px',
  },
  headingDiv: {
    backgroundColor: '#065492',
    color: 'white',
  },
});

interface IProps extends WithStylesProps<typeof styles> {
    [key: string]: any
}

const APIKey = (props: IProps) => {
    const { t } = useTranslation();
    const { classes, customerId, onClose } = props;
    const [key, setKey] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        fetchAPIKey();
    }, []);

    const fetchAPIKey = async() => {
        setLoading(true);
        const response = await getSoftdataAPIKey(customerId);
        if(response.isSuccess){
            const apiKey = response?.data?.apiKey || '';
            setKey(apiKey);
        }else{
            message.error(response.errorMessage);
        }
        setLoading(false);
    };

    const regenerateAPIKey = async () => {
        setLoading(true);
        const response = await reGenerateAPIKey(customerId);
        if(!response.isSuccess){
            message.error(response.errorMessage);
            setLoading(false);
        }else{
            await fetchAPIKey();
        }
    };

    return (
        <div>
            <Modal
                title={t('softdata_api_key_management')}
                visible={true}
                width={'35%'}
                footer={null}
                centered
                onCancel={onClose}
            >
            {
                loading ?
                <Loader zIndex={100} /> :
                <div className={classes.mainDiv}>
                    <Text strong className={classes.heading}>
                        <span style={{paddingRight:'20px'}}>{t('api_key')}</span>
                        <CopyToClipboardWrapper style={{ display: 'inline-block', marginLeft: '10px' }} text={key} />
                    </Text>
                    <Text>{key}</Text>

                    <Row justify="end">
                        <Col>
                            <Popconfirm
                                title={t('confirmation')}
                                onConfirm={regenerateAPIKey}
                                okText={t('Yes')}
                                cancelText={t('No')}
                            >
                                <Button type="link">{t('regenerate_api_key')}</Button>
                            </Popconfirm>
                        </Col>
                    </Row>
                </div>
            }
            </Modal>
        </div>
    );
};

const mapStateToProps = ({ masterData }) => {
    return {};
};

const APIKeyStyled = withStyles(styles, { injectTheme: true })(
    APIKey,
);

export default withRouter(
    connect(
        mapStateToProps,
    )(APIKeyStyled),
);
