import { getDownloadsRoute } from '../../routing/utils';
import { getAllConsignmentsData, getSummaryMetricsData,
  getSubBucketData } from '../../api/genericConsignmentView';
import { searchHubWithrealFilter } from '../../api/dashboard';
import { LocalStorageKeys, LocalStorageService } from 'src/services/localStorage';
const viewControl = {
  pageTitle: 'Orders Dashboard',
  referenceNumberList: 'Orders',
  polling:false,
  applyDateFilterSort: false,
  buckets : [
    'ondemand_all_orders',
  ],
  navBar : {
    left : ['single_hub_search', 'date_filter'],
    right : ['search_redirect'],
  },
  searchFilterOptions:[
    { key:'orderNumber', val: 'Order Number' },
  ],
  globalActions:[],
  dateFilterOptions : [
    { key:'booking_time', val: 'Business Date' },
    { key:'softdata_upload_time', val: 'Softdata Upload Time'},
  ],
  fetchData : getAllConsignmentsData,
  fetchSummaryMetrics : getSummaryMetricsData,
  fetchSubBucket: getSubBucketData,
  searchHubData : searchHubWithrealFilter(true, LocalStorageService.getRaw(LocalStorageKeys.__REDIRECT_TO_VIEW_TYPE__)),
  singleHubSearchText: 'Current Store',
  hubSearchPlaceholder: 'Select Store',
  searchText: 'Search for stores',
};
const buckets = {
  rider_reporting : {
  },
  order_reporting : {
  },
  rider_order_details : {
    name: 'Rider Service Level',
    type: 'table',
  },
  rider_exceptions : {
    name: 'Rider Exception Report',
    type: 'table',
    hideXScroll: true,
  },
  rider_score: {
    name: 'Rider Score Counts',
    type: 'table',
    hideXScroll: true,
  },
  rider_score_details : {
    name: 'Rider Score',
    type: 'table',
  },
  rider_dph : {
    name: 'Order Per Hour - Rider',
    type: 'table',
    hideXScroll: true,
  },
  store_dph : {
    name: 'Orders Per Hour - Store',
    type: 'table',
    hideXScroll: true,
  },
  allocation_details : {
    name: 'Single and Multi Run Order Table',
    type: 'table',
    hideXScroll: true,
  },
  reassign_details: {
    name: 'Order Reassignments Table',
    type: 'table',
    hideXScroll: true,
  },
  unresolved_orders : {
    name: 'Unresolved Orders',
    type: 'table',
    hideXScroll: true,
  },
  store_summary : {
    name: 'Store Service Level',
    type: 'table',
  },
  order_delivered_reachedgate_metrics : {
    name: 'Rider Punching Discipline',
    type: 'table',
  },
  store_delivered_reachedgate_metrics : {
    name: 'Rider Punching Discipline',
    type: 'table',
  },
  ondemand_all_orders : {
    name: 'All Orders',
    filters : [
      'day_part_filter',
      'time_slot',
      'non_regular_orders',
      // 'store_type',
      'employee_type',
      'order_status',
      'service_type',
    ],
    actions: [
      {
        key:'download',
        name:'Download',
        children : [
          {
            key:'downloads_all',
            name:'Download All',
            type:'modal',
            modal:'downloads_all',
            requireRows : false,
          },
          {
            key:'downloads_selected',
            name:'Download Selected',
            type:'modal',
            modal:'downloads_selected',
            requireRows : true,
          },
          {
            key:'view_request',
            name:'View Requests',
            type:'route',
            route: getDownloadsRoute,
          },
        ],
      },
    ],
    status_filter : [],
    iconActions: [],
    isSubBucket: false,
  },
};

const bucketColor = {
  ondemand_all_orders:'#C41990',
};

export default { viewControl, buckets, bucketColor };
