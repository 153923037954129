import Icon from '@ant-design/icons';
import * as React from 'react';

const BusinessIntelligenceSvg = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Menu" transform="translate(-24.000000, -247.000000)">
                <g id="Business_Intelligence" transform="translate(24.000000, 247.000000)">
                    <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                    <g transform="translate(1.500000, 1.500000)" fill="#666666" id="Path">
                        <path d="M10.5,0 C10.9445,0 11.3047,0.36028 11.3047,0.80471 L11.3047,2.74377 C11.3047,3.1882 10.9445,3.54848 10.5,3.54848 C10.0556,3.54848 9.6953,3.1882 9.6953,2.74377 L9.6953,0.80471 C9.6953,0.36028 10.0556,0 10.5,0 Z"></path>
                        <path d="M13.2491,15.6755 C13.6034,15.4073 14.1081,15.4771 14.3763,15.8315 L17.3114,19.7096 C17.5796,20.064 17.5097,20.5687 17.1553,20.8369 C16.801,21.1051 16.2963,21.0353 16.0281,20.6809 L13.093,16.8028 C12.8248,16.4484 12.8947,15.9437 13.2491,15.6755 Z"></path>
                        <path d="M7.75079,15.6755 C8.10517,15.9437 8.17504,16.4484 7.90683,16.8028 L4.97181,20.6809 C4.7036,21.0353 4.1989,21.1051 3.84452,20.8369 C3.49014,20.5687 3.42028,20.064 3.68848,19.7096 L6.62351,15.8315 C6.89171,15.4771 7.39641,15.4073 7.75079,15.6755 Z"></path>
                        <path d="M0.80471,2.64417 C1.24914,2.64417 1.60942,3.00445 1.60942,3.44887 L1.60942,14.9836 L19.3906,14.9836 L19.3906,3.44887 C19.3906,3.00445 19.7509,2.64417 20.1953,2.64417 C20.6397,2.64417 21,3.00445 21,3.44887 L21,15.7883 C21,16.2328 20.6397,16.593 20.1953,16.593 L0.80471,16.593 C0.36028,16.593 0,16.2328 0,15.7883 L0,3.44887 C0,3.00445 0.36028,2.64417 0.80471,2.64417 Z"></path>
                        <path d="M0,3.44887 C0,3.00445 0.36028,2.64417 0.80471,2.64417 L20.1953,2.64417 C20.6397,2.64417 21,3.00445 21,3.44887 C21,3.8933 20.6397,4.25358 20.1953,4.25358 L0.80471,4.25358 C0.36028,4.25358 0,3.8933 0,3.44887 Z"></path>
                        <path d="M17.4754,6.00352 C17.8169,6.28793 17.8632,6.79533 17.5788,7.13684 L12.881,12.7777 C12.6005,13.1145 12.1022,13.1649 11.76,12.8911 L7.91542,9.8155 L4.01777,13.7132 C3.70351,14.0274 3.194,14.0274 2.87974,13.7132 C2.56548,13.3989 2.56548,12.8894 2.87974,12.5751 L7.28669,8.16819 C7.57663,7.87825 8.03821,7.85268 8.3584,8.10883 L12.1492,11.1415 L16.3421,6.1069 C16.6265,5.76539 17.1339,5.7191 17.4754,6.00352 Z"></path>
                        <path d="M14.1023,6.11947 C14.1023,5.67504 14.4626,5.31476 14.907,5.31476 L17.5512,5.31476 C17.9956,5.31476 18.3559,5.67504 18.3559,6.11947 C18.3559,6.5639 17.9956,6.92418 17.5512,6.92418 L14.907,6.92418 C14.4626,6.92418 14.1023,6.5639 14.1023,6.11947 Z"></path>
                        <path d="M17.5513,5.31476 C17.9957,5.31476 18.356,5.67504 18.356,6.11947 L18.356,8.7108 C18.356,9.1552 17.9957,9.5155 17.5513,9.5155 C17.1069,9.5155 16.7466,9.1552 16.7466,8.7108 L16.7466,6.11947 C16.7466,5.67504 17.1069,5.31476 17.5513,5.31476 Z"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    );
};

export const BusinessIntelligenceIcon = props => (
  <Icon component={BusinessIntelligenceSvg} {...props} />
);
