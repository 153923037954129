export const retailConsignmentStatus = {
  reachedathub: 'Reached at hub',
  outfordelivery: 'Out for delivery',
  delivered: 'Delivered',
  attempted: 'Undelivered',
  pickup_awaited : 'Pickup Awaited',
  pickup_scheduled : 'Pickup Scheduled',
  out_for_pickup : 'Out For Pickup',
  not_picked_up : 'Not Picked Up',
  pickup_completed : 'Pickup Completed',
  inscan_at_hub : 'Inscan at Hub',
  intransittohub: 'In Transit to Hub',
  received: 'Received',
  not_received: 'Not Received',
  out_for_store_pickup: 'Out for Store Pickup',
  loaded: 'Loaded',
  on_hold: 'On Hold',
  rto: 'RTO',
  rvp_delivered: 'RVP Delivered',
};
export const tripStatus = {
  created: 'Created',
  assigned: 'Assigned',
  started: 'Started',
  completed: 'Completed',
};
export const tripType = {
  endpoint: 'Local',
};
export function getRetailConsignmentStatus(status) {
  const statusValue = retailConsignmentStatus[status] ? retailConsignmentStatus[status] : status;
  return statusValue;
}
// For In trip Status calculation
// consignment_type: null
// task_status: 'success', Pretty name: 'Delivered'
// task_status: 'attempted', Pretty name: 'Attempted'

// consignment_type: 'reverse'
// task_status: 'success', Pretty name: 'Pickup Completed'
// task_status: 'attempted', Pretty name: 'Not Picked Up'
export function getRetailInTripConsignmentStatus(record):any {
  if (!record.consignment_type || record.consignment_type === 'reverse') {
    if (record.task_status === 'success') {
      return {status: (record.task_type === 'single_consignment_pickup' || record.task_type === 'pickup') ? 'Pickup Completed' : 'Delivered', time :record.task_completion_time };
    }
    if (record.task_status === 'attempted') {
      return { status: (record.task_type === 'single_consignment_pickup' || record.task_type === 'pickup') ? 'Not Picked Up' : 'Undelivered', time :record.task_completion_time };
    }
    return { status: getRetailConsignmentStatus(record.status) ,
      time :record.last_main_event_time };
  }
  if (record.consignment_type === 'pickupanddelivery') {
    if (record.task_status === 'success') {
      return { status :
      record.task_type === 'store_consignment_pickup' ?
      'Pickup Completed' : 'Delivered', time:record.task_completion_time };
    }
    if (record.task_status === 'attempted') {
      return { status :
      record.task_type === 'store_consignment_pickup' ?  'Not Picked Up' :
       'Undelivered', time:record.task_completion_time };
    }
    return {status:getRetailConsignmentStatus(record.status),
      time:record.last_main_event_time};
  }
  if (record.consignment_type === 'pickupanddeliveryreverse') {
    if (record.task_status === 'success') {
      return { status :
         record.task_type === 'single_consignment_pickup' ?
         'Pickup Completed' : 'Received', time:record.task_completion_time };
    }

    if (record.task_status === 'attempted') {
      return { status :
       record.task_type === 'single_consignment_pickup' ?
        'Not Picked Up' : 'Not Received', time:record.task_completion_time };
    }

    return {status:getRetailConsignmentStatus(record.status),
      time:record.last_main_event_time};
  }
  if (!record.consignment_type || record.consignment_type === 'replacement') {
    if (record.task_status === 'success') {
      return { status: 'Delivered', time :record.task_completion_time };
    }
    if (record.task_status === 'attempted') {
      return { status: 'Undelivered', time :record.task_completion_time };
    }
    return { status: getRetailConsignmentStatus(record.status) ,
      time :record.last_main_event_time };
  }
  return { status: getRetailConsignmentStatus(record.status) ,
    time :record.last_main_event_time };
}
export function tripStatusPrettyNames(key) {
  const value = tripStatus[key];
  return value ? value : key;
}
export function tripTypePrettyNames(key) {
  const value = tripType[key];
  return value ? value : key;
}
