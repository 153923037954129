import { morphism } from 'morphism';
import { API_BASE_URL } from '@src/globals';
import { POST, GET } from '@api/apiHandler';
import {
    FETCH_ITEM_UNITS,
    ADD_ITEM_UNITS_MULTIPLE,
    UPDATE_ITEM_UNITS_MULTIPLE,
    GENERATE_OBJECT_BUILDER_EXCEL,
    DOWNLOAD_ITEM_UNITS,
} from '@api/routes';
import { getAuthenticationHeaders } from './utils';
import {
    ITEM_UNIT_MASTER_OBJECT_TYPE,
    ITEM_UNIT_PARAMS,
} from '@components/pages/OpsDashboard/Setup/AppFlowConfig/ItemUnitMaster.constants';

import {
    requestModelItemUnitManage,
    responseModelItemUnitManage,
    responseModelSingleItemUnitManage,
} from '@model/consignmentView';

export const fetchAllItemUnits = async(params = {}) => {
    const body = {
      ...morphism(requestModelItemUnitManage, params),
    };

    const isActive = body?.is_active;
    if(isActive === '' || isActive === undefined || isActive === null){
        body.is_active = true;
    }

    const response = await GET(`${API_BASE_URL}${FETCH_ITEM_UNITS}`, body);
    return {
      ...morphism(responseModelItemUnitManage, response),
    };
};

export const addItemUnitsMultiple = async(body) => {
    const response = await POST(`${API_BASE_URL}${ADD_ITEM_UNITS_MULTIPLE}`,body);
    return {
        ...morphism(responseModelSingleItemUnitManage, response),
    };
};

export const updateItemUnitsMultiple = async(body) => {
    const response = await POST(`${API_BASE_URL}${UPDATE_ITEM_UNITS_MULTIPLE}`,body);
    return {
        ...morphism(responseModelSingleItemUnitManage, response),
    };
};

export const generateSampleItemUnits = async () => {
    const body = {
        objectType: ITEM_UNIT_MASTER_OBJECT_TYPE,
    };
    return POST(`${API_BASE_URL}${GENERATE_OBJECT_BUILDER_EXCEL}`,body, await getAuthenticationHeaders() , true);
};

export const addSingleItemUnit = async (body) => {
    const parsedInput = {
        [ITEM_UNIT_PARAMS]: [body],
    };
    const response = await POST(`${API_BASE_URL}${ADD_ITEM_UNITS_MULTIPLE}`,parsedInput);
    return {
        ...morphism(responseModelSingleItemUnitManage, response),
    };
};

export const updateSingleItemUnit = async (body) => {
    const parsedInput = {
        [ITEM_UNIT_PARAMS]: [body],
    };
    const response = await POST(`${API_BASE_URL}${UPDATE_ITEM_UNITS_MULTIPLE}`,parsedInput);
    return {
        ...morphism(responseModelSingleItemUnitManage, response),
    };
};

export const downloadItemUnits = async (body) => {
    const payload = {
        ...morphism(requestModelItemUnitManage, body),
    };
    return GET(`${API_BASE_URL}${DOWNLOAD_ITEM_UNITS}`,payload);
};
