import {
  addVehicleDocument,
  getAllTmsDataSetupVehicleDocuments,
  updateVehicleDocument,
  downloadCSVVechicleDump,
} from '@api/TMSDashboard/Setup';

const getKeyMappingVehicleDocuments = (masterData) => {
  return masterData.ops_dashboard_config.config.vehicle_master_config
    ?.vehicleDocumentFieldList?.csv_field_list;
};

const vehicleDocumentsFormItemLayout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};

const viewControl = {
  pageTitle: 'Setup',
  buckets: ['tms_setup_vehicle_documents'],
  globalActions: [],
  fetchData: getAllTmsDataSetupVehicleDocuments,
  onSingleAdd: addVehicleDocument,
  addSingle: {
    sampleUrl: undefined,
    downloadSampleCsvFromApiCall: false,
    downloadSampleCsv: null,
    title: 'Add Vehicle Documents',
    okText: 'Add',
  },
  edit: {
    title: 'Edit Vehicle Documents',
    okText: 'Update',
  },
  warningMsg: 'No Document to upload',
  successMsg: 'Uploaded Successfully',
  updateMsg: 'Updated Successfully',
  singleUploadMapping: getKeyMappingVehicleDocuments,
  onSingleUpdate: updateVehicleDocument,
  downloadData: downloadCSVVechicleDump,
  formItemLayout: vehicleDocumentsFormItemLayout,
  uploadProps: {
    accept: '.pdf, .png, .jpeg, .jpg',
    name: 'file',
    multiple: false,
    showUploadList: { showPreviewIcon: false, showRemoveIcon: true },
  },
  s3FolderDocumentTypeMapping: {
    rc_document: 'rc_vehicle_document',
    puc_document: 'puc_vehicle_document',
    insurance_document: 'insurance_vehicle_document',
  },
};
const buckets = {
  tms_setup_vehicle_documents: {
    name: 'Vehicle Documents',
    filters: [],
    actions: [],
  },
};

const bucketColor = {};

export default { viewControl, buckets, bucketColor };
