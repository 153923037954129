import * as React from 'react';
import Icon from '@ant-design/icons';

const HamburgerSvg = ({ color = 'white' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M1.54321 3.1377H14.4823C14.7932 3.1377 15.0448 2.88613 15.0448 2.5752C15.0448 2.26426 14.7932 2.0127 14.4823 2.0127H1.54321C1.23228 2.0127 0.980713 2.26426 0.980713 2.5752C0.980713 2.88613 1.23228 3.1377 1.54321 3.1377Z" fill={color} />
    <path d="M14.4823 12H1.59009C1.27915 12 1.02759 12.2516 1.02759 12.5625C1.02759 12.8734 1.27915 13.125 1.59009 13.125H14.4823C14.7932 13.125 15.0448 12.8734 15.0448 12.5625C15.0448 12.2516 14.7917 12 14.4823 12Z" fill={color} />
    <path d="M14.4823 7H1.59009C1.27915 7 1.02759 7.25156 1.02759 7.5625C1.02759 7.87344 1.27915 8.125 1.59009 8.125H14.4823C14.7932 8.125 15.0448 7.87344 15.0448 7.5625C15.0448 7.25156 14.7917 7 14.4823 7Z" fill={color} />
  </svg>
);

const MenuIcon = ({ color, ...props }) => (
  <Icon component={() => <HamburgerSvg color={color} />} {...props} />
);

MenuIcon.defaultProps = {
  color: 'white',
};

export default MenuIcon;
