import {
  SET_CURRENT_HUB_REPORTS,
  APPLY_FILTERS_RETAIL_REPORTS,
  RETAIL_REPORTS_UPDATE_TABLE_DATA,
  RETAIL_REPORTS_ENABLE_LOADING,
  RETAIL_REPORTS_DISABLE_LOADING,
} from './constants';
import { message } from 'antd';
import { getDownloadsRetailReports, fetchDownloadRequestsV1 } from '../api/download';

export const setCurrentHubReports = data => async (dispatch, getState) => {
  dispatch({
    data,
    type: SET_CURRENT_HUB_REPORTS,
  });

};

export const applyFilters = data => async (dispatch, getState) => {
  dispatch({
    data,
    type: APPLY_FILTERS_RETAIL_REPORTS,
  });
};

export const fetchReports = () => async (dispatch, getState) => {
  dispatch({
    type: RETAIL_REPORTS_ENABLE_LOADING,
  });
  const { dumpType } = getState().generateReportsReducer.appliedFilters;
  const response =   await getDownloadsRetailReports(dumpType);
  if (response.isSuccess) {
    dispatch({
      type: RETAIL_REPORTS_UPDATE_TABLE_DATA,
      data: response.data.requests,
    });
  } else {
    message.error(response.errorMessage);
  }
  dispatch({
    type: RETAIL_REPORTS_DISABLE_LOADING,
  });
};

export const fetchReportsExpress = () => async (dispatch) => {
  dispatch({
    type: RETAIL_REPORTS_ENABLE_LOADING,
  });
  const params = {
    dumpType: ['LOGISTICS_EXPRESS_REPORTS', 'LOGISTICS_EXPRESS_ANALYTICS_REPORTS'],
  };
  const response = await fetchDownloadRequestsV1(params);
  if (response.isSuccess) {
    dispatch({
      type: RETAIL_REPORTS_UPDATE_TABLE_DATA,
      data: response.data.requests,
    });
  } else {
    message.error(response.errorMessage);
  }
  dispatch({
    type: RETAIL_REPORTS_DISABLE_LOADING,
  });
};
