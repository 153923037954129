import {
  fetchAllCurrencyConversionList,
  createNewCurrencyConversion,
  updateCurrencyConversion,
  downloadSampleCsvCurrencyConversion,
  deleteSingleCurrencyConversion,
  bulkUploadConnections,
} from '@api/currencyConversion';

const getKeyMappingCurrencyConversionSingle = (masterData) => {
  return (
    masterData.ops_dashboard_config?.config?.conversion_config
      ?.csv_field_list || []
  );
};

const currencyConversionFormItemLayout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};
const keyMappingCurrencyConversion = () => {
  return {
    'From Currency': 'from_value',
    'To Currency': 'to_value',
    'Conversion Factor': 'factor',
  };
};
const viewControl = {
  pageTitle: 'Setup',
  buckets: ['currencyConversion'],
  globalActions: [],
  actions: [
    {
      key: 'download',
      name: 'Download',
    },
    {
      key: 'addOptions',
      name: 'Add New',
    },
    {
      key: 'pagination',
      name: 'Pagination',
    },
    {
      key: 'reload',
      name: 'Reload',
    },
  ],
  addOptions: [
    {
      key: 'add_single',
      title: 'Single',
      action: 'add',
      type: 'single',
    },
    {
      key: 'add_bulk',
      title: 'Bulk',
      action: 'add',
      type: 'bulk',
    },
  ],
  id: 'reason_id',
  fetchData: fetchAllCurrencyConversionList,
  onSingleAdd: createNewCurrencyConversion,
  add: {
    sampleUrl: undefined,
    downloadSampleCsvFromApiCall: true,
    downloadSampleCsv: downloadSampleCsvCurrencyConversion,
    title: 'Add Currency Conversion',
  },
  edit: {
    title: 'Edit Currency Conversion',
    okText: 'Update',
  },
  firstRowAsHeader: true,
  headerMapping: keyMappingCurrencyConversion,
  params: 'data',
  onBulkUpload: bulkUploadConnections,
  warningMsg: 'No Currency Conversion to upload',
  successMsg: 'Uploaded Successfully',
  updateMsg: 'Updated Successfully',
  modalHeading: 'Upload Currency Conversion',
  singleUploadMapping: getKeyMappingCurrencyConversionSingle,
  onSingleUpdate: updateCurrencyConversion,
  onSingleDelete: deleteSingleCurrencyConversion,
  formItemLayout: currencyConversionFormItemLayout,
};

const buckets = {
  currencyConversion: {
    name: 'Currency Conversion',
    filters: [],
    actions: [],
  },
};

const bucketColor = {};

export default { viewControl, buckets, bucketColor };
