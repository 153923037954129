import * as React from 'react';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '@theming/jssTypes';
import { Modal, message, Select, Radio } from 'antd';
import { connect } from 'react-redux';
import ExcelUpload from '@components/common/ExcelUpload';
import FailureArrayHandler from '@components/common/FailureArrayHandler';
import { downloadSampleManualCSV } from '@api/retailDashboard';
import { downloadFileData } from '@utils/utils';

const Option = Select.Option;
const styles = (theme: ThemeType): CssType => ({
  selectOptions: {
    width: '100%',
    marginBottom: '10px',
  },
  option: {
    fontSize: '12px',
  },
});

const BulkTripUploadModal = (props) => {
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [tripCreationList, setTripCreationList] = React.useState<any>([]);
  const [option, setOption] = React.useState<any>(undefined);
  const [isErrorModalVisible, setErrorModalVisible] = React.useState(false);
  const [failureArray, setFailureArray] = React.useState([]);

  const {
    isVisible,
    currentActionConfig,
    classes,
    onModalClose,
    currHub,
    width = 360,
    customTripBasedOnVehicle,
    customTripBasedOnVehicleMake,
    customTripBasedOnVendorAndVehicleMake,
  } = props;

  const getOptions = () => {
    const options = {
      rider: 'Rider',
    };
    if (customTripBasedOnVehicle) {
      options['vehicle'] = 'Vehicle';
    }
    if (customTripBasedOnVehicle && customTripBasedOnVehicleMake) {
      options['vehiclemake'] = 'Vehicle Make';
    }
    if (customTripBasedOnVehicleMake && customTripBasedOnVendorAndVehicleMake) {
      options['vendorvehiclemake'] = 'Vendor and Vehicle Make';
    }

    const optionList = createOptionsFromObject(options);

    return (
      optionList?.map((ele) => (
        <Option key={ele.key} value={ele.key}>
          {ele.label}
        </Option>
      )) || []
    );
  };

  const createOptionsFromObject = (options) => {
    return Object.keys(options).map((ele) => {
      return { key: ele, label: options[ele] };
    });
  };

  const handleSampleFileDownload = async () => {
    const params = {
      type: option,
    };
    const response = await downloadSampleManualCSV(params);
    if (response.isSuccess) {
      const fileNameArr = response.fileName?.split('.');
      downloadFileData(response.data,
        fileNameArr ? fileNameArr[0] : 'sample',
        fileNameArr ? `.${fileNameArr[1]}` : '.xlsx');
    }
  };

  const getMapping = () => {
    let ret;
    if (option === 'vehicle') {
      ret = {
        reference_number: 'Reference Number',
        vehicle_code: 'Vehicle Code',
        trip_scheduled_date: 'Trip Scheduled Date',
        is_ftl: 'Is FTL Trip (Yes/No)',
      };
    } else if (option === 'vehiclemake') {
      ret = {
        reference_number: 'Reference Number',
        vehicle_make_code: 'Vehicle Make Code',
        unique_id: 'Unique Id',
        trip_scheduled_date: 'Trip Scheduled Date',
        is_ftl: 'Is FTL Trip (Yes/No)',
      };
    } else if (option === 'vendorvehiclemake') {
      ret = {
        reference_number: 'Reference Number',
        vehicle_make_code: 'Vehicle Make Code',
        vehicle_vendor_code: 'Vehicle Vendor Code',
        unique_id: 'Unique Id',
        indent_number: 'Indent Number',
        trip_scheduled_date: 'Trip Scheduled Date',
        is_ftl: 'Is FTL Trip (Yes/No)',
      };
    } else {
      ret = {
        reference_number: 'Reference Number',
        worker_code: 'Worker Code',
        trip_scheduled_date: 'Trip Scheduled Date',
        is_ftl: 'Is FTL Trip (Yes/No)',
      };
    }
    return ret;
  };

  const getFailureColumns = () => {
    return [
      {
        title: 'Consignment Number',
        dataIndex: 'reference_number',
      },
      {
        title: 'Reason',
        dataIndex: 'reason',
      },
    ];
  };

  const handleParsedData = async (data) => {
    if (data?.length > 0) {
      setTripCreationList(data);
    } else {
      setTripCreationList([]);
    }
  };

  const getBody = () => {
    return {
      data: tripCreationList,
      hubId: currHub?.id,
      type: option,
    };
  };

  const handleSubmit = async () => {
    if (currentActionConfig.requireOption && !option) {
      message.error('Please select an option');
      return;
    }

    if (tripCreationList.length === 0) {
      message.warning('Please upload excel sheet with atleast 1 row of data');
      return;
    }

    setConfirmLoading(true);
    const response = await currentActionConfig.actionApiCall(getBody());
    if (response.isSuccess) {
      if (response?.data?.failures?.length) {
        setFailureArray(response.data.failures);
        setErrorModalVisible(true);
      } else {
        message.success('Trip created successfully');
        onModalClose(true);
      }
    } else {
      onModalClose(false);
      message.error(response.errorMessage);
    }
    setConfirmLoading(false);
  };

  const handleFailureModalClose = () => {
    setErrorModalVisible(false);
    onModalClose(false);
  };

  return (
    <Modal
      width={width}
      confirmLoading={confirmLoading}
      title={'Bulk Upload'}
      visible={isVisible}
      onOk={handleSubmit}
      onCancel={() => onModalClose(false)}
    >
      <div>
        {currentActionConfig.requireOption && (
          <div>
            <div className={classes.option}>
              Select Option <span>*</span>
            </div>
            <Select
              placeholder="Select"
              onChange={(val) => setOption(val)}
              className={classes.selectOptions}
            >
              {getOptions()}
            </Select>
          </div>
        )}

        {option && (
          <ExcelUpload
            mapping={getMapping()}
            onDataParsed={handleParsedData}
            sampleApi={handleSampleFileDownload}
          />
        )}
        {isErrorModalVisible && (
          <FailureArrayHandler
            failureColumns={getFailureColumns()}
            failureArray={failureArray}
            isVisible={isErrorModalVisible}
            onModalClose={handleFailureModalClose}
          ></FailureArrayHandler>
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ masterData, cachedData }) => {
  return {
    customTripBasedOnVehicle:
      masterData?.enable_worker_vehicle_separation || false,
    customTripBasedOnVehicleMake:
      masterData.ops_dashboard_config?.parts_to_show?.flm_trip_config
        ?.custom_trip_based_on_vehiclemake || false,
    customTripBasedOnVendorAndVehicleMake:
      masterData.ops_dashboard_config?.parts_to_show?.flm_trip_config
        ?.custom_trip_allow_vendor_with_vehiclemake || false,
    currHub: cachedData.currHub,
  };
};

const BulkTripUploadModalStyled = withStyles(styles, { injectTheme: true })(
  BulkTripUploadModal,
);
export default connect(mapStateToProps)(BulkTripUploadModalStyled);
