import { setupSideNavConfig } from './setupSideNavConfig';
import {
  getLabelSetupRoute,
  getOpsDashboardBaseRoute,
} from '@src/routing/utils';
import { topLevelRoutes } from '../routes';

/*
  Cannot add component to this config, because if a component needs to use this for linking,
  we will have cyclic dependencies.
  So, created another file for route component mapping (./routeComponent.ts)

  o route - route in react app
  o oldRoute - route in angular app
  o isTopLevel - if the route is top level route of manage (navigation from manage side nav)
*/
export const setupRoutes = {
  apiKeyManagementConsole: {
    route: '/apiKeyManagementConsole',
    isTopLevel: true,
    isAllowed: masterData =>
      masterData.ops_dashboard_config?.parts_to_show?.setup_api_key_management,
  },
  hubSetup: {
    route: '/hubs',
    isTopLevel: true,
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_hubs,
  },
  miscHubOpsAttendance: {
    route: '/miscHubOps/attendance',
    isTopLevel: true,
  },
  setupEmployees: {
    route: '/employees',
    isTopLevel: true,
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_employees,
  },
  appUsers: {
    route: '/appUsers',
    isTopLevel: true,
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
    ?.app_user?.setup?.read,
  },
  appUserCategories: {
    route: '/appUsers/categories',
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
    ?.app_user?.categories?.read,
  },
  appUserDevices: {
    route: '/appUsers/devices',
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
    ?.app_user?.imei?.read,
  },
  appUserApproval: {
    route: '/appUsers/approval',
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
    ?.app_user?.enableWorkerCreationApproval,
  },
  customerAllocationRules: {
    route: '/customerRules',
    isTopLevel: true,
    isAllowed: masterData =>
      masterData.ops_dashboard_config.parts_to_show.setup_client_allocation_rules,
  },
  myDTDCConfig: {
    route: '/setupMyDTDCBanner',
    isTopLevel: true,
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.show_mydtdc_banners,
  },
  customerManagement: {
    route: '/customerManagement',
    isTopLevel: true,
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_client_management,
  },
  customerDetails: {
    route: '/customerManagement/customerDetails/:id',
    isTopLevel: true,
    isAllowed: (masterData) =>
      masterData?.location_master_config?.setup_client_management,
  },
  customerManage: {
    route: '/customerManagement/customerManage',
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_client_management,
  },
  inventoryRequests: {
    route: '/customerManagement/inventoryRequests',
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_client_management,
  },
  globalSeries: {
    route: '/customerManagement/globalSeries',
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_global_virtual_series,
  },
  customerConfiguration: {
    route: '/customerManagement/customerConfiguration',
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_customer_portal_config,
  },
  customerProfile: {
    route: '/customerManagement/customerProfile',
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.enable_customer_config,
  },
  appFlowConfig: {
    route: '/appFlowConfig',
    isTopLevel: true,
    isAllowed: (masterData) =>
      masterData.ops_dashboard_config?.parts_to_show?.setup_appflow_config,
  },
  master:{
    route: '/appFlowConfig/master',
    isAllowed: (masterData) =>
      masterData.ops_dashboard_config?.parts_to_show?.setup_delivery_config,
  },
  serviceTaskMapping: {
    route: '/appFlowConfig/serviceTaskMapping',
    isAllowed: (masterData) =>
      masterData.ops_dashboard_config?.parts_to_show?.show_service_task_mapping,
  },
  twoFactorAuth: {
    route: '/appFlowConfig/twoFactorAuth',
    isAllowed: (masterData) =>
      masterData.ops_dashboard_config?.parts_to_show?.show_two_factor_auth_screen,
  },
  rescheduledReasons: {
    route: '/appFlowConfig/rescheduledReasons',
    isAllowed: (masterData) =>
      masterData.ops_dashboard_config?.parts_to_show?.setup_reschedule_reason,
  },
  serviceType: {
    route: '/appFlowConfig/serviceType',
    isAllowed: (masterData) =>
      masterData.ops_dashboard_config?.parts_to_show?.setup_service_type,
  },
  merchantDetails: {
    route: '/appFlowConfig/merchantDetails',
    isAllowed: (masterData) =>
      masterData.ops_dashboard_config?.parts_to_show?.setup_merchant_details,
  },
  configObject: {
    route: '/appFlowConfig/configObject',
    isAllowed: (masterData) =>
      masterData.ops_dashboard_config?.parts_to_show?.setup_appflow_config,
  },
  organisationSettings: {
    route: '/appFlowConfig/organisationSettings',
    isAllowed: (masterData) =>
      masterData.ops_dashboard_config?.parts_to_show?.enable_app_settings,
  },
  receiversRelation: {
    route: '/appFlowConfig/receiversRelation',
    isAllowed: (masterData) =>
      masterData.ops_dashboard_config?.parts_to_show?.setup_receiver_relation,
  },
  undeliveredReasons: {
    route: '/appFlowConfig/undeliveredReasons',
    isAllowed: (masterData) =>
      masterData.ops_dashboard_config?.parts_to_show?.setup_reason_master,
  },
  kyc: {
    route: '/appFlowConfig/kyc',
    isAllowed: (masterData) =>
      masterData.ops_dashboard_config?.parts_to_show?.setup_kyc,
  },
  packagingTypeMaster: {
    route: '/appFlowConfig/packagingTypeMaster',
    isAllowed: (masterData) =>
      masterData.ops_dashboard_config?.parts_to_show?.show_ltl_packaging_type_master,
  },
  partialDeliveryReasons: {
    route: '/appFlowConfig/partialDeliveryReasons',
    isAllowed: (masterData) =>
      masterData.ops_dashboard_config?.parts_to_show
        ?.show_ltl_partial_delivery_reason,
  },
  lateDeliveryReasons: {
    route: '/appFlowConfig/lateDeliveryReasons',
    isAllowed: (masterData) =>
      masterData.ops_dashboard_config?.parts_to_show
        ?.show_ltl_late_delivery_reason,
  },
  bookingMasters: {
    route: '/bookingMasters/masterData',
    isTopLevel: true,
    isAllowed: masterData => (
      masterData.ops_dashboard_config.parts_to_show.setup_booking_master ||
      masterData.ops_dashboard_config.parts_to_show.setup_booking_master_edit),
  },
  admVehicles: {
    route: '/admVehicles',
    isTopLevel: true,
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_adm_vehicles,
  },
  headerMapping: {
    route: '/headerMapping',
    isTopLevel: true,
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_header_mapping,
  },
  thirdParty: {
    route: '/thirdParty',
    isTopLevel: true,
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_third_party_vendor,
  },
  rateMasters: {
    route: '/rateMasters',
    isTopLevel: true,
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_rate_master,
  },
  carrierPartner: {
    route: '/carrierPartner',
    isTopLevel: true,
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_carrier_partner ||
      masterData.setup_to_show_in_opsv2?.carrier_partner,
  },
  carrierAccountIntegration: {
    route: '/carrierPartner/carrierAccountIntegration',
    isTopLevel: false,
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show
      ?.show_carrier_partner_revamp,
  },
  carrierPartnerCarriers: {
    route: '/carrierPartner/carrier',
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
    ?.carrier_master?.setup?.read,
  },
  carrierPartnerRules: {
    route: '/carrierPartner/rules',
    isAllowed: masterData =>
      masterData.ops_dashboard_config.parts_to_show.show_courier_allocation_v2,
  },
  courierAllocationEngine: {
    route: '/carrierPartner/allocationEngine',
    isTopLevel: false,
    isAllowed: masterData =>
    masterData.ops_dashboard_config.parts_to_show.show_courier_allocation_v3,
  },
  carrierPartnerRulesOld: {
    route: '/carrierPartner/allocation-rules',
    isAllowed: masterData =>
      !masterData.ops_dashboard_config.parts_to_show.show_courier_allocation_v2,
  },
  courierFieldMaster:{
    route: '/carrierPartner/courierFieldMaster',
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
    ?.carrier_master?.courier_field_master?.read,
  },
  courierTracking:{
    route: '/carrierPartner/courierTracking',
    isAllowed: masterData =>
      masterData.ops_dashboard_config.parts_to_show.show_courier_tracking_integration_parts || true,
  },
  advancedSettings:{
    route: '/carrierPartner/advancedSettings',
    isAllowed: masterData => true,
  },
  carrierAllocationDownloads: {
    route: '/carrierPartner/carrierAllocationDownloads',
    isAllowed: masterData =>
    masterData.ops_dashboard_config.config.async_courier_account_allocation_rules_download,
  },
  carrierPartnerConfig: {
    route: '/carrierPartner/config',
    isAllowed: masterData =>
    masterData.ops_dashboard_config.parts_to_show.show_courier_allocation_v2,
  },
  carrierChangeReasonMaster: {
    route: '/carrierPartner/carrierChangeReasonMaster',
    isAllowed: masterData =>
      masterData?.courier_partner_config?.show_courier_partner_change_reason,
  },
  carrierPartnerDataIngestion: {
    route: '/carrierPartner/data',
    isAllowed: masterData =>
    masterData.ops_dashboard_config.parts_to_show.show_courier_allocation_v2,
  },
  transport: {
    route: '/transport',
    isTopLevel: true,
    isAllowed: masterData => (masterData.ops_dashboard_config.parts_to_show.setup_transport_module
               || masterData.ops_dashboard_config.parts_to_show.setup_vehicle_module),
  },
  transportVendor: {
    route: '/transport/vendor',
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
    ?.transport?.vendor?.read,
  },
  transportVehicleMake: {
    route: '/transport/vehicleMake',
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
    ?.transport?.vehicle_make?.read,
  },
  transportVehicleCategory: {
    route: '/transport/vehicleCategory',
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
    ?.transport?.vehicle_category?.read,
  },
  transportOptimizerTags: {
    route: '/transport/optimizerTags',
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
    ?.transport?.optimizer_tag?.read,
  },
  transportVehicleDocuments: {
    route: '/transport/vehicleDocuments',
    isAllowed: masterData =>
    masterData.ops_dashboard_config.parts_to_show.setup_vehicle_documents_module || true,
  },
  transportVehicle: {
    route: '/transport/vehicle',
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
    ?.transport?.vehicle?.read,
  },
  transportRoute: {
    route: '/transport/routes',
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
    ?.transport?.route?.read,
  },
  transportRouteDetails: {
    route: '/transport/routes/details/:routeId',
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_transport_module,
  },
  transportConnection: {
    route: '/transport/connections',
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
    ?.transport?.connection?.read,
  },
  transportException: {
    route: '/transport/exceptions',
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
    ?.transport?.exception?.read,
  },
  transportRouting: {
    route: '/transport/routing',
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
    ?.transport?.routing?.read,
  },
  transportBagging: {
    route: '/transport/bagging',
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
    ?.transport?.bagging?.read,
  },
  transportDocks: {
    route: '/transport/docks',
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
    ?.transport?.dock?.read,
  },
  transportGates: {
    route: '/transport/gates',
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
    ?.transport?.gate?.read,
  },
  transportEwbThresholdMaster: {
    route: '/transport/ewbThresholdMaster',
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
    ?.transport?.ewb_threshold_master,
  },
  shelfManagement: {
    route: '/shelf/management',
    isTopLevel: true,
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.show_shelf_data,
  },
  setupPromocode: {
    route: '/promocode',
    isTopLevel: true,
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.show_promo_campaign,
  },
  addressHierarchy: {
    route: '/addressHierarchy',
    isTopLevel: true,
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_address_config,
  },
  pincode: {
    route: '/pincode/pincodeMasters',
    isTopLevel: true,
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_pincode_master,
  },
  expressBookingMasters: {
    route: '/expressBookingMasters',
    isTopLevel: true,
    isAllowed: masterData =>
      masterData.ops_dashboard_config.parts_to_show.show_booking_masters_v2,
  },
  supplementaryServices: {
    route: '/supplementaryServices',
    isTopLevel: true,
  },
  pricing: {
    route: '/pricing',
  },
  courierPricing: {
    route: '/courierPricing',
  },
  courierTat: {
    route: '/courierTat',
  },
  courierDestinationType: {
    route: '/courierestinationType',
  },
  courierServiceability: {
    route: '/courierServiceability',
  },
  courierHoliday: {
    route: '/courierHoliday',
  },
  tat: {
    route: '/expressBookingMasters/tat',
    isAllowed: masterData => masterData.ops_dashboard_config?.parts_to_show?.setup_permission_obj
    ?.booking_master_v2?.tat?.read,
  },
  serviceability: {
    route: '/expressBookingMasters/serviceability',
    isAllowed: masterData => masterData.ops_dashboard_config?.parts_to_show?.setup_permission_obj
    ?.booking_master_v2?.serviceability?.read,
  },
  destinationType: {
    route: '/expressBookingMasters/destinationType',
    isAllowed: masterData => masterData.ops_dashboard_config?.parts_to_show?.setup_permission_obj
    ?.booking_master_v2?.destination?.read,
  },
  polygonMaster: {
    route: '/expressBookingMasters/polygonMaster',
    isAllowed: masterData => masterData.ops_dashboard_config?.parts_to_show?.setup_permission_obj
    ?.booking_master_v2?.polygon_master?.read,
  },
  passwordPolicy: {
    route: '/password-policy',
    isTopLevel: true,
    isAllowed: masterData => masterData.show_password_policy,
  },
  smsManagement : {
    route : '/sms/management',
    isTopLevel: true,
    isAllowed: masterData =>
      masterData.ops_dashboard_config.parts_to_show.show_sms_integration_parts,
  },
  marketPlaceIntegration : {
    route : '/market-place-integration',
    isTopLevel: true,
    isAllowed: masterData =>
      masterData?.show_external_setup_section || false,
  },
  validationBuilder : {
    route : '/validation/management',
    isTopLevel: true,
    isAllowed: masterData =>
      masterData.ops_dashboard_config.parts_to_show.show_validation_builder ||
      masterData.ops_dashboard_config.parts_to_show.show_transformation_builder,
  },
  setupValidationBuilder: {
    route : '/validation/management',
    isTopLevel: true,
    isAllowed: masterData =>
      masterData.ops_dashboard_config.parts_to_show.show_validation_builder,
  },
  transformationBuilder : {
    route : '/validation/management/transformation',
    isTopLevel: true,
    isAllowed: masterData =>
      masterData.ops_dashboard_config.parts_to_show.show_transformation_builder,
  },
  emailManagement : {
    route : '/sms/management/email/template',
    isTopLevel: true,
    isAllowed: masterData =>
      masterData.ops_dashboard_config.parts_to_show.use_generic_email_templates,
  },
  pushNotifications: {
    route: '/pushNotifications',
    isTopLevel: true,
    isAllowed: (masterData) =>
      masterData.ops_dashboard_config.parts_to_show.setup_push_notifications,
  },
  configurations : {
    route: '/configurations',
    isTopLevel: true,
    isAllowed: masterData => masterData.show_allocation_setup,
  },
  labelSetup : {
    route : getLabelSetupRoute(),
    isTopLevel: true,
    isAllowed: masterData =>
      masterData.ops_dashboard_config.parts_to_show.show_code_free_label_setup,
  },
  transportConsignmentRoute: {
    route: '/transport/consignmentRoute',
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
    ?.transport?.consignment_route?.read,
  },
  reasonCategoryMaster: {
    route: '/appFlowConfig/reasonCategoryMaster',
    isAllowed: (masterData) =>
      masterData.ops_dashboard_config?.parts_to_show?.setup_reason_master,
  },
  ltlNDRMaster: {
    route: '/appFlowConfig/ltlNDRMaster',
    isAllowed: (masterData) =>
      masterData.ops_dashboard_config?.parts_to_show?.use_ltl_ndr,
  },
  itemUnitMaster: (masterData) => masterData?.ops_dashboard_config?.
                      parts_to_show?.setup_item_unit_master?.read,
  seriesConsumptionRules: {
    route: '/seriesConsumption',
    isTopLevel: true,
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show
    && masterData.ops_dashboard_config.parts_to_show.show_series_consumption_rules_module,
  },
  multiLegCourierHubAllocation: {
    route: '/carrierPartner/multiLegHubAllocation',
    isTopLevel: false,
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show
    && masterData.ops_dashboard_config.parts_to_show.show_multi_leg_hub_allocation,
  },
  rosterManagement: {
    route: '/roster-management',
    isTopLevel: true,
    isAllowed: masterData => masterData.show_roster_management_ondemand,
  },
  riderManagement: {
    route: '/rider-management',
    isTopLevel: true,
    isAllowed: masterData => true,
  },
  riderPayout: {
    route: '/payout',
    isTopLevel: true,
    isAllowed: masterData => masterData.rider_payout_read || false,
  },
  contracts: {
    route: '/rateMasters/contracts',
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
    ?.rate_masters?.contracts?.read,
  },
  invoice: {
    route: '/rateMasters/invoice',
    isAllowed: masterData => masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
    ?.rate_masters?.invoice?.read,
  },
  courierContracts: {
    route: '/rateMasters/courierContracts',
    isAllowed: (masterData) => masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
      ?.rate_masters?.courierwise_contracts?.read,
  },
  tripContracts: {
    route: '/rateMasters/tripContracts',
    isAllowed: (masterData) =>
      masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
      ?.rate_masters?.trip_contracts?.read,
  },
  currencyConversion: {
    route: '/currencyConversion',
    isTopLevel: true,
    isAllowed: (masterData) =>
      masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
      ?.currency?.conversion?.read,
  },
  addressRegisterDetails: {
    route: '/addressRegister/:id',
    isTopLevel: true,
    isAllowed: (masterData) =>
      masterData?.location_master_config?.show_in_setup,
  },
  addressRegister: {
    route: '/addressRegister',
    isTopLevel: true,
    isAllowed: (masterData) =>
      masterData?.location_master_config?.show_in_setup,
  },
  consignmentRouteDownloads: {
    route: '/transport/consignmentRoute/consignmentRouteDownloads',
    isTopLevel: false,
    isAllowed: (masterData) => masterData.ops_dashboard_config?.parts_to_show?.setup_permission_obj
    ?.transport?.download || false,
  },
  pricingPlayground: {
    route: '/rateMasters/pricingPlayground',
    isAllowed: (masterData) => masterData.allocation_engine_playground_update,
  },
  hubOpsConfig: {
    route: '/appFlowConfig/hubOpsConfig',
    isTopLevel: false,
    isAllowed: (masterData) =>
      masterData.ops_dashboard_config?.parts_to_show?.setup_hubops_config,
  },
  objectBuilder: {
    route: '/objectBuilder',
    isTopLevel: true,
    isAllowed: (masterData) => masterData.ops_dashboard_config?.parts_to_show?.setup_object_builder,
  },
  viewBuilder: {
    route: '/viewBuilder',
    isTopLevel: true,
    isAllowed: (masterData) => masterData.ops_dashboard_config?.parts_to_show?.setup_view_builder,
  },
  pageBuilder: {
    route: '/pageBuilder',
    isTopLevel: true,
    isAllowed: (masterData) => masterData.ops_dashboard_config?.parts_to_show?.setup_page_builder,
  },
  workflowBuilder: {
    route: '/workflowBuilder',
    isTopLevel: true,
    isAllowed: (masterData) => masterData.ops_dashboard_config?.parts_to_show?.setup_workflow_builder,
  },
};

export const getSetupBaseRoute =
  () => `${getOpsDashboardBaseRoute()}${topLevelRoutes.setup.route}`;

export const getFullRouteForId = (routeId) => {
  const routeObj = setupRoutes[routeId];
  return `${getSetupBaseRoute()}${routeObj.route}`;
};

export const getDefaultRouteIdTransport = (masterData) => {
  const routeObj = setupRoutes['transportVehicle'];
  if(routeObj.isAllowed(masterData))
  {
    return getFullRouteForId('transportVehicle');
  }
  return getFullRouteForId('transportVendor');
};

export const shouldAllowSetupRoute = (masterData, routeId) => {
  const routeObj = setupRoutes[routeId];
  if (!routeObj || !routeObj.isAllowed) {
    return true;
  }
  return routeObj.isAllowed(masterData) || false;
};

const topLevelPriorityList = setupSideNavConfig.map(elem => elem.routeId);
export const defaultTopLevelSetup = (masterData) => {
  return topLevelPriorityList.find(id => shouldAllowSetupRoute(masterData, id));
};
