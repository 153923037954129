import { GET, POST } from './apiHandler';
import { API_BASE_URL } from '@src/globals';
import { FETCH_TRIP_COMMENTS, POST_TRIP_COMMENT } from './routes';

export const fetchTripComments = async (params) => {
  return GET(`${API_BASE_URL}${FETCH_TRIP_COMMENTS}`, params);
};

export const addTripComment = async (params) => {
  return POST(`${API_BASE_URL}${POST_TRIP_COMMENT}`, params);
};
