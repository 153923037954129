import {
  fetchAllServiceType,
  createServiceType,
  updateSingleServiceTask,
} from '@api/appFlowConfig';

const serviceTaskMappingFailureColumns = [
  {
    title: 'Service ID',
    dataIndex: 'service_code',
  },
  {
    title: 'Error',
    dataIndex: 'message',
  },
];

const getKeyMappingServiceTaskSingle = (masterData) => {
  return (
    masterData.ops_dashboard_config?.config?.service_task_mapping_config
      ?.csv_field_list || []
  );
};

const serviceTaskFormItemLayout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};

const viewControl = {
  pageTitle: 'Setup',
  buckets: ['serviceTaskMapping'],
  globalActions: [],
  actions: [
    {
      key: 'addOptions',
      name: 'Add New',
    },
    {
      key: 'pagination',
      name: 'Pagination',
    },
    {
      key: 'perPageCountChange',
      name: 'Pagination Count',
    },
    {
      key: 'reload',
      name: 'Reload',
    },
  ],
  filters: ['keyword'],
  id: 'service_type_id',
  addOptions: [
    {
      key: 'add_single',
      title: 'Single',
      action: 'add',
      type: 'single',
    },
  ],
  fetchData: fetchAllServiceType,
  onSingleAdd: createServiceType,
  onSingleUpdate: updateSingleServiceTask,
  add: {
    title: 'Add New Mapping',
    okText: 'Add',
  },
  edit: {
    title: 'Edit Mapping',
    okText: 'Update',
  },
  successMsg: 'Uploaded Successfully',
  updateMsg: 'Updated Successfully',
  params: 'task_types',
  singleUploadMapping: getKeyMappingServiceTaskSingle,
  firstRowAsHeader: true,
  failureArrayColumns: serviceTaskMappingFailureColumns,
  formItemLayout: serviceTaskFormItemLayout,
};

const buckets = {
  serviceTaskMapping: {
    name: 'Service Task Mapping',
    filters: [],
    actions: [],
  },
};

const bucketColor = {};

export default { viewControl, buckets, bucketColor };
