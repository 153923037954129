import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_LAST_UPDATED_TIME, REMOVE_REPORT } from 'src/actions/constants';
import lodashIsEmpty from 'lodash/isEmpty';
import { getReportVisualization } from 'src/api/landingPageDashboard';
import { API_STATUS } from 'src/components/common/utils/constant';

const useChartContainerHook = (
  reportId,
  dashboardGroupId,
) => {
  const dispatch = useDispatch();
  const [apiStatus, setApiStatus] = useState(API_STATUS.LOADING);
  const [formData, setFormData] = useState<any>('{}');
  const [chartData, setChartData] = useState('{}');
  const [visualizeMetaData, setVisualizeMetaData] = useState('{}');
  const [chartType, setChartType] = useState();
  const [dateRange, setDateRange] = useState('');
  const [chartConfig, setChartConfig] = useState('{}');
  const [formatting, setFormatting] = useState('{}');
  const [chartOptions, setChartOptions] = useState('{}');
  const [columnWidth, setColumnWidth] = useState('{}');
  const [reportDescription, setReportDescription] = useState('');
  const [htmlData, setHtmlData] = useState('');
  const [reportType, setReportType] = useState('');
  const [lastRefreshTime, setLastRefreshTime] = useState('');
  const [dbType, setDbType] = useState('');
  const { lastUpdatedTime, reportsData } = useSelector(
    (state: any) => state.landingPageDashboardGroup,
  );
  const setData = (data) => {
    setFormData(JSON.parse(data.formData || '{}'));
    setChartData(JSON.parse(data.chartData || '{}'));
    setChartType(data.chartType);
    setDateRange(data.reportDateRange);
    setVisualizeMetaData(JSON.parse(data?.visualizeMetaData || '{}'));
    setChartConfig(JSON.parse(data.chartConfig || '{}'));
    setFormatting(JSON.parse(data.formatting || null) ?? []);
    setChartOptions(data.chartOptions);
    setColumnWidth(JSON.parse(data.columnWidth || null));
    setReportDescription(data.reportDescription);
    setHtmlData(data.htmlData);
    setReportType(data.reportType);
    setLastRefreshTime(data.lastRefreshTime);
    setDbType(data.dbType);
  };

  const getReportData = async ({
    reportId,
    dashboardGroupId,
    globalFilters = [],
    isDummyDataRequest = false,
  }) => {
    try {
      const { data } = await getReportVisualization({
        reportId,
        dashboardGroupId,
        globalFilters,
        isDummyDataRequest,
      });

      const now = Date.now();
      if (lastUpdatedTime < now) {
        dispatch({ type: SET_LAST_UPDATED_TIME, payload: now });
      }
      dispatch({
        type: REMOVE_REPORT,
        payload: reportId,
      });

      if (data.err) {
        throw new Error(data.err);
      }

      setData(data);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (error) {
      setApiStatus(API_STATUS.ERROR);
      console.error('An error occurred during data fetching:', error);
    }
  };

  // this useEffect is used to set data when autoRefresh API is working
  useEffect(() => {
    const data = reportsData[reportId];
    if (data) {
      setData(data);
    }
  }, [reportsData, reportId]);

  useEffect(() => {
    if (lodashIsEmpty(reportsData)) {
      getReportData({
        reportId,
        dashboardGroupId,
        globalFilters: [],
        isDummyDataRequest: false,
      });
    }
  }, []);
  return {
    apiStatus,
    getReportVisualisation: getReportData,
    chartType,
    chartData,
    chartOptions,
    formData,
    chartConfig,
    formatting,
    columnWidth,
    dateRange,
    visualizeMetaData,
    reportDescription,
    htmlData,
    reportType,
    lastRefreshTime,
    dbType,
  };
};

export default useChartContainerHook;
