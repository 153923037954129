import * as React from 'react';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '../../../../theming/jssTypes';
import { connect } from 'react-redux';
import * as moment from 'moment';
import { useHistory } from 'react-router';
import {
  Modal,
  message,
  Select,
  Input,
  Row,
  Col,
  Checkbox,
  DatePicker,
  Table,
  InputNumber,
  Button,
} from 'antd';
import {
  CloseOutlined,
  ScanOutlined,
  LoadingOutlined,
  CalendarOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  getFullWorkerList,
  getFullVehiclesList,
  checkForRescheduledCNs,
  getFullVehicleMakeList,
  getFullVehicleVendorList,
  getTaskDetails,
} from '../../../../api/trips';
import {
  fetchVehicleRequest,
  createVehicleRequest,
  updateVehicleRequest,
  handleActionChangeVehicleRequest,
} from '../../../../api/vehiclerequest';
import { customTripColumns, customTripConsolidatedColumns } from './masterData';
import { getViewBuckets } from '../../../../utils/genericConfig/utils';
import {
  createManualTrip,
  createRoute,
  createRouteAll,
} from 'src/api/retailDashboard';
import {
  downloadFileData,
  getKeyArrayFromObject,
  getTaskTypePrettyName,
} from '@utils/utils';
import {
  getRetailTripManagerRoute,
  getCustomTripRequestsRoute,
  getRetailTripDetailsRoute,
  getAutoAllocateRequestsPageRoute,
} from '../../../../routing/utils';
import FailureArrayHandler from '../../../common/FailureArrayHandler';
import {
  getRetailConsignmentStatus,
  tripStatusPrettyNames,
} from '../../../../utils/retailConsignment';
import ConfirmationModal from '../../../common/ConfirmationModal';
import ScanErrorModal from '@components/common/ScanErrorModal';
import * as lodash from 'lodash';
import { validateInScannedConsignments } from '@api/dashboard';
import { generateTimeSlotsOptionsFromList } from 'src/utils/retailTimeslotsOptions';

const Option = Select.Option;
const styles = (theme: ThemeType): CssType => ({
  tableContainer: {
    flex: '1 1 0',
    overflow: 'auto',
    height: 200,
    marginTop: 15,
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
    '& .ant-table-tbody > tr > td, .ant-table-thead > tr > th': {
      padding: 9,
    },
    '& button': {
      float: 'right',
    },
  },
  selectStyle: {
    width: '320px',
  },
  customTripModal: {
    marginTop: '10px',
    alignItems: 'center',
    '& .ant-picker': {
      width: '200px',
    },
  },
  status: {
    textTransform: 'capitalize',
  },
  greenText: {
    color: '#7CBE28',
  },
  rider: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  riderDetails: {
    fontSize: '12px',
  },
  vehicle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  vehicleDetails: {
    fontSize: '12px',
  },
  piecesDetailContainer: {
    display: 'flex',
    gap: '1rem',
    marginTop: '1.5rem',
  },
});

const CustomTripCreationModal = (props: any) => {
  const {
    isVisible,
    classes,
    handleModalClose,
    hubId,
    consignments,
    tasks,
    tripCreationType,
    action,
    allowVehicleSelect,
    enableScheduledRider,
    allConsignments,
    filters,
    viewType,
    bucket,
    isAsync,
    bucketFilters,
    showPrimaryTripOption,
    tripIsConsolidatedPlanningType,
    hideConsignmentRequest,
    hidePickupRequest,
    enablePieceScanning,
    enableScheduledTripOnScheduledDate,
    validationBeforeAddingCN,
    customTripBasedOnVehicleMake,
    customTripBasedOnVendorAndVehicleMake,
    manualFreightType,
    enableVehicleApprovalWorkflow,
    enableOptimizeTrip,
    showTripStartTimeForOptimisation,
    showTripStartTime,
    timeSlots,
    optimizeTripProcessingTime,
    enableVehicleMakeVendorMapping,
    sendVehicleRequisitionRequest,
    allowAdditionToStartTrip,
    isLiveDashboardScreen,
  } = props;

  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [consignment, setConsignment] = React.useState('');
  const [task, setTask] = React.useState('');
  const [cnList, setCNList] = React.useState<any>([]);
  const [taskList, setTaskList] = React.useState<any>([]);
  const [tableList, setTableList] = React.useState<any>([]);
  const [ridersList, setRidersList] = React.useState<any>([]);
  const [vehicleRequestList, setVehicleRequestList] = React.useState<any>([]);
  const [vehiclesList, setVehiclesList] = React.useState<any>([]);
  const [currRider, setCurrRider] = React.useState<any>(undefined);
  const [currVehicle, setCurrVehicle] = React.useState<any>(undefined);
  const [currVehicleRequest, setCurrVehicleRequest] = React.useState<any>(undefined);
  const [fleetTypeRider, setFleetTypeRider] = React.useState<any>('DEDICATED');
  const [vehicleChange, setVehicleChange] = React.useState(false);
  const [scheduleTrip, setScheduleTrip] = React.useState(false);
  const [scheduledDate, setScheduledDate] = React.useState();
  const [isFailureModalVisible, setFailureModalVisible] = React.useState(false);
  const [failureArray, setFailureArray] = React.useState([]);
  const [isPrimaryTrip, setPrimaryTrip] = React.useState(false);
  const [rescheduledCNArray, setRescheduledCNArray] = React.useState([]);
  const [optimizeTrip, setOptimizeTrip] = React.useState(true);
  const [isAppendDropped, setIsAppendDropped] = React.useState(true);
  const [tripStartTime, setTripStartTime] = React.useState();
  const [tripProcessingTime, setTripProcessingTime] = React.useState(
    optimizeTripProcessingTime,
  );
  const [routeOptimizationTimeSlots, setRouteOptimizationTimeSlots] =
    React.useState({
      startTime: null,
      endTime: null,
      prettyName: null,
    });
  const [
    isRescheduledCNConfirmationgModalVisible,
    setRescheduledCNConfirmationgModalVisible,
  ] = React.useState(false);
  const [piecesList, setPiecesList] = React.useState<any>({
    completePieces: [],
    missingPieces: [],
    partialPieces: [],
    partialCN: [],
  });
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [validationLoader, setValidationLoader] = React.useState(false);
  const [currVehicleMake, setCurrVehicleMake] = React.useState<any>(undefined);
  const [vehicleMakeList, setVehicleMakeList] = React.useState<any>([]);
  const [currVehicleVendor, setCurrVehicleVendor] =
    React.useState<any>(undefined);
  const [vehicleVendorList, setVehicleVendorList] = React.useState<any>([]);
  const [isFTL, setIsFTL] = React.useState(false);
  const vehicleMakeListMaster = React.useRef([]);
  const [addRider, setAddRider] = React.useState(false);
  const [addVehicle, setAddVehicle] = React.useState(false);
  const [riderName, setRiderName] = React.useState(null);
  const [riderPhone, setRiderPhone] = React.useState(null);
  const [vehicleNumber, setVehicleNumber] = React.useState(null);

  const history = useHistory();
  const failureColumns = [
    {
      title: 'Consignment Number',
      dataIndex: 'reference_number',
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
    },
  ];

  const failureColumnsConsolidatedPlanning = [
    {
      title: 'Task Reference Number',
      dataIndex: 'task_reference_number',
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
    },
  ];

  const getResponseData = (fetchType, response) => {
    switch (fetchType) {
      case 'rider':
        return response?.data?.data;
      case 'vehicle':
      case 'vehicleMake':
      case 'vehicleVendor':
        return response?.data;
      default:
        return response?.data;
    }
  };

  const getResponseFromFetchType = (fetchType, params) => {
    switch (fetchType) {
      case 'rider':
        return getFullWorkerList(params);
      case 'vehicle':
        return getFullVehiclesList(params);
      case 'vehicleMake':
        return getFullVehicleMakeList(params);
      case 'vehicleVendor':
        return getFullVehicleVendorList(params);
      default:
        return {};
    }
  };

  const handleSetVehicleMakeList = () => {
    if (currVehicleVendor) {
      const currentVendorData = vehicleVendorList.find(
        (v) => v.value === currVehicleVendor.value,
      );
      const allowedVehicleMakeIds = (
        currentVendorData?.details?.vehicle_make_list || []
      ).map((id) => id.toString());
      /**
       * Computation Logic 
       * when enableVehicleMakeVendorMapping : true
       * Vehicle Vendor has a list of Vehicle Make stored in setup [Multiple]
       * newVehicleMakeList is updated from BE list received directly
       * where as if enableVehicleMakeVendorMapping : false
       * Use vehicle_make_list comes from vendor and filter those vehicle makes from all.
       */
      let newVehicleMakeList;
      if(enableVehicleMakeVendorMapping){
        newVehicleMakeList = vehicleMakeListMaster?.current || [];
      } else {
        newVehicleMakeList = allowedVehicleMakeIds?.length
        ? vehicleMakeListMaster.current.filter((make) =>
            allowedVehicleMakeIds.includes(make.value),
          )
        : [];
      }
      setVehicleMakeList(newVehicleMakeList);
    } else {
      setVehicleMakeList([...vehicleMakeListMaster.current]);
    }
  };

  const fetchVehicleRequestList = async () => {
    const params: Object = {
      planning_hub_id: hubId,
      status: 'approved',
      action_type: 'FETCH',
      planning_leg: 'FLM',
      page_number: 1,
      skip_time_filter: true,
    };
    const response = await fetchVehicleRequest(params);
    let responseData = [];
    if (response?.isSuccess && response?.data?.page_data) {
      responseData = response?.data?.page_data;
    }
    const options = responseData?.length
      ? responseData.map((ele) => {
          return {
            key: ele?.vehicle_request_reference_number,
            value: ele.vehicle_request_reference_number,
            label: ele?.vehicle_request_reference_number,
          };
        })
      : [];
    setVehicleRequestList(options);
  };

  const fetchOptions = async (fetchType) => {
    const params: Object = {
      hubId,
      scheduleWorkerFlag: enableScheduledTripOnScheduledDate && scheduleTrip,
    };
    if (fetchType === 'vehicle') {
      params['vehicleMakeId'] = currVehicleMake?.value;
    }
    if (fetchType === 'vehicleMake' && enableVehicleMakeVendorMapping && currVehicleVendor?.label){
      params['vendor_code'] = currVehicleVendor?.label;
    }
    // changes to be done only when user is on live dashboard screen
    if (fetchType === 'rider' && allowAdditionToStartTrip && isLiveDashboardScreen) {
      params['includeStartedVehicles'] = allowAdditionToStartTrip;
    }
    const response = await getResponseFromFetchType(fetchType, params);
    const responseData = getResponseData(fetchType, response);
    const showCode =
      fetchType === 'vehicleMake' || fetchType === 'vehicleVendor';
    const options = responseData?.length
      ? responseData.map((ele) => {
          return {
            key: showCode ? ele?.code : ele.id,
            value: ele.id,
            worker_code: ele.worker_code,
            worker_phone: ele.worker_phone,
            worker_username: ele.worker_username,
            label: showCode
              ? ele?.code
              : fetchType === 'rider'
              ? ele.name
              : ele.vehicle_number,
            details: ele,
          };
        })
      : [];
    switch (fetchType) {
      case 'rider':
        setRidersList(options);
        break;
      case 'vehicle':
        setVehiclesList(options);
        break;
      case 'vehicleMake':
        vehicleMakeListMaster.current = options;
        handleSetVehicleMakeList();
        break;
      case 'vehicleVendor':
        setVehicleVendorList(options);
        break;
    }
  };

  React.useEffect(() => {
    ['selected', 'upload'].indexOf(tripCreationType) !== -1
      ? tripIsConsolidatedPlanningType
        ? setLists()
        : setCNList(consignments)
      : null;
    // fetchOptions('rider');
    enableVehicleApprovalWorkflow ? fetchVehicleRequestList() : null;
    allowVehicleSelect ? fetchOptions('vehicle') : null;
    customTripBasedOnVehicleMake ? fetchOptions('vehicleMake') : null;
    customTripBasedOnVehicleMake && customTripBasedOnVendorAndVehicleMake
      ? fetchOptions('vehicleVendor')
      : null;
    setVehicleChange(allowVehicleSelect);
  }, []);

  React.useEffect(() => {
    fetchOptions('rider');
  }, [scheduleTrip]);

  const setLists = () => {
    setCNList(consignments);
    setTaskList(tasks);
    const list: any = [];
    consignments?.map((value) => {
      list.push({
        reference_number: value,
        task_type: 'Consignment',
      });
    });
    tasks?.map((value) => {
      const selectedCn = allConsignments.filter(
        (x) => x.task_reference_number === value,
      );
      list.push({
        reference_number: value,
        task_type: selectedCn[0]?.task_type ?? 'Pickup Request/Task',
      });
    });
    setTableList(list);
  };

  const getValidConsignmentList = async () => {
    const completePieces = piecesList.completePieces.map((item) => {
      if (item) return item.reference_number;
    });

    const partialPieces = piecesList.partialPieces.map((item) => {
      if (item) return item.reference_number;
    });

    const res = await validateInScannedConsignments({
      hub_id: hubId,
      consignments: [...completePieces, ...partialPieces],
    });

    const missingPieces = res.data?.missing_consignments;

    if (missingPieces?.length) {
      setPiecesList({
        ...piecesList,
        missingPieces,
      });
      return false;
    }
    return true;
  };

  const handleFailureModalClose = () => {
    if(tripIsConsolidatedPlanningType) {
      handleModalClose(false);
      return;
    }
    setFailureModalVisible(false);
    Modal.confirm({
      title: 'Do you want to continue scanning with the valid consignments?',
      okText: 'Continue',
      cancelText: 'Cancel',
      onOk() {
        const validCnList = cnList.filter(
          (val) => !failureArray.find((ele) => ele.reference_number === val),
        );
        if (enablePieceScanning) {
          const updatedCompletePieces = piecesList.completePieces.filter(
            (val) =>
              !failureArray.find(
                (ele) => ele.reference_number === val.parent_reference_number,
              ),
          );
          const updatedPartialPieces = piecesList.partialPieces.filter(
            (val) =>
              !failureArray.find(
                (ele) => ele.reference_number === val.parent_reference_number,
              ),
          );
          setPiecesList({
            ...piecesList,
            completePieces: updatedCompletePieces,
            partialPieces: updatedPartialPieces,
          });
        }
        setCNList(validCnList);
      },
      onCancel() {
        handleModalClose(false);
      },
    });
  };

  const getScheduledDate = (value) => {
    let startDate = moment().format('YYYY-MM-DD');
    let endDate = moment().format('YYYY-MM-DD');
    if (value && value.length > 1) {
      startDate = value[0].format('YYYY-MM-DD');
      endDate = value[1].format('YYYY-MM-DD');
    }
    const obj = {
      fromDateString: startDate,
      toDateString: endDate,
    };
    return value && value.length > 1 ? obj : {};
  };

  const getAPIRequestBody = () => {
    const params = {
      source: 'dashboard_custom_trip',
      consignments:
        ['selected', 'upload'].indexOf(tripCreationType) !== -1 ? cnList : [],
      hubId,
      vehicleRequestNumber: currVehicleRequest?.key,
      isExcelUpload: tripCreationType === 'upload',
      workerId: currRider?.value,
      vehicleId: currVehicle?.value,
      changeVehicle: vehicleChange,
      endpointType:
        ['all', 'filtered'].indexOf(tripCreationType) !== -1
          ? 'all'
          : 'selected',
      scheduledAt: scheduledDate,
      applyFilters: tripCreationType === 'filtered' ? true : undefined,
      fromDate: filters.startDate,
      toDate: filters.endDate,
      dateField: filters.dateFilterType,
      isPrimaryTrip: showPrimaryTripOption ? isPrimaryTrip : undefined,
      isScheduledTrip: scheduleTrip,
      vehicleMakeId: currVehicleMake?.value,
      vendorId: currVehicleVendor?.value,
      is_ftl: isFTL,
      riderMobile: riderPhone,
      riderName,
      vehicleNumber,
    };
    let body =
      tripCreationType === 'filtered'
        ? {
            ...params,
            statusList:
              getKeyArrayFromObject(bucketFilters.status) || undefined,
            attemptCountList: bucketFilters.attempts || undefined,
            bookingServiceTypeIdList:
              getKeyArrayFromObject(bucketFilters.serviceType) || undefined,
            customerIdList:
              getKeyArrayFromObject(bucketFilters.customer) || undefined,
            consignmentType: bucketFilters.consignmentType || undefined,
            orderTypeList: bucketFilters.orderTypeList || undefined,
            proximityList: bucketFilters.proximityList || undefined,
            purchasingGroup: bucketFilters.purchasingGroup || undefined,
            originPincodeList: bucketFilters.originPincodeList || undefined,
            movementTypeList: bucketFilters.movementTypeList || undefined,
            destinationPincodeList:
              bucketFilters.destinationPincodeList || undefined,
            referenceNumberListInclusive:
              bucketFilters.reference_number_search || undefined,
            scheduledDate: getScheduledDate(bucketFilters.scheduledDate),
          }
        : params;
    if (tripIsConsolidatedPlanningType) {
      const otherParams = {
        tasks:
          ['selected', 'upload'].indexOf(tripCreationType) !== -1
            ? taskList
            : [],
        planning_type: viewType,
      };
      body = {
        ...body,
        ...otherParams,
      };
    }
    return body;
  };

  const createCustomTripApiCall = async () => {
    let actionConfig: any = {};
    if (action) {
      const bucketConfig = getViewBuckets(viewType)[bucket];
      actionConfig = bucketConfig.actionsApiCalls
        ? bucketConfig.actionsApiCalls[action]
        : {};
    }

    const body = getAPIRequestBody();
    //CORE-212103
    const isBlob = !(['all','filtered','selected'].includes(tripCreationType));
    const response =
      actionConfig && actionConfig.createCustomTrip
        ? await actionConfig.createCustomTrip(body, isAsync, isBlob)
        : await createManualTrip(body, isAsync, isBlob);

    if (response.isSuccess) {
      if (response.data?.success) {
        const tripReferenceNumber =
          response.data?.tripData?.trip_reference_number;
        const successMessage = () =>
          message.success({
            content: (
              <div>
                Custom Trip Created Successfully!{' '}
                {tripReferenceNumber ? (
                  <a
                    target="_blank"
                    href={`/ops${getRetailTripDetailsRoute(
                      tripReferenceNumber,
                    )}`}
                  >
                    View Trip
                  </a>
                ) : null}
                <CloseOutlined
                  style={{
                    color: '#333333',
                    marginLeft: '0.6rem',
                    marginRight: '0',
                  }}
                  onClick={() => message.destroy()}
                />
              </div>
            ),
            className: 'custom-antd-success-message',
            duration: 7,
          });
        if (!isAsync) {
          successMessage();
          if(isBlob){
            downloadFileData(response.data, 'consignments');
            history.push(getRetailTripManagerRoute(), {
              activeBucket: 'created',
            });
          }
          handleModalClose(true);
        } else if (response.data.tripId) {
          successMessage();
          handleModalClose(true);
        } else {
          message.success('Custom trip request created');
          history.push(getCustomTripRequestsRoute());
          handleModalClose(true);
        }
      } else if (response.data?.failures?.length) {
        setFailureArray(response.data.failures);
        setFailureModalVisible(true);
      } else {
        message.error('Something went wrong!');
        handleModalClose(false);
      }
    } else {
      message.error(response.errorMessage);
      handleModalClose(false);
    }
    setConfirmLoading(false);
  };

  const checkForRescheduledCNApiCall = async () => {
    const body = {
      consignments:['selected', 'upload'].indexOf(tripCreationType) !== -1 ? cnList : [],
    };
    const response = await checkForRescheduledCNs(body);

    if (response.isSuccess) {
      const data = response.data;
      if (data && data?.reschedule_comparison_result &&
          data?.reschedule_comparison_result?.length > 0) {
        const rescheduledConsignments = data.reschedule_comparison_result;
        setRescheduledCNConfirmationgModalVisible(true);
        setRescheduledCNArray(rescheduledConsignments);
        setConfirmLoading(false);
      } else {
        createCustomTripApiCall();
      }
    } else {
      message.error(response.errorMessage);
      handleModalClose(false);
      setConfirmLoading(false);
    }
  };

  const handleCreateCustomTrip = async () => {
    setConfirmLoading(true);

    if (enablePieceScanning) {
      const isValid = await getValidConsignmentList();

      if (!isValid) {
        setShowErrorModal(true);
        setConfirmLoading(false);
        return;
      }
    }

    if (currVehicleVendor && !currVehicleMake) {
      setConfirmLoading(false);
      message.error('Please select vehicle make with vendor');
      return;
    }

    if (addRider && (!riderName || !riderPhone)) {
      message.error('Please enter both rider name and rider phone');
    }

    if (addVehicle && !vehicleNumber) {
      message.error('Please enter vehicle registration number');
    }

    if (validationBeforeAddingCN) {
      checkForRescheduledCNApiCall();
    } else {
      createCustomTripApiCall();
    }
  };

  const getAPIRequestBodyCreateRoute = () => {
    const defaultStartTime = tripStartTime
      ? Date.parse(tripStartTime)
      : Date.now();
    return {
      consignments: cnList,
      hubId,
      routeOptimizationTimeSlots: {
        pretty_name: routeOptimizationTimeSlots?.prettyName,
        start_time: routeOptimizationTimeSlots?.startTime,
        end_time: routeOptimizationTimeSlots?.endTime,
      },
      workerId: currRider?.value,
      changeVehicle: false,
      appendDropoedCn: isAppendDropped,
      tripProcessingTime,
      scheduledDate,
      ...(showTripStartTimeForOptimisation &&
        showTripStartTime && { defaultStartTime }),
    };
  };

  const handleOptimizedTrip = async () => {
    const body = getAPIRequestBodyCreateRoute();
    setConfirmLoading(true);
    const response = await createRoute(body, isAsync);
    if (response.isSuccess) {
      if (!isAsync) {
        message.success('Trips Created');
        downloadFileData(response.data, 'consignments');
        history.push(getRetailTripManagerRoute(), {
          activeBucket: 'created',
        });
      } else {
        message.success('Auto allocation request created');
        history.push(getAutoAllocateRequestsPageRoute());
      }
      handleModalClose(true);
    } else if (response?.failures?.length) {
      setFailureArray(response.failures);
      setFailureModalVisible(true);
    } else {
      message.error(response.errorMessage);
      handleModalClose(false);
    }

    setConfirmLoading(false);
  };

  const handleRescheduledCNConfirmation = async () => {
    setConfirmLoading(true);
    setRescheduledCNConfirmationgModalVisible(false);
    createCustomTripApiCall();
  };

  const handleRescheduledCNConfirmationgModalClose = () => {
    setRescheduledCNConfirmationgModalVisible(false);
  };

  const onConsignmentChange = (e) => {
    setConsignment(e.target.value);
  };

  const onTaskChange = (e) => {
    setTask(e.target.value);
  };

  const handleAddCNClick = async (e) => {
    if (!enablePieceScanning && consignment === '') {
      message.warning('Please enter a valid consignment number');
      return;
    }
    if (!enablePieceScanning && cnList.length) {
      const obj = cnList.find((element) => {
        return element.toUpperCase() === consignment.toUpperCase();
      });
      if (obj) {
        message.error('Consignment has already been scanned once');
        setConsignment('');
        return;
      }
    }
    if (enablePieceScanning) {
      setValidationLoader(true);
      if (piecesList.length) {
        const obj = piecesList.find((element) => {
          return element.toUpperCase() === consignment.toUpperCase();
        });
        if (obj) {
          message.error('Piece has already been scanned once');
          setConsignment('');
          setValidationLoader(false);
          return;
        }
      }

      const completePieces = piecesList.completePieces.map((item) => {
        if (item) return item.reference_number;
      });

      const partialPieces = piecesList.partialPieces.map((item) => {
        if (item) return item.reference_number;
      });

      const res = await validateInScannedConsignments({
        hub_id: hubId,
        consignments: [consignment, ...completePieces, ...partialPieces],
      });

      if (!res.isSuccess) {
        setValidationLoader(false);
        message.error(res.errorMessage);
        return;
      }

      const validConsignments = res.data?.valid_consignments || [];
      const partialConsignments = res.data?.partial_scanned_consignments || [];

      const uniqueValidConsignments = lodash
        .uniqBy(validConsignments, 'parent_reference_number')
        .map((item: any) => item.parent_reference_number);
      const uniqueCompletePieces = lodash.uniqBy(
        validConsignments,
        'reference_number',
      );
      const uniquePartialPieces = lodash.uniqBy(
        partialConsignments,
        'reference_number',
      );
      const uniquePartialCN = lodash.uniqBy(
        partialConsignments,
        'parent_reference_number',
      );

      setConsignment('');
      setPiecesList({
        ...piecesList,
        completePieces: uniqueCompletePieces,
        partialPieces: uniquePartialPieces,
        partialCN: uniquePartialCN,
      });
      setCNList(uniqueValidConsignments);
      setValidationLoader(false);
      return;
    }
    const cnlist = [consignment, ...cnList];
    if (tripIsConsolidatedPlanningType) {
      const tablelist = [
        ...tableList,
        {
          reference_number: consignment,
          task_type: 'Consignment',
        },
      ];
      setTableList(tablelist);
    }
    setConsignment('');
    setCNList(cnlist);
  };

  const handleTaskAddCNClick = async (e) => {
    if (task === '') {
      message.warning('Please enter a valid task number');
      return;
    }
    if (taskList?.length) {
      const obj = taskList.find((element) => {
        return element.toUpperCase() === task.toUpperCase();
      });
      if (obj) {
        message.error('Task has already been scanned once');
        setTask('');
        return;
      }
    }
    const tasklist = [...taskList, task];
    if (tripIsConsolidatedPlanningType) {
      const taskType = await getTaskDetails({ taskId: task });
      const tablelist = [
        ...tableList,
        {
          reference_number: task,
          task_type: taskType.data[0].task_type,
        },
      ];
      setTableList(tablelist);
    }
    setTask('');
    setTaskList(tasklist);
  };

  const handleDeleteCNClick = (cnNumber) => {
    if (enablePieceScanning) {
      const updatedCompletePieces = piecesList.completePieces.filter((item) => {
        if (item.parent_reference_number !== cnNumber) return item;
      });
      const updatedPartialPieces = piecesList.partialPieces.filter((item) => {
        if (item.parent_reference_number !== cnNumber) return item;
      });

      setPiecesList({
        ...piecesList,
        completePieces: updatedCompletePieces,
        partialPieces: updatedPartialPieces,
      });
    }
    const cnlist = cnList.filter((cn) => cn !== cnNumber);
    setCNList(cnlist);
  };

  const handleDeleteTasksClick = (taskRecord) => {
    const tablelist = tableList.filter((task) => task !== taskRecord);
    const cnlist = cnList?.filter(
      (task) => task !== taskRecord.reference_number,
    );
    const tasklist = taskList?.filter(
      (task) => task !== taskRecord.reference_number,
    );
    setCNList(cnlist);
    setTaskList(tasklist);
    setTableList(tablelist);
  };

  const renderExtraDetails = (extraDetail) => {
    let extraDetailString = '';
    if (extraDetail.status) {
      extraDetailString = extraDetailString.concat(
        ` | ${tripStatusPrettyNames(extraDetail.status)}`,
      );
    }
    if (extraDetail.hasOwnProperty('task_count')) {
      extraDetailString = extraDetailString.concat(` | ${extraDetail.task_count}`);
    }
    return extraDetailString;
  };

  const getListFromType = (type) => {
    switch (type) {
      case 'rider':
        return ridersList;
      case 'vehicleMake':
        return vehicleMakeList;
      case 'vehicle':
        return vehiclesList;
      case 'vehicleVendor':
        return vehicleVendorList;
      default:
        return [];
    }
  };

  const generateOptionsFromList = (type) => {
    const hasValueAndCode = type === 'vehicleMake' || type === 'vehicleVendor';
    return getListFromType(type).map((elem) => (
      <Option
        key={elem.key}
        value={hasValueAndCode ? elem?.value : elem.key}
        label={elem.label}
        worker_code={elem.worker_code}
        worker_phone={elem.worker_phone}
        worker_username={elem.worker_username}
      >
        {type === 'rider' ? (
          <div className={classes.rider}>
            <div>{elem.label}</div>
            <div className={classes.riderDetails}>
              {elem.details.trip_reference_number || (
                <span className={classes.greenText}>Available</span>
              )}
              {renderExtraDetails(elem.details)}
            </div>
          </div>
        ) : type === 'vehicle' ? (
          <div className={classes.vehicle}>
            <div>{elem.label}</div>
            <div className={classes.vehicleDetails}>
              {elem.details.trip_reference_number || (
                <span className={classes.greenText}>Available</span>
              )}
              {renderExtraDetails(elem.details)}
            </div>
          </div>
        ) : hasValueAndCode ? (
          `${elem.details?.code} ${elem.details?.name ? '|' : ''} ${
            elem.details?.name || ''
          }`
        ) : (
          elem.label
        )}
      </Option>
    ));
  };
  const generateVehicleRequestOptionsFromList = () => {
    return vehicleRequestList.map((elem) => (
      <Option
        key={elem.key}
        value={elem.key}
        label={elem.label}
      >
        {elem.label}
      </Option>
    ));
  };

  React.useEffect(() => {
    fetchOptions('vehicle');
  }, [currVehicleMake]);

  React.useEffect(() => {
    setCurrVehicleMake(null);
    if(enableVehicleMakeVendorMapping){
      fetchOptions('vehicleMake');
    }
    handleSetVehicleMakeList();
  }, [currVehicleVendor]);

  const onTimeSlotChange = (val) => {
    const timeSlot = val.split('_');
    if (timeSlot.length && timeSlot.length > 2) {
      setRouteOptimizationTimeSlots({
        startTime: timeSlot[0],
        endTime: timeSlot[1],
        prettyName: timeSlot[2],
      });
    }
  };
  const calculateFleetType = (value, type) => {
    let fleetType = 'DEDICATED';
    if (value && ridersList && value.key && type === 'rider') {
      const obj = ridersList.find((rider) => {
        return rider.key === value.key;
      });
      fleetType = obj?.details?.fleet_type || 'DEDICATED';
    } else if (value && vehiclesList && value.key && type === 'vehicle') {
      const obj = vehiclesList.find((veh) => {
        return veh.key === value.key;
      });
      fleetType = obj?.details?.fleet_type || 'DEDICATED';
    }
    setFleetTypeRider(fleetType);
  };

  const onSelectChange = (value, type) => {
    switch (type) {
      case 'rider':
        setCurrRider(value);
        if(!allowVehicleSelect){
          calculateFleetType(value, 'rider');
        }
        break;
      case 'vehicleMake':
        setCurrVehicleMake({ ...value });
        break;
      case 'vehicleVendor':
        setCurrVehicleVendor({ ...value });
        break;
      default:
        setCurrVehicleMake(null);
        setCurrVehicleVendor(null);
        setCurrVehicle(value);
        if(allowVehicleSelect){
          calculateFleetType(value, 'vehicle');
        }
    }
  };
  const getVehicleRequestSelect = () => {
    const value = currVehicleRequest
    ? {
        key: currVehicleRequest.key,
        value: currVehicleRequest.value,
        label: currVehicleRequest.label,
      }
    : undefined;
    return (<Select
        labelInValue
        showSearch
        allowClear
        disabled={
          (fleetTypeRider === 'DEDICATED')
        }
        placeholder={'Select Vehicle Request'}
        value={value}
        onChange={(value) => setCurrVehicleRequest(value)}
        filterOption={(inputVal, option: any) => {
          if (!inputVal) {
            return true;
          }
          const optionval =
            typeof option.label === 'string'
              ? option.label.trim().toLowerCase()
              : '';
          return optionval.includes(inputVal.trim().toLowerCase());
        }}
        className={classes.selectStyle}
        optionLabelProp="label"
      >
        {generateVehicleRequestOptionsFromList()}
      </Select>);
  };

  const getRiderVehicleSelect = (type) => {
    let value;
    let addNewOption;
    if (type === 'rider') {
      value = currRider
        ? {
            key: currRider.key,
            value: currRider.value,
            label: currRider.label,
          }
        : undefined;
      if (sendVehicleRequisitionRequest && customTripBasedOnVendorAndVehicleMake) {
        addNewOption = <Button
          style={{ width: '100%' }}
          key="Add Rider"
          onClick={() => {
            setAddRider(true);
            setCurrRider(null);
          }} icon={<PlusOutlined/>}>
          Add New Rider
        </Button>;
      }
    } else if (type === 'vehicleMake') {
      value = currVehicleMake
        ? {
            key: currVehicleMake.key,
            value: currVehicleMake.value,
            label: currVehicleMake.label,
            details: currVehicleMake.details,
          }
        : undefined;
    } else if (type === 'vehicleVendor') {
      value = currVehicleVendor
        ? {
            key: currVehicleVendor.key,
            value: currVehicleVendor.value,
            label: currVehicleVendor.label,
            details: currVehicleVendor.details,
          }
        : undefined;
    } else {
      value = currVehicle
        ? {
            key: currVehicle.key,
            value: currVehicle.value,
            label: currVehicle.label,
          }
        : undefined;
      if (sendVehicleRequisitionRequest
        && allowVehicleSelect
        && customTripBasedOnVendorAndVehicleMake) {
        addNewOption = <Button
          style={{ width: '100%' }}
          key="Add Vehicle"
          onClick={() => {
            setAddVehicle(true);
            setCurrVehicle(null);
          }} icon={<PlusOutlined/>}>
          Add New Vehicle
        </Button>;
      }
    }
    return (
      <Select
        labelInValue
        showSearch
        allowClear
        disabled={
          (type === 'vehicle' && !vehicleChange) ||
          ((type === 'vehicleMake' || type === 'vehicleVendor') && currVehicle)
        }
        placeholder={`Select ${type.charAt(0).toUpperCase() + type.slice(1)}`}
        value={value}
        onChange={(value) => onSelectChange(value, type)}
        filterOption={(inputVal, option: any) => {
          if (!inputVal) {
            return true;
          }
          const searchValue = inputVal.trim().toLowerCase();
          return (
            option?.label?.toLowerCase()?.includes(searchValue) ||
            (option?.worker_code && option.worker_code?.toLowerCase()?.includes(searchValue)) ||
            (option?.worker_phone && option.worker_phone?.toLowerCase()?.includes(searchValue)) ||
            (option?.worker_username && option.worker_username?.toLowerCase()?.includes(searchValue))
          );
        }}
        className={classes.selectStyle}
        optionLabelProp="label"
        dropdownStyle={{
          padding: '0',
        }}
        dropdownRender={(menu) => {
          return (
            <>
              {menu}
              {addNewOption ?? <></>}
            </>
          );
        }}
      >
        {generateOptionsFromList(type)}
      </Select>
    );
  };

  const handleChangeVehicle = (e) => {
    if (e.target.checked) {
      setVehicleChange(true);
    } else {
      setVehicleChange(false);
    }
  };

  const handleScheduleTrip = (e) => {
    e.target.checked ? setScheduleTrip(true) : setScheduleTrip(false);
  };

  const handleTripStartTime = (date) => {
    setTripStartTime(date);
  };

  const handleTripProcessingTime = (value) => {
    if (value) setTripProcessingTime(value);
  };

  const handleOptimizeTrip = (e) => {
    e.target.checked ? setOptimizeTrip(true) : setOptimizeTrip(false);
  };

  const handleAppendDropped = (e) => {
    e.target.checked ? setIsAppendDropped(true) : setIsAppendDropped(false);
  };

  const handleModalOk = () => {
    if (
      enableOptimizeTrip &&
      optimizeTrip &&
      !tripIsConsolidatedPlanningType &&
      tripCreationType === 'selected'
    ) {
      if (!currRider || !currRider.value) {
        message.error('Please select rider');
        return;
      }
      return handleOptimizedTrip();
    }
    return handleCreateCustomTrip();
  };

  const disabledDate = (current) => {
    return current && current < moment().startOf('day');
  };

  const handleDateChange = (date) => {
    setScheduledDate(date?.format('YYYY-MM-DD'));
  };
  const generateConsolidatedColumnsToshow = () => {
    const columnData: any = [];
    const indexList = customTripConsolidatedColumns;
    indexList.forEach((item) => {
      const itemToPush = {};
      itemToPush['title'] = item.pretty_name;
      itemToPush['dataIndex'] = item.column_id;
      itemToPush['key'] = item.column_id;
      itemToPush['width'] = 150;

      if (item.column_id === 'serial_number') {
        itemToPush['width'] = 80;
        itemToPush['render'] = (text, record, index) => index + 1;
      }
      if (item.column_id === 'consignment_count') {
        itemToPush['render'] = (text, record) => {
          const selectedCn = allConsignments.filter(
            (x) => x.reference_number === record.reference_number,
          );
          const consignment_count = selectedCn?.length
            ? selectedCn[0].consignment_count
            : null;
          return (
            <div className={classes.status}>
              {consignment_count ? consignment_count : null}
            </div>
          );
        };
      }
      if (item.column_id === 'actions') {
        itemToPush['title'] = '';
        itemToPush['width'] = 70;
        itemToPush['render'] = (text, record) => {
          return (
            <div style={{ textAlign: 'center' }}>
              <CloseOutlined
                style={{ color: 'red' }}
                onClick={() => handleDeleteTasksClick(record)}
              />
            </div>
          );
        };
      }
      if (item.column_id === 'task_type') {
        itemToPush['render'] = (text, record) => {
          return getTaskTypePrettyName(text);
        };
      }
      columnData.push(itemToPush);
    });
    return columnData;
  };
  const generateCnColumnsToshow = () => {
    const columnData: any = [];
    const indexList = customTripColumns;
    indexList.forEach((item) => {
      const itemToPush = {};
      itemToPush['title'] = item.pretty_name;
      itemToPush['dataIndex'] = item.column_id;
      itemToPush['key'] = item.column_id;
      itemToPush['width'] = 100;

      if (item.column_id === 'serial_number') {
        itemToPush['width'] = 80;
        itemToPush['render'] = (text, record, index) => index + 1;
      }
      if (item.column_id === 'reference_number') {
        itemToPush['render'] = (text, record) => record;
      }
      if (item.column_id === 'status') {
        itemToPush['width'] = 150;
        itemToPush['render'] = (text, record) => {
          const selectedCn = allConsignments.filter(
            (x) => x.reference_number === record,
          );
          const status = selectedCn?.length ? selectedCn[0].status : null;
          return (
            <div className={classes.status}>
              {status ? getRetailConsignmentStatus(status) : null}
            </div>
          );
        };
      }
      if (item.column_id === 'actions') {
        itemToPush['title'] = '';
        itemToPush['width'] = 70;
        itemToPush['render'] = (text, record) => {
          return (
            <div style={{ textAlign: 'center' }}>
              <CloseOutlined
                style={{ color: 'red' }}
                onClick={() => handleDeleteCNClick(record)}
              />
            </div>
          );
        };
      }
      columnData.push(itemToPush);
    });
    return columnData;
  };

  const renderHeaderText = () => {
    const taskType = tripIsConsolidatedPlanningType ? 'tasks' : 'consignments';
    const countCheck = tripIsConsolidatedPlanningType
      ? tableList?.length
      : cnList?.length;
    switch (tripCreationType) {
      case 'all':
        return 'All ' + taskType + ' are ';
      case 'filtered':
        return 'All filtered ' + taskType + ' are ';
      default:
        return `${countCheck || 'No'} ${
          countCheck > 1 ? taskType + ' are' : taskType + ' is'
        }`;
    }
  };

  const handlePrimaryTripChange = (e) => {
    e.target.checked ? setPrimaryTrip(true) : setPrimaryTrip(false);
  };

  const handleFtlTripCheck = (e) => {
    setIsFTL(e.target.checked);
  };

  if (showErrorModal) {
    return (
      <ScanErrorModal
        isVisible={showErrorModal}
        data={piecesList.missingPieces}
        onModalClose={() => setShowErrorModal(false)}
      />
    );
  }

  const handleRiderName = (e) => {
    setRiderName(e.target.value);
  };

  const handleRiderPhone = (e) => {
    setRiderPhone(e.target.value);
  };

  const handleVehicleNumber = (e) => {
    setVehicleNumber(e.target.value);
  };

  const renderSelectRider = () => {
    return <Row className={classes.customTripModal}>
      <Col span={6}>Rider:</Col>
      <Col span={18}>{getRiderVehicleSelect('rider')}</Col>
    </Row>;
  };

  const renderAddRider = () => {
    return <>
      <Row className={classes.customTripModal}>
        <Col span={6}>Rider Name:</Col>
        <Col span={18}>
          <Input
            placeholder={'Rider Name'}
            onChange={handleRiderName}
            value={riderName}
          />
        </Col>
      </Row>
      <Row className={classes.customTripModal}>
        <Col span={6}>Rider Phone:</Col>
        <Col span={18}>
          <Input
            placeholder={'Rider Phone'}
            onChange={handleRiderPhone}
            value={riderPhone}
          />
        </Col>
      </Row>
      <Row className={classes.customTripModal}>
        <Button onClick={() => {
          setAddRider(false);
          setRiderName(null);
          setRiderPhone(null);
        }}>
          Select Rider
        </Button>
      </Row>
    </>;
  };

  const renderAddVehicle = () => {
    return <>
      <Row className={classes.customTripModal}>
        <Col span={6}>Vehicle Registration Number:</Col>
        <Col span={18}>
          <Input
            placeholder={'Vehicle Registration Number'}
            onChange={handleVehicleNumber}
            value={vehicleNumber}
          />
        </Col>
      </Row>
      <Row className={classes.customTripModal}>
        <Button onClick={() => {
          setAddVehicle(false);
          setVehicleNumber(null);
        }}>
          Select Vehicle
        </Button>
      </Row>
    </>;
  };

  const renderSelectVehicle = () => {
    return allowVehicleSelect ? (
      <React.Fragment>
        <Row className={classes.customTripModal}>
          <Col span={6}>Vehicle:</Col>
          <Col span={18}>{getRiderVehicleSelect('vehicle')}</Col>
        </Row>
        <Row className={classes.customTripModal}>
          <Col>
            <Checkbox onChange={handleChangeVehicle}>
              Pick Vehicle from Rider-Vehicle Mapping
            </Checkbox>
          </Col>
        </Row>
      </React.Fragment>
    ) : <></>;
  };

  return (
    <Modal
      width={500}
      style={{ top: 40 }}
      title="Custom Trip Creation"
      confirmLoading={confirmLoading}
      maskClosable={false}
      okText="Create"
      visible={isVisible}
      onCancel={() => handleModalClose(false)}
      onOk={handleModalOk}
      destroyOnClose
    >
      {tripCreationType === 'selected' ? (
        <Row>
          {!hideConsignmentRequest && (
            <Row style={{ alignItems: 'center' }}>
              <Col span={7}>Consignment No.</Col>
              <Col span={17}>
                <div className={classes.selectStyle}>
                  <Input
                    addonAfter={
                      validationLoader ? <LoadingOutlined /> : <ScanOutlined />
                    }
                    placeholder={'Add consignments'}
                    onChange={onConsignmentChange}
                    onPressEnter={handleAddCNClick}
                    value={consignment}
                  />
                </div>
              </Col>
            </Row>
          )}
          {tripIsConsolidatedPlanningType && !hidePickupRequest ? (
            <Row className={classes.customTripModal}>
              <Col span={6}>Pickup Request/Task:</Col>
              <Col span={18}>
                <div className={classes.selectStyle}>
                  <Input
                    placeholder={'Add Pickup Request/Task'}
                    onChange={onTaskChange}
                    onPressEnter={handleTaskAddCNClick}
                    value={task}
                  />
                </div>
              </Col>
            </Row>
          ) : null}
        </Row>
      ) : (
        <div>{renderHeaderText()} selected for custom trip creation.</div>
      )}
      {addRider ? renderAddRider() : renderSelectRider()}
      {enableVehicleApprovalWorkflow && (currRider || currVehicle) && (fleetTypeRider === 'MARKET') && (
        <Row className={classes.customTripModal}>
        <Col span={6}>Request Number:</Col>
        <Col span={18}>{getVehicleRequestSelect()}</Col>
      </Row>
      )}
      {!addRider && enableOptimizeTrip &&
        !tripIsConsolidatedPlanningType &&
        showTripStartTimeForOptimisation &&
        tripCreationType === 'selected' && (
          <>
            <Row className={classes.customTripModal}>
              <Col>
                <Checkbox checked={optimizeTrip} onChange={handleOptimizeTrip}>
                  Optimize Trip
                </Checkbox>
              </Col>
            </Row>
            {optimizeTrip && (
              <Row className={classes.customTripModal}>
                <Col style={{ paddingLeft: '20px' }}>
                  <Checkbox
                    checked={isAppendDropped}
                    onChange={handleAppendDropped}
                  >
                    Append dropped CNs
                  </Checkbox>
                </Col>
              </Row>
            )}
            {optimizeTrip && (
              <Row className={classes.customTripModal}>
                {timeSlots?.length > 0 && (
                  <Col className={classes.selectContainer}>
                    <span>CN Time Slot</span>
                    <Select
                      allowClear
                      className={classes.selectStyle}
                      placeholder="Select Time Slot"
                      onChange={onTimeSlotChange}
                      suffixIcon={<CalendarOutlined />}
                    >
                      {generateTimeSlotsOptionsFromList(timeSlots)}
                    </Select>
                  </Col>
                )}
                <Col span={6}>Trip Start Time:</Col>
                <Col span={18}>
                  <DatePicker
                    disabledDate={disabledDate}
                    placeholder="Select Date"
                    defaultValue={moment()}
                    showTime
                    onChange={handleTripStartTime}
                  />
                </Col>
              </Row>
            )}
            {optimizeTrip && (
              <Row className={classes.customTripModal}>
                <Col span={8}>Trip Processing Time:</Col>
                <Col span={5}>
                  <InputNumber
                    min={1}
                    defaultValue={optimizeTripProcessingTime}
                    onChange={handleTripProcessingTime}
                  />
                </Col>
                <Col span={5}>-min</Col>
              </Row>
            )}
          </>
        )}
      {addVehicle ? renderAddVehicle() : renderSelectVehicle()}
      {customTripBasedOnVehicleMake && customTripBasedOnVendorAndVehicleMake && (
        <React.Fragment>
          <Row className={classes.customTripModal}>
            <Col span={6}>Vehicle Vendor:</Col>
            <Col span={18}>{getRiderVehicleSelect('vehicleVendor')}</Col>
          </Row>
        </React.Fragment>
      )}
      {customTripBasedOnVehicleMake && (
        <React.Fragment>
          <Row className={classes.customTripModal}>
            <Col span={6}>Vehicle Make:</Col>
            <Col span={18}>{getRiderVehicleSelect('vehicleMake')}</Col>
          </Row>
        </React.Fragment>
      )}
      {enableScheduledRider && (
        <Row className={classes.customTripModal}>
          <Col>
            <Checkbox onChange={handleScheduleTrip}>
              Schedule this trip
            </Checkbox>
          </Col>
        </Row>
      )}
      {scheduleTrip && (
        <Row className={classes.customTripModal}>
          <Col span={6}>Date: </Col>
          <Col span={18}>
            <DatePicker
              disabledDate={disabledDate}
              placeholder="Select Date"
              format={'DD/MM/YYYY'}
              onChange={handleDateChange}
            />
          </Col>
        </Row>
      )}
      {showPrimaryTripOption && (
        <Row className={classes.customTripModal}>
          <Col>
            <Checkbox onChange={handlePrimaryTripChange}>
              Is Primary Trip
            </Checkbox>
          </Col>
        </Row>
      )}
      {manualFreightType ? (
        <Row className={classes.customTripModal}>
          <Col>
            <Checkbox onChange={handleFtlTripCheck}>Is FTL trip</Checkbox>
          </Col>
        </Row>
      ) : null}
      {enablePieceScanning && (
        <div className={classes.piecesDetailContainer}>
          <div>
            Parent CN Selected:{' '}
            <b>
              {cnList.length} / {cnList.length + piecesList.partialCN.length}
            </b>
          </div>
          <div>
            Pieces Scanned:{' '}
            <b>
              {piecesList.partialPieces.length +
                piecesList.completePieces.length}
            </b>
          </div>
        </div>
      )}
      {tripCreationType === 'selected' && (
        <div className={classes.tableContainer}>
          {tripIsConsolidatedPlanningType ? (
            <Table
              locale={{ emptyText: 'No data' }}
              pagination={false}
              rowKey={(record: any) => record['reference_number']}
              dataSource={tableList}
              columns={generateConsolidatedColumnsToshow()}
            />
          ) : (
            <Table
              locale={{ emptyText: 'No data' }}
              pagination={false}
              rowKey={(record: any) => record['reference_number']}
              dataSource={cnList}
              columns={generateCnColumnsToshow()}
            />
          )}
        </div>
      )}
      {isFailureModalVisible ? (
        <FailureArrayHandler
          isVisible={isFailureModalVisible}
          failureColumns={tripIsConsolidatedPlanningType ? failureColumnsConsolidatedPlanning: failureColumns}
          failureArray={failureArray}
          onModalClose={handleFailureModalClose}
        />
      ) : null}
      {isRescheduledCNConfirmationgModalVisible ? (
        <ConfirmationModal
          title={'Confirmation'}
          dataArray={rescheduledCNArray}
          isVisible={isRescheduledCNConfirmationgModalVisible}
          onModalClose={handleRescheduledCNConfirmationgModalClose}
          showFooterSection={true}
          footerText={'Do you want to continue?'}
          primaryButtonText={'Yes'}
          onClickPrimaryButton={handleRescheduledCNConfirmation}
          secondaryButtonText={'No'}
          onClickSecondaryButton={handleRescheduledCNConfirmationgModalClose}
        ></ConfirmationModal>
      ) : null}
    </Modal>
  );
};

const CustomTripCreationModalStyled = withStyles(styles, { injectTheme: true })(
  CustomTripCreationModal,
);

const mapStateToProps = ({ masterData }) => {
  return {
    allowVehicleSelect: masterData.enable_worker_vehicle_separation || false,
    optimizeTripProcessingTime:
      masterData.ops_dashboard_config?.parts_to_show?.flm_trip_config
        ?.optimisation_via_custom_trip?.trip_processing_time,
    enableOptimizeTrip:
      masterData.ops_dashboard_config?.parts_to_show?.flm_trip_config
        ?.optimisation_via_custom_trip?.enable,
    enableScheduledRider:
      masterData.retail_features_config?.enable_scheduled_riders,
    showPrimaryTripOption:
      masterData.retail_features_config?.optimise_secondary_trips,
    hidePickupRequest:
      masterData.ops_dashboard_config?.parts_to_show?.flm_trip_config
        ?.hide_pickup_request_addition_on_custom_trip,
    hideConsignmentRequest:
      masterData.ops_dashboard_config?.parts_to_show?.flm_trip_config
        ?.hide_consignment_addition_on_custom_trip,
    enablePieceScanning:
      masterData.ops_dashboard_config?.parts_to_show?.flm_trip_config
        ?.enable_piece_level_scanning,
    enableScheduledTripOnScheduledDate:
      masterData?.retail_features_config
        ?.enable_scheduled_trip_on_scheduled_date || false,
    customTripBasedOnVehicleMake:
      masterData.ops_dashboard_config?.parts_to_show?.flm_trip_config
        ?.custom_trip_based_on_vehiclemake || false,
    customTripBasedOnVendorAndVehicleMake:
      masterData.ops_dashboard_config?.parts_to_show?.flm_trip_config
        ?.custom_trip_allow_vendor_with_vehiclemake || false,
    manualFreightType:
      masterData.ops_dashboard_config?.parts_to_show?.flm_trip_config
        ?.enable_manual_freight_type || false,
    showTripStartTimeForOptimisation:
      masterData?.ops_dashboard_config?.parts_to_show?.etaConfig
        ?.input_start_time_for_optimisation || false,
    enableVehicleApprovalWorkflow: masterData?.enable_vehicle_request_approval,
    enableVehicleMakeVendorMapping:
    masterData?.ops_dashboard_config?.config
      ?.enable_vehicle_make_mapping || false,
    sendVehicleRequisitionRequest: masterData.send_vehicle_requisition_request || false,
    allowAdditionToStartTrip: masterData.allow_addition_to_started_trip || false,
  };
};

export default connect(mapStateToProps)(CustomTripCreationModalStyled);
