import { morphism } from 'morphism';
import { BUSINESS_BOOKING_API_URL } from '@src/globals';
import { POST, GET } from '@api/apiHandler';
import {
  FETCH_TRIP_CHARGES,
  FETCH_TRIP_CHARGES_DATA,
  UPDATE_TRIP_CHARGES,
  DELETE_SINGLE_TRIP_CHARGE,
  DELETE_ALLOCATION_RULES,
  FETCH_ALLOCATION_ENGINE_PLAYGROUND_METADATA,
  GET_PREDEFINED_CHARGES_OR_ATTRIBUTES,
  VALIDATE_AND_UPDATE_ALLOCATION_ENGINE_METADATA,
} from '@api/routes';
import {
  requestModelRateMaster,
  responseModelSetupMaster,
} from '@model/consignmentView';

// Rate Masters
export const fetchTripChargesData = async (params = null) => {
  if(params?.pagination){
    params.pagination.lastId = params.pagination.nextOrPrev === 'next' ? params.pagination.lastItemIdNext : params.pagination.lastItemIdPrev;
    params.pagination.lastPrimarySortFieldValue = params.pagination.nextOrPrev === 'next' ? params.pagination.lastSortedColumnValueNext : params.pagination.lastSortedColumnValuePrev;
  }
  const response = await GET(
    `${BUSINESS_BOOKING_API_URL}${FETCH_TRIP_CHARGES_DATA}`,
    morphism(requestModelRateMaster, params),
  );
  return {
    ...response,
    data: morphism(responseModelSetupMaster, response?.data),
  };
};

export const fetchTripCharges = async (params) => {
  return GET(`${BUSINESS_BOOKING_API_URL}${FETCH_TRIP_CHARGES}`, params);
};

export const updateTripCharges = async (body) => {
  return POST(`${BUSINESS_BOOKING_API_URL}${UPDATE_TRIP_CHARGES}`, body);
};

export const deleteSingleTripCharge = async (chargeId) => {
  return POST(`${BUSINESS_BOOKING_API_URL}${DELETE_SINGLE_TRIP_CHARGE}`, {
    id: chargeId,
    metadataType: 'trip_pricing',
  });
};

export const deleteBulkTripCharge = async (chargeIdArray) => {
  return POST(`${BUSINESS_BOOKING_API_URL}${DELETE_ALLOCATION_RULES}`, {
    idArray: chargeIdArray,
    metadataType: 'trip_pricing',
  });
};

export const fetchPricingPlaygroundMetadata = async (params) => {
  return GET(`${BUSINESS_BOOKING_API_URL}${FETCH_ALLOCATION_ENGINE_PLAYGROUND_METADATA}`, params);
};

export const getPredefinedChargesOrAttributes = async (params) => {
  return GET(`${BUSINESS_BOOKING_API_URL}${GET_PREDEFINED_CHARGES_OR_ATTRIBUTES}`, params);
};

export const validateAndUpdatePricingMetadata = async (metadataStateObject, metadataType) => {
  return POST(`${BUSINESS_BOOKING_API_URL}${VALIDATE_AND_UPDATE_ALLOCATION_ENGINE_METADATA}`, {
    metadataStateObject,
    metadataType,
  });
};
