import * as moment from 'moment';
import {
  TRIP_ENABLE_LOADING,
  TRIP_DISABLE_LOADING,
  TRIP_UPDATE_FILTER,
  TRIP_RESET_FILTER,
  TRIP_PAGINATION,
  TRIP_RESET_PAGINATION,
  TRIP_UPDATE_DATA,
} from '../actions/constants';

const defaultState = {
  isLoading: false,
  tripData: [],
  appliedFilters: {
    searchType: 'tripReferenceNumber',
    searchValue: '',
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    dateFilterType: 'updated_at',
    status: null,
    organisation_reference_number: null,
  },
  // More Filters component will always recive data from temp
  pagination: {
    currentPageNumber: 1,
    nextOrPrev: 'first',
    limitPerPage: 100,
  },
};

export default function (state: any = defaultState, action) {

  switch (action.type) {

    case TRIP_UPDATE_DATA: {
      return {
        ...state,
        // Data of all columns will be here
        // Need to filter on the basis of columns_to_display
        tripData: action.data.page_data,
        pagination: {
          ...state.pagination,
          lastItemIdPrev: action.data ? action.data.last_trip_id_prev : 0,
          isNextPresent: action.data ? action.data.is_next_present : null,
          lastItemIdNext: action.data ? action.data.last_trip_id_next : 0,
          lastDateFieldValuePrev: action.data ? action.data.last_date_field_value_prev : null,
          lastDateFieldValueNext: action.data ? action.data.last_date_field_value_next : null,
        },
      };
    }
    case TRIP_ENABLE_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case TRIP_DISABLE_LOADING: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case TRIP_UPDATE_FILTER: {
      return {
        ...state,
        appliedFilters: {
          ...state.appliedFilters,
          ...action.data,
        },
      };
    }
    case TRIP_RESET_FILTER: {
      return {
        ...state,
        appliedFilters: defaultState.appliedFilters,
      };
    }
    case TRIP_PAGINATION: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.data,
        },
      };
    }
    case TRIP_RESET_PAGINATION: {
      return {
        ...state,
        pagination: defaultState.pagination,
      };
    }
    default: return state;

  }
}
