import Icon from '@ant-design/icons';
import * as React from 'react';

const AppUsersSvg = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Setup_Tooltip" transform="translate(-16.000000, -458.000000)">
                <g id="Group-6" transform="translate(0.000000, 58.000000)">
                    <g id="App_Users" transform="translate(16.000000, 400.000000)">
                        <rect id="Rectangle-6" opacity="0.1" x="0" y="0" width="24" height="24"></rect>
                        <g transform="translate(3.000000, 2.000000)" fill="#666666">
                            <path d="M2.03288,1.64613 C1.93031,1.64613 1.83194,1.68688 1.75941,1.75941 C1.68688,1.83194 1.64614,1.93031 1.64614,2.03288 L1.64614,17.79019 C1.64614,17.89279 1.68688,17.99109 1.75941,18.06369 C1.83194,18.13619 1.93031,18.17689 2.03288,18.17689 L9.5297,18.17689 C9.6323,18.17689 9.7307,18.13619 9.8032,18.06369 C9.8757,17.99109 9.9165,17.89279 9.9165,17.79019 L9.9165,17.35389 C9.9165,16.89929 10.285,16.53079 10.7396,16.53079 C11.1941,16.53079 11.5626,16.89929 11.5626,17.35389 L11.5626,17.79019 C11.5626,18.32929 11.3485,18.84639 10.9672,19.22769 C10.586,19.60889 10.0689,19.82309 9.5297,19.82309 L2.03288,19.82309 C1.49373,19.82309 0.97666,19.60889 0.59542,19.22769 C0.21418,18.84639 0,18.32929 0,17.79019 L0,2.03288 C0,1.49372 0.21418,0.97665 0.59542,0.59541 C0.97666,0.21417 1.49373,0 2.03288,0 L9.5297,0 C9.7967,0 10.0611,0.05258 10.3077,0.15474 C10.5543,0.2569 10.7784,0.40664 10.9672,0.59541 C11.156,0.78418 11.3057,1.00829 11.4079,1.25493 C11.51,1.50157 11.5626,1.76592 11.5626,2.03288 L11.5626,3.30219 C11.5626,3.75676 11.1941,4.12526 10.7396,4.12526 C10.285,4.12526 9.9165,3.75676 9.9165,3.30219 L9.9165,2.03288 C9.9165,1.98209 9.9065,1.9318 9.8871,1.88488 C9.8676,1.83795 9.8391,1.79532 9.8032,1.75941 C9.7673,1.7235 9.7247,1.69501 9.6777,1.67557 C9.6308,1.65614 9.5805,1.64613 9.5297,1.64613 L2.03288,1.64613 Z" id="Path"></path>
                            <path d="M4.13525,3.30219 C4.13525,2.84762 4.50375,2.47912 4.95832,2.47912 L6.60446,2.47912 C7.05903,2.47912 7.42753,2.84762 7.42753,3.30219 C7.42753,3.75676 7.05903,4.12526 6.60446,4.12526 L4.95832,4.12526 C4.50375,4.12526 4.13525,3.75676 4.13525,3.30219 Z" id="Path"></path>
                            <polygon id="Path" fillRule="nonzero" points="6.81263 15.28129 4.75 15.28129 4.75 17.34389 6.81263 17.34389"></polygon>
                            <path d="M13.2187,6.18788 C10.9349,6.18788 9.0835,8.03929 9.0835,10.32309 C9.0835,12.60689 10.9349,14.45819 13.2187,14.45819 C15.5025,14.45819 17.3539,12.60689 17.3539,10.32309 C17.3539,8.03929 15.5025,6.18788 13.2187,6.18788 Z M7.43738,10.32309 C7.43738,7.13013 10.0258,4.54174 13.2187,4.54174 C16.4116,4.54174 19,7.13013 19,10.32309 C19,13.51599 16.4116,16.10439 13.2187,16.10439 C10.0258,16.10439 7.43738,13.51599 7.43738,10.32309 Z" id="Shape"></path>
                            <path d="M11.8509,7.92129 C12.2712,7.64312 12.7642,7.49478 13.2683,7.49478 C13.7724,7.49478 14.2654,7.64312 14.6857,7.92129 C15.1061,8.19949 15.4353,8.59529 15.6324,9.05929 C15.6756,9.16109 15.6978,9.27049 15.6978,9.38099 L15.6978,10.70979 C15.6978,10.72299 15.6975,10.73619 15.6969,10.74939 C15.6719,11.26869 15.5234,11.77099 15.268,12.21619 L16.7669,13.11839 C17.1563,13.35289 17.282,13.85859 17.0476,14.24809 C16.8132,14.63749 16.3074,14.76319 15.9179,14.52879 L13.8751,13.29909 C13.6689,13.17499 13.5273,12.96669 13.4877,12.72929 L13.4282,12.37229 C13.3867,12.12309 13.4619,11.86869 13.6321,11.68219 C13.8819,11.40849 14.0299,11.05749 14.0517,10.68799 L14.0517,9.57649 C13.9819,9.46419 13.8885,9.36769 13.7773,9.29409 C13.6263,9.19419 13.4493,9.14089 13.2683,9.14089 C13.0873,9.14089 12.9103,9.19419 12.7593,9.29409 C12.6481,9.36769 12.5547,9.46419 12.4849,9.57649 L12.4849,10.69629 C12.4975,11.04269 12.6198,11.37609 12.8342,11.64859 C12.9484,11.79369 13.0105,11.97299 13.0105,12.15759 L13.0105,12.59399 C13.0105,12.88259 12.8592,13.15019 12.6119,13.29909 L10.5691,14.52879 C10.1796,14.76319 9.6739,14.63749 9.4394,14.24809 C9.205,13.85859 9.3307,13.35289 9.7201,13.11839 L11.2368,12.20549 C10.9919,11.75529 10.8545,11.25159 10.8391,10.73429 C10.8389,10.72609 10.8388,10.71799 10.8388,10.70979 L10.8388,9.38099 C10.8388,9.27049 10.861,9.16109 10.9042,9.05929 C11.1013,8.59529 11.4305,8.19949 11.8509,7.92129 Z" id="Path"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    );
};

export const AppUsersIcon = props => (
  <Icon component={AppUsersSvg} {...props} />
);
