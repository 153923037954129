import { getErrorMessage, getAuthenticationHeaders } from './utils';
import { FETCH_LTL_TRACKING, CREATE_LTL_ADDRESS,
  FETCH_LTL_DETAILS, FETCH_LTL_MULTIPLE_UPLOAD, DOWNLOAD_LTL_TRACKING,
  GET_DOWNLOAD_REQUESTS, EDIT_LTL_ADDRESS, FETCH_LTL_ADDRESS, CREATE_LTL_TRACKING,
  SEARCH_CUSTOMER_LTL,
  EDIT_LTL_SHIPMENT,
  FETCH_LTL_SUMMARY_METRICS_DATA,
  FETCH_LTL_SUMMARY_METRICS,
  POLL_LTL_TRACKING,
  LTL_BOOK_MULTIPLE_SHIPMENTS,
  LTL_GET_PRINT_LABEL_STREAM,
  LTL_POST_COMMENTS,
  LTL_EDIT_CONSIGNMENT,
  LTL_FETCH_NDR_SHIPPER_NEXT_STEPS,
  LTL_GET_ATTEMPT_HISTORY,
  LTL_EDIT_CONSIGNMENT_TAGS,
  EDIT_CN_NDR,
  RESCHEDULE_NDR,
  MARK_RETRY_NDR,
  MARK_RTO_NDR,
 } from './routes';
import apiProvider from './provider';
import { GET, POST } from './apiHandler';

import { LTL_BASE_URL, API_BASE_URL } from '../globals';

export const applyFiltersToData = async (params: any) => {
  try {
    const ltlTrackingResponse = await apiProvider.post(
       `${LTL_BASE_URL}${FETCH_LTL_TRACKING}`, { ...params } , {
         headers: await getAuthenticationHeaders(),
       });
    return {
      isSuccess: true,
      data: ltlTrackingResponse.data.data,
    };

  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const shipperNextSteps = async (params: any) => {
  try {
    const ltlTrackingResponse = await apiProvider.post(
       `${API_BASE_URL}${LTL_FETCH_NDR_SHIPPER_NEXT_STEPS}`, { ...params } , {
         headers: await getAuthenticationHeaders(),
       });
    return {
      isSuccess: true,
    };

  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return {
      errorMessage,
      isSuccess: false,
    };
  }

};

export const fetchLTLDetails = async (referenceNumber: any) => {
  try {
    const params =  { referenceNumber };
    const response = await apiProvider.get(
      `${API_BASE_URL}${FETCH_LTL_DETAILS}`, { params,
        headers: await getAuthenticationHeaders() });

    return {
      isSuccess: true,
      data: response.data.data,
    };
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const deleteSavedAddressApi = async (address: any) => {
  try {
    // const savedAddressResponse =
    // await apiProvider.post(`${SHIPPER_BASE_URL}${DELETE_LTL_SAVED_ADDRESS}`,
    // { id: address.id }, { headers: await getAuthenticationHeaders()})
    return {
      isSuccess: true,
    };
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const fetchSavedAddressApi = async (id: any, type:string) => {
  try {
    const addressResponse =
      await apiProvider.post(`${LTL_BASE_URL}${FETCH_LTL_ADDRESS}`,
        { type, clientId: id }, { headers: await getAuthenticationHeaders() });
    return {
      isSuccess: true,
      data: addressResponse.data.data,
    };
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const editAddressApi = async (address: any) => {
  try {
    const response = await apiProvider.post(`${LTL_BASE_URL}${EDIT_LTL_ADDRESS}`,
      address, { headers: await getAuthenticationHeaders() });
    return {
      isSuccess: true,
    };
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const createAddressApi = async (address: any) => {
  try {
    const savedAddressResponse =
      await apiProvider.post(`${LTL_BASE_URL}${CREATE_LTL_ADDRESS}`,
      address, { headers: await getAuthenticationHeaders() });
    return {
      isSuccess: true,
    };
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const createSingleShipmentApi = async (params: any) => {
  try {
    const singleShipmentCreateResponse = await apiProvider.post(
      `${LTL_BASE_URL}${CREATE_LTL_TRACKING}`, params, {
        headers: await getAuthenticationHeaders(),
      });
    return {
      isSuccess: true,
    };
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const editSingleShipmentApi = async (params: any) => {
  try {
    const response = await apiProvider.post(
      `${LTL_BASE_URL}${EDIT_LTL_SHIPMENT}`, params, {
        headers: await getAuthenticationHeaders(),
      });
    return {
      isSuccess: true,
    };
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const deleteShipment = async (referenceNumber: any) => {
  try {
    // const deletedShipmentReaponse = await apiProvider.
    return {
      isSuccess: true,
    };
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const bulkUploadShipmentApi = async (params: any) => {
  try {
    const response = await apiProvider.post(
        `${LTL_BASE_URL}${FETCH_LTL_MULTIPLE_UPLOAD}`, { rows : params.shipmentDataList }, {
          headers: await getAuthenticationHeaders(),
        });
    if (response.data.hasOwnProperty('data') && response.data.data.hasOwnProperty('failureArray')) {
      return {
        isSuccess: true,
        data: response.data.data.failureArray,
      };
    }
    return {
      isSuccess: true,
      data: [],
    };
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const bulkBookShipmentApi = async (params: any) => {
  try {
    const response = await apiProvider.post(
        `${LTL_BASE_URL}${LTL_BOOK_MULTIPLE_SHIPMENTS}`, { rows : params.shipmentDataList }, {
          headers: await getAuthenticationHeaders(),
        });
    if (response.data.hasOwnProperty('data') && response.data.data.hasOwnProperty('failureArray')) {
      return {
        isSuccess: true,
        data: response.data.data.failureArray,
      };
    }
    return {
      isSuccess: true,
      data: [],
    };
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const createDownloadRequestApi = async (params: any) => {
  try {
    const response = await apiProvider.post(
      `${LTL_BASE_URL}${DOWNLOAD_LTL_TRACKING}`, params,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      isSuccess: true,
    };
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const fetchDownloadRequestApi = async () => {
  try {
    const response = await apiProvider.get(`${LTL_BASE_URL}${GET_DOWNLOAD_REQUESTS}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      isSuccess: true,
      data: response.data.data,
    };
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const searchCustomer = async (queryString: any) => {
  try {
    const response = await apiProvider.get(
      `${LTL_BASE_URL}${SEARCH_CUSTOMER_LTL}?searchString=${queryString}`, {
        headers: await getAuthenticationHeaders() });
    return {
      isSuccess: true,
      data: response.data,
    };
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const getSummaryMetricsView = async () => {
  try {
    const response = await apiProvider.get(
      `${LTL_BASE_URL}${FETCH_LTL_SUMMARY_METRICS}`, {
        headers: await getAuthenticationHeaders() });
    return {
      isSuccess: true,
      data: response.data.data,
    };
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getSummaryMetricsData = async (filters, metrics) => {
  try {
    const response = await apiProvider.post(
      `${LTL_BASE_URL}${FETCH_LTL_SUMMARY_METRICS_DATA}`, { filters, metrics } , {
        headers: await getAuthenticationHeaders() });
    return {
      isSuccess: true,
      data: response.data.data,
    };
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getStatus = async (data) => {
  try {
    const response = await apiProvider.post(
      `${LTL_BASE_URL}${POLL_LTL_TRACKING}`, { referenceNumbers:data } , {
        headers: await getAuthenticationHeaders() });
    return {
      isSuccess: true,
      data: response.data.data,
    };
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getPrintLabel = async (referenceNumbers, isSmall?: Boolean, useCodeFreeLabel?: Boolean) => {
  try {
    const response = await apiProvider.post(
      `${LTL_BASE_URL}${LTL_GET_PRINT_LABEL_STREAM}`, { referenceNumbers,
        isSmall: isSmall === false ? false : true,
        useCodeFreeLabel: useCodeFreeLabel === true ? true: false,
        printPod: true } , {
          headers: await getAuthenticationHeaders(),
          responseType:'blob',
        });
    return {
      isSuccess: true,
      data: response.data,
    };
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const postComments = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${LTL_POST_COMMENTS}`, body,
      {
        headers: await getAuthenticationHeaders(),
      });
    return {
      isSuccess: true,
      data: response.data.data,
    };
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const postCarrierRemarks = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${LTL_EDIT_CONSIGNMENT}`, body,
      {
        headers: await getAuthenticationHeaders(),
      });
    return {
      isSuccess: true,
      data: response.data.data,
    };
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getAttemptHistory = async (referenceNumber) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${LTL_GET_ATTEMPT_HISTORY}=${referenceNumber}`,
      {
        headers: await getAuthenticationHeaders(),
      });
    return {
      isSuccess: true,
      data: response.data.data,
    };
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const assignTag = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${LTL_EDIT_CONSIGNMENT_TAGS}`, body,
      {
        headers: await getAuthenticationHeaders(),
      });
    return {
      isSuccess: true,
      data: response.data.data,
    };
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const rtoNdr = async (body) => {
  return POST(
    `${API_BASE_URL}${MARK_RTO_NDR}`,
    body,
  );
};

export const retryNdr = async (body) => {
  return POST(
    `${API_BASE_URL}${MARK_RETRY_NDR}`,
    body,
  );
};

export const editNdr = async (body) => {
  return POST(
    `${API_BASE_URL}${EDIT_CN_NDR}`,
    body,
  );
};

export const rescheduleNdr = async (body) => {
  return POST(
    `${API_BASE_URL}${RESCHEDULE_NDR}`,
    body,
  );
};
