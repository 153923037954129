import { morphism } from 'morphism';
import { API_BASE_URL } from '@src/globals';
import { POST, GET } from '@api/apiHandler';
import {
  CREATE_NEW_CURRENCY_CONVERSION,
  FETCH_ALL_CURRENCY_CONVERSION,
  UPDATE_CURRENCY_CONVERSION,
  DOWNLOAD_SAMPLE_CSV_CURRENCY_CONVERSION,
  DELETE_SINGLE_CURRENCY_CONVERSION,
  BULK_UPLOAD_CURRENCY_CONVERSION,
} from '@api/routes';
import {
  requestModelSetupMaster,
  responseModelSetupMaster,
  responseSetupMaster,
  responseCurrencyConversionMaster,
} from '@model/consignmentView';

// Currency Conversion Master

export const fetchAllCurrencyConversionList = async (params = {}) => {
  const response = await POST(
    `${API_BASE_URL}${FETCH_ALL_CURRENCY_CONVERSION}`,
    morphism(requestModelSetupMaster, { type: 'currency', ...params }),
  );
  return {
    ...morphism(responseCurrencyConversionMaster, response),
  };
};

export const createNewCurrencyConversion = async (body) => {
  return POST(`${API_BASE_URL}${CREATE_NEW_CURRENCY_CONVERSION}`, {
    type: 'currency',
    data: [body],
  });
};

export const updateCurrencyConversion = async (id, body) => {
  return POST(`${API_BASE_URL}${UPDATE_CURRENCY_CONVERSION}`, {
    type: 'currency',
    data: [body],
  });
};

export const downloadSampleCsvCurrencyConversion = async (params) => {
  return GET(
    `${API_BASE_URL}${DOWNLOAD_SAMPLE_CSV_CURRENCY_CONVERSION}`,
    params,
    'blob',
  );
};

export const deleteSingleCurrencyConversion = async (id) => {
  return POST(`${API_BASE_URL}${DELETE_SINGLE_CURRENCY_CONVERSION}`, {
    type: 'currency',
    data: [id],
  });
};

export const bulkUploadConnections = async (body) => {
  return POST(`${API_BASE_URL}${BULK_UPLOAD_CURRENCY_CONVERSION}`, {
    type: 'currency',
    data: body?.data,
  });
};
