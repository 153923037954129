export const getInfoDetailRoute = (id) => {
  return `/info/${id}`;
};

export const getSetupRoute = () => {
  return '/setup';
};

export const getSummaryMetricsRoute = () => {
  return '/summarymetrics';
};

export const getDetailsRoute = (referenceNumber) => {
  return `/details/${referenceNumber}`;
};
export const getSupplementoryServicesRoute = () => {
  return '/supplementary-services';
};
export const getSupplementoryServicesDetailsRoute = (reference_number = undefined) => {
  return reference_number ? `/supplementary-services/details/${reference_number}` : '';
};

export const getTripTrackingLinkByPlanningId = (id = undefined) => {
  return id ? `/retail/tracking?planningId=${id}` : '/retail/tracking';
};

export const getPickupRequestDetailsRoute = (referenceNumber) => {
  return `/pickup_details/${referenceNumber}`;
};

export const getDownloadsRoute = () => {
  return '/downloads';
};

export const getRetailConsignmentRoute = () => {
  return '/retail/createroute';
};

export const getRetailTripDetailsRoute = (tripId) => {
  return `/retail/trip/details/${tripId}`;
};

export const getIstTripDetailsRoute = (tripId) => {
  return `/tms/ist/details/${tripId}`;
};

export const getRetailTripManagerRoute = () => {
  return '/retail/trip-manager';
};

export const getRetailTripManagerMapEditRoute = () => {
  return '/retail/trip-manager/map-edit';
};

export const getRetailTrackingRoute = (id = undefined) => {
  return id ? `/retail/tracking?tripId=${id}` : '/retail/tracking';
};

export const getRetailERPSyncRoute = () => {
  return '/retail/erp-sync-logs';
};

export const getPrintLabelRequestRoute = () => {
  return '/retail/print-label/requests';
};

export const getDomesticTrackRoute = () => {
  return '/domestic/track';
};

export const getDomesticTrackDetailsRoute = (referenceNumber) => {
  return `/domestic/track/detail/${referenceNumber}`;
};

export const getDomesticSingleShipmentRoute = (referenceNumber) => {
  return `/domestic/track/edit/${referenceNumber}`;
};

export const getDomesticCreateSingleShipmentRoute = () => {
  return '/domestic/track/create';
};

export const getDomesticViewDownloadRoute = () => {
  return '/domestic/track/download/fetch';
};

export const getDomesticCarrierManagementRoute = () => '/domestic/carrierManagement';

export const getCarrierDownloadsRoute = () => {
  return '/dashboard/setup/carrierPartner/carrierAllocationDownloads';
};

export const getTransportSectionDownloadRoute = () => {
  return '/dashboard/setup/transport/consignmentRoute/consignmentRouteDownloads';
};

export const getDomesticCarrierManagementDetailsRoute = carrierId =>
  `/domestic/carrierManagement/detail/${carrierId}`;

export const getLTLAnalyicsRoute = () => '/ltl/analytics';
export const getDomesticCustomerActivityRoute = () => '/customerActivity';
export const getRiderReconciliationRoute = () => '/reconciliation/rider-list';
export const getRiderReconciliationDetails = workerCode =>
  `/reconciliation/reconcile/${workerCode}`;

export const getInvoiceRoute = () => '/invoice';

export const getViewDownloadRequestCustomerActivityRoute = () =>
'/customerActivity/downloads';

export const getAutoAllocateRequestsPageRoute = () => (
  '/retail/requests'
);
export const getRetailAnalyticsRoute = () => {
  return '/retail/analytics';
};

export const getRetailOverviewDashboardRoute = () => {
  return '/retail/overviewDashboard';
};
export const getRetailAnalyticsOverviewRoute = () => {
  return '/retail/overview';
};
export const getRetailSupportPortalRoute = () => {
  return '/retail/supportPortal';
};
export const getSupportPortalDetailsRoute = (referenceNumber) => {
  return `/retail/supportPortal/consignment/${referenceNumber}`;
};

export const getTransactionsHistoryRoute = () => {
  return '/transactionsHistory';
};

export const getRetailStoreViewRoute = () => {
  return '/retail/store-consignments';
};

export const getBankDepositRoute = () => {
  return '/bank-deposit';
};

export const getSupplyRequestRoute = () => {
  return '/supply-request';
};

export const getBankDepositReconcileRoute = () => {
  return '/bank-deposit/reconcile';
};

export const getCmsSlipUploadRoute = () => {
  return '/cms-slip-upload';
};

export const getCmsDepositRoute = () => {
  return '/cms-deposit';
};

export const getBankTransactionsHistoryRoute = () => {
  return '/bank-transaction-history';
};

export const getVendorPayoutRoute = () => {
  return '/vendor-payout';
};

export const getCodSettlementRoute = () => {
  return '/cod-settlement';
};

export const getBranchManagerDashboardRoute = () => {
  return '/branch-manager-dashboard';
};
export const getThirdPartyViewRoute = () => '/third-party';
 // FoodDashboard
export const getFoodDashboardDispatchScreen = () => '/od/dispatch-screen';
export const getFoodLiveTrackingMap = () => '/od/dispatch-screen/map';
export const getFoodDashboardOrderManagement = () => '/od/order-management';
export const getFoodDashboardRiderManagement = () => '/od/rider-management';
export const getFoodDashboardRosterManagement = () => '/od/roster-management';
export const getFoodDashboardCODCashSettlement = () => '/od/cod-settlement';
export const getFoodDashboardAnalytics = () => '/od/analytics';
export const getFoodDashboardReporting = () => '/od/reporting';
export const getFoodDashboardStoreConfiguration = () => '/od/store-configuration';
export const getFoodDashboardNotifications = () => '/od/notifications';
export const getBulkRequestsPageRoute = () => '/od/dispatch-screen/bulk-requests';
export const getFoodDashboardConfigurations = () => '/od/configurations';
export const getFoodDashboardPayout = () => '/od/payout';

export const getOpsDashboardBaseRoute = () => '/dashboard';
export const getTmsDashboardBaseRoute = () => '/tms';
export const getShelfBaseRoute = () => '/shelf';
export const getCrateBaseRoute = () => '/crates';
export const getExpressDashboardBaseRoute = () => '/express';
export const getCRMBaseRoute = () => '/crm';
export const getSlotManagementBaseRoute = () => '/slot-management';
export const getTripReconciliationRoute = (tripId) => {
 return `/retail/trip-reconciliation/${tripId}`;
};
export const getCashRemittanceRoute = () => '/remittance';
export const getV1PagesBaseRoute = () => '/v1';
export const getCRMInOpsRoute = () => '/crm-view/crm';
export const getPrintLabelRequestsRoute = () =>  '/retail/print-label/requests';
export const getReconciliationRoute = (Id) => {
  return `/reconciliation/recon/${Id}`;
};
export const getCustomTripRequestsRoute = () =>  '/retail/custom-trip/requests';
export const getCustomTripBulkDeleteRequestsRoute = () =>  '/retail/custom-trip/bulk/delete/requests';

export const getActivityLogRoute = () =>  '/activity-log';
export const getActivityLogRouteOps = () =>  '/activity-log-v1';
export const getBranchLeadRoleRoute = () => '/branch-lead-role';
export const getCarrierReconciliationRoute = () => '/carrier-reconciliation';
export const getCarrierReconcileRoute = () => {
  return '/carrier-reconciliation/reconcile';
};
export const getCarrierTransactionsHistoryRoute = () => '/carrier-reconciliation/transactions';

export const getCarrierTransactionsHistoryRouteV2 = () => '/ops/carrier-reconciliation/transactions';

export const getTransactionsHistoryRouteV2 = () => '/ops/transactionsHistory';
export const getBankTransactionsHistoryRouteV2 = () => '/ops/bank-transaction-history';
export const getSoftdataFailureHandlerRoute = () => '/softdata/failure-handler';

export const getCallsOverviewRoute = () => '/crm-view/crm/calls-overview';
export const getMyCallsRoute = () => '/crm-view/crm/my-calls';
export const getPayInSlipBaseRoute = () => '/payinslip';
export const getVehicleRequestRoute = () => '/vehicle-request';
export const getVehicleRequestDetails = (request_reference_number = undefined) => {
  return request_reference_number ? `/vehicle-request/details/${request_reference_number}` : '';
};
export const getPriceCalculatorBaseRoute = () => '/price-calculator';

export const getLabelSetupRoute = () => '/label-setup';
export const getLabelLayoutSelection = () => '/label-setup/layout-selection';
export const getLabelPreviewRoute = () => '/label-setup/preview';
export const getLabelCreateRoute = () => '/label-setup/create';

export const getRoutingPlaygroundRoute = () => '/routingPlayground';
export const getTerritoryOptimizationRoute = () => '/territoryOptimization';
export const getDevToolsRoute = () => '/dev-tools';

export const getConsignmentUploadRequestRoute = () => '/uploads';
export const getAppFlowCofigRoute = () => '/dashboard/setup/appFlowConfig';

export const getIntegrationMarketplaceRoute = () => '/integrationMarketplace';
export const getChannelPageRoute = () => '/integrationMarketplace/:integrationId';
export const getIntegrationFormRoute = () => '/integrationMarketplace/:integrationId/form';
export const getIntegrationEditFormRoute = () => '/integrationMarketplace/:integrationId/form/:id';
export const getIntegrationRawFormRoute = () => '/integrationMarketplace/:integrationId/raw-form';

export const getGeocodingPlaygroundRoute = () => '/geocodingPlayground';

export const getPageBuilderDashboardRoute = (pageId) => `/dashboards/${pageId}`;

export const getAnalyticsLandingPage = () => '/home';

export const getFoodDashboardCODCashSettlementV2 = () => '/ops/od/cod-settlement';

export const getLiveDashboardRoute = () => '/retail/live';
