import React from 'react';
import { Tooltip, Typography } from 'antd';

const { Text } = Typography;

export default function TextWithEllipsisAndTooltip({ children, width }) {
  return (
    <Tooltip placement={'top'} title={children}>
      <Text style={{ width }} ellipsis={true}>
        {children}
      </Text>
    </Tooltip>
  );
}
