import * as moment from 'moment';
import {
  CUSTOMER_ACTIVITY_SHIPMENT_VIEW_LOADING_ENABLE,
  CUSTOMER_ACTIVITY_SHIPMENT_VIEW_DATA_FETCHED,
  CUSTOMER_ACTIVITY_SHIPMENT_VIEW_ERROR,
  CUSTOMER_ACTIVITY_SHIPMENT_VIEW_FILTERS_APPLIED,
  CUSTOMER_ACTIVITY_SHIPMENT_VIEW_SET_PAGINATION,
  CUSTOMER_ACTIVITY_SHIPMENT_VIEW_RESET_PAGINATION,
  CUSTOMER_ACTIVITY_SHIPMENT_VIEW_RESET_FILTER,
  CUSTOMER_ACTIVITY_SHIPMENT_VIEW_REMOVE_SEARCH_FILTERS,
  CUSTOMER_ACTIVITY_SHIPMENT_VIEW_SET_COLUMNS,
  CUSTOMER_ACTIVITY_SUMMARY_METRICS_VIEW, CUSTOMER_ACTIVITY_SHIPMENT_SUMMARY_METRICS_DATA,
  CUSTOMER_ACTIVITY_TOGGLE_SHIPMENT_SELECTED_SUMMARY_METRIC,
  CUSTOMER_ACTIVITY_SUMMARY_METRICS_DISABLE_LOADING,
} from '../actions/constants';
import { getLastSortedColumnValue } from '../utils/pagination';

const defaultState = {
  isFeedbackLoading:true,
  feedbackData: null,
  feedbackError: null,
  selectedFilters: {
    fromDate: moment().subtract(7, 'd').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
    timeFilterKey : 'last_feedback_completion_time',
    sortBy : 'last_feedback_completion_time',
    searchBy: 'courier_partner_reference_number',
    searchKey: '',
    customer: [],
    status: [],
    // origin: [],
    // destination: [],
    feedbackStatus:undefined,
    carrier: [],
    conditions:[],
    raiseDispute: undefined,
  },
  pagination: {
    currentPageNo:1,
    resultPerPage: 10,
    nextOrPrev:'first',
    isNextPresent: null,
  },
  selectedSummaryMetrics:[],
  shipmentSummaryMetricsData:[],
  summaryMetricsView: [],
  summaryMetricsLoading:true,
  // column_to_show :[
  //   { column_id:'courier_partner', pretty_name: 'Carrier' },
  //   { column_id:'courier_partner_reference_number', pretty_name: 'AWB #' },
  //   { column_id:'created_at', pretty_name: 'Creation Date' },
  //   // { column_id:'transit_days', pretty_name: 'Transit Days' },
  //   { column_id:'statusAndStatusTime', pretty_name: 'Current Status' },
  //   { column_id:'attempt_count', pretty_name:'Attempt Count' },
  //   { column_id:'false_attempt_count', pretty_name:'False Attempt Count' },
  //   { column_id:'feedback_count', pretty_name:'Feedback Count' },
  //   { column_id:'is_fake_delivery', pretty_name:'Fake Delivery' },
  //   { column_id:'last_feedback_completion_time', pretty_name:'Last Feedback Time' },

  // ],
  // full_column_list: [
  //   { column_id:'courier_partner', pretty_name: 'Carrier' },
  //   { column_id:'courier_partner_reference_number', pretty_name: 'AWB #' },
  //   { column_id:'created_at', pretty_name: 'Creation Date' },
  //   // { column_id:'transit_days', pretty_name: 'Transit Days' },
  //   { column_id:'statusAndStatusTime', pretty_name: 'Status' },
  //   { column_id:'attempt_count', pretty_name:'Attempt Count' },
  //   { column_id:'false_attempt_count', pretty_name:'False Attempt Count' },
  //   { column_id:'feedback_count', pretty_name:'Feedback Count' },
  //   { column_id:'is_fake_delivery', pretty_name:'False Delivery' },
  //   { column_id:'last_feedback_completion_time', pretty_name:'Feedback Time' },
  // ],
  columnsToSort: [
    { column_id:'attempt_count', pretty_name:'Attempt Count' },
    { column_id:'created_at', pretty_name: 'Creation Date' },
    { column_id:'false_attempt_count', pretty_name:'False Attempt Count' },
    { column_id:'feedback_count', pretty_name:'Feedback Count' },
    { column_id:'last_feedback_completion_time', pretty_name:'Feedback Time' },
  ],
};

export default function (state: any = defaultState, action) {
  switch (action.type){
    case CUSTOMER_ACTIVITY_SHIPMENT_VIEW_LOADING_ENABLE: {
      return {
        ...state,
        isFeedbackLoading: true,
        feedbackData: null,
        feedbackError: null,
      };
    }
    case CUSTOMER_ACTIVITY_SHIPMENT_VIEW_SET_PAGINATION : {
      return{
        ...state,
        pagination: {
          ...state.pagination,
          resultPerPage: 10,
          nextOrPrev: action.data.nextOrPrev,
          currentPageNo: action.data.currentPageNo,
        },
      };
    }
    case CUSTOMER_ACTIVITY_SHIPMENT_VIEW_RESET_PAGINATION: {
      return {
        ...state,
        pagination: defaultState.pagination,
      };
    }
    case CUSTOMER_ACTIVITY_SHIPMENT_VIEW_DATA_FETCHED: {
      return {
        ...state,
        isFeedbackLoading: false,
        feedbackData: action.data.data,
        feedbackError: null,
        pagination: {
          ...state.pagination,
          resultPerPage: 10,
          currentPageNo: action.data.page,
          isNextPresent: action.data.isNextAvailable,
          lastSortedColumnValueNext: action.data.lastSortedColumnValueNext,
          lastTrackingRequestIdNext: action.data.lastTrackingRequestIdNext,
          lastSortedColumnValuePrev: action.data.lastSortedColumnValuePrev,
          lastTrackingRequestIdPrev: action.data.lastTrackingRequestIdPrev,
          lastSortedColumnValue: getLastSortedColumnValue(action.data),
          // lastTrackingRequestId: getLastTrackingColumnId(action.data),
        },
      };
    }
    case CUSTOMER_ACTIVITY_SHIPMENT_VIEW_ERROR: {
      return{
        ...state,
        isFeedbackLoading: false,
        feedbackData: null,
        feedbackError: action.data,
      };
    }
    case CUSTOMER_ACTIVITY_SHIPMENT_VIEW_RESET_FILTER: {
      return {
        ...state,
        selectedFilters: defaultState.selectedFilters,
        pagination: defaultState.pagination,
      };
    }
    case CUSTOMER_ACTIVITY_SHIPMENT_VIEW_FILTERS_APPLIED: {
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          ...action.data,

        },
      };
    }
    case CUSTOMER_ACTIVITY_SHIPMENT_VIEW_REMOVE_SEARCH_FILTERS: {
      const { searchBy, searchKey, ...x } =  state.selectedFilters;
      return{
        ...state,
        selectedFilters: x,
      };
    }
    case CUSTOMER_ACTIVITY_SHIPMENT_VIEW_SET_COLUMNS: {
      return {
        ...state,
        column_to_show:action.data,
      };
    }
    case CUSTOMER_ACTIVITY_SHIPMENT_SUMMARY_METRICS_DATA: {
      return{
        ...state,
        shipmentSummaryMetricsData:action.data,
      };
    }
    case CUSTOMER_ACTIVITY_SUMMARY_METRICS_VIEW: {
      return{
        ...state,
        summaryMetricsView:action.data,
      };
    }
    case CUSTOMER_ACTIVITY_TOGGLE_SHIPMENT_SELECTED_SUMMARY_METRIC: {
      let selectedSummaryMetrics:any = null;
      if (action.data === state.selectedSummaryMetrics[0]) {
        selectedSummaryMetrics = [];
      }else {
        selectedSummaryMetrics = [action.data];
      }
      return{
        ...state,
        selectedSummaryMetrics,
      };
    }

    case CUSTOMER_ACTIVITY_SUMMARY_METRICS_DISABLE_LOADING: {
      return {
        ...state,
        summaryMetricsLoading:false,
      };
    }
    case CUSTOMER_ACTIVITY_SHIPMENT_VIEW_RESET_PAGINATION: {
      return {
        ...state,
        pagination: defaultState.pagination,
      };
    }
    default: {
      return state;
    }
  }
}
