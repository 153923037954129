import { morphism } from 'morphism';
import { API_BASE_URL } from '@src/globals';
import { POST, GET } from '@api/apiHandler';
import {
  FETCH_ALL_REASON,
  CREATE_REASON,
  UPDATE_REASON,
  CREATE_PACKAGING_TYPE,
  FETCH_ALL_PACKAGING_TYPE,
  UPDATE_PACKAGING_TYPE,
  CREATE_PARTIAL_DELIVERY_REASON,
  FETCH_ALL_PARTIAL_DELIVERY_REASON,
  UPDATE_PARTIAL_DELIVERY_REASON,
  CREATE_LATE_DELIVERY_REASON,
  FETCH_ALL_LATE_DELIVERY_REASON,
  UPDATE_LATE_DELIVERY_REASON,
  UPDATE_REASON_MASTER,
  FETCH_ALL_SERVICE_TASK,
  FETCH_ONE_SERVICE_TASK,
  CREATE_SERVICE_TASK,
  UPDATE_SERVICE_TASK,
  CHANGE_TWO_FACTOR_DATA,
  GET_MASTER_DATA,
  GET_SORTING_METADATA,
  UPDATE_SORTING_METADATA,
  FETCH_ADDRESS_META_DATA,
  GET_VISIBILITY_CONFIGURATION_DATA,
  UPDATE_VISIBILITY_CONFIGURATION_DATA,
  FETCH_APPFLOW_CONFIG,
  FETCH_APPFLOW_OBJECT_CONFIG,
  CREATE_APPFLOW_OBJECT,
  UPDATE_APPFLOW_OBJECT,
  DELETE_APPFLOW_OBJECT,
  GET_CUSTOMER_LIST,
} from '@api/routes';
import {
  requestModelSetupMaster,
  responseModelSetupMaster,
} from '@model/consignmentView';

//Reason Category Master

export const fetchAllReasons = async (params) => {
  const response = await GET(
    `${API_BASE_URL}${FETCH_ALL_REASON}`,
    morphism(requestModelSetupMaster, { type: 'reason_category', ...params }),
  );
  return {
    ...response,
    data: morphism(responseModelSetupMaster, response?.data),
  };
};

export const fetchAllReasonsforLTL = async (params) => {
  const response = await GET(
    `${API_BASE_URL}${FETCH_ALL_REASON}`,
    morphism(requestModelSetupMaster, { type: 'ltl_ndr_reason', ...params }),
  );
  return {
    ...response,
    data: morphism(responseModelSetupMaster, response?.data),
  };
};

// Packaging Type Master

export const fetchAllPackagingType = async (params = null) => {
  const response = await GET(
    `${API_BASE_URL}${FETCH_ALL_PACKAGING_TYPE}`,
    morphism(requestModelSetupMaster, params),
  );
  return {
    ...response,
    data: morphism(responseModelSetupMaster, response?.data),
  };
};

export const createReason = async (body) => {
  return POST(`${API_BASE_URL}${CREATE_REASON}`, {
    ...body,
    type: 'reason_category',
  });
};

export const updateReason = async (reasonId, body) => {
  return POST(`${API_BASE_URL}${UPDATE_REASON}/${reasonId}/update`, {
    ...body,
    type: 'reason_category',
  });
};

export const createReasonForLTL = async (body) => {
  return POST(`${API_BASE_URL}${CREATE_REASON}`, {
    ...body,
    type: 'ltl_ndr_reason',
  });
};

export const updateReasonForLTL = async (reasonId, body) => {
  return POST(`${API_BASE_URL}${UPDATE_REASON}/${reasonId}/update`, {
    ...body,
    type: 'ltl_ndr_reason',
  });
};

export const createPackagingType = async (body) => {
  return POST(`${API_BASE_URL}${CREATE_PACKAGING_TYPE}`, body);
};

export const updatePackagingType = async (packagingType, body) => {
  return POST(
    `${API_BASE_URL}${UPDATE_PACKAGING_TYPE}/${packagingType}/update`,
    body,
  );
};

// Partial Delivery Reasons

export const fetchAllPartialDeliveryReasons = async (params = null) => {
  const response = await GET(
    `${API_BASE_URL}${FETCH_ALL_PARTIAL_DELIVERY_REASON}`,
    morphism(requestModelSetupMaster, params),
  );
  return {
    ...response,
    data: morphism(responseModelSetupMaster, response?.data),
  };
};

export const createPartialDeliveryReason = async (body) => {
  return POST(`${API_BASE_URL}${CREATE_PARTIAL_DELIVERY_REASON}`, body);
};

export const updatePartialDeliveryReason = async (reasonId, body) => {
  return POST(
    `${API_BASE_URL}${UPDATE_PARTIAL_DELIVERY_REASON}/${reasonId}/update`,
    body,
  );
};

// Late Delivery Reasons

export const fetchAllLateDeliveryReasons = async (params = null) => {
  const response = await GET(
    `${API_BASE_URL}${FETCH_ALL_LATE_DELIVERY_REASON}`,
    morphism(requestModelSetupMaster, params),
  );
  return {
    ...response,
    data: morphism(responseModelSetupMaster, response?.data),
  };
};

export const createLateDeliveryReason = async (body) => {
  return POST(`${API_BASE_URL}${CREATE_LATE_DELIVERY_REASON}`, body);
};

export const updateLateDeliveryReason = async (reasonId, body) => {
  return POST(
    `${API_BASE_URL}${UPDATE_LATE_DELIVERY_REASON}/${reasonId}/update`,
    body,
  );
};

export const fetchReasonMaster = async (params) => {
  const response = await GET(
    `${API_BASE_URL}${FETCH_ALL_REASON}`,
    morphism(requestModelSetupMaster, {
      type: 'courier_partner_change_reasons',
      ...params,
    }),
  );
  return {
    ...response,
    data: morphism(responseModelSetupMaster, response?.data),
  };
};

//Service Type Master
export const fetchAllServiceType = async (params = null) => {
  const response = await GET(
    `${API_BASE_URL}${FETCH_ALL_SERVICE_TASK}`,
    morphism(requestModelSetupMaster, params),
  );
  return {
    ...response,
    data: morphism(responseModelSetupMaster, response?.data),
  };
};

export const createNewReason = async (body) => {
  return POST(`${API_BASE_URL}${CREATE_REASON}`, body);
};

export const updateReasonMaster = async (reasonId, body) => {
  return POST(`${API_BASE_URL}${UPDATE_REASON_MASTER(reasonId)}`, body);
};

export const fetchOneServiceType = async (serviceCode, params) => {
  return GET(
    `${API_BASE_URL}${FETCH_ONE_SERVICE_TASK}/${serviceCode}/getOne`,
    params,
  );
};

export const createServiceType = async (body) => {
  return POST(`${API_BASE_URL}${CREATE_SERVICE_TASK}`, body);
};

export const updateSingleServiceTask = async (serviceCode, body) => {
  return POST(
    `${API_BASE_URL}${UPDATE_SERVICE_TASK}/${serviceCode}/update`,
    body,
  );
};

export const changeTwoFactorData = async (body) => {
  return POST(`${API_BASE_URL}${CHANGE_TWO_FACTOR_DATA}`, body);
};

export const getMasterData = async () => {
  return GET(`${API_BASE_URL}${GET_MASTER_DATA}`);
};

export const getSortingMetadata = async () => {
  return GET(`${API_BASE_URL}${GET_SORTING_METADATA}`);
};

export const updateSortingMetadata = async (body) => {
  return POST(`${API_BASE_URL}${UPDATE_SORTING_METADATA}`, body);
};

export const fetchAddressMetadata = async () => {
  return GET(`${API_BASE_URL}${FETCH_ADDRESS_META_DATA}`);
};

export const updateSectionVisibility = async (body) => {
  return POST(`${API_BASE_URL}${UPDATE_VISIBILITY_CONFIGURATION_DATA}`, body);
};

export const getSectionVisibilityData = async () => {
  return GET(`${API_BASE_URL}${GET_VISIBILITY_CONFIGURATION_DATA}`);
};

export const getAppFlowConfig = async (params) => {
  return GET(`${API_BASE_URL}${FETCH_APPFLOW_CONFIG}`, params);
};

export const getAppFlowObjectConfigMetadata = async () => {
  return GET(`${API_BASE_URL}${FETCH_APPFLOW_OBJECT_CONFIG}`);
};

export const createAppFlowConfigObject = async (body) => {
  return POST(`${API_BASE_URL}${CREATE_APPFLOW_OBJECT}`, body);
};

export const updateAppFlowConfigObject = async (body) => {
  return POST(`${API_BASE_URL}${UPDATE_APPFLOW_OBJECT}`, body);
};

export const deleteAppFlowConfigObject = async (body) => {
  return POST(`${API_BASE_URL}${DELETE_APPFLOW_OBJECT}`, body);
};

export const getCustomerList = async (params = {search: ''}) => {
  return GET(`${API_BASE_URL}${GET_CUSTOMER_LIST}`, params);
};
