import { API_BASE_URL } from '@src/globals';
import {
  GET_CRATE_TABLE_DATA,
  ADD_CRATE_MAPPING_BULK,
  SAMPLE_CSV_DOWNLOAD_LINK,
} from './routes';
import {
  requestModelCrate,
  responseModelCrate,
} from '@src/model/consignmentView';
import { morphism } from 'morphism';
import { GET, POST } from './apiHandler';

export const getCrateTableData = async (body) => {
  const params = {
    ...morphism(requestModelCrate, body),
  };
  const response = await GET(`${API_BASE_URL}${GET_CRATE_TABLE_DATA}`, params);
  if (response.isSuccess) {
    return {
      data: morphism(responseModelCrate, response.data),
      isSuccess: true,
    };
  }

  return response;
};

export const uploadCSVCrateMappingData = async (body) => {
  const response = await POST(`${API_BASE_URL}${ADD_CRATE_MAPPING_BULK}`, body);
  if (response.isSuccess) {
    return response.response;
  }

  return response;
};

export const getSampleCSVCrateMapping = async () => {
  return GET(`${API_BASE_URL}${SAMPLE_CSV_DOWNLOAD_LINK}`);
};
