import React from 'react';
import consignmentTableActions from './GenericData/consignment/tableActionsData';
import customerTableActions from './GenericData/customer/tableActionsData';
import GenericTableActionComponent from './GenericData/GenericTableActionComponent';

const tableActionsDataMap = {
    consignment: consignmentTableActions,
    customer: customerTableActions,
};

export const tableActionResolver = (actionId, record, objectType, otherParams) => {
    const data = tableActionsDataMap[objectType] || {};

    if (data[actionId]) {
        if (data[actionId].labelComponent) {
            const Component = data[actionId].labelComponent;
            return <Component
                actionId={actionId}
                actionDetails={data[actionId]}
                record={record}
                params={otherParams}
            />;
        }
        return <GenericTableActionComponent
            actionId={actionId}
            actionDetails={data[actionId]}
            record={record}
            params={otherParams}
        />;
    }

    return null;
};
