import * as React from 'react';
import DocumentTitle from 'react-document-title';
import Navbar from '../../../../navbar/Navbar';
import SideLabel from '../../../../common/SideLabel';
import SimplePaginator from '../../../../common/SimplePaginator';
import { getDownloadsRoute } from '../../../../../routing/utils';
import GenericDropdown from '../../../../common/GenericDropdown';
import {
  dateFilterOptions,
  fixedColumnListLeftSide,
  fixedColumnListRightSide,
  courierTatColumnList,
  statusFilterOptions,
  getLastSortedColumnValue,
  getLastRowId,
  getPageNumber,
} from '../../utils';
import {
  Select,
  message,
  Button,
  Tooltip,
  DatePicker,
  Input,
} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import Loader from '../../../../common/Loader';
import FixedHeaderTable from '../../../../common/FixedHeaderTable/FixedHeaderTable';
import * as moment from 'moment';
import * as lodash from 'lodash';
import GenericBulkUpload from 'src/components/pages/TMSDashboard/Setup/GenericBulkUpload/GenericBulkUpload';
import { fetchAllocationData } from 'src/api/CarrierPartner';
import {
  fetchConsignmentPriceCalculatorData,
  downloadConsignmentPriceCalculatorData,
} from 'src/api/dashboard';
import { Link } from 'react-router-dom';
import { getDetailsRoute } from '@routing/utils';
import GenericActionButton from 'src/components/common/GenericActionButton';
import { useTranslation } from 'react-i18next';

const Option = Select.Option;
const Search = Input.Search;

export default function ConsignmentPriceCalculator(props: any) {
  const { t } = useTranslation();
  const { classes, ConsignmentPriceCalculatorColumnList, ltlOrgActiveCourierPartner } = props;

  // Loaders -
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDataLoading, setIsDataLoading] = React.useState(false);

  // Filters -
  const [sortBy, setSortBy] = React.useState('last_main_event_time');
  const [referenceNumberSearchValue, setReferenceNumberSearchValue] = React.useState('');
  const [selectedStatus, setSelectedStatus] = React.useState('' as any);
  const [selectedCarrier, setSelectedCarrier] = React.useState('' as any);
  const [dateFilterValues, setDateFilterValues] = React.useState({
    from_date: moment().format('YYYY-MM-DD'),
    to_date: moment().format('YYYY-MM-DD'),
  });

  // Pagination -
  const [pageNumber, setPageNumber] = React.useState(1);
  const [responsePagination, setResponsePagination] = React.useState({} as any);
  const [isNextPresent, setIsNextPresent] = React.useState(false);

  const [pricingMetadataColumnList, setPricingMetadataColumnList] = React.useState([]);
  const [consignmentData, setConsignmentData] = React.useState([]);
  const [downloadButtonLoading, setDownloadButtonLoading] = React.useState(false);
  const [isGenericBulkUploadVisible, setGenericBulkUploadVisible] = React.useState(false);
  const [isGenericBulkUploadVisibleWithAwb, setGenericBulkUploadVisibleWithAwb] =
    React.useState(false);
  const [consignmentListPriceCalculatorReponse, setConsignmentListPriceCalculatorReponse] =
    React.useState([]);
  const [showCheckPriceResponseData, setShowCheckPriceResponseData] = React.useState(false);

  React.useEffect(() => {
    // fetchPricingMetadata();
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [sortBy, dateFilterValues, selectedStatus, referenceNumberSearchValue, selectedCarrier]);

  const fetchPricingMetadata = async (courierPartner) => {
    // setIsLoading(true);

    const body = {
      metadataType: 'courierPricing',
      courierPartner: courierPartner || selectedCarrier,
    };

    const response: any = await fetchAllocationData(body);
    if (response.isSuccess) {
      if (Array.isArray(response?.data)) {
        const metadataColumnList = response?.data.map(row => {
          return {
            column_id: `courier_price_details.${row.chargeId}`,
            pretty_name: row.chargeName || row.chargeId,

          };
        });
        setPricingMetadataColumnList(metadataColumnList);
      }
    } else {
      message.error(response.errorMessage);
    }

    // setIsLoading(false);
  };

  const getDataRequestBodyForDownload = async () => {
    const body: any = {
      bucket: 'crm_default',
      sortBy,
      descendingOrder: true,
    };

    if (showCheckPriceResponseData && consignmentListPriceCalculatorReponse?.length > 0) {
      body.referenceNumberList = consignmentListPriceCalculatorReponse;
    }
    else if (!showCheckPriceResponseData) {
      body.timeFilters = [{
        columnName: sortBy,
        fromDateString: dateFilterValues.from_date,
        toDateString: dateFilterValues.to_date,
      }];
      if (referenceNumberSearchValue) {
        body.referenceNumberList = [referenceNumberSearchValue];
      }
      if (selectedStatus) {
        body.statusList = [selectedStatus];
      }
      if (selectedCarrier) {
        body.courierAccountList = [selectedCarrier];
      }
    }
    return body;
  };

  const getDataRequestBody = async (nextOrPrev = 'first', pageNo = pageNumber) => {
    const body: any = {
      bucket: 'crm_default',
      resultPerPage: 100,
      pageNumber: pageNo,
      sortBy,
      descendingOrder: true,
      nextOrPrev,
      lastSortedColumnValue: getLastSortedColumnValue(nextOrPrev, responsePagination),
      lastRowId: getLastRowId(nextOrPrev, responsePagination),
      timeFilters: [{
        columnName: sortBy,
        fromDateString: dateFilterValues.from_date,
        toDateString: dateFilterValues.to_date,
      }],
    };

    if (referenceNumberSearchValue) {
      body.referenceNumberList = [referenceNumberSearchValue];
    }

    if (selectedStatus) {
      body.statusList = [selectedStatus];
    }

    if (selectedCarrier) {
      body.courierAccountList = [selectedCarrier];
    }

    return body;
  };

  const fetchData = async (nextOrPrev = 'first') => {
    setIsDataLoading(true);

    const pageNo = await getPageNumber(nextOrPrev, pageNumber);
    const body = await getDataRequestBody(nextOrPrev, pageNo);
    const response: any = await fetchConsignmentPriceCalculatorData(body);
    if (response.isSuccess) {
      setConsignmentData(response?.data?.page_data);
      setPageNumber(pageNo);
      setIsNextPresent(response?.data?.is_next_present);
      setResponsePagination({
        isNextPresent: response?.data?.is_next_present,
        lastRowIdPrev: response?.data?.last_row_id_prev,
        lastRowIdNext: response?.data?.last_row_id_next,
        lastSortedColumnValue: response?.data?.last_sort_by_key,
        lastSortedColumnValuePrev: response?.data?.last_sorted_column_value_prev,
        lastSortedColumnValueNext: response?.data?.last_sorted_column_value_next,
      });
    } else {
      message.error(response.errorMessage);
    }

    setIsDataLoading(false);
  };

  const onCheckPriceClick = () => {
    setGenericBulkUploadVisible(true);
  };

  const closeModals = () => {
    setGenericBulkUploadVisible(false);
    setGenericBulkUploadVisibleWithAwb(false);
  };

  const getDisabledDate = (current) => {
    return current && current > moment().endOf('day');
  };

  const fetchDate = (dateArr) => {
    const date: any = { start: moment(), end: moment() };
    if (dateArr?.length === 2) {
      date.start = dateArr[0];
      date.end = dateArr[1];
    }
    return date;
  };

  const handleDateSelect = (dateArr) => {
    const date = fetchDate(dateArr);
    setDateFilterValues({
      from_date: date.start.format('YYYY-MM-DD'),
      to_date: date.end.format('YYYY-MM-DD'),
    });
  };

  const handleCheckPriceResponse = (responseData) => {
    setConsignmentData(responseData);
    if (responseData?.length > 0) {
      const consignmentList = responseData.map((consignment) => consignment.reference_number);
      setConsignmentListPriceCalculatorReponse(consignmentList);
    }
    setShowCheckPriceResponseData(true);
  };

  const handleDownload = async () => {
    setDownloadButtonLoading(true);

    const body = await getDataRequestBodyForDownload();
    const response = await downloadConsignmentPriceCalculatorData(body);
    if (response.isSuccess) {
      message.success('Download Request succesfully added under PRICE_CALCULATOR_DUMP');
      props.history.push(getDownloadsRoute());
    }
    else {
      message.error(response.errorMessage);
    }
    setDownloadButtonLoading(false);
  };

  const onClickReset = () => {
    setShowCheckPriceResponseData(false);
    setConsignmentListPriceCalculatorReponse([]);
    fetchData('first');
  };

  const generateOptionsFromList = (optionsList: any, style:any = {}) => {
    const x:any = [];
    if (optionsList) {
      optionsList.forEach((ele:any) => {
        x.push(<Option key={ele.key} value = {ele.key} style = {{ ...style }}>{ele.val}</Option>);
      });
    }
    return x;
  };

  const renderDefaultFilters = () => {
    return (
      <>
        <div className={classes.filterRow}>
          <div className={classes.filterItem}>
            <SideLabel text={t('carrier')} />
            <Select
              placeholder={t('carrier')}
              style={{ width: 150 }}
              onChange={(val) => {
                setPricingMetadataColumnList([]);
                setSelectedCarrier(val);
                fetchPricingMetadata(val);
              }}
              allowClear
            >
              {generateOptionsFromList((ltlOrgActiveCourierPartner || []).map((courierPartner) => {
                  return {
                    key: courierPartner.id,
                    val: courierPartner.id,
                  };
              }))}
            </Select>
          </div>
        </div>
        <div className={classes.filterRow}>
          <div className={classes.filterItem}>
            <SideLabel text={t('reference_#')} />
            <Search
              placeholder={t('reference_number')}
              onSearch={(value) => setReferenceNumberSearchValue(value)}
              style={{ width: 200 }}
              allowClear
            />
          </div>
          <div className={classes.filterItem}>
            <Select
              style={{ width: 130, backgroundColor: '#FAFAFA' }}
              defaultValue={sortBy}
              onChange={(value) => setSortBy(value)}
            >
              { generateOptionsFromList(dateFilterOptions.map((filter) => (
                  { ...filter, val: t(filter.key) }
                )), { backgroundColor: '#FAFAFA' }) }
            </Select>
            <DatePicker.RangePicker
              value={[
                moment(dateFilterValues.from_date),
                moment(dateFilterValues.to_date),
              ]}
              format={'DD/MM/YYYY'}
              onChange={handleDateSelect}
              disabledDate={getDisabledDate}
              ranges={{
                Today: [moment(), moment()],
                'Last 7 days': [moment().subtract(6, 'day'), moment()],
                'Last 30 days': [moment().subtract(29, 'day'), moment()],
                'This Month': [
                  moment().startOf('month'),
                  moment().endOf('month'),
                ],
              }}
              className={classes.dateClass}
            ></DatePicker.RangePicker>
          </div>
          <div className={classes.filterItem}>
            <SideLabel text={t('status')} />
            <Select
              placeholder={t('select_status')}
              style={{ width: 150 }}
              onChange={(val) => {setSelectedStatus(val);}}
              allowClear
            >
              {generateOptionsFromList(statusFilterOptions)}
            </Select>
          </div>
        </div>
      </>
    );
  };

  const renderDefaultActions = () => {
    return (
      <>
        <div className={classes.filterRow} style={{ marginRight: 0 }}>
          <div className={classes.filterItem}>
            <Tooltip title={t('download_data')}>
              <Button
                onClick={handleDownload}
                loading={downloadButtonLoading}
                disabled={
                  (showCheckPriceResponseData &&
                    consignmentListPriceCalculatorReponse?.length === 0)
                    ? true : false}
              >
                <DownloadOutlined />
              </Button>
            </Tooltip>
          </div>
        {showCheckPriceResponseData && (
          <div className={classes.filterItem}>
            <Tooltip title={t('reset_data')}>
              <GenericActionButton onClick={onClickReset}>
                {t('reset_data')}
              </GenericActionButton>
            </Tooltip>
          </div>
        )}
          {!showCheckPriceResponseData && (
            <div className={classes.filterItem}>
              <GenericDropdown
                label={<div><Button>
                  {t('check_price')}
                </Button>
                </div>}
                menu={[
                  {
                    label: 'With Reference #',
                    onClick: () => {
                      setGenericBulkUploadVisible(true);
                    },
                  },
                  {
                    label: 'With Carrier AWB #',
                    onClick: () => {
                      setGenericBulkUploadVisibleWithAwb(true);
                    },
                  },
                ]}
              />
            </div>
          )}
        {!showCheckPriceResponseData && (
          <div className={classes.filterItem}>
            <SimplePaginator
              onFirstClick={() => fetchData('first')}
              onPrevClick={() => fetchData('prev')}
              onNextClick={() => fetchData('next')}
              isNext={isNextPresent}
              pageNumber={pageNumber}
            />
          </div>
        )}
        </div>
      </>
    );
  };

  const generateDataColumns = () => {
    const columnData: any = [];
    const indexList = [
      ...fixedColumnListLeftSide,
      ...ConsignmentPriceCalculatorColumnList,
      ...courierTatColumnList,
      ...pricingMetadataColumnList,
      ...fixedColumnListRightSide,
    ];

    indexList.forEach((item) => {
      const itemToPush = {};
      itemToPush['title'] = t(item.column_id + '_price_calulator_column');
      itemToPush['dataIndex'] = item.column_id;
      itemToPush['key'] = item.column_id;
      itemToPush['align'] = 'center';
      itemToPush['fixed'] = item.fixed;
      itemToPush['width'] = item.width;
      itemToPush['render'] = (x, record) => {
        switch (item.column_id) {
          case 'reference_number':
            return (
              <Link target={'_blank'} to={getDetailsRoute(x)}>
                {x}
              </Link>
            );
          case 'courier_price_details.finalPrice':
          case 'courier_amount':
            const courierAmount = lodash.get(record, 'courier_amount', '');
            const finalPrice = lodash.get(record, 'courier_price_details.finalPrice', '');

            return {
              props: {
                style: {
                  background: courierAmount && finalPrice ?
                    (Number(courierAmount) !== Number(finalPrice) ? '#FAE2E1' : '#d9f7be' ) : undefined,
                  },
              },
              children: lodash.get(record, item.column_id, ''),
            };
          default:
            return lodash.get(record, item.column_id, '');
        }
      };

      columnData.push(itemToPush);
    });

    return columnData;
  };

  return (
    <div className={classes.topDiv}>
      <DocumentTitle title={t('price_calculator')} />
      <Navbar pageTitle={t('price_calculator')} />

      {isLoading && (
        <div className={classes.loaderContainer}>
          <Loader zIndex={100} />
        </div>
      )}
      {!isLoading && (
        <div className={classes.mainDiv}>
            <div className={classes.containerDefaultFilter} style={{justifyContent: showCheckPriceResponseData ? 'flex-end' : 'space-between'}}>
              {!showCheckPriceResponseData && renderDefaultFilters()}
              {renderDefaultActions()}
            </div>
            <FixedHeaderTable
              bordered
              loading={isDataLoading}
              locale={{ emptyText: t('no_data_found')}}
              rowKey={(record) => record.reference_number}
              dataSource={consignmentData}
              columns={generateDataColumns()}
              pagination={!showCheckPriceResponseData ? false : {
                simple: true,
                pageSize: 100,
              }}
            />
        </div>
      )}
      {isGenericBulkUploadVisible ? (
        <GenericBulkUpload
          genericConsignmentViewType="dashboard_consignment_price_calculator"
          currentAction="add"
          handleModalClose={closeModals}
          isVisible={isGenericBulkUploadVisible}
          handleResponse={handleCheckPriceResponse}
        />
      ) : null}
      {isGenericBulkUploadVisibleWithAwb ? (
        <GenericBulkUpload
          genericConsignmentViewType="dashboard_consignment_price_calculator_with_awb"
          currentAction="add"
          handleModalClose={closeModals}
          isVisible={isGenericBulkUploadVisibleWithAwb}
          handleResponse={handleCheckPriceResponse}
        />
      ) : null}
    </div>
  );
}
