import Icon from '@ant-design/icons';
import * as React from 'react';

const HeaderMappingSvg = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>Header Mapping</title>
        <desc>Created with Sketch.</desc>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Artboard" fill="currentColor" fillRule="nonzero">
                <g id="hm" transform="translate(5.000000, 1.000000)">
                    <path d="M8.5,5.7 L13.7,5.7 C13.7,5.7 15,5.8 15,4.4 C15,3 13.7,3.1 13.7,3.1 C13.7,3.1 13.7,0.5 11.1,0.5 C8.5,0.5 8.5,3 8.5,3 C8.5,3 7.2,3.1 7.2,4.4 C7.2,5.9 8.5,5.7 8.5,5.7 Z M11.1,1.8 C11.8,1.8 12.4,2.4 12.4,3.1 C12.4,3.8 11.9,4.5 11.1,4.5 C10.3,4.5 9.8,3.9 9.8,3.2 C9.8,2.5 10.4,1.8 11.1,1.8 Z" id="Shape"></path>
                    <path d="M19.7,3.1 L15.9,3.1 C15.8,4.5 15.9,6.9 14.5,6.9 C13.1,6.9 7.8,6.9 7.8,6.9 C6.4,6.9 6.5,4.5 6.4,3.1 L2.5,3.1 C1.5,3.1 0.7,3.9 0.7,4.9 L0.7,27.7 C0.7,28.7 1.5,29.5 2.5,29.5 L19.4,29.5 C20.4,29.5 21.2,28.7 21.2,27.7 L21.2,4.9 C21.5,3.9 20.7,3.1 19.7,3.1 Z M15.6,22 L13.3,22 L13.3,17.1 L8.7,17.1 L8.7,22 L6.4,22 L6.4,10.6 L8.7,10.6 L8.7,15.2 L13.3,15.2 L13.3,10.6 L15.6,10.6 L15.6,22 Z" id="Shape"></path>
                </g>
            </g>
        </g>
    </svg>
  );
};

export const HeaderMappingNavIcon = props => (
  <Icon component={HeaderMappingSvg} {...props} />
);
