import { API_BASE_URL } from '../globals';
import {
  GET_PASSWORD_POLICY,
  GET_ACTIVITY_LOG,
  EDIT_PASSWORD_POLICY,
  EDIT_PASSWORD_CONSTRAINT,
} from './routes';
import { GET, POST } from './apiHandler';

export const getPasswordPolicy = async (params) => {
  return GET(`${API_BASE_URL}${GET_PASSWORD_POLICY}`, params);
};

export const getActivityLog = async (params) => {
  return GET(`${API_BASE_URL}${GET_ACTIVITY_LOG}`, params);
};

export const editPasswordPolicy = async (body) => {
  return POST(`${API_BASE_URL}${EDIT_PASSWORD_POLICY}`, body);
};

export const editPasswordConstraint = async (body) => {
  return POST(`${API_BASE_URL}${EDIT_PASSWORD_CONSTRAINT}`, body);
};
