import Icon from '@ant-design/icons';
import * as React from 'react';

const AppFlowConfigSvg = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Setup_Tooltip" transform="translate(-16.000000, -602.000000)">
                <g id="Group-6" transform="translate(0.000000, 58.000000)">
                    <g id="App_Flow_Config" transform="translate(16.000000, 544.000000)">
                        <rect id="Rectangle-6" opacity="0.1" x="0" y="0" width="24" height="24"></rect>
                        <g transform="translate(4.100000, 2.000000)" fill="#666666">
                            <path d="M2.00303,1.65250472 C1.91236,1.65942472 1.82716,1.69857472 1.76286,1.76287472 C1.69856,1.82717472 1.65941,1.91236472 1.6525,2.00303472 L1.6525,17.8169947 C1.65288,17.9179947 1.69236,18.0148947 1.76266,18.0873947 C1.79667,18.1253947 1.83836,18.1557947 1.88497,18.1764947 C1.93158,18.1971947 1.98206,18.2077947 2.03307,18.2075947 L9.54442,18.2075947 C9.64642,18.2026947 9.74302,18.1599947 9.81522,18.0877947 C9.88742,18.0154947 9.93012,17.9189947 9.93502,17.8169947 L9.93502,17.3762947 C9.93502,17.1584947 10.02152,16.9495947 10.17552,16.7955947 C10.32962,16.6415947 10.53842,16.5550947 10.75632,16.5550947 C10.97412,16.5550947 11.18292,16.6415947 11.33702,16.7955947 C11.49102,16.9495947 11.57752,17.1584947 11.57752,17.3762947 L11.57752,17.8169947 C11.57752,18.3481947 11.36652,18.8576947 10.99082,19.2332947 C10.61522,19.6089947 10.10572,19.8199947 9.57452,19.8199947 L2.00303,19.8199947 C1.47179,19.8199947 0.96231,19.6089947 0.58667,19.2332947 C0.21103,18.8576947 0,18.3481947 0,17.8169947 L0,2.00303472 C0.00752,1.47469472 0.22351,0.970734725 0.6009,0.600914725 C0.97073,0.223514725 1.47469,0.00753472469 2.00303,0 L9.51442,0 C9.78202,-0.000565275313 10.04722,0.0504447247 10.29562,0.150234725 C10.56212,0.253774725 10.80682,0.406674725 11.01662,0.600914725 C11.20682,0.785244725 11.35682,1.00687472 11.45732,1.25190472 C11.55622,1.50050472 11.60712,1.76554472 11.60752,2.03308472 L11.60752,3.30500472 C11.60752,3.52281472 11.52102,3.73170472 11.36702,3.88571472 C11.21302,4.03972472 11.00412,4.12624472 10.78632,4.12624472 C10.56852,4.12624472 10.35962,4.03972472 10.20562,3.88571472 C10.05162,3.73170472 9.96512,3.52281472 9.96512,3.30500472 L9.96512,2.00303472 C9.97452,1.95340472 9.97452,1.90243472 9.96512,1.85280472 C9.94162,1.80818472 9.91122,1.76760472 9.87492,1.73262472 C9.84152,1.69712472 9.80042,1.66972472 9.75472,1.65250472 C9.70512,1.64303472 9.65412,1.64303472 9.60452,1.65250472 L2.00303,1.65250472 Z" id="Path"></path>
                            <path d="M4.14643,3.30498472 C4.1463,3.19367472 4.16879,3.08351472 4.21255,2.98116472 C4.2563,2.87882472 4.3204,2.78644472 4.40095,2.70963472 C4.48151,2.63283472 4.57683,2.57320472 4.68114,2.53436472 C4.78545,2.49553472 4.89656,2.47830472 5.00774,2.48373472 L6.61012,2.48373472 C6.72632,2.47093472 6.84382,2.48275472 6.95512,2.51843472 C7.06642,2.55412472 7.16892,2.61286472 7.25592,2.69082472 C7.34292,2.76879472 7.41252,2.86423472 7.46022,2.97091472 C7.50792,3.07760472 7.53252,3.19313472 7.53252,3.30998472 C7.53252,3.42683472 7.50792,3.54237472 7.46022,3.64905472 C7.41252,3.75574472 7.34292,3.85118472 7.25592,3.92914472 C7.16892,4.00711472 7.06642,4.06585472 6.95512,4.10153472 C6.84382,4.13722472 6.72632,4.14904472 6.61012,4.13623472 L5.00774,4.13623472 C4.89572,4.14171472 4.78376,4.12419472 4.67878,4.08475472 C4.57379,4.04531472 4.47799,3.98478472 4.39729,3.90689472 C4.31659,3.82901472 4.2527,3.73542472 4.20956,3.63190472 C4.16641,3.52838472 4.14493,3.41712472 4.14643,3.30498472 Z" id="Path"></path>
                            <polygon id="Path" fillRule="nonzero" points="6.82032 15.3031947 4.7572 15.3031947 4.7572 17.3662947 6.82032 17.3662947"></polygon>
                            <polygon id="Path" fillRule="nonzero" points="12.11832 5.78878472 10.45582 5.78878472 10.45582 7.62156472 12.11832 7.62156472"></polygon>
                            <polygon id="Path" fillRule="nonzero" points="12.11832 13.1398947 10.45582 13.1398947 10.45582 14.9726947 12.11832 14.9726947"></polygon>
                            <polygon id="Path" fillRule="nonzero" points="15.87392 9.55439472 14.17142 9.55439472 14.17142 11.2169947 15.87392 11.2169947"></polygon>
                            <polygon id="Path" fillRule="nonzero" points="13.90552 6.57747472 12.73702 7.74597472 13.91262 8.92159472 15.08112 7.75305472"></polygon>
                            <polygon id="Path" fillRule="nonzero" points="8.62922 6.55274472 7.45412 7.72873472 8.62302 8.89679472 9.79812 7.72083472"></polygon>
                            <polygon id="Path" fillRule="nonzero" points="13.95422 11.8710947 12.77902 13.0470947 13.94792 14.2151947 15.12312 13.0391947"></polygon>
                            <polygon id="Path" fillRule="nonzero" points="8.58792 11.9091947 7.41942 13.0776947 8.59502 14.2532947 9.76352 13.0847947"></polygon>
                            <polygon id="Path" fillRule="nonzero" points="8.52312 9.55439472 6.69032 9.55439472 6.69032 11.2169947 8.52312 11.2169947"></polygon>
                            <path d="M11.28692,14.0212947 C10.57782,14.0212947 9.88462,13.8109947 9.29502,13.4169947 C8.70542,13.0229947 8.24582,12.4630947 7.97442,11.8078947 C7.70312,11.1527947 7.63212,10.4318947 7.77042,9.73639472 C7.90882,9.04089472 8.25022,8.40199472 8.75172,7.90059472 C9.25312,7.39912472 9.89202,7.05764472 10.58752,6.91929472 C11.28302,6.78095472 12.00392,6.85195472 12.65902,7.12332472 C13.31422,7.39470472 13.87412,7.85425472 14.26812,8.44389472 C14.66212,9.03349472 14.87242,9.72669472 14.87242,10.4357947 C14.87242,11.3867947 14.49462,12.2986947 13.82222,12.9710947 C13.14982,13.6434947 12.23792,14.0212947 11.28692,14.0212947 Z M11.28692,8.51289472 C10.90662,8.51289472 10.53492,8.62569472 10.21862,8.83699472 C9.90242,9.04829472 9.65592,9.34859472 9.51042,9.69999472 C9.36492,10.0512947 9.32682,10.4379947 9.40102,10.8109947 C9.47522,11.1839947 9.65832,11.5265947 9.92722,11.7954947 C10.19622,12.0644947 10.53882,12.2475947 10.91182,12.3217947 C11.28482,12.3959947 11.67142,12.3578947 12.02282,12.2123947 C12.37422,12.0667947 12.67452,11.8203947 12.88582,11.5040947 C13.09712,11.1878947 13.20992,10.8161947 13.20992,10.4357947 C13.21652,10.1790947 13.17172,9.92369472 13.07812,9.68459472 C12.98452,9.44549472 12.84392,9.22749472 12.66472,9.04359472 C12.48542,8.85969472 12.27122,8.71359472 12.03462,8.61389472 C11.79792,8.51409472 11.54372,8.46279472 11.28692,8.46289458 L11.28692,8.51289472 Z" id="Shape" fillRule="nonzero"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    );
};

export const AppFlowConfigIcon = props => (
  <Icon component={AppFlowConfigSvg} {...props} />
);
