import Icon from '@ant-design/icons';
import * as React from 'react';

const TerritoryOptimisationSvg = (props) => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="24" height="24" viewBox="0 0 699.000000 616.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,616.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M5090 6151 c-44 -14 -84 -38 -114 -70 l-30 -31 -1439 0 -1440 0 -33
25 c-77 59 -176 70 -259 31 -115 -54 -167 -169 -135 -299 l11 -42 -679 -1195
-679 -1195 -62 -7 c-213 -24 -303 -261 -157 -416 34 -37 95 -72 124 -72 11 0
24 -6 30 -12 5 -7 328 -575 716 -1262 670 -1184 706 -1251 696 -1279 -30 -87
5 -207 78 -267 47 -39 131 -64 189 -56 47 6 112 36 132 60 12 14 154 16 1431
16 1277 0 1419 -2 1431 -16 42 -51 149 -74 230 -50 104 30 179 126 179 230 0
49 31 105 733 1340 l732 1288 49 19 c57 21 109 68 137 121 78 152 -23 337
-195 355 l-55 6 -667 1176 c-367 647 -672 1183 -677 1192 -8 12 -4 31 11 67
59 136 -8 290 -147 337 -47 16 -100 18 -141 6z m116 -130 c98 -45 96 -179 -3
-223 -40 -17 -49 -18 -86 -6 -110 35 -115 198 -7 235 46 16 48 16 96 -6z
m-3282 -25 c69 -29 96 -107 61 -176 -30 -59 -127 -80 -179 -39 -31 25 -56 69
-56 101 0 38 33 90 70 110 42 22 60 22 104 4z m2863 -133 c-4 -3 -519 -174
-1144 -378 l-1138 -372 -25 22 c-36 33 -98 61 -150 69 l-45 7 -42 92 c-23 51
-74 162 -113 248 l-72 156 25 39 c14 22 29 53 32 69 4 17 8 36 11 43 3 9 278
12 1335 12 733 0 1329 -3 1326 -7z m258 -174 c54 -26 64 -36 69 -62 3 -18 104
-586 226 -1263 121 -677 220 -1239 220 -1249 0 -10 -18 -34 -40 -53 -114 -97
-119 -262 -11 -370 l48 -47 -247 -1045 c-136 -575 -250 -1057 -253 -1072 -5
-19 -15 -28 -39 -33 -46 -10 -88 -33 -124 -66 l-30 -29 -1155 287 -1154 288
-8 55 c-21 148 -178 245 -317 196 l-45 -16 -66 68 c-60 61 -1535 1597 -1616
1683 -34 36 -34 36 -21 79 12 39 104 131 866 866 851 819 854 822 885 813 145
-41 278 44 322 207 2 6 97 41 211 78 115 38 654 213 1199 391 545 178 996 323
1004 324 7 1 42 -13 76 -30z m-3036 -307 l112 -246 -32 -62 c-29 -58 -31 -69
-28 -145 l4 -82 -785 -752 c-432 -414 -787 -751 -788 -750 -2 2 289 521 648
1152 l651 1149 53 -9 53 -10 112 -245z m3939 -1042 c322 -569 587 -1042 589
-1051 3 -10 -2 -28 -10 -40 -9 -13 -21 -45 -27 -71 l-12 -46 -71 -16 c-40 -9
-173 -38 -297 -65 l-224 -50 -36 38 c-19 21 -53 47 -75 59 l-40 20 -203 1138
c-112 627 -202 1144 -202 1149 0 6 6 1 12 -10 6 -11 275 -486 596 -1055z
m-3562 707 c109 -100 -17 -275 -143 -197 -74 45 -83 139 -19 197 29 27 44 33
81 33 37 0 52 -6 81 -33z m-2055 -1833 c85 -82 35 -214 -81 -214 -114 0 -165
124 -87 209 30 32 55 41 101 37 24 -3 49 -14 67 -32z m6486 4 c40 -37 55 -82
43 -128 -32 -119 -206 -122 -239 -4 -16 58 18 130 74 155 32 14 95 3 122 -23z
m-1087 -233 c44 -23 70 -62 70 -107 0 -79 -49 -128 -131 -128 -33 0 -47 6 -74
33 -64 64 -51 157 25 199 42 22 72 23 110 3z m844 -44 c16 -17 -17 -77 -651
-1198 -368 -649 -669 -1177 -671 -1173 -2 5 422 1826 469 2016 10 37 16 44 53
58 55 21 112 80 135 141 l18 48 284 62 c156 34 298 63 315 64 19 0 38 -6 48
-18z m-5230 -1130 l716 -744 0 -62 c0 -67 13 -115 41 -156 l18 -25 -96 -161
-96 -161 -56 -5 c-31 -3 -62 -7 -70 -8 -9 -3 -203 329 -597 1026 -499 882
-604 1071 -580 1044 2 -2 326 -339 720 -748z m1029 -713 c28 -14 57 -69 57
-106 0 -123 -165 -172 -230 -67 -69 111 55 235 173 173z m1166 -553 c581 -146
1065 -270 1076 -275 12 -5 -470 -8 -1235 -7 l-1254 2 -8 38 c-4 21 -14 50 -22
65 -14 27 -12 33 88 196 l103 169 64 16 c36 9 78 26 94 38 17 12 32 23 34 23
2 0 479 -119 1060 -265z m-1596 -187 c28 -15 57 -69 57 -108 0 -41 -29 -94
-61 -111 -33 -17 -92 -14 -127 7 -85 50 -78 169 11 213 42 21 79 21 120 -1z
m3173 2 c43 -21 74 -67 74 -110 0 -64 -62 -123 -129 -123 -48 0 -77 17 -101
56 -41 67 -10 160 60 184 47 16 48 16 96 -7z"/>
<path d="M3375 4199 c-200 -29 -394 -136 -522 -289 -66 -79 -144 -236 -175
-350 -18 -68 -22 -110 -21 -215 1 -155 21 -244 87 -380 58 -121 682 -984 723
-1002 21 -8 35 -8 56 0 37 16 671 881 727 993 111 222 121 504 25 727 -54 126
-94 186 -190 282 -190 190 -441 273 -710 234z m299 -175 c102 -27 201 -81 280
-155 237 -221 298 -554 153 -844 -32 -65 -597 -846 -609 -842 -15 4 -581 784
-608 837 -58 115 -75 192 -75 340 1 121 4 143 29 215 38 109 78 176 154 257
173 183 429 256 676 192z"/>
<path d="M3379 3782 c-122 -32 -225 -120 -282 -240 -30 -63 -32 -74 -32 -183
0 -113 1 -116 38 -191 173 -351 682 -318 807 53 18 53 21 81 18 159 -3 81 -8
105 -35 163 -60 128 -179 222 -312 247 -74 14 -125 12 -202 -8z m248 -170 c48
-25 107 -88 129 -139 10 -23 17 -66 18 -108 1 -116 -51 -201 -158 -258 -58
-31 -184 -31 -242 0 -66 35 -101 70 -130 130 -52 106 -32 237 49 321 82 86
225 109 334 54z"/>
</g>
</svg>
  );
};

export const TerritoryOptimisationNavIcon = (props) => (
  <Icon component={TerritoryOptimisationSvg} {...props} />
);
