import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => {
  return {
    theme: state.uiTheme,
  };
};

const connectTheme = component => connect(mapStateToProps)(component);

export default connectTheme;
