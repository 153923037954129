import * as React from 'react';
import { Table } from 'antd';
import * as ReactDOM from 'react-dom';
import { elementOuterHeight } from '../../../utils/utils';
import { withSize } from 'react-sizeme';
import withStyles from 'react-jss';
import { CssType,
  ThemeType} from '../../../theming/jssTypes';
import { createSelector } from 'reselect';
import classNames from 'classnames';
import CustomTableTitle from './CustomTableTitle';
import { LocalStorageService, LocalStorageKeys } from 'src/services/localStorage';
import CustomTableCell from './CustomTableCell';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const defaultWidth = 190;

const styles = (theme: ThemeType): CssType => ({
  tableParent: {
    margin: '8px',
    '& .ant-table-tbody > tr > td, .ant-table-thead > tr > th' : {
      wordBreak: 'break-word',
      backgroundColor: theme.colors.surfaceBg,
      color: theme.colors.primaryText,
    },
    '& .ant-table-tbody > tr > td' : {
      wordWrap: 'break-word',
      wordBreak: 'break-all',
      '& a': {
        color: theme.colors.hyperlink,
      }
    },
    '& .ant-table-thead': {
      backgroundColor: theme.colors.primaryColor,
      color: theme.colors.primaryText,
    },
  },
  compactTable: {
    '& .ant-table-thead > tr > th' : {
      overflow: 'hidden',
      fontSize: theme.sizes.bodyText,
      fontWeight: '600',
      lineHeight: '12px',
      padding: '7px 12px',
      backgroundColor: theme.colors.tableHeader,
      color: theme.colors.primaryText,

      '& .ant-table-column-title': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        width: '100%',
      },
    },
    '& .ant-table-thead > tr' : {
      backgroundColor: theme.colors.tableHeader,
    },
    '& .ant-table-row': {
      backgroundColor: theme.colors.primaryColor,
    },
    '& .ant-table-row > td' : {
      fontSize: theme.sizes.bodyText,
      lineHeight: '14px',
      padding: '10px 12px',
      color: theme.colors.primaryText,
      fontWeight: '500',
    },
    '& .ant-table-expanded-row > td': {
      padding: '8px 0',
    },
    '& .ant-table-row-selected': {
      backgroundColor: `${theme.colors.selectionOnWhiteBg} !important`,
      '& .ant-table-cell-fix-left, .ant-table-cell-fix-right': {
        backgroundColor: `#c4e8f6 !important`, //have to use this color as selection color has opacity which causes cells to overlap
      }
    },
    '& .ant-table-row-selected > td' : {
      backgroundColor: `${theme.colors.selectionOnWhiteBg} !important`, //set opacity to 20%

      '&:hover': {
        backgroundColor: `${theme.colors.HoverOnWhiteBg} !important`,
      },
    },
    '& .ant-table-ping-left .ant-table-cell-fix-left-first::after, .ant-table-ping-left .ant-table-cell-fix-left-last::after': {
      boxShadow: 'none',
    },
    '& .ant-table-ping-right .ant-table-cell-fix-right-first::after, .ant-table-ping-right .ant-table-cell-fix-right-last::after': {
      boxShadow: 'none',
    },
    '& .ant-table-cell-row-hover': {
      backgroundColor: `${theme.colors.HoverOnWhiteBg} !important`,
    },
  },
});
class FixedHeaderTable extends React.PureComponent<any, any> {
  state: any = {
    scrollY: 0,
    scrollX: 0,
    columnWidths: {},
  };
  tableRef;
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
  }

  getLocalStorageKeyForTable() {
    if (this.tableRef.current) {
      const pathname = this.props.match?.path ?? window.location.pathname;
      const currentTableRef = this.tableRef.current;
      const tabParent = currentTableRef.closest ?
      currentTableRef.closest('.ant-tabs-tabpane, .shipsy-generic-tabs-with-sm-cls') : null;
      let idxKey = tabParent?.getAttribute('id');
      if (!idxKey) {
        const allTableRefs = Array.from(document.getElementsByClassName('shipsy-fixed-header-table') ?? []);
        const currentTableIndex = currentTableRef ?
        allTableRefs.findIndex(ref => ref === currentTableRef) : -1;
        if (currentTableIndex !== null && currentTableIndex > -1) {
          idxKey = currentTableIndex;
        }
      }
      if (idxKey && pathname) {
        return `${LocalStorageKeys._TABLE_COLUMS_WIDTH_DATA}:${pathname}-${idxKey}`;
      }
    }
    return null;
  }

  fetchColumnWidthFromLocalStorage() {
    let columnWidthFromLocalStorage;
    const key = this.getLocalStorageKeyForTable();
    if (key) {
      try {
        const localdata = LocalStorageService.getRaw(key);
        if (localdata) {
          columnWidthFromLocalStorage = JSON.parse(localdata);
        }
      } catch (e) {
        console.log(e);
      }
    }
    return columnWidthFromLocalStorage;
  }

  getColumnData() {
    const { columns, size, resizable = true } = this.props;

    return columns.map((column, index) => {
      const widthKey = column.key ?? column.dataIndex;
      return {
        ...column,
        onHeaderCell: (column) => ({
          width: column.width,
          tableHeight: size.height,
          onResizeStop: this.handleResize(column, index),
          resizable,
        }),
        onCell: (record) => ({
          sameTooltipAsElem: column.sameTooltipAsElem,
        }),
        width: this.state.columnWidths[widthKey] ?? column.width,
      };
    });
  }

  getScrollHeight = () => {
    const { size } = this.props;
    const { width, height } = size;
    const tableElement: any = ReactDOM
      .findDOMNode(this.tableRef.current);

    if (!tableElement || !height || !width) {
      return 0;
    }

    const tableHeaderElementHeight = tableElement
      .getElementsByTagName('thead')[0]
      .offsetHeight;

    const paginationElement = tableElement
      .getElementsByClassName('ant-table-pagination')[0];
    // Pagination might not always be present
    const paginationElementHeight = paginationElement ? elementOuterHeight(paginationElement) : 0;

    // Offset of position of title (equal to 1 (top: 1 in ant css))
    const titlePositionOffset = 1;

    const tableTitleElementHeight = tableElement
      .getElementsByClassName('ant-table-title')[0] ?
      tableElement
        .getElementsByClassName('ant-table-title')[0]
        .offsetHeight + titlePositionOffset : 0;

    const cardHeaderHeight =
      (tableHeaderElementHeight
        + tableTitleElementHeight
        + paginationElementHeight
      );

    return Math.max(0, (height - cardHeaderHeight));
  };

  getScrollWidth = createSelector(
    (p:any) => p.columns,
    (columns:any) => {
      return (columns.reduce((acc, cur) => {
        return acc + (cur.width || defaultWidth);
      }, 0) - 15);
    },
  );

  getTotalColumnWidth = createSelector(
    (p:any) => p.columns,
    (columns:any) => {
      return (columns.reduce((acc, cur) => {
        return acc + (cur.width || defaultWidth);
      }, 0));
    },
  );

  componentDidMount() {
    const newState: any = {
      columnWidths: this.fetchColumnWidthFromLocalStorage() || {},
    };
    const scrollY = this.getScrollHeight();
    if (scrollY !== this.state.scrollY) {
      newState.scrollY = scrollY;
    }

    this.setState(newState);
  }

  componentDidUpdate() {
    const scrollY = this.getScrollHeight();
    if (scrollY !== this.state.scrollY) {
      this.setState({ scrollY });
    }
  }

  handleResize(column: any, index: number) {
    return (width) => {
      const { columnWidths } = this.state;
      const newColumnWidth = {...columnWidths};
      newColumnWidth[column.key ?? column.dataIndex] = Math.max(width, 36);
      const keyToSet = this.getLocalStorageKeyForTable();
      if (keyToSet) {
        LocalStorageService.set(keyToSet, JSON.stringify(newColumnWidth));
      }

      this.setState({ columnWidths: newColumnWidth });
    };
  }

  render() {
    const {
      size,
      scroll,
      classes,
      hideScrollX,
      style,
      rowSelection,
      locale,
      components,
      t,
      ...otherProps
    } = this.props;
    const columns = this.getColumnData();
    const { width, height } = size;
    const scrollY = (width && height) ? this.state.scrollY : false;
    let scrollX: undefined | number;
    let columnsToSet = columns;
    if (hideScrollX) {
      // No scroll in x
      const totalColumnWidth = this.getTotalColumnWidth(this.props);
      let totalWidthToSet = 0;
      columnsToSet = columns.map((c) => {
        const widthToSet =
          Math.floor(((c.width || defaultWidth) / totalColumnWidth) * (width - 10));
        totalWidthToSet += widthToSet;
        return {...c, width: widthToSet};
      });
      scrollX = totalWidthToSet;
    } else {
      scrollX = this.getScrollWidth(this.props);
      if (scroll && scroll.x) {
        scrollX = scroll.x;
      }
      if (scrollX && scrollX < width && columns.length) {
        const givenTotalWidth = columns.reduce((acc, cur) => {
          return acc + (cur.width);
        }, 0);
        const numberOfdefinedWidthColumns = columns.reduce((acc, cur) => {
          return acc + (cur.width ? 1 : 0);
        }, 0);
        columns.forEach(c => !c.width &&
          (c['width'] = (
            width - givenTotalWidth - 62) / (columns.length - numberOfdefinedWidthColumns)));
      }
      scrollX = Math.max(scrollX || 0, width);
    }
    const scrollPropToAdd: any = {
      y: scrollY,
      x: scrollX,
    };

    return (
      <div style={{ position: 'relative', height: '100%', ...style}}>
       <div className = {classNames(
          classes.compactTable,
          classes.tableParent,
          this.props.className,
          'shipsy-fixed-header-table',
        )}
        style={{ position: 'absolute', width: '100%', height: size.height }}
        ref={this.tableRef}
       >
        <Table
          {...otherProps}
          columns={columnsToSet}
          scroll={scrollPropToAdd}
          bordered={true}
          locale={{
            ...(locale || {}),
            emptyText: locale?.emptyText ?? t('No data found'),
          }}
          components={{
            header: {
              cell: CustomTableTitle,
            },
            body: {
              cell: CustomTableCell,
            },
          }}
          rowSelection={rowSelection ? {
            ...rowSelection,
            getCheckboxProps: (record) => {
              const propsFromParent = rowSelection.getCheckboxProps ?
              rowSelection.getCheckboxProps(record) : {};
              return {
                ...propsFromParent,
                style: {
                  borderRadius: '2px',
                },
              };
            },
          } : null}
        />
        </div>
      </div>
    );
  }
}

export default withTranslation()(React.memo(
  withSize({ monitorHeight: true })(
    withRouter(
      withStyles(styles, { injectTheme: true })(FixedHeaderTable)
    ),
  ),
));
