import * as React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopyOutlined, CheckOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

const CopyToClipboardWrapper = (props) => {
  const { text, style, timeout = 3000 } = props;

  const [copied, setCopied] = React.useState(false);

  const onCopy = () => {
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, timeout);
  };

  return (
    <div style={style}>
      {copied ? (
        <Tooltip placement="bottom" title={'Copied'}>
          <CheckOutlined style={{ color: '#3fb950' }} />
        </Tooltip>
      ) : (
        <CopyToClipboard text={text}>
          <CopyOutlined onClick={onCopy} style={{ color: '#666667' }} />
        </CopyToClipboard>
      )}
    </div>
  );
};

export default CopyToClipboardWrapper;
