export const tagOptions = {
  pending_from_customer : 'Pending from Customer',
  pending_from_courier : 'Pending from Carrier',
  pending_from_warehouse : 'Pending from Warehouse',
  damage : 'Damage',
  lost : 'Lost',
  redispatched: 'Redispatched' ,
};

export const lastScanOptions = {
  '<1' : '<1 day',
  '1-2': '1 - 2 days' ,
  '2-3': '2 - 3 days' ,
  '3-4': '3 - 4 days' ,
  '4-5': '4 - 5 days' ,
  '>5': '>5 days' ,
};

export const getColumnsWidth = {
  order_number : 200,
  destination_details_ltl : 260,
  time_since_last_attempt_millis : 130,
  time_since_first_attempt_millis : 130,
  time_since_last_scan_millis : 130,
  last_attempt_time : 130,
  comments : 130,
  attempt_count_ltl : 130,
  is_cod_ltl : 130,
  consignment_email_count : 130,
  consignment_rating : 130,
  is_fake_delivery : 130,
  time_since_last_delivery_time_millis : 130,
  ltl_delivery_completion_time : 130,
  rto_initiated_time : 130,
  time_since_last_rto_millis : 130,
  transit_days : 130,
  pieces_detail : 130,
  status_ltl : 170,
  created_at_ltl: 130,

};

export const falseDeliveryOptions = {
  falseDelivery: 'Yes',
  authenticDelivery: 'No',
  noFeedback: 'No Feedback',
};
export const shipperNextStepsOptions = {
  return: 'Return',
  reattempt: 'Reattempt',
  canNotReachCustomer: 'Can\'t reach customer',
};

export const autoAllocationOptions = {
  auto_assignment: 'Auto Assignment',
  manual: 'Manual',
  reassigned: 'Reassigned',
};

export const internalNDRList = {
  address_incomplete: 'Address Incomplete',
  address_incorrect: 'Address Incorrect',
  address_not_found: 'Address Not Found',
  consignee_not_available: 'Consignee Not Available',
  consignee_not_contactable: 'Consignee Not Contactable',
  consignee_refused_delivery: 'Consignee Refused Delivery',
  consignee_cancelled_the_order: 'Consignee Cancelled the Order',
  damaged: 'Damaged',
  future_delivery_requested: 'Future Delivery Requested',
  no_such_consignee_at_the_given_address: 'No Such Consignee At The Given Address',
  premises_closed: 'Premises Closed',
  requested_future_delivery: 'Requested Future Delivery',
  unable_to_collect_payment: 'Unable to collect payment',
  others: 'Others',

};

export function getTagPrettyName(key) {
  const value = tagOptions[key] ?  tagOptions[key] : key;
  return value;
}

export function getLastScanPrettyName(key) {
  const value = lastScanOptions[key] ?  lastScanOptions[key] : key;
  return value;
}
export function getFalseDeliveryPrettyName(key) {
  const value = falseDeliveryOptions[key] ?  falseDeliveryOptions[key] : key;
  return value;
}
export function getShipperNextStepsPrettyName(key) {
  const value = shipperNextStepsOptions[key] ?  shipperNextStepsOptions[key] : key;
  return value;
}

export function getAutoAllocationPrettyName(key) {
  const value = autoAllocationOptions[key] ?  autoAllocationOptions[key] : key;
  return value;
}

export function getInternalNdrPrettyName(key) {
  const value = internalNDRList[key] ?  internalNDRList[key] : key;
  return value;
}
