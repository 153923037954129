import Icon from '@ant-design/icons';
import * as React from 'react';

const LabelSvg = (props) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>baseline-menu-24px (1)</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Label-Dashboard" transform="translate(-16.000000, -22.000000)">
          <g
            id="baseline-menu-24px-(1)"
            transform="translate(16.000000, 22.000000)"
          >
            <polygon id="Shape" points="0 0 20 0 20 20 0 20"></polygon>
            <path
              d="M2.5,15 L17.5,15 L17.5,13.3333333 L2.5,13.3333333 L2.5,15 Z M2.5,10.8333333 L17.5,10.8333333 L17.5,9.16666667 L2.5,9.16666667 L2.5,10.8333333 Z M2.5,5 L2.5,6.66666667 L17.5,6.66666667 L17.5,5 L2.5,5 Z"
              id="Shape"
              fill="currentColor"
              fillRule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const LabelNavIcon = (props) => <Icon component={LabelSvg} {...props} />;
