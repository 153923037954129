import * as React from 'react';
import { Select as AntSelect } from 'antd';

class Select extends React.PureComponent<any, any> {
  static Option = AntSelect.Option;
  static OptGroup = AntSelect.OptGroup;
  state = {
    mainDivRef: null as HTMLElement | null,
  };

  setMainDivRef = (el) => {
    this.setState({
      mainDivRef: el,
    });
  };

  render() {
    return (
      <div ref={this.setMainDivRef} style={{ position: 'relative' }}>
        {
          this.state.mainDivRef ?
            <AntSelect
              getPopupContainer={() => this.state.mainDivRef as HTMLElement}
              {...this.props}/>
            :
            null
        }
      </div>
    );
  }
}

export default Select;
