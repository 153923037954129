import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import store from './store/appStore';
import { BrowserRouter } from 'react-router-dom';
import { JssProvider } from 'react-jss';
import { create as createJss } from 'jss';
import jssPreset from 'jss-preset-default';
import ThemeProvider from './theming/ThemeProvider';
import * as serviceWorker from './serviceWorker';
import Main from './Main';
import { applicationType } from './globals';
import i18n from './i18n';
import jssrtl from 'jss-rtl';
import { isLanguageRTL } from './utils/intl-utils';
import { ConfigProvider } from 'antd';
import { QueryClient, QueryClientProvider } from 'react-query';
import Login from './components/pages/Login/Login';
import ForgotPassword from './components/pages/ForgotPassword/ForgotPassword';
import SSORedirect from './components/pages/LoginPageAuth/SSORedirect';
import OidcOAuth from './components/pages/LoginPageAuth/OidcOAuth';
import { Switch, Route } from 'react-router';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

export const queryClient = new QueryClient();

declare const module: any;
const localJss = createJss({
  plugins: [...jssPreset().plugins, jssrtl({ enabled: isLanguageRTL(i18n.language) })],
});

const basename = applicationType;
const launchApp = (Component: any) => {
  ReactDOM.render(
    <Provider store={store}>
      <JssProvider jss={localJss}>
      <ThemeProvider>
        <BrowserRouter basename={basename}>
          <BrowserRouter >
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/forgot-password" render={props => <ForgotPassword {...props} />} />
              <Route exact path="/ssoRedirect" component={SSORedirect} />
              <Route exact path="/oauth/oidc/:issuer/:organisationId" component={OidcOAuth} />
            </Switch>
          </BrowserRouter>
          <I18nextProvider i18n={i18next}>
            <ConfigProvider direction={isLanguageRTL(i18n.language) ? 'rtl' : 'ltr'}>
              <QueryClientProvider client={queryClient}>
                <Component />
              </QueryClientProvider>
            </ConfigProvider>
          </I18nextProvider>
        </BrowserRouter>
      </ThemeProvider>
      </JssProvider>
    </Provider>,
    document.getElementById('root') as HTMLElement,
  );
};

const launchMain = () => launchApp(Main);
launchMain();

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('/sw.js');
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
if (module.hot && process.env.NODE_ENV !== 'production') {
  // module.hot.accept();
  module.hot.accept('./Main', () => {
    /* eslint-disable-next-line global-require */
    const NextApp = require('./Main').default;
    launchApp(NextApp);
  });
}
