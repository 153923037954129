import { CssType, ThemeType } from '../../../theming/jssTypes';

const styles = (theme: ThemeType): CssType => ({
    container: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    sectionParent: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        height: '100%',
    },
});

export default styles;
