import { getBaggingMasterData, downloadDumpBaggingMaster } from '../../api/TMSDashboard/Setup';
const viewControl = {
  pageTitle: 'Setup',
  buckets: ['tms_setup_bagging_all'],
  globalActions: [],
  fetchData: getBaggingMasterData,
  downloadData: downloadDumpBaggingMaster,
  downloadWithGlobalAppliedFilters: true,
  hubFilterText: 'Origin Hub',
  destinationHubFilterText: 'Bag Dstn. Hub',
  baggingConsignmentDestinationHubFilterText: 'CN Dstn. Hub',
  isActiveOptionList: [
    { id: 'true', label: 'Active' },
    { id: 'false', label: 'Inactive' },
  ],
  filters: ['hub', 'destination_hub', 'bagging_consignment_destination_hub','is_active'],
  actions: [
    {
      key: 'updateOptions',
      name: 'Update Options',
      isAllowed: (masterData) =>
        masterData.ops_dashboard_config?.parts_to_show?.setup_permission_obj
          ?.transport?.bagging?.update,
    },
    {
      key: 'add',
      name: 'Add New',
      isAllowed: (masterData) =>
        masterData.ops_dashboard_config?.parts_to_show?.setup_permission_obj
          ?.transport?.bagging?.update,
    },
    {
      key: 'download',
      name: 'Download',
    },
    {
      key: 'pagination',
      name: 'Pagination',
    },
    {
      key: 'perPageCountChange',
      name: 'Pagination Count',
    },
    {
      key: 'reload',
      name: 'Reload',
    },
  ],
  updateOptions: [
    {
      key: 'delete_via_excel',
      title: 'Delete via Excel',
      action: 'delete',
      type: 'bulk',
    },
    {
      key: 'update_via_excel',
      title: 'Update via Excel',
      action: 'update',
      type: 'bulk',
    },
  ],
};
const buckets = {
  tms_setup_bagging_all: {
    name: 'Bagging',
    filters: [],
    actions: [],
  },
};

const bucketColor = {};

export default { viewControl, buckets, bucketColor };
