import { Input } from 'antd';
import { createUseStyles } from 'react-jss';
import { Col, Checkbox, Row, Slider, Space } from 'antd';
import React, { useState } from 'react';

const useStyle = createUseStyles({
    select: {
        width: '50%',
    },
    checkbox: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0px 12px',
    },
});

const MultiSelect = (props) => {
    const classes = useStyle();
    const [inputValue, setInputValue] = useState(null);
    // const { options } = props;
    const options = [
        {value: 1, label: '1'},
        {value: 2, label: '2'},
    ];
    const onChange = (value) => {
        setInputValue(value);
    };
    return (
       <Checkbox.Group className={classes.checkbox} options={options} value={inputValue} onChange={onChange} />
    );
};

export default MultiSelect;
