import { CssType,
  ThemeType} from '../../../../theming/jssTypes';
const styles = (theme: ThemeType): CssType => ({
  trackConsignmentModal: {
    position: 'relative',
    height: '90%',
    top: '5%',
    padding: 0,
    '& .ant-modal-content': {
      height: '100%',
      overflow: 'auto',
    },
  },
  modalTitle: {
    display: 'inline-block',
    fontWeight: 700,
    fontSize: '16px',
    color: theme.colors.primaryColor,
    marginRight: '60px',
  },
  inputRow: {
    display: 'grid',
    gridTemplateColumns: '12fr 1fr 1fr',
    gridColumnGap: '1em',
  },
  modalInput: {
    display: 'inline-block',
  },
  modalButton: {
    display: 'inline-block',
    maxWidth: '90px',
    backgroundColor: theme.colors.primaryColor,
    color: theme.colors.textOnDarkBg,
  },
  downloadBtn: {
    background: theme.colors.surfaceBg,
    color: theme.colors.primaryColor,
    minWidth: '90px',
  },
  consignmentOverview: {
    marginTop: '30px',
  },
  leftSection: {
    borderLeft: `2px solid ${theme.colors.primaryColor}`,
    paddingLeft: '15px',
  },
  rightSection: {
    borderLeft: `2px solid ${theme.colors.primaryColor}`,
    paddingLeft: '15px',
  },
  consignmentOverviewSection: {
    marginRight: '20%',
  },
  tabsContainer: {
    marginTop: '20px',
  },
  tableContainer: {
    borderLeft: `1px solid ${theme.colors.borderColor}`,
    borderRight: `1px solid ${theme.colors.borderColor}`,
    overflow: 'auto',
  },
  imageContainer: {
    marginTop: '16px',
  },
  imageLabel: {
    marginBottom: '4px',
    fontSize: '16px',
    fontWeight: 600,
  },
  pocImage: {
    width: '250px',
    height: 'auto',
  },
  tooltipIcon: {
    marginLeft: '5px',
  },
});

export default styles;
