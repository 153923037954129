export const getOpsV1DeliveryTaskListRoute = () => '/v1/delivery-task-list';
export const getOpsV1DeliveryTaskMapRoute = () => '/v1/delivery-task-map';
export const getOpsV1PickupTaskListRoute = () => '/v1/pickup-task-list';
export const getOpsV1PickupTaskMapRoute = () => '/v1/pickup-task-map';
export const getOpsV1MediumListRoute = () => '/v1/medium-list';
export const getOpsV1MediumMapRoute = () => '/v1/medium-map';
export const getOpsV1TrackingRoute = () => '/v1/tracking';
export const getOpsV1KmReadingRoute = () => '/v1/km-reading';
export const getOpsV1AttendanceRoute = () => '/v1/attendance';
export const getOpsV1ConsignmentsListRoute = () => '/v1/consignments-list';
export const getOpsV1ConsignmentsMapRoute = () => '/v1/consignments-map';
export const getOpsV1CoordinatorPortalRoute = () => '/v1/coordinator-portal';
export const getOpsV1HubsRoute = () => '/v1/hubs';
export const getOpsV1EmployeesRoute = () => '/v1/employees';
export const getOpsV1TransportRoute = () => '/v1/transport';
