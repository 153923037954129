import * as React from 'react';
import PriceCalculatorRoutes from './PriceCalculatorRoutes';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '../../../theming/jssTypes';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';
import SideMenu from '../../sidemenu/SideMenu';

const styles = (theme: ThemeType): CssType => ({
  scrollWrapper: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  mainDiv: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentDiv: {
    flex: '1 1 auto',
    backgroundColor: theme.colors.pageBg,
  },
});

const PriceCalculator = (props) => {
  return (
    <React.Fragment>
      <DocumentTitle title="Price Calculator" />
        <PriceCalculatorRoutes />
      <SideMenu />
    </React.Fragment>
  );
};

const mapStateToProps = ({ masterData }) => {
  return {
    viewType: masterData.viewType,
  };
};
export default connect(mapStateToProps)(
  withStyles(styles, { injectTheme: true })(PriceCalculator),
);
