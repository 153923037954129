import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import PieceDetailsModal from 'src/components/pages/retail/trip/PieceDetailsModal';

interface IProps extends ReturnType<typeof mapStateToProps> {
    pieces: any;
    record: any;
    handleReload: any;
}

const PieceDetailsCell = (props: IProps) => {
    const { pieces, record, setOneAsDefaultPieces, handleReload } = props;
    const [ modalVisible, setModalVisible ] = React.useState(false);

    const renderModal = () => {
        if (modalVisible) {
            return <PieceDetailsModal
                isVisible={modalVisible}
                handleModalClose={(isSuccess) => {
                    if (isSuccess) {
                        handleReload();
                    }
                    setModalVisible(false);
                }}
                pieceDetails={{
                    pieceDetailsData: record.pieces_detail,
                    selectedReferenceNumber: record.reference_number,
                    ndrReason: record.delivery_failure_reason,
                }}
            />
        }
        return <></>;
    };

    return pieces && pieces.length > 0 ?
        <div>
            {pieces.length}
            <InfoCircleOutlined
                style={{ marginLeft: 8, color: '#085492', fontSize: 14 }}
                onClick={() => setModalVisible(true)} />
            {renderModal()}
        </div>
        : setOneAsDefaultPieces ? 1 : 0;
};

const mapStateToProps = (
    { masterData },
) => {
    return {
        setOneAsDefaultPieces: masterData?.crm_config_master?.set_one_as_default_pieces,
    };
};

export default connect(mapStateToProps)(PieceDetailsCell);
