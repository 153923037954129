import { CssType, ThemeType } from '@theming/jssTypes';
import { MOBILE_BREAK_POINT } from '../../../globals';

const styles = (theme: ThemeType): CssType => ({
  loaderContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
});

export default styles;
