import { CssType, ThemeType } from '../../../../../theming/jssTypes';
import {
  getTextInputStyleForSelectWithInput,
  tablePaginationStyle,
} from '../../../../../theming/cssSnippets/commonSnippets';
import { NAVBAR_HEIGHT } from '../../../../../globals';

const styles = (theme: ThemeType): CssType => ({
  loaderContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  topDiv: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mainDiv: {
    display: 'flex',
    flexDirection: 'column',
    height: `calc( 100vh - ${2 * NAVBAR_HEIGHT}px)`,
    flex: '1 1 auto',
  },
  containerDefaultFilter: {
    height: '56px',
    background: theme.colors.surfaceBg,
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    border: `1px solid ${theme.colors.borderColor}`,
    borderBottom: 0,
    padding: '8px',
    justifyContent: 'space-between',
    '& .ant-select-selector, .ant-input-affix-wrapper, .ant-input-search-button, .ant-picker-range, .ant-input-group': {
      height: '28px !important',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '0px !important',
    },
    '& .ant-btn': {
      borderRadius: '0px !important',
    }
  },
  filterRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: '6px',
  },
  filterItem: {
    display: 'flex',
    alignItems: 'center',
  },
});
export default styles;
