import * as React from 'react';
import withStyles from 'react-jss';
import { Modal, Table, Button } from 'antd';
import { WarningTwoTone, ScanOutlined } from '@ant-design/icons';

import { CssType, ThemeType } from '@theming/jssTypes';
import { humanize } from '@utils/utils';

const styles = (theme: ThemeType): CssType => ({
  failureModal: {
    padding: '2rem 0',
  },
  continueModal: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  failureTable: {
    padding: '1rem 0',
  },
  customPrimaryButton: {
    backgroundColor: theme.colors.primaryColor,
    color: theme.colors.textOnDarkBg,
  },
});

const defaultColumns = [
  {
    title: 'S. No',
    dataIndex: 'serial_number',
  },
  {
    title: 'Piece Reference No.',
    dataIndex: 'reference_number',
  },
];

const ScanErrorModal = (props) => {
  const { columns = defaultColumns, data, isVisible, classes, width } = props;

  const generateColumnForTable = () => {
    const columnData: any = [];
    if (!data || data.length === 0) {
      return columnData;
    }
    const indexList = columns;
    indexList.forEach((item) => {
      const itemToPush: any = {};
      itemToPush['title'] = item.title;
      itemToPush['dataIndex'] = item.dataIndex;
      itemToPush['key'] = item.dataIndex;
      itemToPush['width'] = item.dataIndex === 'serial_number' ? 80 : 150;
      if (item.dataIndex === 'serial_number') {
        itemToPush['render'] = (text, record, index) => index + 1;
      } else {
        itemToPush['render'] = (text, record, index) => {
          return typeof record[item.dataIndex] !== 'string'
            ? text
            : record[item.dataIndex]
            ? humanize(record[item.dataIndex])
            : null;
        };
      }

      columnData.push(itemToPush);
    });

    return columnData;
  };

  const handleModalClose = () => {
    props.onModalClose();
  };

  return (
    <Modal
      title={
        <div>
          <WarningTwoTone twoToneColor="#eb2f96" style={{ fontSize: 18 }} />{' '}
          Errors
        </div>
      }
      className={classes.failureModal}
      visible={isVisible}
      onCancel={handleModalClose}
      destroyOnClose
      maskClosable={false}
      width={width || 300}
      okText="Ok"
      footer={null}
      closable={false}
      okButtonProps={{ className: classes.customPrimaryButton }}
    >
      <div>
        <div>
          The following <b>pieces are missing</b>. Please complete scan to
          proceed
        </div>
        <Table
          className={classes.failureTable}
          pagination={false}
          scroll={{ y: '400px' }}
          columns={generateColumnForTable()}
          dataSource={data}
          rowKey={(record) => record['reference_number']}
        />
        <Button
          className={classes.continueModal}
          onClick={handleModalClose}
          type="primary"
          icon={<ScanOutlined />}
        >
          Continue to Scan
        </Button>
      </div>
    </Modal>
  );
};

const StyledScanErrorModal = withStyles(styles, { injectTheme: true })(
  ScanErrorModal,
);
export default StyledScanErrorModal;
