import { PasswordConstruction } from '../../common/icons/PasswordConstruction';
import { PasswordPolicy } from '../../common/icons/PasswordPolicy';
import { SingleSignOn } from '../../common/icons/SingleSignOn';
import {
  editPasswordConstraint,
  editPasswordPolicy,
} from '../../../api/passwordPolicy';

export const tabs = [
  {
    key: 'password_policy',
    name: 'Security Policy',
    icon: PasswordPolicy,
  },
  {
    key: 'password_constraint',
    name: 'Password Construction',
    icon: PasswordConstruction,
  },
  {
    key: 'single_sign_on',
    name: 'Single Sign On',
    icon: SingleSignOn,
  },
];

export const tabsConfig = {
  password_policy: {
    heading: 'Maintain Security Policy',
    description:
      'Security policy will be created based on the below parameters and constraints. You can set different policy at different levels.',
    edit: editPasswordPolicy,
  },
  password_constraint: {
    heading: 'Password Construction',
    description:
      'Password will be constructed based on the below constraints. You can set different policy at different levels.',
    edit: editPasswordConstraint,
  },
};

export const warningConfig = {
  warning: {
    heading: 'Password Expiry Warning',
    description:
      'Your password will expire soon. Create a new password to prevent further login issues.',
    icon: 'info',
    color: '#F4A13F',
    allowSkip: true,
  },
  expired: {
    heading: 'Password Expired',
    description:
      'Your password has expired. In order to proceed you must change it.',
    icon: 'warning',
    color: 'red',
    allowSkip: false,
  },
  firstLogin: {
    heading: 'Set New Password',
    description:
      'Before proceeding ahead, please set a new password for your account',
    allowSkip: false,
  },
};

export const policyTypes = [
  {
    key: 'employee',
    name: 'Employee',
  },
  {
    key: 'customer',
    name: 'Customer',
  },
];

export const activityColumn = [
  {
    pretty_name: 'Date',
    column_id: 'event_time',
  },
  {
    pretty_name: 'Source',
    column_id: 'source',
  },
  {
    pretty_name: 'User Agent',
    column_id: 'user_agent',
  },
  {
    pretty_name: 'Source IP',
    column_id: 'ip_address',
  },
  {
    pretty_name: 'Action',
    column_id: 'event',
  },
];
