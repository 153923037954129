import { IframeApps } from '../components/common/persistentIframeRenderer/constants';
import {
  IFRAME_SET_VISIBILITY,
  IFRAME_SET_DIMENSIONS,
  IFRAME_SET_LOADED,
  IFRAME_SET_RENDERED,
} from '../actions/constants';
import { objectHasOwnProperty } from '../utils/utils';

const getDefaultDimensions = () => ({
  top: 0,
  left: 0,
  width: 0,
  height: 0,
});

const getDefaultState = () => {
  const defaultState: any = {};
  // By default all iframes are hidden
  for (const value in IframeApps) {
    if (objectHasOwnProperty(IframeApps, value)) {
      defaultState[IframeApps[value]] = {
        visibility: false,
        dimensions: getDefaultDimensions(),
        isLoaded: false,
        isRendered: false,
      };
    }
  }
  return defaultState;
};

export default function (state: any = getDefaultState(), action) {
  switch (action.type) {
    case IFRAME_SET_VISIBILITY: {
      // Reset dimensions if visibility is false
      const dimensionToSet = action.visibility ?
        state[action.appName].dimensions : getDefaultDimensions();
      return {
        ...state,
        [action.appName]: {
          ...state[action.appName],
          visibility: action.visibility,
          dimensions: dimensionToSet,
        },
      };
    }

    case IFRAME_SET_DIMENSIONS: {
      return {
        ...state,
        [action.appName]: {
          ...state[action.appName],
          dimensions: action.dimensions,
        },
      };
    }

    case IFRAME_SET_LOADED: {
      return {
        ...state,
        [action.appName]: {
          ...state[action.appName],
          isLoaded: action.isLoaded,
        },
      };
    }

    case IFRAME_SET_RENDERED: {
      return {
        ...state,
        [action.appName]: {
          ...state[action.appName],
          isRendered: action.isRendered,
        },
      };
    }

    default: return state;
  }
}
