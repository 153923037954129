import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { Avatar, Popover, Badge } from 'antd';
import { getTimeInWordFormat } from 'src/utils/utils';
import { getColor, getNameAbbreviation } from 'src/utils/utils';
import lodash from 'lodash';
import { LiaBot } from 'src/assets/images/LiaBot';
import { readNotification } from '.././notificationAPIs';
import { getAuthenticationHeaders } from 'src/api/utils';

const useStyle = createUseStyles({
  avatarTextStyle: {
    display: 'flex',
    alignItems: 'center',
  },
  timeStyle: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
  },
  timeText: {
    color: '#999',
    textAlign: 'right',
    fontSize: '10px',
    fontWeight: 400,
  },
});
const BadgeReadStatus = (props) => {
  const {
    id,
    notificationId,
    notificationStatus,
    setNotificationStatus,
    userId,
    organizationId,
  } = props;
  return (
    <Popover
      content={notificationStatus[id] ? 'Mark as unread' : 'Mark as read'}
    >
      {notificationStatus[id] ? (
        <div
          onClick={(e) => {
            markAsUnRead({
              notificationHistoryId: id,
              notificationId,
              notificationStatus,
              setNotificationStatus,
              userId,
              organizationId,
            });
            e.stopPropagation();
          }}
        >
          <Badge status="default" />
        </div>
      ) : (
        <div
          onClick={(e) => {
            markAsRead({
              notificationHistoryId: id,
              notificationId,
              notificationStatus,
              setNotificationStatus,
              userId,
              organizationId,
            });
            e.stopPropagation();
          }}
        >
          <Badge status="processing" color="#1890ff" />
        </div>
      )}
    </Popover>
  );
};
const markAsRead = async (props) => {
  const {
    notificationHistoryId,
    notificationId,
    notificationStatus,
    setNotificationStatus,
    userId,
    organizationId,
  } = props;
  setNotificationStatus({
    ...notificationStatus,
    [notificationHistoryId]: true,
  });
  await readNotification({
    notificationHistoryId,
    notificationId,
    isRead: true,
    isReadAll: false,
    organizationId,
    userId,
  });
};
const markAsUnRead = async (props) => {
  const {
    notificationHistoryId,
    notificationId,
    notificationStatus,
    setNotificationStatus,
    userId,
    organizationId,
  } = props;
  setNotificationStatus({
    ...notificationStatus,
    [notificationHistoryId]: false,
  });
  await readNotification({
    notificationHistoryId,
    notificationId,
    isRead: false,
    isReadAll: false,
    userId,
    organizationId,
  });
};
export const TimeAndBadgeComponent = (props) => {
  const classes = useStyle();
  const [userId, setUserId] = useState(null);
  const [organisationId, setOrganisationId] = useState(null);

  useEffect(() => {
    const fetchIds = async () => {
      const data = await getAuthenticationHeaders();
      setUserId(data['user-id']);
      setOrganisationId(data['organisation-id']);
    };

    fetchIds();
  }, []);
  const { item, notificationId, notificationStatus, setNotificationStatus } =
    props;
  const time = getTimeInWordFormat(item.updatedAt);
  return (
    <div className={classes.timeStyle}>
      <div className={classes.timeText}>{time && time + ' ago'}</div>
      <BadgeReadStatus
        id={item.id}
        notificationId={notificationId}
        notificationStatus={notificationStatus}
        setNotificationStatus={setNotificationStatus}
        userId={userId}
        organizationId={organisationId}
      />
    </div>
  );
};

export const ProfileAvatar = (props) => {
  const classes = useStyle();
  const { name, sizeAvatar, sizeText } = props; //20 12
  return (
    <Avatar
      size={sizeAvatar}
      style={{
        top: '-2px',
        backgroundColor:
          name !== 'LIA'
            ? getColor(getNameAbbreviation(name))?.backgroundColor
            : '#E9F7FF',
        color: getColor(getNameAbbreviation(name))?.color,
      }}
    >
      {name === 'LIA' ? (
        <LiaBot />
      ) : (
        <span
          style={{ fontSize: sizeText }}
          className={classes.avatarTextStyle}
        >
          {lodash.upperCase(name[0])}{' '}
        </span>
      )}
    </Avatar>
  );
};
