import Icon from '@ant-design/icons';
import * as React from 'react';

const ListSvg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="1 3 24 24" width="1em">
      <path
        fill="currentColor"
        d="M4 14h4v-4H4v4zm0 5h4v-4H4v4zM4 9h4V5H4v4zm5 5h12v-4H9v4zm0 5h12v-4H9v4zM9 5v4h12V5H9z"
      />
    </svg>
  );
};

export const ListNavIcon = props => (
  <Icon component={ListSvg} {...props} />
);
