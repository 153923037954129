import * as React from 'react';
import withStyles from 'react-jss';
import { CssType,
  ThemeType} from '../../../theming/jssTypes';
import { CloseOutlined } from '@ant-design/icons';
import { Modal, Input, Checkbox, Tabs, Switch, Space, Select } from 'antd';
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc';
import { DragIcon } from '../../common/icons/DragIcon';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setColumns, setCustomFilters, setCurrentCustomFilters,
  setCustomActions, setCustomUpdates, reloadBucket } from '../../../actions/genericConsignmentActions';
import classNames from 'classnames';
import { trackGaEvents } from 'src/utils/googleAnalyticsHelper';
import { CUSTOMISE_COLUMNS, gaType } from 'src/utils/gaConstants';
import { withTranslation } from 'react-i18next';

const Search = Input.Search;
const TabPane = Tabs.TabPane;
const Option = Select.Option;

const autoRefreshOptions = [
  {name: '10 Seconds', value: 10},
  {name: '30 Seconds', value: 30},
  {name: '45 Seconds', value: 45},
  {name: '1 minute', value: 60},
];
const styles = (theme: ThemeType): CssType => ({
  listItemStyle: {
    marginBottom:'10px',
    backgroundColor: '#D5E9FF',
    borderRadius: '4px',
    paddingLeft:'8px',
    paddingRight: '8px',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  mainDiv: {
    height:'350px',
    display: 'flex',
    overflow:'hidden',
    justifyContent: 'space-between',
  },
  leftDiv: {
    width: '48%',
    display: 'flex',
    flexDirection: 'column',
    '& .ant-checkbox-group-item': {
      marginBottom: '12px',
    },
    '& .ant-checkbox-group': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  searchStyle: {
  },
  rightDiv: {
    overflow:'auto',
    border: '1px solid #E8E8E8',
    padding: '12px',
    width: '48%',
    '& ul': {
      padding: 0,
      'list-style-type': 'none',
    },
  },
  checkboxDiv: {
    overflow:'auto',
    border: '1px solid #E8E8E8',
    height: '100%',
    padding: '16px',
  },
  customHeader: {
    fontWeight:600,
    fontSize:'14px',
    height: '10%',
    color:'#424242',
    padding: '6px',
    borderBottom: '1px solid #E8E8E8',
    marginBottom:'12px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  listStyle: {
    height: '90%',
    overflow: 'auto',
  },
  customRightDiv: {
    width: '60%',
    overflow: 'hidden',
  },
  updateDiv: {
    height:'350px',
    overflow:'hidden',
    paddingTop: '12px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  customPrimaryButton: {
    backgroundColor: theme.colors.primaryColor,
    color: theme.colors.textOnDarkBg,
  },
});

class CustomColumnsModal extends React.PureComponent<any, any> {

  state: any = {
    isUploading: false,
    items: [], // Contains value or column_id of selected or existing columns
    allOptions : [],
    allFilters: [],
    existingFilters: [],
    allActions: [],
    existingActions: [],
    updates: [],
    enableUpdateForAll: false,
    existingHeaders: [],
    allHeaders: [],
  };

  componentDidMount() {
    // Created mapping to render the checkboxes
    // pretty_name => label
    // column_id => value
    const { showFiltersAndActions, fullFiltersMetadata,
      filtersToShow, fullActionsList, updatesToShow, actionsToShow,
      fullColumnList, columnsToShow, headersToShow, fullHeadersList, t, maxColumnLimit } = this.props;

    const allOptions = fullColumnList.map((ele) => {
      return (this.isColumnDisabled(ele.column_id || ele.id) ?
      { label: t(ele.pretty_name), value: ele.column_id || ele.id, disabled: true } :
      { label: t(ele.pretty_name), value: ele.column_id || ele.id });
    });
    const MAX_COLUMNS_SELECTED = maxColumnLimit;
    const existingColumns = columnsToShow.map((ele) => {
      return (ele.column_id || ele.id);
    });
    this.setState({
      allOptions,
      items:existingColumns.slice(0, MAX_COLUMNS_SELECTED),
    });
    if(showFiltersAndActions){
      this.setState({
        allFilters: this.getAllOptionsList(fullFiltersMetadata),
        existingFilters: this.getExistingOptionsList(filtersToShow),
        allActions: this.getAllOptionsList(fullActionsList),
        existingActions: this.getExistingOptionsList(actionsToShow),
        allHeaders: this.getAllHeadersList(fullHeadersList),
        existingHeaders: this.getExistingOptionsList(headersToShow),
        updates: updatesToShow,
      });
    }
  }
  disabledTripColumns = ['organisation_reference_number', 'status'];
  getAllHeadersList = (headers) => {
    const { t } = this.props;
    return headers.map((ele) => {
      return { label: t(ele.pretty_name), value: ele.id };
    });
  };
  getAllOptionsList = (options) => {
    const { t } = this.props;
    return options.map((ele) => {
      return { label: t(ele.label), value: ele.id };
    });
  };
  getExistingOptionsList = (options) => {
    return options.map(ele => {
      return { value: ele.id, visibleOutside: ele.show_on_main_dashboard };
    });
  };

  isColumnDisabled(column_id) {
    const { viewType } = this.props;
    const tripViewTypes = ['retailTripsPrimary', 'retailTripsSecondary'];
    if( column_id === 'reference_number' ||
        (column_id === 'organisation_reference_number' && tripViewTypes.includes(viewType))
     ){
      return true;
    }
    return false;
  }

  ListItem = (record: any) => {
    const { classes, t } = this.props;
    return (
      <div className={classes.listItemStyle}>
        <div>
        { this.isColumnDisabled(record.record.value) ? null : <DragIcon /> }
        <span style={{ marginLeft: '8px' }}>
            {t(this.props.fullColumnList.filter(
              x => (x.column_id || x.id) === record.record.value)[0]?.pretty_name)}
          </span>
        </div>
        { this.isColumnDisabled(record.record.value) ? null :
          <div>
            <CloseOutlined onClick={() => this.handleCrossClick(record.record.value)} />
          </div> }
      </div>
    );
  };

  handleCrossClick = (value) => {
    this.setState({
      items: this.state.items.filter(x => x !== value),
    });
  };
  handleFilterCrossClick = (value) => {
    const { existingFilters } = this.state;
    this.setState({
      existingFilters: existingFilters.filter(x => x.value !== value),
    });
  };
  handleActionCrossClick = (value) => {
    const { existingActions } = this.state;
    this.setState({
      existingActions: existingActions.filter(x => x.value !== value),
    });
  };
  handleHeaderCrossClick = (value) => {
    const { existingHeaders } = this.state;
    this.setState({
      existingHeaders: existingHeaders.filter(x => x.value !== value),
    });
  };

  // onCheckboxChange = (checkedValues) => {
  //   const afterSet = new Set(checkedValues);
  //   if (checkedValues.length > this.state.items.length) {
  //     const union = new Set([...this.state.items, ...checkedValues]);
  //     this.setState({
  //       items: Array.from(union),
  //     });
  //   }else {
  //     const intersection = new Set(
  //       [...this.state.items].filter(x => afterSet.has(x)));
  //     this.setState({
  //       items: Array.from(intersection),
  //     });
  //   }
  // }

  SortableItem = SortableElement((props: any) => <this.ListItem record={props} />);
  SortableList = SortableContainer((props: any) => {
    return (
      <ul>
        {props.items.map((value, index) => {
          return this.isColumnDisabled(value) ?
          <this.SortableItem key={`item-${index}`} index={index} value={value} disabled={true}/> :
          <this.SortableItem key={`item-${index}`} index={index} value={value}/>;
        })
        }
      </ul>
    );
  });

  onfilterOrActionLocationChange = (checked, value, itemType) => {
    let options = this.getExistingOptions(itemType);
    options = options.map((ele) => {
      if(value === ele.value){
        ele.visibleOutside = checked;
      }
      return ele;
    });
    this.setExistingOptions(itemType, options);
  };

  getExistingOptions = (itemType) => {
    const { existingFilters, existingActions, existingHeaders } = this.state;
    switch (itemType) {
      case 'filter':
        return existingFilters;
      case 'header':
        return existingHeaders;
      case 'action':
        return existingActions;
      default:
        return [];
    }
  };
  setExistingOptions = (itemType, options) => {
    switch(itemType){
      case 'filter':
        this.setState({ existingFilters: options });
        break;
      case 'action':
        this.setState({ existingActions: options});
        break;
      case 'header':
        this.setState({ existingHeaders: options});
        break;
      default:
        return;
    }
  };
  getFullOptionsList = (itemType) => {
    const { fullFiltersMetadata, fullActionsList, fullHeadersList } = this.props;
    switch (itemType) {
      case 'filter':
        return fullFiltersMetadata;
      case 'action':
        return fullActionsList;
      case 'header':
        return fullHeadersList;
      default:
        return [];
    }
  };
  getAllOptions = (itemType) => {
    const { allFilters, allActions, allHeaders } = this.state;
    switch(itemType){
      case 'filter':
        return allFilters;
      case 'action':
        return allActions;
      case 'header':
        return allHeaders;
      default:
        return [];
    }
  };
  setAllOptions = (itemType, options) => {
    switch(itemType){
      case 'filter':
        this.setState({ allFilters: options });
        break;
      case 'action':
        this.setState({ allActions: options});
        break;
      case 'header':
        this.setState({ allHeaders: options});
        break;
      default:
        return;
    }
  };

  getFilterAndActionListItem = (record, itemType) => {
    const { classes, t } = this.props;
    const allOptions = this.getFullOptionsList(itemType);
    let item = null;
    switch(itemType){
      case 'filter':
        item = record.filterItem.filter;
        break;
      case 'action':
        item = record.actionItem.action;
        break;
      case 'header':
        item = record.headerItem.header;
        break;
      default:
        return;
    }
    return (
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div className={classes.listItemStyle} style={{width: '60%'}}>
          <div>
            {this.isHeaderDisabled(item.value) ? null: <DragIcon />}
            <span style={{ marginLeft: '8px' }}>
              {t(allOptions.filter(x => x.id === item.value)[0]?.label)}
            </span>
          </div>
          <div>
            {this.isHeaderDisabled(item.value) ? null:  <CloseOutlined onClick={() => {
              switch(itemType){
                case 'filter':
                  this.handleFilterCrossClick(item.value);
                  break;
                case 'action':
                  this.handleActionCrossClick(item.value);
                  break;
                case 'header':
                  this.handleHeaderCrossClick(item.value);
                  break;
                default:
                  return;
              }
            }} />}
          </div>
        </div>
        <div style={{width: '40%', textAlign: 'center'}}>
          <Switch
            size="small"
            checked={item.visibleOutside}
            disabled={this.isHeaderDisabled(item.value)}
            style={{ marginTop: 8 }}
            onChange={(e) => this.onfilterOrActionLocationChange(e, item.value, itemType)} />
        </div>
      </div>
    );
  };
  isHeaderDisabled(column_id) {
    if(this.disabledTripColumns.includes(column_id))
    {
      return true;
    }
    return false;
  }
  FilterListItem = (record: any) => this.getFilterAndActionListItem(record, 'filter');
  SortableFilterItem = SortableElement((props: any) => {
    return <this.FilterListItem filterItem={props} />;
  });
  SortableFilterList = SortableContainer((props: any) => {
    return (
      <ul>
        {props.items.map((value, index) => {
          return <this.SortableFilterItem key={`item-${index}`} index={index} filter={value}/>;
        })}
      </ul>
    );
  });

  ActionListItem = (record: any) => this.getFilterAndActionListItem(record, 'action');
  SortableActionItem = SortableElement((props: any) => {
    return <this.ActionListItem actionItem={props} />;
  });
  SortableActionList = SortableContainer((props: any) => {
    return (
      <ul>
        {props.items.map((value, index) => {
          return <this.SortableActionItem key={`item-${index}`} index={index} action={value}/>;
        })}
      </ul>
    );
  });

  HeaderListItem = (record: any) => this.getFilterAndActionListItem(record, 'header');
  SortableHeaderItem = SortableElement((props: any) => {
    return <this.HeaderListItem headerItem={props} />;
  });
  SortableHeaderList = SortableContainer((props: any) => {
    return (
      <ul>
        {props.items.map((value, index) => {
          return this.isHeaderDisabled(value?.value) ? <this.SortableHeaderItem key={`item-${index}`} index={index} header={value} disabled={true}/>
            : <this.SortableHeaderItem key={`item-${index}`} index={index} header={value}/>;
        })}
      </ul>
    );
  });

  onSortEnd = ({ oldIndex, newIndex }) => {
    if (newIndex !== 0) {
      this.setState(({ items }) => ({
        items: arrayMove(items, oldIndex, newIndex),
      }));
    }
  };

  onFilterSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ existingFilters }) => ({
      existingFilters: arrayMove(existingFilters, oldIndex, newIndex),
    }));
  };

  onActionSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ existingActions }) => ({
      existingActions: arrayMove(existingActions, oldIndex, newIndex),
    }));
  };

  onHeaderSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ existingHeaders }) => ({
      existingHeaders: arrayMove(existingHeaders, oldIndex, newIndex),
    }));
  };

  handleModalOk = async () => {
    const { showFiltersAndActions, fullColumnList, setCustomFilters, setColumns, setCustomActions,
      setCurrentCustomFilters, hideActions, setCustomUpdates,
      onModalSubmit, autoRefreshTrip, bucket, reloadBucket } = this.props;
    const { items, existingFilters, existingActions, updates,
      enableUpdateForAll, existingHeaders  } = this.state;
    const columns = items.map((element) => {
      return fullColumnList.filter(x => ((x.column_id || x.id) === element))[0];
    }).filter(col => col);
    trackGaEvents({actionType: CUSTOMISE_COLUMNS}, {bucket});
    if(onModalSubmit) {
      const actions = this.getCustomOptionsList(existingActions);
      const headers = this.getCustomOptionsList(existingHeaders);
      autoRefreshTrip(updates);
      onModalSubmit(columns, actions, headers, updates);
      this.handleModalClose();
      return;
    }
    await setColumns(columns);
    if(showFiltersAndActions){
      const customFilters = this.getCustomOptionsList(existingFilters);
      const customActions = this.getCustomOptionsList(existingActions);
      setCustomFilters(customFilters);
      setCurrentCustomFilters(customFilters);
      !hideActions && setCustomActions(customActions);
      setCustomUpdates({updates, enableUpdateForAll });
    }
    if (reloadBucket) {
      reloadBucket();
    }
    this.handleModalClose();
  };
  getCustomOptionsList = (options) => {
    return options.map((ele) => {
      return { id: ele.value, show_on_main_dashboard: ele.visibleOutside };
    });
  };

  handleModalClose = () => {
    this.props.onModalClose();
  };

  handleSearchChange = (e) => {
    const { t } = this.props;
    this.setState({
      allOptions: this.props.fullColumnList
        .filter(x =>
          x.pretty_name
            .toLowerCase()
            .includes(e.target.value.toLowerCase()),
        )
        .map((ele) => {
          return ( this.isColumnDisabled(ele.column_id || ele.id) ?
            { label: t(ele.pretty_name), value: ele.column_id || ele.id, disabled: true } :
            { label: t(ele.pretty_name), value: ele.column_id || ele.id });
        },
        ),
    });
  };

  onFilterOrActionSearch = (e, searchType) => {
    const optionsList = this.getFullOptionsList(searchType);
    const searchedOptions = optionsList.filter(x => {
      return x.label?.toLowerCase()?.includes(e.target.value.toLowerCase());
    }).map(ele => {
      return { label: ele.label, value: ele.id };
    });
    this.setAllOptions(searchType, searchedOptions);
  };
  handleCheckboxChange = (e) => {
    if (e.target.checked) {
      this.setState({ items: [...this.state.items, e.target.value] });
    } else {
      const items = this.state.items.filter(x => x !== e.target.value);
      this.setState({ items });
    }
  };
  onFilterOrActionCheckboxChange = (e, searchType) => {
    const existingOptions = this.getExistingOptions(searchType);
    if (e.target.checked) {
      this.setExistingOptions(searchType, [...existingOptions, { value: e.target.value }]);
    } else {
      const optionsToShow = existingOptions.filter(x => x.value !== e.target.value);
      this.setExistingOptions(searchType, optionsToShow);
    }
  };
  renderColumns = () => {
    const { classes,t, maxColumnLimit } = this.props;
    const MAX_COLUMNS_SELECTED = maxColumnLimit;
    if (this.state.items?.length > MAX_COLUMNS_SELECTED) {
      this.setState({
        items: this.state.items?.slice(0, MAX_COLUMNS_SELECTED),
      });
    }
    let totalCheckedColumns = 0;
    return (
      <div className={classes.mainDiv}>
        <div className={classes.leftDiv} >
          <div style={{
            padding: '12px', border: '1px solid #E8E8E8' }}>
            <Search
              onChange={this.handleSearchChange}
              className={classes.searchStyle}
              placeholder={t('search')}
            />
          </div>
          <div
            style={{ padding: '16px', color: '#000', fontWeight: 'bold' }}
          >
            {this.state.items?.length || 0} {t('Selected / Max')} {MAX_COLUMNS_SELECTED} {t('columns allowed')}
          </div>
          <div className={classes.checkboxDiv}>
            {this.state.allOptions.map((x) => {
              let isChecked = this.state.items.includes(x.value);
              let shouldDisable = x.disabled || false;
              if (this.state.items?.length >= MAX_COLUMNS_SELECTED && !isChecked) {
                shouldDisable = true;
              }
              if (totalCheckedColumns >= MAX_COLUMNS_SELECTED) {
                isChecked = false;
                shouldDisable = true;
              }
              if(isChecked){
                totalCheckedColumns++;
              }
              return (
                <div style={{ marginBottom: '12px' }} key={x.value}>
                  <Checkbox
                    checked = {isChecked}
                    value={x.value}
                    disabled={shouldDisable}
                    onChange={(e) => { this.handleCheckboxChange(e); }}
                  >
                    {t(x.label)}
                  </Checkbox>
                </div>
              );
            })}
          </div>
        </div>
        <div className={classes.rightDiv} >
          <div style={{
            fontWeight:600, fontSize:'14px',
            color:'#424242',
            marginBottom:'12px' }} >
            {this.props.t('existing_columns')}
          </div>
          <div><this.SortableList distance={1} items={this.state.items} onSortEnd={this.onSortEnd}
            helperClass="sortableHelper" />
            </div>
        </div>
      </div>
    );
  };
  renderFiltersAndActions = (itemType) => {
    const { classes, t } = this.props;
    const { existingFilters, existingActions, existingHeaders } = this.state;
    const allOptions = this.getAllOptions(itemType);
    const existingOptions = this.getExistingOptions(itemType);
    return (
      <div className={classes.mainDiv}>
        <div className={classes.leftDiv} style={{width: '40%'}} >
          <div style={{
            padding: '12px', border: '1px solid #E8E8E8' }}>
            <Search
              onChange={(e) => this.onFilterOrActionSearch(e, itemType)}
              className={classes.searchStyle}
              placeholder={t('filter_search')}
            />
          </div>
          <div className={classes.checkboxDiv}>
            {allOptions.map((x) => {
              return (
                <div style={{ marginBottom: '12px' }} >
                  <Checkbox
                    checked = {existingOptions.findIndex(ele => ele.value === x.value) !== -1}
                    value={x.value}
                    disabled = {this.isHeaderDisabled(x.value) || false}
                    onChange={(e) => this.onFilterOrActionCheckboxChange(e, itemType)} >
                      {t(x.label)}
                  </Checkbox>
                </div>
              );
            })}
          </div>
        </div>
        <div className={classNames(classes.rightDiv, classes.customRightDiv)} >
          <div className={classes.customHeader} >
            <div style={{textTransform: 'capitalize'}}>{itemType === 'header' ? t('details_to_show') : t(`${itemType}s_to_show`) }</div>
            <div>{t('show_on_main_dashboard')}</div>
          </div>
          <div className={classes.listStyle}>
            {
              {
                filter : <this.SortableFilterList distance={1} items={existingFilters}
                onSortEnd={this.onFilterSortEnd} helperClass="sortableHelper" />,
                action : <this.SortableActionList distance={1} items={existingActions}
                onSortEnd={this.onActionSortEnd} helperClass="sortableHelper" />,
                header : <this.SortableHeaderList distance={1} items={existingHeaders}
                onSortEnd={this.onHeaderSortEnd} helperClass="sortableHelper" />,
              }[itemType]
            }
          </div>
        </div>
      </div>
    );
  };
  generateOptionsFromObjectList = (list) => {
    const optionsList: any = [];
    if (list) {
      list.forEach((element) => {
        optionsList.push(<Option value={element.value}>
          {element.name}</Option>);
      });
    }
    return optionsList;
  };
  changeUpdateValue = (val, object, key) => {
    const { updatesActive = [{}] } = this.props;
    const defaultUpdateValues = updatesActive.filter(elem => elem.id === object.id);
    const update = {
     ...defaultUpdateValues[0],
      ...object,
    };
    update[key] = val;
    const items  = this.state.updates.filter(elem => elem.id !== object.id);
    this.setState({ updates: [...items, update]});
  };
  renderUpdates = () => {
    const { classes, onModalSubmit, t } = this.props;
    const { updates } = this.state;
    return (
      <div className={classes.updateDiv}>
        <div>{updates.map((x) => {
          return (
            this.renderUpdate(x)
          );
        })}</div>
        <div>
          <Space>
          <Checkbox
            onChange={(e) => this.setState({enableUpdateForAll: e.target.checked})}>
          </Checkbox>
          {!onModalSubmit && <span>{t('enable_this_setting_on_all_tabs')}</span>}
          </Space>
        </div>
      </div>
    );
  };
  renderUpdate = (update) => {
    const { updatesActive, t } = this.props;
    const activeUpdate = updatesActive.find(element => element.id === update.id);
    switch (update.id) {
      case 'auto_refresh_duration_in_sec':
        return <Space>
          <span>{t(update.label)}</span>
          <Switch
            onChange={(e) => this.changeUpdateValue(e, update, 'isActive')}
            defaultChecked={activeUpdate?.isActive} size="small"
           />
          <Select
            defaultValue={activeUpdate?.value}
            onChange={(e) => this.changeUpdateValue(e, update, 'value')}
            placeholder={t('select_duration')}
          >
            {this.generateOptionsFromObjectList(autoRefreshOptions)}
          </Select>
        </Space>;
      default:
        return null;
    }
  };
  render() {
    const { isVisible, classes, showFiltersAndActions, defaultTab, hideActions,
      updatesToShow, hideFilters, showHeaders, hideColumnTab, t } = this.props;
    const { isUploading , updates} = this.state;
    return (
      <Modal
        title={showFiltersAndActions ? t('customise') : t('customize_columns')}
        visible={isVisible}
        okText={t('update')}
        cancelText={t('cancel')}
        destroyOnClose maskClosable={false}
        width={showFiltersAndActions ? 900 : 800}
        confirmLoading={isUploading}
        onOk={this.handleModalOk}
        onCancel={this.handleModalClose}
        okButtonProps={{ className: classes.customPrimaryButton }}
      >
        {showFiltersAndActions ?
          <Tabs defaultActiveKey={defaultTab || 'column'}>
            {  !hideColumnTab &&
              <TabPane tab={t('columns')} key="column">
                {this.renderColumns()}
              </TabPane>
            }
            {!hideFilters &&
              <TabPane tab={t('filter')} key="filter">
                {this.renderFiltersAndActions('filter')}
              </TabPane>
            }
            {!hideActions &&
              <TabPane tab={t('actions')} key="action">
                {this.renderFiltersAndActions('action')}
              </TabPane>
            }
            {showHeaders &&
              <TabPane tab={t('details')} key="header">
                {this.renderFiltersAndActions('header')}
              </TabPane>
            }
            {updatesToShow.length && <TabPane tab="Updates" key="updates">
                {this.renderUpdates()}
              </TabPane>}
          </Tabs> : this.renderColumns()}
      </Modal>
    );
  }
}

const mapStateToProps = ({ masterData }, ownProps) => {
  const { viewType, bucket } = ownProps;
  const fullFilterList = Array.isArray(masterData.ops_full_filter_list_by_bucket?.[bucket]) ?
    masterData.ops_full_filter_list_by_bucket[bucket] : [];
  const filtersMetadata = Array.isArray(masterData.ops_filter_metadata) ?
  masterData.ops_filter_metadata : [];
  return {
    maxColumnLimit: masterData.dashboard_fetch_max_column_limit || 20,
    columnsToShow: ownProps.columnsToShow && ownProps.columnsToShow ||
      masterData.ops_consignment_column_list[bucket],
    fullColumnList: ownProps.fullColumnList && ownProps.fullColumnList ||
      masterData.ops_full_column_list_by_bucket[bucket],
    filtersToShow: Array.isArray(masterData.ops_filter_list_by_bucket?.[bucket]) ?
      masterData.ops_filter_list_by_bucket[bucket] : [],
    fullFiltersMetadata: fullFilterList.map((ele) => {
      return filtersMetadata.filter(item => item.id === ele)[0];
    }),
    fullHeadersList: ownProps.fullHeadersList || [],
    headersToShow:  ownProps.headersToShow || [],
    fullActionsList: ownProps.fullActionsList ? ownProps.fullActionsList :
      Array.isArray(masterData.ops_full_actions_list_by_bucket?.[bucket]) ?
      masterData.ops_full_actions_list_by_bucket[bucket] : [],
    actionsToShow: ownProps.actionsToShow ? ownProps.actionsToShow :
      Array.isArray(masterData.ops_actions_list_by_bucket?.[bucket]) ?
      masterData.ops_actions_list_by_bucket[bucket] : [],
    updatesToShow: ownProps.updatesList ? ownProps.updatesList :
      Array.isArray(masterData.ops_full_keys_list_by_bucket?.[bucket]) ?
      masterData.ops_full_keys_list_by_bucket[bucket] : [],
    updatesActive: ownProps.activeUpdatesList ? ownProps.activeUpdatesList :
      Array.isArray(masterData.ops_keys_list_by_bucket?.[bucket]) ?
      masterData.ops_keys_list_by_bucket[bucket] : [],
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { viewType, bucket } = ownProps;
  return bindActionCreators({
    setColumns : setColumns(viewType)(bucket),
    setCustomFilters: setCustomFilters(viewType)(bucket),
    setCurrentCustomFilters: setCurrentCustomFilters(viewType)(bucket),
    setCustomActions: setCustomActions(viewType)(bucket),
    setCustomUpdates: setCustomUpdates(viewType)(bucket),
    reloadBucket: reloadBucket(viewType)(bucket)
  }, dispatch);
};

const StyledCustomModal = withStyles(styles, { injectTheme: true })(CustomColumnsModal);
export default withTranslation('translation')(connect(mapStateToProps, mapDispatchToProps)(StyledCustomModal));
