import React, { useState } from 'react';
import { connect } from 'react-redux';
import withStyles, { WithStylesProps } from 'react-jss';
import { CssType, ThemeType } from 'src/theming/jssTypes';
import { message, Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { fetchPublishedLabelList } from 'src/api/labelSetup';
import { downloadFileData, getCodeFreeLabelType } from 'src/utils/utils';
import { printConsignmentLabel } from 'src/api/genericConsignmentView';
import Loader from 'src/components/common/Loader';

const styles = (theme: ThemeType): CssType => ({
  btn: {
    color: 'blue',
    cursor: 'default',
  },
  loader: {
    marginLeft: '3px',
  },
});

interface IProps extends WithStylesProps<ReturnType<typeof styles>>,ReturnType<typeof mapStateToProps> {
    record: any;
    params: any;
    actionId: string;
    actionDetails: any;
}

const DownloadLabelComponent = (props: IProps) => {
    const {
        record,
        classes,
        params,
        actionId,
        actionDetails,
        enableCodeFreeLabel,
        defaultLabelSizeToPrint,
    } = props;

    const { t } = useTranslation();
    const [printModalVisible, setPrintModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedLabel, setSelectedLabel] = useState(null);
    const [labelList, setLabelList] = useState(null);

    const downloadConsignmentLabel = async() => {
        setLoading(true)
        const body = {
          consignmentIds: [record.reference_number],
          isReferenceNumber: true,
          isSmall: enableCodeFreeLabel ? getCodeFreeLabelType(selectedLabel?.value) :
          (defaultLabelSizeToPrint === 'A6' || defaultLabelSizeToPrint === 'a6') ? true : false,
        };
        const response = await printConsignmentLabel(body);
        if (response.isSuccess) {
          downloadFileData(response.data, 'reports', '.pdf');
        } else {
          message.error(response.errorMessage);
        }
        setPrintModalVisible(false);
        setLoading(false);
    };

    const fetchCodeFreeLabels = async () => {    
        setLoading(true);
        const response = await fetchPublishedLabelList();
        setLoading(false);
        if (response.isSuccess) {
          const labelListData = response.data?.metadata_list?.filter(
            (label) => label.is_active,
          );
          setLabelList(labelListData);
          setPrintModalVisible(true);
        } else {
          message.error(response.errorMessage);
        }
    };

    const getCodeFreeLabels = () => {
        return (
            labelList?.map((ele) => (
            <Select.Option key={ele.id} value={ele.label_category}>
                {t(ele.label_category)}
            </Select.Option>
            )) || []
        );
    };

    const renderModal = () => {
        if (printModalVisible) {
            return <Modal
                width={360}
                confirmLoading={loading}
                title={t('Print Label')}
                visible={printModalVisible}
                onOk={downloadConsignmentLabel}
                onCancel={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setPrintModalVisible(false);
                }}
            >
                <div>{t('Label Type')}:</div>
                <Select
                    labelInValue
                    defaultValue={labelList?.[0]?.label_category}
                    placeholder={t('Select Label Type')}
                    onChange={setSelectedLabel}
                    className={classes.labelSelect}
                >
                    {getCodeFreeLabels()}
                </Select>
            </Modal>;
        }
        return <></>;
    };

    return <div
        style={{ display: 'flex', alignItems: 'center' }}
        onClick={(e) => {
            e.preventDefault();
            enableCodeFreeLabel ? fetchCodeFreeLabels()
            : downloadConsignmentLabel();
        }}>
        {t('consignment_label')}
        {renderModal()}
        {
            loading ? <Loader zIndex={2000} size={18} className={classes.loader} /> : <></>
        }
    </div>;
};

const mapStateToProps = (
    { masterData },
) => {
    return {
        enableCodeFreeLabel: masterData.ops_dashboard_config?.parts_to_show?.enable_code_free_label_generation,
        defaultLabelSizeToPrint: masterData?.default_label_size_to_print,
    };
};

export default connect(mapStateToProps)(withStyles(styles, { injectTheme: true })(DownloadLabelComponent));
