const config = {
        CDN_URL: "https://in-cdn.shipsy.io/crm-assets/",
	CSP_VIOLATION_REPORT_URI: "http://ec2-35-85-44-168.us-west-2.compute.amazonaws.com:5002/report/ops_portal",
	LIA_CO_PILOT_CONFIG: {
		URL: 'lia-copilot.shipsy.io',
	},
AES_ENCRYPTION_KEY: "8b7e156228aed2a6abf7152985cf4f9c",
"AUTH_RSA_PUBLIC_KEY": "-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA8U+fRUkt2fV+HiYLPRvVZUH6QHL/PU60+EfKX3A6p9tMc/Ntg2N1B9aBXXjZA4gIFcRO5w6PpzWA7dqrzKQDyIdNtExjkaeA8KLLUm+JE5IN5P14yknCqgb6AtJB3aFWD2VipXH/U4tLaEona7AIpf4mzy6EWZdmC2c3kbb0dW5WQg1yemO+P8JpExvNpAu8fKwZWq1so4hGgR7paYCsaH+qqCy8h5wu8mSHgWldQfJtgGomEBDu5P91ouA59ri6NVeXMCSJMfDvYgW5XiBbCo5kHgtE+0cvtmTA3w58Mr4I6qlJM2teJkPEWZvDLdMlOBaH3M6cH1DR0xcHhkB8cwIDAQAB-----END PUBLIC KEY-----",
	OIDC_SELF_BASE_URL: '.portal.shipsy.io',
	SSO_REDIRECT_BASE_URL: 'https://dashboardlogin.shipsy.io',
	USERPILOT_TOKEN: "NX-0ec2ed6c",
	USERPILOT_BLACKLISTED_ORGS: ["qwiklogistics"],
	CONSIGNMENT_LABEL_URL: "https://elabelprod.shipsy.in",
	API_BASE_URL: "https://app.shipsy.in/api",
	RAVEN_BASE_URL: "https://r.shipsy.in/api",
	BUSINESS_BOOKING_API_URL: "https://onlinebookingmultitenantbackend.shipsy.in",
	ENVIRONMENT: "production",
	LTL_BASE_URL: "https://ltlapi.shipsy.in",
	LTL_POLLING_FREQUENCY: 20000,
	LOG_ROCKET_CONFIG: {
		'shipsyflamingo': ['HITESH.SARUP+SHIPSY@SHIPSY.IO', 'SHIPSY_SUPPORT_ARCHIT.GARG'],
		'1': ['PBLZ838', 'NF981_DEFAULT', 'C29043', 'N05_DEFAULT', 'C20_DEFAULT', 'C28393', 'OF919_DEFAULT', 'C17888', 'AV00109_DEFAULT', 'C26683', 'C20677', 'N20_DEFAULT', 'SHIPSY_SUPPORT_ARCHIT.GARG'],
		'ajex': ['AJ10001447', 'AE10001626', 'AJ10001214', 'AJ10001249', 'AJ10001667', 'AJ10001926', 'AJ10001241', 'AE10001556', 'AJ10000846', 'AJ10001177', 'AJ10000969', 'AJ10001241', 'SHIPSY_SUPPORT_ARCHIT.GARG'],
		'zajel': ['ISMAIL.IBRAHIM@ZAJEL.COM', 'THUSSAIN@ZAJEL.COM', 'ROYJEE14@GMAIL.COM', 'TEK.RAJ@ZAJEL.COM', 'BUTTDANIAL609@GMAIL.COM', 'SHIPSY_SUPPORT_ARCHIT.GARG'],
		'flowpl': ['F00365', 'F01784', 'F01880', 'F01898', 'L_060279', 'SHIPSY_SUPPORT_ARCHIT.GARG'],
		'qwiklogistics': ['7984279382', '9628859089', '9985928248', '7386187160', '8296922076', 'SHIPSY_SUPPORT_ARCHIT.GARG'],
		'connect': ['RIYAS', 'BILAL', 'OMAR.A', 'SAOUD.M', '1570298763161898466', 'SHIPSY_SUPPORT_ARCHIT.GARG'],
	},
	LOG_ROCKET_KEY: "iuqv0m/shipsy",
	INTERCOM_KEY: "ntvhrj4m",
	SHOW_BOOKING: false,
	ROUTING_PLAYGROUND_URL: "https://routingv2.shipsy.in",
	SENTRY_KEY: "https://84e3a8767fbf69ba3252907a8328c75c@sentry.shipsy.io//56",
	ANALYTICS_DATA: [
		{
			src: "https://datastudio.google.com/embed/reporting/1jWTdi57pU6dssxwnEsdP021qUoTiG0_7/page/jp8o",
			colSpan: 24,
			height: "1400px",
		},
		{
			src: "https://datastudio.google.com/embed/reporting/1Sskj3hrItAnYeFNZmvGdBbGNZrIdqFeR/page/tj6o",
			colSpan: 24,
			height: "1400px",
		},
		{
			src: "https://datastudio.google.com/embed/reporting/1Y09hOEq1m-Nsvvdoh5l9P0RUJro3zILs/page/ui2o",
			colSpan: 24,
			height: "1400px",
		},
	],
	GOOGLE_MAPS_API_KEY: "AIzaSyC1TlsvPXIS4YbZp0G9X9Wg9px-hB_gksk",
	USE_HARDCODED_MASTER_DATA: false,
	DOMESTIC_STATUS_FETCH_POLLING_FREQUENCY: 30000,
	ROUTE_OPTIMISATION_BASE_URL: "https://projectxroutingapi.shipsy.in/api",
	RENDER_PERSISTENT_IFRAME_OPS: true,
	HIDE_ADD_TO_EXISTING_TRIP_RETAIL: true,
	HIDE_RETAIL_STORE_HUB_CHANGES: false,
	HIDE_RETAIL_SCHEDULING: true,
	QUERYBUILDER_CONFIG: {
		ANALYTICS_FRONTEND_URL: "analyticsdashboard.shipsy.io",
		BACKENDURL: "https://querybuilderprodapi.shipsy.in",
		DOWNLOAD_PAGE_POLL_INTERVAL: 10000,
	},
	API_PLAYGROUND_CONFIG: {
		URL: "https://apiplayground.shipsy.in",
	},
	FoodDashboard_DISPATCH_POLLING_FREQUENCY: 30000,
	GA_MID: "G-L6R11ZZ96S",
	ZAPSCALE_ORG_LIST: ["connect", "pepcart", "panemirates", "warehousenow", "3mplast",
		"tejcourier", "alamin", "biryanibykilo", "burgerking", "jfl", "more", "zepto", "wellness",
		"sbt", "gmggroup", "zajil", "fnp", "cabtechnologies", "dtdc", "brfglobal", "kelloggsmenat"],
	IS_ENV_PRODUCTION: true,
	GREEN_DEPLOYMENT_URLS: ["greenportal.shipsy.io"],
	PROJECTX_BASE_URL_CONFIG: {
		ksaorgdemo: "https://ksaapi.shipsy.io/api",
		ajex: "https://ksaapi.shipsy.io/api",
		"aone-ops": "https://ksaapi.shipsy.io/api",
		eudemo: "https://projectxeuapi.shipsy.io/api",
		tibbygo: "https://ksaapi.shipsy.io/api",
		"app2opsportal.tibbygo.com": "https://ksaapi.shipsy.io/api",
		ypserverdemo: "https://yemenapi.shipsy.io/api",
		yemenpost: "https://yemenapi.shipsy.io/api",
		uaedemo: "https://projectxuaeapi.shipsy.io/api",
		zajel: "https://projectxuaeapi.shipsy.io/api",
		"ops.zajel.com": "https://projectxuaeapi.shipsy.io/api",
		"gcpsaudidemo": "https://saudiapi.shipsy.io/api",
		hellmann: "https://projectxeuapi.shipsy.io/api",
		amresttest: "https://projectxeuapi.shipsy.io/api",
		"burjeelpharmacy": "https://projectxuaeapi.shipsy.io/api",
		sterling: "https://projectxuaeapi.shipsy.io/api",
		floward: "https://projectxuaeapi.shipsy.io/api",
		shipsyazure: "https://swedenapi.shipsy.io/api",
		chronodiali: "https://projectxuaeapi.shipsy.io/api",
		gmggroupsupercare: "https://projectxuaeapi.shipsy.io/api",
		gmggrouphealthcare: "https://projectxuaeapi.shipsy.io/api",
                 "cross.chronodiali.ma": "https://projectxuaeapi.shipsy.io/api"
	},
	CONSIGNMENT_LABEL_URL_CONFIG: {
		ksaorgdemo: "https://ksaelabelprod.shipsy.io",
		ajex: "https://ksaelabelprod.shipsy.io",
		"aone-ops": "https://ksaelabelprod.shipsy.io",
		eudemo: "https://elabeleuapi.shipsy.io",
		tibbygo: "https://ksaelabelprod.shipsy.io",
		"app2opsportal.tibbygo.com": "https://ksaelabelprod.shipsy.io",
		ypserverdemo: "https://yemenelabelprod.shipsy.io",
		yemenpost: "https://yemenelabelprod.shipsy.io",
		uaedemo: "https://elabeluaeapi.shipsy.io",
		zajel: "https://elabeluaeapi.shipsy.io",
		"ops.zajel.com": "https://elabeluaeapi.shipsy.io",
		"gcpsaudidemo": "https://saudielabel.shipsy.io",
		hellmann: "https://elabeleuapi.shipsy.io",
		amresttest: "https://elabeleuapi.shipsy.io",
		"burjeelpharmacy": "https://elabeluaeapi.shipsy.io",
		sterling: "https://elabeluaeapi.shipsy.io",
		floward: "https://elabeluaeapi.shipsy.io",
		shipsyazure: "https://swedenelabelprod.shipsy.io",
		chronodiali: "https://elabeluaeapi.shipsy.io",
		gmggroupsupercare: "https://elabeluaeapi.shipsy.io",
		gmggrouphealthcare: "https://elabeluaeapi.shipsy.io",
                 "cross.chronodiali.ma": "https://elabeluaeapi.shipsy.io"
	},
	RAVEN_BASE_URL_CONFIG: {
		ksaorgdemo: "https://ksaraven.shipsy.io/api",
		ajex: "https://ksaraven.shipsy.io/api",
		"aone-ops": "https://ksaraven.shipsy.io/api",
		eudemo: "https://projectraveneuapi.shipsy.io",
		tibbygo: "https://ksaraven.shipsy.io/api",
		"app2opsportal.tibbygo.com": "https://ksaraven.shipsy.io/api",
		ypserverdemo: "https://yemenraven.shipsy.io/api",
		yemenpost: "https://yemenraven.shipsy.io/api",
		uaedemo: "https://projectravenuaeapi.shipsy.io/api",
		zajel: "https://projectravenuaeapi.shipsy.io/api",
		"ops.zajel.com": "https://projectravenuaeapi.shipsy.io/api",
		"gcpsaudidemo": "https://saudiraven.shipsy.io/api",
		hellmann: "https://projectraveneuapi.shipsy.io",
		amresttest: "https://projectraveneuapi.shipsy.io",
		"burjeelpharmacy": "https://projectravenuaeapi.shipsy.io/api",
		sterling: "https://projectravenuaeapi.shipsy.io/api",
		floward: "https://projectravenuaeapi.shipsy.io/api",
		shipsyazure: "https://swedenraven.shipsy.io/api",
		chronodiali: "https://projectravenuaeapi.shipsy.io/api",
		gmggroupsupercare: "https://projectravenuaeapi.shipsy.io/api",
		gmggrouphealthcare: "https://projectravenuaeapi.shipsy.io/api",
                 "cross.chronodiali.ma": "https://projectravenuaeapi.shipsy.io/api"
	},
	LTL_BASE_URL_CONFIG: {
		ksaorgdemo: "https://ksaltlapi.shipsy.io",
		ajex: "https://ksaltlapi.shipsy.io",
		"aone-ops": "https://ksaltlapi.shipsy.io",
		eudemo: "https://ltleuapi.shipsy.io",
		tibbygo: "https://ksaltlapi.shipsy.io",
		"app2opsportal.tibbygo.com": "https://ksaltlapi.shipsy.io",
		ypserverdemo: "http://yemenltlapi.shipsy.io",
		yemenpost: "http://yemenltlapi.shipsy.io",
		uaedemo: "https://ltluaeapi.shipsy.io",
		zajel: "https://ltluaeapi.shipsy.io",
		"ops.zajel.com": "https://ltluaeapi.shipsy.io",
		"gcpsaudidemo": "https://saudiltlapi.shipsy.io",
		hellmann: "https://ltleuapi.shipsy.io",
		amresttest: "https://ltleuapi.shipsy.io",
		"burjeelpharmacy": "https://ltluaeapi.shipsy.io",
		sterling: "https://ltluaeapi.shipsy.io",
		floward: "https://ltluaeapi.shipsy.io",
		shipsyazure: "http://swedenltlapi.shipsy.io",
		chronodiali: "https://ltluaeapi.shipsy.io",
		gmggroupsupercare: "https://ltluaeapi.shipsy.io",
		gmggrouphealthcare: "https://ltluaeapi.shipsy.io",
                 "cross.chronodiali.ma": "https://ltluaeapi.shipsy.io"
	},
	BUSINESS_BOOKING_CONFIG: {
		eudemo: "https://obbv2euapi.shipsy.io",
		ajex: "https://ksaobbv2api.shipsy.io",
		"aone-ops": "https://ksaobbv2api.shipsy.io",
		tibbygo: "https://ksaobbv2api.shipsy.io",
		"app2opsportal.tibbygo.com": "https://ksaobbv2api.shipsy.io",
		ypserverdemo: "https://yemenobbv2api.shipsy.io",
		yemenpost: "https://yemenobbv2api.shipsy.io",
		uaedemo: "https://obbv2uaeapi.shipsy.io",
		zajel: "https://obbv2uaeapi.shipsy.io",
		"ops.zajel.com": "https://obbv2uaeapi.shipsy.io",
		"gcpsaudidemo": "https://saudiobbv2api.shipsy.io",
		hellmann: "https://obbv2euapi.shipsy.io",
		amresttest: "https://obbv2euapi.shipsy.io",
		"burjeelpharmacy": "https://obbv2uaeapi.shipsy.io",
		sterling: "https://obbv2uaeapi.shipsy.io",
		floward: "https://obbv2uaeapi.shipsy.io",
		shipsyazure: "https://swedenobbv2api.shipsy.io",
		ksaorgdemo: "https://ksaobbv2api.shipsy.io",
		chronodiali: "https://obbv2uaeapi.shipsy.io",
		gmggroupsupercare: "https://obbv2uaeapi.shipsy.io",
		gmggrouphealthcare: "https://obbv2uaeapi.shipsy.io",
                 "cross.chronodiali.ma": "https://obbv2uaeapi.shipsy.io"
	},
	DEVREV_APP_ID: "don:core:dvrv-us-1:devo/xXjPo9nF:plug_setting/1",
	FIREBASE_CONFIG: {
		apiKey: "AIzaSyBS7-u48IAJzePDpwM-nbKNXULw8kD7HL4",
		authDomain: "notification-service-418009.firebaseapp.com",
		databaseURL: "https://notification-service-418009-default-rtdb.firebaseio.com",
		projectId: "notification-service-418009",
		storageBucket: "notification-service-418009.appspot.com",
		messagingSenderId: "413910393309",
		appId: "1:413910393309:web:18279bf70b4ef30ee9d634",
		measurementId: "G-9SKNTTVQEC"
	},
	FIREBASE_VAPID_KEY: "BMJxxP73j8ok0wOvo5Ak_qked8D9oZW4-FhMsqie5JGh08HUvWcWpQKuX7vT2KIzQ9ahK7Q8ujQdcs15G7y8m4A",
	FIRESTORE_DB_ID: "notification-service-prod",
        NEWRELIC_SNIPPET: ";window.NREUM||(NREUM={});NREUM.init={privacy:{cookies_enabled:false},ajax:{deny_list:[\"bam.nr-data.net\"]}};\n\n;NREUM.loader_config={accountID:\"1549482\",trustKey:\"1549482\",agentID:\"1120291041\",licenseKey:\"789c65050f\",applicationID:\"1120291041\"};\n;NREUM.info={beacon:\"bam.nr-data.net\",errorBeacon:\"bam.nr-data.net\",licenseKey:\"789c65050f\",applicationID:\"1120291041\",sa:1};\n",
        NEWRELIC_SAMPLING: 0.1
};

module.exports.default = config;
