import Icon from '@ant-design/icons';
import * as React from 'react';
const IntegrationMarketplaceSvg = (props: any) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Menu" transform="translate(-24.000000, -343.000000)">
            <g id="Integration-Marketplace" transform="translate(24.000000, 343.000000)">
                <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                <g transform="translate(2.500000, 2.500000)" fill="#666666">
                    <path d="M13.253,5.747 L19,5.747 L19,0 L13.253,0 L13.253,5.747 Z M14.858,4.142 L17.395,4.142 L17.395,1.605 L14.858,1.605 L14.858,4.142 Z" id="Fill-1"></path>
                    <path d="M6.16,8.698 L8.697,8.698 L8.697,6.16 L6.16,6.16 L6.16,8.698 Z M4.556,10.302 L10.302,10.302 L10.302,4.555 L4.556,4.555 L4.556,10.302 Z" id="Fill-2"></path>
                    <path d="M6.16,12.84 L8.697,12.84 L8.697,10.302 L6.16,10.302 L6.16,12.84 Z M4.556,14.444 L10.302,14.444 L10.302,8.697 L4.556,8.697 L4.556,14.444 Z" id="Fill-3"></path>
                    <path d="M10.302,12.84 L12.839,12.84 L12.839,10.302 L10.302,10.302 L10.302,12.84 Z M8.698,14.444 L14.444,14.444 L14.444,8.697 L8.698,8.697 L8.698,14.444 Z" id="Fill-4"></path>
                    <path d="M16.541,19 L2.459,19 C1.103,19 0,17.897 0,16.541 L0,2.459 C0,1.103 1.103,0 2.459,0 L11.157,0 L11.157,1.604 L2.459,1.604 C1.988,1.604 1.604,1.988 1.604,2.459 L1.604,16.541 C1.604,17.012 1.988,17.396 2.459,17.396 L16.541,17.396 C17.012,17.396 17.396,17.012 17.396,16.541 L17.396,7.844 L19,7.844 L19,16.541 C19,17.897 17.897,19 16.541,19" id="Fill-5"></path>
                </g>
            </g>
        </g>
    </g>
  </svg>
);

export const IntegrationMarketplaceIcon =
  (props) => <Icon component={IntegrationMarketplaceSvg} {...props} />;
