import { applicationType } from './../globals';

export const getUsecaseType = (masterdata) => {
  const showLtlOptions = masterdata.show_ltl_options;
  const showRetailOptions = masterdata.show_retail_options;

  if (applicationType === 'crm') {
    return 'logistics_crm';
  }

  // Application type is ops
  if (showLtlOptions) {
    return 'ltl_ops';
  }
  if (showRetailOptions) {
    return 'retail_ops';
  }

  return 'logistics_ops';
};
