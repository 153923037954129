import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { FIREBASE_CONFIG, FIRESTORE_DB_ID } from './globals';
import { getMessaging } from 'firebase/messaging';

// Initialize Firebase
const isFirebaseConfigEmpty = Object.keys(FIREBASE_CONFIG).length === 0;

let app = null;
let messaging = null;
let firestore = null;
let auth = null;

try {
  if (!isFirebaseConfigEmpty) {
    app = initializeApp(FIREBASE_CONFIG);
    const userAgent = navigator.userAgent;
    messaging = !userAgent.includes('iPhone') ? getMessaging(app) : null;
    firestore = getFirestore(app, FIRESTORE_DB_ID);
    auth = getAuth(app);
  }
} catch (error) {
  console.error('Error initializing Firebase:', error);
}

export { messaging, firestore, auth };
