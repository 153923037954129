import Icon from '@ant-design/icons';
import * as React from 'react';

const RateMastersSvg = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Setup_Tooltip" transform="translate(-16.000000, -746.000000)">
              <g id="Group-6" transform="translate(0.000000, 58.000000)">
                  <g id="Rate_Masters" transform="translate(16.000000, 688.000000)">
                      <rect id="Rectangle" fillRule="nonzero" x="0" y="0" width="24" height="24"></rect>
                      <path d="M8.83508,8.04643 C8.83508,7.60342 9.19421,7.24429 9.63722,7.24429 L14.3815,7.24429 C14.8245,7.24429 15.1836,7.60342 15.1836,8.04643 C15.1836,8.48944 14.8245,8.84857 14.3815,8.84857 L9.63722,8.84857 C9.19421,8.84857 8.83508,8.48944 8.83508,8.04643 Z" id="Path" fill="#666666"></path>
                      <path d="M8.83508,12 C8.83508,11.557 9.19421,11.1979 9.63722,11.1979 L17.5444,11.1979 C17.9874,11.1979 18.3465,11.557 18.3465,12 C18.3465,12.443 17.9874,12.8021 17.5444,12.8021 L9.63722,12.8021 C9.19421,12.8021 8.83508,12.443 8.83508,12 Z" id="Path" fill="#666666"></path>
                      <path d="M6.46289,3.30214 C6.46289,2.85913 6.82202,2.5 7.26503,2.5 L19.9165,2.5 C20.3595,2.5 20.7186,2.85913 20.7186,3.30214 C20.7186,3.74514 20.3595,4.10427 19.9165,4.10427 L8.06716,4.10427 L8.06716,19.1164 C8.06716,19.5594 7.70803,19.9186 7.26503,19.9186 C6.82202,19.9186 6.46289,19.5594 6.46289,19.1164 L6.46289,3.30214 Z" id="Path" fill="#666666"></path>
                      <path d="M8.83508,15.1628 C8.83508,14.7198 9.19421,14.3607 9.63722,14.3607 L17.5444,14.3607 C17.9874,14.3607 18.3465,14.7198 18.3465,15.1628 C18.3465,15.6058 17.9874,15.965 17.5444,15.965 L9.63722,15.965 C9.19421,15.965 8.83508,15.6058 8.83508,15.1628 Z" id="Path" fill="#666666"></path>
                      <path d="M19.9165,2.5 C20.3595,2.5 20.7187,2.85913 20.7187,3.30214 L20.7187,19.1164 C20.7187,19.5594 20.3595,19.9186 19.9165,19.9186 C19.4735,19.9186 19.1144,19.5594 19.1144,19.1164 L19.1144,3.30214 C19.1144,2.85913 19.4735,2.5 19.9165,2.5 Z" id="Path" fill="#666666"></path>
                      <path d="M4.82629,20.6979 C4.82629,20.2548 5.18542,19.8957 5.62843,19.8957 L18.3906,19.8957 C18.8336,19.8957 19.1927,20.2548 19.1927,20.6979 C19.1927,21.1409 18.8336,21.5 18.3906,21.5 L5.62843,21.5 C5.18542,21.5 4.82629,21.1409 4.82629,20.6979 Z" id="Path" fill="#666666"></path>
                      <path d="M3.30005,8.04643 C3.30005,7.60342 3.65918,7.24429 4.10218,7.24429 L6.41898,7.24429 C6.86199,7.24429 7.22112,7.60342 7.22112,8.04643 C7.22112,8.48944 6.86199,8.84857 6.41898,8.84857 L4.10218,8.84857 C3.65918,8.84857 3.30005,8.48944 3.30005,8.04643 Z" id="Path" fill="#666666"></path>
                      <path d="M4.10218,7.24429 C4.54519,7.24429 4.90432,7.60342 4.90432,8.04643 L4.90432,19.1164 C4.90432,19.5594 4.54519,19.9186 4.10218,19.9186 C3.65918,19.9186 3.30005,19.5594 3.30005,19.1164 L3.30005,8.04643 C3.30005,7.60342 3.65918,7.24429 4.10218,7.24429 Z" id="Path" fill="#666666"></path>
                      <path d="M19.9165,18.3143 C20.3595,18.3143 20.7187,18.6734 20.7187,19.1164 C20.7187,19.7486 20.4675,20.3549 20.0205,20.8019 C19.5735,21.2489 18.9673,21.5 18.3351,21.5 C17.8921,21.5 17.533,21.1409 17.533,20.6979 C17.533,20.2549 17.8921,19.8957 18.3351,19.8957 C18.5418,19.8957 18.74,19.8136 18.8861,19.6675 C19.0323,19.5213 19.1144,19.3231 19.1144,19.1164 C19.1144,18.6734 19.4735,18.3143 19.9165,18.3143 Z" id="Path" fill="#666666"></path>
                      <path d="M7.26504,18.3143 C7.70804,18.3143 8.06717,18.6734 8.06717,19.1164 C8.06717,19.7486 7.81605,20.3549 7.36904,20.8019 C6.92204,21.2489 6.31577,21.5 5.68361,21.5 C5.2406,21.5 4.88147,21.1409 4.88147,20.6979 C4.88147,20.2549 5.2406,19.8957 5.68361,19.8957 C5.89029,19.8957 6.0885,19.8136 6.23465,19.6675 C6.3808,19.5213 6.4629,19.3231 6.4629,19.1164 C6.4629,18.6734 6.82203,18.3143 7.26504,18.3143 Z" id="Path" fill="#666666"></path>
                      <path d="M4.10218,18.3143 C4.54519,18.3143 4.90432,18.6734 4.90432,19.1164 C4.90432,19.3231 4.98642,19.5213 5.13257,19.6675 C5.27872,19.8136 5.47693,19.8957 5.68361,19.8957 C6.12662,19.8957 6.48575,20.2549 6.48575,20.6979 C6.48575,21.1409 6.12662,21.5 5.68361,21.5 C5.05145,21.5 4.44518,21.2489 3.99818,20.8019 C3.55117,20.3549 3.30005,19.7486 3.30005,19.1164 C3.30005,18.6734 3.65918,18.3143 4.10218,18.3143 Z" id="Path" fill="#666666"></path>
                  </g>
              </g>
          </g>
      </g>
    </svg>
    );
};

export const RateMastersIcon = props => (
  <Icon component={RateMastersSvg} {...props} />
);
