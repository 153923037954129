import { CssType, ThemeType } from '../../../../../theming/jssTypes';

const styles = (theme: ThemeType): CssType => ({
    container: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
    },
    tableContainer: {
        flex: 1,
        height: '100%',
    },
    filterActionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: '8px',
    },
    filterParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    filterContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '8px',
    },
    actionsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    filterNodeWrapper: {
        marginRight: '8px',
        display: 'flex',
        alignItems: 'center',
    },
    actionNodeWrapper: {
        marginRight: '8px',
    },
    actionPaginationContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    reloadPaginationContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
});

export default styles;
