import React from 'react';
import { ViewActionType } from 'src/types/view.types';
import DownloadAction from './components/DownloadAction';

interface ParamType {
    pageId: string;
    viewIndex: number;
    sectionIndex: number;
}

const actions = {
    // download: (action: ViewActionType, params: ParamType) => {
    //     return <DownloadAction
    //         actionData={action}
    //         pageId={params.pageId}
    //         viewIndex={params.viewIndex}
    //         sectionIndex={params.sectionIndex}
    //     />;
    // },
};

export default actions;
