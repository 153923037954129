import {
  fetchAllPartialDeliveryReasons,
  createPartialDeliveryReason,
  updatePartialDeliveryReason,
} from '@api/appFlowConfig';

const getKeyMappingPartialDeliverySingle = (masterData) => {
  return (
    masterData.ops_dashboard_config?.config?.ltl_partial_delivery_config
      ?.csv_field_list || []
  );
};

const partialDeliveryFormItemLayout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};

const viewControl = {
  pageTitle: 'Setup',
  buckets: ['partialDeliveryReasons'],
  globalActions: [],
  addOptions: [
    {
      key: 'add_single',
      title: 'Single',
      action: 'add',
      type: 'single',
    },
  ],
  id: 'reason_id',
  fetchData: fetchAllPartialDeliveryReasons,
  onSingleAdd: createPartialDeliveryReason,
  add: {
    sampleUrl: undefined,
    downloadSampleCsvFromApiCall: false,
    downloadSampleCsv: null,
    title: 'Add Partial Delivery Reason',
  },
  edit: {
    title: 'Edit Partial Delivery Reason',
    okText: 'Update',
  },
  warningMsg: 'No Partial Delivery Reason to upload',
  successMsg: 'Uploaded Successfully',
  updateMsg: 'Updated Successfully',
  modalHeading: 'Upload Partial Delivery Reason',
  singleUploadMapping: getKeyMappingPartialDeliverySingle,
  onSingleUpdate: updatePartialDeliveryReason,
  formItemLayout: partialDeliveryFormItemLayout,
};

const buckets = {
  partialDeliveryReasons: {
    name: 'Partial Delivery Reason',
    filters: [],
    actions: [],
  },
};

const bucketColor = {};

export default { viewControl, buckets, bucketColor };
