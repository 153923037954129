import React, { useState, useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { getSignedUrlApi } from '@api/getSignedUrl';
import lodash from 'lodash';

type ReadSignedRequirements = {
  url: string;
  renderingCb: (url: string) => any;
};

export const ReadWithSignedUrl = (props: ReadSignedRequirements) => {
  const { url, renderingCb } = props;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [signedUrl, setSignedUrl] = useState<string>();

  useEffect(() => {
    fetchAndRenderSignedUrl();
  }, []);

  const fetchAndRenderSignedUrl = async () => {
    const resp = await getSignedUrlApi(url);
    setIsLoading(false);
    const respSignedUrl: string = resp?.data?.signedUrl ?? '';

    if (!!errorMessage) {
      setErrorMessage(errorMessage);
      return;
    }
    if (!respSignedUrl) {
      setErrorMessage('Unable to retrieve signed Url');
      return;
    }
    setSignedUrl(respSignedUrl);
  };

  if (isLoading) {
    return <LoadingOutlined />;
  }
  if (!!errorMessage) {
    // change this to tooltip showing the message and preview being a static error
    return <span>{errorMessage}</span>;
  }

  return renderingCb(signedUrl);
};
