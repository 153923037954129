import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppMetadata } from '../constants';
import { setIframeVisibility, setIframeDimensions, setIframeRendered } from
  '../../../../actions/persistentIframeActions';
import { sendRoutingEvent, sendCredentials } from '../iframeMessageUtils';
import { withSize } from 'react-sizeme';
import { getAuthenticationHeaders } from '../../../../api/utils';
import lodash from 'lodash';

const defaultParams = {
  contractCode: ''
};

function PersistentIframeController(props) {
  const { route, setIframeVisibility, setIframeDimensions,
    appName, setIframeRendered, destroyBeforeMount, params = defaultParams} = props;
  const enclosingDiv = React.useRef(null);
  const dimensionRef = React.useRef({} as any);

  React.useEffect(() => {
    // setTimeout(() => {
    //   // Show iframe
    //   setIframeVisibility(appName, true);
    // }, 50);

    // added timeout to unmount old tab before mounting new tab for antd Tabs
    let interval = null;
    if (destroyBeforeMount) {
      setTimeout(() => {
        console.log('injector mounted', route);
        setIframeVisibility(appName, true);
        setPosition(true);
        interval = setInterval(() => {
          setPosition(true);
        }, 50);
      }, 10);
    }
    else {
      console.log('injector mounted', route);
      // Show iframe
      setIframeVisibility(appName, true);
      // Set interval to check for size and position
      setPosition(true);
      interval = setInterval(() => {
        setPosition(true);
      }, 50);
    }

    return () => {
      // Hide iframe
      setIframeVisibility(appName, false);

      // Clear interval
      clearInterval(interval);

      console.log('injector unmounted', route);
    };
  }, []);

  React.useEffect(() => {
    console.log('sending routing event', route);
    
    if(typeof params === 'object' && params.contractCode){
      const paramsToSend = formParamsToSendInRoutingEvent(route, params);
      sendRoutingEvent(appName, paramsToSend);
    } else {
      sendRoutingEvent(appName, route);
    }
  }, [route, params.contractCode]);

  const sendAppCredentials = async () => {
    const shouldPassCredentials = AppMetadata[appName]?.passCredentials || false;
    const credentials = await getAuthenticationHeaders();
    if(shouldPassCredentials) {
      sendCredentials(appName, credentials);
    }
  };

  React.useEffect(() => {
    sendAppCredentials();
  }, []);

  React.useEffect(() => {
    setIframeRendered(appName, true);
  });

  const setPosition = (visibility) => {
    if (!enclosingDiv.current) {
      return;
    }
    const rect = (enclosingDiv.current as any).getBoundingClientRect();
    const { top, left, width } = rect;
    let { height } = rect;
    const oldValue = dimensionRef.current;
    if (
      oldValue.top !== top ||
      oldValue.left !== left ||
      oldValue.width !== width ||
      oldValue.height !== height
    ) {
      if(visibility && height===0){
        height = 750;
      }
      setIframeDimensions(appName, {
        top,
        left,
        width,
        height,
      });
      dimensionRef.current = { top, left, width, height };
    }
  };

  function formParamsToSendInRoutingEvent(route, objectToSend = {}){
    if(!objectToSend || lodash.isEmpty(objectToSend)) return {to: route, params: {}};
    return {
      to: route,
      params: objectToSend
    };
  }

  return (
    <div
      style={{ width: '100%', height: '100%', flex: '1 1 auto' }}
      ref={enclosingDiv}
    >
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setIframeVisibility,
    setIframeDimensions,
    setIframeRendered,
  }, dispatch);
};

export default connect(null, mapDispatchToProps)(PersistentIframeController);
