// tslint:disable: max-line-length

import Icon from '@ant-design/icons';
import * as React from 'react';
const DelayedDelivered = (props: any) => (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 9.91668C6.16528 9.91668 6.30382 9.86077 6.41563 9.74897C6.52743 9.63716 6.58333 9.49862 6.58333 9.33334C6.58333 9.16807 6.52743 9.02952 6.41563 8.91772C6.30382 8.80591 6.16528 8.75001 6 8.75001C5.83472 8.75001 5.69618 8.80591 5.58437 8.91772C5.47257 9.02952 5.41667 9.16807 5.41667 9.33334C5.41667 9.49862 5.47257 9.63716 5.58437 9.74897C5.69618 9.86077 5.83472 9.91668 6 9.91668ZM5.41667 7.58334H6.58333V4.08334H5.41667V7.58334ZM1.91667 12.25C1.59583 12.25 1.32118 12.1358 1.09271 11.9073C0.864236 11.6788 0.75 11.4042 0.75 11.0833V2.91668C0.75 2.59584 0.864236 2.32119 1.09271 2.09272C1.32118 1.86425 1.59583 1.75001 1.91667 1.75001H4.36667C4.49306 1.40001 4.70451 1.11807 5.00104 0.904177C5.29757 0.690288 5.63056 0.583344 6 0.583344C6.36944 0.583344 6.70243 0.690288 6.99896 0.904177C7.29549 1.11807 7.50694 1.40001 7.63333 1.75001H10.0833C10.4042 1.75001 10.6788 1.86425 10.9073 2.09272C11.1358 2.32119 11.25 2.59584 11.25 2.91668V11.0833C11.25 11.4042 11.1358 11.6788 10.9073 11.9073C10.6788 12.1358 10.4042 12.25 10.0833 12.25H1.91667ZM1.91667 11.0833H10.0833V2.91668H1.91667V11.0833ZM6 2.47918C6.12639 2.47918 6.2309 2.43786 6.31354 2.35522C6.39618 2.27258 6.4375 2.16807 6.4375 2.04168C6.4375 1.91529 6.39618 1.81077 6.31354 1.72814C6.2309 1.6455 6.12639 1.60418 6 1.60418C5.87361 1.60418 5.7691 1.6455 5.68646 1.72814C5.60382 1.81077 5.5625 1.91529 5.5625 2.04168C5.5625 2.16807 5.60382 2.27258 5.68646 2.35522C5.7691 2.43786 5.87361 2.47918 6 2.47918Z" fill="white"/>
    </svg>
);
export const DelayedDeliveredIcon = (props) => (
    <Icon component={DelayedDelivered} {...props} />
);
