import {
    fetchAllCustomers,
    createCustomer,
    updateCustomer,
    downloadCustomer,
    downloadCsvForCreate,
    uploadCsvForCreate,
    downloadCsvForUpdate,
    uploadCsvForUpdate,
    downloadVirtualSeries
} from '@api/customerManagement';


const viewControl = {
    pageTitle: 'Customer Management',
    buckets: ['customerManage'],
    globalActions: [],
    downloadOptions : [
        {
            key: 'download',
            title: 'Download Data',
            action: 'download',
        },
        {
            key: 'download_virtual_series_dump',
            title: 'Download Virtual Series Dump',
            action: 'download_virtual_series_dump'
        }
    ],
    addOptions: [
        {
            key: 'add_single',
            title: 'Single',
            action: 'add_single',
            type: 'single',
        },
        {
            key: 'add',
            title: 'Bulk',
            action: 'add_bulk',
            type: 'bulk',
        },
    ],
    fetchData: fetchAllCustomers,
    downloadData: downloadCustomer,
    add_bulk: {
        downloadSampleCsvFromApiCall: false,
        sampleUrlWithParams: downloadCsvForCreate,
        title: 'Add Customer',
        onBulkUpload: uploadCsvForCreate,
    },
    update: {
        downloadSampleCsvFromApiCall: false,
        sampleUrlWithParams: downloadCsvForUpdate,
        title: 'Update Customer',
        onBulkUpload: uploadCsvForUpdate,
    },
    params: 'customer_array',
    modalHeading: 'Customer Management',
    warningMsg: 'No Customers to upload',
    successMsg: 'Customers Uploaded Successfully',
    updateMsg: 'Customers Updated Successfully',
    failureArrayColumns: [
        {
            key: 'customer_code',
            dataIndex: 'customer_code',
            title: 'Customer Code',
        },
        {
            key: 'message',
            dataIndex: 'message',
            title: 'Message',
        },
    ],
    bulkUploadMapping: null,
    sectionWiseForm: true,
    useDrawer: true,
    uploadProps: {
        accept: '.pdf, .png, .jpeg, .jpg',
        name: 'file',
        multiple: false,
        showUploadList: { showPreviewIcon: true, showRemoveIcon: true },
    },
    firstRowAsHeader: true,
    onSingleAdd: createCustomer,
    onSingleUpdate: updateCustomer,
    id: 'code',
};

const buckets = {
    customerManage: {
        name: 'Customer Management',
        filters: [],
        actions: [],
    },
};

const bucketColor = {};

export default { viewControl, buckets, bucketColor };
