import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import withStyles from 'react-jss';
import * as GenericComponents from '../form';
import { getIntegrationMarketplaceRoute } from 'src/routing/utils';
import { withRouter } from 'react-router';
import * as DefaultIcons from '../../../common/icons';
import * as AntdIcons from '@ant-design/icons';
import { Spin, Button, message, notification } from 'antd';
import useGenericState from 'src/hooks/useGenericState';
import Navbar from 'src/components/navbar/Navbar';
import { NAVBAR_HEIGHT } from 'src/globals';
const Editor = React.lazy(() => import('@monaco-editor/react'));
import { executeIntegrationAction } from 'src/api/integrationMarketplace';
import * as lodash from 'lodash';

const styles = (theme) => ({
  parent: {
    height: `calc( 100vh - ${NAVBAR_HEIGHT}px )`,
    width: '100vw',
    backgroundColor: '#EFF2F5',
  },
  editorWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& .form-section .form-group label': {
      fontSize: '20px',
    },
    '& .ant-legacy-form-explain': {
      position: 'absolute',
    },
    '& .decorationsOverviewRuler': {
      width: '10px !important',
    },
    '& .scrollbar': {
      width: '10px !important',
      '& .slider': {
        right: '0 !important',
        borderRadius: '20px !important',
        width: '10px !important',
      },
    },
  },
  buttonWrapper: {
    marginTop: '30px',
    display: 'flex',
    width: '55%',
    justifyContent: 'flex-end',
  },
});

interface IProps {
  classes: {
    [X in keyof ReturnType<typeof styles>]: string;
  };
  history: any;
  integrations: any;
  categories: any;
  services: any;
}

const RawForm = (props: IProps) => {
  const { classes, history, integrations, categories, services } = props;
  const location = useLocation();

  if (!location || !location?.state) history.push(getIntegrationMarketplaceRoute());
  const { integrationId, action, currentIntegrationData } = location?.state || {};
  const serviceId = lodash.get(integrations, `${integrationId}.service_id`);
  const baseUrl = lodash.get(services, `${serviceId}.baseUrl`, '');

  const [state, setState] = useGenericState({
    isValidated: true,
    config: {},
    isSaving: false,
  });

  const { isValidated, config, isSaving } = state;
  const categoryPrettyName = categories[integrations[integrationId]?.category]?.pretty_name;

  React.useEffect(() => {
    if (!Object.keys(integrations).length || !Object.keys(categories).length
      || !location || !location?.state || !integrationId) {
      history.push(getIntegrationMarketplaceRoute());
    }
    if (currentIntegrationData) setState({ config: currentIntegrationData });
  }, []);

  const handleEditorValidation = (markers) => {
    setState({
      isValidated: !markers.filter((mark) => mark.severity === 8).length,
    });
  };

  const handleOnEditorMount = (editor, monaco) => {
    editor.updateOptions({
      scrollBeyondLastLine: false,
    });
  };

  const blacklistedFields = ['isActive', 'isEditable'];

  const validateField = () => {
    for (const field of blacklistedFields) {
      if (currentIntegrationData.hasOwnProperty(field)) {
        if (!config.hasOwnProperty(field) || config[field] !== currentIntegrationData[field]) {
          return false;
        }
      }
    }
    return true;
  };

  const handleSave = async () => {
    if (!validateField()) {
      message.error(`Cannot modify one of these fields [${blacklistedFields.join(', ')}]`);
      return;
    }
    setState({ isSaving: true });
    if (!isValidated) {
      message.error('Invalid JSON Format!');
    } else {
      const response = await executeIntegrationAction(baseUrl, integrationId, 'EDIT', config);
      if(response.isSuccess) {
        message.success(`${categories[integrations[integrationId]?.category]?.pretty_name} Updated Successfully!`);
        notification.warning({
          message: 'Warning!',
          description:
            'You have updated a system defined webhook. Please note that the functionality may be impacted.',
          duration: 6,
          placement: 'topRight',
        });
        history.push(`${getIntegrationMarketplaceRoute()}/${integrationId}`);
      } else {
        message.error(response.errorMessage);
      }
    }
    setState({ isSaving: false });
  };

  console.log(config);
  return (
    <>
      <Navbar pageTitle={`Edit ${categoryPrettyName}`} />
      <div className={classes.parent}>
        <div className={classes.editorWrapper}>
          <Editor
            height="75%"
            width="55%"
            language="json"
            value={JSON.stringify(config, null, 2)}
            onChange={(e) => setState({ config: JSON.parse(e) })}
            onValidate={(markers) => handleEditorValidation(markers)}
            loading={<Spin />}
            onMount={handleOnEditorMount}
          />
          <div className={classes.buttonWrapper}>
            <Button type="primary"  style={{ marginRight: '20px' }} onClick={() => history.push(`${getIntegrationMarketplaceRoute()}/${integrationId}`)}>Cancel</Button>
            <Button type="primary" loading={isSaving} onClick={() => handleSave()}>Save</Button>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ integrationMarketplace }) => {
  return {
    integrations: integrationMarketplace.integrations,
    categories: integrationMarketplace.categories,
    services: integrationMarketplace.services,
  };
};

const styledRawForm = withStyles(styles, { injectTheme: true })(RawForm);
export default withRouter(connect(mapStateToProps, null)(styledRawForm));
