import * as React from 'react';
import withStyles, { WithStylesProps } from 'react-jss';
import { connect } from 'react-redux';
import styles from './TripMapEditUnplannedTaskTab.style';
import { bindActionCreators } from 'redux';
import TripMapEditUnplannedTaskFilterBar from './TripMapEditUnplannedTaskFilterBar/TripMapEditUnplannedTaskFilterBar.component';
import FixedHeaderTable from 'src/components/common/FixedHeaderTable/FixedHeaderTable';
import {
  MAP_EDIT_VIEW_TYPE,
  UNPLANNED_CN_OFFLINE_ACTIONS,
} from '../TripManagerMapEditUtils';
import {
  applyBucketSort,
  fetchConsignments,
  setSelectedRowKeys,
} from 'src/actions/genericConsignmentActions';
import { createSelector } from 'reselect';
import { Link } from 'react-router-dom';
import { getDetailsRoute } from 'src/routing/utils';
import { formatDateTime, getConsignmentTypePrettyName } from 'src/utils/utils';
import SplitPanes from 'src/components/common/SplitPanes';
import { useTripManagerMapEditContext } from '../StateManagement/TripManagerMapEdit.context';
import {
  updateDragEndTime,
  updateCNDataOffline,
  setUnplannedCnData,
} from '../StateManagement/TripManagerMapEdit.actions';
import TripMapEditMapView from '../TripMapEditMapView/TripMapEditMapView.component';
import RightClickPopup from 'src/components/common/RightClickPopup';
import { EditOutlined } from '@ant-design/icons';
import { message } from 'antd';

interface IProps extends WithStylesProps<typeof styles> {
  bucket: string;
  isTableLoading: boolean;
  allUnplannedCNsStateData: any;
  dateFilterType: string;
  applyBucketSort: any;
  fetchConsignments: any;
  showLogisticsExpressOptions: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  selectedRowKeys: any;
  // eslint-disable-next-line react/no-unused-prop-types
  setSelectedRowKeys: any;
  // eslint-disable-next-line react/no-unused-prop-types
  consignmentColumnsToShow: any;
  currHub: any;
  fromDateString: any;
  toDateString: any;
  mapViewComponent: any;
}

const TripMapEditUnplannedTaskTab = function (props: IProps) {
  const {
    classes,
    bucket,
    isTableLoading,
    allUnplannedCNsStateData,
    dateFilterType,
    applyBucketSort,
    fetchConsignments,
    selectedRowKeys,
    currHub,
    fromDateString,
    toDateString,
    mapViewComponent,
  } = props;
  const { tripManagerMapEditState, tripManagerMapEditDispatch } =
    useTripManagerMapEditContext();
  const { isMapView, allUnplannedCNs, allTrips, editActions } =
    tripManagerMapEditState;
  const [popupState, setPopupState] = React.useState({
    record: null as any,
    visible: false,
    x: 0,
    y: 0,
  });

  const tableData = allUnplannedCNs;
  const firstRender = React.useRef(true);

  React.useEffect(() => {
    if (!firstRender.current || !editActions || !editActions.length) {
      tripManagerMapEditDispatch(
        setUnplannedCnData([...allUnplannedCNsStateData]),
      );
    } else if (firstRender.current) {
      firstRender.current = false;
    }
  }, [allUnplannedCNsStateData]);

  const rowSelection = createSelector(
    (p: IProps) => p.selectedRowKeys,
    (p: IProps) => p.setSelectedRowKeys,
    (selectedRowKeys, setSelectedRowKeys) => {
      return {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          setSelectedRowKeys(selectedRowKeys);
        },
      };
    },
  );

  const handleTableChange = (pagination, filters, sorter) => {
    let filter: any = null;
    if (sorter.column) {
      filter = {
        sortBy: sorter.columnKey,
        descendingOrder: sorter.order === 'descend',
        bucketSort: true,
      };
    } else {
      filter = {
        sortBy: dateFilterType,
        descendingOrder: true,
        bucketSort: false,
      };
    }
    applyBucketSort(filter);
  };

  const generateDataColumns = createSelector(
    (p: IProps) => p.consignmentColumnsToShow,
    (consignmentColumnsToShow) => {
      const columnData: any = [];
      const indexList = consignmentColumnsToShow;
      const fixedColumnList = ['reference_number'];
      const { showLogisticsExpressOptions } = props;
      indexList.forEach((item) => {
        const itemToPush = {};
        const columnId = item.sort_key || item.column_id;
        itemToPush['title'] = item.pretty_name;
        itemToPush['dataIndex'] = columnId;
        itemToPush['width'] = 170;
        itemToPush['sorter'] = item.is_sortable;
        itemToPush['key'] = columnId;

        switch (columnId) {
          case 'reference_number':
            itemToPush['render'] = (x, record) => {
              return (
                <Link
                  to={{
                    pathname: getDetailsRoute(
                      encodeURIComponent(record[columnId]),
                    ),
                    state: { viewType: MAP_EDIT_VIEW_TYPE },
                  }}
                >
                  {x}
                </Link>
              );
            };
            break;
          case 'consignment_type':
            itemToPush['render'] = (x, record) => {
              return x
                ? getConsignmentTypePrettyName(x, showLogisticsExpressOptions)
                : 'Delivery';
            };
            break;
          case 'destination_address':
            itemToPush['render'] = (x, record) => {
              const colLine1 = `${columnId}_line_1`;
              const colLine2 = `${columnId}_line_2`;
              return (
                <div>
                  {record[colLine1]}
                  {record[colLine2] != null ? `, ${record[colLine2]}` : null}
                </div>
              );
            };
            break;
          case 'created_at':
            itemToPush['render'] = (x) => {
              if (x) {
                return formatDateTime(x);
              }
              return '';
            };
            break;
          default:
            itemToPush['render'] = (x, record) => x;
        }

        if (fixedColumnList.indexOf(columnId) >= 0) {
          itemToPush['width'] = 150;
          itemToPush['fixed'] = 'left';
          columnData.unshift(itemToPush);
        } else {
          columnData.push(itemToPush);
        }
      });

      columnData.unshift({
        title: 'S.No.',
        key: 'index',
        render: (text, record, index) => index + 1,
        width: 80,
        fixed: 'left',
      });

      return columnData;
    },
  );

  const handleOnRow = (record) => ({
    onContextMenu: (event) => {
      event.preventDefault();
      if (selectedRowKeys && selectedRowKeys.length) {
        if (!popupState.visible) {
          document.addEventListener('click', function onClickOutside() {
            setPopupState({ ...popupState, visible: false });
            document.removeEventListener('click', onClickOutside);
          });
        }
        setPopupState({
          record,
          visible: true,
          x: event.clientX,
          y: event.clientY,
        });
      }
    },
  });

  const renderTable = () => {
    return (
      <FixedHeaderTable
        locale={{ emptyText: 'No consignments found' }}
        rowKey={(record: any) => record['reference_number']}
        loading={isTableLoading}
        rowSelection={rowSelection(props)}
        pagination={false}
        dataSource={tableData}
        columns={generateDataColumns(props)}
        onChange={handleTableChange}
        style={{ flex: '1 1 0' }}
        onRow={handleOnRow}
      />
    );
  };

  const handleDragEnd = () => {
    tripManagerMapEditDispatch(updateDragEndTime());
  };

  const renderCNs = () => {
    if (isMapView) {
      return (
        <SplitPanes
          left={renderTable()}
          right={mapViewComponent}
          onDragEnd={handleDragEnd}
        />
      );
    }
    return renderTable();
  };

  const handleCreateTrip = () => {
    const requestBody = {
      changeVehicle: true,
      consignments: [...selectedRowKeys],
      dateField: dateFilterType,
      endpointType: 'selected',
      fromDate: fromDateString,
      hubId: currHub?.id,
      source: 'dashboard_custom_trip',
      toDate: toDateString,
    };

    updateCNDataOffline(tripManagerMapEditDispatch, {
      actionType: UNPLANNED_CN_OFFLINE_ACTIONS.CREATE_TRIP,
      tripId: `temp_trip_${+new Date()}`,
      newData: requestBody,
      allUnplannedCNs: tableData,
      allTrips,
      editActions,
    });
    message.success('Created Trip');
  };

  React.useEffect(() => {
    const allUnplannedCNsRefNum = allUnplannedCNs.map(
      (cn) => cn.reference_number,
    );
    const newSelectedRowKeys = selectedRowKeys.filter((id) =>
      allUnplannedCNsRefNum.includes(id),
    );
    if (newSelectedRowKeys.length !== selectedRowKeys.length) {
      setSelectedRowKeys(newSelectedRowKeys);
    }
  }, [allUnplannedCNs]);

  return (
    <div className={classes.mainDiv}>
      <TripMapEditUnplannedTaskFilterBar bucket={bucket} />
      <div className={classes.tableContainer}>{renderCNs()}</div>
      <RightClickPopup
        {...popupState}
        menuConfig={[
          {
            id: 'create_trip',
            name: 'Create Trip',
            icon: <EditOutlined />,
            handler: handleCreateTrip,
          },
        ]}
      />
    </div>
  );
};

const TripMapEditUnplannedTaskTabStyled = withStyles(styles, {
  injectTheme: true,
})(TripMapEditUnplannedTaskTab);

const mapStateToProps = (
  { genericConsignmentReducer, masterData, cachedData },
  ownProps,
) => {
  const viewType = MAP_EDIT_VIEW_TYPE;
  const { bucket } = ownProps;
  const currentViewReducer = genericConsignmentReducer[viewType];
  const currentBucketReducer = currentViewReducer.buckets[bucket];
  return {
    isTableLoading: currentBucketReducer.isLoading,
    allUnplannedCNsStateData: currentBucketReducer.data,
    selectedRowKeys: currentBucketReducer.selectedRowKeys,
    dateFilterType: currentViewReducer.globalAppliedFilters?.dateFilterType,
    fromDateString: currentViewReducer.globalAppliedFilters?.startDate,
    toDateString: currentViewReducer.globalAppliedFilters?.endDate,
    consignmentColumnsToShow: masterData.ops_consignment_column_list[bucket],
    showLogisticsExpressOptions: masterData.show_logistics_express_options,
    currHub: cachedData.currHub,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const viewType = MAP_EDIT_VIEW_TYPE;
  const { bucket } = ownProps;
  return bindActionCreators(
    {
      fetchConsignments: fetchConsignments(viewType),
      setSelectedRowKeys: setSelectedRowKeys(viewType)(bucket),
      applyBucketSort: applyBucketSort(viewType)(bucket),
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TripMapEditUnplannedTaskTabStyled);
