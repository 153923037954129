import React from 'react';
import withStyles, { WithStylesProps } from 'react-jss';
import { ThemeType } from '../../theming/jssTypes';
import { Dropdown, Menu } from 'antd';

const styles = (theme: ThemeType) => ({
    overlay: {
        width: '150px',
        boxShadow: '0 1px 4px 0 rgba(0,0,0,0.2)',
    },
    dropdownMenu: {
        '& .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title': {
            lineHeight: '14px',
            padding: '6px 8px',
        },
    },
    ellipsisIcon: {
        fontSize: 16,
        cursor: 'pointer',
    },
});

interface IProps extends WithStylesProps<ReturnType<typeof styles>> {
    menu: {
        label: string;
        disabled?: boolean;
        onClick: () => void;
    }[];
    trigger?: 'click' | 'hover';
    className?: string;
    label: React.ReactNode | Element | string;
}

const GenericDropdown = (props: IProps) => {
    const { classes, className, menu, trigger, label } = props;
    return <Dropdown
    className={className}
    overlayClassName={classes.overlay}
    overlay={
        <Menu className={classes.dropdownMenu}>
            {
                menu.map((item, index) => <Menu.Item
                    key={item.label}
                    onClick={item.onClick}
                    disabled={item.disabled ?? false}
                >
                    {item.label}
                </Menu.Item>)
            }
        </Menu>
    }
    trigger={[trigger]}
  >
    {label}
  </Dropdown>;
};

GenericDropdown.defaultProps = {
    trigger: 'hover',
    className: '',
};

export default withStyles(styles, { injectTheme: true })(GenericDropdown);
