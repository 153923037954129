import Icon from '@ant-design/icons';
import * as React from 'react';

const PushNotificationSvg = (props) => {
  return (
    <svg
      width="16px"
      height="22px"
      viewBox="0 0 16 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Push Notifications</title>
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Push_Notification-1"
          transform="translate(-20.000000, -294.000000)"
          fill="currentColor"
          fill-rule="nonzero"
        >
          <g
            id="noun-notification-smartphone-1375006"
            transform="translate(20.691156, 294.000000)"
          >
            <path
              d="M14.5375071,4.01415305 L7.1105951,4.01415305 C6.6844948,4.01415305 6.33929369,4.38170452 6.33929369,4.83501904 L6.33929369,10.8076918 C6.33929369,11.2609909 6.68465919,11.6284806 7.1105951,11.6284806 L8.02498667,11.6284806 L9.19119503,12.859432 L10.275604,11.6284806 L14.5373897,11.6284806 C14.96349,11.6284806 15.3086911,11.2610063 15.3086911,10.8076918 L15.308844,4.83463285 C15.308844,4.38164274 14.9633259,4.01415305 14.5375426,4.01415305 L14.5375071,4.01415305 Z M12.817568,8.81252716 C12.817568,8.88991393 12.753968,8.95266832 12.6755385,8.95266832 L11.6766079,8.95266832 C11.6342601,9.27926145 11.3737428,9.53661236 11.0426041,9.57824265 C10.6411219,9.62877828 10.2738859,9.34879717 10.2226928,8.95266832 L9.22376214,8.95266832 C9.1453326,8.95266832 9.08173262,8.88991393 9.08173262,8.81252716 L9.08173262,8.43132419 C9.08173262,8.39677916 9.09488079,8.36359194 9.11811924,8.33794567 L9.51349585,7.90349223 L9.51334298,7.2495336 C9.51471893,6.68609577 9.85335825,6.17668463 10.3765415,5.95084608 C10.3765415,5.95084608 10.4607791,5.91192677 10.6081628,5.88251138 C10.6057167,5.86546497 10.6029648,5.84856956 10.6029648,5.83077087 C10.6029648,5.63179892 10.7663992,5.4702363 10.9683575,5.4702363 C11.1701631,5.4702363 11.3337501,5.6314977 11.3337501,5.83077087 C11.3337501,5.85234254 11.3308453,5.87361377 11.327176,5.8941277 C11.4465796,5.92113023 11.5236315,5.95054486 11.5236315,5.95054486 C12.0467951,6.17606674 12.3854601,6.68533113 12.38683,7.24876897 L12.38683,7.936055 L12.5634109,8.09957941 L12.7817291,8.34064121 C12.8051206,8.36643693 12.8181157,8.399472 12.8181157,8.43401974 L12.8179628,8.81250785 L12.817568,8.81252716 Z"
              id="Shape"
            ></path>
            <path
              d="M10.3939196,11.9587424 L10.3939196,16.9875892 L0.995212475,16.9874384 L0.995212475,1.69467736 L10.3939196,1.69467736 L10.3939196,3.68682981 L11.3881144,3.68682981 L11.3881144,0.875240243 C11.3881144,0.391625984 11.072866,0 10.6836224,0 L0.704492078,0 C0.315248463,0 0,0.391896311 0,0.875240243 L0,20.2028186 C0,20.6864329 0.315401103,21.0780589 0.704492078,21.0780589 L10.6836224,21.0780589 C11.072866,21.0780589 11.3881144,20.6861626 11.3881144,20.2028186 L11.3881144,11.9590164 L10.3939196,11.9587424 Z M5.69456602,19.9353076 C5.16177432,19.9353076 4.72972498,19.5090025 4.72972498,18.9832946 C4.72972498,18.4575866 5.16177432,18.0312816 5.69456602,18.0312816 C6.22735772,18.0312816 6.65940706,18.4575866 6.65940706,18.9832946 C6.65940706,19.5090025 6.22735772,19.9353076 5.69456602,19.9353076 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const PushNotificationNavIcon = (props) => (
  <Icon component={PushNotificationSvg} {...props} />
);
