import { getAllTmsDataIST, getTmsSummaryMetricsDataIST } from '../../api/TMSDashboard/ISTView';
const defaultRetailStatusFilterOptions = [
  'reachedathub',
  'outfordelivery',
  'delivered',
  'attempted',
  'inscan_at_hub',
  'in_transit',
  'pickup_awaited',
  'pickup_scheduled',
  'out_for_pickup',
  'not_picked_up',
  'pickup_completed',
];

const viewControl = {
  pageTitle: 'Trips',
  buckets : [
    'tms_ist_created',
    'tms_ist_arrived',
    'tms_ist_unloading',
    'tms_ist_loading',
    'tms_ist_loaded',
    'tms_ist_departed',
    'tms_ist_completed',

  ],
  navBar : {
    left : ['single_hub_search', 'date_filter'],
    right : ['search_redirect'],
  },
  globalActions:[],
  dateFilterOptions : [
    { key:'last_status_change_time', val:'Last Updated' },
    { key:'created_at', val:'Creation Date' },
  ],
  searchFilterOptions : [
    { key:'vehicle_number', val: 'Vehicle No.' },
    { key:'ist_reference_number', val: 'Trip No.' },
  ],
  fetchData : getAllTmsDataIST,
  fetchSummaryMetrics : getTmsSummaryMetricsDataIST,
};
const buckets = {
  tms_ist_created : {
    name: 'Created',
    filters : [
      'status',
      'attempt_count',
      'service_type',
      'load_type',
      'customer_code',
      'consignment_type',
      'proximity',
      'orderType',
      'purchasingGroup',
      'reference_number_search',
    ],
    actions : [
      // 'addCN',
    ],
    status_filter: defaultRetailStatusFilterOptions,

  },
  tms_ist_arrived : {
    name:'Arrived',
    filters : [
      // 'planned', // not included in filterpopup
      'status',
      'attempt_count',
      'service_type',
      'load_type',
      'customer_code',
      'consignment_type',
      'proximity',
      'orderType',
      'purchasingGroup',
      'reference_number_search',
    ],
    actions : [
      // 'createRoute',
      // 'unassign',
    ],
    status_filter: defaultRetailStatusFilterOptions,

  },
  tms_ist_unloading : {
    hidePagination : false,
    hidePerPageCount :false,
    name:'Unloading',
    filters : [
      // 'planned', // not included in filterpopup
      'status',
      'attempt_count',
      'service_type',
      'load_type',
      'customer_code',
      'consignment_type',
      'proximity',
      'orderType',
      'purchasingGroup',
      'reference_number_search',
    ],
    actions : [
      // 'createRoute',
      // 'unassign',
    ],
    status_filter: defaultRetailStatusFilterOptions,

  },
  tms_ist_loading : {
    name: 'Loading',
    filters : [
      'status',
      'attempt_count',
      'service_type',
      'load_type',
      'customer_code',
      'consignment_type',
      'proximity',
      'orderType',
      'purchasingGroup',
      'reference_number_search',
    ],
    actions: [
    ],
    status_filter : defaultRetailStatusFilterOptions,
  },
  tms_ist_loaded : {
    name: 'Loaded',
    filters : [
      'status',
      'attempt_count',
      'service_type',
      'load_type',
      'customer_code',
      'consignment_type',
      'proximity',
      'orderType',
      'purchasingGroup',
      'reference_number_search',
    ],
    actions: [
    ],
    status_filter : defaultRetailStatusFilterOptions,
  },
  tms_ist_departed : {
    name: 'Departed',
    filters : [
      'status',
      'attempt_count',
      'service_type',
      'load_type',
      'customer_code',
      'consignment_type',
      'proximity',
      'orderType',
      'purchasingGroup',
      'reference_number_search',
    ],
    actions: [
    ],
    status_filter : defaultRetailStatusFilterOptions,
  },
  tms_ist_completed: {
    name: 'Completed',
    filters : [
      'status',
      'attempt_count',
      'service_type',
      'load_type',
      'customer_code',
      'consignment_type',
      'proximity',
      'orderType',
      'purchasingGroup',
      'reference_number_search',
    ],
    actions: [
    ],
    status_filter : defaultRetailStatusFilterOptions,
  },
};

const bucketColor = {
  tms_ist_created: '#EC5D2D',
  tms_ist_arrived: '#DA1A83',
  tms_ist_unloading: '#A11DAA',
  tms_ist_loading: '#93C848',
  tms_ist_loaded: '#27C69B',
  tms_ist_departed: '#25A4D0',
  tms_ist_completed: '#19CA2C',
};

export default { viewControl, buckets, bucketColor };
