import React from 'react';
import { connect } from 'react-redux';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '../../theming/jssTypes';
import { isAuthenticationFlowPage } from '../../api/utils';
const svgImage = require('../../assets/images/404.svg');


const styles = (theme: ThemeType): CssType => {
  // console.log('>>> THEME: ', theme);
  return {
    message1: {
      textAlign: 'center',
      color: theme.colors.primaryColor,
      fontSize: theme.sizes.fontSize,
      fontFamily: theme.fonts.fontFamily,
    },
    message2: {
      marginTop: '2%',
      textAlign: 'center',
      color: theme.colors.secondaryColor,
      fontSize: theme.sizes.fontSize,
      fontFamily: theme.fonts.fontFamily,
    },
   };
};


const NotFound404 = (props) => {
 const { classes, theme } = props;


 if (isAuthenticationFlowPage()) {
   return null;
 }


 return (
   <div>
     <div style={{ width: '100%', textAlign: 'center', marginTop: '20%' }}>
       <img className={classes.errorIcon} src={svgImage} alt="404 Icon" />
     </div>
     <div className={classes.message1}>SORRY!</div>
     <div className={classes.message2}>The page you're looking for could not be found</div>
   </div>
 );
};


const mapStateToProps = (state) => {
 return {};
};

// Connect the component to the Redux store to get the theme
export default connect(mapStateToProps)(withStyles(styles, { injectTheme: true })(NotFound404));
