import * as moment from 'moment';
import {
  GENERIC_ANALYTICS_UPDATE_GLOBAL_FILTER,
  GENERIC_ANALYTICS_UPDATE_CHART_DATA,
  GENERIC_ANALYTICS_CHART_ENABLE_LOADING,
  GENERIC_ANALYTICS_APPLY_BUCKET_FILTER,
  GENERIC_ANALYTICS_RESET_BUCKET_FILTER,
  GENERIC_ANALYTICS_RESET_ALL_BUCKETS_EXCEPT_FILTERS,
  GENERIC_ANALYTICS_BUCKET_ENABLE_LOADING,
  GENERIC_ANALYTICS_CHART_DISABLE_LOADING,
  GENERIC_ANALYTICS_SET_ACTIVE_BUCKET,
  GENERIC_ANALYTICS_UPDATE_TABLE_DATA,
  GENERIC_ANALYTICS_UPDATE_TABLE_PAGINATION,
  GENERIC_ANALYTICS_RESET_TABLE_PAGINATION,
  GENERIC_ANALYTICS_UPDATE_FEEDBACK_SUMMARY_METRICS_DATA,
  GENERIC_ANALYTICS_TOGGLE_SELECTED_SUMMARY_METRICS,
  SET_MASTER_DATA,
  GENERIC_ANALYTICS_UPDATE_VISIBLE_CHARTS,
 } from '../actions/constants';
import { getViewBuckets, getViewCharts } from '../components/pages/GenericAnalytics/config/utils';
const Immer = require('immer').Immer;
const immerInstance = new Immer({ autoFreeze:false });
const produce = immerInstance.produce;

const defaultPagination =  {
  currentPageNumber: 1,
  nextOrPrev: 'first',
  perPageCount : 10,
  paginationParams: [],
};
const noPagination = {
  currentPageNumber: 1,
  nextOrPrev: 'first',
  perPageCount : undefined,
  paginationParams: [],
};
const defaultChartState = {
  isLoading : true,
  data : {},
};
const defaultTableState = {
  isLoading : true,
  data : [],
  pagination:defaultPagination,
  prevPagination:defaultPagination,
};
const getDefaultChartState =  (bucket, type) => {
  let bucketState: any = type === 'table' ? defaultTableState : defaultChartState;
  if (bucket.hidePagination) {
    bucketState = {...bucketState,
      pagination:noPagination,
      prevPagination:noPagination,
    };
  }
  return bucketState;
};

const getDefaultChartsState = (viewType, bucket) => {
  const charts = getViewCharts(viewType);
  const chartsState = {
  };
  getViewBuckets(viewType)[bucket].charts.forEach((element) => {
    chartsState[element] =
        getDefaultChartState(bucket, charts[element] ? charts[element].type : null);
  });
  return chartsState;
};
const defaultState = {
  domestic : {
    globalAppliedFilters: {
      startDate: moment().subtract(0, 'd').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      dateFilterType: 'last_main_event_time',
      carrierList: [],
      isForceReload: false,
    },
    appliedFilters : {},
    buckets: {},
  },
  retail : {
    globalAppliedFilters: {
      carrierList: [],
      isForceReload: false,
    },
    activeBucket : 'live',
    buckets: {
      live : {
        appliedFilters : {},
        shouldUpdate : true,
        charts : getDefaultChartsState('retail', 'live'),
      },
      history : {
        appliedFilters : {
          startDate: moment().subtract(0, 'd').format('YYYY-MM-DD'),
          endDate: moment().format('YYYY-MM-DD'),
          dateFilterType: 'start_time',
        },
        shouldUpdate : true,
        charts : getDefaultChartsState('retail', 'history'),
      },
    },
  },
  ondemand : {
    globalAppliedFilters: {
      startDate: moment().subtract(0, 'd').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      dateFilterType: 'booking_time',
      isForceReload: false,
      searchKey: '',
    },
    visibleCharts: {
      chartList: {},
      currentChart: '',
      updateType: 'single',
    },
    activeBucket : 'order_reporting',
    buckets: {
      order_summary : {
        appliedFilters : {
          nonRegularOrders: ['regular'],
        },
        reset: false,
        shouldFetchAfterFilter: true,
        shouldUpdate : false,
        charts :
        {
          ...getDefaultChartsState('ondemand', 'order_summary'),
        },
      },
      order_summary_advance : {
        appliedFilters : {
          nonRegularOrders: ['regular'],
        },
        reset: false,
        shouldUpdate : false,
        shouldFetchAfterFilter: true,
        charts :
        {
          ...getDefaultChartsState('ondemand', 'order_summary_advance'),
        },
      },
      dph : {
        appliedFilters : {},
        reset: false,
        shouldUpdate : false,
        shouldFetchAfterFilter: true,
        charts :
        {
          ...getDefaultChartsState('ondemand', 'dph'),
        },
      },
      rider_reporting : {
        appliedFilters : {},
        reset: false,
        shouldUpdate : false,
        shouldFetchAfterFilter: true,
        charts :
        {
          ...getDefaultChartsState('ondemand', 'rider_reporting'),
        },
      },
      rider_reporting_advance : {
        appliedFilters : {},
        reset: false,
        shouldUpdate : false,
        shouldFetchAfterFilter: true,
        charts :
        {
          ...getDefaultChartsState('ondemand', 'rider_reporting_advance'),
        },
      },
      order_reporting : {
        appliedFilters : {},
        reset: false,
        shouldUpdate : false,
        shouldFetchAfterFilter: true,
        charts :
        {
          ...getDefaultChartsState('ondemand', 'order_reporting'),
        },
      },
      order_reporting_advance : {
        appliedFilters : {},
        reset: false,
        shouldUpdate : false,
        shouldFetchAfterFilter: true,
        charts :
        {
          ...getDefaultChartsState('ondemand', 'order_reporting_advance'),
        },
      },
      customer_feedback : {
        appliedFilters : {},
        reset: false,
        shouldUpdate : false,
        shouldFetchAfterFilter: true,
        charts :
        {
          ...getDefaultChartsState('ondemand', 'customer_feedback'),
        },
        summaryData: [],
        selectedSummaryMetrics: [],
      },
    },
  },
};

const reducer = produce((state, action) => {
  switch (action.type) {
    case GENERIC_ANALYTICS_UPDATE_GLOBAL_FILTER :
      state[action.viewType].globalAppliedFilters = {
        ...state[action.viewType].globalAppliedFilters,
        ...action.data,
      };
      return state;

    case GENERIC_ANALYTICS_CHART_ENABLE_LOADING :
      state[action.viewType].buckets[action.bucket].charts[action.chart].isLoading = true;
      return state;

    case GENERIC_ANALYTICS_CHART_DISABLE_LOADING :
      state[action.viewType].buckets[action.bucket].charts[action.chart].isLoading = false;
      return state;

    case GENERIC_ANALYTICS_BUCKET_ENABLE_LOADING :
      const defaultBucketCharts = defaultState[action.viewType].buckets[action.bucket].charts;
      state[action.viewType].buckets[action.bucket].charts = defaultBucketCharts;
      return state;

    case GENERIC_ANALYTICS_UPDATE_CHART_DATA:
      state[action.viewType].buckets[action.bucket].charts[action.chart] = {
        isLoading: false,
        data : action.data,
      };
      return state;

    case GENERIC_ANALYTICS_APPLY_BUCKET_FILTER:
      state[action.viewType].buckets[action.bucket] = {
        ...state[action.viewType].buckets[action.bucket],
        appliedFilters: action.data,
      };
      return state;

    case GENERIC_ANALYTICS_RESET_BUCKET_FILTER:
      state[action.viewType].buckets[action.bucket].appliedFilters =
        defaultState[action.viewType].buckets[action.bucket].appliedFilters;
      return state;

    case GENERIC_ANALYTICS_RESET_ALL_BUCKETS_EXCEPT_FILTERS :
      const resetBuckets = defaultState[action.viewType].buckets;
      const bucketKeys = Object.keys(resetBuckets);
      bucketKeys.forEach((x) => {
        state[action.viewType].buckets[x] = {
          ...resetBuckets[x],
          appliedFilters : state[action.viewType].buckets[x].appliedFilters,
          reset: state[action.viewType].activeBucket === x ? false : true,
        };
      });
      return state;
    case GENERIC_ANALYTICS_SET_ACTIVE_BUCKET :
      state[action.viewType].activeBucket = action.data;
      state[action.viewType].buckets[action.data].reset = false;
      return state;

    case GENERIC_ANALYTICS_UPDATE_TABLE_DATA:
      state[action.viewType].buckets[action.bucket].shouldUpdate = false;
      state[action.viewType].buckets[action.bucket].reset = false;
      state[action.viewType].buckets[action.bucket].charts[action.chart] = {
        isLoading: false,
        data: action.data.page_data,
        pagination: {
          ...state[action.viewType].buckets[action.bucket].charts[action.chart].pagination,
          lastItemIdPrev: action.data ? action.data.lastItemIdPrev : 0,
          isNextPresent: action.data ? action.data.isNextPresent : null,
          lastItemIdNext: action.data ? action.data.lastItemIdNext : 0,
          lastSortedColumnValuePrev: action.data ? action.data.lastSortedColumnValuePrev : 0,
          lastSortedColumnValueNext: action.data ? action.data.lastSortedColumnValueNext : 0,
          lastSortByKey:action.data ? action.data.lastSortByKey : 0,
          isNextAvailableForFeedback: action.data.isNextAvailableForFeedback,
          nextOrPrev: action.data.nextOrPrev,
          page: action.data.page ? action.data.page : null,
          paginationParams : action.data.paginationParams ?
                             action.data.paginationParams : undefined,

        },
      };
      return state;
    case GENERIC_ANALYTICS_UPDATE_TABLE_PAGINATION:
      state[action.viewType].buckets[action.bucket].charts[action.chart] = {
        ...state[action.viewType].buckets[action.bucket].charts[action.chart],
        pagination : {
          ...state[action.viewType].buckets[action.bucket].charts[action.chart].pagination,
          ...action.data,
        },
        prevPagination : {
          ...state[action.viewType].buckets[action.bucket].charts[action.chart].pagination,
          ...action.data,
        },
      };
      return state;
    case GENERIC_ANALYTICS_RESET_TABLE_PAGINATION: {
      state[action.viewType].buckets[action.bucket].shouldUpdate = false;
      state[action.viewType].buckets[action.bucket].reset = false;
      state[action.viewType].buckets[action.bucket].charts[action.chart] = {
        ...state[action.viewType].buckets[action.bucket].charts[action.chart],
        pagination : {
          ...defaultState[action.viewType].buckets[action.bucket].charts[action.chart].pagination,
          perPageCount: state[action.viewType].buckets[action.bucket].charts[action.chart].
            pagination.perPageCount,
        },
        prevPagination : {
          ...defaultState[action.viewType].buckets[action.bucket].charts[action.chart].pagination,
          perPageCount: state[action.viewType].buckets[action.bucket].charts[action.chart].
            pagination.perPageCount,
        },
      };
      return state;
    }
    case GENERIC_ANALYTICS_UPDATE_FEEDBACK_SUMMARY_METRICS_DATA: {
      state[action.viewType].buckets[action.bucket].summaryData = action.data;
      return state;
    }
    case GENERIC_ANALYTICS_TOGGLE_SELECTED_SUMMARY_METRICS: {
      if (action.data ===
        state[action.viewType].buckets[action.bucket].selectedSummaryMetrics[0]) {
        state[action.viewType].buckets[action.bucket].selectedSummaryMetrics = [];
      }else {
        state[action.viewType].buckets[action.bucket].selectedSummaryMetrics = [action.data];
      }
      return state;
    }
    case SET_MASTER_DATA: {
      return {
        ...state,
        ...getDefaultGlobalAndBucketFilters({ state, action }),
      };
    }
    case GENERIC_ANALYTICS_UPDATE_VISIBLE_CHARTS: {
      state[action.viewType].visibleCharts = {
        chartList: { ...state[action.viewType].visibleCharts.chartList, [action.data.currentChart]: true },
        currentChart: action.data.currentChart,
        updateType: action.data.updateType,
      };
      return state;
    }
  }
}, {
  ...defaultState,
},
);

function getDefaultGlobalAndBucketFilters({
  state, action,
}) {
  return Object.keys(defaultState)?.reduce((newState, viewType) => {
    const buckets = defaultState[viewType]?.buckets ?
      Object.keys(defaultState[viewType]?.buckets) : [];
    const {
      dayPartList,
      nonRegularOrderList,
      orderStatusList,
      excludeDays,
      outlierOrderMinDistance,
      outlierOrderMaxDistance,
    } = action.data?.default_filter_values_for_analytics || {};
    const {
      dayPartList: dayPartListOptions,
    } = action.data?.filter_values_master_for_analytics || {};
    /* id is of the form [number, number],
       antd option matching fails even if both the numbers match.
       Hence, the labels aren't correcly shown in the dropdown.
       To fix that - we're finding out the same
       array object from the options' list,
       so that match by reference succeeds & label is shown. */
    const defaultOptionForDayPartFilter = dayPartListOptions?.filter(option => {
      return dayPartList?.some(defaultOption => {
        const everyOptionIdInDefaultVal =
          option?.id?.every(val => defaultOption?.id?.includes(val));
        const everyDefaultValIdInOption =
          defaultOption?.id?.every(val => option?.id?.includes(val));
        const atLeastOneDefaultOptionMatchesOption =
          everyOptionIdInDefaultVal && everyDefaultValIdInOption;
        return atLeastOneDefaultOptionMatchesOption;
      });
    });
    newState[viewType] = {
      ...state?.[viewType],
      globalAppliedFilters: {
        ...state?.[viewType]?.globalAppliedFilters,
        startDate: action.data?.analytics_default_date_range ?
          moment().subtract(action.data?.analytics_default_date_range - 1 || 0, 'day').format('YYYY-MM-DD') :
          state?.[viewType]?.globalAppliedFilters.startDate,
      },
      buckets: {
        ...state?.[viewType]?.buckets,
        ...buckets?.reduce((bucketsData, bucketKey) => {
          bucketsData[bucketKey] = {
            ...state?.[viewType]?.buckets?.[bucketKey],
            appliedFilters: {
              ...state?.[viewType]?.buckets?.[bucketKey]?.appliedFilters,
              dayPartFilter: defaultOptionForDayPartFilter?.map(el => el?.id),
              nonRegularOrders: nonRegularOrderList?.map(el => el?.id),
              finalConsignmentStatus: orderStatusList?.map(el => el?.id),
              excludeDays,
              outlierOrderMinDistance,
              outlierOrderMaxDistance,
            },
          };
          return bucketsData;
        }, {}),
      },
    };
    return newState;
  }, {});
}

export default reducer;
