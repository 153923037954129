import { BUSINESS_BOOKING_API_URL } from '../globals';
import {
  GET_BOOKING_MASTER_HEADERS,
  GET_BOOKING_MASTER_TABLE_DATA,
  BOOKING_MASTER_DOWNLOAD_DUMP,
  BOOKING_MASTER_SAMPLE_CSV_DOWNLOAD,
  BOOKING_MASTER_UPDATE,
  BOOKING_MASTER_DELETE,
  GET_CONTRACT_CODES,
  ADD_OR_UPDATE_CONTRACT_CODES,
  FETCH_VENDOR_ACCOUNTS,
  LINK_MULTIPLE_CONTRACTS,
} from './routes';
import {
  requestModelBookingMaster,
  responseModelBookingMaster,
} from '../model/bookingMasterView';
import { morphism } from 'morphism';
import { GET, POST } from './apiHandler';
import { generateQueryStringFromObject } from '@src/api/utils';

export const getBookingMastersTableData = async (body) => {
  const params = {
    ...morphism(requestModelBookingMaster, body),
  };
  params.chargeId = params.chargeId ? params.chargeId  : body['bucket'];
  const response = await GET(
    `${BUSINESS_BOOKING_API_URL}${GET_BOOKING_MASTER_TABLE_DATA}?${generateQueryStringFromObject(params)}`);
  if (response.isSuccess) {
    if (response.data && Array.isArray(response.data.data)) {
      response.data.data.forEach(row => {
        row.forEach((element, colIndex) => {
          if (Array.isArray(element)) {
            row[colIndex] = element.join(',');
          }
        });
      });
    }
    return {
      data: morphism(responseModelBookingMaster, response.data),
      isSuccess: true,
    };
  }

  return response;
};

export const getBookingMastersHeaders = async (params) => {
  return GET(
    `${BUSINESS_BOOKING_API_URL}${GET_BOOKING_MASTER_HEADERS}`,
    params,
  );
};

export const downloadBookingMastersData = async (params) => {
  return GET(
    `${BUSINESS_BOOKING_API_URL}${BOOKING_MASTER_DOWNLOAD_DUMP}`,
    params,
    'blob',
  );
};

export const downloadSampleCsvBookingMaster = async (params) => {
  return GET(
    `${BUSINESS_BOOKING_API_URL}${BOOKING_MASTER_SAMPLE_CSV_DOWNLOAD}`,
    params,
    'blob',
  );
};

export const bulkUpdateBookingMasters = async (body) => {
  return POST(`${BUSINESS_BOOKING_API_URL}${BOOKING_MASTER_UPDATE}`, body);
};

export const deleteRowBookingMasters = async (body) => {
  return POST(`${BUSINESS_BOOKING_API_URL}${BOOKING_MASTER_DELETE}`, body);
};

export const getContractCodes = async (params) => {
  return GET(
    `${BUSINESS_BOOKING_API_URL}${GET_CONTRACT_CODES}`,
    params,
  );
};

export const addOrUpdateContractCodes = async (body) => {
  return POST(
    `${BUSINESS_BOOKING_API_URL}${ADD_OR_UPDATE_CONTRACT_CODES}`,
    body,
  );
};

export const fetchVendorAccounts = async (body) => {
  return GET(
    `${BUSINESS_BOOKING_API_URL}${FETCH_VENDOR_ACCOUNTS}`,
    body,
  );
};

export const linkMultipleContracts = async (body) => {
  return POST(
    `${BUSINESS_BOOKING_API_URL}${LINK_MULTIPLE_CONTRACTS}`,
    body,
  );
};
