import * as React from 'react';
import { LeftOutlined, RightOutlined, VerticalRightOutlined } from '@ant-design/icons';
import { Tooltip, InputNumber } from 'antd';
import withStyles from 'react-jss';
import { CssType,
  ThemeType} from '../../theming/jssTypes';

const styles = (theme: ThemeType): CssType => ({
  disabledStyle: {
    pointerEvents: 'none',
    cursor: 'default',
    color: theme.colors.primaryDisabledFill,
  },
  pageNumberInput: {
    width: '45px',
    lineHeight: '1',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px',
    cursor: 'pointer',
    direction: 'ltr',
    '& div': {
      color: theme.colors.primaryColor,
    }
  },
});

class SimplePaginator extends React.PureComponent<any, any> {
  state = {
    localPageNumber: 1,
  };

  onPageNumberChange = (localPageNumber) => {
    this.setState({ localPageNumber });
  };

  onPageNumberStep = (localPageNumber) => {
    this.setState({ localPageNumber }, this.applyPageNumber);
  };

  applyPageNumber = () => {
    this.props.onPageJumped(this.state.localPageNumber);
  };

  renderPageNumberInput = () => {
    const { pageNumber, isNext, loading, classes } = this.props;
    return <InputNumber
      className={classes.pageNumberInput}
      disabled={loading}
      size="middle"
      min={1} step={1}
      max={isNext ? pageNumber + 1 : pageNumber}
      value={this.props.pageNumber}
      onChange={loading ? undefined : this.onPageNumberChange}
      onStep={loading ? undefined : this.onPageNumberStep}
      onPressEnter={loading ? undefined : this.applyPageNumber} />;
  };

  render() {
    const { pageNumber, isNext, classes, loading } = this.props;
    return (
      <div className={classes.container} style={{ direction: 'ltr' }}>
        <Tooltip title={'Go to First Page'}>
          <a>
            <VerticalRightOutlined
              className={pageNumber !== 1 && !loading ?
                {} : classes.disabledStyle}
              style={{ marginRight: '5px' }}
              onClick={pageNumber !== 1 && !loading ? this.props.onFirstClick : null}>
            </VerticalRightOutlined>
          </a>
        </Tooltip>
        <Tooltip title={'Go to Previous Page'}>
          <a>
            <LeftOutlined
              className={pageNumber !== 1 && !loading ?
                {} : classes.disabledStyle}
              onClick={pageNumber !== 1 && !loading ?
                this.props.onPrevClick : null} />
          </a>
        </Tooltip>
        <div style={{ margin: '0 5px' }} >
          {this.props.onPageJumped ? this.renderPageNumberInput() : this.props.pageNumber}
        </div>
        <Tooltip title={'Go to Next Page'}>
          <a>
            <RightOutlined
              className={isNext && !loading ? {} : classes.disabledStyle}
              onClick={isNext && !loading ? this.props.onNextClick : null} />
          </a>
        </Tooltip>
      </div>
    );
  }
}

export default withStyles(styles, { injectTheme: true })(SimplePaginator);
