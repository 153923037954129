import { Button } from 'antd';
import React from 'react';
import type { CSSProperties, MouseEventHandler } from 'react';
import withStyles, { WithStylesProps } from 'react-jss';
import { CssType, ThemeType } from 'src/theming/jssTypes';
import classnames from 'classnames';

const styles = (theme: ThemeType): CssType => ({
    button: {},
});

interface IProps extends WithStylesProps<typeof styles> {
    onClick?: MouseEventHandler<HTMLElement>;
    children: React.ReactNode;
    className?: string;
    style?: CSSProperties;
    disabled?: boolean;
    loading?: boolean;
}

const GenericActionButton = React.forwardRef((props: IProps, ref: any) => {
    const { children, onClick, classes, style, className, disabled, loading } = props;
    return (
        <Button
            className={classnames(classes.button, className)}
            onClick={onClick}
            disabled={disabled}
            loading={loading}
            style={style}
            ref={ref}
        >
            {children}
        </Button>
    );
});

GenericActionButton.defaultProps = {
    onClick: (e) => {},
    style: {},
    className: '',
    disabled: false,
    loading: false,
};

export default withStyles(styles, {
    injectTheme: true,
})(GenericActionButton);
