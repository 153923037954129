import { CssType, ThemeType } from '../../../../../theming/jssTypes';
import { createUseStyles } from 'react-jss';
const useStyles = createUseStyles(
  (theme: ThemeType): CssType => ({
    col: {
      padding: '5px',
      marginLeft: '10px',
    },
    selectStyle: {
      minWidth: '100%',
    },
    row: {
      padding: '6px',
    },
    inputStyle: {
      width: '100%',
    },
  }),
);
export default useStyles;
