/* eslint-disable eqeqeq */
import { getErrorMessage, getAuthenticationHeaders,
 generateQueryStringFromObject } from './utils';
import apiProvider,{apiProviderWithBaseUrl} from './provider';
import { API_BASE_URL, BUSINESS_BOOKING_API_URL } from '../globals';

const dateFormat = 'DD-MM-YYYY';

import {
  GET_DISPATCH_SCREEN_DATA_V2,
  VIEW_ROSTER_DATA,
  REASSIGN_RIDER,
  FORCE_CHECKOUT_RIDER,
  GET_RIDER_LIVE_TRACKING_DATA,
  GET_ORDER_HISTORY_DATA,
  GET_RIDER_MANAGEMENT_DATA,
  GET_ROSTER_MANAGEMENT_DATA,
  GET_RIDER_ORDER_HISTORY_DATA,
  CREATE_SHIFT,
  EXTEND_SHIFT,
  GIVE_BREAK_RIDER,
  GET_RIDER_DPH_DATA,
  GET_REPORTING_TABLE_DATA,
  GET_REPORTING_TABLE_DOWNLOAD_DATA,
  FETCH_CUSTOMER_ACTIVITY_FEEDBACK_SUMMARY_METRICS_DATA,
  GET_FEEDBACK_FEEDBACK,
  GET_RIDER_DETAILS,
  UPDATE_RIDER_TEMPERATURE,
  GET_STORE_DETAILS,
  UPDATE_STORE_CONFIGURATION,
  GET_FILTER_MASTER_DATA,
  ADD_RIDER, MANAGE_RIDER, BULK_RIDER_UPLOAD, SAMPLE_CSV_ADD_RIDER, MARK_RIDER_INSTORE,
  UPDATE_RIDER_KM_READING,
  GET_DEACTIVATED_RIDER_MANAGEMENT_DATA,
  BULK_RIDER_UPDATE,
  DOWNLOAD_RIDER_ACTIVE,
  DOWNLOAD_RIDER_INACTIVE,
  FETCH_ALLOCATION_RULES_DATA,
  UPDATE_ALLOCATION_RULES_DATA,
  MARK_REACHED_AT_HUB, SAMPLE_CSV_UPDATE_ORDERS, GET_BULK_UPDATE_ORDER_REQUESTS,
  ADD_ORDERS_CSV_UPLOAD,
  COD_TRANSACTION_HISTORY_DATA_LIST,
  COD_WORKER_DATA_LIST,
  COD_GET_CONSIGNMENTS,
  COD_UPDATE_REQUESTS,
  COD_ADHOC_CASH,
  COD_ADD_PETTY_CASH,
  REALLOCATE_RIDER,
  MARK_DELIVERED_ONDEMAND,
  GET_TERMS_AND_CONDITIONS,
  SET_TERMS_AND_CONDITIONS,
  GET_DELIVERY_PATH,
  GET_PLANNED_SHIFT,
  CREATE_PLANNED_SHIFT,
  UPDATE_PLANNED_SHIFT,
  DELETE_PLANNED_SHIFT,
  DUPLICATE_PLANNED_SHIFT,
  SAMPLE_CSV_WEEK_OFFS,
  CREATE_WEEK_OFFS,
  GET_WEEK_OFFS,
  GET_CUSTOMERS,
  SET_CUSTOMER,
  GET_ORDER_NUMBER,
  ADD_SINGLE_ORDER,
  DELETE_CUSTOMER,
  GET_CONFIG_OBJECTS,
  SET_CONFIG_OBJECTS,
  GET_CONFIG_MAPPINGS,
  SET_CONFIG_MAPPINGS,
  SET_HUB_CONFIG_MAPPING,
  GET_HUB_LIST_SAMPLE,
  ADD_RIDER_COMMENT,
  FETCH_RIDER_PAYOUT_TYPES,
  FETCH_RIDER_PAYOUT_DATA,
  DOWNLOAD_RIDER_PAYOUT_DATA,
  DOWNLOAD_RIDER_PAYOUT_SAMPLE_CSV,
  UPLOAD_RIDER_PAYOUT_SAMPLE_CSV,
  DELETE_PAYOUT,
  DELETE_PAYOUT_SINGLE,
  UPDATE_PAYOUT,
  GET_PARKING_CONFIG,
  DELETE_PARKING_CONFIG,
  CREATE_PARKING_CONFIG,
  EDIT_PARKING_CONFIG,
  ACKNOWLEDGE_CANCELLED_ORDERS,
  GET_COD_HUB_CONFIG,
  ADD_COD_HUB_CONFIG,
  DELETE_COD_HUB_CONFIG,
  MARK_CANCELLED_ONDEMAND,
  DOWNLOAD_RIDERS_CSV,
  GET_RIDER_DATA_DOWNLOAD_REQUESTS,
  SEND_COD_TRANSACTION_DATA_DOWNLOAD_REQUEST,
  GET_COD_TRANSACTION_DATA_DOWNLOAD_REQUESTS,
  SHOW_DISPATCH_V2_FOR_HUB,
  UPDATE_ORDER_DETAILS,
  ALLOW_UNDELIVERED_BY_RIDER,
  SETTLE_PAYOUT_AMOUNT,
  GET_CLUBBING_CONFIG_MAPPINGS,
  ADD_CLUBBING_CONFIG_MAPPING,
  UPDATE_CLUBBING_CONFIG_MAPPING,
  DELETE_CLUBBING_CONFIG_MAPPING,
  DELETE_BULK_CLUBBING_CONFIG_MAPPINGS,
  GET_CLUBBING_CONFIGS,
  ADD_CLUBBING_CONFIG,
  UPDATE_CLUBBING_CONFIG,
  DELETE_CLUBBING_CONFIG,
  GET_DISPATCH_SCREEN_ANALYTICS,
  GET_DISPATCH_SCREEN_SUMMARY_METRICS,
  UNBLOCK_RIDER_LOGIN_OTP,
  GET_WEATHER_FORECAST,
  MARK_RTO_DELIVERED,
  MARK_RTO_UNDELIVERED,
  MARK_ORDER_UNDELIVERED,
  DELETE_CLUBBING_MAPPING,
  RIDER_ASSIGNMENT,
  DEACTIVATE_PLANNED_SHIFT,
  DETACH_REUSABLE_ASSETS,
  MARK_READY_TO_DISPATCH,
  GET_RTO_PATH,
  GET_PAYMENT_CONFIGURATIONS,
  ADD_PAYMENT_CONFIGURATIONS,
  DELETE_PAYMENT_CONFIGURATION,
  UPDATE_PAYMENT_CONFIGURATION,
  SEARCH_RIDERS_ORDER_ASSIGNMENT,
  BULK_COD_SETTLEMENT,
  GET_DOWNLOADS_REQUESTS_COD_SETTLEMENT,
  GET_DOWNLOAD_REPORT_COD_SETTLEMENT,
  DOWNLOAD_SAMPLE_BULK_COD_SETTLE_FILE,
  UPDATE_WORKER_ELIGIBILITY,
  GET_CUSTOMER_LIST_SAMPLE,
  FETCH_SOFTDATA_UPLOAD_CONFIG,
  FETCH_HUB_STATE_DATA,
  UPDATE_HUB_STATE_DATA,
} from './routes';
import { requestOnDemandSchema, responseModelTable, requestOnDemandSchemaForFeedbacks,
         requestOnDemandSchemaForSubBuckets, responseModelFeedbacksTable,
         requestOnDemandSchemaPunchingTable, responseCodModel, responseCodHistoryModel } from '../model/analytics';
import morphism from 'morphism';
import {POST, GET} from './apiHandler';
import { requestCodSettlementModelCN, requestCodHistoryModelCN } from '../model/consignmentView';
import { CreateOrderUploadReq } from '../types/request/CreateOrderUpload';
import store from '@store/appStore';

export const getDispatchScreenDataV2 = async(body) => {
  return POST(`${API_BASE_URL}${GET_DISPATCH_SCREEN_DATA_V2}`, body);
};

export const viewRosterData = async(body) => {
  return POST(`${API_BASE_URL}${VIEW_ROSTER_DATA}`, body);
};

export const fetchHubStateData = async (body) => {
  return POST(`${API_BASE_URL}${FETCH_HUB_STATE_DATA}`, body); 
};

export const updateHubStateData = async (body) => {
  return POST(`${API_BASE_URL}${UPDATE_HUB_STATE_DATA}`, body);
};

export const reassignRider = async (
  body,
) => {

const headersWithHubId = {
  ...(await getAuthenticationHeaders()),
  'hub-id': store?.getState()?.cachedData?.currHub?.id,
};
  try {
    const response =
      await apiProvider.post(
        `${API_BASE_URL}${REASSIGN_RIDER}`, { ...body },
        {
          headers: headersWithHubId,
        },
      );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const giveBreakRider = async (
  body,
) => {
  try {
    const response =
    await apiProvider.post(
      `${API_BASE_URL}${GIVE_BREAK_RIDER}`, body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const forceCheckoutRider = async (
  body,
) => {
  try {
    const response =
    await apiProvider.post(
      `${API_BASE_URL}${FORCE_CHECKOUT_RIDER}`, body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const getRiderLiveTrackingData = async(params) => {
  try {
    const response =
    await apiProvider.get(
     `${API_BASE_URL}${GET_RIDER_LIVE_TRACKING_DATA}?${generateQueryStringFromObject(params)}`,
      {
        headers: await getAuthenticationHeaders(),
      },
      );
    return {
      isSuccess: true,
      data:response.data.data,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const getOrderHistoryData = async(params) => {
  try {
    const response =
       await apiProvider.get(
        `${API_BASE_URL}${GET_ORDER_HISTORY_DATA}?${generateQueryStringFromObject(params)}`,
         {
           headers: await getAuthenticationHeaders(),
         },
      );
    return {
      isSuccess: true,
      data:response.data.data,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const downloadOrderHistoryData = async(params) => {
  try {
    const response =
       await apiProvider.get(
        `${API_BASE_URL}${GET_ORDER_HISTORY_DATA}?${generateQueryStringFromObject(params)}`,
         {
           headers: await getAuthenticationHeaders(),
           responseType:'blob',
         },
      );
    return {
      isSuccess: true,
      data:response.data,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getRiderManagementData = async(params) => {
  return GET(`${API_BASE_URL}${GET_RIDER_MANAGEMENT_DATA}`, params);
};

export const getRiderOrderHistoryData = async(params) => {
  try {
    const response =
     await apiProvider.get(
      `${API_BASE_URL}${GET_RIDER_ORDER_HISTORY_DATA}?${generateQueryStringFromObject(params)}`,
       {
         headers: await getAuthenticationHeaders(),
       },
      );
    return {
      isSuccess: true,
      data:response.data.data,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const getRosterManagementData = async(params) => {
  try {
    const response =  await apiProvider.get(
      `${API_BASE_URL}${GET_ROSTER_MANAGEMENT_DATA}?${generateQueryStringFromObject(params)}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      isSuccess: true,
      data:response.data.data,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const createShift = async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${CREATE_SHIFT}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const extendShift = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${EXTEND_SHIFT}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const fetchRiderDphData = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${GET_RIDER_DPH_DATA}`, { ...morphism(requestOnDemandSchema, body) },
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return  {
      data : response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const fetchReportingData = async (body) => {
  try {
    const response = await apiProvider.post(
     `${API_BASE_URL}${GET_REPORTING_TABLE_DATA}`,
      { ...morphism(requestOnDemandSchema, body) },
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return  {
      data : morphism(responseModelTable, response.data.data),
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const fetchReportingDataPunchingTable = async (body) => {
  try {
    const response = await apiProvider.post(
     `${API_BASE_URL}${GET_REPORTING_TABLE_DATA}`,
      { ...morphism(requestOnDemandSchemaPunchingTable, body) },
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return  {
      data : morphism(responseModelTable, response.data.data),
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const fetchDownloadsReportingData = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${GET_REPORTING_TABLE_DOWNLOAD_DATA}`,
      { ...morphism(requestOnDemandSchema, body) },
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return  {
      data : morphism(responseModelTable, response.data.data),
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const fetchSubBucketsAnalytics = async (body) => {
  try {
    const response =
     await apiProvider.post(
       `${API_BASE_URL}${FETCH_CUSTOMER_ACTIVITY_FEEDBACK_SUMMARY_METRICS_DATA}`,
        { ...morphism(requestOnDemandSchemaForSubBuckets, body) },
       {
         headers: await getAuthenticationHeaders(),
       },
    );
    return {
      isSuccess: true,
      data: response.data.data,
    };
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getFeedbackDataWithFilters = async (params: any) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${GET_FEEDBACK_FEEDBACK}`,
       { ...morphism(requestOnDemandSchemaForFeedbacks, params) }, {
         headers: await getAuthenticationHeaders(),
       });
    return {
      isSuccess: true,
      data: morphism(responseModelFeedbacksTable, response.data),
    };
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const getRiderSideDrawerData = async(workerId) => {
  try {
    const response =  await apiProvider.get(
      `${API_BASE_URL}${GET_RIDER_DETAILS}?worker_id=${workerId}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      isSuccess: true,
      data:response.data.data,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getPlannedShift = async (params) => {
  return GET(`${API_BASE_URL}${GET_PLANNED_SHIFT}`, params);
};

export const createPlannedShift = async(body) => {
  return POST(`${API_BASE_URL}${CREATE_PLANNED_SHIFT}`,body);
};

export const updatePlannedShift = async(body) => {
  return POST(`${API_BASE_URL}${UPDATE_PLANNED_SHIFT}`,body);
};

export const deletePlannedShift = async(body) => {
  return POST(`${API_BASE_URL}${DELETE_PLANNED_SHIFT}`,body);
};

export const duplicatePlannedShift = async(body) => {
  return POST(`${API_BASE_URL}${DUPLICATE_PLANNED_SHIFT}`,body);
};

export const deactivatePlannedShift = async(body) => {
  return POST(`${API_BASE_URL}${DEACTIVATE_PLANNED_SHIFT}`,body);
};

export const updateRiderTemperature = async(body) => {
  return POST(`${API_BASE_URL}${UPDATE_RIDER_TEMPERATURE}`, body);
};

export const getStoreDetails = async(params) => {
  return GET(`${API_BASE_URL}${GET_STORE_DETAILS}`, params);
};

export const updateStoreConfiguration = async(body) => {
  return POST(`${API_BASE_URL}${UPDATE_STORE_CONFIGURATION}`, body);
};

export const fetchFilterMasterData = async (body) => {
  return POST(`${API_BASE_URL}${GET_FILTER_MASTER_DATA}`, body);
};

export const addRider = async (body) => {
  return POST(`${API_BASE_URL}${ADD_RIDER}`, body);
};

export const addRiderComment = async(body) => {
  return POST(`${API_BASE_URL}${ADD_RIDER_COMMENT}`, body);
};

export const updateRider = async (workerId, body) => {
  return POST(`${API_BASE_URL}${MANAGE_RIDER}/${workerId}/update`, body);
};

export const uploadMultipleRider = async (body) => {
  return POST(`${API_BASE_URL}${BULK_RIDER_UPLOAD}`, body);
};

export const getSampleCSVAddRider = async () => {
  return GET(`${API_BASE_URL}${SAMPLE_CSV_ADD_RIDER}`);
};

export const getSampleCSVWeekOffs = async() => {
  return GET(`${API_BASE_URL}${SAMPLE_CSV_WEEK_OFFS}`);
};

export const fetchRiderDetails = async (workerId) => {
  return GET(`${API_BASE_URL}${MANAGE_RIDER}/${workerId}/details`);
};

export const disableRider = async(workerId) => {
  return POST(`${API_BASE_URL}${MANAGE_RIDER}/${workerId}/disable`, null);
};

export const markRiderAsInstore = async(body) => {
  return POST(`${API_BASE_URL}${MARK_RIDER_INSTORE}`, body);
};

export const updateKmReading = async(body) => {
  return POST(`${API_BASE_URL}${UPDATE_RIDER_KM_READING}`, body);
};

export const getDeactivatedRiderManagementData = async (params) => {
  return GET(`${API_BASE_URL}${GET_DEACTIVATED_RIDER_MANAGEMENT_DATA}?${generateQueryStringFromObject(params)}`);
};

export const updateMultipleRider = async (body) => {
  return POST(`${API_BASE_URL}${BULK_RIDER_UPDATE}`, body);
};

export const downloadRidersActive = async(params) => {
  return GET(`${API_BASE_URL}${DOWNLOAD_RIDER_ACTIVE}`, params);
};

export const downloadRidersInactive = async(params) => {
  return GET(`${API_BASE_URL}${DOWNLOAD_RIDER_INACTIVE}`, params);
};

export const downloadRidersCSV = async(params) => {
  return GET(`${API_BASE_URL}${DOWNLOAD_RIDERS_CSV}`, params);
};

export const getRiderDataDownloadRequests = async(params) => {
  return GET(`${API_BASE_URL}${GET_RIDER_DATA_DOWNLOAD_REQUESTS}`, params);
};

export const enableRider = async(workerId) => {
  return POST(`${API_BASE_URL}${MANAGE_RIDER}/${workerId}/enable`, null);
};

export const fetchAllocationRulesData = async (body) => {
  return POST(`${API_BASE_URL}${FETCH_ALLOCATION_RULES_DATA}`, body);
};

export const updateAllocationRules = async (body) => {
  return POST(`${API_BASE_URL}${UPDATE_ALLOCATION_RULES_DATA}`, body);
};

export const markReachedAtHub = async(body) => {

const headersWithHubId = {
  ...(await getAuthenticationHeaders()),
  'hub-id': store?.getState()?.cachedData?.currHub?.id,
};
  return POST(`${API_BASE_URL}${MARK_REACHED_AT_HUB}`, body, headersWithHubId);
};

export const getSampleCsvUpdateOrders = async() => {
  return GET(`${API_BASE_URL}${SAMPLE_CSV_UPDATE_ORDERS}`);
};

export const getBulkUpdateOrderRequests = async(body) => {
  return POST(`${API_BASE_URL}${GET_BULK_UPDATE_ORDER_REQUESTS}`, body);
};

export const addOrdersCsvUpload = async(body: CreateOrderUploadReq) => {
  return POST(`${API_BASE_URL}${ADD_ORDERS_CSV_UPLOAD}`, body);
};

export const codListAllWorkersData = async (body) => {
    const params = morphism(requestCodSettlementModelCN, body);
    const response = await POST(
      `${API_BASE_URL}${COD_WORKER_DATA_LIST}`,
      params,
    );
    if (response.isSuccess) {
      return {
        data: morphism( responseCodModel, response ),
        isSuccess: true,
      };
    }
    return response;
};

export const codGetConsignmentsForWorker = async (body) => {
  const response = await POST(
    `${API_BASE_URL}${COD_GET_CONSIGNMENTS}`,
    body,
  );
  if (response.isSuccess) {
    return {
      data: response.data,
      isSuccess: true,
    };
  }
  return response;
};

export const codUpdateRequest = async (body) => {
  return POST(`${API_BASE_URL}${COD_UPDATE_REQUESTS}`, body);
};

export const codAdhocCashSettlement = async (body) => {
  return POST(`${API_BASE_URL}${COD_ADHOC_CASH}`, body);
};

export const codAddPettyCash = async (body) => {
  return POST(`${API_BASE_URL}${COD_ADD_PETTY_CASH}`, body);
};

export const codTransactionHistoryData = async (body) => {
  const params = morphism(requestCodHistoryModelCN, body);
  const response = await POST(
    `${API_BASE_URL}${COD_TRANSACTION_HISTORY_DATA_LIST}`,
    params,
  );
  if (response.isSuccess) {
    return {
      data: morphism( responseCodHistoryModel, response.data ),
      isSuccess: true,
    };
  }
  return response;
};

export const workerReallocate = async (body) => {
  return POST(`${API_BASE_URL}${REALLOCATE_RIDER}`, body);
};

export const markDelivered = async (body) => {

const headersWithHubId = {
  ...(await getAuthenticationHeaders()),
  'hub-id': store?.getState()?.cachedData?.currHub?.id,
};
  return POST(`${API_BASE_URL}${MARK_DELIVERED_ONDEMAND}`, body, headersWithHubId);
};

export const getRiderTermsAndConditions = async (params) => {
  return GET(`${API_BASE_URL}${GET_TERMS_AND_CONDITIONS}`, params);
};

export const setRiderTermsAndConditions = async (body) => {
  return POST(`${API_BASE_URL}${SET_TERMS_AND_CONDITIONS}`, body);
};

export const getDeliveryPath = async (params) => {
  return GET(`${API_BASE_URL}${GET_DELIVERY_PATH}`,params);
};
export const getRtoPath = async (params) => {
  return GET(`${API_BASE_URL}${GET_RTO_PATH}`,params);
};

export const createWeekOff = async (data) => {
  return apiProviderWithBaseUrl.post(`${CREATE_WEEK_OFFS}`,data);
};

interface getWeekOffsParams {
  year?: number;
  sortBy?: string;
  pageSize?: number;
  descendingOrder?: boolean;
  lastRowIdPrev?: string;
  lastRowIdNext?: string;
  workerCode?: string;
  nextOrPrev?: string;
  searchRiderValue?:string;
}

export const getWeekOffs = async (
  {searchRiderValue,year, lastRowIdPrev, lastRowIdNext, nextOrPrev,pageSize}) => {
    const paginationDetails = nextOrPrev? {
      lastRowIdPrev,
      lastRowIdNext,
    }:{};
    let body:getWeekOffsParams = {
      year,
      sortBy: '_id',
      pageSize,
      descendingOrder: true,
      nextOrPrev,
      workerCode:undefined,
      ...paginationDetails,
    };
    if(searchRiderValue) {
      body = {year, workerCode:searchRiderValue};
    }
    const data = ( await apiProviderWithBaseUrl.post(
        `${GET_WEEK_OFFS}`,
        body,
        )
      )?.data || [];
    data.weekOffs = data?.data?.map((x) => ({ ...x, key: x.workerCode })) || [];
    return data;
  };
export const getCustomersForHub = async (body) => {
  return POST(`${API_BASE_URL}${GET_CUSTOMERS}`,body);
};

export const setCustomerData = async (body) => {
  return POST(`${API_BASE_URL}${SET_CUSTOMER}`,body);
};

export const getOrderNumber = async () => {
  return GET(`${API_BASE_URL}${GET_ORDER_NUMBER}`);
};

export const addSingleOrder = async(body: CreateOrderUploadReq) => {
  return POST(`${API_BASE_URL}${ADD_SINGLE_ORDER}`, body);
};

export const updateCustomerData = async(body,id) => {
  return POST(`${API_BASE_URL}${SET_CUSTOMER}/${id}`, body);
};

export const deleteCustomerData = (id) => {
  return POST(`${API_BASE_URL}${DELETE_CUSTOMER}/${id}`,{});
};

export const getConfigObjects = async () => {
  return GET(`${API_BASE_URL}${GET_CONFIG_OBJECTS}`);
};

export const setConfigObjects = async (body) => {
  return POST(`${API_BASE_URL}${SET_CONFIG_OBJECTS}`,body);
};

export const getConfigMappings = async (body) => {
  return POST(`${API_BASE_URL}${GET_CONFIG_MAPPINGS}`, body);
};

export const setConfigMappings = async (body) => {
  return POST(`${API_BASE_URL}${SET_CONFIG_MAPPINGS}`,body);
};

export const setHubConfigMappings = async (body) => {
  return POST(`${API_BASE_URL}${SET_HUB_CONFIG_MAPPING}`,body);
};

export const getHubListSample = async () => {
  return GET(`${API_BASE_URL}${GET_HUB_LIST_SAMPLE}`);
};

export const getCustomerListSample = async () => {
  return GET(`${API_BASE_URL}${GET_CUSTOMER_LIST_SAMPLE}`);
};

export const getPayoutTypes = async (params) => {
  return GET(`${BUSINESS_BOOKING_API_URL}${FETCH_RIDER_PAYOUT_TYPES}`, params);
};

export const getPayoutData = async (params) => {
  return GET(`${BUSINESS_BOOKING_API_URL}${FETCH_RIDER_PAYOUT_DATA}`, params);
};

export const downloadPayoutData = async (params) => {
  return GET(`${BUSINESS_BOOKING_API_URL}${DOWNLOAD_RIDER_PAYOUT_DATA}`,
  params,
  'blob');
};

export const getSampleCSVPayout = async (params) => {
  return GET(`${BUSINESS_BOOKING_API_URL}${DOWNLOAD_RIDER_PAYOUT_SAMPLE_CSV}`, params, 'blob');
};

export const uploadPayoutData = async (data) => {
  return POST(`${BUSINESS_BOOKING_API_URL}${UPLOAD_RIDER_PAYOUT_SAMPLE_CSV}`, data);
};

export const deletePayouts = async (body) => {
  return POST(`${BUSINESS_BOOKING_API_URL}${DELETE_PAYOUT}`, body);
};

export const deletePayoutSingle = async (body) => {
  return POST(`${BUSINESS_BOOKING_API_URL}${DELETE_PAYOUT_SINGLE}`, body);
};

export const getParkingConfig = async (body) => {
  return POST(`${API_BASE_URL}${GET_PARKING_CONFIG}`, body);
};

export const deleteParkingConfig = async (body) => {
  return POST(`${API_BASE_URL}${DELETE_PARKING_CONFIG}`, body);
};

export const createParkingConfig = async (body) => {
  return POST(`${API_BASE_URL}${CREATE_PARKING_CONFIG}`, body);
};

export const editParkingConfig = async (body) => {
  return POST(`${API_BASE_URL}${EDIT_PARKING_CONFIG}`, body);
};

export const setCancelAcknowledgement = async(body) => {
  return POST(`${API_BASE_URL}${ACKNOWLEDGE_CANCELLED_ORDERS}`, body);
};

export const getCODHubConfig = async (body) => {
  return POST(`${API_BASE_URL}${GET_COD_HUB_CONFIG}`,body);
};

export const addCODHubConfig = async (body) => {
  return POST(`${API_BASE_URL}${ADD_COD_HUB_CONFIG}`,body);
};

export const deleteCODHubConfig = async (body) => {
  return POST(`${API_BASE_URL}${DELETE_COD_HUB_CONFIG}`,body);
};

export const markCancelled = async (body) => {

const headersWithHubId = {
  ...(await getAuthenticationHeaders()),
  'hub-id': store?.getState()?.cachedData?.currHub?.id,
};
  return POST(`${API_BASE_URL}${MARK_CANCELLED_ONDEMAND}`, body, headersWithHubId);
};

export const sendCodTransactionDataDownloadRequest = async (body) => {
  return POST(`${API_BASE_URL}${SEND_COD_TRANSACTION_DATA_DOWNLOAD_REQUEST}`, body);
};

export const getCodTransactionDataDownloadRequests = async (params) => {
  return GET(`${API_BASE_URL}${GET_COD_TRANSACTION_DATA_DOWNLOAD_REQUESTS}`, params);
};

export const showDispatchV2ForHub = async (params) => {
  return GET(`${API_BASE_URL}${SHOW_DISPATCH_V2_FOR_HUB}`, params);
};

export const addOrderDetails = async (body) => {

const headersWithHubId = {
  ...(await getAuthenticationHeaders()),
  'hub-id': store?.getState()?.cachedData?.currHub?.id,
};
  return POST(`${API_BASE_URL}${UPDATE_ORDER_DETAILS}`, body, headersWithHubId);
};

export const settlePayoutAmount = async (body) => {
  return POST(`${API_BASE_URL}${SETTLE_PAYOUT_AMOUNT}`, body);
};

export const getClubbingConfigMappings = async (params) => {
  return GET(`${API_BASE_URL}${GET_CLUBBING_CONFIG_MAPPINGS}`, params);
};

export const addClubbingConfigMapping = async (body) => {
  return POST(`${API_BASE_URL}${ADD_CLUBBING_CONFIG_MAPPING}`, body);
};

export const updateClubbingConfigMapping = async (body, id) => {
  return POST(`${API_BASE_URL}${UPDATE_CLUBBING_CONFIG_MAPPING}/${id}`, body);
};

export const deleteClubbingConfigMapping = async (body, id) => {
  return POST(`${API_BASE_URL}${DELETE_CLUBBING_CONFIG_MAPPING}/${id}`, body);
};

export const deleteBulkClubbingConfigMapping = async (body) => {
  return POST(`${API_BASE_URL}${DELETE_BULK_CLUBBING_CONFIG_MAPPINGS}`, body);
};

export const getClubbingConfigs = async (params) => {
  return GET(`${API_BASE_URL}${GET_CLUBBING_CONFIGS}`, params);
};

export const addClubbingConfig = async (body) => {
  return POST(`${API_BASE_URL}${ADD_CLUBBING_CONFIG}`, body);
};

export const updateClubbingConfig = async (body, id) => {
  return POST(`${API_BASE_URL}${UPDATE_CLUBBING_CONFIG}/${id}`, body);
};

export const deleteClubbingConfig = async (body, id) => {
  return POST(`${API_BASE_URL}${DELETE_CLUBBING_CONFIG}/${id}`, body);
};

export const getDispatchScreenAnalytics = async (body) => {
  return POST(`${API_BASE_URL}${GET_DISPATCH_SCREEN_ANALYTICS}`, body);
};

export const getDispatchScreenSummaryMetrics = async(body) => {
  return POST(`${API_BASE_URL}${GET_DISPATCH_SCREEN_SUMMARY_METRICS}`, body);
};

export const unblockRiderLoginOTP = async (body) => {
  return POST(`${API_BASE_URL}${UNBLOCK_RIDER_LOGIN_OTP}`, body);
};

export const getWeatherForecast = async (params) => {
  return GET(`${API_BASE_URL}${GET_WEATHER_FORECAST}`, params);
};

export const markRtoDelivered = async (body) => {
  return POST(`${API_BASE_URL}${MARK_RTO_DELIVERED}`, body);
};

export const markRtoUndelivered = async (body) => {
  return POST(`${API_BASE_URL}${MARK_RTO_UNDELIVERED}`, body);
};

export const allowRiderToUndeliver = async (body) => {
  body.hub_id = store?.getState()?.cachedData?.currHub?.id;
  return POST(`${API_BASE_URL}${ALLOW_UNDELIVERED_BY_RIDER}`, body, {
    ...(await getAuthenticationHeaders()),
    'hub-id': store?.getState()?.cachedData?.currHub?.id,
  });
}

export const markUndelivered = async (body) => {
  return POST(`${API_BASE_URL}${MARK_ORDER_UNDELIVERED}`, body);
};

export const deleteClubbingMapping = async (body, id) => {
  return POST(`${API_BASE_URL}${DELETE_CLUBBING_MAPPING}/${id}`, body);
};

export const assignMultipleRiders = async (body) => {
  return POST(`${API_BASE_URL}${RIDER_ASSIGNMENT}`, body);
};

export const detachReusableAssets = async (body) => {
  return POST(`${API_BASE_URL}${DETACH_REUSABLE_ASSETS}`, body);
};

export const getSampleCsvMarkReadyToDispatch = async () => {
  return GET(`${API_BASE_URL}${MARK_READY_TO_DISPATCH}`);
};

export const getPaymentConfigs = async (body) => {
  return POST(`${API_BASE_URL}${GET_PAYMENT_CONFIGURATIONS}`,body);
};

export const addPaymentConfig = async (body) => {
  return POST(`${API_BASE_URL}${ADD_PAYMENT_CONFIGURATIONS}`,body);
};

export const updatePaymentConfig = async (body) => {
  return POST(`${API_BASE_URL}${UPDATE_PAYMENT_CONFIGURATION}`,body);
};

export const deletePaymentConfig = async (body) => {
  return POST(`${API_BASE_URL}${DELETE_PAYMENT_CONFIGURATION}`,body);
};

export const searchRidersForOrderAssignment = async (params) => {
  return GET(`${API_BASE_URL}${SEARCH_RIDERS_ORDER_ASSIGNMENT}`, params);
};

export const bulkCodSettlement = async (body) => {
  return POST(`${API_BASE_URL}${BULK_COD_SETTLEMENT}`, body);
};

export const getDownloadsRequestCodSettlement = async (body) => {
  return POST(`${API_BASE_URL}${GET_DOWNLOADS_REQUESTS_COD_SETTLEMENT}`, body);
};

export const getDownloadsReportCodSettlement = async (id, body) => {
  return POST(`${API_BASE_URL}${GET_DOWNLOAD_REPORT_COD_SETTLEMENT}/${id}`, body);
};

export const downloadSampleBulkCodSettlementFile = async (body) => {
  return POST(`${API_BASE_URL}${DOWNLOAD_SAMPLE_BULK_COD_SETTLE_FILE}`, body);
};

export const updateWorkerEligibility = async (body) => {
  return POST(`${API_BASE_URL}${UPDATE_WORKER_ELIGIBILITY}`, body);
};

export const fetchSoftdataUploadConfig = async (body) => {
  return POST(`${API_BASE_URL}${FETCH_SOFTDATA_UPLOAD_CONFIG}`, body);
};
