import {
    fetchSupplementaryServiceMaster,
    downloadSupplementaryServiceMaster,
    createSupplementaryServiceMaster,
    deleteSupplementaryServiceMasterSingle,
    updateSupplementaryServiceMaster,
} from '@api/supplementaryServices';

const viewControl = {
    pageTitle: 'Supplementary Services',
    buckets: ['supplementaryServices'],
    globalActions: [],
    downloadOptions : [
        {
            key: 'download',
            title: 'Download Data',
            action: 'download',
        },
    ],
    addOptions: [
        {
            key: 'add_single',
            title: 'Single',
            action: 'add_single',
            type: 'single',
        },
        // {
        //     key: 'add',
        //     title: 'Bulk',
        //     action: 'add_bulk',
        //     type: 'bulk',
        // },
    ],
    onSingleUpdate: updateSupplementaryServiceMaster,
    onSingleDelete: deleteSupplementaryServiceMasterSingle,
    fetchData: fetchSupplementaryServiceMaster,
    downloadData: downloadSupplementaryServiceMaster,
    // add_bulk: {
    //     downloadSampleCsvFromApiCall: false,
    //     sampleUrlWithParams: downloadCsvForCreate,
    //     title: 'Add Supplementary Services',
    //     onBulkUpload: uploadCsvForCreate,
    // },
    // update: {
    //     downloadSampleCsvFromApiCall: false,
    //     sampleUrlWithParams: downloadCsvForUpdate,
    //     title: 'Update Customer',
    //     onBulkUpload: uploadCsvForUpdate,
    // },
    params: 'customer_array',
    modalHeading: 'Supplementary Services',
    warningMsg: 'No Customers to upload',
    successMsg: 'Supplementary Service Uploaded Successfully',
    updateMsg: 'Supplementary Service Updated Successfully',
    failureArrayColumns: [
        {
            key: 'customer_code',
            dataIndex: 'customer_code',
            title: 'Customer Code',
        },
        {
            key: 'message',
            dataIndex: 'message',
            title: 'Message',
        },
    ],
    bulkUploadMapping: null,
    sectionWiseForm: true,
    useDrawer: true,
    uploadProps: {
        accept: '.pdf, .png, .jpeg, .jpg',
        name: 'file',
        multiple: false,
        showUploadList: { showPreviewIcon: true, showRemoveIcon: true },
    },
    firstRowAsHeader: true,
    onSingleAdd: createSupplementaryServiceMaster,
    // onSingleUpdate: updateCustomer,
    id: 'code',
};

const buckets = {
    supplementaryServices: {
        name: 'Supplementary Services',
        filters: [],
        actions: [],
    },
};

const bucketColor = {};

export default { viewControl, buckets, bucketColor };
