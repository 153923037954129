import { API_BASE_URL } from '@src/globals';
import { POST, GET } from '@api/apiHandler';
import {
    FETCH_ALL_OBJECT_PAGES,
    CREATE_OBJECT_PAGE,
    UPDATE_OBJECT_PAGE,
    ACTION_OBJECT_PAGE,
    FETCH_OBJECT_PAGE_MASTER_DATA,
    FETCH_OBJECT_PAGES_FOR_EMPLOYEE,
} from '@api/routes';

export const fetchAllObjectPages = (body) => {
    return POST(`${API_BASE_URL}${FETCH_ALL_OBJECT_PAGES}`, body);
};

export const createObjectPage = (body) => {
    return POST(`${API_BASE_URL}${CREATE_OBJECT_PAGE}`, body);
};

export const updateObjectPage = (viewId, body) => {
    return POST(`${API_BASE_URL}${UPDATE_OBJECT_PAGE(viewId)}`, body);
};

export const actionObjectPage = (viewId, body) => {
    return POST(`${API_BASE_URL}${ACTION_OBJECT_PAGE(viewId)}`, body);
};

export const fetchPageBuilderMasterData = async () => {
    return GET(`${API_BASE_URL}${FETCH_OBJECT_PAGE_MASTER_DATA}`);
};

export const fetchObjectPagesForEmployee = async () => {
    return GET(`${API_BASE_URL}${FETCH_OBJECT_PAGES_FOR_EMPLOYEE}`);
};
