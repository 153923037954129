import RetailAnalyticsView from './retail';
import OnDemandAnalyticsView from './ondemand';
const viewControlResolver = {
  retail : RetailAnalyticsView,
  ondemand: OnDemandAnalyticsView,
};

function getViewControl(viewType) {
  return viewControlResolver[viewType].viewControl;
}

function getViewBuckets(viewType) {
  if (viewType !== 'domestic') {
    if(viewType === 'ondemand'){
      return viewControlResolver[viewType].getOnDemandBuckets();
    }
    return viewControlResolver[viewType].buckets;
  }
}

function getViewCharts(viewType) {
  if(viewType === 'ondemand'){
    return viewControlResolver[viewType].getOnDemandCharts();
  }
  return viewControlResolver[viewType].charts;
}

export { getViewControl, getViewBuckets, getViewCharts };
