import React, { useState } from 'react';
import { ThemeType } from '@ant-design/compatible/lib/icon';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Input, message } from 'antd';
import withStyles from 'react-jss';
import { CssType } from 'src/theming/jssTypes';
import lodash from 'lodash';
import { getDstServiceability, getHubNameFromPincode } from 'src/api/dashboard';

const styles = (theme: ThemeType): CssType => ({
  wrapper: {
    padding: '10px 15px',
    border: '1px solid #D3D3D3',
    borderRadius: 6,
    top: 60,
    zIndex: 1,
    right: 16,
    position: 'absolute',
  },
  cross: {
    color: 'grey',
    padding: '4px',
    float: 'right',
    marginTop: '-8px',
  },
});

const UNDEFINED = 'UNDEFINED';
const DstServiceableCheck = (props) => {
  const [pincode, setPincode] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [hubName, setHubName] = useState<string>(UNDEFINED);
  const [isServiceable, setIsServiceable] = useState<boolean>(false);
  const { classes, hideContent } = props;

  const fetchServiceableInfo = async (pincode) => {
    setLoading(true);
    const params = {
      metadataType: 'serviceability',
      chargeId: 'serviceability',
      property_pincode: pincode,
    };
    const response = await getDstServiceability(params);
    if (response.isSuccess) {
      const isServiceAble = lodash.get(response.data, 'data[0][4]', false);
      let hubName = '';
      if (isServiceAble) {
        hubName = await fetchHubName(pincode);
      }
      setHubName(hubName);
      setIsServiceable(isServiceAble);
    } else {
      message.error(response.errorMessage);
    }
    setLoading(false);
  };

  const fetchHubName = async (pincode) => {
    let hubName = '';
    const params = {
      query_str: pincode,
    };
    const response = await getHubNameFromPincode(params);
    if (response.isSuccess) {
      hubName = lodash.get(response.data, '[0].hub_name', '');
    }
    return hubName;
  };

  const isValidPincode = (pincode) => {
    return pincode && pincode.length === 6;
  };

  const handleInputChange = (e) => {
    const value = Number(e.target.value);
    if (!isNaN(value)) {
      setPincode(e.target.value);
      if (hubName !== UNDEFINED) {
        setHubName(UNDEFINED);
      }
    }
  };
  const renderResult = () => {
    if (hubName === UNDEFINED) {
      return '';
    }
    return isServiceable ? (
      <div> True, {hubName}</div>
    ) : (
      <div style={{ color: 'red' }}> False </div>
    );
  };

  return (
    <div className={classes.wrapper}>
      <div style={{ marginBottom: '10px' }}>
        <span style={{ color: 'black', fontSize: '13px' }}>
          PIN Code Delivery Serviceability
        </span>
        <span>
          <CloseOutlined
            className={classes.cross}
            onClick={() => hideContent()}
          />
        </span>
      </div>
      <Input
        maxLength={6}
        onChange={handleInputChange}
        style={{ width: 200 }}
        placeholder="Destination Pin"
      ></Input>
      <Button
        loading={loading}
        disabled={!isValidPincode(pincode)}
        onClick={() => fetchServiceableInfo(pincode)}
        style={{ width: 100, marginLeft: 10 }}
        type="primary"
      >
        Check
      </Button>
      {renderResult()}
    </div>
  );
};

export default withStyles(styles, { injectTheme: true })(DstServiceableCheck);
