/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const PicodeAvailablity = (props: any) => (
    <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
        <title>Group 31</title>
        <g id="Uppdated" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Create" transform="translate(-35.000000, -300.000000)">
                <g id="Group-31" transform="translate(36.000000, 301.000000)">
                    <polygon id="Fill-1" fill="#F5D340" points="24 23.9999938 18 20.8797318 16.1538462 11.7039152 20.3076923 14.5121509" />
                    <polygon id="Fill-2" fill="#EDC20B" points="24 23.9999938 21.6562462 22.7811571 18.3521231 13.1900336 20.3076923 14.5121509" />
                    <polygon id="Fill-3" fill="#FFE261" points="18 20.8797318 16.1538462 11.7039152 12 16.4933301 12 23.9999938" />
                    <polygon id="Fill-4" fill="#F5D340" points="6 20.8797318 7.84615385 11.7039152 12 16.4933301 12 23.9999938" />
                    <polygon id="Fill-5" fill="#FFE261" points="6 20.8797318 0 23.9999938 3.69230769 14.5121509 7.84615385 11.7039152" />
                    <g id="Group-30" transform="translate(0.000031, 0.000000)">
                        <polyline id="Stroke-6" stroke="#000000" strokeWidth="0.615384615" strokeLinecap="round" strokeLinejoin="round" points="6.81409231 16.8334448 6 20.8797381 0 24 3.69230769 14.5121572 7.84615385 11.7039215 7.088 15.4717625" />
                        <polyline id="Stroke-8" stroke="#000000" strokeWidth="0.615384615" strokeLinecap="round" strokeLinejoin="round" points="7.29095385 14.4632627 7.84609231 11.7038903 11.9999385 16.4933051 11.9999385 23.9999688 5.99993846 20.8797069 6.73938462 17.2046" />
                        <polygon id="Stroke-10" stroke="#000000" strokeWidth="0.615384615" strokeLinecap="round" strokeLinejoin="round" points="17.9999692 20.8797318 16.1538154 11.7039152 11.9999692 16.4933301 11.9999692 23.9999938" />
                        <polygon id="Stroke-12" stroke="#000000" strokeWidth="0.615384615" strokeLinecap="round" strokeLinejoin="round" points="23.9999692 23.9999938 17.9999692 20.8797318 16.1538154 11.7039152 20.3076615 14.5121509" />
                        <path d="M11.9999692,0 C8.55406154,0 5.76710769,2.82620843 5.76710769,6.32065216 C5.76710769,11.0611413 11.9999692,18.0590151 11.9999692,18.0590151 C11.9999692,18.0590151 18.2328308,11.0611413 18.2328308,6.32065216 C18.2328308,2.82620843 15.4458769,0 11.9999692,0" id="Fill-14" fill="#E9520C" />
                        <path d="M11.6913785,0 C8.74559385,0 5.87070154,2.79388252 5.87070154,6.24819967 C5.87070154,10.9343338 12.0606092,17.8518921 12.0606092,17.8518921 C12.0606092,17.8518921 17.0198092,10.9343338 17.0198092,6.24819967 C17.0198092,2.79388252 14.6372246,0 11.6913785,0" id="Fill-16" fill="#F76725" />
                        <path d="M11.9999692,9.94016222 C10.0297538,9.94016222 8.43073846,8.31862451 8.43073846,6.3206584 C8.43073846,4.32269229 10.0297538,2.70115457 11.9999692,2.70115457 C13.9701846,2.70115457 15.5692,4.32269229 15.5692,6.3206584 C15.5692,8.31862451 13.9701846,9.94016222 11.9999692,9.94016222" id="Fill-18" fill="#FFFFFF" />
                        <path d="M11.9999692,8.56724697 C10.7770769,8.56724697 9.78458462,7.56077529 9.78458462,6.3206584 C9.78458462,5.0805415 10.7770769,4.07406982 11.9999692,4.07406982 C13.2228615,4.07406982 14.2153538,5.0805415 14.2153538,6.3206584 C14.2153538,7.56077529 13.2228615,8.56724697 11.9999692,8.56724697" id="Fill-20" fill="#EC793C" />
                        <path d="M11.4734708,8.56724697 C10.5412246,8.56724697 9.78460923,7.56077529 9.78460923,6.3206584 C9.78460923,5.0805415 10.5412246,4.07406982 11.4734708,4.07406982 C12.4057785,4.07406982 13.1623938,5.0805415 13.1623938,6.3206584 C13.1623938,7.56077529 12.4057785,8.56724697 11.4734708,8.56724697" id="Fill-22" fill="#FB925B" />
                        <path d="M11.9999692,8.56724697 C10.7770769,8.56724697 9.78458462,7.56077529 9.78458462,6.3206584 C9.78458462,5.0805415 10.7770769,4.07406982 11.9999692,4.07406982 C13.2228615,4.07406982 14.2153538,5.0805415 14.2153538,6.3206584 C14.2153538,7.56077529 13.2228615,8.56724697 11.9999692,8.56724697 Z" id="Stroke-24" stroke="#000000" strokeWidth="0.615384615" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M11.9999692,0 C8.55406154,0 5.76710769,2.82620843 5.76710769,6.32065216 C5.76710769,11.0611413 11.9999692,18.0590151 11.9999692,18.0590151 C11.9999692,18.0590151 18.2328308,11.0611413 18.2328308,6.32065216 C18.2328308,2.82620843 15.4458769,0 11.9999692,0 Z" id="Stroke-26" stroke="#000000" strokeWidth="0.615384615" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M11.9999692,9.94016222 C10.0297538,9.94016222 8.43073846,8.31862451 8.43073846,6.3206584 C8.43073846,4.32269229 10.0297538,2.70115457 11.9999692,2.70115457 C13.9701846,2.70115457 15.5692,4.32269229 15.5692,6.3206584 C15.5692,8.31862451 13.9701846,9.94016222 11.9999692,9.94016222 Z" id="Stroke-28" stroke="#000000" strokeWidth="0.615384615" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

const PicodeAvailablityIcon = (props: any) => (
    <Icon component={(innerProps: any) => {
        return PicodeAvailablity({ ...innerProps, ...props });
    }}
    />
);

export default PicodeAvailablityIcon;
