import { CssType, ThemeType } from '@theming/jssTypes';

const styles = (theme: ThemeType): CssType => ({
  loaderContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  logoImage: {
    display: 'flex',
    height: 60,
    margin: '0 auto',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100vw',
  },
  topMainDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    flexGrow: 1,
    background: 'transparent',
  },
  loginContainer: {
    padding: '24px',
    backgroundColor: '#fff',
    maxWidth: '100%',
    borderRadius: '8px'
  },
  roundedAndShadowBorder: {
    boxShadow: '0px 0px 64px rgba(0, 0, 0, 0.12)',
    borderRadius: '8px',
  },
  imageLimit: {
    maxHeight: '480px',
    maxWidth: '800px'
  },
  heading: {
    display: 'flex',
    color: '#333333',
    fontFamily: 'Open Sans',
    fontSize: 24,
    fontWeight: '600',
    letterSpacing: 0,
    margin: '16px auto',
    width: 'fit-content',
  },
  passwordInputHeader: {
    display: 'flex',
    marginTop: 20,
    color: '#666666',
    fontFamily: 'Open Sans',
    fontSize: 10,
    letterSpacing: 0,
    justifyContent: 'space-between',
  },
  inputBox: {
    display: 'flex',
    marginTop: 3,
    width: 350,
    maxWidth: '100%',
  },
  submitButtonContainer: {
    display: 'flex',
    marginTop: 15,
    width: 350,
    maxWidth: '100%',
  },
  ssoBtn: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: "#fff",
    border: "1px solid #999999",
    transition: 'all 0.5s',
    padding: '8px 16px',
    width: '100%',
    justifyContent: 'center',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '600',

    '&:hover': {
      backgroundColor: "#eee",
    },
    '& > img': {
      marginRight: '8px',
      marginLeft: '8px',
      height: '18px',
      width: 'auto',
      mixBlendMode: 'multiply',
    },
  },
  submitButton: {
    backgroundColor: '#006EC3',
  },
  orDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15px',

    '& > hr': {
      borderWidth: 0,
      width: '100%',
      backgroundColor: '#D9D9D9',
      height: '2px',
    },
    '& > span': {
      position: 'absolute',
      backgroundColor: '#fff',
      color: '#666666',
      padding: '0 8px',
    },
  },
  forgotPasswordBtnDiv: {
    textAlign: 'center',

    '& > button': {
      border: 'none',
      color: '#006EC3',
      background: 'transparent',
      marginTop: '10px',
      fontSize: '12px',
      lineHeight: '22px',
      fontWeight: '600',
      cursor: 'pointer',
    },
  },
  footerDiv: {
    margin: 'auto auto 8px',

    '& > span': {
      color: '#666666',
      fontSize: '12px',
    },
    '& > img': {
      marginLeft: '8px',
      height: '16px',
    },
  },
  centeredContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'stretch',
    flexDirection: 'column',
    flexGrow: 1
  }
});

export default styles;
