import { NAVBAR_HEIGHT } from 'src/globals';
import { CssType, ThemeType } from 'src/theming/jssTypes';
import { isMobile } from 'react-device-detect';
import { MoreColors } from 'src/theming/moreColors';

export const navbarStyles = (theme: ThemeType) : CssType => ({
    navbar: {
        height: `${NAVBAR_HEIGHT}px`,
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexShrink: 0,
        padding: '4px 16px',
        borderBottom: `1px solid ${theme.colors.primaryText}`,
        color: theme.colors.textOnDarkBg,
        backgroundColor: theme.colors.globalHeader,
        fontSize: theme.sizes.bodyText,
        fontFamily: theme.fonts.fontFamily,
    },
    hamburger: {
        '&:hover': {
            cursor: 'pointer',
        },
        color: theme.colors.textOnDarkBg,
        fontSize: theme.sizes.bodyText,
        position: 'relative',
        zIndex: '501',
    },
    leftDiv: {
        display: 'flex',
        marginTop: '5px',
    },
    filterGroupToolsLeft: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-start',
        marginLeft: '20px',
        position: 'relative',
        zIndex: '501',
    },
    filterGroupToolsRight: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
        marginLeft: 'auto',
        position: 'relative',
        zIndex: '501',

        '& .ant-input-search .ant-input-group .ant-input-affix-wrapper': {
            backgroundColor: `${theme.colors.surfaceBg}0F !important`,
            color: theme.colors.textOnDarkBg,
            fontSize: theme.sizes.bodyText,
            fontFamily: theme.fonts.fontFamily,
            borderRadius: '0px !important',
            border: 'none',
        },
        '& .ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input': {
            backgroundColor: `transparent !important`,
            color: theme.colors.textOnDarkBg,
            fontSize: theme.sizes.bodyText,
            fontFamily: theme.fonts.fontFamily,
            border: 'none',
        },
        '& .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary)': {
            backgroundColor: `${theme.colors.globalHeader}`,
            color: theme.colors.textOnDarkBg,
            fontSize: theme.sizes.bodyText,
            fontFamily: theme.fonts.fontFamily,
            border: 'none',
        },
        '& .ant-btn-link': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '24px',
            width: '24px',
        },
        '& .ant-btn > span': {
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: theme.sizes.bodyText,
        },
        '& .ant-select-selector': {
            border: 'none !important',
            borderRadius: '0px !important',
            backgroundColor: `${theme.colors.surfaceBg}1A !important`,
        },
        '& .ant-input-group-wrapper': {
            '& .ant-btn': {
                backgroundColor: `${theme.colors.searchBarBackground} !important`,
                height: '26.25px',
                display: 'flex',
                alignItems: 'center',
            },
        }
    },
    profileDropDown: {
        backgroundColor: theme.colors.globalHeader,
        boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.24)',
        borderRadius: 4,
        color: theme.colors.textOnDarkBg,
        '& .ant-menu ': {
            color: theme.colors.textOnDarkBg,
            backgroundColor: theme.colors.globalHeader
        },
        '.ant-menu-item a': {
            color: theme.colors.textOnDarkBg,
        },
        '& .ant-menu-item': {
            color: `${theme.colors.textOnDarkBg} !important`,
        },
        '& .ant-menu-item:hover': {
            color: theme.colors.textOnDarkBg,
            backgroundColor: `${theme.colors.HoverOnDarkBg} !important`,
        },
        '& .ant-menu-item-selected': {
            backgroundColor: `${theme.colors.selectionOnDarkBg} !important`,
            '& .ant-select-selector': {
                margin: 'none !important',
                backgroundColor:  `${theme.colors.selectionOnDarkBg} !important`,
                display: 'flex',
                color: theme.colors.textOnDarkBg,
                fontSize: theme.sizes.bodyText,
                fontFamily: theme.fonts.fontFamily,
                alignItems: 'center',
                border: 'none',
              },
        },
        '& .ant-menu-item, & .ant-menu-item-active': {
            border: 'none !important',
        },
        '& .ant-btn-link': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '24px',
            width: '24px',
        },
        '& .ant-btn > span': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '24px',
            width: '24px',
            fontSize: theme.sizes.bodyText,
        },

        margin: '0px 4px',
    },
    toolContainer: {
        marginLeft: '4px',
        marginRight: '4px',
        '& .ant-input-group-addon': {
            background: `${theme.colors.surfaceBg}1A`,
            color: theme.colors.textOnDarkBg,
            border: 'none',
            fontSize: theme.sizes.bodyText,
        },
        '& .ant-input': {
            background: `${theme.colors.surfaceBg}1A`,
        },
        '& .ant-btn': {
            border: 'none',
            background: `${theme.colors.surfaceBg}1A`,
            color: theme.colors.textOnDarkBg,
            '& :hover': {
                background: `transparent !important`,
            }
        },
        // '&:not(:last-child)': {
        //   marginLeft: '8px',
        // },
    },
    profile: {
        height: 28,
        width: 28,
        borderRadius: 14,
        background: theme.colors.globalHeader,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        paddingLeft: 10,
        marginLeft: 12,
        color: theme.colors.textOnDarkBg,
        fontWeight: 'bold',
    },
    circle: {
        width: '25%',
        marginLeft: 10,
    },
    trackIcon: {
        borderRadius: 50,
        backgroundColor: `${theme.colors.surfaceBg}0F`,
        color: theme.colors.textOnDarkBg,
        cursor: 'pointer',
        margin: '0 4px',
        marginTop: 4,
        height: '24px',
        width: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    helpIcon: {
        fontSize: '14px',
        color: theme.colors.textOnDarkBg,
        margin: '0px 4px',
    },
    pageTitle: {
        fontSize: theme.sizes.subHeading,
        fontFamily: theme.fonts.fontFamily,
        marginLeft: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        position: 'relative',
        lineHeight: '14px',
    },
    noMargin: {
        margin: '0px',
    },
    customDivForName: {
        fontFamily: theme.fonts.fontFamily,
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: theme.sizes.bodyText,
        color: theme.colors.textOnDarkBg,
    },
    customDivForEmail: {
        fontFamily: theme.fonts.fontFamily,
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: theme.sizes.bodyText,
        color: theme.colors.textOnDarkBg,
    },
    linkForActivityLog: {
        color: `${theme.colors.textOnDarkBg} !important`,
    },
    tickStyle: {
        marginLeft: '30px',
        color: theme.colors.success,
    },
    logoutIconStyles: {
        color: theme.colors.textOnDarkBg,
        height: 38,
    },
    dropdownStyles: {
        backgroundColor: theme.colors.globalHeader,
        fontSize: theme.sizes.bodyText,
        fontFamily: theme.fonts.fontFamily,
        fontWeight: 'normal',
        '& .ant-select-item-option': {
          backgroundColor: theme.colors.globalHeader,
          color: theme.colors.textOnDarkBg,
        },
        '& .ant-select-item-option:hover': {
          backgroundColor: `${theme.colors.hyperlink}4D !important`,
        },
        '& .ant-select-item-option-content': {
          fontWeight: 'normal',
          fontSize: theme.sizes.bodyText,
          fontFamily: theme.fonts.fontFamily,
        }
    },
    changeLanguagedropdown: {
        backgroundColor: theme.colors.globalHeader,
        color: theme.colors.textOnDarkBg,
        margin: 'none !important',
        '&.ant-select .ant-select-selector': {
            margin: 'none !important',
            backgroundColor: theme.colors.globalHeader,
            display: 'flex',
            color: theme.colors.textOnDarkBg,
            fontSize: theme.sizes.bodyText,
            fontFamily: theme.fonts.fontFamily,
            alignItems: 'center',
            border: 'none',
          },
          '& .ant-select-arrow': {
            color: theme.colors.textOnDarkBg,
            fontSize: theme.sizes.bodyText,
          },
          '& .ant-select-selector': {
            margin: 'none !important',
            backgroundColor: theme.colors.globalHeader,
            color: theme.colors.textOnDarkBg,
            fontSize: theme.sizes.bodyText,
            fontFamily: theme.fonts.fontFamily,
            borderTopRightRadius: '0 !important',
            borderBottomRightRadius: '0 !important',
          },
    }
});
