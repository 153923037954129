import * as React from 'react';
import Loader from '@components/common/Loader';
import styles from '@components/pages/LoginPageAuth/styles';
import { callbackOidcOAuthUrl } from '@api/login';
import withStyles from 'react-jss';
import { getURLConfigs } from '@api/utils';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import url from 'url';
import { fetchOrganisationDetailsAndRedirect, setLocalStorageDataFromJSON } from './SSORedirect';

const OidcOAuth = (props) => {
    const { classes } = props;
    const { organisationId, issuer } = useParams();
    const { location } = window;

    React.useEffect(() => {
        getRequiredDataToSetInLocalStorage();
    }, []);

    const getRequiredDataToSetInLocalStorage = async () => {
        const { href = '' } = location;
        const { query } = url.parse(href);
        const searchParams = new URLSearchParams(query);
        const code = searchParams.has('code') ? searchParams.get('code') : null;

        const urlConfigs = getURLConfigs();
        const resp = await callbackOidcOAuthUrl({
            issuer,
            organisationId,
            code,
            error: '',
        });

        let dataToSet = resp?.data;
        if (!resp || resp?.data?.status === 'ERROR') {
            message.error(resp?.data?.error);
        }
        setLocalStorageDataFromJSON(dataToSet);
        fetchOrganisationDetailsAndRedirect();
    };

    return (
        <div className={classes.loaderContainer}>
            <Loader zIndex={100} />
        </div>
    );
};

export default withStyles(styles, { injectTheme: true })(OidcOAuth);
