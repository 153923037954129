import Icon from '@ant-design/icons';
import * as React from 'react';
const PngFileIconsvg = (props: any) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    dataName="Layer 1"
    viewBox="0 0 118 150"
    {...props}
  >
    <defs></defs>
    <path
      className="png-cls-1"
      d="M80.09,43.76a6,6,0,0,1-6-6V0h-59A15.08,15.08,0,0,0,0,15V135a15.08,15.08,0,0,0,15.06,15h87.88A15.08,15.08,0,0,0,118,135V43.76Z"
    />
    <path
      className="png-cls-2"
      d="M79.57,43.73h38.18L74,0V38.19A6,6,0,0,0,79.57,43.73Z"
    />
    <path
      className="png-cls-3"
      d="M24.54,117.36A3.73,3.73,0,1,1,21,121.11,3.58,3.58,0,0,1,24.54,117.36Z"
    />
    <path
      className="png-cls-3"
      d="M39.14,125.91v5.85H33.22V104.33h4.84l.4,2h.16a8.8,8.8,0,0,1,5.69-2.46c4.84,0,7.82,4,7.82,10.16,0,6.86-4.11,10.81-8.43,10.81a7,7,0,0,1-4.76-2.1ZM42.33,120c2.06,0,3.71-1.77,3.71-5.89,0-3.59-1.09-5.4-3.47-5.4a4.63,4.63,0,0,0-3.43,1.81v8.19A4.6,4.6,0,0,0,42.33,120Z"
    />
    <path
      className="png-cls-3"
      d="M56.33,104.33h4.84l.4,2.54h.16a9.22,9.22,0,0,1,6.41-3c4.36,0,6.21,3.06,6.21,8.06v12.43H68.43V112.68c0-2.9-.77-3.79-2.46-3.79-1.5,0-2.42.69-3.72,1.94v13.51H56.33Z"
    />
    <path
      className="png-cls-3"
      d="M81,123.73v-.16a3.84,3.84,0,0,1-1.86-3.39,4.84,4.84,0,0,1,2.26-3.75v-.16a6.4,6.4,0,0,1-2.7-5.2c0-4.8,4-7.22,8.27-7.22a9.3,9.3,0,0,1,3.1.48h7.3v4.32H94.2a5.35,5.35,0,0,1,.64,2.62c0,4.6-3.47,6.73-7.86,6.73a8,8,0,0,1-2.42-.4,1.69,1.69,0,0,0-.73,1.49c0,1,.81,1.5,3,1.5h3.22c4.92,0,7.63,1.49,7.63,5.08,0,4.15-4.32,7.17-11.17,7.17-4.68,0-8.39-1.53-8.39-5.08C78.15,126.11,79.15,124.78,81,123.73Zm6.45,5.4c2.66,0,4.6-1.08,4.6-2.46S91,125.1,89,125.1H86.94a11.68,11.68,0,0,1-2.82-.24,2.6,2.6,0,0,0-1.09,2C83,128.33,84.8,129.13,87.46,129.13Zm2.26-18.06c0-2.1-1.17-3.23-2.74-3.23s-2.74,1.09-2.74,3.23,1.17,3.34,2.74,3.34S89.72,113.29,89.72,111.07Z"
    />
  </svg>
);

const PngFileIcon = (props: any) => (
  <Icon component={PngFileIconsvg} {...props} />
);

export default PngFileIcon;
