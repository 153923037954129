import apiProvider from './provider';
import { getAuthenticationHeaders, getErrorMessage } from './utils';
import { API_BASE_URL } from '../globals';
import { CHANGE_PASSWORD, GENERATE_DEVREV_AUTH_TOKEN } from './routes';
import { GET } from './apiHandler';

export const changeUserPassword = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${CHANGE_PASSWORD}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const generateDevrevAuthToken = async () => {
  return await GET(`${API_BASE_URL}${GENERATE_DEVREV_AUTH_TOKEN}`);
};

