import moment from 'moment-timezone';
import { filterTimeFormats } from 'src/actions/constants';

const isSame = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);

export const compareNumberOperations = (operator, lhs = {}, rhs: any = {}) => {
  const { value = {} } = rhs;
  switch (operator) {
    case 'numberIsGreaterThanOrEqualTo':
      return lhs >= value && !isSame(lhs, {});
    case 'numberIsLessThanOrEqualTo':
      return lhs <= value && !isSame(lhs, {});
    case 'numberIsLessThan':
      return lhs < value && !isSame(lhs, {});
    case 'numberIsGreaterThan':
      return lhs > value && !isSame(lhs, {});
    case 'numberIsEqualTo':
      return lhs === Number(value);
    case 'numberIsNotEqualTo':
      return lhs !== Number(value);
    case 'numberIsNull':
      if (typeof lhs === 'object' && isSame(lhs, {})) return true;
      return lhs ? false : true;
    case 'numberIsNotNull':
      if (typeof lhs === 'object' && isSame(lhs, {})) return false;
      return lhs ? true : false;
    case 'numberBetween':
      return lhs >= value?.from && lhs <= value?.to;
    default:
      return false;
  }
};

const unitMapping = {
  days: 'day',
  weeks: 'week',
  month: 'month',
  quarters: 'quarter',
  years: 'year',
};

export const compareTimestampzOperations = (
  operator,
  lhs: any = {},
  rhs: any = {},
  bucketValue,
) => {
  switch (operator) {
    case 'timestampzBetween':
      const { value = {} } = rhs;
      return (
        moment(lhs).diff(moment(value.from), bucketValue) >= 0 &&
        moment(lhs).diff(moment(value.to), bucketValue) <= 0
      );
    case 'timestampzCurrent':
      const currentDate = moment();
      return moment(lhs).isSame(currentDate, rhs.value);
    case 'timestampzPrevious':
      const startDatePrev = moment().subtract(
        rhs.value?.quantity,
        rhs.value?.unit,
      );
      const endDatePrev = moment();
      return moment(lhs).isBetween(startDatePrev, endDatePrev, undefined, '[]');
    case 'timestampzPast':
      if (!lhs) return false;
      const startDatePast = moment()
        .subtract(rhs.value?.quantity, rhs.value?.unit)
        .startOf(unitMapping[rhs.value?.unit]);
      const endDatePast = moment();
      return moment(lhs).isBetween(startDatePast, endDatePast, undefined, '[]');
    case 'timestampzIsBefore':
      return moment(lhs).isBefore(moment(rhs.value));
    case 'timestampzIsAfter':
      return moment(lhs).isAfter(moment(rhs.value));
    case 'timestampzIsNull':
      if (typeof lhs === 'object' && isSame(lhs, {})) return true;
      return lhs ? false : true;
    case 'timestampzIsOn':
      return (
        moment(lhs).format(filterTimeFormats[bucketValue]) ===
        moment(rhs.value).format(filterTimeFormats[bucketValue])
      );
    default:
      return false;
  }
};

export const compareTimestampOperations = (
  operator,
  lhs: any = {},
  rhs: any = {},
  bucketValue,
) => {
  switch (operator) {
    case 'timestampzBetween':
      const { value = {} } = rhs;
      return (
        moment(lhs).diff(moment(value.from), bucketValue) >= 0 &&
        moment(lhs).diff(moment(value.to), bucketValue) <= 0
      );
    case 'timestampzCurrent':
      const currentDate = moment();
      return moment(lhs).isSame(currentDate, rhs.value);
    case 'timestampzPrevious':
      const startDatePrev = moment().subtract(
        rhs.value?.quantity,
        rhs.value?.unit,
      );
      const endDatePrev = moment();
      return moment(lhs).isBetween(startDatePrev, endDatePrev, undefined, '[]');
    case 'timestampzPast':
      if (!lhs) return false;
      const startDatePast = moment()
        .subtract(rhs.value?.quantity, rhs.value?.unit)
        .startOf(unitMapping[rhs.value?.unit]);
      const endDatePast = moment();
      return moment(lhs).isBetween(startDatePast, endDatePast, undefined, '[]');
    case 'timestampzIsBefore':
      return moment(lhs).isBefore(moment(rhs.value));
    case 'timestampzIsAfter':
      return moment(lhs).isAfter(moment(rhs.value));
    case 'timestampzIsNull':
      return typeof lhs === 'object' ? isSame(lhs, {}) : lhs ? true : false;
    case 'timestampzIsOn':
      return (
        moment(lhs).format(filterTimeFormats[bucketValue]) ===
        moment(rhs.value).format(filterTimeFormats[bucketValue])
      );
    default:
      return false;
  }
};

export const compareDateOperations = (
  operator,
  lhs: any = {},
  rhs: any = {},
  bucketValue,
) => {
  switch (operator) {
    case 'timestampzBetween':
      const { value = {} } = rhs;
      return (
        moment(lhs).diff(moment(value.from), bucketValue) >= 0 &&
        moment(lhs).diff(moment(value.to), bucketValue) <= 0
      );
    case 'timestampzCurrent':
      const currentDate = moment();
      return moment(lhs).isSame(currentDate, rhs.value);
    case 'timestampzPrevious':
      const startDatePrev = moment().subtract(
        rhs.value?.quantity,
        rhs.value?.unit,
      );
      const endDatePrev = moment();
      return moment(lhs).isBetween(startDatePrev, endDatePrev, undefined, '[]');
    case 'timestampzPast':
      if (!lhs) return false;
      const startDatePast = moment()
        .subtract(rhs.value?.quantity, rhs.value?.unit)
        .startOf(unitMapping[rhs.value?.unit]);
      const endDatePast = moment();
      return moment(lhs).isBetween(startDatePast, endDatePast, undefined, '[]');
    case 'timestampzIsBefore':
      return moment(lhs).isBefore(moment(rhs.value));
    case 'timestampzIsAfter':
      return moment(lhs).isAfter(moment(rhs.value));
    case 'timestampzIsNull':
      return typeof lhs === 'object' ? isSame(lhs, {}) : lhs ? true : false;
    case 'timestampzIsOn':
      return (
        moment(lhs).format(filterTimeFormats[bucketValue]) ===
        moment(rhs.value).format(filterTimeFormats[bucketValue])
      );
    default:
      return false;
  }
};

export const compareBooleanOperation = (
  operator,
  lhs: any = {},
  rhs: any = {},
) => {
  switch (operator) {
    case 'booleanIsEqualTo':
      return lhs === rhs.value;
    case 'booleanIsNull':
      if (typeof lhs === 'object' && isSame(lhs, {})) return true;
      return lhs ? false : true;
    case 'booleanIsNotNull':
      if (typeof lhs === 'object' && isSame(lhs, {})) return false;
      return lhs ? true : false;
    case 'booleanIsNotTrue':
      return lhs !== true;
    case 'booleanIsNotFalse':
      return lhs !== false;
    default:
      return false;
  }
};
