import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import * as LogRocket from 'logrocket';

const configureStore = preloadedState => createStore(
  rootReducer,
  preloadedState,
  compose(
    applyMiddleware(thunk),
    applyMiddleware(LogRocket.reduxMiddleware()),
  ),
);

export default configureStore;
