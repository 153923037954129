import React from 'react';
import { useTranslation } from 'react-i18next';

const CreditNoteUrlTableAction = (props) => {
    const {
        record,
    } = props;

    const { t } = useTranslation();

    return <a href={record.credit_note_url} target="_blank">{t('credit_note_2')}</a>;
};

export default CreditNoteUrlTableAction;