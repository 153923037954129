import Icon from '@ant-design/icons';
import * as React from 'react';

const ReportingSvg = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Menu" transform="translate(-24.000000, -295.000000)">
                <g id="Reporting-Copy" transform="translate(24.000000, 295.000000)">
                    <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                    <g id="Reporting" transform="translate(1.500000, 1.500000)" fill="#666666">
                        <path d="M9.20999993,4.19072 C7.95993993,4.19051 6.72915993,4.49869 5.62670993,5.08796 C4.52424993,5.67723 3.58416993,6.52938 2.88976993,7.5689 C2.19536993,8.6084 1.76810993,9.8032 1.64584993,11.0474 C1.52359993,12.2915 1.71011993,13.5467 2.18889993,14.7015 C2.66766993,15.8563 3.42390993,16.8752 4.39057993,17.6678 C5.35724993,18.4604 6.50449993,19.0023 7.73065993,19.2455 C8.95679993,19.4886 10.2239999,19.4255 11.4198999,19.0617 C12.6158999,18.698 13.7035999,18.0448 14.5867999,17.16 C14.9007999,16.8455 15.4102999,16.845 15.7247999,17.159 C16.0393999,17.473 16.0397999,17.9825 15.7257999,18.297 C14.6556999,19.3692 13.3374999,20.1607 11.8882999,20.6015 C10.4390999,21.0423 8.90349993,21.1188 7.41759993,20.8242 C5.93173993,20.5295 4.54150993,19.8728 3.37011993,18.9123 C2.19871993,17.9519 1.28233993,16.7172 0.702179929,15.3179 C0.122029929,13.9185 -0.104000071,12.3976 0.0441499291,10.89 C0.192289929,9.3824 0.710029929,7.93458 1.55146993,6.67492 C2.39291993,5.41527 3.53208993,4.38264 4.86803993,3.66857 C6.20397993,2.9545 7.69543993,2.58105 9.21019993,2.58129987 C9.65469993,2.58129987 10.0148999,2.94171 10.0147999,3.38614 C10.0146999,3.83057 9.65439993,4.19079 9.20999993,4.19072 Z" id="Path"></path>
                        <path d="M9.05529993,0.23569 C9.20619993,0.08478 9.41089993,0 9.62429993,0 C12.6412999,0 15.5347999,1.19862 17.6681999,3.33215 C19.8012999,5.46545 20.9997999,8.35872 21.0000275,11.3756 C21.0000275,11.3759 21.0000275,11.3763 21.0000275,11.3766 L20.1952999,11.3766 L21.0000275,11.3748 C21.0000275,11.3751 21.0000275,11.3753 21.0000275,11.3756 C21.0032999,12.8703 20.7098999,14.3508 20.1368999,15.7313 C19.5637999,17.1121 18.7223999,18.3654 17.6613999,19.4185 C17.3467999,19.7308 16.8388999,19.7299 16.5254999,19.4164 L9.05529993,11.9456 C8.90439993,11.7947 8.81959993,11.59 8.81959993,11.3766 L8.81959993,0.80471 C8.81959993,0.59129 8.90439993,0.38661 9.05529993,0.23569 Z M10.4289999,1.64261 L10.4289999,11.0433 L17.0730999,17.688 C17.7290999,16.919 18.2617999,16.0509 18.6504999,15.1144 C19.1418999,13.9303 19.3934999,12.6604 19.3906247,11.3784 L19.3906247,11.3766 C19.3906247,8.7862 18.3616999,6.30183 16.5300999,4.47014 C14.8891999,2.82909 12.7244999,1.83232 10.4289999,1.64261 Z" id="Shape"></path>
                        <path d="M9.05529993,0.23569 C9.20619993,0.08478 9.41089993,0 9.62429993,0 C12.6412999,0 15.5347999,1.19862 17.6681999,3.33215 C19.8012999,5.46545 20.9997999,8.35872 21.0000275,11.3756 C21.0000275,11.3759 21.0000275,11.3763 21.0000275,11.3766 L20.1952999,11.3766 L21.0000275,11.3748 C21.0000275,11.3751 21.0000275,11.3753 21.0000275,11.3756 C21.0032999,12.8703 20.7098999,14.3508 20.1368999,15.7313 C19.5637999,17.1121 18.7223999,18.3654 17.6613999,19.4185 C17.3467999,19.7308 16.8388999,19.7299 16.5254999,19.4164 L9.05529993,11.9456 C8.90439993,11.7947 8.81959993,11.59 8.81959993,11.3766 L8.81959993,0.80471 C8.81959993,0.59129 8.90439993,0.38661 9.05529993,0.23569 Z M10.4289999,1.64261 L10.4289999,11.0433 L17.0730999,17.688 C17.7290999,16.919 18.2617999,16.0509 18.6504999,15.1144 C19.1418999,13.9303 19.3934999,12.6604 19.3906247,11.3784 L19.3906247,11.3766 C19.3906247,8.7862 18.3616999,6.30183 16.5300999,4.47014 C14.8891999,2.82909 12.7244999,1.83232 10.4289999,1.64261 Z" id="Shape"></path>
                        <path d="M0.0104999291,11.3766 C0.0104999291,10.9322 0.370779929,10.5719 0.815209929,10.5719 L9.21039993,10.5719 C9.65479993,10.5719 10.0150999,10.9322 10.0150999,11.3766 C10.0150999,11.821 9.65479993,12.1813 9.21039993,12.1813 L0.815209929,12.1813 C0.370779929,12.1813 0.0104999291,11.821 0.0104999291,11.3766 Z" id="Path"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    );
};

export const ReportingIcon = props => (
  <Icon component={ReportingSvg} {...props} />
);
