import {
  OPS_DASHBOARD_MANAGE_SET_MAP_VIEW,
  OPS_DASHBOARD_MANAGE_SET_SHOW_VIEW_TYPE,
} from '../actions/constants';

const getDefaultState = () => {
  return {
    isMapView: true,
    showViewTypeOption: false,
  };
};

export default function (state: any = getDefaultState(), action) {
  switch (action.type) {
    case OPS_DASHBOARD_MANAGE_SET_MAP_VIEW: {
      return {
        ...state,
        isMapView: action.data || false,
      };
    }

    case OPS_DASHBOARD_MANAGE_SET_SHOW_VIEW_TYPE: {
      return {
        ...state,
        showViewTypeOption: action.data || false,
      };
    }

    default: return state;
  }
}
