import Icon from '@ant-design/icons';
import * as React from 'react';
const ListNotificationEndSvg = (props: any) => (
  // tslint:disable-next-line: max-line-length
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.55859 5.30662C5.55859 4.69542 6.05407 4.19995 6.66526 4.19995H11.2253C11.8365 4.19995 12.3319 4.69542 12.3319 5.30662C12.3319 5.91781 11.8365 6.41328 11.2253 6.41328H7.77193V27.9066H24.2253V6.41328H20.7853C20.1741 6.41328 19.6786 5.91781 19.6786 5.30662C19.6786 4.69542 20.1741 4.19995 20.7853 4.19995H25.3319C25.9431 4.19995 26.4386 4.69542 26.4386 5.30662V29.0133C26.4386 29.6245 25.9431 30.12 25.3319 30.12H6.66526C6.05407 30.12 5.55859 29.6245 5.55859 29.0133V5.30662Z"
      fill="#21AF49"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3332 1.82666C11.9444 1.82666 12.4399 2.32213 12.4399 2.93333V4.69194C12.443 4.78333 12.4636 4.87331 12.5004 4.95706C12.5385 5.04363 12.5933 5.12185 12.6616 5.18725C12.7299 5.25265 12.8105 5.30394 12.8986 5.33821C12.9868 5.37247 13.0808 5.38902 13.1754 5.38693L13.1999 5.38666H18.7999L18.8244 5.38693C18.9189 5.38902 19.013 5.37247 19.1011 5.33821C19.1893 5.30394 19.2698 5.25265 19.3382 5.18725C19.4065 5.12185 19.4613 5.04363 19.4994 4.95706C19.5362 4.87331 19.5567 4.78332 19.5599 4.69194V2.93333C19.5599 2.32213 20.0554 1.82666 20.6666 1.82666C21.2778 1.82666 21.7732 2.32213 21.7732 2.93333V4.70666L21.773 4.73062C21.7646 5.11586 21.6805 5.49568 21.5253 5.84838C21.3701 6.20109 21.147 6.51976 20.8686 6.7862C20.5902 7.05264 20.2621 7.26162 19.9029 7.40121C19.548 7.53914 19.1697 7.60664 18.7891 7.59999H13.2107C12.8301 7.60664 12.4518 7.53914 12.0969 7.40121C11.7377 7.26162 11.4096 7.05264 11.1312 6.7862C10.8528 6.51976 10.6297 6.20109 10.4745 5.84838C10.3193 5.49568 10.2352 5.11586 10.2268 4.73062L10.2266 4.70666V2.93333C10.2266 2.32213 10.722 1.82666 11.3332 1.82666Z"
      fill="#21AF49"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2266 2.93333C10.2266 2.32213 10.722 1.82666 11.3332 1.82666H20.6666C21.2778 1.82666 21.7732 2.32213 21.7732 2.93333C21.7732 3.54452 21.2778 4.03999 20.6666 4.03999H11.3332C10.722 4.03999 10.2266 3.54452 10.2266 2.93333Z"
      fill="#21AF49"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.3932 15.1388C14.8541 15.5402 14.9024 16.2392 14.501 16.7001L12.4343 19.0735C12.2283 19.31 11.9316 19.4481 11.618 19.4532C11.3043 19.4584 11.0032 19.3302 10.7895 19.1006L9.36287 17.5672C8.94654 17.1198 8.97177 16.4195 9.41923 16.0032C9.8667 15.5868 10.5669 15.6121 10.9833 16.0595L11.5725 16.6928L12.8318 15.2466C13.2332 14.7857 13.9322 14.7374 14.3932 15.1388Z"
      fill="#21AF49"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.3932 21.2721C14.8541 21.6735 14.9024 22.3725 14.501 22.8334L12.4343 25.2068C12.2283 25.4433 11.9316 25.5814 11.618 25.5865C11.3043 25.5917 11.0032 25.4635 10.7895 25.2339L9.36287 23.7005C8.94654 23.2531 8.97177 22.5528 9.41923 22.1365C9.8667 21.7201 10.5669 21.7454 10.9833 22.1928L11.5725 22.8261L12.8318 21.3799C13.2332 20.919 13.9322 20.8707 14.3932 21.2721Z"
      fill="#21AF49"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0664 17.16C16.0664 16.5488 16.5619 16.0533 17.1731 16.0533H21.8397C22.4509 16.0533 22.9464 16.5488 22.9464 17.16C22.9464 17.7712 22.4509 18.2667 21.8397 18.2667H17.1731C16.5619 18.2667 16.0664 17.7712 16.0664 17.16Z"
      fill="#21AF49"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.3932 9.20544C14.8541 9.60681 14.9024 10.3059 14.501 10.7668L12.4343 13.1401C12.2283 13.3767 11.9316 13.5147 11.618 13.5199C11.3043 13.525 11.0032 13.3969 10.7895 13.1672L9.36287 11.6339C8.94654 11.1864 8.97177 10.4862 9.41923 10.0698C9.8667 9.65349 10.5669 9.67873 10.9833 10.1262L11.5725 10.7595L12.8318 9.31328C13.2332 8.85235 13.9322 8.80407 14.3932 9.20544Z"
      fill="#21AF49"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0664 11.2267C16.0664 10.6155 16.5619 10.12 17.1731 10.12H21.8397C22.4509 10.12 22.9464 10.6155 22.9464 11.2267C22.9464 11.8379 22.4509 12.3333 21.8397 12.3333H17.1731C16.5619 12.3333 16.0664 11.8379 16.0664 11.2267Z"
      fill="#21AF49"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0664 23.0801C16.0664 22.4689 16.5619 21.9734 17.1731 21.9734H21.8397C22.4509 21.9734 22.9464 22.4689 22.9464 23.0801C22.9464 23.6913 22.4509 24.1867 21.8397 24.1867H17.1731C16.5619 24.1867 16.0664 23.6913 16.0664 23.0801Z"
      fill="#21AF49"
    />
  </svg>
);
export const ListNotificationEnd = (props) => (
  <Icon component={ListNotificationEndSvg} {...props} />
);
