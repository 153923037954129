import Icon from '@ant-design/icons';
import * as React from 'react';
const DragSvg = (props: any) => (
  // tslint:disable-next-line:max-line-length
  <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="#4F4F4F" width="16" height="16" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z" /><path d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
  </svg>
);

export const DragIcon = props => (
   <Icon component={DragSvg} {...props}/>
);
