import React from 'react';
import CustomerSoftdataApiKeyTableAction from './components/SoftdataApiKeyTableAction';

const tableActions = {
    softdata_api_key: (record, otherParams) => {
        return <CustomerSoftdataApiKeyTableAction customerData={record} />;
    },
};

export default tableActions;
