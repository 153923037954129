import store from '../store/appStore';

const getHubId = (iteratee) => {
  return iteratee.globalAppliedFilters.hubId || iteratee.currHub.id;
};

const getWorkerIdList = (iteratee) => {
  return iteratee.globalAppliedFilters.searchList?.length
    ? iteratee.globalAppliedFilters.searchList
    : [];
};

const getStatus = (iteratee) => {
  const status = iteratee.globalAppliedFilters.status;

  if (status === 'settled') {
    return true;
  }
  if (status === 'nonsettled') {
    return false;
  }
  return undefined;
};

const fetchDate = (type, value) => {
  if (type === 'trip') {
    return store?.getState()?.masterData?.show_trip_reconciliation
      ? value
      : undefined;
  }
  return store?.getState()?.masterData
    ?.show_task_completion_filter_in_reconciliation
    ? value
    : undefined;
};

const dateFilters = {
  from_date: {
    path: 'globalAppliedFilters.tripStartDate',
    fn: (value) => {
      return fetchDate('trip', value);
    },
  },
  to_date: {
    path: 'globalAppliedFilters.tripEndDate',
    fn: (value) => {
      return fetchDate('trip', value);
    },
  },
  task_from_date: {
    path: 'globalAppliedFilters.taskStartDate',
    fn: (value) => {
      return fetchDate('task', value);
    },
  },
  task_to_date: {
    path: 'globalAppliedFilters.taskEndDate',
    fn: (value) => {
      return fetchDate('task', value);
    },
  },
};

const requestModelRider = {
  ...dateFilters,

  hub_id: (iteratee) => {
    return getHubId(iteratee);
  },
  worker_id_list: (iteratee) => {
    return getWorkerIdList(iteratee);
  },
  is_settled: (iteratee) => {
    return getStatus(iteratee);
  },
};

const responseModelRider = {
  page_data: 'worker_data',
};

export { requestModelRider, responseModelRider };
