import {
  fetchReasonMaster,
  updateReasonMaster,
  createNewReason,
} from '@api/appFlowConfig';

export const formItemLayout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};

const courierPartnerChangeReasonMapping = [
  { id: 'index', name: 'S. No.' },
  { id: 'reason_id', name: 'Reason Id' },
  { id: 'name', name: 'Reason Name' },
  { id: 'is_active', name: 'Status' },
  { id: 'actions', name: 'Action' },
];

const genericModalFieldList = [
  {
    name: 'Reason Id',
    id: 'reason_id',
    type: 'string',
    isMandatory: true,
    pattern: '^[a-zA-Z0-9-_]+$',
  },
  {
    name: 'Reason Name',
    id: 'name',
    type: 'string',
    isMandatory: true,
    pattern: '^[a-zA-Z0-9-_ ]+$',
  },
  {
    name: 'Status',
    id: 'is_active',
    type: 'boolean',
    defaultChecked: true,
    pattern: null,
    checkedChildren: 'Active',
    unCheckedChildren: 'Inactive',
  },
];

const getKeyMappingCarrierChangeReason = () => {
  return genericModalFieldList;
};

const viewControl = {
  pageTitle: 'Setup',
  id: 'reason_id',
  type: 'courier_partner_change_reasons',
  columnList: courierPartnerChangeReasonMapping,
  add: {
    sampleUrl: undefined,
    downloadSampleCsvFromApiCall: false,
    downloadSampleCsv: null,
    title: 'Add New Carrier Change Reason',
  },
  edit: {
    title: 'Edit Carrier Change Reason',
    okText: 'Update',
  },
  actionList: [
    {
      key: 'addOptions',
      name: 'Add New',
    },
    {
      key: 'pagination',
      name: 'Pagination',
    },
    {
      key: 'perPageCountChange',
      name: 'Pagination Count',
    },
    {
      key: 'reload',
      name: 'Reload',
    },
  ],
  globalActions: [],
  addOptions: [
    {
      key: 'add_single',
      title: 'Single',
      action: 'add',
      type: 'single',
    },
  ],
  modalFieldList: genericModalFieldList,
  filtersList: ['keyword', 'is_active'],
  formItemLayout,
  onSingleUpdate: updateReasonMaster,
  onSingleAdd: createNewReason,
  fetchData: fetchReasonMaster,
  singleUploadMapping: getKeyMappingCarrierChangeReason,
  modalHeading: 'Upload Carrier Partner Change Reason',
  updateMsg: 'Updated Successfully',
  successMsg: 'Uploaded Successfully',
  warningMsg: 'No Carrier Change Reason to upload',
  buckets: ['courierPartnerChangeReasons'],
  tableActions: ['edit'],
};

const buckets = {
  courierPartnerChangeReasons: {
    name: 'Courier Partner Change Reasons',
    filters: [],
    actions: [],
  },
};

const bucketColor = {};
export default { viewControl, buckets, bucketColor };
