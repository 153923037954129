import * as React from 'react';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '../../theming/jssTypes';
import { Modal, Table } from 'antd';
import * as moment from 'moment';
const styles = (theme: ThemeType): CssType => ({
  tableContainer: {
    flex: '1 1 0',
    overflow: 'auto',
    height: 300,
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
    '& .ant-table-tbody > tr > td, .ant-table-thead > tr > th': {
      padding: 9,
    },
  },
});

class EwayBillPartBListModal extends React.Component<any, any> {
  generateDataColumns = () => {
    const columnData: any = [
      {
        title: 'S.No.',
        key: 'index',
        render: (text, record, index) => index + 1,
        width: 30,
      },
      {
        title: 'EWB No.',
        key: 'ewbNumber',
        width: 10,
        render: (text, record) => record['ewbNumber'] ?? '',
      },
      {
        title: 'Status',
        key: 'pickupCompletionPartBStatus',
        width: 30,
        render: (text, record) =>
          record['pickupCompletionPartBStatus'] === false
            ? record['pickupCompletionPartBResponse']
            : record['pickupCompletionPartBStatus'],
      },
    ];
    return columnData;
  };
  render() {
    const { isVisible, classes, ewayBillList } = this.props;
    const header = 'E-Way Bill List';
    return (
      <Modal
        title={header}
        width={500}
        visible={isVisible}
        footer={null}
        onCancel={() => this.props.handleModalClose(false)}
        destroyOnClose
      >
        <div className={classes.tableContainer}>
          <Table
            locale={{ emptyText: 'No EWBs' }}
            pagination={false}
            columns={this.generateDataColumns()}
            dataSource={ewayBillList}
          />
        </div>
      </Modal>
    );
  }
}
export default withStyles(styles, { injectTheme: true })(
  EwayBillPartBListModal,
);
