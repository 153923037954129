import * as React from 'react';
import { Line } from '@ant-design/charts';
import LineConfigs from './ConfigCharts/configLine';

const LineViz = (props) => {
  const {
      formData,
      chartData,
      chartConfig,
      chartsFromDashboard,
    } = props;
  const lineConfig = LineConfigs(chartConfig, formData, chartsFromDashboard);

  const config: any = {
    data: chartData,
    ...formData,
    ...lineConfig,
  };
  return (
    <Line
      {...config}
    />
  );
};

export default LineViz;
