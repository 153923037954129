import * as React from 'react';
import { Switch, Route } from 'react-router';
import { connect } from 'react-redux';
import NotFound404 from '../NotFound404';
import ConsignmentPriceCalculator from './screens/consignment-price-calculator/index';
import { getPriceCalculatorRoute } from './routes';

const TmsDashboardRoutes = (props) => {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={getPriceCalculatorRoute()} component={ConsignmentPriceCalculator} />
        <Route render={(props) => <NotFound404 />} />
      </Switch>
    </React.Fragment>
  );
};

const mapStateToProps = ({ masterData }) => {
  return { masterData };
};

export default connect(mapStateToProps)(TmsDashboardRoutes);
