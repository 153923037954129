import {
  bulkUploadRoutes,
  bulkUpdateRoutes,
  bulkUploadConnections,
  bulkUploadVehicleExceptions,
  bulkUploadBagExceptions,
  bulkUpdateVehicleExceptions,
  bulkUpdateBagExceptions,
  bulkUploadBaggingHubs,
  bulkUpdateBaggingHubs,
  bulkDeleteBaggingHubs,
  downloadSampleCsvBaggingMaster,
  downloadSampleCsvConnectionMaster,
  downloadSampleCsvGateMaster,
  downloadSampleCsvDockMaster,
  bulkUploadGates,
  bulkUploadDocks,
  downloadSampleCsvRouteMaster,
  downloadSampleCsvRouteMasterEnableDisable,
  downloadSampleBulkSettlement,
  bulkUploadBulkSettlement,
  bulkUploadVehicle,
  bulkUploadVendor,
  downloadSampleCsvBagExceptionMaster,
  bulkUploadEWBThresholdMaster,
  downloadSampleCSVEWBThresholdMaster,
} from '../../../../api/TMSDashboard/Setup';
import {
  downloadSampleCsvBookingMaster,
  bulkUpdateBookingMasters,
} from '../../../../api/BookingMasters';
import { API_BASE_URL } from '../../../../globals';
import { getAuthenticationHeaders } from '../../../../api/utils';
import {
  downloadSampleCsvForCheckConsignmentPrice,
  checkConsignmentPriceCalculatorData,
} from 'src/api/dashboard';

const keyMappingConnections = {
  route_code: 'Route Code',
  connection_code: 'Connection Code',
  hub_code: 'Hub',
  arrival_time: 'Arrival Time',
  departure_time: 'Departure Time',
  mode: 'Mode',
  customer_code: 'Customer Code',
  is_active: 'Active',
  master_last_updated_by: 'Last Updated By',
  master_last_updated_at: 'Last Updated At',
  created_by: 'Created By',
  created_at: 'Created At',
};
const keyMappingRoutesAdd = {
  route_code: 'Route Code',
  route_name: 'Route Name',
  position: 'Position',
  hub_code: 'Hub Code',
  distance_from_previous_hub: 'Distance From Previous Hub(In Km)',
  distance_variance: 'Variance (in %age)',
  transit_time: 'Transit Time',
  stoppage_time: 'Stoppage Time',
  trip_type: 'Trip Type',
  master_last_updated_by: 'Last Updated By',
  master_last_updated_at: 'Last Updated At',
};
const keyMappingRoutesUpdate = {
  route_code: 'Route Code',
  route_name: 'Route Name',
  position: 'Position',
  hub_code: 'Hub Code',
  distance_from_previous_hub: 'Distance From Previous Hub(In Km)',
  distance_variance: 'Variance (in %age)',
  transit_time: 'Transit Time',
  stoppage_time: 'Stoppage Time',
  is_active: 'Active',
  trip_type: 'Trip Type',
  master_last_updated_by: 'Last Updated By',
  master_last_updated_at: 'Last Updated At',
};
const keyMappingRoutesUpdateEnableDisable = {
  route_code: 'Route Code',
  route_name: 'Route Name',
  is_active: 'Active',
};
const keyMappingUpdateBagsException = {
  code: 'Exception Code',
  name: 'Exception Name',
  active: 'Active Flag',
  pre_requisite_exception_code: 'Pre Requisite Exception Code',
  pre_requisite_exception_duration:
    'Pre Requisite Exception Duration (In Days)',
  applicable_on: 'Applicable On',
  is_terminal: 'Is Terminal',
  efs_exception_code: 'EFS Exception Code',
};

const keyMappingAddBagsException = {
  code: 'Exception Code',
  name: 'Exception Name',
  pre_requisite_exception_code: 'Pre Requisite Exception Code',
  pre_requisite_exception_duration:
    'Pre Requisite Exception Duration (In Days)',
  applicable_on: 'Applicable On',
  is_terminal: 'Is Terminal',
  efs_exception_code: 'EFS Exception Code',
};

const keyMappingExceptionVehicles = {
  code: 'Exception Code',
  name: 'Exception Name',
  active: 'Active Flag',
  created_at: 'Created At',
  created_by: 'Created Bt',
};
const keyMappingBagging = {
  origin_hub_code: 'Origin Hub',
  destination_hub_code: 'Bag Destination Hub',
  consignment_destination_hub_code: 'Consignment Destination Hub',
  is_active: 'Active',
  master_last_updated_by: 'Last Updated By',
  master_last_updated_at: 'Last Updated At',
};
const keyMappingGates = {
  gate_code: 'Gate Code',
  gate_name: 'Gate Name',
  hub_code: 'Hub Code',
  active: 'Active',
};
const keyMappingEWBThreshold = {
  origin_state: 'Origin State',
  ewb_limit: 'EWB Limit',
};
const keyMappingDocks = {
  dock_code: 'Dock Code',
  dock_name: 'Dock Name',
  hub_code: 'Hub Code',
  active: 'Active',
};
const keyMappingVechileDocuments = {};
const keyMappingBulkSettlement = {
  worker_code: 'Rider Code',
  cash_balance: 'Cash Balance',
};
const keyMappingVehicles = {
  'VEHICLE CODE': 'code',
  'HUB CODE': 'hub_code',
  'VEHICLE NUMBER': 'vehicle_number',
  'FLEET TYPE': 'fleet_type',
  'VEHICLE MAKE CODE': 'vehicle_make_code',
  'VEHICLE VENDOR CODE': 'vehicle_vendor_code',
  'AVAILABLE FOR LOCAL TRIP': 'is_available_for_endpoint',
  'AVAILABLE FOR UPCOUNTRY': 'is_available_for_hubtohub',
  'AVAILABLE FOR PLANNING': 'is_available',
  'AVAILABLE FOR MID MILE': 'is_available_for_midmile',
  'ROUTE CODE': 'route_code',
  'ADM ROUTE CODE': 'adm_route_code',
  'FIXED COST': 'fixed_cost',
  'VARIABLE COST': 'variable_cost',
  'OPENING TIME (HH:MM)': 'opening_time',
  'CLOSING TIME (HH:MM)': 'closing_time',
  'VEHICLE CATEGORY': 'vehicle_category_id',
  'AUTO CREATE WORKER [TRUE/FALSE]': 'auto_create_worker',
  'WORKER DEFAULT PASSWORD': 'worker_password',
  'OPTIMIZER TAGS': 'constraint_tags',
  'VEHICLE TAGGED WITH': 'vehicle_tagged_with',
};
const keyMappingVendor = {
  code: 'Code',
  name: 'name',
};
const keyMappingCheckPriceData = {
  reference_number: 'reference_number',
  courier_amount: 'courier_amount',
};
const keyMappingCheckPriceDataWithAwb = {
  courier_partner_reference_number: 'courier_partner_reference_number',
  courier_amount: 'courier_amount',
};
const routeFailurecolumns = [
  {
    title: 'Route Code',
    dataIndex: 'route_code',
  },
  {
    title: 'Error',
    dataIndex: 'message',
  },
];
const exceptionFailurecolumns = [
  {
    title: 'Exception Code',
    dataIndex: 'code',
  },
  {
    title: 'Error',
    dataIndex: 'message',
  },
];
const baggingFailurecolumns = [
  {
    title: 'Origin Hub Code',
    dataIndex: 'origin_hub_code',
  },
  {
    title: 'Error',
    dataIndex: 'message',
  },
];
const bookingMasterFailurecolumns = [
  {
    title: 'Row No.',
    dataIndex: 'rowNum',
  },
  {
    title: 'Error',
    dataIndex: 'message',
  },
];
const gateFailurecolumns = [
  {
    title: 'Gate Code',
    dataIndex: 'gate_code',
  },
  {
    title: 'Error',
    dataIndex: 'message',
  },
];
const ewbThresholdFailureColumns = [
  {
    title: 'Origin State',
    dataIndex: 'origin_state',
  },
  {
    title: 'Error',
    dataIndex: 'message',
  },
];
const dockFailurecolumns = [
  {
    title: 'Dock Code',
    dataIndex: 'dock_code',
  },
  {
    title: 'Error',
    dataIndex: 'message',
  },
];
const bulkSettlementFailurecolumns = [
  {
    title: 'Rider Code',
    dataIndex: 'worker_code',
  },
  {
    title: 'Error',
    dataIndex: 'message',
  },
];
const vehicleFailureColumns = [
  {
    title: 'Reason',
    dataIndex: 'reason',
  },
  {
    title: 'Message',
    dataIndex: 'message',
  },
];
const checkPriceFailureColumns = [
  {
    title: 'Consignment #',
    dataIndex: 'reference_number',
  },
  {
    title: 'Reason',
    dataIndex: 'reason',
  },
  {
    title: 'Message',
    dataIndex: 'message',
  },
];
const checkPriceFailureColumnsWithAwb = [
  {
    title: 'Carrier AWB #',
    dataIndex: 'courier_partner_reference_number',
  },
  {
    title: 'Reason',
    dataIndex: 'reason',
  },
  {
    title: 'Message',
    dataIndex: 'message',
  },
];
export const bulkUploadConfig = {
  tms_setup_connection: {
    onBulkUpload: bulkUploadConnections,
    add: {
      sampleUrl: undefined,
      downloadSampleCsvFromApiCall: true,
      downloadSampleCsv: downloadSampleCsvConnectionMaster,
    },
    update: {
      sampleUrl: undefined,
      downloadSampleCsvFromApiCall: true,
      downloadSampleCsv: downloadSampleCsvConnectionMaster,
    },
    params: 'connection_list',
    warningMsg: 'No Connections to upload',
    successMsg: 'Connections uploaded successfully',
    excelUploadHeader: 'Upload a csv file to upload multiple connections.',
    modalHeading: 'Upload Connections',
    bulkUploadMapping: keyMappingConnections,
    failureArrayColumns: routeFailurecolumns,
  },
  tms_setup_route: {
    onBulkUpload: bulkUploadRoutes,
    add: {
      sampleUrl:
        'https://shipsy-public-assets.s3-us-west-2.amazonaws.com/shipsy/sample-downloads/ops/Sample+Route+Master.xlsx',
      downloadSampleCsvFromApiCall: true,
      downloadSampleCsv: downloadSampleCsvRouteMaster,
      bulkUploadMappingByAction: keyMappingRoutesAdd,
    },
    update: {
      sampleUrl:
        'https://shipsy-public-assets.s3-us-west-2.amazonaws.com/shipsy/sample-downloads/ops/Sample+Update+Route+Master.xlsx',
      downloadSampleCsvFromApiCall: true,
      downloadSampleCsv: downloadSampleCsvRouteMaster,
      bulkUploadMappingByAction: keyMappingRoutesUpdate,
    },
    enable_disable_route: {
      onBulkUpload: bulkUpdateRoutes,
      sampleUrl: undefined,
      downloadSampleCsvFromApiCall: true,
      downloadSampleCsv: downloadSampleCsvRouteMasterEnableDisable,
      bulkUploadMappingByAction: keyMappingRoutesUpdateEnableDisable,
    },
    params: 'route_list',
    warningMsg: 'No Routes to upload',
    successMsg: 'Routes uploaded successfully',
    excelUploadHeader: 'Upload a csv file to upload multiple routes.',
    modalHeading: 'Upload Routes',
    failureArrayColumns: routeFailurecolumns,
  },
  tms_setup_exception_vehicle: {
    add: {
      onBulkUpload: bulkUploadVehicleExceptions,
      sampleUrl:
        'https://shipsy-public-assets.s3-us-west-2.amazonaws.com/shipsy/sample-downloads/ops/Sample+Exception+For+Create.xlsx',
    },
    update: {
      onBulkUpload: bulkUpdateVehicleExceptions,
      sampleUrl:
        'https://shipsy-public-assets.s3-us-west-2.amazonaws.com/shipsy/sample-downloads/ops/Sample+exception+for+update.xlsx',
    },
    params: 'exception_list',
    warningMsg: 'No Exceptions to upload',
    successMsg: 'Exceptions uploaded successfully',
    excelUploadHeader: 'Upload a csv file to upload multiple exceptions.',
    modalHeading: 'Upload Exceptions',
    bulkUploadMapping: keyMappingExceptionVehicles,
    failureArrayColumns: exceptionFailurecolumns,
  },
  tms_setup_exception_bag: {
    add: {
      onBulkUpload: bulkUploadBagExceptions,
      sampleUrl: undefined,
      downloadSampleCsvFromApiCall: true,
      downloadSampleCsv: downloadSampleCsvBagExceptionMaster,
      bulkUploadMappingByAction: keyMappingAddBagsException,
    },
    update: {
      onBulkUpload: bulkUpdateBagExceptions,
      sampleUrl: undefined,
      downloadSampleCsvFromApiCall: true,
      downloadSampleCsv: downloadSampleCsvBagExceptionMaster,
      bulkUploadMappingByAction: keyMappingUpdateBagsException,
    },
    params: 'exception_list',
    warningMsg: 'No Exceptions to upload',
    successMsg: 'Exceptions uploaded successfully',
    excelUploadHeader: 'Upload a csv file to upload multiple exceptions.',
    modalHeading: 'Upload Exceptions',
    bulkUploadMapping: keyMappingAddBagsException,
    failureArrayColumns: exceptionFailurecolumns,
  },
  tms_setup_bagging: {
    add: {
      onBulkUpload: bulkUploadBaggingHubs,
      sampleUrl: undefined,
      downloadSampleCsvFromApiCall: true,
      downloadSampleCsv: downloadSampleCsvBaggingMaster,
    },
    delete: {
      onBulkUpload: bulkDeleteBaggingHubs,
      sampleUrl: undefined,
      downloadSampleCsvFromApiCall: true,
      downloadSampleCsv: downloadSampleCsvBaggingMaster,
    },
    update: {
      onBulkUpload: bulkUpdateBaggingHubs,
      sampleUrl: undefined,
      downloadSampleCsvFromApiCall: true,
      downloadSampleCsv: downloadSampleCsvBaggingMaster,
    },
    params: 'bagging_master_list',
    warningMsg: 'No hubs to upload',
    successMsg: 'Hubs uploaded successfully',
    excelUploadHeader: 'Upload a csv file to upload multiple hubs.',
    modalHeading: 'Upload Bag Master',
    bulkUploadMapping: keyMappingBagging,
    failureArrayColumns: baggingFailurecolumns,
  },
  setup_booking_masters: {
    add: {
      onBulkUpload: bulkUpdateBookingMasters,
      sampleUrl: undefined,
      downloadSampleCsvFromApiCall: true,
      downloadSampleCsv: downloadSampleCsvBookingMaster,
    },
    update: {
      onBulkUpload: bulkUpdateBookingMasters,
      sampleUrl: undefined,
      downloadSampleCsvFromApiCall: true,
      downloadSampleCsv: downloadSampleCsvBookingMaster,
    },
    warningMsg: 'Nothing to upload',
    successMsg: 'Updated successfully',
    excelUploadHeader: 'Upload a csv file to update.',
    modalHeading: 'Update',
    bulkUploadMapping: undefined,
    failureArrayColumns: bookingMasterFailurecolumns,
  },
  setup_routing: {
    add: {
      onBulkUpload: bulkUpdateBookingMasters,
      sampleUrl: undefined,
      downloadSampleCsvFromApiCall: true,
      downloadSampleCsv: downloadSampleCsvBookingMaster,
    },
    update: {
      onBulkUpload: bulkUpdateBookingMasters,
      sampleUrl: undefined,
      downloadSampleCsvFromApiCall: true,
      downloadSampleCsv: downloadSampleCsvBookingMaster,
    },
    warningMsg: 'Nothing to upload',
    successMsg: 'Updated successfully',
    excelUploadHeader: 'Upload a csv file to update.',
    modalHeading: 'Update',
    bulkUploadMapping: undefined,
    failureArrayColumns: bookingMasterFailurecolumns,
  },
  tms_setup_gate: {
    onBulkUpload: bulkUploadGates,
    add: {
      sampleUrl: undefined,
      downloadSampleCsvFromApiCall: true,
      downloadSampleCsv: downloadSampleCsvGateMaster,
      title: 'Add Gate',
    },
    update: {
      sampleUrl: undefined,
      downloadSampleCsvFromApiCall: true,
      downloadSampleCsv: downloadSampleCsvGateMaster,
    },
    params: 'gate_list',
    warningMsg: 'No Gates to upload',
    successMsg: 'Gates uploaded successfully',
    excelUploadHeader: 'Upload a csv file to upload multiple gates.',
    modalHeading: 'Upload Gates',
    bulkUploadMapping: keyMappingGates,
    failureArrayColumns: gateFailurecolumns,
  },
  tms_setup_ewb_threshold_master: {
    onBulkUpload: bulkUploadEWBThresholdMaster,
    add: {
      sampleUrl: undefined,
      downloadSampleCsvFromApiCall: true,
      downloadSampleCsv: downloadSampleCSVEWBThresholdMaster,
      title: 'Add EWB Threshold',
    },
    update: {
      sampleUrl: undefined,
      downloadSampleCsvFromApiCall: true,
      downloadSampleCsv: downloadSampleCSVEWBThresholdMaster,
    },
    params: 'rows',
    warningMsg: 'No rows to upload',
    successMsg: 'EWB Thresholds uploaded successfully',
    excelUploadHeader: 'Upload a csv file to upload multiple thresholds.',
    modalHeading: 'Upload EWB Threshold',
    bulkUploadMapping: keyMappingEWBThreshold,
    failureArrayColumns: ewbThresholdFailureColumns,
  },
  tms_setup_dock: {
    onBulkUpload: bulkUploadDocks,
    add: {
      sampleUrl: undefined,
      downloadSampleCsvFromApiCall: true,
      downloadSampleCsv: downloadSampleCsvDockMaster,
      title: 'Add Dock',
    },
    update: {
      sampleUrl: undefined,
      downloadSampleCsvFromApiCall: true,
      downloadSampleCsv: downloadSampleCsvDockMaster,
    },
    params: 'dock_list',
    warningMsg: 'No Docks to upload',
    successMsg: 'Docks uploaded successfully',
    excelUploadHeader: 'Upload a csv file to upload multiple docks.',
    modalHeading: 'Upload Docks',
    bulkUploadMapping: keyMappingDocks,
    failureArrayColumns: dockFailurecolumns,
  },
  tms_setup_vehicle_documents: {
    bulkUploadMapping: keyMappingVechileDocuments,
  },
  rider_recon: {
    onBulkUpload: bulkUploadBulkSettlement,
    add: {
      sampleUrl: undefined,
      downloadSampleCsvFromApiCall: true,
      downloadSampleCsv: downloadSampleBulkSettlement,
      title: 'Upload',
    },
    warningMsg: 'No Data to upload',
    successMsg: 'Bulk Settlement is Successful',
    excelUploadHeader: 'Upload a csv file for bulk settlement.',
    modalHeading: 'Upload Data for Bulk Settlement',
    bulkUploadMapping: keyMappingBulkSettlement,
    failureArrayColumns: bulkSettlementFailurecolumns,
  },
  tms_setup_vehicle: {
    onBulkUpload: bulkUploadVehicle,
    add: {
      sampleUrlWithParams: async () =>
        (API_BASE_URL +
        '/Dashboard/vehiclemanagement/vehicle/sample_csv_create_vehicle.csv?' +
        '&header-access-token=' +
        await getAuthenticationHeaders()?.['access-token'] +
        '&header-user-id=' +
        await getAuthenticationHeaders()?.['user-id'] +
        '&header-organisation-id=' +
        await getAuthenticationHeaders()?.['organisation-id']),
      title: 'Upload',
    },
    params: 'vehicle_array',
    warningMsg: 'No Data to upload',
    successMsg: 'Vehicles uploaded successfully',
    excelUploadHeader: 'Upload a csv file to upload multiple vehicles.',
    modalHeading: 'Upload Vehicles',
    headerMapping: keyMappingVehicles,
    failureArrayColumns: vehicleFailureColumns,
  },
  tms_setup_vendor: {
    onBulkUpload: bulkUploadVendor,
    add: {
      sampleUrlWithParams: async () =>
        (API_BASE_URL +
        '/Dashboard/vehiclevendormanagement/vehiclevendor/sample_csv_create_vehiclevendor.csv?' +
        '&header-access-token=' +
        await getAuthenticationHeaders()?.['access-token'] +
        '&header-user-id=' +
        await getAuthenticationHeaders()?.['user-id'] +
        '&header-organisation-id=' +
        await getAuthenticationHeaders()?.['organisation-id']),
      title: 'Upload',
    },
    params: 'vehicle_vendor_array',
    warningMsg: 'No Data to upload',
    successMsg: 'Vendors uploaded successfully',
    excelUploadHeader: 'Upload a csv file to upload multiple vendors.',
    modalHeading: 'Upload Vendors',
    bulkUploadMapping: keyMappingVendor,
    failureArrayColumns: vehicleFailureColumns,
  },
  dashboard_consignment_price_calculator: {
    onBulkUpload: checkConsignmentPriceCalculatorData,
    add: {
      title: 'Upload',
      downloadSampleCsvFromApiCall: true,
      downloadSampleCsv: downloadSampleCsvForCheckConsignmentPrice,
    },
    params: 'consignment_data',
    warningMsg: 'No Data to upload',
    successMsg: 'Data uploaded successfully',
    excelUploadHeader: 'Upload a csv file to check the price.',
    modalHeading: 'Upload Consignments to Check Price Data',
    bulkUploadMapping: keyMappingCheckPriceData,
    failureArrayColumns: checkPriceFailureColumns,
  },
  dashboard_consignment_price_calculator_with_awb: {
    onBulkUpload: checkConsignmentPriceCalculatorData,
    add: {
      title: 'Upload',
      downloadSampleCsvFromApiCall: true,
      downloadSampleCsv: downloadSampleCsvForCheckConsignmentPrice,
      sampleUrlParams: {
        calculate_price_by_awb: true,
      },
    },
    params: 'consignment_data',
    warningMsg: 'No Data to upload',
    successMsg: 'Data uploaded successfully',
    excelUploadHeader: 'Upload a csv file to check the price.',
    modalHeading: 'Upload AWBs to Check Price Data',
    bulkUploadMapping: keyMappingCheckPriceDataWithAwb,
    failureArrayColumns: checkPriceFailureColumnsWithAwb,
  },
  setup_courier_account_integrations: {
    add: {
      onBulkUpload: bulkUpdateBookingMasters,
      sampleUrl: undefined,
      downloadSampleCsvFromApiCall: true,
      downloadSampleCsv: downloadSampleCsvBookingMaster,
    },
    update: {
      onBulkUpload: bulkUpdateBookingMasters,
      sampleUrl: undefined,
      downloadSampleCsvFromApiCall: true,
      downloadSampleCsv: downloadSampleCsvBookingMaster,
    },
    warningMsg: 'Nothing to upload',
    successMsg: 'Updated successfully',
    excelUploadHeader: 'Upload a csv file to update.',
    modalHeading: 'Update',
    bulkUploadMapping: undefined,
    failureArrayColumns: bookingMasterFailurecolumns,
  },
};

//workflow builder controlled setup viewTypes are configured for proper data updation.
export const workFlowBuilderGenericViewTypes = [
  'customerManage',
  'supplementaryServices',
  'itemUnitMaster',
];
