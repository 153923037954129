import { CssType, ThemeType } from '../../../../../theming/jssTypes';
import { createUseStyles } from 'react-jss';
const useStyles = createUseStyles(
  (theme: ThemeType): CssType => ({
    addCNModal: {
      display: 'flex',
      '& label': {
        display: 'inline-block',
        border: '1px solid #d9d9d9',
        padding: '4px 11px',
        height: '32px',
        borderRadius: '4px 0 0 4px',
      },
      '& input': {
        width: '230px',
        borderRadius: '0 4px 4px 0',
      },
      '& button': {
        marginLeft: 10,
        float: 'right',
      },
    },
    tableContainer: {
      flex: '1 1 0',
      overflow: 'auto',
      height: 300,
      marginTop: 15,
      boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
      '& .ant-table-tbody > tr > td, .ant-table-thead > tr > th': {
        padding: 9,
      },
      '& button': {
        float: 'right',
      },
    },
    selectStyle: {
      width: '200px',
    },
    label: {
      marginLeft: '5px',
    },
    selectQcChecked: {
      '& .ant-select-selection--single': {
        border: '1px solid #4AD859',
        background: '#DCFFE0',
      },
      minWidth: '120px',
    },
    selectQcNotChecked: {
      '& .ant-select-selection--single': {
        border: '1px solid #F19E67',
        background: '#FFEADC',
      },
      minWidth: '120px',
    },
    qcChecked: {
      background: '#DCFFE0',
      '&:hover': {
        background: '#DCFFE0 !important',
      },
      '&.ant-select-dropdown-menu-item-selected': {
        background: '#DCFFE0 !important',
      },
      '&.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled)':
        {
          background: '#DCFFE0 !important',
        },
    },
    qcNotChecked: {
      background: '#FFEADC',
      '&:hover': {
        background: '#FFEADC !important',
      },
      '&.ant-select-dropdown-menu-item-selected': {
        background: '#FFEADC !important',
      },
      '&.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled)':
        {
          background: '#FFEADC !important',
        },
    },
    qcStatus: {
      minWidth: '80px',
    },
    disableRto: {
      color: '#a1a1a1',
    },
    rtoReason: {
      minWidth: '130px',
    },
  }),
);
export default useStyles;
