import * as React from 'react';
import { Modal, Row, Col, Typography, Button } from 'antd';
import withStyles, { WithStylesProps } from 'react-jss';
import { LinkOutlined, DownloadOutlined, CloseOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import lodash from 'lodash';
const { Text } = Typography;

const styles = {
  grayCircle: {
    width: '32px',
    height: '32px',
    fontSize: '14px',
    backgroundColor: '#F5F5F5',
    borderRadius: '64px',
    marginRight: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

interface IDocumentTypeProps extends WithStylesProps<typeof styles> {
  documentType: string;
  documentUrls: string[];
  index: number;
  availableDocumentTypesList: { id: string; name: string }[];
}

const DocumentType = (props: IDocumentTypeProps) => {
  const {
    documentType,
    documentUrls = [],
    index,
    classes,
    availableDocumentTypesList,
  } = props;
  const rows = [];
  for (let i = 0; i < documentUrls.length; i += 3) {
    const rowData = documentUrls.slice(i, i + 3);
    rows.push(rowData);
  }

  const documentTypePrettyNameMapping = {};
  for (const data of availableDocumentTypesList) {
    documentTypePrettyNameMapping[data.id] = data.name;
  }
  return (
    <div style={{ marginTop: '10px' }}>
      <div style={{ display: 'flex', marginTop: '10px', alignItems: 'center' }}>
        <div className={classes.grayCircle}>{index + 1}</div>
        <div style={{ color: '#111111', fontSize: '12px' }}>
          {documentTypePrettyNameMapping[documentType] || documentType}
        </div>
      </div>
      <>
        {rows.map((row, index) => (
          <Row
            key={index}
            gutter={[16, 16]}
            style={{ marginTop: '10px', marginLeft: '42px' }}
          >
            {row.map((item, i) => (
              <Col key={i} span={8}>
                <div key={i}>
                  <a href={item} target="_blank">
                    <>
                      <LinkOutlined color="#666666" />
                      <Text
                        style={{
                          marginLeft: '10px',
                          marginRight: '10px',
                          maxWidth: '80%',
                          textOverflow: 'ellipsis',
                          color: '#006EC3',
                        }}
                        ellipsis={{ tooltip: 'Click to Open' }}
                      >
                        {item}
                      </Text>
                    </>
                  </a>
                </div>
              </Col>
            ))}
          </Row>
        ))}
      </>
    </div>
  );
};

const DocumentDetailsModal = (props: any) => {
  const handleModalClose = () => {
    props.onModalClose(false);
  };

  const { isVisible, documentDetails = [], classes } = props;

  const documentDetailsByType = lodash.groupBy(documentDetails, 'type');
  const handleAllDocumentDetailsDownload = async () => {
    const documentUrls = documentDetails.map((item) => item.url);
    const promises = documentUrls.map(async (url) => {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const link = document.createElement('a');
        const downloadUrl = window.URL.createObjectURL(blob);
        link.href = downloadUrl;
        link.download = '';
        link.click();
        window.URL.revokeObjectURL(downloadUrl);
      }
      catch (err) {}
    });
    await Promise.all(promises);
  };

  return (
    <Modal
      title={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ fontSize: '16px' }}>Documents</div>
          <div style={{ flexGrow: 1 }}></div>
          <div onClick={handleAllDocumentDetailsDownload} style={{ marginRight: '20px', display: 'flex', alignItems: 'center', cursor: 'pointer', color: '#006EC3' }}>
            <DownloadOutlined
              style={{ color: '#006EC3', marginRight: '5px' }}
            />
            <div>Download All</div>
          </div>
          <div>
          <CloseOutlined style={{ fontSize: '16px' }} onClick={handleModalClose}/>
          </div>
        </div>
      }
      visible={isVisible}
      onCancel={handleModalClose}
      width={'50%'}
      footer={null}
      centered
      closable={false}
    >
      {Object.keys(documentDetailsByType).map((key: string, index: number) => {
        const documentUrls = documentDetailsByType[key]?.map((item) => item.url) || [];
        return (
          <DocumentType
            documentType={key}
            documentUrls={documentUrls}
            index={index}
            classes={classes}
            availableDocumentTypesList={props.availableDocumentTypesList}
          />
        );
      })}
    </Modal>
  );
};

const mapStateToProps = ({ masterData }, ownProps) => {
  return {
    availableDocumentTypesList: masterData.available_document_types || [],
  };
};

const DocumentDetailsModalStyled = withStyles(styles, { injectTheme: true })(
  DocumentDetailsModal,
);
const DocumentDetailsModalConnected = connect(mapStateToProps)(
  DocumentDetailsModalStyled,
);
export default withRouter(
  DocumentDetailsModalConnected,
) as React.ComponentType<any>;
