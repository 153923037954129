import Icon from '@ant-design/icons';
import * as React from 'react';

const AnalyticsSvg = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Menu" transform="translate(-24.000000, -151.000000)">
                <g id="Analytics" transform="translate(24.000000, 151.000000)">
                    <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                    <g transform="translate(1.500000, 1.500000)" fill="#666666">
                        <path d="M0.8815875,15.788 C0.8815875,15.3436 1.2418675,14.9833 1.6862975,14.9833 L4.3304175,14.9833 C4.7748375,14.9833 5.1351175,15.3436 5.1351175,15.788 L5.1351175,20.1949 C5.1351175,20.6393 4.7748375,20.9996 4.3304175,20.9996 L1.6862975,20.9996 C1.2418675,20.9996 0.8815875,20.6393 0.8815875,20.1949 L0.8815875,15.788 Z M2.4910075,16.5928 L2.4910075,19.3902 L3.5257075,19.3902 L3.5257075,16.5928 L2.4910075,16.5928 Z" id="Shape"></path>
                        <path d="M6.1696775,11.3812 C6.1696775,10.9368 6.5299575,10.5765 6.9743775,10.5765 L9.6184975,10.5765 C10.0628975,10.5765 10.4231975,10.9368 10.4231975,11.3812 L10.4231975,20.1949 C10.4231975,20.6393 10.0628975,20.9996 9.6184975,20.9996 L6.9743775,20.9996 C6.5299575,20.9996 6.1696775,20.6393 6.1696775,20.1949 L6.1696775,11.3812 Z M7.7790875,12.1859 L7.7790875,19.3902 L8.8137975,19.3902 L8.8137975,12.1859 L7.7790875,12.1859 Z" id="Shape"></path>
                        <path d="M11.4579975,13.1439 C11.4579975,12.6995 11.8182975,12.3392 12.2626975,12.3392 L14.9067975,12.3392 C15.3512975,12.3392 15.7114975,12.6995 15.7114975,13.1439 L15.7114975,20.1949 C15.7114975,20.6393 15.3512975,20.9996 14.9067975,20.9996 L12.2626975,20.9996 C11.8182975,20.9996 11.4579975,20.6393 11.4579975,20.1949 L11.4579975,13.1439 Z M13.0673975,13.9486 L13.0673975,19.3902 L14.1020975,19.3902 L14.1020975,13.9486 L13.0673975,13.9486 Z" id="Shape"></path>
                        <path d="M16.7465975,8.737 C16.7465975,8.29262 17.1068975,7.93234 17.5512975,7.93234 L20.1953975,7.93234 C20.6397975,7.93234 21.0000975,8.29262 21.0000975,8.737 L21.0000975,20.1949 C21.0000975,20.6393 20.6397975,20.9996 20.1953975,20.9996 L17.5512975,20.9996 C17.1068975,20.9996 16.7465975,20.6393 16.7465975,20.1949 L16.7465975,8.737 Z M18.3559975,9.5418 L18.3559975,19.3902 L19.3906975,19.3902 L19.3906975,9.5418 L18.3559975,9.5418 Z" id="Shape"></path>
                        <path d="M15.8649975,0.80471 C15.8649975,0.36028 16.2252975,0 16.6696975,0 L20.1951975,0 C20.6395975,0 20.9998975,0.36028 20.9998975,0.80471 L20.9998975,4.3302 C20.9998975,4.77463 20.6395975,5.13491 20.1951975,5.13491 C19.7507975,5.13491 19.3904975,4.77463 19.3904975,4.3302 L19.3904975,1.60941 L16.6696975,1.60941 C16.2252975,1.60941 15.8649975,1.24913 15.8649975,0.80471 Z" id="Path"></path>
                        <path d="M20.7638975,0.23569 C21.0781975,0.54995 21.0781975,1.05946 20.7638975,1.37372 L13.7128975,8.4247 C13.4229975,8.7146 12.9613975,8.7402 12.6411975,8.48406 L8.7967975,5.4085 L1.3737175,12.8316 C1.0594575,13.1458 0.5499475,13.1458 0.2356875,12.8316 C-0.0785625,12.5173 -0.0785625,12.0078 0.2356875,11.6935 L8.1680475,3.76118 C8.4579875,3.47124 8.9195975,3.44568 9.2397975,3.70183 L13.0841975,6.77738 L19.6258975,0.23569 C19.9401975,-0.07856 20.4496975,-0.07856 20.7638975,0.23569 Z" id="Path"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    );
};

export const AnalyticsIcon = props => (
  <Icon component={AnalyticsSvg} {...props} />
);
