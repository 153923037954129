import Icon from '@ant-design/icons';
import * as React from 'react';

const CustomerAllocationRulesSvg = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Setup_Tooltip" transform="translate(-16.000000, -506.000000)">
                <g id="Group-6" transform="translate(0.000000, 58.000000)">
                    <g id="Customer_Allocation_Rules" transform="translate(16.000000, 448.000000)">
                        <rect id="Rectangle-6" opacity="0.1" x="0" y="0" width="24" height="24"></rect>
                        <g transform="translate(4.500000, 1.800000)" fill="#666666" id="Path">
                            <path d="M0,2.49999681 C0,2.0609248 0.355949185,1.70497562 0.795021188,1.70497562 L4.07089162,1.70497562 C4.50997321,1.70497562 4.86591281,2.0609248 4.86591281,2.49999681 C4.86591281,2.93907839 4.50997321,3.29501799 4.07089162,3.29501799 L1.59004238,3.29501799 L1.59004238,18.73568 L13.4099576,18.73568 L13.4099576,3.29501799 L10.9386869,3.29501799 C10.4996054,3.29501799 10.1436658,2.93907839 10.1436658,2.49999681 C10.1436658,2.0609248 10.4996054,1.70497562 10.9386869,1.70497562 L14.2049788,1.70497562 C14.6440604,1.70497562 15,2.0609248 15,2.49999681 L15,19.5307012 C15,19.9697828 14.6440604,20.3257224 14.2049788,20.3257224 L0.795021188,20.3257224 C0.355949185,20.3257224 0,19.9697828 0,19.5307012 L0,2.49999681 Z"></path>
                            <path d="M4.14752017,-8.50747786e-16 C4.58660175,-8.50747786e-16 4.94254136,0.355939606 4.94254136,0.795021188 L4.94254136,2.05838648 C4.94481148,2.12403799 4.95956248,2.18868375 4.98603764,2.24884674 C5.01339403,2.31104039 5.05274279,2.36723785 5.10183295,2.41422073 C5.15091354,2.4612036 5.20877767,2.49805236 5.27211117,2.5226597 C5.33543508,2.54727662 5.40300231,2.5591732 5.47092394,2.55766937 L5.48851977,2.5574778 L9.51148023,2.5574778 L9.5291048,2.55766937 C9.59701685,2.5591732 9.66464154,2.54727662 9.72795588,2.5226597 C9.79127022,2.49805236 9.84912477,2.46119403 9.89826283,2.41422073 C9.9473051,2.36723785 9.98667302,2.31104039 10.0139719,2.24884674 C10.0405046,2.18868375 10.0552556,2.12403799 10.0574586,2.05838648 L10.0574586,0.795021188 C10.0574586,0.355939606 10.4133982,-8.50747786e-16 10.8524798,-8.50747786e-16 C11.2915614,-8.50747786e-16 11.647501,0.355939606 11.647501,0.795021188 L11.647501,2.0689708 L11.6473094,2.08618349 C11.6413707,2.36293707 11.58093,2.63579217 11.4694354,2.88917405 C11.3579409,3.14254634 11.1975957,3.37148371 10.9976909,3.56289224 C10.7976904,3.75430078 10.5619618,3.90443526 10.3039152,4.00471329 C10.0489337,4.10380359 9.77718972,4.1522903 9.50372159,4.14752017 L5.49629756,4.14752017 C5.22285817,4.1522903 4.95106629,4.10380359 4.69610395,4.00471329 C4.43809563,3.90443526 4.20235748,3.75430078 4.0023857,3.56289224 C3.80240435,3.37148371 3.64209743,3.14255592 3.53062205,2.88917405 C3.41914666,2.63579217 3.35868674,2.36293707 3.35269056,2.08618349 L3.35249898,2.0689708 L3.35249898,0.795021188 C3.35249898,0.355939606 3.70844817,-8.50747786e-16 4.14752017,-8.50747786e-16 Z"></path>
                            <path d="M3.35249898,0.795021188 C3.35249898,0.355939606 3.70844817,-8.50747786e-16 4.14752017,-8.50747786e-16 L10.8524798,-8.50747786e-16 C11.2915614,-8.50747786e-16 11.647501,0.355939606 11.647501,0.795021188 C11.647501,1.23409319 11.2915614,1.59004238 10.8524798,1.59004238 L4.14752017,1.59004238 C3.70844817,1.59004238 3.35249898,1.23409319 3.35249898,0.795021188 Z"></path>
                            <path d="M6.34576334,9.5633386 C6.67689445,9.85165352 6.71156887,10.3538579 6.42325395,10.684989 L4.9385471,12.3899742 C4.79055821,12.559898 4.57739675,12.6590362 4.35208008,12.6627718 C4.12675384,12.6665074 3.9104506,12.5743616 3.75694446,12.4094187 L2.73203763,11.3078833 C2.43293725,10.9864265 2.45106948,10.4833601 2.77252624,10.1843172 C3.093983,9.88517851 3.59703026,9.903282 3.89612106,10.2247388 L4.31941716,10.6797208 L5.22410338,9.64073344 C5.51244703,9.30960232 6.01463222,9.27492791 6.34576334,9.5633386 Z"></path>
                            <path d="M7.5477779,11.0153538 C7.5477779,10.5762722 7.90371751,10.2203326 8.34279909,10.2203326 L11.6952981,10.2203326 C12.1343797,10.2203326 12.4903193,10.5762722 12.4903193,11.0153538 C12.4903193,11.4544354 12.1343797,11.810375 11.6952981,11.810375 L8.34279909,11.810375 C7.90371751,11.810375 7.5477779,11.4544354 7.5477779,11.0153538 Z"></path>
                            <path d="M6.34576334,5.30082772 C6.67689445,5.58917136 6.71156887,6.09135656 6.42325395,6.42248767 L4.9385471,8.12747287 C4.79055821,8.29743499 4.57739675,8.39657317 4.35208008,8.40030881 C4.12675384,8.40404445 3.9104506,8.31189863 3.75694446,8.14693652 L2.73203763,7.04540114 C2.43293725,6.72394438 2.45106948,6.22088754 2.77252624,5.92179674 C3.093983,5.62270593 3.59703026,5.64082858 3.89612106,5.96228534 L4.31941716,6.41722904 L5.22410338,5.37829918 C5.51244703,5.04716806 6.01463222,5.01248407 6.34576334,5.30082772 Z"></path>
                            <path d="M7.5477779,6.75289081 C7.5477779,6.31380923 7.90371751,5.95786962 8.34279909,5.95786962 L11.6952981,5.95786962 C12.1343797,5.95786962 12.4903193,6.31380923 12.4903193,6.75289081 C12.4903193,7.19196281 12.1343797,7.547912 11.6952981,7.547912 L8.34279909,7.547912 C7.90371751,7.547912 7.5477779,7.19196281 7.5477779,6.75289081 Z"></path>
                            <path d="M5.54769616,13.8487902 C5.86080041,14.1566454 5.86511077,14.6599991 5.557246,14.9731225 L3.8809965,16.6781077 C3.57317005,16.9912311 3.06981627,16.9954457 2.75671202,16.6876863 C2.44360777,16.3798311 2.43932615,15.8764773 2.74715261,15.5633539 L4.4234021,13.8583687 C4.73122856,13.5452453 5.23459191,13.5410308 5.54769616,13.8487902 Z"></path>
                            <path d="M2.75671202,13.8487902 C3.06981627,13.5410308 3.57317005,13.5452453 3.8809965,13.8583687 L5.557246,15.5633539 C5.86511077,15.8764773 5.86080041,16.3798311 5.54769616,16.6876863 C5.23459191,16.9954457 4.73122856,16.9912311 4.4234021,16.6781077 L2.74715261,14.9731225 C2.43932615,14.6599991 2.44360777,14.1566454 2.75671202,13.8487902 Z"></path>
                            <path d="M7.5477779,15.2682382 C7.5477779,14.8291567 7.90371751,14.473217 8.34279909,14.473217 L11.6952981,14.473217 C12.1343797,14.473217 12.4903193,14.8291567 12.4903193,15.2682382 C12.4903193,15.7073198 12.1343797,16.0632594 11.6952981,16.0632594 L8.34279909,16.0632594 C7.90371751,16.0632594 7.5477779,15.7073198 7.5477779,15.2682382 Z"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    );
};

export const CustomerAllocationRulesIcon = props => (
  <Icon component={CustomerAllocationRulesSvg} {...props} />
);
