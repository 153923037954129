import Icon from '@ant-design/icons';
import * as React from 'react';

const PasswordPolicySvg = (props) => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Password_Policy"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <g
          id="Group-9"
          transform="translate(12.000000, 12.000000) scale(-1, 1) rotate(90.000000) translate(-12.000000, -12.000000) translate(7.000000, 2.000000)"
          stroke="#424242"
          stroke-width="1.5"
        >
          <path
            d="M8.53608736,1.45351947 C10.4885263,3.3916831 10.487693,6.5350983 8.53525405,8.47408914 C6.58281508,10.4114256 3.41791401,10.4105983 1.46547504,8.47243471 C-0.488630543,6.53344387 -0.487797236,3.39251032 1.46380843,1.45434669 C3.4170807,-0.483816946 6.58281508,-0.485471375 8.53608736,1.45351947 Z"
            id="Stroke-1"
          ></path>
          <line
            x1="5.58984612"
            y1="19.5863927"
            x2="9.12556893"
            y2="19.5863927"
            id="Stroke-3"
          ></line>
          <line
            x1="5.58984612"
            y1="16.0767697"
            x2="9.12556893"
            y2="16.0767697"
            id="Stroke-5"
          ></line>
          <line
            x1="5.58984612"
            y1="19.5863927"
            x2="5.34402047"
            y2="9.91211887"
            id="Stroke-7"
          ></line>
        </g>
      </g>
    </svg>
  );
};

export const PasswordPolicy = (props) => (
  <Icon component={PasswordPolicySvg} {...props} />
);
