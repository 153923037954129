import * as React from 'react';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '@theming/jssTypes';
import { Modal } from 'antd';

const styles = (theme: ThemeType): CssType => ({});

const TextModal: React.FC<any> = (props) => {
  const { isVisible, text, title, footerList } = props;

  const handleModalClose = () => {
    props.onModalClose(false);
  };

  return (
    <Modal
      confirmLoading={false}
      title={title}
      visible={isVisible}
      onCancel={handleModalClose}
      width={'35%'}
      footer={footerList || null}
      centered
    >
      {text}
    </Modal>
  );
};

export default withStyles(styles, { injectTheme: true })(TextModal);
