import * as React from 'react';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '../../../../theming/jssTypes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, message } from 'antd';
import { markCnEligibleForPlanning } from '../../../../api/genericConsignmentView';
import { setActiveBucket } from '../../../../actions/genericConsignmentActions';
import FailureArrayHandler from '../../../common/FailureArrayHandler';
const styles = (theme: ThemeType): CssType => ({});

const MoveConsignmentsModal = (props: any) => {
  const {
    isVisible,
    onModalClose,
    consignmentList,
    setActiveBucket,
    viewType,
  } = props;
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [isErrorModalVisible, setIsErrorModalVisible] = React.useState(false);
  const [failureArray, setFailureArray] = React.useState([]);
  const failureColumns = [
    {
      title: 'Consignment Number',
      dataIndex: 'reference_number',
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
    },
  ];

  const handleModalClose = () => {
    setIsErrorModalVisible(false);
    onModalClose(false);
  };

  const getSuccessMessage = () => {
    if (consignmentList.length === 1) {
      return 'Success! 1 consignment has been moved.';
    }
    return `Success! All ${consignmentList.length} consignments have been moved.`;
  };

  const getConfirmationMessage = () => {
    if (consignmentList.length === 1) {
      return `This 1 consignment will permanently be moved to the Unplanned Section. Are you sure
      to proceed? This can't be undone!`;
    }
    return `These ${consignmentList.length} consignments will permanently be moved to the Unplanned
    Section. Are you sure to proceed? This can't be undone!`;
  };

  const getBucket = () => {
    switch (viewType) {
      case 'logistics_express':
        return 'logistics_express_unplanned';
      case 'retailPlanning':
        return 'retail_dc_unplanned';
      default:
        return undefined;
    }
  };

  const handleSubmit = async () => {
    setConfirmLoading(true);
    const body = {
      referenceNumberList: consignmentList,
    };
    const response = await markCnEligibleForPlanning(body);
    setConfirmLoading(false);

    if (response.isSuccess) {
      message.success(getSuccessMessage());
      onModalClose(true);
      const activeBucket = getBucket();
      activeBucket ? setActiveBucket(activeBucket) : null;
    } else if (response.errFailures?.length) {
      setFailureArray(response.errFailures);
      setIsErrorModalVisible(true);
    } else {
      message.error(response.errorMessage);
      handleModalClose();
    }
  };

  return (
    <Modal
      width={400}
      confirmLoading={confirmLoading}
      title="Confirm Action"
      visible={isVisible}
      okText="Submit"
      onOk={handleSubmit}
      maskClosable={false}
      onCancel={handleModalClose}
    >
      <p>{getConfirmationMessage()}</p>
      {isErrorModalVisible && (
        <FailureArrayHandler
          failureColumns={failureColumns}
          failureArray={failureArray}
          isVisible={isErrorModalVisible}
          onModalClose={handleModalClose}
        />
      )}
    </Modal>
  );
};

const MoveConsignmentsModalStyled = withStyles(styles, { injectTheme: true })(
  MoveConsignmentsModal,
);

const mapDispatchToProps = (dispatch, ownProps) => {
  const { viewType } = ownProps;
  return bindActionCreators(
    {
      setActiveBucket: setActiveBucket(viewType),
    },
    dispatch,
  );
};

export default connect(null, mapDispatchToProps)(MoveConsignmentsModalStyled);
