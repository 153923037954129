import Icon from '@ant-design/icons';
import * as React from 'react';

const ValidationBuilderSvg = (props) => {
    return (
        <svg width="1em" height="1em" viewBox="0 0 24 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Validation-Builder" transform="translate(-15.000000, -108.000000)" fill="#666666">
                    <g id="Group-6" transform="translate(0.000000, 52.000000)">
                        <g id="Group-2" transform="translate(0.000000, 40.000000)">
                            <g id="Group-9" transform="translate(15.333451, 15.999893)">
                                <path d="M7.05151603,14.000214 C6.85396097,14.000214 6.65562814,13.9247697 6.50396185,13.774659 L0.230422055,7.55245242 C-0.0752438434,7.24989762 -0.076799395,6.75756553 0.2257554,6.4526774 C0.52753242,6.14778928 1.02064229,6.14545595 1.32553042,6.44801075 L7.59907021,12.6702173 C7.90395833,12.9727721 7.90629166,13.4651042 7.60373687,13.7699924 C7.45207058,13.9232142 7.25140442,14.000214 7.05151603,14.000214" id="Fill-1"></path>
                                <g id="Group-5" transform="translate(10.580088, 0.000000)">
                                    <path d="M0.777272514,9.05558198 C0.596050747,9.05558198 0.414051205,8.99258214 0.267051574,8.86424912 C-0.0572809447,8.5819165 -0.0907253052,8.09113996 0.191607319,7.76680744 L6.72259091,0.267492943 C7.00336798,-0.0568395758 7.49492231,-0.0910617121 7.81925482,0.191270912 C8.14358734,0.473603536 8.1770317,0.965157857 7.89469908,1.2887126 L1.36371549,8.78880487 C1.21049365,8.96535998 0.994271969,9.05558198 0.777272514,9.05558198" id="Fill-3"></path>
                                </g>
                                <g id="Group-8" transform="translate(4.666655, 0.000238)">
                                    <path d="M7.05151603,13.999976 C6.84696099,13.999976 6.64940593,13.9190873 6.50396185,13.774421 L0.230422055,7.55221443 C-0.0752438434,7.24965964 -0.076799395,6.75732754 0.2257554,6.45243942 C0.52753242,6.14755129 1.02064229,6.14521797 1.32553042,6.44777276 L7.01029391,12.0858697 L17.3026014,0.26678829 C17.5826007,-0.0567664525 18.0757106,-0.0909885888 18.3992653,0.191344035 C18.7235978,0.473676659 18.7570422,0.964453204 18.4754873,1.28878572 L7.637959,13.7331989 C7.49718158,13.8949763 7.29495986,13.9914205 7.08029374,13.9991982 C7.07018265,13.999976 7.06084934,13.999976 7.05151603,13.999976" id="Fill-6"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export const ValidationBuilderNavIcon = (props) => (
    <Icon component={ValidationBuilderSvg} {...props} />
);
