// tslint:disable: max-line-length

import Icon from '@ant-design/icons';
import * as React from 'react';
const PlannedUnloading = (props: any) => (
    <svg width="14" height="44" viewBox="0 0 14 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="6.5" y="0.5" width="1" height="31" rx="0.5" fill="white" />
        <rect x="6.5" y="0.5" width="1" height="31" rx="0.5" stroke="#2D2D2D" />
        <rect x="0.25" y="31.25" width="13.5" height="12.5" rx="3.75" fill="white" stroke="#2D2D2D" strokeWidth="0.5" />
        <path fillRule="evenodd" clipRule="evenodd" d="M7 35.5962C7 34.1623 5.88072 33 4.5 33C3.11929 33 2 34.1623 2 35.5962C2 37.03 3.11929 38.1923 4.5 38.1923C5.88072 38.1923 7 37.03 7 35.5962ZM3.5 35.25C3.3159 35.25 3.16667 35.405 3.16667 35.5962C3.16667 35.7873 3.3159 35.9423 3.5 35.9423H5.5C5.6841 35.9423 5.83333 35.7873 5.83333 35.5962C5.83333 35.405 5.6841 35.25 5.5 35.25H3.5Z" fill="#2D2D2D" />
        <path fillRule="evenodd" clipRule="evenodd" d="M7.48168 35.25C7.49378 35.3637 7.5 35.4792 7.5 35.5962C7.5 37.3167 6.15685 38.7115 4.5 38.7115C4.02105 38.7115 3.56832 38.595 3.16667 38.3877V40.0962C3.16667 40.6697 3.61438 41.1346 4.16667 41.1346H4.38165C4.52507 41.6351 4.9715 42 5.5 42C6.0285 42 6.47493 41.6351 6.61835 41.1346H8.21498C8.3584 41.6351 8.80483 42 9.33333 42C9.86183 42 10.3083 41.6351 10.4517 41.1346H11C11.5523 41.1346 12 40.6697 12 40.0962V38.3716C12 38.3105 11.9844 38.2505 11.9549 38.1977L11.2726 36.9783C11.0936 36.6584 10.7643 36.4615 10.408 36.4615H9.49905L8.76055 35.5986C8.57078 35.3769 8.29875 35.25 8.01308 35.25H7.48168ZM9.66667 37.1538V39.4038H11.3333V38.8846H10.3333C10.1492 38.8846 10 38.7296 10 38.5385C10 38.3473 10.1492 38.1923 10.3333 38.1923H11.1809L10.6962 37.3261C10.6365 37.2195 10.5267 37.1538 10.408 37.1538H9.66667ZM5.5 40.2692C5.22385 40.2692 5 40.5017 5 40.7885C5 41.0752 5.22385 41.3077 5.5 41.3077C5.77615 41.3077 6 41.0752 6 40.7885C6 40.5017 5.77615 40.2692 5.5 40.2692ZM9.33333 40.2692C9.05718 40.2692 8.83333 40.5017 8.83333 40.7885C8.83333 41.0752 9.05718 41.3077 9.33333 41.3077C9.60948 41.3077 9.83333 41.0752 9.83333 40.7885C9.83333 40.5017 9.60948 40.2692 9.33333 40.2692Z" fill="#2D2D2D" />
    </svg>

);
export const PlannedUnloadingIcon = (props) => (
    <Icon component={PlannedUnloading} {...props} />
);
