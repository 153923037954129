import { LocalStorageKeys, LocalStorageService } from 'src/services/localStorage';
import {
  SET_CURRENT_HUB,
  SET_CURRENT_HUBS_LIST,
} from './constants';

export const setCurrHub = (data, skipSettingLocalStorage = false) => (dispatch) => {
  if (!skipSettingLocalStorage) {
    LocalStorageService.set(LocalStorageKeys.__CURRENT_HUB__, JSON.stringify(data.currHub));
  }
  dispatch({
    data,
    type: SET_CURRENT_HUB,
  });
};

export const setCurrHubsList = (data, skipSettingLocalStorage = false) => (dispatch) => {
  if (!skipSettingLocalStorage) {
    LocalStorageService.set(LocalStorageKeys.__CURRENT_HUBS__, JSON.stringify(data));
  }
  dispatch({
    data,
    type: SET_CURRENT_HUBS_LIST,
  });
};
