// tslint:disable: max-line-length

import Icon from '@ant-design/icons';
import * as React from 'react';
const Inspection = (props: any) => (
    <svg width="14" height="44" viewBox="0 0 14 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="6" y="12" width="2" height="32" rx="1" fill="#F69944" />
        <rect width="14" height="14" rx="1.5" fill="#F69944" />
        <path d="M6.41688 8.16667C6.90948 8.16667 7.32429 7.99815 7.66133 7.66112C7.99837 7.32408 8.16688 6.90926 8.16688 6.41667C8.16688 5.92408 7.99837 5.50926 7.66133 5.17223C7.32429 4.83519 6.90948 4.66667 6.41688 4.66667C5.92429 4.66667 5.50948 4.83519 5.17244 5.17223C4.8354 5.50926 4.66688 5.92408 4.66688 6.41667C4.66688 6.90926 4.8354 7.32408 5.17244 7.66112C5.50948 7.99815 5.92429 8.16667 6.41688 8.16667ZM10.1697 11.2778L8.128 9.23611C7.86874 9.40463 7.59327 9.52778 7.30161 9.60556C7.00994 9.68334 6.71503 9.72223 6.41688 9.72223C5.49651 9.72223 4.71549 9.40139 4.07383 8.75973C3.43216 8.11806 3.11133 7.33704 3.11133 6.41667C3.11133 5.4963 3.43216 4.71528 4.07383 4.07361C4.71549 3.43195 5.49651 3.11111 6.41688 3.11111C7.33725 3.11111 8.11827 3.43195 8.75994 4.07361C9.40161 4.71528 9.72244 5.4963 9.72244 6.41667C9.72244 6.71482 9.68031 7.00973 9.59605 7.30139C9.51179 7.59306 9.3854 7.86852 9.21688 8.12778L11.278 10.1889L10.1697 11.2778Z" fill="white" />
    </svg>
);
export const InspectionIcon = (props) => (
    <Icon component={Inspection} {...props} />
);
