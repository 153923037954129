import React from 'react';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import NavBar from '../../navbar/Navbar';
import { Spin } from 'antd';
import { useDashboardGroup } from './hooks/useDashboardHook';
import DashboardPage from './Dashboard';
import { LoadingOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';

const useStyles = createUseStyles({
  page: {
    backgroundColor: '#f2ede4',
    height: '100%',
  },
  header: {
    textAlign: 'left',
    padding: '1.25rem 1.5rem 0.625rem 1.5rem',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  welcomeMessage: {
    color: '#111',
    fontFamily: 'Open Sans',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    marginBottom: 0,
  },
  welomeMessageSubHeading: {
    color: '#666',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    marginBottom: 0,
  },
});

const Header = () => {
  const { user_data } = useSelector((state: any) => state.masterData);
  const name = user_data?.name;
  const classes = useStyles();
  const welcomeMeassage = name ? `Welcome again, ${name}!` : 'Welcome again!';
  return (
    <header className={classes.header}>
      <p className={classes.welcomeMessage}>{welcomeMeassage}</p>
      <p className={classes.welomeMessageSubHeading}>
        Here’s how today is looking for you...
      </p>
    </header>
  );
};

const AnalyticsLandingPage = (props) => {
  const classes = useStyles();
  const { dashboardApiStatus } = useDashboardGroup();
  return (
    <div className={classes.page}>
      <NavBar pageTitle={props.t('crm_landing_page_home')} />
      <Header />
      {dashboardApiStatus === 'loading' && (
        <div className={classes.loader}>
          <Spin indicator={<LoadingOutlined spin />} size={'large'} />
        </div>
      )}
      {dashboardApiStatus === 'success' && <DashboardPage />}
    </div>
  );
};

const LandingPage = withTranslation('translation')(AnalyticsLandingPage);
export default LandingPage;
