import { LocalStorageKeys, LocalStorageService } from 'src/services/localStorage';
import {
  SET_CURRENT_HUB,
  SET_CURRENT_HUB_ANALYTICS,
  SET_SEARCHED_HUBS_CRM,
  SET_CURRENT_HUBS_LIST,
} from '../actions/constants';

const defaultState: any = {
  currHub: undefined,
  allSearchedHubs: [],
  devMode: isDevMode(),
  searchedHubsCRM: [],
};

function isDevMode() {
  const devDataVal = LocalStorageService.getRaw(LocalStorageKeys.__DEV_MODE__) || false;
  if (devDataVal && devDataVal === 'shipsy') {
    return true;
  }
  return false;
}

export default function (state: any = defaultState, action) {
  switch (action.type) {

    case SET_CURRENT_HUB: {
      return {
        ...state,
        currHub: action.data.currHub ?
          action.data.currHub : undefined,
        hubType : action.data.currHub && action.data.currHub.hub_type,
      };
    }
    case SET_CURRENT_HUB_ANALYTICS: {
      return {
        ...state,
        allSearchedHubs: action.data,
      };
    }
    case SET_SEARCHED_HUBS_CRM: {
      return {
        ...state,
        searchedHubsCRM: action.data,
      };
    }
    case SET_CURRENT_HUBS_LIST: {
      return {
        ...state,
        selectedHubsList: action.data,
      };
    }

    default: return state;
  }
}
