import {
    fetchAllItemUnits,
    addItemUnitsMultiple,
    updateItemUnitsMultiple,
    generateSampleItemUnits,
    addSingleItemUnit,
    updateSingleItemUnit,
    downloadItemUnits,
} from '@api/itemUnitMaster';

import {
    ITEM_UNIT_PARAMS,
} from '@components/pages/OpsDashboard/Setup/AppFlowConfig/ItemUnitMaster.constants';

const viewControl = {
    pageTitle: 'Item Unit Master',
    buckets: ['itemUnitMaster'],
    globalActions: [],
    downloadOptions : [
        {
            key: 'download',
            title: 'Download Data',
            action: 'download',
        },
    ],
    addOptions: [
        {
            key: 'add_single',
            title: 'Single',
            action: 'add_single',
            type: 'single',
        },
        {
            key: 'add',
            title: 'Bulk',
            action: 'add_bulk',
            type: 'bulk',
        },
    ],
    fetchData: fetchAllItemUnits,
    downloadData: downloadItemUnits,
    add_bulk: {
        downloadSampleCsvFromApiCall: true,
        downloadSampleCsv: generateSampleItemUnits,
        title: 'Add Item Units',
        onBulkUpload: addItemUnitsMultiple,
    },
    update: {
        downloadSampleCsvFromApiCall: true,
        downloadSampleCsv: generateSampleItemUnits,
        title: 'Update Item Units',
        onBulkUpload: updateItemUnitsMultiple,
    },
    params: ITEM_UNIT_PARAMS,
    modalHeading: 'Item Master Unit',
    warningMsg: 'No Item Units to upload',
    successMsg: 'Item Units Uploaded Successfully',
    updateMsg: 'Item Units Updated Successfully',
    failureArrayColumns: [
        {
            key: 'code',
            dataIndex: 'code',
            title: 'Item Code',
        },
        {
            key: 'error',
            dataIndex: 'error',
            title: 'Error',
        },
    ],
    bulkUploadMapping: null,
    sectionWiseForm: true,
    firstRowAsHeader: true,
    onSingleAdd: addSingleItemUnit,
    onSingleUpdate: updateSingleItemUnit,
    id: 'code',
};

const buckets = {
    itemUnitMaster: {
        name: 'Item Unit Master',
        filters: [],
        actions: [],
    },
};

const bucketColor = {};

export default { viewControl, buckets, bucketColor };
