import { GET, POST, DELETE } from '@src/api/apiHandler';
import { ROUTING_PLAYGROUND_URL, API_BASE_URL } from '@src/globals';
import apiProvider from '@src/api/provider';
import {
  getAuthenticationHeaders,
  getErrorMessage,
  getFileExtension,
  dataHeaders,
} from '@src/api/utils';

import {
  APPLY_STRATEGY,
  FETCH_PARAMS,
  UPDATE_PARAMS,
  ALL_HUB,
  FETCH_STRATEGY_DESCRIPTION,
  FETCH_STRATEGY_MASTERDATA,
  ALL_PARTITION,
  PARTITON_UPDATE,
  TEST_CONFIGURATION,
  SET_CONFIG_AS_MASTER,
  FETCH_HUB_WITH_FILTER,
  FETCH_OSM_POLYGONS,
  EXCEL_UPLOAD_ROUTING_PLAYGROUND,
  APPLY_STRATEGY_FOR_MULTIPLE,
  GET_MASTER_CONFIG,
  CLEAN_PARTITION,
  MERGE_PARTITION,
  SIGNED_URL,
  NEW_SIMULATION_REQUEST,
  GET_ALL_SIMULATION_REQUEST,
  DELETE_SIMULATION_REQUESTS,
  GET_AVAILABLE_AREA,
  GET_SIMULATION_DETAILS,
  GET_SIMULATION_PARTITIONS,
  FETCH_HUBS,
  FETCH_PROFILES,
  SAVE_PROFILE,
  GET_POLYLINE,
  SAVE_GEOFENCE,
  FETCH_TRIP_REQUESTS,
  FETCH_TRIP_DETAILS,
  DELETE_REQUESTS,
  FETCH_TO_INPUT_PARAMS,
  SMOOTHEN_POLYGON,
  SIMPLIFY_POLYGON,
  UPDATE_REQUESTS_PARAMS,
  UPDATE_REQUEST_DATA,
  RECALCULATE_METRICS,
  DELETE_SUB_SCENARIO,
  UPSERT_TAG,
  FETCH_TAGS,
  FETCH_PARTITIONS,
  UPDATE_PARTITIONS,
  GET_META_DATA,
  GEOCODE_MULTIPLE,
  HUB_OPTIMISATION,
  HUB_OPTIMISATION_UPDATE,
  REVERSE_GEOCODE,
  GET_GEOCODING_CONFIG,
  UPDATE_GEOCODING_CONFIG,
  GET_AUDIT_LOG,
} from './routes';

export const fetchStrategyDescription = async () => {
  return POST(`${API_BASE_URL}${FETCH_STRATEGY_DESCRIPTION}`, {});
};

export const fetchStrategyMasterData = async (body) => {
  return POST(`${API_BASE_URL}${FETCH_STRATEGY_MASTERDATA}`, body);
};

export const fetchAllHub = async () => {
  return POST(`${API_BASE_URL}${ALL_HUB}`, {});
};

export const fetchSavedParamsForHub = async (body) => {
  return POST(`${API_BASE_URL}${FETCH_PARAMS}`, body);
};

export const updateParamsForHub = async (body) => {
  return POST(`${API_BASE_URL}${UPDATE_PARAMS}`, body);
};

export const setConfigAsMaster = async (body) => {
  return POST(`${API_BASE_URL}${SET_CONFIG_AS_MASTER}`, body);
};

export const fetchHubWithFilter = async (body) => {
  return POST(`${API_BASE_URL}${FETCH_HUB_WITH_FILTER}`, body);
};

export const fetchOsmPolygons = async (body) => {
  return POST(`${API_BASE_URL}${FETCH_OSM_POLYGONS}`, body);
};

export const excelUpload = async (body) => {
  return POST(`${API_BASE_URL}${EXCEL_UPLOAD_ROUTING_PLAYGROUND}`, body);
};

export const runStrategy = async (body) => {
  return POST(`${API_BASE_URL}${TEST_CONFIGURATION}`, body);
};

export const applyStrategyForMultiple = async (body) => {
  return POST(`${API_BASE_URL}${APPLY_STRATEGY_FOR_MULTIPLE}`, body);
};

export const getMasterConfig = async () => {
  return POST(`${API_BASE_URL}${GET_MASTER_CONFIG}`, {});
};

export const cleanPartitions = async (body) => {
  return POST(`${API_BASE_URL}${CLEAN_PARTITION}`, body);
};

export const mergePartitions = async (body) => {
  return POST(`${API_BASE_URL}${MERGE_PARTITION}`, body);
};

export const addFileToSignedUrl = async (putObject: any, file: File) => {
  try {
    const response = await apiProvider.put(putObject.fileSignedUrl, file, {
      headers: {
        'Content-Type': 'application/*',
      },
    });
    return {
      data: response.data,
      url: putObject.fileUrl,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getS3SignedUrlAndUpload = async (file: File) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${SIGNED_URL}`,
      { fileType: getFileExtension(file).toUpperCase() },
      { headers: await getAuthenticationHeaders() },
    );
    const fileUpload = await addFileToSignedUrl(response.data, file);
    return fileUpload;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const postNewSimulationRequest = async (body) => {
  return POST(`${API_BASE_URL}${NEW_SIMULATION_REQUEST}`, body);
};

export const getAllSimulationRequest = async (body) => {
  return POST(`${API_BASE_URL}${GET_ALL_SIMULATION_REQUEST}`, body);
};

export const deleteSimulationsRequests = async (body) => {
  return POST(`${API_BASE_URL}${DELETE_SIMULATION_REQUESTS}`, body);
};

export const getAvailableArea = async (body) => {
  return POST(`${API_BASE_URL}${GET_AVAILABLE_AREA}`, body);
};

export const fetchSimulationDetails = async (body) => {
  return POST(`${API_BASE_URL}${GET_SIMULATION_DETAILS}`, body);
};

export const fetchSimulationPartitions = async (body) => {
  return POST(`${API_BASE_URL}${GET_SIMULATION_PARTITIONS}`, body);
};

export const fetchHubs = async (body) => {
  return POST(`${API_BASE_URL}${FETCH_HUBS}`, body);
};

export const fetchProfiles = async (body) => {
  return POST(`${API_BASE_URL}${FETCH_PROFILES}`, body);
};

export const saveProfile = async (body) => {
  return POST(`${API_BASE_URL}${SAVE_PROFILE}`, body);
};

export const getPolyLines = async (body) => {
  return POST(`${API_BASE_URL}${GET_POLYLINE}`, body);
};

export const updateGeoFence = async (body) => {
  return POST(`${API_BASE_URL}${SAVE_GEOFENCE}`, body);
};

export const fetchTripRequests = async (body) => {
  return POST(`${API_BASE_URL}${FETCH_TRIP_REQUESTS}`, body);
};

export const fetchTripDetails = async (body) => {
  return POST(`${API_BASE_URL}${FETCH_TRIP_DETAILS}`, body);
};

export const deleteRequest = async (body) => {
  return POST(`${API_BASE_URL}${DELETE_REQUESTS}`, body);
};

export const fetchParams = async (body) => {
  return POST(`${API_BASE_URL}${FETCH_TO_INPUT_PARAMS}`, body);
};
export const smoothenPolygons = async (body) => {
  return POST(`${API_BASE_URL}${SMOOTHEN_POLYGON}`, body);
};

export const simplifyPolygons = async (body) => {
  return POST(`${API_BASE_URL}${SIMPLIFY_POLYGON}`, body);
};

export const updateRequestParams = async (body) => {
  return POST(`${API_BASE_URL}${UPDATE_REQUESTS_PARAMS}`, body);
};

export const updateRequestData = async (body) => {
  return POST(`${API_BASE_URL}${UPDATE_REQUEST_DATA}`, body);
};

export const recalculateMetrics = async (body) => {
  return POST(`${API_BASE_URL}${RECALCULATE_METRICS}`, body);
};

export const deleteSubScenario = async (body) => {
  return POST(`${API_BASE_URL}${DELETE_SUB_SCENARIO}`, body);
};

export const upsertTag = async (body) => {
  return POST(`${API_BASE_URL}${UPSERT_TAG}`, body);
};

export const fetchAllTags = async (body) => {
  return POST(`${API_BASE_URL}${FETCH_TAGS}`, body);
};

export const fetchPartitions = async (body) => {
  return POST(`${API_BASE_URL}${FETCH_PARTITIONS}`, body);
};

export const updatePartitions = async (body) => {
  return POST(`${API_BASE_URL}${UPDATE_PARTITIONS}`, body);
};

export const geocodeMultiple = async (body) => {
  return POST(`${API_BASE_URL}${GEOCODE_MULTIPLE}`, body);
};

export const getMetaData = async (body) => {
  return POST(`${API_BASE_URL}${GET_META_DATA}`, body);
};

// hub optimisation

export const postHubOptimisationRequests = async (body) => {
  return POST(`${API_BASE_URL}${HUB_OPTIMISATION}`, body);
};

export const getHubOptimisationRequests = async (body) => {
  return GET(`${API_BASE_URL}${HUB_OPTIMISATION}`, body);
};

export const deleteHubOptimisationRequests = async (body) => {
  return DELETE(`${API_BASE_URL}${HUB_OPTIMISATION}`, body);
};

export const getHubOptSimulationDetails = async (id) => {
  return GET(`${API_BASE_URL}${HUB_OPTIMISATION}/${id}`);
};

export const updateHubOptRequestData = async (body) => {
  return POST(`${API_BASE_URL}${HUB_OPTIMISATION_UPDATE}`, body);
};

export const getHubOptSimulationResult = (params) => {
  const { count, id } = params;
  return GET(`${API_BASE_URL}${HUB_OPTIMISATION}/${id}/${count}`);
};

export const reverseGeocode = async (body) => {
  return POST(`${API_BASE_URL}${REVERSE_GEOCODE}`, body);
};

export const getGeoCodingConfig = async () => {
  return GET(`${API_BASE_URL}${GET_GEOCODING_CONFIG}`);
};

export const updateGeocodingConfig = async (body) => {
  return POST(`${API_BASE_URL}${UPDATE_GEOCODING_CONFIG}`, body);
};

export const getGeoCodingConfigAuditLog = async () => {
  return GET(`${API_BASE_URL}${GET_AUDIT_LOG}`);
};
