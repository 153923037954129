import { getDomesticCreateSingleShipmentRoute, getDownloadsRoute } from '../../routing/utils';
import { getAllConsignmentsData, getSummaryMetricsData,
  getSubBucketData } from '../../api/genericConsignmentView';

const viewControl = {
  pageTitle : 'Carrier Tracking',
  polling:true,
  applyDateFilterSort: true,
  buckets : [
    'ltl_track',
    'ndr',
    'ltl_delivered',
    'rto',
  ],
  navBar : {
    left : ['single_hub_search', 'date_filter'],
    right : ['domestic_search'],
  },
  globalActions:[
    {
      key:'add',
      name:'Add Shipment',
      children:[
        {
          key:'bulk',
          name:'Bulk Upload',
          type:'modal',
          modal:'multipleShipmentUpload',
        },
        {
          key:'single',
          name:'Single Shipment',
          type:'route',
          route: getDomesticCreateSingleShipmentRoute,
        },
      ],
      isAllowed: masterData => masterData.show_ltl_options && masterData.third_party_update,
    },
  ],
  dateFilterOptions : [
    { key:'created_at', val: 'Created At' },
    { key:'last_main_event_time', val: 'Last Updated' },
    { key:'booking_time', val: 'Date of Order' },
    { key:'order_pickup_time', val: 'Pickup Date' },

  ],
  searchFilterOptions : [
    { key:'awbNumber', val: 'AWB Number' },
    { key:'orderNumber', val: 'Order Number' },
    { key:'destinationPhone', val: 'Destination Phone' },
    { key: 'referenceNumber', val: 'Reference Number' },
    { key: 'customerReferenceNumber', val: 'Customer Reference Number' },
  ],
  fetchData : getAllConsignmentsData,
  fetchSummaryMetrics : getSummaryMetricsData,
  fetchSubBucket: getSubBucketData,
};
const buckets = {

  ltl_track :{
    name: 'All Shipments',
    filters : [
      'tag',
      'attempt_count',
      'time_since_last_scan',
      'carrier',
      'isCod',
      'status',
      'hasRemarks',
      'service_type',
      'orderType',
      'deliveryType',
    ],
    actions: [
      {
        key:'download',
        name:'Download',
        children : [
          {
            key:'downloads_all',
            name:'Download All',
            type:'modal',
            modal:'downloads_all',
            requireRows : false,
          },
          {
            key:'downloads_selected',
            name:'Download Selected',
            type:'modal',
            modal:'downloads_selected',
            requireRows : true,
          },
          {
            key:'view_request',
            name:'View Requests',
            type:'route',
            route: getDownloadsRoute,
          },
        ],
      },
    ],
    status_filter : [
      'tracking_pending',
      'in_transit',
      'rto_initiated',
      'rto_in_transit',
      'rto_delivered',
      'delivered',
      'attempted',
      'outfordelivery',
    ],
    iconActions: [
      // {
      //   key:'delete_shipments',
      //   name:'Delete',
      //   icon: 'delete',
      //   type:'modal',
      //   modal:'delete_shipments',
      //   requireRows : true,
      // },
    ],
    isSubBucket: true,
  },
  ndr :{
    name: 'NDR/ Undelivered',
    filters : [
      'carrier',
      'Courier NDR Reason',
      'Internal NDR',
      'tag',
      'false_attempt',
      'undeliveredFeedbackPresent',
      'current_status',
      'attempt_count',
      'next_steps',
      'isCod',
      'hasRemarks',
      'shipperNextSteps',
      'internalNDRList',
      'consignmentEmailCountList',
      'service_type',
      'orderType',
      'deliveryType',
    ],
    actions: [
      {
        key:'download',
        name:'Download',
        children : [
          {
            key:'downloads_all',
            name:'Download All',
            type:'modal',
            modal:'downloads_all',
            requireRows : false,
          },
          {
            key:'downloads_selected',
            name:'Download Selected',
            type:'modal',
            modal:'downloads_selected',
            requireRows : true,
          },
          {
            key:'view_request',
            name:'View Requests',
            type:'route',
            route: getDownloadsRoute,
          },
        ],
      },
      {
        key: 'retry',
        name: 'Reattempt',
        requireRows : true,
        type:'modal',
        modal:'retry',
      },
      {
        key: 'reschedule',
        name: 'Reschedule',
        requireRows : true,
        type:'modal',
        modal:'reschedule',
      },
      {
        key: 'rto',
        name: 'RTO',
        requireRows : true,
        type:'modal',
        modal:'rto',
      },
    ],
    status_filter : [
    ],
    iconActions: [
      {
        key:'email_carrier',
        name:'Email Carrier',
        type:'modal',
        modal:'email_carrier',
        requireRows : true,
        icon: 'mail',
      },
      // {
      //   key:'delete_shipments',
      //   name:'Delete',
      //   icon: 'delete',
      //   type:'modal',
      //   modal:'delete_shipments',
      //   requireRows : true,
      // },
      {
        key:'next_steps',
        name:'Next Step',
        type:'modal',
        modal:'next_steps',
        requireRows : true,
        icon: 'right-circle',
      },
      {
        key:'tags',
        name:'Tag',
        icon: 'tag',
        type:'modal',
        modal:'tags',
        requireRows : true,
      },
      {
        key:'carrier_remarks',
        name:'Remark For Carrier',
        type:'modal',
        modal:'carrier_remarks',
        requireRows : true,
        icon: 'edit',
      },
    ],
    isSubBucket: false,
  },
  rto: {
    name: 'RTO',
    filters : [
      'carrier',
      'tag',
      'status',
      'isCod',
      'attempt_count',
      'hasRemarks',
      'service_type',
      'orderType',
      'deliveryType',
    ],
    actions: [
      {
        key:'download',
        name:'Download',
        children : [
          {
            key:'downloads_all',
            name:'Download All',
            type:'modal',
            modal:'downloads_all',
            requireRows : false,
          },
          {
            key:'downloads_selected',
            name:'Download Selected',
            type:'modal',
            modal:'downloads_selected',
            requireRows : true,
          },
          {
            key:'view_request',
            name:'View Requests',
            type:'route',
            route: getDownloadsRoute,
          },
        ],
      },
    ],
    status_filter : [
      'rto_initiated',
      'rto_in_transit',
      'rto_delivered',
    ],
    iconActions: [
      // {
      //   key:'delete_shipments',
      //   name:'Delete',
      //   icon: 'delete',
      //   type:'modal',
      //   modal:'delete_shipments',
      //   requireRows : true,
      // },
    ],
    isSubBucket: false,
  },
  ltl_delivered :{
    name: 'Delivered',
    filters : [
      'carrier',
      'tag',
      'false_delivery',
      'attempt_count',
      'rating',
      'hasRemarks',
      'service_type',
      'orderType',
      'deliveryType',
    ],
    actions: [
      {
        key:'download',
        name:'Download',
        children : [
          {
            key:'downloads_all',
            name:'Download All',
            type:'modal',
            modal:'downloads_all',
            requireRows : false,
          },
          {
            key:'downloads_selected',
            name:'Download Selected',
            type:'modal',
            modal:'downloads_selected',
            requireRows : true,
          },
          {
            key:'view_request',
            name:'View Requests',
            type:'route',
            route: getDownloadsRoute,
          },
        ],
      },
    ],
    status_filter : [
    ],
    iconActions: [
      // {
      //   key:'delete_shipments',
      //   name:'Delete',
      //   icon: 'delete',
      //   type:'modal',
      //   modal:'delete_shipments',
      //   requireRows : true,
      // },
    ],
    isSubBucket: true,
  },
};

const bucketColor = {
  ltl_track: '#C41990',
  ndr: '#EC544A',
  rto: '#9B2626',
  ltl_delivered: 'green',
};

export default { viewControl, buckets, bucketColor };
