import Icon from '@ant-design/icons';
import * as React from 'react';

const CODSettlementSvg = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Menu" transform="translate(-24.000000, -487.000000)">
                <g id="COD_Settlement" transform="translate(24.000000, 487.000000)">
                    <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                    <g transform="translate(1.500000, 1.500000)" fill="#666666" id="Path">
                        <path d="M4.4072285,3.44887883 C4.4072285,3.00444883 4.7675085,2.64416883 5.2119385,2.64416883 L15.7885985,2.64416883 C16.2329985,2.64416883 16.5932985,3.00444883 16.5932985,3.44887883 L16.5932985,11.3813988 C16.5932985,11.8257988 16.2329985,12.1860988 15.7885985,12.1860988 C15.3441985,12.1860988 14.9838985,11.8257988 14.9838985,11.3813988 L14.9838985,4.25358883 L6.0166485,4.25358883 L6.0166485,11.3813988 C6.0166485,11.8257988 5.6563685,12.1860988 5.2119385,12.1860988 C4.7675085,12.1860988 4.4072285,11.8257988 4.4072285,11.3813988 L4.4072285,3.44887883 Z"></path>
                        <path d="M10.5000985,11.3046988 C10.0122985,11.3046988 9.5443985,11.4984988 9.1994985,11.8434988 C8.8544985,12.1883988 8.6606985,12.6562988 8.6606985,13.1441988 C8.6606985,13.5885988 8.3004085,13.9488988 7.8559785,13.9488988 C7.4115485,13.9488988 7.0512685,13.5885988 7.0512685,13.1441988 C7.0512685,12.2294988 7.4146285,11.3521988 8.0614185,10.7053988 C8.7081985,10.0585988 9.5854985,9.69529883 10.5000985,9.69529883 C11.4147985,9.69529883 12.2920985,10.0585988 12.9388985,10.7053988 C13.5856985,11.3521988 13.9489985,12.2294988 13.9489985,13.1441988 C13.9489985,13.5885988 13.5886985,13.9488988 13.1442985,13.9488988 C12.6998985,13.9488988 12.3395985,13.5885988 12.3395985,13.1441988 C12.3395985,12.6562988 12.1457985,12.1883988 11.8007985,11.8434988 C11.4558985,11.4984988 10.9879985,11.3046988 10.5000985,11.3046988 Z"></path>
                        <path d="M7.8559785,5.28832883 C8.3004085,5.28832883 8.6606985,5.64860883 8.6606985,6.09303883 L8.6606985,6.97442883 C8.6606985,7.41885883 8.3004085,7.77913883 7.8559785,7.77913883 C7.4115485,7.77913883 7.0512685,7.41885883 7.0512685,6.97442883 L7.0512685,6.09303883 C7.0512685,5.64860883 7.4115485,5.28832883 7.8559785,5.28832883 Z"></path>
                        <path d="M0.0984085032,9.23329883 C0.311228503,8.84309883 0.800038503,8.69929883 1.1901985,8.91219883 L10.5001985,13.9902988 L19.8100985,8.91219883 C20.2002985,8.69929883 20.6890985,8.84309883 20.9018985,9.23329883 C21.1146985,9.62339883 20.9708985,10.1121988 20.5807985,10.3250988 L10.8854985,15.6133988 C10.6452985,15.7443988 10.3549985,15.7443988 10.1147985,15.6133988 L0.419528503,10.3250988 C0.0293585032,10.1121988 -0.114401497,9.62339883 0.0984085032,9.23329883 Z"></path>
                        <path d="M0.804948503,8.81389883 C1.2493785,8.81389883 1.6096585,9.17419883 1.6096585,9.61859883 L1.6096585,18.4324988 C1.6096585,18.6865988 1.7105985,18.9302988 1.8902785,19.1099988 C2.0699485,19.2895988 2.3136385,19.3905988 2.5677285,19.3905988 L18.4327985,19.3905988 C18.6868985,19.3905988 18.9304985,19.2895988 19.1101985,19.1099988 C19.2898985,18.9302988 19.3907985,18.6865988 19.3907985,18.4324988 L19.3907985,9.61859883 C19.3907985,9.17419883 19.7510985,8.81389883 20.1954985,8.81389883 C20.6399985,8.81389883 21.0001985,9.17419883 21.0001985,9.61859883 L21.0001985,18.4324988 C21.0001985,19.1134988 20.7296985,19.7664988 20.2481985,20.2479988 C19.7667985,20.7294988 19.1136985,20.9999988 18.4327985,20.9999988 L2.5677285,20.9999988 C1.8867885,20.9999988 1.2337385,20.7294988 0.752238503,20.2479988 C0.270748503,19.7664988 0.000238503156,19.1134988 0.000238503156,18.4324988 L0.000238503156,9.61859883 C0.000238503156,9.17419883 0.360528503,8.81389883 0.804948503,8.81389883 Z"></path>
                        <path d="M15.6037985,5.36078883 C15.9059985,5.03490883 16.4151985,5.01570883 16.7409985,5.31789883 L20.7425985,9.02859883 C21.0683985,9.33069883 21.0875985,9.83989883 20.7853985,10.1657988 C20.4832985,10.4916988 19.9740985,10.5108988 19.6481985,10.2086988 L15.6466985,6.49800883 C15.3207985,6.19581883 15.3015985,5.68666883 15.6037985,5.36078883 Z"></path>
                        <path d="M9.9580985,0.20992883 C10.2649985,-0.0697911702 10.7343985,-0.0700011702 11.0415985,0.20944883 L13.7738985,2.69496883 C14.1026985,2.99402883 14.1266985,3.50296883 13.8276985,3.83172883 C13.5285985,4.16047883 13.0196985,4.18454883 12.6908985,3.88548883 L10.5005985,1.89299883 L8.1599785,4.02602883 C7.8314885,4.32538883 7.3225185,4.30176883 7.0231685,3.97327883 C6.7238085,3.64479883 6.7474285,3.13582883 7.0759185,2.83646883 L9.9580985,0.20992883 Z"></path>
                        <path d="M5.4484885,5.29808883 C5.7512285,5.62345883 5.7328785,6.13264883 5.4075085,6.43538883 L1.3531085,10.2076988 C1.0277385,10.5104988 0.518558503,10.4920988 0.215818503,10.1667988 C-0.0869214968,9.84139883 -0.0685714968,9.33219883 0.256798503,9.02949883 L4.3111985,5.25710883 C4.6365685,4.95436883 5.1457485,4.97271883 5.4484885,5.29808883 Z"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    );
};

export const CODSettlementIcon = props => (
  <Icon component={CODSettlementSvg} {...props} />
);
