import * as React from 'react';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '../../../../theming/jssTypes';
import { Modal, Row, Col, message, Spin, Input } from 'antd';
import { createHandDelivery } from '../../../../api/genericConsignmentView';
const styles = (theme: ThemeType): CssType => ({
  row: {
    padding: '6px',
  },
  spinner: {
    padding: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const HandDeliveryModal = (props) => {
  const [isLoading, setLoading] = React.useState(false);
  const [confirmloading, setconfirmloading] = React.useState(false);
  const [selectedRider, setSelectedRider] = React.useState('');
  const {
    classes,
    isVisible,
    onModalClose,
    record,
    currHub,
    handleModalClose,
  } = props;
  const handleDeliverySubmit = async () => {
    const body = {
      vehicle_number: selectedRider,
      reference_number: record.reference_number,
      hub_id: currHub?.id,
    };
    if (!selectedRider) {
      message.error('Please assign a rider');
      return;
    }
    setconfirmloading(true);
    const response = await createHandDelivery(body);
    if (response.isSuccess) {
      message.success('Hand Delivery request created successfully');
      onModalClose(true);
    } else {
      message.error(response.errorMessage);
      onModalClose(false);
    }
    setconfirmloading(false);
  };
  const onRiderChange = (e) => {
    setSelectedRider(e.target.value);
  };
  return (
    <Modal
      title={'Hand Delivery'}
      confirmLoading={confirmloading}
      mask={true}
      visible={isVisible}
      onCancel={handleModalClose}
      width={500}
      onOk={handleDeliverySubmit}
      okText={'Create Hand Delivery'}
    >
      {isLoading ? (
        <div className={classes.spinner}>
          <Spin />
        </div>
      ) : (
        <React.Fragment>
          <Row className={classes.row}>
            <Col span={10}>CN No.: </Col>
            <Col span={12}>
              <Input disabled={true} value={record.reference_number} />
            </Col>
          </Row>
          <Row className={classes.row}>
            <Col span={10}>Vehicle Number: </Col>
            <Col span={12}>
              <Input value={selectedRider} onChange={onRiderChange} />
            </Col>
          </Row>
          <Row className={classes.row}>
            <Col span={10}>Customer Name: </Col>
            <Col span={12}>
              <Input disabled={true} value={record.customer_name} />
            </Col>
          </Row>
          <Row className={classes.row}>
            <Col span={10}>Customer Mobile No.: </Col>
            <Col span={12}>
              <Input disabled={true} value={record.destination_phone} />
            </Col>
          </Row>
        </React.Fragment>
      )}
    </Modal>
  );
};
const StyledHandDeliveryModal = withStyles(styles, { injectTheme: true })(
  HandDeliveryModal,
);
export default StyledHandDeliveryModal;
