import {
  getAllExceptionDataVehicles,
  getAllExceptionDataBags,
} from '../../api/TMSDashboard/Setup';
const viewControl = {
  pageTitle: 'Setup',
  buckets: ['tms_setup_exception_vehicle', 'tms_setup_exception_bag'],
  globalActions: [],
};
const buckets = {
  tms_setup_exception_vehicle: {
    name: 'Vehicle',
    filters: [],
    actions: [],
    fetchData: getAllExceptionDataVehicles,
  },
  tms_setup_exception_bag: {
    name: 'Bag',
    filters: [],
    actions: [],
    fetchData: getAllExceptionDataBags,
  },
};

const bucketColor = {};

export default { viewControl, buckets, bucketColor };
