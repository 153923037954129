import Icon from '@ant-design/icons';
import * as React from 'react';

const TransportSvg = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Setup_Tooltip" transform="translate(-16.000000, -314.000000)">
                <g id="Group-6" transform="translate(0.000000, 58.000000)">
                    <g id="Transport" transform="translate(16.000000, 256.000000)">
                        <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                        <g transform="translate(0.500000, 3.500000)" fill="#666666">
                            <path d="M1.50681957,-8.8817842e-16 L12.7551996,-8.8817842e-16 C12.9543996,0.00271 13.1509996,0.0447 13.3339996,0.12342 C13.5168996,0.20215 13.6825996,0.31614 13.8214996,0.45889 C13.9603996,0.60164 14.0697996,0.77035 14.1434996,0.95539 C14.2162996,1.13838 14.2527996,1.33378 14.251063,1.53069 L14.251063,13.69231 C14.2527996,13.88921 14.2162996,14.08461 14.1434996,14.26761 C14.0697996,14.45271 13.9603996,14.62141 13.8214996,14.76411 C13.6825996,14.90691 13.5168996,15.02091 13.3339996,15.09961 C13.1509996,15.17831 12.9543996,15.22021 12.7551996,15.22291 L12.7441996,15.22311 L7.82543957,15.22311 C7.37982957,15.22311 7.01859957,14.86181 7.01859957,14.41621 C7.01859957,13.97061 7.37982957,13.60931 7.82543957,13.60931 L12.6372996,13.60931 L12.6372996,1.61368 L1.61374957,1.61368 L1.61374957,13.60931 L3.61625957,13.60931 C4.06186957,13.60931 4.42309957,13.97061 4.42309957,14.41621 C4.42309957,14.86181 4.06186957,15.22311 3.61625957,15.22311 L1.49583957,15.22311 C1.29668957,15.22031 1.10001957,15.17831 0.917069574,15.09961 C0.734119574,15.02091 0.568469574,14.90691 0.429575574,14.76411 C0.290684574,14.62141 0.181272574,14.45271 0.107589574,14.26761 C0.0347225739,14.08461 -0.00179142609,13.88921 0,13.69231 L0,1.53072 C-0.00179142609,1.3338 0.0347225739,1.13838 0.107589574,0.95539 C0.181272574,0.77035 0.290684574,0.60164 0.429575574,0.45889 C0.568469574,0.31614 0.734119574,0.20215 0.917069574,0.12342 C1.10001957,0.0447 1.29668957,0.00279 1.49583957,-8.8817842e-16 L1.50681957,-8.8817842e-16 Z" id="Path"></path>
                            <path d="M13.4440996,7.77681 C13.8896996,7.77681 14.2509996,8.13801 14.2509996,8.58361 L14.2509996,13.60931 L15.6312996,13.60931 C16.0768996,13.60931 16.4381996,13.97061 16.4381996,14.41621 C16.4381996,14.86181 16.0768996,15.22301 15.6312996,15.22301 L14.1625996,15.22301 C13.7596996,15.21771 13.3747996,15.05531 13.0898996,14.77041 C12.8049996,14.48551 12.6425996,14.10051 12.6372996,13.69761 L12.6371996,13.68711 L12.6372996,8.58361 C12.6372996,8.13801 12.9984996,7.77681 13.4440996,7.77681 Z" id="Path"></path>
                            <path d="M22.1929996,7.77681 C22.6385996,7.77681 22.9998996,8.13801 22.9998996,8.58361 L22.9998996,13.69761 C22.9945996,14.10051 22.8320996,14.48551 22.5471996,14.77041 C22.2622996,15.05531 21.8773996,15.21771 21.4744996,15.22291 L21.4639996,15.22311 L20.0057996,15.22301 C19.5601996,15.22301 19.1989996,14.86181 19.1989996,14.41621 C19.1989996,13.97061 19.5601996,13.60931 20.0057996,13.60931 L21.3861996,13.60931 L21.3861996,8.58361 C21.3861996,8.13801 21.7473996,7.77681 22.1929996,7.77681 Z" id="Path"></path>
                            <path d="M12.6371996,2.75103 C12.6371996,2.30542 12.9983996,1.94419 13.4439996,1.94419 L20.0056996,1.94419 C20.3419996,1.94419 20.6430996,2.15281 20.7611996,2.46773 L22.9483996,8.30031 C23.1047996,8.71751 22.8933996,9.18261 22.4761996,9.33911 C22.0589996,9.49551 21.5938996,9.28411 21.4373996,8.86691 L19.4465996,3.55787 L13.4439996,3.55787 C12.9983996,3.55787 12.6371996,3.19663 12.6371996,2.75103 Z" id="Path"></path>
                            <path d="M5.13058957,11.71801 C5.66423957,11.61191 6.21737957,11.66631 6.72006957,11.87461 C7.22274957,12.08281 7.65239957,12.43541 7.95468957,12.88781 C8.25697957,13.34021 8.41831957,13.87211 8.41831957,14.41621 C8.41831957,15.14581 8.12847957,15.84551 7.61255957,16.36151 C7.09664957,16.87741 6.39690957,17.16721 5.66728957,17.16721 C5.12318957,17.16721 4.59130957,17.00591 4.13889957,16.70361 C3.68649957,16.40131 3.33388957,15.97161 3.12566957,15.46901 C2.91744957,14.96631 2.86296957,14.41311 2.96911957,13.87951 C3.07526957,13.34581 3.33727957,12.85571 3.72201957,12.47091 C4.10675957,12.08621 4.59693957,11.82421 5.13058957,11.71801 Z M6.10253957,13.36541 C5.89470957,13.27931 5.66602957,13.25681 5.44540957,13.30071 C5.22477957,13.34461 5.02211957,13.45291 4.86305957,13.61201 C4.70399957,13.77101 4.59567957,13.97371 4.55178957,14.19431 C4.50790957,14.41491 4.53042957,14.64361 4.61651957,14.85141 C4.70259957,15.05931 4.84837957,15.23691 5.03540957,15.36191 C5.22244957,15.48681 5.44233957,15.55351 5.66728957,15.55351 C5.96893957,15.55351 6.25822957,15.43371 6.47151957,15.22041 C6.68481957,15.00711 6.80463957,14.71781 6.80463957,14.41621 C6.80463957,14.19121 6.73793957,13.97131 6.61296957,13.78431 C6.48798957,13.59731 6.31035957,13.45151 6.10253957,13.36541 Z" id="Shape"></path>
                            <path d="M17.7677996,11.71801 C18.3013996,11.61191 18.8545996,11.66631 19.3572996,11.87461 C19.8599996,12.08281 20.2895996,12.43541 20.5918996,12.88781 C20.8941996,13.34021 21.0554996,13.87211 21.0554996,14.41621 C21.0554996,15.14581 20.7656996,15.84551 20.2497996,16.36151 C19.7338996,16.87741 19.0340996,17.16721 18.3044996,17.16721 C17.7603996,17.16721 17.2284996,17.00591 16.7760996,16.70361 C16.3236996,16.40131 15.9710996,15.97161 15.7628996,15.46901 C15.5546996,14.96631 15.5001996,14.41311 15.6062996,13.87951 C15.7124996,13.34581 15.9744996,12.85571 16.3591996,12.47091 C16.7439996,12.08621 17.2341996,11.82421 17.7677996,11.71801 Z M18.7396996,13.36541 C18.5318996,13.27931 18.3031996,13.25681 18.0825996,13.30071 C17.8619996,13.34461 17.6592996,13.45291 17.5002996,13.61201 C17.3411996,13.77101 17.2328996,13.97371 17.1889996,14.19431 C17.1450996,14.41491 17.1675996,14.64361 17.2536996,14.85141 C17.3397996,15.05931 17.4855996,15.23691 17.6725996,15.36191 C17.8596996,15.48681 18.0795996,15.55351 18.3044996,15.55351 C18.6060996,15.55351 18.8953996,15.43371 19.1086996,15.22041 C19.3219996,15.00711 19.4418996,14.71781 19.4418996,14.41621 C19.4418996,14.19121 19.3750996,13.97131 19.2501996,13.78431 C19.1251996,13.59731 18.9475996,13.45151 18.7396996,13.36541 Z" id="Shape"></path>
                            <path d="M17.3324996,1.94419 C17.7780996,1.94419 18.1392996,2.30542 18.1392996,2.75103 L18.1392996,7.77681 L21.2208996,7.77681 C21.6664996,7.77681 22.0276996,8.13801 22.0276996,8.58361 C22.0276996,9.02921 21.6664996,9.39041 21.2208996,9.39041 L17.3324996,9.39041 C16.8868996,9.39041 16.5255996,9.02921 16.5255996,8.58361 L16.5255996,2.75103 C16.5255996,2.30542 16.8868996,1.94419 17.3324996,1.94419 Z" id="Path"></path>
                            <path d="M7.61152957,14.41621 C7.61152957,14.03161 7.49749957,13.65581 7.28386957,13.33601 C7.07023957,13.01631 6.76659957,12.76711 6.41134957,12.62001 C6.05608957,12.47281 5.66517957,12.43431 5.28803957,12.50931 C4.91090957,12.58441 4.56448957,12.76951 4.29258957,13.04141 C4.02068957,13.31331 3.83551957,13.65971 3.76049957,14.03691 C3.68548957,14.41401 3.72398957,14.80491 3.87113957,15.16021 C4.01828957,15.51541 4.26747957,15.81911 4.58719957,16.03271 C4.90691957,16.24631 5.28280957,16.36041 5.66733957,16.36041 C6.18296957,16.36041 6.67747957,16.15551 7.04208957,15.79091 C7.40668957,15.42631 7.61152957,14.93181 7.61152957,14.41621 Z" id="Path" fillRule="nonzero"></path>
                            <path d="M20.2486996,14.41621 C20.2486996,14.03161 20.1346996,13.65581 19.9210996,13.33601 C19.7073996,13.01631 19.4037996,12.76711 19.0485996,12.62001 C18.6932996,12.47281 18.3023996,12.43431 17.9252996,12.50931 C17.5480996,12.58441 17.2016996,12.76951 16.9297996,13.04141 C16.6578996,13.31331 16.4726996,13.65971 16.3976996,14.03691 C16.3226996,14.41401 16.3611996,14.80491 16.5082996,15.16021 C16.6554996,15.51541 16.9046996,15.81911 17.2243996,16.03271 C17.5440996,16.24631 17.9199996,16.36041 18.3044996,16.36041 C18.8201996,16.36041 19.3146996,16.15551 19.6792996,15.79091 C20.0438996,15.42631 20.2486996,14.93181 20.2486996,14.41621 Z" id="Path" fillRule="nonzero"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    );
};

export const TransportIcon = props => (
  <Icon component={TransportSvg} {...props} />
);
