export const THIRDPARTY_INTEGRATION_LOG_TYPES = {
    CANCEL: 'Cancel',
    EDIT: 'Edit',
    CREATE_BOOKING: 'Create Booking',
    SERVICEABILITY: 'Service Ability',
    PRE_BOOKING: 'Pre Booking',
    POST_BOOKING: 'Post Booking',
    TRACKING: 'tracking',
    WEBHOOK: 'webhook',
    NDR_ACTION: 'Ndr Action',
};

export const API_STATUS = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
  IDLE: 'idle', // Optional: Represents the initial or default state
};

export const DB_TYPES = {
  POSTGRESQL: 'postgresql',
  REDSHIFT: 'redshift',
  ELASTICSEARCH: 'elasticSearch',
  MONGODB: 'mongoDB',
  BIGQUERY: 'BigQuery',
  NEWRELIC: 'newRelic',
};

export const LAYOUT_VIEW_TYPES = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile',
};

export const CUSTOMER_TYPES = {
  BUSINESS: 'BUSINESS',
  RETAIL: 'RETAIL',
};
