import Icon from '@ant-design/icons';
import * as React from 'react';
const PdfFileIconSvg = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    dataName="Layer 1"
    viewBox="0 0 118 150"
    {...props}
  >
    <defs>
      <style>
        {'.pdf-cls-1{fill:#d32932;}.pdf-cls-2{fill:#ed676a;}.pdf-cls-3{fill:#fff;}'}
      </style>
    </defs>
    <path
      className="pdf-cls-1"
      d="M80.09,43.76a6,6,0,0,1-6-6V0h-59A15.08,15.08,0,0,0,0,15V135a15.08,15.08,0,0,0,15.06,15h87.88A15.08,15.08,0,0,0,118,135V43.76Z"
    />
    <path
      className="pdf-cls-2"
      d="M79.57,43.73h38.18L74,0V38.19A6,6,0,0,0,79.57,43.73Z"
    />
    <path
      className="pdf-cls-3"
      d="M24.55,117.36A3.73,3.73,0,1,1,21,121.11,3.59,3.59,0,0,1,24.55,117.36Z"
    />
    <path
      className="pdf-cls-3"
      d="M39.15,125.91v5.85H33.22V104.34h4.84l.4,2h.16a8.82,8.82,0,0,1,5.69-2.46c4.84,0,7.82,4,7.82,10.17,0,6.85-4.11,10.8-8.42,10.8A6.92,6.92,0,0,1,39,122.73ZM42.33,120c2.06,0,3.71-1.77,3.71-5.88,0-3.59-1.08-5.41-3.46-5.41a4.64,4.64,0,0,0-3.43,1.82v8.18A4.59,4.59,0,0,0,42.33,120Z"
    />
    <path
      className="pdf-cls-3"
      d="M63.59,103.85a6.55,6.55,0,0,1,4.92,2.1l-.24-3.07V96.07H74.2v28.27H69.36l-.41-2h-.16a7.86,7.86,0,0,1-5.32,2.46c-5,0-8.19-3.95-8.19-10.48S59.36,103.85,63.59,103.85ZM65,120a3.76,3.76,0,0,0,3.27-1.81V110A4.73,4.73,0,0,0,65,108.69c-1.89,0-3.59,1.78-3.59,5.57C61.37,118.21,62.7,120,65,120Z"
    />
    <path
      className="pdf-cls-3"
      d="M90.73,100.67a6.18,6.18,0,0,0-2-.41c-1.45,0-2.3.81-2.3,2.87v1.21H90V109H86.38v15.37H80.45V109H77.79v-4.39l2.66-.2v-1.09c0-4.24,2-7.71,7.38-7.71a12,12,0,0,1,4,.73Z"
    />
  </svg>
);

const PdfFileIcon = (props: any) => (
  <Icon component={PdfFileIconSvg} {...props} />
);

export default PdfFileIcon;
