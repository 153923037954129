import React from 'react';
import { connect } from 'react-redux';
import { getConsignmentTypePrettyName } from 'src/utils/utils';

interface IProps extends ReturnType<typeof mapStateToProps> {
    value: any;
}

const ConsignmentTypeCell = (props: IProps) => {
    const { value, showLogisticsExpressOptions } = props;
    const val = value ?
        getConsignmentTypePrettyName(value, showLogisticsExpressOptions) : 'Delivery';
    return val;
};

const mapStateToProps = (
    { masterData },
) => {
    return {
        showLogisticsExpressOptions: masterData.show_logistics_express_options,
    };
};

export default connect(mapStateToProps)(ConsignmentTypeCell);
