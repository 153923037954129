import Icon from '@ant-design/icons';
import * as React from 'react';
const LogoutSvg = (props: any) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Logout"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="baseline-exit_to_app-24px">
        <polygon id="Path" points="0 0 20 0 20 20 0 20"></polygon>
        <path
          d="M8.40833333,12.9916667 L9.58333333,14.1666667 L13.75,10 L9.58333333,5.83333333 L8.40833333,7.00833333 L10.5583333,9.16666667 L2.5,9.16666667 L2.5,10.8333333 L10.5583333,10.8333333 L8.40833333,12.9916667 Z M15.8333333,2.5 L4.16666667,2.5 C3.24166667,2.5 2.5,3.25 2.5,4.16666667 L2.5,7.5 L4.16666667,7.5 L4.16666667,4.16666667 L15.8333333,4.16666667 L15.8333333,15.8333333 L4.16666667,15.8333333 L4.16666667,12.5 L2.5,12.5 L2.5,15.8333333 C2.5,16.75 3.24166667,17.5 4.16666667,17.5 L15.8333333,17.5 C16.75,17.5 17.5,16.75 17.5,15.8333333 L17.5,4.16666667 C17.5,3.25 16.75,2.5 15.8333333,2.5 Z"
          id="Shape"
          fill="currentColor"
          fill-rule="nonzero"
        ></path>
      </g>
    </g>
  </svg>
);

export const LogoutIcon = (props) => <Icon component={LogoutSvg} {...props} />;
