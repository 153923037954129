import { API_BASE_URL } from '@src/globals';
import { POST, GET } from '@api/apiHandler';
import {
    FETCH_ALL_OBJECT_VIEWS,
    CREATE_OBJECT_VIEW,
    UPDATE_OBJECT_VIEW,
    ACTION_OBJECT_VIEW,
    GET_DEFAULT_OBJECT_VIEW,
    MARK_VIEW_AS_DEFAULT,
    DELETE_OBJECT_VIEW,
    FETCH_OBJECT_VIEW_MASTER_DATA,
    FETCH_OBJECT_VIEW_BY_ID,
    FETCH_OBJECT_VIEW_DATA_OBJECTS,
    DOWNLOAD_OBJECT_VIEW_DATA_OBJECTS,
    SEARCH_OBJECT_VIEWS,
} from '@api/routes';

export const fetchObjectViewDataObjects = (body) => {
    return POST(`${API_BASE_URL}${FETCH_OBJECT_VIEW_DATA_OBJECTS}`, body);
};

export const downloadObjectViewDataObjects = (body) => {
    return POST(`${API_BASE_URL}${DOWNLOAD_OBJECT_VIEW_DATA_OBJECTS}`, body);
};

export const fetchAllObjectViews = (body) => {
    return POST(`${API_BASE_URL}${FETCH_ALL_OBJECT_VIEWS}`, body);
};

export const fetchObjectViewById = (viewId) => {
    return GET(`${API_BASE_URL}${FETCH_OBJECT_VIEW_BY_ID(viewId)}`);
};

export const searchObjectViews = (body) => {
    return POST(`${API_BASE_URL}${SEARCH_OBJECT_VIEWS}`, body);
};

export const createObjectView = (body) => {
    return POST(`${API_BASE_URL}${CREATE_OBJECT_VIEW}`, body);
};

export const updateObjectView = (viewId, body) => {
    return POST(`${API_BASE_URL}${UPDATE_OBJECT_VIEW(viewId)}`, body);
};

export const deleteObjectView = (viewId, body) => {
    return POST(`${API_BASE_URL}${DELETE_OBJECT_VIEW(viewId)}`, body);
};

export const actionObjectView = (viewId, body) => {
    return POST(`${API_BASE_URL}${ACTION_OBJECT_VIEW(viewId)}`, body);
};

// @Deprecated: This API is deprecated and will be removed in future
export const getWorkFlowBuilderDefaultView = (body) => {
    return GET(`${API_BASE_URL}${GET_DEFAULT_OBJECT_VIEW}`, body);
};

// @Deprecated: This API is deprecated and will be removed in future
export const markViewAsDefault = (body) => {
    return POST(`${API_BASE_URL}${MARK_VIEW_AS_DEFAULT}`,body);
};

export const fetchViewBuilderMasterData = async () => {
    return GET(`${API_BASE_URL}${FETCH_OBJECT_VIEW_MASTER_DATA}`);
};
