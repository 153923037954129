import Icon from '@ant-design/icons';
import * as React from 'react';
const SecurityIconSvg = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 20 24"
    style={{
      enableBackground: 'new 0 0 20 24',
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style type="text/css">{'\n\t.st0{fill:#333333;}\n'}</style>
    <path
      className="st0"
      d="M18,3h-1c-4.2,0-6.1-2.5-6.2-2.6C10.6,0.1,10.3,0,9.9,0C9.6,0,9.4,0.2,9.2,0.4C9.1,0.5,7.2,3,3,3H2  C0.9,3,0,3.9,0,5v3c0,0.6,0,1.5,0,2c0,0.1,0.2,10.5,9.6,13.9c0,0,0,0,0,0C9.8,24,9.9,24,10,24c0.1,0,0.2,0,0.3-0.1c0,0,0,0,0,0  C19.8,20.5,20,10.1,20,10c0-0.6,0-1.5,0-2V5C20,3.9,19.1,3,18,3z M18,8c0,0.5,0,1.4,0,2c0,0.1-0.2,8.8-8,11.9C2.2,18.8,2,10.4,2,10  c0-0.5,0-1.4,0-2V5h1c3.6,0,5.9-1.6,7-2.6c1.1,1,3.4,2.6,7,2.6h1V8z M14.6,8.2c0.4,0.3,0.5,1,0.2,1.4l-4.4,6C10.2,15.8,10,16,9.7,16  c0,0-0.1,0-0.1,0c-0.2,0-0.5-0.1-0.6-0.2l-3.6-3c-0.4-0.4-0.5-1-0.1-1.4c0.4-0.4,1-0.5,1.4-0.1l2.8,2.3l3.8-5.1  C13.5,8,14.1,7.9,14.6,8.2z"
    />
  </svg>
);

const SecurityIcon = (props: any) => (
  <Icon component={SecurityIconSvg} {...props} />
);

export default SecurityIcon;
