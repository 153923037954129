import * as React from 'react';
import {
  TripManagerMapEditReducer,
  defaultState,
  StateType,
} from './TripManagerMapEdit.reducer';
import { ActionType } from './TripManagerMapEdit.types';

const TripManagerMapEditContext = React.createContext<{
  tripManagerMapEditState: StateType;
  tripManagerMapEditDispatch: React.Dispatch<ActionType>;
}>({
  tripManagerMapEditState: defaultState,
  tripManagerMapEditDispatch: () => null,
});

export const TripManagerMapEditProvider = ({ children }) => {
  const [tripManagerMapEditState, tripManagerMapEditDispatch] =
    React.useReducer(TripManagerMapEditReducer, defaultState);

  const providerState = {
    tripManagerMapEditState,
    tripManagerMapEditDispatch,
  };

  return (
    <TripManagerMapEditContext.Provider value={providerState}>
      {children}
    </TripManagerMapEditContext.Provider>
  );
};

export function useTripManagerMapEditContext() {
  return React.useContext(TripManagerMapEditContext);
}
