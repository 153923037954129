import Icon from '@ant-design/icons';
import * as React from 'react';

const DispatchManagementSvg = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Menu" transform="translate(-24.000000, -656.000000)">
                <g id="Dispatch-Management-Copy" transform="translate(24.000000, 656.000000)">
                    <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                    <g id="Dispatch_Management" transform="translate(1.500000, 3.100000)" fill="#666666">
                        <path d="M0,0.80471 C0,0.36028 0.36028,0 0.80471,0 L20.1953,0 C20.6397,0 21,0.36028 21,0.80471 L21,16.96059 C21,17.40499 20.6397,17.76529 20.1953,17.76529 L0.80471,17.76529 C0.36028,17.76529 0,17.40499 0,16.96059 L0,0.80471 Z M1.60942,1.60942 L1.60942,16.15589 L19.3906,16.15589 L19.3906,1.60942 L1.60942,1.60942 Z" id="Shape"></path>
                        <path d="M0,4.03945 C0,3.59502 0.36028,3.23474 0.80471,3.23474 L20.1953,3.23474 C20.6397,3.23474 21,3.59502 21,4.03945 C21,4.48388 20.6397,4.84416 20.1953,4.84416 L0.80471,4.84416 C0.36028,4.84416 0,4.48388 0,4.03945 Z" id="Path"></path>
                        <path d="M3.23462,7.26539 C3.23462,6.82089 3.5949,6.46063 4.03933,6.46063 L8.887,6.46063 C9.3314,6.46063 9.6917,6.82089 9.6917,7.26539 L9.6917,10.50009 C9.6917,10.94449 9.3314,11.30479 8.887,11.30479 L4.03933,11.30479 C3.5949,11.30479 3.23462,10.94449 3.23462,10.50009 L3.23462,7.26539 Z M4.84404,8.07009 L4.84404,9.69529 L8.08226,9.69529 L8.08226,8.07009 L4.84404,8.07009 Z" id="Shape"></path>
                        <path d="M11.3083,7.26539 C11.3083,6.82089 11.6686,6.46063 12.1131,6.46063 L16.9607,6.46063 C17.4051,6.46063 17.7654,6.82089 17.7654,7.26539 L17.7654,10.50009 C17.7654,10.94449 17.4051,11.30479 16.9607,11.30479 L12.1131,11.30479 C11.6686,11.30479 11.3083,10.94449 11.3083,10.50009 L11.3083,7.26539 Z M12.9178,8.07009 L12.9178,9.69529 L16.156,9.69529 L16.156,8.07009 L12.9178,8.07009 Z" id="Shape"></path>
                        <path d="M3.23462,13.73469 C3.23462,13.29029 3.5949,12.92999 4.03933,12.92999 L8.887,12.92999 C9.3314,12.92999 9.6917,13.29029 9.6917,13.73469 C9.6917,14.17919 9.3314,14.53949 8.887,14.53949 L4.03933,14.53949 C3.5949,14.53949 3.23462,14.17919 3.23462,13.73469 Z" id="Path"></path>
                        <path d="M11.3083,13.73469 C11.3083,13.29029 11.6686,12.92999 12.1131,12.92999 L16.9607,12.92999 C17.4051,12.92999 17.7654,13.29029 17.7654,13.73469 C17.7654,14.17919 17.4051,14.53949 16.9607,14.53949 L12.1131,14.53949 C11.6686,14.53949 11.3083,14.17919 11.3083,13.73469 Z" id="Path"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    );
};

export const DispatchManagementIcon = props => (
  <Icon component={DispatchManagementSvg} {...props} />
);
