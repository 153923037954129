import { compact } from 'lodash';
import {
  compareBooleanOperation,
  compareDateOperations,
  compareNumberOperations,
  compareTimestampOperations,
  compareTimestampzOperations,
} from './typeMetadata';
import initialChartConfig from '../chartConfig/initialChartConfig';

export const calculateTableColumnWidth = (props) => {
  const { metrics, dashboardColumnWidth, columns, containerWidth } = props;
  const columnWidth = {};
  for (const key in metrics) {
    if (metrics[key]) {
      const { aliasName } = metrics[key];
      if (metrics[key]?.columnWidth) {
        columnWidth[aliasName] = metrics[key]?.columnWidth;
      }
    }
  }
  const widthByFieldValue = dashboardColumnWidth
    ? dashboardColumnWidth
    : !columnWidth || Object.keys(columnWidth).length === 0
    ? columns.reduce((acc, column) => {
        const size = Math.floor(containerWidth / columns.length) + 1;
        acc[column] = Math.max(size, 100);
        return acc;
      }, {})
    : columnWidth;
  return widthByFieldValue;
};

export const calculatePivotColumnWidth = (props) => {
  const {
    metrics,
    dashboardColumnWidth,
    columns,
    containerWidth,
    distinctColCount,
  } = props;
  const columnWidth = {};
  for (const key in metrics) {
    if (metrics[key]) {
      const { aliasName } = metrics[key];
      if (metrics[key]?.columnWidth && columns.includes(aliasName)) {
        columnWidth[aliasName] = metrics[key]?.columnWidth;
      }
    }
  }
  const columnsCount = distinctColCount || columns.length;
  const widthByFieldValue = dashboardColumnWidth
    ? dashboardColumnWidth
    : !columnWidth || Object.keys(columnWidth).length === 0
    ? columns.reduce((acc, column) => {
        const size = Math.floor(containerWidth / columnsCount) + 1;
        acc[column] = Math.max(size, 100);
        return acc;
      }, {})
    : columnWidth;
  return widthByFieldValue;
};

const disableColor = '#d3d7d4';

export const generateColors = (h1, h2, n) => {
  const colors: any = [];

  // Convert hex codes to HSL
  const hsl1: any = hexToHsl(h1);
  const hsl2: any = hexToHsl(h2);

  // Calculate the hsl difference
  const diff1 = (hsl2.h - hsl1.h + 360) % 360;
  const diff2 = (hsl1.h - hsl2.h + 360) % 360;
  const hDiff =
    diff1 < diff2 || (diff1 === diff2 && hsl1.h < hsl2.h) ? diff1 : -diff2;
  const sDiff = hsl2.s - hsl1.s;
  const lDiff = hsl2.l - hsl1.l;

  for (let i = 0; i <= n + 1; i++) {
    let h = (hsl1.h + (hDiff * i) / (n + 1)) % 360;
    h = h < 0 ? h + 360 : h;

    const s = hsl1.s + (sDiff * i) / (n + 1);
    const l = hsl1.l + (lDiff * i) / (n + 1);

    const hsl = `hsl(${h.toFixed(2)}, ${s.toFixed(2)}%, ${l.toFixed(2)}%)`;
    colors.push(hsl);
  }

  return colors;
};

// Helper function to convert hex to HSL
export const hexToHsl = (hex) => {
  // Remove the hash character if present
  const formattedHex = hex.replace('#', '');

  // Convert hex to RGB
  const r = parseInt(formattedHex.substring(0, 2), 16) / 255;
  const g = parseInt(formattedHex.substring(2, 4), 16) / 255;
  const b = parseInt(formattedHex.substring(4, 6), 16) / 255;

  // Find the maximum and minimum values for RGB
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  let h;
  let s;
  let l;

  // Calculate hue
  if (max === min) {
    h = 0;
  } else if (max === r) {
    h = ((g - b) / (max - min)) % 6;
  } else if (max === g) {
    h = (b - r) / (max - min) + 2;
  } else if (max === b) {
    h = (r - g) / (max - min) + 4;
  }

  h = Math.round(h * 60);
  if (h < 0) {
    h += 360;
  }

  // Calculate lightness
  l = (max + min) / 2;

  // Calculate saturation
  if (max === min) {
    s = 0;
  } else if (l <= 0.5) {
    s = (max - min) / (2 * l);
  } else {
    s = (max - min) / (2 - 2 * l);
  }

  h = Math.round(h);
  s = Math.round(s * 100);
  l = Math.round(l * 100);

  return { h, s, l };
};

export function getRange(data, field) {
  const values = data.map((d) => d[field]);
  const compactValue = compact(values);
  return {
    min: Math.min(...compactValue),
    max: Math.max(...compactValue),
  };
}

export function getIndex(colors, fieldValue, rawData, field) {
  const { min, max } = getRange(rawData, field);
  const step = (max - min) / (colors.length - 1);
  if (!step) {
    return 0;
  }
  return Math.floor((fieldValue - min) / step);
}

export const getFormattedColumns = ({ formatting, formData }) => {
  // map formatting conditions columns to their prettyName
  // prettyName is required by antv for iteration of columns
  const prettyNameMapping = {};
  formatting.forEach((format) => {
    const col = formData.meta.find((el) => el.metric === format.metric);
    format.rules.map((rule) => {
      rule.conditions?.map((condition) => {
        const conditionCol = formData.meta.find(
          (el) => el.metric === condition.columns[0],
        );
        if (conditionCol) {
          prettyNameMapping[condition.columns[0]] = conditionCol.name;
        }
      });
    });
    prettyNameMapping[format.metric] = col?.name;
  });
  return prettyNameMapping;
};

const applyFormattingConditions = ({
  visualizeMetaData,
  formatting,
  formData,
  condition,
  rowValues,
  fieldType,
  isNumberChart,
}) => {
  // apply rule based on its fieldType
  const prettyNameMapping = getFormattedColumns({ formatting, formData });
  const { summarizeData } = visualizeMetaData;
  const { operator, value, columns } = condition;
  const parsedValue = JSON.parse(value || '{}');
  const column = columns[0]; // support for only single column in one rule as of now
  const metric = ['timestampz', 'timestamp', 'date'].includes(fieldType)
    ? summarizeData.filter((data) => data.metric === column)
    : null;
  const indexValue = isNumberChart ? 'value' : prettyNameMapping[column];
  switch (fieldType) {
    case 'number':
      return compareNumberOperations(
        operator,
        rowValues?.[0]?.[indexValue],
        parsedValue,
      );
    case 'timestampz':
      return compareTimestampzOperations(
        operator,
        rowValues?.[0]?.[indexValue],
        parsedValue,
        metric[0]?.bucketValue || 'day',
      );
    case 'timestamp':
      return compareTimestampOperations(
        operator,
        rowValues?.[0]?.[indexValue],
        parsedValue,
        metric[0]?.bucketValue || 'day',
      );
    case 'date':
      return compareDateOperations(
        operator,
        rowValues?.[0]?.[indexValue],
        parsedValue,
        metric[0]?.bucketValue || 'day',
      );
    case 'boolean':
      return compareBooleanOperation(
        operator,
        rowValues?.[0]?.[indexValue],
        parsedValue,
      );
    default:
      return false;
  }
};

const applyConditions = ({
  visualizeMetaData,
  formatting,
  formData,
  fieldKey,
  rule,
  rowValues,
  isNumberChart,
}) => {
  if (!rule.conditions) {
    // else then case
    return rule.actions;
  }

  let falseCondition = true;
  // all conditions should be true (&&)
  // return if any condition is false
  rule.conditions?.map((condition, index) => {
    if (!falseCondition) return;
    const col = formData.meta.find((el) => el.metric === condition.columns[0]);
    if (!col) return;
    if (
      !applyFormattingConditions({
        visualizeMetaData,
        formatting,
        formData,
        condition,
        rowValues,
        fieldType: col.aggregateOp ? col.operation.type : col.type,
        isNumberChart,
      })
    ) {
      falseCondition = false;
      return;
    }
  });

  if (!falseCondition) return null;
  return rule.actions;
};

export const getMapping = ({
  visualizeMetaData,
  formData,
  formatting,
  totalData,
  fieldKey,
  fieldName,
  type,
  isNumberChart,
}) => {
  return {
    field: fieldName,
    mapping(fieldValue, ...rowValues) {
      let columnAction: any = null;
      // iterate all rules
      formatting
        .filter((metric) => metric.metric === fieldKey)
        .map((rule) => {
          if (columnAction) {
            return;
          }
          let ruleAction: any = null;
          // iterate all conditions in a rule, return the first true condition
          rule.rules.map((condition) => {
            if (ruleAction) return;
            const action = applyConditions({
              visualizeMetaData,
              formatting,
              formData,
              fieldKey,
              rule: condition,
              rowValues,
              isNumberChart,
            });

            // only single 'then' action supported as now
            if (action) {
              ruleAction = action;
              return;
            }
          });

          if (ruleAction) {
            columnAction = ruleAction;
            return;
          }
        });

      if (columnAction) {
        const action = columnAction.find(
          (action) => action.actionType === type,
        );
        if (action && action.value === 'custom') {
          // custom color
          return {
            fill: action.color,
          };
        }

        if (action && action.value === 'automatic') {
          // min-max based coloring using generated pallette
          const colors = generateColors(
            action.gradientStart,
            action.gradientEnd,
            action.noOfBuckets,
          );
          const index = getIndex(colors, fieldValue, totalData, fieldName);
          return { fill: fieldValue ? colors[index] : disableColor };
        }

        return;
      }
      return;
    },
  };
};

export const GetChartConfigData = (configObject) => {
  const { chartConfig, configType, formData, chartsFromDashboard } = configObject;
  //dashboard
  if (chartsFromDashboard) {
    if (
      chartConfig &&
      (configType in chartConfig ||
        ['table', 'pivotTable'].includes(configType))
    ) {
      return chartConfig;
    }
    return initialChartConfig(configType, formData);
  }
  return initialChartConfig(configType, formData);
};
