import { CssType, ThemeType } from '../../../../../../theming/jssTypes';

const styles = (theme: ThemeType): CssType => ({
    mainDiv: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        fontFamily: 'Open Sans',
    },
    slotsHeader: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '25px 1%',
        borderBottom: '1px solid #F3F3F3',
        backgroundColor: '#FCFCFC',
    },
    riderTitle: {
        width: '10%',
        fontSize: 12,
        fontWeight: 600,
        color: '#2D2D2D',
        fontFamily: 'Open Sans',
    },
    slotList: {
        width: '90%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    slot: {
        fontSize: 12,
        fontWeight: 400,
        color: '#2D2D2D',
        fontFamily: 'Open Sans',
    },
    riderSlotPlanning: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    riderRow: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 4,
        backgroundColor: '#FFFFFF',
        gap: 0,
    },
    riderNameAndSlot: {
        width: '10%',
        fontSize: 12,
        fontWeight: 600,
        color: '#2D2D2D',
        fontFamily: 'Open Sans',
        padding: '25px 1%'
    },
    riderName: {
        color: '#111111',
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: 12,
    },
    riderSlotRange: {
        fontFamily: 'Open Sans',
        fontSize: 10,
        fontWeight: 400,
        margin: '4px 0',
    },
    plannedSlots: {
        width: '90%',
        display: 'flex',
        flexDirection: 'row',
    },
    icon: {
        margin: '4px 0px',
    },
    operation: {
        textAlign: 'center',
        height: 32,
        borderRadius: 3,
        padding: '2px 0.1%',
        paddingTop: 8,
        margin: '16px 0px 4px 0px',
        fontFamily: 'Open Sans',
        fontSize: 10,
        fontWeight: 600,
        color: '#FFFFFF',
        minWidth: '16px',
        maxWidth: '30%',
        gap: 0,
    },
    riderPopover: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        borderRadius: 4,
        width: '100%',
        minWidth: '230px'
    },
    popoverData: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 10,
        width: '100%',
    },
    popoverTitle: {
        fontFamily: 'Open Sans',
        fontSize: 10,
        fontWeight: 600,
        color: '#666666'
    },
    popoverRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    popoverRowTitle: {
        fontFamily: 'Open Sans',
        fontSize: 10,
        fontWeight: 400,
        color: '#666666',
        width: '30%',
    },
    popoverEventRowTitle: {
        fontFamily: 'Open Sans',
        fontSize: 10,
        fontWeight: 600,
        color: '#111111',
        marginLeft: 8,
    },
    popoverEventRowValue: {
        fontFamily: 'Open Sans',
        fontSize: 10,
        fontWeight: 400,
        color: '#111111',
        margin: '0px 8px',
    },
    popoverRowDataTitle: {
        fontFamily: 'Open Sans',
        fontSize: 10,
        fontWeight: 400,
        color: '#666666',
        width: '20%',
    },
    popoverRowValueEvent: {
        fontFamily: 'Open Sans',
        fontSize: 10,
        fontWeight: 400,
        color: '#666666',
        width: '40%',
    },
    popoverRowDataValue: {
        fontFamily: 'Open Sans',
        fontSize: 10,
        fontWeight: 600,
        color: '#111111',
        width: '10%',
    },
    verticalLine: {
        height: 12,
        width: 1,
        backgroundColor: '#666666',
        margin: '0 8px',
    },
    singleTrip: {
        display: 'flex',
        flexDirection: 'row',
        gap: 0,
        padding: '25px 0px',
        width: '100%',
        height: 110,
    },
    verticalLineFull: {
        width: 1,
        height: '100%',
        backgroundColor: '1px dotted #433F3F',
        position: 'absolute',
        top: '21.5%',
        zIndex: 1
    }
});

export default styles;
