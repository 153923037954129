import ReactGA from "react-ga4";
import { GA_MID } from "../globals"
import { bucketsPrettyNameMapping } from "../components/pages/GenericConsignment/utils"
import {
  HUB_SEARCH,
  MULTI_FILTER,
  TABLE_ACTION,
  INPUT_FILTER,
  CONSIGNMENT_TAB,
  DATE_SEARCH,
  CUSTOMISE_COLUMNS,
  ROW_ACTIONS,
  BUTTON_CLICK,
  gaType
} from "./gaConstants"
import { LocalStorageKeys, LocalStorageService } from "src/services/localStorage";

export function initializeGa(){
  if(GA_MID){
	ReactGA.initialize([
	{
	  trackingId: GA_MID,
	  gtagOptions: {
	    send_page_view: false
	  }
	}
	]);
	ReactGA.set({
	  dimension1: "orgId",
	  dimension2: "userName"
	})
}
}

export function trackPageView(userName, path){
  if(GA_MID){
	const orgId: any = LocalStorageService.getRaw(LocalStorageKeys.__ORGANISATION_ID__);
	ReactGA.gtag("set", "user_properties", { orgId: orgId })
	ReactGA.gtag("set", "user_properties", { userName: userName })
	ReactGA.send({ hitType: "pageview", page: path });
  }
}

export function trackConsignmentTab(tab_id){
  if(GA_MID){
	if(bucketsPrettyNameMapping[tab_id]){
	  ReactGA.gtag('event', 'click', {
	  	event_category: "Tab Change",
		event_label: bucketsPrettyNameMapping[tab_id]
	  })
	}
  }
}

export function trackTableAction(actionDetails, bucket){
  if(!GA_MID) return
  if(!bucket && !actionDetails) return
  if(!actionDetails.children && !actionDetails.title){ //handling the case in which no sub buttons are available
	ReactGA.gtag('event', 'click', {
	  event_category: "Table Action",
	  event_label: actionDetails.label,
	  current_view: bucketsPrettyNameMapping[bucket]
	})
	return
  }
  if(actionDetails.title){
	ReactGA.gtag("event", "click", {
	  event_category: "Table Action",
	  event_label: `${actionDetails.title}__${actionDetails.label}`,
	  current_view: bucketsPrettyNameMapping[bucket]
	})
  }
}

export function trackInputFilter(values, bucket, filtersToShow){
  const fieldFilter = Object.fromEntries(Object.entries(values).filter(([key, value]) => value !== undefined));
  if(!GA_MID) return
  if(!bucket || !values || !fieldFilter || !bucketsPrettyNameMapping[bucket]) return
  Object.entries(fieldFilter).filter(([key, value]) => {
	let labelObj = filtersToShow.filter((item) => key === item.id)
	ReactGA.gtag("event", "search", {
	  event_category: "Table Filter",
	  event_label: labelObj[0].label,
	  current_view: bucketsPrettyNameMapping[bucket]
	})
  })
}

export function trackMultiFilter(bucket, title){
  if(!GA_MID || !bucket || !title) return
  if(!bucketsPrettyNameMapping[bucket]) return

  ReactGA.gtag("event", "search", {
	event_category: "Table Filter",
	event_label: title,
	current_view: bucketsPrettyNameMapping[bucket]
  })
}

export function trackHubSearch(values){
  if(!GA_MID || !values) return
  ReactGA.gtag("event", "search", {
	event_category: "Main Header",
	event_label: "Hub Filter"
  })
}

export function trackDateSearch(dates){
  if(!GA_MID || !dates) return
  ReactGA.gtag("event", "search", {
	event_category: "Main Header",
	event_label: "Date Filter"
  })
}

export function trackRowActions(label, bucket){
  if(!GA_MID || !label || !bucket) return
  if(!bucketsPrettyNameMapping[bucket]) return
  ReactGA.gtag("event", "click", {
	event_category: "Row Action",
	event_label: label,
	current_view: bucketsPrettyNameMapping[bucket]
  });
}

export function trackCustomiseColumns(bucket){
  if(!GA_MID) return
  if(!bucketsPrettyNameMapping[bucket]) return
  ReactGA.gtag("event", "click", {
	event_category: "Table Action",
	event_label: "Customise Table",
	current_view: bucketsPrettyNameMapping[bucket]
  })
}

export function trackButtonClick(label){
  if(!GA_MID) return
  if(!label) return
  ReactGA.gtag("event", "click", {
	event_category: "Button Click",
	event_label: label
  })
}

export function trackGaEvents(gaType: gaType, params){
  if(!gaType || !params) return
  switch(gaType.actionType){
	case HUB_SEARCH:
	  if(!params.values) return
	  trackHubSearch(params.values)
	  break
	case MULTI_FILTER:
	  if(!params.bucket || !params.title) return
	  trackMultiFilter(params.bucket, params.title)
	  break
	case TABLE_ACTION:
	  if(!params.bucket || !params.actionDetails) return
	  trackTableAction(params.actionDetails, params.bucket)
	  break
	case INPUT_FILTER:
	  if(!params.values || !params.bucket || !params.filtersToShow) return
	  trackInputFilter(params.values, params.bucket, params.filtersToShow)
	  break
	case CONSIGNMENT_TAB:
	  if(!params.activeBucket) return
	  trackConsignmentTab(params.activeBucket)
	  break
	case DATE_SEARCH:
	  if(!params.dates) return
	  trackDateSearch(params.dates)
	  break
	case CUSTOMISE_COLUMNS:
	  if(!params.bucket) return
	  trackCustomiseColumns(params.bucket)
	  break
	case ROW_ACTIONS:
	  if(!params.label || !params.bucket) return
	  trackRowActions(params.label, params.bucket)
	  break
	case BUTTON_CLICK:
	  if(!params.label) return
	  trackButtonClick(params.label)
	  break
	default:
	  return
  }
}
