import {
  SET_INTEGRATION_MARKETPLACE_MASTERDATA,
  SET_INTEGRATION_MARKETPLACE_INTEGRATION_DATA,
  UPDATE_INTEGRATION_METADATA,
 } from '../actions/constants';

const defaultState = {
  integrations: {},
  categories: {},
};

export default function (state: any = defaultState, action) {
  switch (action.type) {
    case SET_INTEGRATION_MARKETPLACE_MASTERDATA: {
      return {
        ...state,
        integrations: action.data.integrations,
        categories: action.data.categories,
        services: action.data.services,
      };
    }
    case SET_INTEGRATION_MARKETPLACE_INTEGRATION_DATA: {
      return {
        ...state,
        integrationData: action.data.integration_data,
        activeCount: action.data.activeCount,
        customersIntegrationList: action?.data?.customers || [],
      };
    }
    case UPDATE_INTEGRATION_METADATA: {
      return {
        ...state,
        integrations: {
          ...state.integrations,
          [action.integrationId]: action.data.masterdata,
        },
      };
    }
    default: return state;
  }
}
