import { colors } from './colors';

export const DEFAULT_THEME = 'theme_1';

const getConfigBar = (formData) => {
  return {
    graphic: {
      category: {
        data: 'Default',
        section: 'graphic',
      },
      color: {
        data: colors[DEFAULT_THEME],
        section: 'graphic',
      },
      columnSize: {
        data: 'default',
        section: 'graphic',
      },
      tooltip: {
        data: true,
        section: 'graphic',
      },
    },
    graphicLabel: {
      position: {
        data: 'top',
        section: 'graphicLabel',
      },
      offsetX: {
        data: 0,
        section: 'graphicLabel',
      },
      offsetY: {
        data: 0,
        section: 'graphicLabel',
      },
    },
    bar: {
      graphicLabel: {
        data:
          formData.hasOwnProperty('show_datalabels') &&
          formData.show_datalabels,
        section: 'bar',
      },
      legend: {
        data: true,
        section: 'bar',
      },
      xAxis: {
        data: true,
        section: 'bar',
      },
      yAxis: {
        data: true,
        section: 'bar',
      },
    },
    legend: {
      location: {
        data: 'top-left',
        section: 'legend',
      },
      flipPage: {
        data: true,
        section: 'legend',
      },
      offsetX: {
        data: 0,
        section: 'legend',
      },
      offsetY: {
        data: 0,
        section: 'legend',
      },
    },
    xAxis: {
      xTitle: {
        data: false,
        section: 'xAxis',
      },
      axisRulers: {
        data: false,
        section: 'xAxis',
      },
      xAxisLabel: {
        data: true,
        section: 'xAxis',
      },
      tickLine: {
        data: true,
        section: 'xAxis',
      },
      grid: {
        data: false,
        section: 'xAxis',
      },
      autoRotateLabel: {
        data: false,
        section: 'xAxis',
      },
      autoHideLabel: {
        data: false,
        section: 'xAxis',
      },
    },
    xAxisLabel: {
      offsetX: {
        data: 0,
        section: 'xAxisLabel',
      },
      offsetY: {
        data: 0,
        section: 'xAxisLabel',
      },
    },
    yAxis: {
      yTitle: {
        data: false,
        section: 'yAxis',
      },
      axisRulers: {
        data: false,
        section: 'yAxis',
      },
      yAxisLabel: {
        data: true,
        section: 'yAxis',
      },
      tickLine: {
        data: false,
        section: 'yAxis',
      },
      grid: {
        data: true,
        section: 'yAxis',
      },
      autoRotateLabel: {
        data: false,
        section: 'yAxis',
      },
      autoHideLabel: {
        data: false,
        section: 'yAxis',
      },
    },
    yAxisLabel: {
      offsetX: {
        data: 0,
        section: 'yAxisLabel',
      },
      offsetY: {
        data: 0,
        section: 'yAxisLabel',
      },
    },
  };
};

const getConfigLine = (formData) => {
  return {
    graphic: {
      category: {
        data: 'Default',
        section: 'graphic',
      },
      color: {
        data: colors[DEFAULT_THEME],
        section: 'graphic',
      },
      columnSize: {
        data: 2,
        section: 'graphic',
      },
      smooth: {
        data: true,
        section: 'graphic',
      },
      tooltip: {
        data: true,
        section: 'graphic',
      },
    },
    graphicLabel: {
      offsetX: {
        data: 0,
        section: 'graphicLabel',
      },
      offsetY: {
        data: 0,
        section: 'graphicLabel',
      },
    },
    line: {
      graphicLabel: {
        data:
          formData.hasOwnProperty('show_datalabels') &&
          formData.show_datalabels,
        section: 'line',
      },
      point: {
        data: true,
        section: 'line',
      },
      xAxis: {
        data: true,
        section: 'line',
      },
      yAxis: {
        data: true,
        section: 'line',
      },
      legend: {
        data: true,
        section: 'bar',
      },
    },
    legend: {
      location: {
        data: 'top-left',
        section: 'legend',
      },
      flipPage: {
        data: true,
        section: 'legend',
      },
      offsetX: {
        data: 0,
        section: 'legend',
      },
      offsetY: {
        data: 0,
        section: 'legend',
      },
    },
    point: {
      size: {
        data: 5,
        section: 'point',
      },
      shape: {
        data: 'diamond',
        section: 'point',
      },
    },
    xAxis: {
      xTitle: {
        data: false,
        section: 'xAxis',
      },
      axisRulers: {
        data: false,
        section: 'xAxis',
      },
      xAxisLabel: {
        data: true,
        section: 'xAxis',
      },
      tickLine: {
        data: false,
        section: 'xAxis',
      },
      grid: {
        data: false,
        section: 'xAxis',
      },
      autoRotateLabel: {
        data: false,
        section: 'xAxis',
      },
      autoHideLabel: {
        data: true,
        section: 'xAxis',
      },
    },
    xAxisLabel: {
      offsetX: {
        data: 0,
        section: 'xAxisLabel',
      },
      offsetY: {
        data: 0,
        section: 'xAxisLabel',
      },
    },
    yAxis: {
      yTitle: {
        data: false,
        section: 'yAxis',
      },
      axisRulers: {
        data: false,
        section: 'yAxis',
      },
      yAxisLabel: {
        data: true,
        section: 'yAxis',
      },
      tickLine: {
        data: false,
        section: 'yAxis',
      },
      grid: {
        data: true,
        section: 'yAxis',
      },
      autoRotateLabel: {
        data: false,
        section: 'yAxis',
      },
      autoHideLabel: {
        data: false,
        section: 'yAxis',
      },
    },
    yAxisLabel: {
      offsetX: {
        data: 0,
        section: 'yAxisLabel',
      },
      offsetY: {
        data: 0,
        section: 'yAxisLabel',
      },
    },
  };
};

const getConfigPie = (formData) => {
  return {
    graphic: {
      category: {
        data: 'Default',
        section: 'graphic',
      },
      color: {
        data: colors[DEFAULT_THEME],
        section: 'graphic',
      },
      radius: {
        data: 100,
        section: 'graphic',
      },
      tooltip: {
        data: true,
        section: 'graphic',
      },
    },
    legend: {
      location: {
        data: 'right',
        section: 'legend',
      },
      flipPage: {
        data: true,
        section: 'legend',
      },
      offsetX: {
        data: 0,
        section: 'legend',
      },
      offsetY: {
        data: 0,
        section: 'legend',
      },
    },
    pie: {
      legend: {
        data: true,
        section: 'pie',
      },
      graphicLabel: {
        data:
          formData.hasOwnProperty('show_datalabels') &&
          formData.show_datalabels,
        section: 'pie',
      },
    },
    graphicLabel: {
      type: {
        data: 'inner',
        section: 'graphicLabel',
      },
      offset: {
        data: 0,
        section: 'graphicLabel',
      },
      offsetX: {
        data: 0,
        section: 'graphicLabel',
      },
      offsetY: {
        data: 0,
        section: 'graphicLabel',
      },
    },
  };
};

const getConfigProgressBar = (formData) => {
  return {
    graphic: {
      category: {
        data: 'Default',
        section: 'graphic',
      },
      color: {
        data: colors[DEFAULT_THEME],
        section: 'graphic',
      },
      columnSize: {
        data: 'default',
        section: 'graphic',
      },
      tooltip: {
        data: true,
        section: 'graphic',
      },
    },
    graphicLabel: {
      position: {
        data: 'middle',
        section: 'graphicLabel',
      },
      offsetX: {
        data: 0,
        section: 'graphicLabel',
      },
      offsetY: {
        data: 0,
        section: 'graphicLabel',
      },
    },
    progressBar: {
      graphicLabel: {
        data:
          formData.hasOwnProperty('show_datalabels') &&
          formData.show_datalabels,
        section: 'progressBar',
      },
      legend: {
        data: true,
        section: 'progressBar',
      },
      xAxis: {
        data: true,
        section: 'progressBar',
      },
    },
    legend: {
      location: {
        data: 'top-left',
        section: 'legend',
      },
    },
    xAxis: {
      xTitle: {
        data: false,
        section: 'xAxis',
      },
    },
  };
};

const chartMapping = {
  bar: getConfigBar,
  line: getConfigLine,
  pie: getConfigPie,
  progressBar: getConfigProgressBar,
};

const initialChartConfig = (configType, formData) => {
  if (!['bar', 'line', 'pie', 'progressBar'].includes(configType)) {
    return null;
  }
  const chartConfig = chartMapping[configType];
  return chartConfig(formData);
};

export default initialChartConfig;
