import {
  getAllConsignmentsData,
  getSummaryMetricsData,
  assignCarriers,
  carrierHandover,
  carrierBooking,
  generateCarrierAWBNo,
  unassignCarriers,
  bulkAssignCarriersSampleCSV,
} from '../../api/genericConsignmentView';

const viewControl = {
  pageTitle: 'Carrier Dispatch',
  polling: true,
  applyDateFilterSort: true,
  buckets: ['third_party_all', 'third_party_self', 'third_party_assigned'],
  navBar: {
    left: ['single_hub_search', 'date_filter'],
    right: ['logistics_search'],
  },
  globalActions: [],
  searchFilterOptions: [
    { key: 'referenceNumber', val: 'Consignment No.' },
    { key: 'customerReferenceNumber', val: 'Customer Reference Number' },
    { key: 'awbNumber', val: 'Carrier AWB number' },
  ],
  dateFilterOptions: [
    { key: 'created_at', val: 'Created At' },
    { key: 'last_main_event_time', val: 'Last Updated' },
  ],
  fetchData: getAllConsignmentsData,
  fetchSummaryMetrics: getSummaryMetricsData,
  bulkAssignCarriers: assignCarriers,
  bulkCarrierHandOver: carrierHandover,
  bulkCourierBooking: carrierBooking,
  bulkGenerateCarrierAWBNo: generateCarrierAWBNo,
  bulkUnassignCarrier: unassignCarriers,
  bulkAssignCarriersSampleCSVData: bulkAssignCarriersSampleCSV,
  errorColumns: [
    {
      title: 'Consignments',
      dataIndex: 'reference_number',
    },
    {
      title: 'Error',
      dataIndex: 'message',
    },
  ],
  excelUploadMappingAssignCarriers: {
    reference_number: 'Consignment No',
    courier_account: 'Carrier',
    courier_partner_reference_number: 'Courier Partner Reference Number',
  },
  excelUploadMappingAssignCarriersWithReasonCode: {
    reference_number: 'Consignment No',
    courier_account: 'Carrier',
    courier_partner_reference_number: 'Courier Partner Reference Number',
    reason_code: 'Reason Code',
  },
  excelUploadMappingCarrierHandover: {
    reference_number: 'Consignment No',
  },

  excelUploadMappingCarrierBooking: {
    reference_number: 'Consignment No',
    courier_partner_reference_number: 'Courier Partner Reference Number',
  },

  excelUploadMappingGenerateAWB: {
    reference_number: 'Consignment No',
    courier_partner: 'Carrier Name',
    courier_partner_reference_number: 'Carrier AWB No',
  },
  excelUploadMappingUnassignCarrier: {
    reference_number: 'Consignment No',
  },
  downloadBulkAssignSampleCsvFromApi: true,
  sampleCSVGenerateCarrierAWB:
    'https://shipsy-public-assets.s3-us-west-2.amazonaws.com/retail/sample-download/sample_generate_awb_number.xlsx',
  sampleCSVCarrierHandover:
    'https://shipsy-public-assets.s3-us-west-2.amazonaws.com/retail/sample-download/sample_bulk_cn_handover.xlsx',
  sampleCSVCarrierBooking:
    'https://shipsy-public-assets.s3.us-west-2.amazonaws.com/retail/sample-download/sample_bulk_cn_booking.xlsx',
  sampleCSVAssignCarrier:
    'https://shipsy-public-assets.s3-us-west-2.amazonaws.com/retail/sample-download/sample_bulk_assign_third_party.xlsx',
  sampleCSVUnassignCarrier:
    'https://shipsy-public-assets.s3-us-west-2.amazonaws.com/retail/sample-download/sample_bulk_unassign_third_party.xlsx',
};
const buckets = {
  third_party_all: {
    name: 'All',
    filters: [
      'statusList',
      'numPieces',
      'invoiceValue',
      'referenceNumberList',
      'bookingServiceTypeIdList',
      'executionStatusList',
      'isCNVDone',
      'movementTypeList',
      'legTypeList',
      'isPaymentDone',
      'isPgiDone',
      'orderTypeList',
      'constraintTagsList',
    ],
    actions: [
      {
        key: 'assign_to_third_party',
        name: 'Assign to Carrier',
        children: [
          {
            key: 'assign_carrier',
            name: 'Single/Selected',
            type: 'modal',
            modal: 'assign_carrier',
          },
          {
            key: 'bulk_assign_carrier',
            name: 'Bulk',
            type: 'modal',
            modal: 'bulk_assign_carrier',
          },
        ],
        isAllowed: (masterData) =>
          !masterData.hide_assign_third_party_carrier_feature && masterData.third_party_update,
      },
      {
        key: 'bulk_print_label',
        name: 'Bulk Print Label',
        children: [
          {
            key: 'bulk_print_label',
            name: 'Single/Selected',
            type: 'modal',
            modal: 'bulk_print_label',
          },
          {
            key: 'bulk_upload_label',
            name: 'Bulk Upload',
            type: 'modal',
            modal: 'bulk_upload_label',
          },
        ],
        isAllowed: (masterData) => !masterData.hide_third_party_label_print  &&
          masterData.third_party_read,
      },
      {
        key: 'unassign_carrier_third_party',
        name: 'Unassign Carrier',
        children: [
          {
            key: 'unassign_carrier',
            name: 'Single/Selected',
            type: 'modal',
            modal: 'unassign_carrier',
          },
          {
            key: 'bulk_unassign_carrier',
            name: 'Bulk Unassign',
            type: 'modal',
            modal: 'bulk_unassign_carrier',
          },
        ],
        isAllowed: (masterData) =>
          masterData.show_unassign_third_party_carrier_feature && masterData.third_party_update,
      },
      {
        key: 'download_dispatch_summary',
        name: 'Download Dispatch Summary',
        type: 'modal',
        modal: 'download_dispatch_summary',
        isAllowed: (masterData) =>
          masterData.show_download_dispatch_summary &&
          masterData.third_party_read,
      },
      {
        key: 'scan_and_print_label',
        name: 'Scan and Print Label',
        type: 'modal',
        modal: 'scan_and_print_label',
        isAllowed: (masterData) => !masterData.hide_third_party_label_print  &&
          masterData.third_party_read,
      },
    ],
    status_filter: [
      'out_for_pickup',
      'pickup_awaited',
      'softdata_upload',
      'bagged',
      'not_picked_up',
      'inscan_at_hub',
      'attempted',
      'handover_courier_partner',
      'reachedathub',
      'pickup_scheduled',
      'loaded',
      'on_hold',
      'rto',
      'cancelled',
      'intransittohub',
      'archived',
      'shelved',
      'delivered',
      'rescheduled',
      'outfordelivery',
      'pickup_completed',
    ],
    isSubBucket: false,
    showDownloads: true,
  },
  third_party_self: {
    name: 'Self',
    filters: [
      'statusList',
      'numPieces',
      'invoiceValue',
      'referenceNumberList',
      'bookingServiceTypeIdList',
      'executionStatusList',
      'isCNVDone',
      'movementTypeList',
      'legTypeList',
      'isPaymentDone',
      'isPgiDone',
      'orderTypeList',
      'constraintTagsList',
    ],
    actions: [
      {
        key: 'bulk_print_label',
        name: 'Bulk Print Label',
        children: [
          {
            key: 'bulk_print_label',
            name: 'Single/Selected',
            type: 'modal',
            modal: 'bulk_print_label',
          },
          {
            key: 'bulk_upload_label',
            name: 'Bulk Upload',
            type: 'modal',
            modal: 'bulk_upload_label',
          },
        ],
        isAllowed: (masterData) => !masterData.hide_third_party_label_print &&
          masterData.third_party_read,
      },
      {
        key: 'download_dispatch_summary',
        name: 'Download Dispatch Summary',
        type: 'modal',
        modal: 'download_dispatch_summary',
        isAllowed: (masterData) =>
          masterData.show_download_dispatch_summary &&
            masterData.third_party_read,
      },
      {
        key: 'scan_and_print_label',
        name: 'Scan and Print Label',
        type: 'modal',
        modal: 'scan_and_print_label',
        isAllowed: (masterData) => !masterData.hide_third_party_label_print  &&
          masterData.third_party_read,
      },
    ],
    status_filter: [
      'out_for_pickup',
      'pickup_awaited',
      'softdata_upload',
      'bagged',
      'not_picked_up',
      'inscan_at_hub',
      'attempted',
      'handover_courier_partner',
      'reachedathub',
      'pickup_scheduled',
      'loaded',
      'on_hold',
      'rto',
      'cancelled',
      'intransittohub',
      'archived',
      'shelved',
      'delivered',
      'rescheduled',
      'outfordelivery',
      'pickup_completed',
    ],
    isSubBucket: false,
    showDownloads: true,
  },
  third_party_assigned: {
    name: 'Third Party',
    filters: [
      'statusList',
      'numPieces',
      'invoiceValue',
      'referenceNumberList',
      'bookingServiceTypeIdList',
      'executionStatusList',
      'isCNVDone',
      'movementTypeList',
      'legTypeList',
      'isPaymentDone',
      'isPgiDone',
      'orderTypeList',
      'constraintTagsList',
      'bagReferenceNumberList',
    ],
    actions: [
      {
        key: 'create_carrier_booking',
        name: 'Create Carrier Booking',
        children: [
          {
            key: 'carrier_booking',
            name: 'Single/Selected',
            type: 'modal',
            modal: 'carrier_booking',
          },
          {
            key: 'bulk_carrier_booking',
            name: 'Excel Upload',
            type: 'modal',
            modal: 'bulk_carrier_booking',
          },
        ],
        isAllowed: (masterData) => !masterData.disable_create_carrier_booking_button,
      },
      {
        key: 'handover_to_third_party',
        name: 'Handover to Carrier',
        children: [
          {
            key: 'carrier_handover',
            name: 'Single/Selected',
            type: 'modal',
            modal: 'carrier_handover',
          },
          {
            key: 'bulk_carrier_handover',
            name: 'Excel Upload',
            type: 'modal',
            modal: 'bulk_carrier_handover',
          },
        ],
        isAllowed: (masterData) => masterData.third_party_update,
      },
      {
        key: 'generate_carrier_awb',
        name: 'Assign Carrier AWB #',
        children: [
          {
            key: 'generate_carrier_awb',
            name: 'Single/Selected',
            type: 'modal',
            modal: 'generate_carrier_awb',
          },
          {
            key: 'bulk_generate_carrier_awb',
            name: 'Excel Upload',
            type: 'modal',
            modal: 'bulk_generate_carrier_awb',
          },
        ],
        isAllowed: (masterData) => masterData.show_generate_carrier_awb_feature &&
          masterData.third_party_update,
      },
      {
        key: 'third_party_inscan',
        name: 'Inscan',
        type: 'modal',
        modal: 'third_party_inscan',
        isAllowed: (masterData) => masterData.show_third_party_inscan_option &&
          masterData.third_party_update,
      },
      {
        key: 'bulk_print_label',
        name: 'Bulk Print Label',
        children: [
          {
            key: 'bulk_print_label',
            name: 'Single/Selected',
            type: 'modal',
            modal: 'bulk_print_label',
          },
          {
            key: 'bulk_upload_label',
            name: 'Bulk Upload',
            type: 'modal',
            modal: 'bulk_upload_label',
          },
        ],
        isAllowed: (masterData) => !masterData.hide_third_party_label_print &&
          masterData.third_party_read,
      },
      {
        key: 'download_dispatch_summary',
        name: 'Download Dispatch Summary',
        type: 'modal',
        modal: 'download_dispatch_summary',
        isAllowed: (masterData) =>
          masterData.show_download_dispatch_summary &&
            masterData.third_party_read,
      },
      {
        key: 'scan_and_print_label',
        name: 'Scan and Print Label',
        type: 'modal',
        modal: 'scan_and_print_label',
        isAllowed: (masterData) => !masterData.hide_third_party_label_print  &&
          masterData.third_party_read,
      },
    ],
    status_filter: [
      'out_for_pickup',
      'pickup_awaited',
      'softdata_upload',
      'bagged',
      'not_picked_up',
      'inscan_at_hub',
      'attempted',
      'handover_courier_partner',
      'reachedathub',
      'pickup_scheduled',
      'loaded',
      'on_hold',
      'rto',
      'cancelled',
      'intransittohub',
      'archived',
      'shelved',
      'delivered',
      'rescheduled',
      'outfordelivery',
      'pickup_completed',
    ],
    isSubBucket: false,
    showDownloads: true,
  },
};

const bucketColor = {
  third_party_all: '#5ac780',
  third_party_self: '#ea7e77',
  third_party_assigned: '#40a6db',
};

export default { viewControl, buckets, bucketColor };
