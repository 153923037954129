import { getAllTmsVehicleData, getTmsVehicleSummaryMetricsData } from '../../api/TMSDashboard/VehicleView';
const defaultRetailStatusFilterOptions = [
  'reachedathub',
  'outfordelivery',
  'delivered',
  'attempted',
  'inscan_at_hub',
  'in_transit',
  'pickup_awaited',
  'pickup_scheduled',
  'out_for_pickup',
  'not_picked_up',
  'pickup_completed',
];

const viewControl = {
  pageTitle: 'Vehicle',
  buckets : [
    'tms_vehicle_expected',
    'tms_vehicle_arrived',
    'tms_vehicle_unloading',
    'tms_vehicle_loading',
    'tms_vehicle_outbound',
  ],
  navBar : {
    left : ['single_hub_search', 'date_filter'],
    right : ['search_redirect'],
  },
  globalActions:[],
  dateFilterOptions : [
    { key:'last_status_change_time', val:'Last Updated' },
  ],
  searchFilterOptions : [
    { key:'vehicle_number', val: 'Vehicle No.' },
    { key:'ist_reference_number', val: 'Trip No.' },
  ],
  fetchData : getAllTmsVehicleData,
  fetchSummaryMetrics : getTmsVehicleSummaryMetricsData,
};
const buckets = {
  tms_vehicle_expected : {
    name: 'Expected',
    filters : [
      'status',
      'attempt_count',
      'service_type',
      'load_type',
      'customer_code',
      'consignment_type',
      'proximity',
      'orderType',
      'purchasingGroup',
      'reference_number_search',
    ],
    actions : [
      {
      key:'gate_in_tag',
      name:'Gate In',
      isAllowed: masterData => masterData.ops_dashboard_config?.parts_to_show?.gate_update,
      },
      {
        key:'receive_ist',
        name:'Trip Receive',
      },
      {
        key:'track_trace',
        name:'Track',
        isAllowed:
        masterData => masterData.ops_dashboard_config.parts_to_show.show_vehicle_midmile_track,
      },
      {
        key:'trip_manifest',
        name:'Print Manifest',
        isAllowed: masterData => masterData.show_logistics_express_options &&
              masterData.ops_dashboard_config?.parts_to_show?.allow_label_print,
      },
    ],
    status_filter: defaultRetailStatusFilterOptions,
    defaultSortBy: 'last_status_change_time',

  },
  tms_vehicle_arrived : {
    name:'Arrived',
    filters : [
      'status',
      'attempt_count',
      'service_type',
      'load_type',
      'customer_code',
      'consignment_type',
      'proximity',
      'orderType',
      'purchasingGroup',
      'reference_number_search',
    ],
    actions : [
      {
      key:'dock_inscan',
      name:'Dock Inscan',
      isAllowed: masterData =>  masterData.ops_dashboard_config?.parts_to_show?.dock_update,
      },
      {
        key:'ist_load',
        name:'Load',
        isAllowed:
        masterData => masterData.ops_dashboard_config?.parts_to_show?.vehicle_loading_unloading,
      },
      {
        key:'ist_unload',
        name:'Unload',
        isAllowed:
        masterData => masterData.ops_dashboard_config?.parts_to_show?.vehicle_loading_unloading,
      },
      {
        key:'trip_manifest',
        name:'Print Manifest',
        isAllowed: masterData => masterData.show_logistics_express_options &&
              masterData.ops_dashboard_config?.parts_to_show?.allow_label_print ,
      },
    ],
    status_filter: defaultRetailStatusFilterOptions,
    defaultSortBy: 'last_status_change_time',

  },
  tms_vehicle_unloading : {
    hidePagination : false,
    hidePerPageCount :false,
    name:'Unloading',
    filters : [
      'status',
      'attempt_count',
      'service_type',
      'load_type',
      'customer_code',
      'consignment_type',
      'proximity',
      'orderType',
      'purchasingGroup',
      'reference_number_search',
    ],
    actions : [
      {
        key:'close_ist',
        name:' Trip Close',
      },
      {
        key:'ist_load',
        name:'Load',
        isAllowed:
        masterData => masterData.ops_dashboard_config?.parts_to_show?.vehicle_loading_unloading,
      },
      {
        key:'ist_unload',
        name:'Unload',
        isAllowed:
        masterData => masterData.ops_dashboard_config?.parts_to_show?.vehicle_loading_unloading,
      },
      {
        key:'trip_manifest',
        name:'Print Manifest',
        isAllowed: masterData => masterData.show_logistics_express_options &&
              masterData.ops_dashboard_config?.parts_to_show?.allow_label_print,
      },
    ],
    status_filter: defaultRetailStatusFilterOptions,
    defaultSortBy: 'last_status_change_time',

  },
  tms_vehicle_loading : {
    name: 'Loading',
    filters : [
      'status',
      'attempt_count',
      'service_type',
      'load_type',
      'customer_code',
      'consignment_type',
      'proximity',
      'orderType',
      'purchasingGroup',
      'reference_number_search',
    ],
    actions: [
      {
        key:'ist_load',
        name:'Load',
        isAllowed:
        masterData => masterData.ops_dashboard_config?.parts_to_show?.vehicle_loading_unloading,
      },
      {
        key:'ist_freeze',
        name:'Freeze Trip',
        isAllowed:
        masterData => masterData.ops_dashboard_config?.parts_to_show?.vehicle_loading_unloading,
      },
      {
        key:'trip_manifest',
        name:'Print Manifest',
        isAllowed: masterData => masterData.show_logistics_express_options &&
              masterData.ops_dashboard_config?.parts_to_show?.allow_label_print,
      },
    ],
    status_filter : defaultRetailStatusFilterOptions,
    defaultSortBy: 'last_status_change_time',
  },
  tms_vehicle_outbound : {
    name: 'Outbound',
    filters : [
      'status',
      'attempt_count',
      'service_type',
      'load_type',
      'customer_code',
      'consignment_type',
      'proximity',
      'orderType',
      'purchasingGroup',
      'reference_number_search',
    ],
    actions: [
      {
        key:'depart_ist',
        name:'Trip Depart',
      },
      {
        key:'dock_outscan',
        name:'Dock Outscan',
        isAllowed: masterData =>  masterData.ops_dashboard_config?.parts_to_show?.dock_update,
      },
      {
        key:'gate_out',
        name:'Gate Out',
        isAllowed: masterData => masterData.ops_dashboard_config?.parts_to_show?.gate_update,
      },
      {
        key:'trip_manifest',
        name:'Print Manifest',
        isAllowed: masterData => masterData.ops_dashboard_config?.parts_to_show?.allow_label_print,
      },
    ],
    status_filter : defaultRetailStatusFilterOptions,
    defaultSortBy: 'last_status_change_time',
  },
};

const bucketColor = {
  tms_vehicle_expected: '#EC5D2D',
  tms_vehicle_arrived: '#DA1A83',
  tms_vehicle_unloading: '#3CB4B7',
  tms_vehicle_loading: '#B7BF15',
  tms_vehicle_outbound: '#8445DC',
};

export default { viewControl, buckets, bucketColor };
