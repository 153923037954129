import React, { useState, useEffect, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import {
  CloseOutlined,
  BellOutlined,
  SettingOutlined,
  SettingFilled,
  SmileOutlined,
  FrownOutlined,
} from '@ant-design/icons';
import {
  Divider,
  Popover,
  Checkbox,
  Switch,
  List,
  Badge,
  Timeline,
  Modal,
  Row,
  Col,
  Typography,
  Skeleton,
  Avatar,
} from 'antd';
import { isMobile } from 'react-device-detect';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { firestore, auth } from 'src/firebase';
import { signInWithCustomToken } from 'firebase/auth';
import InfiniteScroll from 'react-infinite-scroll-component';
import { NotificationDescription } from './NotificationComponent/Content';
import { CreateTitleForNotificationBody } from './NotificationComponent/Title';
import { ProfileAvatar } from './NotificationComponent/common';
import { notificationSettings } from './notificationConstants';
import { ListNotificationEnd } from 'src/assets/images/listNotification';
import { updateUserNotificationPreference } from 'src/api/dashboard';
import {
  getNotificationData,
  getUserPreference,
  readBellNotification,
  readNotification,
} from './notificationAPIs';
import { getAuthenticationHeaders } from 'src/api/utils';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const useStyle = createUseStyles({
  notificationIcon: {
    cursor: 'pointer',
    margin: '0 4px',
    marginTop: 4,
    color: '#000000',
  },
  settingPage: {
    maxHeight: '640px',
    overflowY: 'auto',
    width: '440px',
  },
  checkBoxStyle: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    paddingTop: '14px',
  },
  reciveTextStyle: {
    color: '#111',
    fontSize: '10px',
    fontWeight: 600,
  },
  notificationTitle: {
    '& .ant-popover-title': {
      padding: '12px 16px',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#111',
    fontSize: '16px',
    fontWeight: '600',
  },
  settingDivider: {
    '& .ant-divider': {
      margin: '18px 0px',
    },
  },
  showUnreadSwitch: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
  },
  avatarStyle: {
    '& .ant-list-item-meta-avatar': {
      marginRight: '10px',
    },
  },
  timeLine: {
    '&:hover': {
      backgroundColor: '#f0f0f0 !important',
      cursor: 'pointer',
    },
    '& .ant-timeline-item-tail': {
      top: '17px',
      left: '10px',
      borderLeft: '2px solid #DDD',
    },
    '& .ant-timeline-item-head-custom': {
      backgroundColor: 'transparent !important',
      top: '13px',
      left: '12px',
    },
    '& .ant-list-item': {
      paddingTop: '8px !important',
      paddingBottom: '11px !important',
    },
    paddingBottom: '0px',
  },
  timeLineHide: {
    '&:hover': {
      backgroundColor: '#f0f0f0 !important',
      cursor: 'pointer',
    },
    '& .ant-timeline-item-tail': {
      visibility: 'hidden',
    },
    '&  .ant-timeline-item-head-custom': {
      backgroundColor: 'transparent !important',
      top: '14px',
      left: '12px',
    },
    '& .ant-list-item': {
      paddingTop: '8px !important',
      paddingBottom: '4px !important',
    },
    paddingBottom: '0px',
  },
  notificationDivider: {
    marginTop: '6px',
    marginBottom: '6px',
  },
  moreNotification: {
    color: '#006EC3',
    fontWeight: 600,
    fontSize: '12px',
  },
  lessNotification: {
    color: '#006EC3',
    fontWeight: 600,
    fontSize: '12px',
    marginLeft: '28px',
    cursor: 'pointer',
  },
  markAllReadText: {
    color: '#006EC3',
    fontSize: '12px',
    fontWeight: '600',
    cursor: 'pointer',
  },
  modalNotification: {
    '& .ant-modal-body': {
      padding: '12px',
    },
    marginTop: '-40px',
  },
  helpIcon: {
    fontSize: '21px',
    color: '#595959',
    margin: '0px 4px',
  },
  noAggreggateResultsContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  noAggregateWrapper: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '1rem',
    gap: '1rem',
    fontWeight: 600,
    alignItems: 'center',
  },
  unReadCountStyle: {
    '& .ant-badge-count': {
      right: '4px',
    },
  },
  userName: {
    color: '#666',
    textAlign: 'right',
    fontSize: '12px',
    fontWeight: 400,
  },
  notificationsText: {
    color: '#111',
    fontSize: '16px',
    fontWeight: '600',
  },
  popoverContent: {
    '& .ant-popover-inner-content': {
      padding: '10px 10px !important',
    },
  },
  notificationEndStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '5px',
  },
  notificationEndText: {
    display: 'flex',
    flexDirection: 'column',
  },
  markAllReadStyle: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
  },
  avatarTextStyle: {
    fontSize: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  test: {
    '& .ant-avatar-group': {
      paddingTop: '2px',
    },
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '28px',
    cursor: 'pointer',
    gap: '3px',
  },
});

const NotificationItem = (props) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const {
    item,
    index,
    showMore,
    notificationData,
    showMoreOnClick,
    notificationHistoryLength,
    notificationStatus,
    setNotificationStatus,
    userId,
    organisationId,
  } = props;

  const onClickNotification = async (notificationHistoryId) => {
    if (!notificationStatus[notificationHistoryId]) {
      setNotificationStatus({
        ...notificationStatus,
        [notificationHistoryId]: true,
      });

      await readNotification({
        notificationHistoryId,
        notificationId: item.id,
        isRead: true,
        isReadAll: false,
        userId,
        organisationId,
      });
    }
    if (item.url) {
      window.open(item.url, '_blank');
    }
  };
  const notificationHistory = item.notificationHistory;
  const uniqueUserIdsWithNames = Array.from(
    new Set(notificationHistory.slice(1).map((item) => item.actionUser.userId)),
  ).map((userId) => {
    const userObject = notificationHistory.find(
      (item) => item.actionUser.userId === userId,
    );
    return { userId, userName: userObject.actionUser.userName };
  });
  const moreThanTwoUniqueUserIds = uniqueUserIdsWithNames.length > 1;
  const avatarGroup = [];
  if (moreThanTwoUniqueUserIds) {
    uniqueUserIdsWithNames.forEach((data, index) => {
      if (index < 4) {
        avatarGroup.push(
          <ProfileAvatar name={data.userName} sizeAvatar={19} sizeText={10} />,
        );
      }
    });
  } else if (notificationHistoryLength > 1) {
    avatarGroup.push(
      <ProfileAvatar
        name={notificationHistory[1].actionUser.userName}
        sizeAvatar={19}
        sizeText={10}
      />,
    );
  }

  const AvatarGroup = (others) => {
    return (
      <div
        className={classes.test}
        onClick={(e) => showMoreOnClick(item.id, e)}
      >
        <Avatar.Group>{avatarGroup}</Avatar.Group>
        <div className={classes.moreNotification}>
          + {notificationHistoryLength - 1} {t('updates from')} {' '}
          {notificationHistory[1].actionUser.userName} {others && t('and others')}
        </div>
      </div>
    );
  };

  return (
    <>
      <Timeline>
        <Timeline.Item
          dot={
            <ProfileAvatar
              name={item.notificationHistory[0].actionUser.userName}
              sizeAvatar={20}
              sizeText={12}
            />
          }
          className={
            !showMore[item.id] ? classes.timeLineHide : classes.timeLine
          }
        >
          <List.Item>
            <List.Item.Meta
              onClick={() =>
                onClickNotification(item.notificationHistory[0].id)
              }
              className={classes.avatarStyle}
              title={CreateTitleForNotificationBody({
                item: item.notificationHistory[0],
                ticketName: item.entityIdPrettyName,
                sourceName: item.sourceName,
                notificationId: item.id,
                notificationStatus,
                setNotificationStatus,
              })}
              description={NotificationDescription({
                item: item.notificationHistory[0],
                title: item.entityTitle,
                notificationId: item.id,
                notificationStatus,
                setNotificationStatus,
              })}
            />
          </List.Item>
        </Timeline.Item>
        {moreThanTwoUniqueUserIds ? (
          <>{!showMore[item.id] && <AvatarGroup others={true} />}</>
        ) : (
          <>
            {notificationHistoryLength > 1 && !showMore[item.id] && (
              <AvatarGroup others={false} />
            )}
          </>
        )}
        {notificationHistoryLength > 1 && showMore[item.id] && (
          <div>
            <List
              split={false}
              itemLayout="horizontal"
              dataSource={notificationData[index].notificationHistory}
              renderItem={(item, i) => {
                if (i === 0) return <></>;
                return (
                  <Timeline.Item
                    dot={
                      <ProfileAvatar
                        name={item.actionUser.userName}
                        sizeAvatar={20}
                        sizeText={12}
                      />
                    }
                    className={
                      i !==
                      notificationData[index].notificationHistory.length - 1
                        ? classes.timeLine
                        : classes.timeLineHide
                    }
                  >
                    <List.Item>
                      <List.Item.Meta
                        onClick={() => onClickNotification(item.id)}
                        className={classes.avatarStyle}
                        title={CreateTitleForNotificationBody({
                          item,
                          notificationId: notificationData[index].id,
                          sourceName: notificationData[index].sourceName,
                          notificationStatus,
                          setNotificationStatus,
                        })}
                        description={NotificationDescription({
                          item,
                          notificationId: notificationData[index].id,
                          notificationStatus,
                          setNotificationStatus,
                        })}
                      />
                    </List.Item>
                  </Timeline.Item>
                );
              }}
            />
            <div
              onClick={(event) => showMoreOnClick(item.id, event)}
              className={classes.lessNotification}
            >
              {' '}
              {t('Show Less')}
            </div>
          </div>
        )}
      </Timeline>
      {index !== notificationData.length - 1 && (
        <Divider className={classes.notificationDivider}></Divider>
      )}
    </>
  );
};

const NotificationIcon = (props) => {
  const { t } = useTranslation();
  const { LiaCoPilotnotification } = props;
  const classes = useStyle();
  const [setting, setSetting] = useState<boolean>(false);
  const [showUnreadNotification, setShowUnreadNotification] =
    useState<boolean>(true);
  const [notificationVisible, setNotificationVisible] =
    useState<boolean>(false);
  const [checkedList, setCheckedList] = useState<any>(['lia']);
  const [showMore, setShowMore] = useState<any>({});
  const [notificationStatus, setNotificationStatus] = useState<any>({});
  const [totalUnreadCount, setTotalUnreadCount] = useState(0);
  const [notificationData, setNotificationData] = useState<any>([]);
  const [errorInNotification, setErrorInNotification] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [loaderSetting, setLoaderSetting] = useState(false);
  const authToken = useSelector((state) => state.notificationReducer.firebaseAuthToken);
  const [userId, setUserId] = useState(null);
  const [organisationId, setOrganisationId] = useState(null);

  const CheckboxGroup = Checkbox.Group;
  const popoverRef = useRef(null);
  useEffect(() => {
    let unsubscribe = () => {};
    const fetchAndSetAuthIds = async () => {
      const data = await getAuthenticationHeaders();
      setUserId(data['user-id']);
      setOrganisationId(data['organisation-id']);
      if (data['user-id'] && data['organisation-id'] && authToken) {
        signInWithCustomToken(auth, authToken)
          .then((userCredential) => {
            const userNotificationCountCollection = collection(
              firestore,
              'userNotificationCount',
            );
            const userNotificationsQuery = query(
              userNotificationCountCollection,
              where('userId', '==', data['user-id']),
              where('organisationId', '==', data['organisation-id']),
              where('userType', '==', 'employee'),
            );
            unsubscribe = onSnapshot(userNotificationsQuery, (snapshot) => {
              snapshot.forEach((doc) => {
                setTotalUnreadCount(doc.data().count);
              });
            });
          })
          .catch((error) => {
            setErrorInNotification(true);
            setLoaderSetting(false);
            setHasMore(false);
          });
      }
    };
    const getInitialNotificationUserPreference = async () => {
      try {
        if (userId && organisationId) {
          const response = await getUserPreference({
            userId,
            organisationId,
          });
          if (Object.keys(response ?? {}).length === 0) {
            setCheckedList(['lia']);
            setShowUnreadNotification(true); //setting up the default values
          } else {
            setCheckedList(response?.allowedServiceNotifications);
            setShowUnreadNotification(response?.showOnlyUnread);
          }
        }
      } catch (error) {
        console.log('error', error);
      }
    };
    fetchAndSetAuthIds();
    getInitialNotificationUserPreference();
    return () => {
      unsubscribe();
    };
  }, [authToken]);

  const getAllNotificationData = async () => {
    const response = await getNotificationData({
      userId,
      organisationId,
      authToken,
    });
    if (!response) {
      setErrorInNotification(true);
      setHasMore(false);
    }
    if (JSON.stringify(response) !== JSON.stringify(notificationData)) {
      setNotificationData(response ?? []);
      if (response?.length < 10) {
        setHasMore(false);
      } else if (response?.length >= 10) {
        setHasMore(true);
      }
    }
    if (response?.length === 0) {
      setHasMore(false);
    }
    setLoaderSetting(false);
  };

  useEffect(() => {
    const initialObject = {};
    if (!notificationData) return;
    if (notificationData && notificationData.length === 0) return;
    const resultObject = notificationData.reduce((acc, notification) => {
      acc[notification.id] = false;
      return acc;
    }, initialObject);
    setShowMore(resultObject);

    const notificationStatus = {};
    notificationData.forEach((notification) => {
      notification.notificationHistory.forEach((historyItem) => {
        notificationStatus[historyItem.id] = true; // true means msg is read
        if (
          notification.unreadIds &&
          notification.unreadIds.includes(historyItem.id)
        ) {
          notificationStatus[historyItem.id] = false; // notification unread
        }
      });
    });
    setNotificationStatus(notificationStatus);
  }, [notificationData]);

  const onChangeCheckedList = async (list) => {
    setLoaderSetting(true);
    setNotificationData([]);
    setCheckedList(list);
    await updateUserNotificationPreference({
      allowedServiceNotifications: list,
      showOnlyUnread: showUnreadNotification,
    });
    getAllNotificationData();
  };
  const onChangeShowUnreadNotification = async (value) => {
    setLoaderSetting(true);
    setNotificationData([]);
    setShowUnreadNotification(value);
    await updateUserNotificationPreference({
      allowedServiceNotifications: checkedList,
      showOnlyUnread: value,
    });
    getAllNotificationData();
  };
  const showMoreOnClick = (id, event) => {
    event.stopPropagation();
    const newShowMore = { ...showMore };
    newShowMore[id] = !showMore[id];
    setShowMore(newShowMore);
  };
  const markAllasReadClick = async () => {
    const newNotificationStatus = { ...notificationStatus };
    Object.keys(newNotificationStatus).forEach((key) => {
      newNotificationStatus[key] = true;
    });
    setNotificationStatus(newNotificationStatus);
    await readNotification({ isReadAll: true, userId, organisationId });
  };

  const loadMoreData = async () => {
    const response = await getNotificationData({
      userId,
      organisationId,
      lastNotificationId: notificationData[notificationData.length - 1].id,
      authToken,
    });
    setNotificationData([...notificationData, ...response]);
    if (response.length === 0 || response.length < 10) {
      setHasMore(false);
    }
  };
  const EmptyIcon = errorInNotification ? FrownOutlined : SmileOutlined;
  const content = setting ? (
    <div className={classes.settingPage}>
      <div className={classes.reciveTextStyle}>
        {t('Receive Notifications from')}...
      </div>
      <CheckboxGroup
        options={notificationSettings}
        value={checkedList}
        onChange={(ea) => onChangeCheckedList(ea)}
        className={classes.checkBoxStyle}
      />
      <Divider className={classes.settingDivider} />
      <div className={classes.showUnreadSwitch}>
        <Switch
          size="small"
          checked={showUnreadNotification}
          onChange={(e) => onChangeShowUnreadNotification(e)}
        />
        <div>{t('Show only Unread Notifications')} </div>
      </div>
    </div>
  ) : (
    <div
      id="scrollableDiv"
      style={{
        maxHeight: isMobile ? '82vh' : '640px',
        overflowY: 'auto',
        overflowX: 'hidden',
        width: !isMobile ? '440px' : undefined,
      }}
    >
      <InfiniteScroll
        dataLength={notificationData.length}
        next={loadMoreData}
        hasMore={hasMore}
        loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
        scrollableTarget="scrollableDiv"
        endMessage={
          <>
            {notificationData.length > 10 && (
              <div className={classes.notificationEndStyle}>
                <ListNotificationEnd />
                <div className={classes.notificationEndText}>
                  <span> {t('Thats all your notifications from')}</span>
                  <span style={{ textAlign: 'center' }}>
                    {' '}
                    {t('the last 30 days')}.{' '}
                  </span>
                </div>
              </div>
            )}
          </>
        }
      >
        <List
          split={false}
          itemLayout="horizontal"
          dataSource={notificationData}
          locale={{
            emptyText: (
              <>
                {!hasMore && !loaderSetting && (
                  <Row className={classes.noAggreggateResultsContainer}>
                    <Col span={24} className={classes.noAggregateWrapper}>
                      <EmptyIcon
                        style={{
                          fontSize: '2rem',
                          color: errorInNotification ? '#FF2E2E' : '#21AF49',
                        }}
                      />
                      <Text style={{ fontSize: '1rem' }}>
                        {errorInNotification
                          ? t('Something Went Wrong')
                          : t('No New Notifications Available!')}
                      </Text>
                    </Col>
                  </Row>
                )}
                {loaderSetting && (
                  <Skeleton avatar paragraph={{ rows: 1 }} active />
                )}
              </>
            ),
          }}
          renderItem={(item, index) => {
            const notificationHistoryLength =
              notificationData[index].notificationHistory.length;
            return (
              <React.Fragment>
                <NotificationItem
                  item={item}
                  index={index}
                  showMore={showMore}
                  notificationHistoryLength={notificationHistoryLength}
                  notificationsLength={notificationData.length}
                  showMoreOnClick={showMoreOnClick}
                  notificationData={notificationData}
                  notificationStatus={notificationStatus}
                  setNotificationStatus={setNotificationStatus}
                  userId={userId}
                  organisationId={organisationId}
                />
              </React.Fragment>
            );
          }}
        />
      </InfiniteScroll>
    </div>
  );
  const SettingIcon = setting ? SettingFilled : SettingOutlined;
  const onSettingIconClick = () => {
    setSetting(!setting);
  };
  const notificationTitle = (
    <div className={classes.notificationTitle}>
      <div className={classes.notificationsText}>{t('Notifications')}</div>
      <div className={classes.markAllReadStyle}>
        {notificationData.length > 0 && (
          <div
            className={classes.markAllReadText}
            onClick={() => markAllasReadClick()}
          >
            {t('Mark all as read')}
          </div>
        )}
        {!errorInNotification && (
          <SettingIcon
            onClick={onSettingIconClick}
            style={{ fontSize: '15px' }}
          />
        )}
        {isMobile && (
          <CloseOutlined onClick={() => setNotificationVisible(false)} />
        )}
      </div>
    </div>
  );

  const onPopoverVisibleChange = async (visible) => {
    setPopoverVisible(visible);
    if (visible) {
      setSetting(false);
      setTotalUnreadCount(0);
      await readBellNotification({ userId, organisationId });
      getAllNotificationData();
    }
  };

  return !isMobile ? (
    <Popover
      overlayClassName={classes.popoverContent}
      ref={popoverRef}
      placement="bottomRight"
      title={notificationTitle}
      content={content}
      trigger="click"
      onVisibleChange={onPopoverVisibleChange}
    >
      <div
        className={LiaCoPilotnotification ?? classes.notificationIcon}
        style={{ left: LiaCoPilotnotification ? '97vw' : undefined }}
      >
        <Badge
          count={totalUnreadCount > 0 ? totalUnreadCount : 0}
          overflowCount={99}
          className={classes.unReadCountStyle}
        >
          <BellOutlined
            className={classes.helpIcon}
            style={{
              color: popoverVisible
                ? '#40A9FF'
                : LiaCoPilotnotification
                ? '000000'
                : '#FFFFFF',
            }}
          />
        </Badge>
      </div>
    </Popover>
  ) : (
    <div
      className={LiaCoPilotnotification ?? classes.notificationIcon}
      style={{ left: LiaCoPilotnotification ? '90vw' : undefined }}
    >
      <Badge
        count={totalUnreadCount > 0 ? totalUnreadCount : 0}
        overflowCount={99}
        className={classes.unReadCountStyle}
      >
        <BellOutlined
          className={classes.helpIcon}
          style={{ color: notificationVisible ? '#40A9FF' : '#FFFFFF' }}
          onClick={() => {
            if (!notificationVisible) {
              getAllNotificationData();
            }
            setNotificationVisible(!notificationVisible);
          }}
        />
      </Badge>
      <Modal
        closable={false}
        style={{ marginTop: '-50px' }}
        footer={null}
        className={classes.modalNotification}
        title={notificationTitle}
        visible={notificationVisible && isMobile}
      >
        {content}
      </Modal>
    </div>
  );
};

export default NotificationIcon;
