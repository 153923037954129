import { CssType,
  ThemeType} from '../../../../theming/jssTypes';
const styles = (theme: ThemeType): CssType => ({
  appModal: {
    zIndex: 500,
    background: '#fff',
    position: 'fixed',
    overflow: 'hidden',
  },
  customModalIframe: {
    border: 'none',
  },
});

export default styles;
