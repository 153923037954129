import Icon from '@ant-design/icons';
import * as React from 'react';

const CustomerManagementSvg = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Setup_Tooltip" transform="translate(-16.000000, -554.000000)">
                <g id="Group-6" transform="translate(0.000000, 58.000000)">
                    <g id="Customer_Management" transform="translate(16.000000, 496.000000)">
                        <rect id="Rectangle-6" opacity="0.1" x="0" y="0" width="24" height="24"></rect>
                        <g transform="translate(1.500000, 1.500000)" fill="#666666">
                            <path d="M10.4999321,1.60941382 C9.97076462,1.60941382 9.54184614,2.03836139 9.54184614,2.56749012 C9.54184614,3.09660915 9.97076462,3.52555672 10.4999321,3.52555672 C11.0290027,3.52555672 11.4580181,3.09660915 11.4580181,2.56749012 C11.4580181,2.03836139 11.0290027,1.60941382 10.4999321,1.60941382 Z M7.93244202,2.56749012 C7.93244202,1.14949959 9.08190282,0 10.4999321,0 C11.9178645,0 13.0674319,1.14949959 13.0674319,2.56749012 C13.0674319,3.98547095 11.9178645,5.13497054 10.4999321,5.13497054 C9.08190282,5.13497054 7.93244202,3.98547095 7.93244202,2.56749012 Z" id="Shape"></path>
                            <path d="M10.5000291,6.0163379 C9.77841059,6.0163379 9.08636264,6.30299778 8.5761009,6.81325952 C8.26051036,7.12881128 8.03047083,7.51388777 7.90085485,7.9324808 L13.0991355,7.9324808 C12.9695098,7.51388777 12.7394412,7.12881128 12.4239573,6.81325952 C11.9136955,6.30299778 11.2215506,6.0163379 10.5000291,6.0163379 Z M7.43803203,5.67522943 C8.25010734,4.86315412 9.35152811,4.40692408 10.5000291,4.40692408 C11.6484331,4.40692408 12.7498151,4.86315412 13.5619874,5.67522943 C14.3740627,6.48731444 14.8302248,7.58872552 14.8302248,8.73716832 C14.8302248,8.95056108 14.7454882,9.15532505 14.594533,9.30618336 C14.4436747,9.45713862 14.2389107,9.54187523 14.0255179,9.54187523 L6.97443359,9.54187523 C6.53001238,9.54187523 6.16972668,9.18159922 6.16972668,8.73716832 C6.16972668,7.58872552 6.62594702,6.48731444 7.43803203,5.67522943 Z" id="Shape"></path>
                            <path d="M4.3303121,13.067461 C3.80119307,13.067461 3.37224551,13.4963795 3.37224551,14.025547 C3.37224551,14.5546176 3.80119307,14.983633 4.3303121,14.983633 C4.85944083,14.983633 5.2883787,14.5546176 5.2883787,14.025547 C5.2883787,13.4963795 4.85944083,13.067461 4.3303121,13.067461 Z M1.76283168,14.025547 C1.76283168,12.6075177 2.91233127,11.4580472 4.3303121,11.4580472 C5.74829294,11.4580472 6.89780222,12.6075177 6.89780222,14.025547 C6.89780222,15.4434794 5.74829294,16.5930468 4.3303121,16.5930468 C2.91233127,16.5930468 1.76283168,15.4434794 1.76283168,14.025547 Z" id="Shape"></path>
                            <path d="M1.26830535,17.1332669 C2.08039036,16.3211916 3.18180144,15.8650295 4.33026363,15.8650295 C5.47871613,15.8650295 6.5801369,16.3211916 7.39221221,17.1332669 C8.20429722,17.9453423 8.66054665,19.0468212 8.66054665,20.1952252 C8.66054665,20.6396561 8.30024155,20.9999321 7.85581065,20.9999321 L0.804706912,20.9999321 C0.591285066,20.9999321 0.386608347,20.9151955 0.235701563,20.7642403 C0.0847850837,20.613382 0,20.4087149 0,20.1952252 C0,19.0468212 0.456230038,17.9453423 1.26830535,17.1332669 Z M4.33026363,17.4744433 C3.60864513,17.4744433 2.91658749,17.7610353 2.40633544,18.271297 C2.09078368,18.5868779 1.86074415,18.9719738 1.73113786,19.3905183 L6.92938939,19.3905183 C6.79977341,18.9719738 6.56973388,18.5868779 6.25419182,18.271297 C5.74393007,17.7610353 5.05187243,17.4744433 4.33026363,17.4744433 Z" id="Shape"></path>
                            <path d="M16.6696103,13.067461 C16.1405398,13.067461 15.7116213,13.4963795 15.7116213,14.025547 C15.7116213,14.5546176 16.1405398,14.983633 16.6696103,14.983633 C17.1987778,14.983633 17.6276963,14.5546176 17.6276963,14.025547 C17.6276963,13.4963795 17.1987778,13.067461 16.6696103,13.067461 Z M14.1022075,14.025547 C14.1022075,12.6075177 15.251678,11.4580472 16.6696103,11.4580472 C18.0876396,11.4580472 19.2371101,12.6075177 19.2371101,14.025547 C19.2371101,15.4434794 18.0876396,16.5930468 16.6696103,16.5930468 C15.251678,16.5930468 14.1022075,15.4434794 14.1022075,14.025547 Z" id="Shape"></path>
                            <path d="M13.607749,17.1332669 C14.4198243,16.3211916 15.5212063,15.8650295 16.6697073,15.8650295 C17.8182083,15.8650295 18.9195903,16.3211916 19.7316656,17.1332669 C20.5437409,17.9453423 21,19.0468212 21,20.1952252 C21,20.6396561 20.639724,20.9999321 20.1952931,20.9999321 L13.1441215,20.9999321 C12.9307287,20.9999321 12.7260617,20.9151955 12.5751064,20.7642403 C12.4242481,20.613382 12.3394146,20.4087149 12.3394146,20.1952252 C12.3394146,19.0468212 12.7956737,17.9453423 13.607749,17.1332669 Z M16.6697073,17.4744433 C15.9480888,17.4744433 15.2560408,17.7610353 14.7457791,18.271297 C14.4301983,18.5868779 14.2002266,18.9719738 14.0706009,19.3905183 L19.2688137,19.3905183 C19.139188,18.9719738 18.9092163,18.5868779 18.5936355,18.271297 C18.0833737,17.7610353 17.3913258,17.4744433 16.6697073,17.4744433 Z" id="Shape"></path>
                            <path d="M10.4999321,10.5766508 C10.944363,10.5766508 11.304639,10.9369268 11.304639,11.3813577 L11.304639,13.1441506 C11.304639,13.5885815 10.944363,13.9488575 10.4999321,13.9488575 C10.0555012,13.9488575 9.69522522,13.5885815 9.69522522,13.1441506 L9.69522522,11.3813577 C9.69522522,10.9369268 10.0555012,10.5766508 10.4999321,10.5766508 Z" id="Path"></path>
                            <path d="M11.2197085,12.7842624 C11.4184614,13.1817682 11.2573262,13.6651741 10.8598203,13.863927 L9.09712438,14.7452265 C8.69961856,14.9439794 8.216203,14.782941 8.01745009,14.3854352 C7.81869718,13.9879294 7.97982277,13.5045235 8.37732859,13.3057706 L10.1401409,12.4243742 C10.5376467,12.2256213 11.0209556,12.3867565 11.2197085,12.7842624 Z" id="Path"></path>
                            <path d="M9.78025269,12.7842624 C9.9790056,12.3867565 10.4624115,12.2256213 10.8599173,12.4243742 L12.6227102,13.3057706 C13.020216,13.5045235 13.1813513,13.9879294 12.9825984,14.3854352 C12.7838455,14.782941 12.3004396,14.9439794 11.9029338,14.7452265 L10.1401409,13.863927 C9.74263506,13.6651741 9.58149977,13.1817682 9.78025269,12.7842624 Z" id="Path"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    );
};

export const CustomerManagementIcon = props => (
  <Icon component={CustomerManagementSvg} {...props} />
);
