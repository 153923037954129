import { getAllTmsDataSetup, downloadRouteMaster } from '../../api/TMSDashboard/Setup';
const viewControl = {
  pageTitle: 'Setup',
  buckets: ['tms_setup_routes'],
  globalActions: [],
  fetchData: getAllTmsDataSetup,
  downloadData: downloadRouteMaster,
  downloadWithGlobalAppliedFilters: true,
  filters: ['hub', 'route', 'is_active'],
  isActiveOptionList: [
    { id: 'true', label: 'Active' },
    { id: 'false', label: 'Inactive' },
  ],
  actions: [
    {
      key: 'updateOptions',
      name: 'Update Options',
      isAllowed: (masterData) =>
        masterData.ops_dashboard_config?.parts_to_show?.setup_permission_obj
          ?.transport?.route?.update,
    },
    {
      key: 'add',
      name: 'Add New',
      isAllowed: (masterData) =>
        masterData.ops_dashboard_config?.parts_to_show?.setup_permission_obj
          ?.transport?.route?.update,
    },
    {
      key: 'download',
      name: 'Download',
    },
    {
      key: 'pagination',
      name: 'Pagination',
    },
    {
      key: 'perPageCountChange',
      name: 'Pagination Count',
    },
    {
      key: 'reload',
      name: 'Reload',
    },
  ],
  updateOptions: [
    {
      key: 'update_route_hub',
      title: 'Update',
      action: 'update',
      type: 'bulk',
    },
    {
      key: 'enable_disable_route',
      title: 'Enable/Disable Route',
      action: 'enable_disable_route',
      type: 'bulk',
    },
  ],
};
const buckets = {
  tms_setup_routes: {
    name: 'Route',
    filters: [],
    actions: [],
  },
};

const bucketColor = {};

export default { viewControl, buckets, bucketColor };
