import React from 'react';
import { connect } from 'react-redux';
import withStyles, { WithStylesProps } from 'react-jss';
import { CssType, ThemeType } from 'src/theming/jssTypes';
import { Carousel, Modal } from 'antd';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import PdfRenderer from 'src/components/common/PdfRenderer';
import TextModal from 'src/components/common/TextModal';

const styles = (theme: ThemeType): CssType => ({
  btn: {
    color: 'blue',
    cursor: 'default',
  },
});

interface IProps extends WithStylesProps<ReturnType<typeof styles>>,ReturnType<typeof mapStateToProps> {
    record: any;
    reload: any;
}

const CustomerFeedbackCell = (props: IProps) => {
    const { record, classes, reload } = props;
    const [ modalVisible, setModalVisible ] = React.useState(false);

    const renderModal = () => {
        if (modalVisible) {
          return <TextModal
            title={'Customer Feedback'}
            text={record.customer_feedback}
            isVisible={modalVisible}
            onModalClose={(res: any) => {
              if (res) {
                reload();
              }
              setModalVisible(false);
            }}
          />
        }
        return <></>;
    };

    return record.customer_feedback ?
      <span
        onClick={() => {
          setModalVisible(true);
        }}
        className={classes.btn}
      >
        View
      </span>
      : '-';
};

const mapStateToProps = (
    { masterData },
) => {
    return {};
};

export default connect(mapStateToProps)(withStyles(styles, { injectTheme: true })(CustomerFeedbackCell));
