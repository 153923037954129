import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import Navbar from '@components/navbar/Navbar';
import { useSelector } from 'react-redux';
import {
  SearchOutlined,
  FilterOutlined,
  CalendarOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import {
  Input,
  Button,
  DatePicker,
  Pagination,
  Popover,
  Radio,
  Divider,
  message,
} from 'antd';
import moment from 'moment';
import { getNavbarFilters } from 'src/components/pages/GenericConsignment/NavbarFilters';
import FiltersMap from './FilterMap';
import { getAllAvailableAndBookedSlots } from 'src/api/dashboard';
import { EvDelivery } from 'src/assets/images/evDelivery';
import { SortIcon } from 'src/assets/images/SortIcon';

const useStyles = createUseStyles({
  slotContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '20px',
  },
  slot: {
    height: '32px',
    textAlign: 'center',
    cursor: 'pointer',
    backgroundColor: '#ffffff',
  },
  timeSlot: {
    marginRight: '25px',
    fontWeight: 400,
    fontSize: '12px',
    color: '#111',
    fontFamily: 'Open Sans',
  },
  booked: {
    backgroundColor: '#4ABFE5',
    margin: '2px 0px',
    height: '28px',
  },
  shift: {
    backgroundColor: '#ffffff',
  },
  outOfShift: {
    backgroundColor: '#EEEEEE',
  },
  riderContainer: {
    display: 'flex',
    borderBottom: '0.8px solid #ccc',
    borderRight: '0.8px solid #ccc',
  },
  spacer: {
    width: '1.5px',
    height: '30px',
    backgroundColor: '#ffffff',
  },
  filterWrapper: {
    display: 'flex',
    '& .ant-radio-button-wrapper ': {
      border: 'none',
      width: '100%',
    },
    '& .ant-radio-button-checked ': {
      background: '#e6f7ff',
    },
  },
  fontStyle: {
    fontWeight: 500,
    color: '#111',
    fontSize: '12px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
  },
  popoverContent: {
    '& .ant-popover-inner-content': {
      padding: '0px !important',
    },
  },
  filterDivider: {
    backgroundColor: '#0f1e37',
    height: '277px',
    opacity: 0.3,
    margin: '0px',
  },
  mainDiv: {
    display: 'flex',
    flexDirection: 'row',
    padding: '15px 8px',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    margin: '6px 6px 0px 6px',
    boxShadow: 'inset 0 -0.5px 0 0 rgba(15, 30, 55, 0.1)',
  },
  topBarLeft: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
  },
  topBarMiddle: {
    flex: 2,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '5px',
  },
  topBarRight: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: '7px',
    alignItems: 'center',
  },
  legendText: {
    color: '#1659CB',
    fontSize: '12px',
    fontWeight: 500,
    fontFamily: 'Open Sans',
    cursor: 'pointer',
  },
  bodyDiv: {
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 200px)',
    display: 'flex',
    flexDirection: 'row',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
  },
  bodyTopBar: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#93BFCF1A',
    borderBottom: '0.8px solid #ccc',
    padding: '8px',
    boxShadow: 'inset 0 -0.5px 0 0 #0F1E371A',
  },
  riderContainers: {
    width: '176px',
    borderRight: '0.8px solid #ccc',
    fontWeight: 600,
    fontSize: '12px',
    fontFamily: 'Open Sans',
    position: 'sticky',
    left: 0,
    zIndex: 1,
  },
  timeSlotTopBar: {
    display: 'flex',
    flexDirection: 'row',
    overflowY: 'auto',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    width: `calc(100% - 176px)`,
  },
  filterBody: {
    width: '500px',
    display: 'flex',
    flexDirection: 'column',
    height: '340px',
  },
  filterRadio: {
    width: '500px',
    height: '340px',
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #0F1E37',
  },
  radioGroup: {
    width: '194px',
    display: 'flex',
    flexDirection: 'column',
  },
  filterButtons: {
    width: '306px',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '8px',
  },
  applyCancel: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    padding: '6px 16px 16px',
  },
  workerDiv: {
    width: '182px',
    borderRight: '0.8px solid #ccc',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '8px',
    backgroundColor: '#FFFFFF',
    height: '32px',
    position: 'sticky',
    left: 0,
    zIndex: 2,
  },
  noDataDiv: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: '210px 500px',
    gap: '1rem',
  },
  bookedSlotsDiv: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
  },
});

const SlotManagement = (props) => {
  const classes = useStyles();
  const [data, setData] = useState({ workers: [] });
  const [selectedDate, setSelectedDate] = useState(moment());
  const [timeSlots, setTimeSlots] = useState([]);
  const [timeSlotLabelWidth, setTimeSlotLabelWidth] = useState(0);
  const [timeSlotWidth, setTimeSlotWidth] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const currHub = useSelector((state) => state.cachedData.currHub);
  const viewType = useSelector((state) => state.masterData.viewType);
  let pollingAgent: any = null;
  useEffect(() => {
    if (currHub.code && selectedDate) {
      fetchData();
      startPoller();
    }
    return () => stopPolling();
  }, [currHub, selectedDate]);
  useEffect(() => {
    const earliestStartTime = Math.min(
      ...data.workers.flatMap((worker) => [
        ...worker.booked_slots.map((slot) => slot.slot_start_time_seconds),
        ...worker.shifts.map(([start]) => start),
      ]),
    );
    const endTime = 23 * 3600 + 59 * 60 + 59;
    const length =  Math.floor((endTime - earliestStartTime) / 60) + 1;
    setTimeSlotWidth(100/length);
    setTimeSlotLabelWidth(Math.floor(100/(length/60)))
    const timeSlots = Array.from(
      { length },
      (_, i) => {
        const timeInSeconds = earliestStartTime + i * 60; // Convert minutes to seconds
        const hour = Math.floor(timeInSeconds / 3600)
          .toString()
          .padStart(2, '0');
        const minute = Math.floor((timeInSeconds % 3600) / 60)
          .toString()
          .padStart(2, '0');
        return `${hour}:${minute}`;
      },
    );
    setTimeSlots(timeSlots);
  }, [data]);

  const fetchData = async () => {
    setLoaded(false);
    const response = await getAllAvailableAndBookedSlots({
      hub_code: currHub.code,
      slot_date: selectedDate.format('YYYY-MM-DD'),
    });
    setLoaded(true);
    setData(response?.data ?? { workers: [] });
    if (!response.isSuccess) {
      message.error('Something went wrong');
    }
  };

  const pollApi = () => {
      pollingAgent = setTimeout(async () => {
        await fetchData();
        poller();
      }, 30 * 1000);
  };
  const poller = async () => {
    if (pollingAgent) {
      stopPolling();
      pollApi();
    }
  };
  const startPoller = () => {
    if (!pollingAgent) {
      pollApi();
    }
  };
  const stopPolling = () => {
    if (pollingAgent) {
      clearTimeout(pollingAgent);
      pollingAgent = undefined;
    }
  };

  const convertTo12HourFormat = (time24) => {
    const [hours, minutes] = time24.split(':').map(Number);

    const period = hours >= 12 ? 'PM' : 'AM';
    let hours12 = hours % 12;
    hours12 = hours12 === 0 ? 12 : hours12;

    return `${hours12} ${period}`;
  };

  const filterListRadio = [
    { name: 'Worker type', type: 'multiSelect', value: 'worker_type' },
    { name: 'Skill set', type: 'multiSelect', value: 'skill_set' },
    { name: '%Availability', type: 'number', value: 'availability' },
  ];
  const [selectedFilterType, setSelectedFilterType] = useState(
    filterListRadio[0],
  );
  const { title } = props;
  const onChangeDatePicker = (date) => {
    setSelectedDate(date);
  };

  const onClickToday = () => {
    setSelectedDate(moment());
  };
  const contentRider = (rider) => {
    return (
      <div>
        <p>Tasks: {rider.booked_slots.length}</p>
        <p>Worker type: {rider.worker_type} </p>
      </div>
    );
  };
  const contentSlot = (slot, rider) => {
    return (
      <div>
        <p>CN#: {slot.reference_number} </p>
        <p>Customer: {slot.customer_name}</p>
        <p>{`${rider.worker_type.toLowerCase() === 'helper' ? 'Rider' : 'Helper'}: `}
          {slot.paired_worker_code_list.join(", ")} </p> 
      </div>
    );
  };
  const onChangeRadio = (value) => {
    const index = filterListRadio.findIndex((item) => item.value === value);
    setSelectedFilterType(filterListRadio[index]);
  };
  const FilterComp = FiltersMap[selectedFilterType.type];
  const filterContent = (
    <div className={classes.filterBody}>
      <div className={classes.filterRadio}>
        <div className={classes.radioGroup}>
          <Radio.Group
            style={{ height: '48px' }}
            value={selectedFilterType.value}
            onChange={(e) => onChangeRadio(e.target.value)}
          >
            {filterListRadio.map((filter, index) => {
              return (
                <div className={classes.filterWrapper}>
                  <Radio.Button key={index} value={filter.value}>
                    {filter.name}
                  </Radio.Button>
                </div>
              );
            })}
            ;
          </Radio.Group>
        </div>
        <Divider className={classes.filterDivider} type="vertical" />
        <div className={classes.filterButtons}>
          <div
            style={{
              height: '48px',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '16px 12px',
            }}
          >
            <span className={classes.fontStyle}>Filters</span>
            <span
              className={classes.fontStyle}
              style={{ color: '#D40B00', fontSize: '10px' }}
            >
              Reset
            </span>
          </div>
          <FilterComp />
        </div>
      </div>
      <div className={classes.applyCancel}>
        <Button type="primary" style={{ marginTop: '8px' }}>
          Apply
        </Button>
        <Button type="default" style={{ marginTop: '8px' }}>
          Cancel
        </Button>
      </div>
    </div>
  );
  const legendData = [
    {
      data: 'EV Delivery',
      icon: <EvDelivery />,
    },
  ];
  const legendContent = (
    <div>
      {legendData.map((item, index) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '8px',
              alignItems: 'center',
            }}
            key={index}
          >
            {item.icon}
            <span className={classes.fontStyle} style={{ color: '#000' }}>
              {item.data}
            </span>
          </div>
        );
      })}
    </div>
  );

  const isToday = (selectedDate) => {
    if (!selectedDate) return false;
    const today = moment();
    return selectedDate.isSame(today, 'day');
  };
  const onClickRefresh = () => {
    const fetchData = async () => {
      setLoaded(false);
      const response = await getAllAvailableAndBookedSlots({
        hub_code: currHub.code,
        slot_date: selectedDate.format('YYYY-MM-DD'),
      });
      setLoaded(true);
      setData(response?.data ?? { workers: [] });
    };
    if (currHub.code && selectedDate) {
      fetchData();
    }
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Navbar
        pageTitle={title || 'Slot Management'}
        leftComponents={getNavbarFilters(['hub_search_with_type'], viewType)}
      />
      <div className={classes.mainDiv}>
        <div className={classes.topBarLeft}>
          {/* <Input placeholder="Search Rider" prefix={<SearchOutlined />} />
          <Popover
            overlayClassName={classes.popoverContent}
            content={filterContent}
            title={null}
            trigger="click"
          >
            <Button
              style={{ borderColor: '#1659CB', color: '#1659CB' }}
              icon={<FilterOutlined />}
            >
              Filters
            </Button>
          </Popover>
          <Button
            style={{ borderColor: '#1659CB', color: '#1659CB' }}
            icon={<SortIcon />}
          >
            Sort By
          </Button> */}
        </div>
        <div className={classes.topBarMiddle}>
          <DatePicker value={selectedDate} onChange={onChangeDatePicker} />
          {!isToday(selectedDate) && (
            <Button
              style={{
                color: '#1659CB',
                borderColor: '#1659CB',
              }}
              onClick={onClickToday}
            >
              Today
            </Button>
          )}
        </div>
        <div className={classes.topBarRight}>
          <Pagination simple defaultCurrent={1} total={1} />
          <Button icon={<ReloadOutlined />} onClick={onClickRefresh}>
            Refresh
          </Button>
          <Popover
            placement="bottomRight"
            content={legendContent}
            title={null}
            trigger="click"
          >
            <span className={classes.legendText}>Legends</span>
          </Popover>
        </div>
      </div>
      <div className={classes.bodyDiv}>
        {data.workers.length > 0 ? (
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div className={classes.bodyTopBar}>
              <div className={classes.riderContainers}>
                Rider ({data.workers?.length || 0})
              </div>
              <div className={classes.timeSlotTopBar}>
                {timeSlots.map((time, index) => (
                  <>
                    {index === 0 ? (
                      <>
                        <div key={index} className={classes.timeSlot} style={{width: `${timeSlotLabelWidth}%`}}>
                          {convertTo12HourFormat(time)}
                        </div>
                      </>
                    ) : (
                      <>
                        {index % 60 === 0 ? (
                          <div key={index} className={classes.timeSlot} style={{width: `${timeSlotLabelWidth}%`}}>
                            {convertTo12HourFormat(time)}
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </>
                ))}
              </div>
            </div>
            <div>
              {data.workers.map((rider, i) => (
                <div key={i} className={classes.riderContainer}>
                  <Popover content={() => contentRider(rider)} title={null}>
                    <div className={classes.workerDiv}>
                      <span className={classes.fontStyle}>
                        {' '}
                        {rider.worker_code}{' '}
                      </span>
                      <span className={classes.fontStyle}>
                        {' '}
                        {`${rider.availablity_percent} %`}
                      </span>
                    </div>
                  </Popover>
                  <div style={{ display: 'flex', overflowY: 'auto', width: `calc(100% - 182px)` }}>
                    {timeSlots.map((slot, j) => {
                      const [startHour, startMinute] = slot
                        .split(':')
                        .map(Number);
                      const timeInSec = startHour * 60 * 60 + startMinute * 60;

                      const bookedSlots = rider.booked_slots.filter(
                        ({ slot_start_time_seconds, slot_end_time_seconds }) =>
                          timeInSec >= slot_start_time_seconds &&
                          timeInSec < slot_end_time_seconds,
                      );
                      const isShift = rider.shifts.some(
                        ([start, end]) => timeInSec >= start && timeInSec < end,
                      );
                      let slotClass = classes.slot;
                      slotClass += isShift
                        ? ` ${classes.shift}`
                        : ` ${classes.outOfShift}`;
                      return (
                        <div key={j} className={classes.bookedSlotsDiv} style={{width: `${timeSlotWidth}%`}} >
                          {bookedSlots.map((bookedSlot, k) => {
                            let slotClass = classes.slot;
                            slotClass += ` ${classes.booked}`;
                            const ninetyPercentTime =
                              bookedSlot.slot_start_time_seconds +
                              (bookedSlot.slot_end_time_seconds -
                                bookedSlot.slot_start_time_seconds) *
                                0.9;
                            let leaf = false;
                            if (
                              bookedSlot.is_green &&
                              timeInSec === ninetyPercentTime
                            ) {
                              leaf = true;
                            }
                            if (
                              timeInSec === bookedSlot.slot_start_time_seconds
                            ) {
                              slotClass += ` ${classes.outOfShift}`;
                            }
                            return (
                              <Popover
                                content={() => contentSlot(bookedSlot, rider)}
                                title={null}
                              >
                              <div style={{ position: 'relative' }}>
                                <div key={k} className={slotClass} />
                                {leaf && (
                                  <EvDelivery
                                    width={13}
                                    height={13}
                                    style={{
                                      position: 'absolute',
                                      top: 9,
                                      right: 0,
                                      zIndex: 1,
                                    }}
                                  />
                                )}
                              </div>
                              </Popover>
                            );
                          })}
                          {bookedSlots.length === 0 && (
                            <div className={slotClass} />
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <>
            {loaded && (
              <div className={classes.noDataDiv}>
                <CalendarOutlined
                  style={{ color: '#666666', fontSize: '8em' }}
                />
                <div
                  className={classes.fontStyle}
                  style={{
                    fontWeight: 600,
                    fontSize: '1rem',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  {' '}
                  <span>
                    There are no slots to show for the selected date range.
                  </span>
                  <span>Pls Select any other Date </span>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SlotManagement;
