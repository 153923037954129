import React, { useEffect, useState } from 'react';
import GridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import useLayout from './hooks/useLayout';
import { SizeMe } from 'react-sizeme';
import { useDispatch, useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { getReportsReloadVisualizationData } from 'src/api/landingPageDashboard';
import {
  SET_LAST_UPDATED_TIME,
  SET_REFRESH_REPORTS_DATA,
} from 'src/actions/constants';
import ChartContainer from './DashboardPageParts/ChartContainer';
import { LAYOUT_VIEW_TYPES } from 'src/components/common/utils/constant';
import { isMobile } from 'react-device-detect';

const useStyle = createUseStyles({
  dashboardContainer: {
    minHeight: '80vh',
    maxWidth: '100%',
    height: 'auto',
    '& .react-grid-item': {
      transform: '0px 2px !important',
    },
    margin: '0 0.2rem',
    position: 'relative',
    zIndex: 0,
    backgroundColor: '#f2ede4',
  },
});

const DashboardPage = () => {
  const dashboardGroupMetaData =
    useSelector((state: any) => state.landingPageDashboardGroup) || {};
  const {
    lastUpdatedTime,
    autoReloadFrequency,
    reportList,
    dashboards,
    currentDashboardGroupTab,
    dashboardGroupId,
    dashboardGridColumns = 12,
  } = dashboardGroupMetaData;
  const dashboardItem = dashboards[currentDashboardGroupTab] || {};
  const { dashboardVisualisations } = dashboardItem;
  const dispatch = useDispatch();
  const [timeoutIds, setTimeoutIds] = useState<any>([]);
  const [selectedLayoutView, setSelectedLayoutView] = useState(
    LAYOUT_VIEW_TYPES.DESKTOP,
  );
  const { layout, chartData } = useLayout(
    dashboardVisualisations?.reports || [],
    dashboardVisualisations?.dashboard._id,
    selectedLayoutView,
  );
  const classes = useStyle();

  const reportIds = chartData.map((reports: any) => reports.reportId);

  let intervalId;
  let nextCallTime = 0; // Variable to track the time for the next API call
  const delay = autoReloadFrequency;

  // saving timeoutIds and the filters corresponding to it in order to clear all the timeouts
  const clearAllTimeouts = () => {
    timeoutIds.forEach((id) => clearTimeout(id.intervalId));
    dispatch({ type: SET_REFRESH_REPORTS_DATA, payload: {} });
    setTimeoutIds([]);
  };

  const MakeAPICallAndScheduleNext = async () => {
    try {
      const { data } = await getReportsReloadVisualizationData({
        dashboardGroupId,
        reportIds,
        globalFilters: [],
        autoReloadFrequency,
      });
      dispatch({ type: SET_REFRESH_REPORTS_DATA, payload: data });
      const now = Date.now();
      dispatch({ type: SET_LAST_UPDATED_TIME, payload: now });
      nextCallTime = now + delay;
      if (
        document.visibilityState === 'visible' &&
        window.location.pathname.endsWith('/home')
      ) {
        intervalId = setTimeout(MakeAPICallAndScheduleNext, delay);
        setTimeoutIds([{ intervalId }]);
      }
    } catch (error) {
      console.error('An error occurred during data refresh:', error);
    }
  };
  useEffect(() => {
    if (
      reportIds.length > 0 &&
      lastUpdatedTime !== 0 &&
      reportList.length === 0
    ) {
      clearAllTimeouts();
      const now = Date.now();
      const timeDelay = now - lastUpdatedTime; // Time since the last API call
      if (timeDelay > delay) {
        MakeAPICallAndScheduleNext();
      } else {
        intervalId = setTimeout(MakeAPICallAndScheduleNext, delay - timeDelay);
        setTimeoutIds([...timeoutIds, { intervalId }]);
      }

      return () => {
        clearTimeout(intervalId);
      };
    }
  }, [reportList.length]);

  useEffect(() => {
    if (isMobile) {
      setSelectedLayoutView(LAYOUT_VIEW_TYPES.MOBILE);
    } else {
      setSelectedLayoutView(LAYOUT_VIEW_TYPES.DESKTOP);
    }
  }, [isMobile]);

  return (
    <>
      <div className={classes.dashboardContainer}>
        <SizeMe>
          {({ size }) => {
            const rowHeight =
              dashboardGridColumns === 12
                ? 30
                : size.width / dashboardGridColumns;
            return (
              <GridLayout
                className="layout"
                layout={layout}
                autoSize={true}
                cols={dashboardGridColumns}
                rowHeight={rowHeight}
                containerPadding={[0, 0]}
                margin={[0, 0]}
                height={1000}
                width={size.width}
              >
                {chartData.map((chart: any, index) => {
                  let padding;
                  if (chart?.chartConfig?.chartPadding) {
                    const chartPadding = chart.chartConfig.chartPadding;
                    const paddingLeft = chartPadding?.chartPaddingLeft?.data;
                    const paddingRight = chartPadding?.chartPaddingRight?.data;
                    const paddingTop = chartPadding?.chartPaddingTop?.data;
                    const paddingBottom =
                      chartPadding?.chartPaddingBottom?.data;
                    padding = `${paddingTop}px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px`;
                  }
                  return (
                    <div
                      key={chart.reportId}
                      style={{ borderRadius: '8px', padding }}
                    >
                      <ChartContainer
                        dashboardGroupId={dashboardGroupId}
                        dashboardId={dashboardVisualisations?.dashboard._id}
                        dashboardTab={currentDashboardGroupTab}
                        chartReport={chart}
                        chartBorderRadius={
                          chart?.chartConfig?.chartBorderRadius
                        }
                        graphic={chart?.chartConfig?.graphic}
                        clearAllTimeouts={clearAllTimeouts}
                        {...chart}
                      />
                    </div>
                  );
                })}
              </GridLayout>
            );
          }}
        </SizeMe>
      </div>
    </>
  );
};

export default DashboardPage;
