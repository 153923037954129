import * as React from 'react';
import withStyles, { WithStylesProps } from 'react-jss';
import styles from './TripMapEditMetricTooltip.style';
import { MetricIcon } from 'src/components/common/icons/MetricIcon';
import { Col, Divider, Row } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

interface IProps extends WithStylesProps<typeof styles> {
  data: any;
  title: string;
  is_metric_breached: boolean;
}

const TripMapEditMetricTooltip = function (props: IProps) {
  const { classes, data, title, is_metric_breached } = props;

  const { rows } = data;

  const renderMetricColumn = ({ span, label, value }, idx) => (
    <Col key={idx} span={span} style={{ marginTop: '8px' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className={classes.greyMetricLabel}>{label}</div>
        <div className={classes.metricContent}>{value}</div>
      </div>
    </Col>
  );

  return (
    <div className={classes.metricContainer}>
      <div className={classes.metricHeader}>
        <span>
          <MetricIcon /> <span style={{ marginLeft: '10px' }}>{title}</span>
        </span>
        {is_metric_breached ? (
          <WarningOutlined
            style={{ color: 'red', marginLeft: '5px', fontSize: '16px' }}
          />
        ) : (
          <></>
        )}
      </div>
      <div className={classes.metricBody}>
        {rows.map((row, idx) => {
          switch (row.type) {
            case 'GRIDROW':
              const { columns } = row;
              return (
                <Row gutter={8} key={idx}>
                  {columns.map(renderMetricColumn)}
                </Row>
              );
            case 'SUBHEADING':
              const { text } = row;
              return (
                <div
                  key={idx}
                  className={classes.greyMetricHeading}
                  style={{
                    marginTop: '8px',
                  }}
                >
                  {text}
                </div>
              );
            case 'DIVIDER':
              return <Divider key={idx} className={classes.divider} />;
            default:
              return <></>;
          }
        })}
      </div>
    </div>
  );
};

export default withStyles(styles, { injectTheme: true })(
  TripMapEditMetricTooltip,
);
