export const orderTableColumns = [
  {
    column_id: 'order_no',
    pretty_name: 'Order Number',
  },
  {
    column_id: 'order_time',
    pretty_name: 'Order Time',
  },
  {
    column_id: 'customer_address',
    pretty_name: 'Customer Address',
  },
  {
    column_id: 'order_status',
    pretty_name: 'Order Status',
  },
  {
    column_id: 'carrier_name',
    pretty_name: 'Carrier Name',
  },
  {
    column_id: 'dispatch_status',
    pretty_name: 'Dispatch Status',
  },
];

export const riderTableColumns = [
  {
    column_id: 'rider_name',
    pretty_name: 'Rider Name',
  },
  {
    column_id: 'on_road_time',
    pretty_name: 'On Road Time',
  },
  {
    column_id: 'status',
    pretty_name: 'Status',
  },
  {
    column_id: 'expected_time',
    pretty_name: 'Expected Time of Return',
  },
];

export const columnsToShowInSideDrawerReports = [

  {
    column_id: 'avg_score',
    pretty_name: 'Score',
  },
  {
    column_id: 'delivered_count',
    pretty_name: 'Delivered Orders',
  },
  {
    column_id: 'dot_lt_20',
    pretty_name: '(DOT<20)%',
  },
  {
    column_id: 'dot_lt_30',
    pretty_name: '(DOT<30)%',
  },
  {
    column_id: 'avg_delivery_time',
    pretty_name: 'ADT',
  },
  {
    column_id: 'startbike_reachedgate_time',
    pretty_name: 'Start Bike to Reach Gate',
  },
  {
    column_id: 'reachedgate_delivered_time',
    pretty_name: 'Reached Gate to Delivered',
  },
  {
    column_id: 'delivered_reachedstore_time',
    pretty_name: 'Delivered to Reached Store',
  },
  {
    column_id: 'dt_lt_2',
    pretty_name: '#Order with drive time <2mins',
  },
  {
    column_id: 'dt_gt_25',
    pretty_name: '#Order with drive time >25mins',
  },
  {
    column_id: 'score_lt_10_per',
    pretty_name: '% Orders with score < 10',
  },
  {
    column_id: 'dph',
    pretty_name: 'DPH',
  },

];
export const columnsToShowFeedbacks = [
  {
    column_id: 'rating',
    pretty_name: 'Rating',
  },
  {
    column_id: 'reason_list',
    pretty_name: 'Feedback',
  },
  {
    column_id: 'customer_name',
    pretty_name: 'Customer',
  },
  {
    column_id: 'timestamp',
    pretty_name: 'Date and Time',
  },

];

export const columnsToShowKmReading = [
  {
    column_id: 'workdate',
    pretty_name: 'Date',
  },
  {
    column_id: 'start_km_reading',
    pretty_name: 'Start Km Reading',
  },
  {
    column_id: 'end_km_reading',
    pretty_name: 'End Km Reading',
  },
  {
    column_id: 'on_road_km_reading',
    pretty_name: 'On Road Reading',
  },
];

export const bulkRequestsColumns = [
  {
    column_id: 'csvId',
    pretty_name: 'CSV ID',
  },
  {
    column_id: 'created_at',
    pretty_name: 'Creation Time',
  },
  {
    column_id: 'request_type',
    pretty_name: 'Type Of Upload',
  },
  {
    column_id: 'employee_code',
    pretty_name: 'Uploaded By',
  },
  {
    column_id: 'total_count',
    pretty_name: 'Total Uploads',
  },
  {
    column_id: 'success_count',
    pretty_name: 'Successful Uploads',
  },
  {
    column_id: 'failure_count',
    pretty_name: 'Unsuccessful Uploads',
  },
  {
    column_id: 'status',
    pretty_name: 'Status',
  },
  {
    column_id: 'actions',
    pretty_name: 'Actions',
  },
];

export const requestTypeMapping = {
  add_order: 'Consignment Creation',
  mark_reachedathub: 'Ready to Dispatch',
};

export const summaryMetricsMapping = {
  in_store_orders: 'in_store_count',
  completed_orders: 'completed_count',
  ongoing_orders: 'ongoing_count',
  unassigned_orders: 'unassigned_count',
  undelivered_orders: 'undelivered_count',
  cancelled_orders: 'cancelled_count',
  // For enhanced screen for pickily when showEnhancedDispatchScreen is true
  in_store: 'in_store_count',
  completed: 'completed_count',
  ongoing: 'ongoing_count',
  unassigned: 'unassigned_count',
  undelivered: 'undelivered_count',
  cancelled: 'cancelled_count',
  rto: 'rto_count',
};

export const assignMultipleRidersModalColumns = [
  {
    column_id: 'order_number',
    pretty_name: 'Order No.',
  },
  {
    column_id: 'worker_name',
    pretty_name: 'Rider Name',
  },
  {
    column_id: 'actions',
    pretty_name: 'Action',
  },
];

export const summaryMetricsMappingRider = {
  in_store: 'in_store_count',
  out_for_delivery: 'out_for_delivery_count',
  returning_to_store: 'returning_to_store_count',
  outside_geofence: 'outside_geofence_count',
};

export const rosterTableColumns = [
  {
    pretty_name: 'Order No.',
    column_id: 'order_number',
  },
];

export const rosterTableCheckInColumns = [
  {
    pretty_name: 'Order No.',
    column_id: 'order_number',
  },
];
