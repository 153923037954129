import Icon from '@ant-design/icons';
import * as React from 'react';

const RosterManagementSvg = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Setup_Tooltip" transform="translate(-16.000000, -74.000000)">
                <g id="Group-6" transform="translate(0.000000, 58.000000)">
                    <g id="Roster_Management" transform="translate(16.000000, 16.000000)">
                        <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                        <g id="Rider_Management" transform="translate(1.500000, 1.500000)" fill="#666666">
                            <path d="M0.40061,3.51346 C0.65711,3.25696 1.00501,3.11285 1.36776,3.11285 L17.327,3.11285 C17.3271,3.11285 17.3271,3.11285 17.3271,3.11285 C17.6885,3.11285 18.0355,3.2549 18.293,3.50839 C18.5506,3.76192 18.6982,4.10656 18.704,4.46794 L18.7042,4.48062 L18.7042,4.48062 L18.7042,10.0636 C18.7042,10.5046 18.3466,10.8621 17.9056,10.8621 C17.4647,10.8621 17.1072,10.5046 17.1072,10.0636 L17.1072,4.70974 L1.59689,4.70974 L1.59689,16.3358 L10.0637,16.3358 C10.5047,16.3358 10.8621,16.6933 10.8621,17.1343 C10.8621,17.5752 10.5047,17.9327 10.0637,17.9327 L1.36776,17.9327 C1.00501,17.9327 0.65711,17.7886 0.40061,17.5321 C0.1441,17.2756 0,16.9277 0,16.5649 L0,4.48062 C0,4.11787 0.1441,3.76997 0.40061,3.51346 Z" id="Path"></path>
                            <path d="M7.48347,0 L11.2266,0 C11.7644,0.00394 12.279,0.21934 12.6593,0.5996 C13.0395,0.97987 13.2549,1.49448 13.2589,2.03225 L13.2589,2.03809 L13.2589,3.87462 C13.2589,4.31558 12.9014,4.67306 12.4604,4.67306 C12.0195,4.67306 11.662,4.31558 11.662,3.87462 L11.662,2.04178 C11.6606,1.92426 11.6133,1.81191 11.5301,1.72877 C11.447,1.64563 11.3346,1.59832 11.2171,1.59689 L7.48701,1.59689 C7.42803,1.59758 7.36975,1.60986 7.31548,1.63303 C7.26056,1.65648 7.21086,1.69062 7.16926,1.73345 C7.12765,1.77629 7.09498,1.82697 7.07315,1.88255 C7.05131,1.93813 7.04074,1.99749 7.04206,2.05719 L7.04226,2.07483 L7.04226,3.91135 C7.04226,4.35231 6.68478,4.70979 6.24382,4.70979 C5.80285,4.70979 5.44537,4.35231 5.44537,3.91135 L5.44537,2.08256 C5.44075,1.81453 5.48881,1.54817 5.58686,1.29861 C5.68611,1.04597 5.83462,0.8156 6.02372,0.62089 C6.21283,0.42617 6.43878,0.27101 6.6884,0.16442 C6.93803,0.05783 7.20636,0.00195 7.47779,0 L7.48347,0 Z" id="Path"></path>
                            <path d="M0,10.0636 C0,9.6227 0.35747,9.2652 0.79844,9.2652 L17.8965,9.2652 C18.3374,9.2652 18.6949,9.6227 18.6949,10.0636 C18.6949,10.5046 18.3374,10.8621 17.8965,10.8621 L0.79844,10.8621 C0.35747,10.8621 0,10.5046 0,10.0636 Z" id="Path"></path>
                            <path d="M7.77759,7.7313 C7.77759,7.29034 8.13506,6.93286 8.576,6.93286 L10.1279,6.93286 C10.5689,6.93286 10.9263,7.29034 10.9263,7.7313 C10.9263,8.17227 10.5689,8.5297 10.1279,8.5297 L8.576,8.5297 C8.13506,8.5297 7.77759,8.17227 7.77759,7.7313 Z" id="Path"></path>
                            <polygon id="Path" points="15.9958 14.2693 15.9958 12.5797 17.5927 12.5797 17.5927 14.2693"></polygon>
                            <polygon id="Path" points="15.9958 21.0002 15.9958 19.3198 17.5927 19.3198 17.5927 21.0002"></polygon>
                            <polygon id="Path" points="19.439 15.9961 21 15.9961 21 17.593 19.439 17.593"></polygon>
                            <polygon id="Path" points="18.103 14.3475 19.1682 13.2823 20.2974 14.4115 19.2322 15.4767"></polygon>
                            <polygon id="Path" points="14.3838 13.2548 15.449 14.32 14.3198 15.4491 13.2546 14.384"></polygon>
                            <polygon id="Path" points="19.26 18.1308 20.3344 19.2051 19.2052 20.3343 18.1309 19.2599"></polygon>
                            <polygon id="Path" points="13.218 19.2326 14.2924 18.1583 15.4216 19.2874 14.3472 20.3618"></polygon>
                            <polygon id="Path" points="12.5796 15.9961 14.2692 15.9961 14.2692 17.593 12.5796 17.593"></polygon>
                            <path d="M16.7944,15.0678 C16.4528,15.0678 16.119,15.169 15.835,15.3588 C15.5511,15.5485 15.3297,15.8182 15.199,16.1337 C15.0683,16.4493 15.0341,16.7965 15.1008,17.1314 C15.1674,17.4664 15.3319,17.7741 15.5734,18.0156 C15.8148,18.2571 16.1225,18.4215 16.4575,18.4881 C16.7924,18.5548 17.1396,18.5206 17.4552,18.3899 C17.7707,18.2592 18.0404,18.0379 18.2301,17.7539 C18.4199,17.4699 18.5211,17.1361 18.5211,16.7945 C18.5211,16.3366 18.3392,15.8974 18.0154,15.5735 C17.6915,15.2497 17.2523,15.0678 16.7944,15.0678 Z M14.9478,14.031 C15.4944,13.6658 16.137,13.4709 16.7944,13.4709 C17.6759,13.4709 18.5212,13.8211 19.1445,14.4444 C19.7679,15.0677 20.118,15.9131 20.118,16.7945 C20.118,17.4519 19.9231,18.0945 19.5579,18.6411 C19.1927,19.1876 18.6736,19.6136 18.0663,19.8652 C17.459,20.1168 16.7907,20.1826 16.146,20.0543 C15.5012,19.9261 14.909,19.6096 14.4442,19.1447 C13.9794,18.6799 13.6628,18.0877 13.5346,17.443 C13.4063,16.7982 13.4721,16.13 13.7237,15.5226 C13.9753,14.9153 14.4013,14.3962 14.9478,14.031 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    );
};

export const RosterManagementIcon = props => (
  <Icon component={RosterManagementSvg} {...props} />
);
