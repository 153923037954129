const responseModelBookingMaster = {
  isNextPresent: 'isNextPresent',
  lastItemIdNext: 'lastIdNext',
  lastItemIdPrev: 'lastIdPrev',
  lastSortedColumnValueNext: 'lastPrimarySortFieldNext',
  lastSortedColumnValuePrev: 'lastPrimarySortFieldValuePrev',
  page_data: 'data',
};

const getLastSortedColumnValue = (pagination) => {
  switch (pagination.nextOrPrev) {
    case 'prev':
      return pagination.lastSortedColumnValuePrev;
    case 'next':
      return pagination.lastSortedColumnValueNext;
    default:
      return null;
  }
};

const getLastRowId = (pagination) => {
  switch (pagination.nextOrPrev) {
    case 'prev':
      return pagination.lastItemIdPrev;
    case 'next':
      return pagination.lastItemIdNext;
    default:
      return null;
  }
};

const requestModelBookingMaster = {
  metadataType: 'bucket',
  nextOrPrev: 'pagination.nextOrPrev',
  lastId: {
    path: 'pagination',
    fn: (value) => {
      return getLastRowId(value) ? getLastRowId(value) : undefined;
    },
  },
  lastPrimarySortFieldValue: {
    path: 'pagination',
    fn: (value) => {
      return getLastSortedColumnValue(value)
        ? getLastSortedColumnValue(value)
        : undefined;
    },
  },
  chargeId: 'appliedFilters.charge.id',
  courierPartnerIds: 'appliedFilters.courierPartner',
  contractCodes: 'appliedFilters.contractCode',
  hubIds: 'appliedFilters.hubIdList',
  clientIds: 'appliedFilters.customerCodes',
  serviceTypes: 'appliedFilters.serviceType',
  property_currentHubId: {
    path: 'appliedFilters.property_currentHubId',
    fn : (value) => {
      return value && value.code ? value.code : undefined;
    },
  },
  property_destinationHubId: {
    path: 'appliedFilters.property_destinationHubId',
    fn : (value) => {
      return value && value.code ? value.code : undefined;
    },
  },
  next_hub: {
    path: 'appliedFilters.next_hub',
    fn : (value) => {
      return value && value.code ? value.code : undefined;
    },
  },
  property_serviceType: 'appliedFilters.property_serviceType',
  isActive: {
    path: 'appliedFilters.is_active',
    fn: (value) => {
      if (value) return value;
      return '';
    },
  },
  courierAccount: 'appliedFilters.courierAccount',
};

export { responseModelBookingMaster, requestModelBookingMaster};
