import React from 'react';
import { ViewFilterType } from 'src/types/view.types';
import consignmentfilters from './GenericData/consignment/filtersData';
import customerfilters from './GenericData/customer/filtersData';
import GenericViewFilterComponent from './components/GenericViewFilterComponent';

const dataMap = {
    consignment: consignmentfilters,
    customer: customerfilters,
};

export const filterResolver = (
    filterData: ViewFilterType,
    objectType,
    params,
) => {
    const data = dataMap[objectType] || {};
    const filterId = filterData.id;

    if (data[filterId]) {
        return data[filterId](filterData, params);
    }

    return <GenericViewFilterComponent objectType={objectType} filterData={filterData} params={params} />;
};
