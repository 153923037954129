import React, { useEffect } from 'react';
import withStyles, { WithStylesProps } from 'react-jss';
import { connect } from 'react-redux';
import styles from './GenericView.styles';
import { fetchViewData, fetchViewDataObjects, setSelectedRowKeys } from 'src/actions/genericPageActions';
import { bindActionCreators } from 'redux';
import { PageData } from 'src/types/page.types';
import Loader from 'src/components/common/Loader';
import FixedHeaderTable from 'src/components/common/FixedHeaderTable/FixedHeaderTable';
import { columnRenderResolver } from './GenericViewColumnResolver';
import { tableActionResolver } from './GenericViewTableActionsResolver';
import { filterResolver } from './GenericViewFiltersResolver';
import { actionResolver } from './GenericViewActionsResolver';
import GenericViewMoreFilters from './components/GenericViewMoreFilters';
import { Button, Dropdown, Menu } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import SimplePaginator from 'src/components/common/SimplePaginator';
import { createSelector } from 'reselect';
import EllipsisIcon from 'src/components/common/icons/EllipsisIcon';

interface IProps extends
    WithStylesProps<ReturnType<typeof styles>>,
    ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {
    viewId: string;
    pageId: string;
    viewIndex: number;
    sectionIndex: number;
}

const GenericView = (props: IProps) => {
    const {
        viewId,
        viewData,
        pageId,
        sectionIndex,
        viewIndex,
        classes,
        fetchViewData,
        fetchViewDataObjects,
        masterData,
        setSelectedRowKeys,
    } = props;

    const extraProps = {};
    switch(viewData.object_type){
        case 'consignment':
            extraProps['rowKey'] = (record:any) => record['reference_number']
    };

    const renderFilters = () => {
        const filterData = viewData.getMainDashboardFilters();
        return <div className={classes.filterParent}>
            <div className={classes.filterContainer}>
                {
                    filterData.map((filter) => {
                        const node = filterResolver(
                            filter,
                            viewData.object_type,
                            {
                                pageId,
                                viewIndex,
                                sectionIndex,
                            },
                        );

                        return  <div className={classes.filterNodeWrapper} key={filter.id}>
                            {node}
                        </div>;
                    })
                }
            </div>
            <div style={{
                marginBottom: '8px',
            }}>
                <GenericViewMoreFilters
                    pageId={pageId}
                    sectionIndex={sectionIndex}
                    viewIndex={viewIndex}
                />
            </div>
        </div>;
    };

    const renderActions = () => {
        const actionData = viewData.getActionList();
        return <div className={classes.actionsContainer}>
            {
                actionData.map((action) => {
                    const node = actionResolver(
                        action,
                        viewData.object_type,
                        {
                            pageId,
                            viewIndex,
                            sectionIndex,
                            reload: handleReload,
                        },
                    );

                    return  <div className={classes.actionNodeWrapper} key={action.id}>
                        {node}
                    </div>;
                })
            }
        </div>;
    };

    const handleReload = () => {
        fetchViewDataObjects({
            viewIndex,
            sectionIndex,
            pageId,
            viewData,
        });
    };

    const handleFirstPageClick = () => {
        viewData.pagination.pageNumber = 1;
        fetchViewDataObjects({
            viewData,
            sectionIndex,
            pageId,
            viewIndex,
        });
    };
    const handlePrevPageClick = () => {
        viewData.pagination.pageNumber = viewData.pagination.pageNumber - 1;
        fetchViewDataObjects({
            viewData,
            sectionIndex,
            pageId,
            viewIndex,
        });
    };
    const handleNextPageClick = () => {
        viewData.pagination.pageNumber = viewData.pagination.pageNumber + 1;
        fetchViewDataObjects({
            viewData,
            sectionIndex,
            pageId,
            viewIndex,
        });
    };

    const renderReloadPagination = () => {
        return <div className={classes.reloadPaginationContainer}>
            <Button onClick={handleReload} style={{
                marginRight: '8px',
            }}>
                <ReloadOutlined />
            </Button>
            <SimplePaginator
                onFirstClick={handleFirstPageClick}
                onPrevClick={handlePrevPageClick}
                onNextClick={handleNextPageClick}
                isNext={viewData.pagination.isNextPresent}
                pageNumber={viewData.pagination.pageNumber}
            />
        </div>;
    };

    const renderFiltersAndActions = () => {
        return <div className={classes.filterActionsContainer}>
            {renderFilters()}
            <div className={classes.actionPaginationContainer}>
                {renderActions()}
                {renderReloadPagination()}
            </div>
        </div>;
    };

    const getColumnsForTable = () => {
        const columns: any[] = [];
        const fieldList = viewData.metadata?.fieldList || [];
        const tableActions = viewData.metadata?.tableActions || [];
        for (const field of fieldList) {
            columns.push({
                title: field.name,
                key: field.id,
                dataIndex: field.id,
                width: field?.width || 100,
                render: (x, record) => columnRenderResolver(
                    field,
                    x,
                    record,
                    viewData.object_type,
                    {
                        reload: handleReload,
                        masterData,
                    },
                ),
            });
        }
        if (tableActions.length) {
            columns.push({
                title: 'Actions',
                key: 'actions',
                width: '60px',
                fixed: 'right',
                render: (x, record) => {
                    return <Dropdown
                        overlayStyle={{
                            width: '150px',
                            boxShadow: '0 1px 4px 0 rgba(0,0,0,0.2)',
                        }}
                        overlay={
                            <Menu>
                                {tableActions.map((tableAction) => {
                                    const node = tableActionResolver(
                                        tableAction.id,
                                        record,
                                        viewData.object_type,
                                        {
                                            pageId,
                                            viewIndex,
                                            sectionIndex,
                                            reload: handleReload,
                                        },
                                    );

                                    return <Menu.Item key={tableAction.id}>
                                        {node}
                                    </Menu.Item>;
                                })}
                            </Menu>
                        }
                        trigger={['click']}
                    >
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <EllipsisIcon className={classes.ellipsisIcon}/>
                        </div>
                    </Dropdown>
                },
            });
        }
        return columns;
    };

    const rowSelection =  createSelector(
        (p:any) => p.selectedRowKeys,
        (p:any) => p.setSelectedRowKeys,
        (selectedRowKeys, setSelectedRowKeys)  => {
            return {
                selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    setSelectedRowKeys({
                        viewId,
                        pageId,
                        sectionIndex,
                        viewIndex,
                        keys: selectedRowKeys,
                    });
                },
            };
    });

    const renderDataTable = () => {
        const selectedRowKeys = viewData.selectedRowKeys;
        return  <div className={classes.tableContainer}>
            <FixedHeaderTable
                locale={{ emptyText: 'No data found' }}
                dataSource={viewData.dataObjects}
                pagination={false}
                columns={getColumnsForTable()}
                rowKey={(record, index) => index}
                resizable={true}
                loading={viewData.isFetchingData}
                rowSelection={rowSelection({ selectedRowKeys, setSelectedRowKeys })}
                {...extraProps}
            />
        </div>;
    };

    const renderViewData = () => {
        return <>
            {renderFiltersAndActions()}
            {renderDataTable()}
        </>;
    };

    return <div className={classes.container}>
        {
            viewData.isLoading ?
            <div className={classes.loadingContainer}>
                <Loader className={classes.loader}/>
            </div> :
            renderViewData()
        }
    </div>;
};

const mapStateToProps = ({ genericPageReducer, masterData }, ownProps) => {
    const { pageId, viewIndex, sectionIndex } = ownProps;
    const pageData: PageData = genericPageReducer[pageId];
    const viewData = pageData.metadata.sectionList[sectionIndex][viewIndex];
    return {
        viewData,
        masterData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchViewData,
        fetchViewDataObjects,
        setSelectedRowKeys,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(
    withStyles(styles, { injectTheme: true })(GenericView),
);
