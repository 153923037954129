import React from 'react';
import MultiSelectList from './MultiSelectList';
import Number from './NumberSlider';

const FilterComponentMap = {
    multiSelect: MultiSelectList,
    number: Number,
};

export default FilterComponentMap;
