import Icon from '@ant-design/icons';
import * as React from 'react';

const LogoutSvg = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Menu" transform="translate(-24.000000, -704.000000)">
                <g id="Logout" transform="translate(24.000000, 704.000000)">
                    <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                    <g id="Group-8" transform="translate(1.100000, 1.500000)" fill="#666666">
                        <path d="M9.44437673,11.3023823 L20.8877285,11.3023823 C21.3317729,11.3023823 21.6924377,10.9417175 21.6924377,10.4976731 C21.6924377,10.0526593 21.3317729,9.69296399 20.8877285,9.69296399 L9.44437673,9.69296399 C9.00033241,9.69296399 8.63966759,10.0526593 8.63966759,10.4976731 C8.63966759,10.9417175 9.00033241,11.3023823 9.44437673,11.3023823" id="Fill-1"></path>
                        <path d="M11.502687,14.3496122 C11.7072576,14.3496122 11.9127978,14.2720499 12.068892,14.1159557 C12.3849584,13.8037673 12.3868975,13.293795 12.0737396,12.9777285 L9.57429363,10.4569529 L12.0456233,8.02828255 C12.3626593,7.71706371 12.3665374,7.20806094 12.054349,6.89102493 C11.7431302,6.57398892 11.235097,6.56914127 10.9170914,6.88132964 L7.86889197,9.87620499 C7.7166759,10.0255125 7.63038781,10.2300831 7.62844039,10.444349 C7.62747922,10.658615 7.71085873,10.8641551 7.86210526,11.0163712 L10.9316343,14.111108 C11.0886981,14.2701108 11.2952078,14.3496122 11.502687,14.3496122" id="Fill-4"></path>
                        <path d="M10.5,21 C14.2714681,21 17.7724377,18.9601108 19.6368421,15.6753463 C19.8559557,15.2894737 19.7211911,14.7979224 19.334349,14.5788089 C18.9455679,14.3577562 18.4559557,14.4954294 18.2368421,14.8813019 C16.6584488,17.6628809 13.6936288,19.3905817 10.5,19.3905817 C5.59709141,19.3905817 1.60941828,15.4019391 1.60941828,10.5 C1.60941828,5.59806094 5.59709141,1.60941828 10.5,1.60941828 C13.7198061,1.60941828 16.6972299,3.35941828 18.2688366,6.17493075 C18.484072,6.56274238 18.9765928,6.70235457 19.3634349,6.48614958 C19.7512465,6.2699446 19.8908587,5.77936288 19.6736842,5.39155125 C17.8180055,2.06606648 14.3034626,0 10.5,0 C4.7099723,0 0,4.7099723 0,10.5 C0,16.2900277 4.7099723,21 10.5,21" id="Fill-6"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    );
};

export const LogoutIcon = props => (
  <Icon component={LogoutSvg} {...props} />
);
