export const columnsToShow = [
  {
    column_id: 'organisation_reference_number',
    pretty_name: 'Trip #',
  },
  {
    pretty_name: 'Creation Time',
    column_id: 'created_at',
  },
  { pretty_name:'Trip Type', column_id:'trip_type' },
  {
    pretty_name: 'Vehicle#',
    column_id: 'vehicle_registration_number',
  },
  {
    pretty_name: 'Vehicle Type',
    column_id: 'vehicle_type',
  },
  {
    pretty_name: 'Freight Type',
    column_id: 'freight_type',
  },
  {
    pretty_name: 'Driver',
    column_id: 'worker_name',
  },
  {
    pretty_name: '#Tasks',
    column_id: 'task',
  },
  {
    pretty_name: 'Route',
    column_id: 'route',
  },
  { pretty_name:'Planning Hub', column_id:'primary_hub_name' },
  { pretty_name:'Primary Trip #', column_id:'primary_organisation_reference_number' },
  {
    pretty_name: 'Hub',
    column_id: 'hub_name',
  },
  {
    pretty_name: 'Status',
    column_id: 'status',
  },
  {
    pretty_name: 'Scheduled At',
    column_id: 'scheduled_at',
  },
  {
    pretty_name: 'Vehicle Make',
    column_id: 'vehicle_make',
  },
  // { pretty_name:'# Pending Delivery', column_id:'incomplete_delivery_task_count' },
  // { pretty_name:'# Pending Pickup', column_id:'incomplete_pickup_task_count' },
  // { pretty_name:'# Delivered', column_id:'delivered_task_count' },
  // { pretty_name:'# Undelivered', column_id:'attempted_task_count' },
  // { pretty_name:'# Pick Up Completed', column_id:'pickedup_task_count' },
  // { pretty_name:'# Not Picked Up', column_id:'notpickedup_task_count' },
  // { pretty_name:'Planning Hub', column_id:'primary_hub_id' },
  {
    pretty_name: 'Last Updated',
    column_id: 'last_main_event_time',
  }, {
    pretty_name: 'Started At',
    column_id: 'start_time',
  }, {
    pretty_name: 'Assigned At',
    column_id: 'assigned_time',
  }, {
    pretty_name: 'Completed At',
    column_id: 'end_time',
  },
  {
    pretty_name: 'Created By',
    column_id: 'created_by',
  },
  {
    pretty_name: 'Planned Distance (km)',
    column_id: 'estimated_distance_metres',
  },
   {
     pretty_name: 'Destination Pincodes',
     column_id: 'destination_pincodes',
   },
];
export const detailColumnsToShow = [
  {
    column_id: 'reference_number',
    pretty_name: 'CN #',
  },
  {
    pretty_name: 'Receiver Address',
    column_id: 'receiver_address',
  },
  {
    pretty_name: 'Destination Pincode',
    column_id: 'destination_pincode',
  },
  {
    pretty_name: 'Customer',
    column_id: 'customer_name',
  },
  {
    pretty_name: 'Status',
    column_id: 'status',
  },
  {
    pretty_name: 'Number of Pieces',
    column_id: 'no_of_pieces',
  },
  {
    pretty_name: 'Consignment Type',
    column_id: 'consignment_type',
  },
  {
    pretty_name: 'Weight(in Kgs)',
    column_id: 'weight',
  },
  {
    pretty_name: 'Actions',
    column_id: 'actions',
  },
];

export const consignmentsColumnsToShow = [
  {
    column_id: 'reference_number',
    pretty_name: 'CN #',
  },
  // {
  //   pretty_name: 'Hub Arrival Time',
  //   column_id: 'hub_arrival_time',
  // },
  {
    pretty_name: 'Creation Time',
    column_id: 'created_at',
  },
  {
    pretty_name: 'Status',
    column_id: 'status',
  },
  {
    pretty_name: 'Trip Assigned',
    column_id: 'trip_assigned',
  },
  {
    pretty_name: 'Destination Pincode',
    column_id: 'destination_pincode',
  },
  {
    pretty_name: 'Customer Name',
    column_id: 'destination_name',
  },
  {
    pretty_name: 'Destination Address',
    column_id: 'destination_address',
  },
  {
    pretty_name: 'Last Updated',
    column_id: 'updated_at',
  },
  {
    pretty_name: 'Number of Pieces',
    column_id: 'no_of_pieces',
  },
  {
    pretty_name: 'Consignment Type',
    column_id: 'consignment_type',
  },
  {
    pretty_name: 'Weight(in Kgs)',
    column_id: 'weight',
  },
];
export const pieceDetailColumns = [
  {
    pretty_name: 'Detail',
    column_id: 'description',
  },
  {
    pretty_name: 'Status',
    column_id: 'status',
  },
  {
    pretty_name: 'NDR',
    column_id: 'delivery_failure_reason',
  },
];

const ColumnsToShowQcCheckModal = [
  {
    column_id: 'serial_number',
    pretty_name: 'S.N.',
  },
  {
    column_id: 'reference_number',
    pretty_name: 'CN#',
  },
  {
    column_id: 'weight',
    pretty_name: 'Weight (Kg)',
  },
  {
    column_id: 'length',
    pretty_name: 'Length (Cm)',
  },
  {
    column_id: 'width',
    pretty_name: 'Width (Cm)',
  },
  {
    column_id: 'height',
    pretty_name: 'Height (Cm)',
  },
  {
    column_id: 'action',
    pretty_name: 'Action',
  },
  {
    column_id: 'is_cnv_done',
    pretty_name: 'CN Verification',
  },
];
export const ColumnsToShowTripReconciliation = [
  {
    column_id: 'serial_number',
    pretty_name: 'S.N.',
  },
  {
    column_id: 'reference_number',
    pretty_name: 'CN#',
  },
  {
    column_id: 'task_type',
    pretty_name: 'Consignment Type',
  },
  {
    column_id: 'address',
    pretty_name: 'Delivery Address',
  },
  {
    column_id: 'customer_name',
    pretty_name: 'Customer Name',
  },
  {
    column_id: 'scan_status',
    pretty_name: 'Scanning Status',
  },
];

export const columnsToShowTripDetailsTracking = [
  {
    pretty_name: 'Event Time',
    column_id: 'event_time',
  },
  {
    pretty_name: 'Location',
    column_id: 'hub_code',
  },
  {
    pretty_name: 'Updated By',
    column_id: 'employee_code',
  },
  {
    pretty_name: 'Event',
    column_id: 'event_string',
  },
];

export const columnsToShowStatusHistory = [
  {
    pretty_name: 'Status',
    column_id: 'status',
  },
  {
    pretty_name: 'Time',
    column_id: 'event_time',
  },
];
const qcStatusCol = [
  {
    column_id: 'qc_status',
    pretty_name: 'QC',
  },
];
const RtoCols = [
  {
    column_id: 'mark_as_rto',
    pretty_name: 'Mark RTO',
  },
  {
    column_id: 'rto_reason',
    pretty_name: 'RTO Reason',
  },
];

export const getColsQcCheckModal = (showQcStatus, showRtoCols) => {
  let columns = ColumnsToShowQcCheckModal;
  if(showQcStatus){
    columns = columns.concat(qcStatusCol);
  }
  if(showRtoCols){
    columns = columns.concat(RtoCols);
  }
  return columns;
};
