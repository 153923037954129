import { CssType, ThemeType } from '../../../../../../theming/jssTypes';

const styles = (theme: ThemeType): CssType => ({
  mainDiv: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 0',
    overflow: 'auto',
    '& .ant-table-tbody td .editLink': {
      opacity: 0,
      marginLeft: 8,
      fontSize: 16,
    },
    '& .ant-table-tbody td:hover .editLink': {
      opacity: 1,
    },
  },
  expandableTableContainer: {
    '& .ant-table-expanded-row-fixed': {
      overflow: 'auto !important',
    },
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  statusCircle: {
    height: '12px',
    width: '12px',
    borderRadius: '50%',
    marginRight: '5px',
    display: 'inline-block',
  },
  metricsBreachedRow: {
    '& td': {
      backgroundColor: 'rgb(236, 140, 140) !important',
    },
  },
});

export default styles;
