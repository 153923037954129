import * as moment from 'moment';
import { useTranslation } from 'react-i18next';

export const defaultColumnsForSupplementaryServices = [
    {
        pretty_name: 'Supplementary Request Reference Number',
        id: 'supplementary_reference_number',
        key: 'supplementary_reference_number',
    },
    {
        pretty_name: 'Status',
        id: 'supplementary_status',
        key: 'supplementary_status',
    },
    {
        pretty_name: 'Hub Code',
        id: 'supplementary_hub_code',
        key: 'supplementary_hub_code',
    },
    {
        pretty_name: 'Fulfilled By',
        id: 'supplementary_fulfilled_by',
        key: 'supplementary_fulfilled_by',
    },
    {
        pretty_name: 'Requested By',
        id: 'supplementary_requested_by',
        key: 'supplementary_requested_by',
    },
    {
        pretty_name: 'Service Code',
        id: 'supplementary_service_code',
        key: 'supplementary_service_code',
    },
    {
        pretty_name: 'Service Name',
        id: 'supplementary_service_name',
        key: 'supplementary_service_name',
    },
    {
        pretty_name: 'Quantity',
        id: 'supplementary_quantity',
        key: 'supplementary_quantity',
    },
    {
        pretty_name: 'Actual Quantity',
        id: 'supplementary_actual_quantity',
        key: 'supplementary_actual_quantity',
    },
    {
        pretty_name: 'Remarks',
        id: 'supplementary_remarks',
        key: 'supplementary_remarks',
    },
    {
        pretty_name: 'Is Fulfilled',
        id: 'supplementary_is_fulfilled',
        key: 'supplementary_is_fulfilled',
    },
    {
        pretty_name: 'Created At',
        id: 'supplementary_created_at',
        key: 'supplementary_created_at',
    },
    {
        pretty_name: 'Last Updated At',
        id: 'supplementary_last_updated_at',
        key: 'supplementary_last_updated_at',
    },
];
export const getColumnsForSupplementaryServices = (columnsToShow) => {
    const columns = columnsToShow?.map((col) => {
        const {t} = useTranslation();
        return (
            col && {
                dataIndex: col.id,
                title: t(col.pretty_name),
                width: 80,
                render: (x, record, index) => {
                    switch (col.key) {
                        case 'supplementary_reference_number':
                            return record.reference_number;
                        case 'supplementary_status':
                            return t(record.status);
                        case 'supplementary_hub_code':
                            return record.hub_code;
                        case 'supplementary_fulfilled_by':
                            return record.supplementary_service &&
                                record.supplementary_service.fulfilled_by
                                ? t(record.supplementary_service.fulfilled_by) : '';
                        case 'supplementary_requested_by':
                            return record.requested_by || '';
                        case 'supplementary_service_code':
                            return record.supplementary_service
                                ? record.supplementary_service.service_code
                                : '';
                        case 'supplementary_service_name':
                            return record.supplementary_service
                                ? t(record.supplementary_service.service_name)
                                : '';
                        case 'supplementary_quantity':
                            return record.supplementary_service
                                ? record.supplementary_service.quantity
                                : '';
                        case 'supplementary_actual_quantity':
                            return record.supplementary_service
                                ? record.supplementary_service.actual_quantity
                                : '';
                        case 'supplementary_remarks':
                            return record.supplementary_service
                                ? t(record.supplementary_service.remarks)
                                : '';
                        case 'supplementary_is_fulfilled':
                            return t(record.is_fulfilled);
                        case 'supplementary_created_at':
                            return record.created_at ? moment(record.created_at).format('DD/MM/YYYY, HH:mm') : null;
                        case 'supplementary_last_updated_at':
                            return record.updated_at ? moment(record.updated_at).format('DD/MM/YYYY, HH:mm') : null;
                        default:
                            return x;
                    }
                },
            }
        );
    });
    return columns.filter((column) => column);
};
