import { GetChartConfigData } from '../utils';

const LineConfigs = (chartConfig, formData, chartsFromDashboard) => {
  const configObject = {
    chartConfig,
    configType: 'line',
    formData,
    chartsFromDashboard,
  };
  const configData = GetChartConfigData(configObject);
  if (!configData) {
    return null;
  }
  const {
    yAxis,
    xAxis,
    graphicLabel: label,
    legend,
    point,
  } = configData['line'] || {};
  const { tooltip, color, columnSize, smooth } = configData['graphic'] || {};
  const { offsetX, offsetY, labelSize } = configData['graphicLabel'] || {};
  const {
    location,
    flipPage,
    offsetX: legendOffsetX,
    offsetY: legendOffsetY,
  } = configData['legend'] || {};
  const { size, shape } = configData['point'] || {};
  const {
    autoHideLabel: xAutoHide,
    autoRotateLabel: xAutoRotate,
    axisRulers: xLine,
    grid: xGrid,
    tickLine: xTickLine,
    xAxisLabel,
    xTitle,
  } = configData['xAxis'] || {};
  const {
    autoHideLabel: yAutoHide,
    autoRotateLabel: yAutoRotate,
    axisRulers: yLine,
    grid: yGrid,
    tickLine: yTickLine,
    yAxisLabel,
    yTitle,
  } = configData['yAxis'] || {};

  const lineConfig = {
    yAxis: yAxis?.data
      ? {
          grid: {
            visible: yGrid?.data,
            line: {
              style: {
                stroke: '#e8e8e8',
                lineWidth: 1,
              },
            },
          },
          tickLine: yTickLine?.data ? {} : undefined,
          line: yLine?.data ? {} : undefined,
          label: yAxisLabel?.data
            ? {
                style: {
                  fill: '#000000',
                },
                autoHide: yAutoHide?.data,
                autoRotate: yAutoRotate?.data,
                offsetX: configData['yAxisLabel']?.offsetX?.data,
                offsetY: configData['yAxisLabel']?.offsetY?.data,
              }
            : false,

          title: yTitle?.data
            ? {
                text: configData['yTitle']?.text?.data,
              }
            : undefined,
        }
      : false,
    xAxis: xAxis?.data
      ? {
          grid: {
            visible: xGrid?.data,
            line: {
              style: {
                stroke: '#e8e8e8',
                lineWidth: 1,
              },
            },
          },
          tickLine: {
            alignTick: xTickLine?.data,
          },
          line: xLine?.data ? {} : undefined,
          label: xAxisLabel?.data
            ? {
                style: {
                  fill: '#000000',
                },
                autoHide: xAutoHide?.data,
                autoRotate: xAutoRotate?.data,
                offsetX: configData['xAxisLabel']?.offsetX?.data,
                offsetY: configData['xAxisLabel']?.offsetY?.data,
              }
            : false,
          title: xTitle?.data
            ? {
                text: configData['xTitle']?.text?.data,
              }
            : undefined,
        }
      : false,
    label: label?.data
      ? {
          offsetX: offsetX?.data,
          offsetY: offsetY?.data,
          style: {
            fill: '#000000',
            opacity: 1,
            fontSize: labelSize?.data,
          },
        }
      : undefined,
    theme: {
      background: '#FFFFFF',
    },
    point: point?.data
      ? {
          size: size?.data,
          shape: shape?.data,
        }
      : false,
    legend: legend?.data
      ? {
          position: location?.data,
          flipPage: flipPage?.data,
          offsetX: legendOffsetX?.data,
          offsetY: legendOffsetY?.data,
        }
      : false,
    lineStyle: {
      lineWidth: columnSize?.data,
    },
    tooltip: tooltip?.data,
    smooth: smooth?.data,
    color: color?.data,
  };
  return lineConfig;
};

export default LineConfigs;
