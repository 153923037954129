import {
  SET_CURRENT_HUB_REPORTS,
  RETAIL_REPORTS_UPDATE_TABLE_DATA,
  RETAIL_REPORTS_ENABLE_LOADING,
  RETAIL_REPORTS_DISABLE_LOADING,
  APPLY_FILTERS_RETAIL_REPORTS,
} from '../actions/constants';
import * as moment from 'moment';
const defaultState: any = {
  searchedHubs: [],
  requests: [],
  isLoading: false,
  appliedFilters: {
    dumpType: undefined,
    date: {
      start: moment().subtract(7, 'day'),
      end: moment(),
    },
  },
};

export default function (state: any = defaultState, action) {
  switch (action.type) {

    case SET_CURRENT_HUB_REPORTS: {
      return {
        ...state,
        searchedHubs:  state.searchedHubs.concat(action.data),
      };
    }
    case RETAIL_REPORTS_UPDATE_TABLE_DATA: {
      return {
        ...state,
        requests: action.data,
      };
    }
    case RETAIL_REPORTS_ENABLE_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case RETAIL_REPORTS_DISABLE_LOADING: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case APPLY_FILTERS_RETAIL_REPORTS: {
      return {
        ...state,
        appliedFilters: {
          ...state.appliedFilters,
          ...action.data,
        },
      };
    }
    default: return state;
  }
}
