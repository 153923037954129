import * as React from 'react';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '../../theming/jssTypes';
import { Modal, Button, message } from 'antd';
import { userConsent } from '../../api/dashboard';
const styles = (theme: ThemeType): CssType => ({
  acceptButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& .ant-btn': {
      width: '120px',
    },
  },
  mainDiv: {
    marginBottom: '20px',
    textAlign: 'justify',
  },
  consentHeading: {
    fontWeight: 600,
    textAlign: 'center',
  },
});

const UserConsentModal = (props: any) => {
  const { isVisible, onModalClose, classes } = props;
  const [confirmLoading, setConfirmLoading] = React.useState(false);

  const modalContent =
    'In order to give you a better service, Shipsy uses cookies. If you ' +
    'continue we assume that you consent to receive tracking and third-party cookies on all ' +
    'Shipsy websites. ';

  const privacyPolicyLink = 'https://shipsy.io/privacy-policy/';

  const onAcceptClick = async () => {
    setConfirmLoading(true);
    const response = await userConsent({ data_consent_version: '1' });
    if (response.isSuccess) {
      onModalClose();
    } else {
      message.error(response.errorMessage);
    }
    setConfirmLoading(false);
  };

  return (
    <Modal
      width={500}
      title="Notice"
      visible={isVisible}
      closable={false}
      maskClosable={false}
      footer={null}
      maskStyle={{ backgroundColor: '#FFFFFF' }}
      zIndex={1001}
    >
      <div className={classes.mainDiv}>
        <h3 className={classes.consentHeading}>
          We need your consent to proceed
        </h3>
        {modalContent}
        <a href={privacyPolicyLink} target="_blank">
          Click here for more information.
        </a>
      </div>
      <div className={classes.acceptButton}>
        <Button
          disabled={confirmLoading}
          type="primary"
          loading={confirmLoading}
          onClick={onAcceptClick}
        >
          Accept
        </Button>
      </div>
    </Modal>
  );
};

export default withStyles(styles, { injectTheme: true })(UserConsentModal);
