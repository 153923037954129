import {
  TOGGLE_SIDE_MENU,
  OPEN_SIDE_MENU,
  CLOSE_SIDE_MENU,
  SET_OPEN_KEYS,
} from './constants';

export const toggleSideMenu = () => {
  return {
    type: TOGGLE_SIDE_MENU,
  };
};

export const closeSideMenu = () => {
  return {
    type: CLOSE_SIDE_MENU,
  };
};

export const openSideMenu = () => {
  return {
    type: OPEN_SIDE_MENU,
  };
};

export const setOpenKeys = (arr) => {
  return {
    type: SET_OPEN_KEYS,
    data: arr,
  };
};
