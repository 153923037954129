import apiProvider from './provider';
import {
  getAuthenticationHeaders,
  getErrorMessage,
} from './utils';
import { API_BASE_URL } from '../globals';
import { GET_DOWNLOADS, GENERIC_DASHBOARD_FETCH_DOWNLOAD_REQUESTS_V1 } from './routes';
import { GET } from './apiHandler';

export const getDownloads = async() => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${GET_DOWNLOADS}` ,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      isSuccess: true,
      data:response.data,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getDownloadsForSummaryMetrics = async() => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${GET_DOWNLOADS}?dumpType=SUMMARY_METRICS_CRM`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      isSuccess: true,
      data:response.data,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const getDownloadsRetailReports = async(dumpType) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${GET_DOWNLOADS}` ,
      {
        params: {
          dumpType,
        },
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      isSuccess: true,
      data:response.data,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const fetchDownloadRequestsV1 = async (params) => {
  return GET(`${API_BASE_URL}${GENERIC_DASHBOARD_FETCH_DOWNLOAD_REQUESTS_V1}`, params);
};
