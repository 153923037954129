import * as React from 'react';
import withStyles, { WithStylesProps } from 'react-jss';
import { connect } from 'react-redux';
import styles from './TripMapEditTripTab.style';
import { bindActionCreators } from 'redux';
import {
  applyBucketSort,
  fetchConsignments,
  setSelectedRowKeys,
} from 'src/actions/genericConsignmentActions';
import FixedHeaderTable from 'src/components/common/FixedHeaderTable/FixedHeaderTable';
import {
  MAP_EDIT_TRIP_TAB_CN_COLUMN_LIST,
  MAP_EDIT_VIEW_TYPE,
  TRIP_OFFLINE_ACTIONS,
  formatDecimalMetric,
} from '../TripManagerMapEditUtils';
import SplitPanes from 'src/components/common/SplitPanes';
import { useTripManagerMapEditContext } from '../StateManagement/TripManagerMapEdit.context';
import {
  updateDragEndTime,
  updateTripDataOffline,
} from '../StateManagement/TripManagerMapEdit.actions';
import TripMapEditTripsFilterBar from './TripMapEditTripsFilterBar/TripMapEditTripsFilterBar.component';
import { createSelector } from 'reselect';
import { getDetailsRoute, getRetailTripDetailsRoute } from 'src/routing/utils';
import { Link } from 'react-router-dom';
import {
  formatDate,
  formatDateTime,
  convertSecondsToHHMMSS,
  formatTime,
} from 'src/utils/utils';
import { Button, Tooltip, message } from 'antd';
import {
  CheckCircleFilled,
  CloseCircleFilled,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  InfoOutlined,
  RightOutlined,
} from '@ant-design/icons';
import {
  tripStatusPrettyNames,
  tripTypePrettyNames,
} from 'src/utils/retailConsignment';
import { MetricIcon } from 'src/components/common/icons/MetricIcon';
import TripMapEditMetricTooltip from './TripMapEditMetricTooltip/TripMapEditMetricTooltip.component';
import { tripColorPalette } from '../../../tracking/colorPalette';
import TripMapUpdateTripDataModal from './TripMapUpdateTripDataModal/TripMapUpdateTripDataModal.component';
import { setTripData, setAvailableWorkers } from '../StateManagement/TripManagerMapEdit.actions';
import RightClickPopup from '../../../../../common/RightClickPopup';
import DragSortingTable from '../../DragSortingTable';
import { DragIcon } from 'src/components/common/icons/DragIcon';
import { getAvailableWorkerList } from 'src/api/trips';

interface IProps extends WithStylesProps<typeof styles> {
  bucket: string;
  isTableLoading: any;
  allTripsStateData: any;
  tripColumnsToShow: any;
  fetchTrips: any;
  applyBucketSort: any;
  // eslint-disable-next-line react/no-unused-prop-types
  selectedRowKeys: any;
  // eslint-disable-next-line react/no-unused-prop-types
  setSelectedRowKeys: any;
  mapViewComponent: any;
  currHub: any;
  startDate: any;
  endDate: any;
}

const TripMapEditTripTab = function (props: IProps) {
  const {
    classes,
    bucket,
    isTableLoading,
    allTripsStateData,
    tripColumnsToShow,
    fetchTrips,
    applyBucketSort,
    selectedRowKeys,
    mapViewComponent,
    currHub,
    startDate,
    endDate,
  } = props;

  const { tripManagerMapEditState, tripManagerMapEditDispatch } =
    useTripManagerMapEditContext();
  const { isMapView, allTrips, allUnplannedCNs, editActions, routeResultMap } =
    tripManagerMapEditState;
  const [popupState, setPopupState] = React.useState({
    record: null as any,
    visible: false,
    x: 0,
    y: 0,
  });
  const tableData =
    allTrips?.map(({ tripDetails, taskDetails }) => ({
      ...tripDetails,
      tasksData: taskDetails,
    })) ?? [];

  const [tripDataForEditViaModal, setTripDataForEditViaModal] =
    React.useState(null);

  React.useEffect(() => {
    fetchAvailableWorkers();
  }, [currHub, startDate, endDate])
  React.useEffect(() => {
    tripManagerMapEditDispatch(setTripData([...allTripsStateData]));
  }, [allTripsStateData]);

  const handleDragEnd = () => {
    tripManagerMapEditDispatch(updateDragEndTime());
  };

  const fetchAvailableWorkers = async () => {
    const body = {
      hub_id: currHub.id,
      start_date: endDate, //show roster for 1 day only
      end_date: endDate,
    };
    const response = await getAvailableWorkerList(body);
    if (!response.isSuccess) {
      tripManagerMapEditDispatch(setAvailableWorkers([]))
      return;
    }
    const data = response.data.data;
    tripManagerMapEditDispatch(setAvailableWorkers(data))
  }

  const rowSelection = createSelector(
    (p: IProps) => p.selectedRowKeys,
    (p: IProps) => p.setSelectedRowKeys,
    (selectedRowKeys, setSelectedRowKeys) => {
      return {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          setSelectedRowKeys(selectedRowKeys);
        },
      };
    },
  );

  const handleTableChange = (pagination, filters, sorter) => {
    let filter: any = null;
    if (sorter.column) {
      filter = {
        sortBy: sorter.columnKey,
        descendingOrder: sorter.order === 'descend',
      };
    }
    if (filter) {
      applyBucketSort(filter);
    } else {
      applyBucketSort({});
    }
  };

  const renderTripMetrics = (tripData) => {
    const {
      weight_capacity,
      volume_capacity,
      total_consignment_count,
      service_time_mins,
      estimated_time_seconds,
      simulated_start_time,
      planned_start_time,
      consignment_capacity,
      vehicle_weight_utilisation,
      vehicle_volume_utilisation,
      vehicle_consignment_utilisation,
      estimated_distance_metres,
      sequence_details,
      delivery_stops,
      is_metric_breached,
    } = tripData;

    return (
      <TripMapEditMetricTooltip
        is_metric_breached={is_metric_breached}
        title="Metrics"
        data={{
          rows: [
            {
              type: 'SUBHEADING',
              text: 'Vehicle Utilization Percentage',
            },
            {
              type: 'GRIDROW',
              columns: [
                {
                  span: 12,
                  label: 'Volume (%)',
                  value: formatDecimalMetric(vehicle_volume_utilisation),
                },
                {
                  span: 12,
                  label: 'Weight (%)',
                  value: formatDecimalMetric(vehicle_weight_utilisation),
                },
                {
                  span: 12,
                  label: 'Number (%)',
                  value: formatDecimalMetric(vehicle_consignment_utilisation),
                },
              ],
            },
            {
              type: 'DIVIDER',
            },
            {
              type: 'GRIDROW',
              columns: [
                {
                  span: 12,
                  label: 'CN count',
                  value: total_consignment_count ?? '-',
                },
                {
                  span: 12,
                  label: 'Total Distance (km)',
                  value: estimated_distance_metres
                    ? formatDecimalMetric(+estimated_distance_metres / 1000)
                    : '-',
                },
                {
                  span: 12,
                  label: 'Total Trip Duration',
                  value: convertSecondsToHHMMSS(estimated_time_seconds) ?? '-',
                },
                {
                  span: 12,
                  label: 'Avg Speed (Km/hr)',
                  value: (() => {
                    if (estimated_time_seconds && estimated_distance_metres) {
                      const distanceInKms = +formatDecimalMetric(+estimated_distance_metres / 1000);
                      // Planned service time was added during BE DB query
                      const timeInHrs = (+estimated_time_seconds / 3600) - (service_time_mins ? (+service_time_mins / 60) : 0)
                      const speed = distanceInKms / timeInHrs;
                      return formatDecimalMetric(speed);
                    }
                    return '-';
                  })(),
                },
                {
                  span: 12,
                  label: 'Total Service Time',
                  value: !isNaN(service_time_mins)
                    ? convertSecondsToHHMMSS(+service_time_mins * 60)
                    : '-',
                },
              ],
            },
            {
              type: 'DIVIDER',
            },
            {
              type: 'GRIDROW',
              columns: [
                {
                  span: 24,
                  label: 'Trip Stops',
                  value: delivery_stops ?? sequence_details?.length ?? '-',
                },
                {
                  span: 24,
                  label: 'Planned Trip Start Time',
                  value: planned_start_time
                    ? formatDateTime(planned_start_time)
                    : simulated_start_time
                    ? formatDateTime(simulated_start_time)
                    : '-',
                },
              ],
            },
            {
              type: 'DIVIDER',
            },
            {
              type: 'SUBHEADING',
              text: 'Vehicle Capacity',
            },
            {
              type: 'GRIDROW',
              columns: [
                {
                  span: 12,
                  label: 'Volume (m3)',
                  value: formatDecimalMetric(volume_capacity),
                },
                {
                  span: 12,
                  label: 'Weight (kg)',
                  value: formatDecimalMetric(weight_capacity),
                },
                {
                  span: 12,
                  label: 'Number',
                  value: consignment_capacity ?? '-',
                },
              ],
            },
          ],
        }}
      />
    );
  };

  const handleTripDataUpdate = (tripData, actionType) => {
    setTripDataForEditViaModal({
      tripData,
      actionType,
    });
  };

  const generateColumnsToShowSelector = createSelector(
    (p: any) => p.classes,
    (p: any) => p.tripColumnsToShow,
    (classes, tripColumnsToShow) => {
      const columnData: any = [];
      const indexList = tripColumnsToShow;
      const fixedColumnList = ['organisation_reference_number'];
      indexList.forEach((item) => {
        const itemToPush = {};
        itemToPush['title'] = item.pretty_name;
        const column_id = item.sort_key || item.column_id;
        itemToPush['sorter'] = item.is_sortable;
        itemToPush['dataIndex'] = column_id;
        itemToPush['key'] = column_id;
        itemToPush['width'] = 190;
        switch (column_id) {
          case 'organisation_reference_number':
          case 'primary_organisation_reference_number':
            itemToPush['render'] = (x, record, index) => {
              const openInNewTab =
                column_id === 'organisation_reference_number';
              return (
                <>
                  <div
                    className={classes.statusCircle}
                    style={{
                      backgroundColor:
                        tripColorPalette[index % tripColorPalette.length],
                    }}
                  ></div>
                  {record.is_local_temp_trip ? (
                    x
                  ) : (
                    <Link
                      to={getRetailTripDetailsRoute(record[column_id])}
                      target={openInNewTab ? '_blank' : null}
                    >
                      {x}
                    </Link>
                  )}
                </>
              );
            };
            break;

          case 'last_main_event_time':
          case 'created_at':
          case 'start_time':
          case 'end_time':
          case 'assigned_time':
          case 'delivery_time_slot_start':
          case 'delivery_time_slot_end':
          case 'time_since_last_scan_millis': 
          case 'time_since_last_attempt_millis': 
          case 'time_since_first_attempt_millis':
          case 'created_at_retail': 
          case 'last_main_event_time_retail':
            itemToPush['render'] = (x) => {
              if (x) {
                return formatDateTime(x);
              }
              return '';
            };
            break;

          case 'scheduled_at':
            itemToPush['render'] = (x) => {
              if (x) {
                return formatDate(x);
              }
              return '';
            };
            break;

          case 'task':
            itemToPush['render'] = (x, record) => {
              return (
                <div>
                  <span>{record.tasksData?.length ?? 0}</span>
                  {/* <Tooltip
                    title={
                      <div>
                        <span>{record.delivered_task_count} Delivered</span>
                        <br />
                        <span>{record.attempted_task_count} Undelivered</span>
                        <br />
                        <span>
                          {record.incomplete_delivery_task_count} Pending
                          Delivery
                        </span>
                        <br />
                        <span>
                          {record.pickedup_task_count} Pickup Completed
                        </span>
                        <br />
                        <span>
                          {record.incomplete_pickup_task_count} Pending Pickup
                        </span>
                        <br />
                        <span>
                          {record.notpickedup_task_count} Not Picked Up
                        </span>
                        <br />
                      </div>
                    }
                  >
                    <Button
                      shape="circle"
                      size="small"
                      icon={<InfoOutlined />}
                      style={{
                        marginLeft: 10,
                      }}
                    ></Button>
                  </Tooltip> */}
                </div>
              );
            };
            break;

          case 'status':
            itemToPush['render'] = (x, record) => {
              return <span>{tripStatusPrettyNames(x)}</span>;
            };
            break;

          case 'type':
            itemToPush['render'] = (x, record) => {
              return <span>{tripTypePrettyNames(x)}</span>;
            };
            break;

          case 'worker_name':
            itemToPush['render'] = (x, record) => {
              return (
                <div>
                  {x ? (
                    <>
                      <span
                        className={classes.vehicleType}
                        style={{ marginRight: '5px' }}
                      >
                        {x}
                      </span>
                    </>
                  ) : null}
                  <Button
                    type="text"
                    icon={<EditOutlined />}
                    onClick={() => {
                      handleTripDataUpdate(
                        record,
                        TRIP_OFFLINE_ACTIONS.UPDATE_WORKER,
                      );
                    }}
                  />
                </div>
              );
            };
            break;

          case 'vehicle_registration_number':
            itemToPush['render'] = (x, record) => {
              return (
                <div>
                  {x ? (
                    <>
                      <span
                        className={classes.vehicleType}
                        style={{ marginRight: '5px' }}
                      >
                        {x}
                      </span>
                    </>
                  ) : null}
                  <Button
                    type="text"
                    icon={<EditOutlined />}
                    onClick={() => {
                      handleTripDataUpdate(
                        record,
                        TRIP_OFFLINE_ACTIONS.UPDATE_VEHICLE,
                      );
                    }}
                  />
                </div>
              );
            };
            break;

          case 'vehicle_type':
            itemToPush['render'] = (x, record) => {
              return (
                <div>
                  {record.vehicle_type ? (
                    <span className={classes.capitalize}>
                      {record.vehicle_type}
                    </span>
                  ) : null}
                </div>
              );
            };
            break;

          case 'trip_type':
            itemToPush['render'] = (x, record) => {
              return <span style={{ textTransform: 'capitalize' }}>{x}</span>;
            };
            break;

          case 'estimated_distance_metres':
            itemToPush['render'] = (x) => {
              return x ? <span>{`${x / 1000} `}</span> : null;
            };
            break;

          case 'route':
            itemToPush['render'] = (x) => {
              return x;
            };
            break;

          case 'service_time_mins':
          case 'vehicle_service_time_mins':
            itemToPush['render'] = (x) => {
              return x;
            };
            break;

          case 'destination_pincodes':
            itemToPush['render'] = (x) => {
              return x && x.length > 0
                ? x
                    .map((ele) => {
                      return ele;
                    })
                    .join(', ')
                : [];
            };
            break;

          case 'is_reconciliation_complete':
          case 'is_hand_delivery_trip':
            itemToPush['render'] = (x) => {
              return x === true ? 'Yes' : 'No';
            };
            break;

          case 'ewb_status':
            itemToPush['render'] = (x) => {
              return x === 'success' ? (
                <span>
                  <CheckCircleFilled
                    style={{ color: '#008000' }}
                    className={classes.leftSpace}
                  />
                  Success
                </span>
              ) : (
                <span>
                  <CloseCircleFilled
                    style={{ color: '#EC544A' }}
                    className={classes.leftSpace}
                  />
                  Failed
                </span>
              );
            };
            break;

          case 'scheduled_worker_name':
            itemToPush['render'] = (x, record) => {
              return x;
            };
            break;

          case 'scheduled_vehicle_registration_number':
            itemToPush['render'] = (x, record) => {
              return x;
            };
            break;

          case 'vehicle_make':
            itemToPush['render'] = (x, record) => {
              return (
                <div>
                  {record.vehicle_make ? (
                    <>
                      <span
                        className={classes.vehicleType}
                        style={{ marginRight: '5px' }}
                      >
                        {record.vehicle_make}
                      </span>
                    </>
                  ) : null}
                  <Button
                    type="text"
                    icon={<EditOutlined />}
                    onClick={() => {
                      handleTripDataUpdate(
                        record,
                        TRIP_OFFLINE_ACTIONS.UPDATE_VEHICLE_MAKE,
                      );
                    }}
                  />
                </div>
              );
            };
            break;
        }

        if (fixedColumnList.indexOf(column_id) >= 0) {
          itemToPush['width'] = 210;
          itemToPush['fixed'] = 'left';
          columnData.unshift(itemToPush);
        } else {
          columnData.push(itemToPush);
        }
      });

      columnData.push({
        title: '',
        key: 'actions',
        width: 90,
        fixed: 'right',
        render: (text, record) => {
          return record.is_local_temp_trip ? (
            <Tooltip
              title={
                'Metrics for new trips will be incorporated after trips are saved'
              }
              color={'#000000'}
            >
              <MetricIcon />
            </Tooltip>
          ) : (
            <Tooltip
              overlayInnerStyle={{
                margin: 0,
                padding: 0,
              }}
              title={renderTripMetrics(record)}
              color={'#fff'}
            >
              <MetricIcon />
            </Tooltip>
          );
        },
      });
      return columnData;
    },
  );

  const getScrollWidth = createSelector(
    (p) => generateColumnsToShowSelector(p),
    (columns) => {
      return columns.reduce((acc, cur) => {
        return acc + (cur.width || 190);
      }, 0);
    },
  );

  const handleDragSortTasks = (tripDetails, newTaskDetails) => {
    updateTripDataOffline(tripManagerMapEditDispatch, {
      actionType: TRIP_OFFLINE_ACTIONS.REORDER_TASKS,
      tripId: tripDetails.id,
      allTrips,
      newData: newTaskDetails,
      editActions,
      allUnplannedCNs,
    });

    message.success('Reorder CNs Done');
  };

  const expandedRowRender = (record, index, indent, expanded) => {
    const columns = MAP_EDIT_TRIP_TAB_CN_COLUMN_LIST.map((colData) => {
      const toRet: any = {
        title: colData.pretty_name,
        dataIndex: colData.column_id,
        key: colData.column_id,
      };

      switch (colData.column_id) {
        case 'reference_number':
          toRet['render'] = (x) => {
            return (
              <Link target={'_blank'} to={getDetailsRoute(x)}>
                {x}
              </Link>
            );
          };
          break;
        case 'consignment_type':
          toRet['render'] = (x) => {
            return x ?? 'Forward';
          };
          break;
        case 'planned_eta':
          toRet['render'] = (x, record) => {
            const etaTimestamp =
              record.eta ?? record.simulated_eta?.simulated_eta;
            return etaTimestamp ? formatDateTime(etaTimestamp) : '-';
          };
          break;
        case 'departure_time':
          toRet['render'] = (x, record) => {
            return x ? formatTime(x) : '-';
          };
          break;
        case 'service_time_mins':
          toRet['render'] = (x, record) => {
            return x ? x + ' mins' : '-';
          };
          break;
        case 'constraint_tags':
          toRet['render'] = (x, record) => {
            return x?.length ? x.join(", ") : '-';
          };
          break;
        default:
          toRet['render'] = (x) => {
            return x ?? '-';
          };
          break;
      }
      return toRet;
    });

    columns.unshift({
      title: 'S. No.',
      dataIndex: 'index',
      key: 'index',
      render: (x, record, idx) => idx + 1,
    });

    columns.unshift({
      title: '',
      key: 'drag',
      render: (text, record, index) => <DragIcon />,
      width: 20,
    });

    const taskData = allTrips[index].taskDetails;

    return (
      <DragSortingTable
        style={{ margin: '0 !important', padding: 0 }}
        dataSource={taskData}
        columns={columns}
        handleDragSort={(data) => handleDragSortTasks(record, data)}
        pagination={false}
      />
    );
  };

  const handleOnRow = (record) => ({
    onContextMenu: (event) => {
      event.preventDefault();
      if (!popupState.visible) {
        document.addEventListener('click', function onClickOutside() {
          setPopupState({ ...popupState, visible: false });
          document.removeEventListener('click', onClickOutside);
        });
      }
      setPopupState({
        record,
        visible: true,
        x: event.clientX,
        y: event.clientY,
      });
    },
  });

  const renderTable = () => {
    return (
      <FixedHeaderTable
        className={classes.expandableTableContainer}
        rowSelection={rowSelection(props)}
        indentSize={15}
        loading={isTableLoading}
        locale={{ emptyText: 'No trips found' }}
        scroll={{ x: getScrollWidth(props) }}
        rowKey={(record: any) => record['id']}
        rowClassName={(record: any) =>
          record['is_metric_breached'] ? classes.metricsBreachedRow : ''
        }
        pagination={false}
        dataSource={tableData}
        columns={generateColumnsToShowSelector(props)}
        onChange={handleTableChange}
        expandable={{
          expandedRowRender,
          indentSize: 0,
          rowExpandable: (record) => {
            const tripData = allTrips.find(
              (trip) => trip.tripDetails.id === record.id,
            );
            const taskData = tripData.taskDetails;
            return taskData && taskData.length;
          },
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <DownOutlined onClick={(e) => onExpand(record, e)} />
            ) : (
              <RightOutlined onClick={(e) => onExpand(record, e)} />
            ),
        }}
        onRow={handleOnRow}
      />
    );
  };

  const renderTrips = () => {
    if (isMapView) {
      return (
        <SplitPanes
          left={renderTable()}
          right={mapViewComponent}
          onDragEnd={handleDragEnd}
        />
      );
    }
    return renderTable();
  };

  const handleModalClose = () => {
    setTripDataForEditViaModal(null);
  };

  const renderTripDataUpdateModal = () => {
    return tripDataForEditViaModal ? (
      <TripMapUpdateTripDataModal
        actionType={tripDataForEditViaModal?.actionType}
        tripData={tripDataForEditViaModal?.tripData}
        visible={!!tripDataForEditViaModal}
        onClose={handleModalClose}
      />
    ) : (
      <></>
    );
  };

  const handleDeleteTrip = (record) => {
    updateTripDataOffline(tripManagerMapEditDispatch, {
      actionType: TRIP_OFFLINE_ACTIONS.DELETE_TRIP,
      tripId: record.id,
      allTrips,
      newData: null,
      editActions,
      allUnplannedCNs,
    });

    message.success('Deleted Trip');
  };

  React.useEffect(() => {
    const allTripIds = allTrips.map((t) => t.tripDetails.id);
    const newSelectedRowKeys = selectedRowKeys.filter((id) =>
      allTripIds.includes(id),
    );
    if (newSelectedRowKeys.length !== selectedRowKeys.length) {
      setSelectedRowKeys(newSelectedRowKeys);
    }
  }, [allTrips]);

  return (
    <div className={classes.mainDiv}>
      <TripMapEditTripsFilterBar bucket={bucket} />
      <div className={classes.tableContainer}>
        {renderTrips()}

        <RightClickPopup
          {...popupState}
          menuConfig={[
            {
              id: 'delete_trip',
              name: 'Delete Trip',
              color: 'red',
              icon: <DeleteOutlined />,
              handler: handleDeleteTrip,
            },
          ]}
        />
      </div>
      {renderTripDataUpdateModal()}
    </div>
  );
};

const TripMapEditTripTabStyled = withStyles(styles, { injectTheme: true })(
  TripMapEditTripTab,
);

const mapStateToProps = (
  { genericConsignmentReducer, masterData, cachedData },
  ownProps,
) => {
  const viewType = MAP_EDIT_VIEW_TYPE;
  const { bucket } = ownProps;
  const currentViewReducer = genericConsignmentReducer[viewType];
  const currentBucketReducer = currentViewReducer.buckets[bucket];
  const globalAppliedFilters = currentViewReducer.globalAppliedFilters;
  return {
    currHub: cachedData.currHub,
    isTableLoading: currentBucketReducer.isLoading,
    allTripsStateData: currentBucketReducer.data,
    selectedRowKeys: currentBucketReducer.selectedRowKeys,
    tripColumnsToShow: masterData.ops_trip_column_list[bucket],
    startDate: globalAppliedFilters.startDate,
    endDate: globalAppliedFilters.endDate,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const viewType = MAP_EDIT_VIEW_TYPE;
  const { bucket } = ownProps;
  return bindActionCreators(
    {
      fetchTrips: fetchConsignments(viewType),
      setSelectedRowKeys: setSelectedRowKeys(viewType)(bucket),
      applyBucketSort: applyBucketSort(viewType)(bucket),
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TripMapEditTripTabStyled);
