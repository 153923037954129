import Icon from '@ant-design/icons';
import * as React from 'react';
const OrderManagementSvg = () => {
  return (
    <svg version="1.1" width="16px" height="16px" viewBox="0 0 16.0 16.0" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(1.0 1.0)">
        <path
          d="M7,0 L14,3.5 L14,10.5 L7,14 L0,10.5 L0,3.5 L7,0 Z M0.875,4.52307692 L0.875,10.0315385 L6.5625,12.8773077 L6.5625,7.37423077 L0.875,4.52307692 Z M13.125,4.52307692 L7.4375,7.37423077 L7.4375,12.8665385 L13.125,10.0315385 L13.125,4.52307692 Z M10.2870833,2.57653846 L4.55583333,5.44923077 L7,6.67423077 L12.7341667,3.80153846 L10.2870833,2.57653846 Z M7,0.931538462 L1.26583333,3.79884615 L3.64,4.98884615 L9.37125,2.09730769 L7,0.931538462 Z">
        </path>
      </g>
    </svg>
  );
};

export const OrderManagementIcon = props => (
  <Icon component={OrderManagementSvg} {...props} />
);
