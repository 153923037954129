import * as React from 'react';
import withStyles from 'react-jss';
import { CssType,
  ThemeType} from '../../theming/jssTypes';
import { Upload, Button } from 'antd';
import { handleXLSXFile, handleXLSXFileDateTime, handleXLSXWorkbook, handleXLSXFileWithoutMapping,
  handleXLSXWorkbookWithoutMapping } from '../../utils/utils';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
const uploadIcon = require('../../assets/images/upload.svg');
import classNames from 'classnames';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

const Dragger = Upload.Dragger;
type CustomerRequestArgs = {
  onSuccess?: any;
  onError?: any;
  file?: any;
};
const styles = (theme: ThemeType): CssType => ({
  link : {
    color : theme.colors.hyperlink,
    cursor: 'pointer',
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  downloadDiv: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10,
    '& a': {
      color: theme.colors.hyperlink
    },
  },
});

class CustomExcelUpload extends React.Component<any, any> {

  temp: UploadFile[] = [];

  constructor(props) {
    super(props);
  }

  state = {
    fileList: this.temp,
  };

  handleMultipleImageUpload = (info: UploadChangeParam) => {
    const { onDataParsed } = this.props;
    this.setState({
      fileList: info.fileList,
    });

    if (this.state.fileList && this.state.fileList.length) {
      const { fileList } = this.state;
      onDataParsed(fileList);
    }
  };

  handleUploadChange = (info: UploadChangeParam) => {
    const status = info.file.status;
    if (status === 'done') {
      this.onFileRecieved(info.file.originFileObj);
    }
    this.setState({
      fileList: [info.file],
    });
  };

  customRequest = (args: CustomerRequestArgs) => {
    const { onSuccess, file } = args;
    setTimeout(() => {
      onSuccess(null, file);
    }, 100);
  };

  onFileRecieved = async (file) => {
    const { mapping, onDataParsed, dateTime, multipleSheets, multipleMappings,
      firstRowAsHeader, enableHeaderMapping } = this.props;

    const header_val = enableHeaderMapping ? 1 : 0;
    const range_val = enableHeaderMapping ? 1 : 0;
    if (multipleSheets) {
      const dataListArray = multipleMappings ? await handleXLSXWorkbook(file, multipleMappings) :
        await handleXLSXWorkbookWithoutMapping(file, header_val, range_val);
      if (dataListArray?.Consignments) {
        dataListArray.Consignments = dataListArray.Consignments?.map((data) => {
          if (data?.invoice_date && moment(data?.invoice_date).isValid()) {
            return {
              ...data,
              invoice_date: moment(data.invoice_date).add(1, 'h').toISOString(),
            };
          }
          return data;
        });
      }
      if (onDataParsed) {
        onDataParsed(dataListArray, file);
      }
    } else {
      let dataList: any = [];
      if(mapping){
        dataList = dateTime ?
        await handleXLSXFileDateTime(file,  Object.keys(mapping)) :
        await handleXLSXFile(file, Object.keys(mapping));
      }else {
        dataList = dateTime ?
        await handleXLSXFileDateTime(file, firstRowAsHeader ? 0 : 1, 0) :
        await handleXLSXFileWithoutMapping(file, firstRowAsHeader);
      }
      if (onDataParsed) {
        onDataParsed(dataList, file);
      }
    }
  };

  uploadProps = {
    accept: '.xlsx, .xls, .csv',
    name: 'file',
    multiple: false,
    customRequest: this.customRequest,
    showUploadList:{ showPreviewIcon: false, showRemoveIcon: false },
  };

  render() {

    const { sampleLink, sampleApi, classes, showButtonOnly, isDisabled,
      title, additionalUploadParams, t, isMultipleImageUpload = false } = this.props;
    const { fileList } = this.state;
    this.uploadProps = {...this.uploadProps, ...additionalUploadParams};

    return (
      <div>
        {showButtonOnly ? (<Upload
          fileList={fileList}
          onChange={this.handleUploadChange}
          {...this.uploadProps}
        >
          <Button>{title ? title : t('upload')}</Button>
        </Upload>) : ( <Dragger
          fileList={fileList}
          style={{ padding: '16px' }}
          onChange={isMultipleImageUpload? this.handleMultipleImageUpload :this.handleUploadChange}
          {...this.uploadProps}
          >
          <p
            style={{ marginBottom: 20, fontSize: '14px' }}
            className="ant-upload-hint">
          </p>
          <p className="ant-upload-drag-icon">
            <img style={{ height: '3em' }} src={uploadIcon} />
          </p>
          <p style={{ color: '#525252', marginBottom: 30, fontSize: '14px', fontWeight:400 }}>
            <b>{t('Drag and drop to upload')} <br/> {t('or')}
            <span style={{ color: '#457CA9' }}> {t('browse')}</span> {t('to choose file')}</b>
          </p>
        </Dragger>)}
        {sampleLink &&
        <div className = { isDisabled ? classNames(classes.disabled, classes.downloadDiv)
          : classes.downloadDiv}>
          <a href={sampleLink} target={'_blank'}>
            {t('download_a_sample_file')}
           </a>
        </div>}
        {sampleApi &&
          <div className = { isDisabled ? classNames(classes.disabled, classes.downloadDiv)
            : classes.downloadDiv}>
          <span className ={classes.link} onClick = {() => sampleApi()} >
          {t('download_a_sample_file')}
           </span>
        </div>
        }
      </div>
    );
  }

}
const ExcelUpload=withTranslation('translation')(CustomExcelUpload);

export default withStyles(styles, { injectTheme: true })(ExcelUpload);
