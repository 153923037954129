export const getColumnList=(viewType) => {

  const pieceDetailColumnsWithCRM=[
       {
           pretty_name: 'Piece Number',
           column_id:'serial_number',
       },
       {
         pretty_name: 'CN Number',
         column_id: 'reference_number',
       },
       {
        pretty_name: 'Carrier AWB number',
        column_id: 'courier_partner_reference_number',
        width: 110,
      },
       {
         pretty_name: 'Detail',
         column_id: 'description',
       },
       {
         pretty_name: 'Bag Number',
         column_id: 'bag_reference_number',
       },
       {
         pretty_name: 'Weight(Kg)',
         column_id: 'weight',
       },
       {
         pretty_name: 'Volume',
         column_id:'volume',
       },
       {
         pretty_name: 'Status',
         column_id: 'status',
       },
       {
         pretty_name: 'NDR',
         column_id: 'delivery_failure_reason',
       },
       {
        pretty_name: 'Image',
        column_id: 'image',
      },
     ];
    const pieceDetailColumnsWithoutCRM=[
       {
           pretty_name: 'S.No.',
           column_id:'serial_number',
           width: 50,
       },
       {
        pretty_name: 'CN Number',
        column_id: 'reference_number',
      },
      {
        pretty_name: 'Carrier AWB number',
        column_id: 'courier_partner_reference_number',
        width: 110,
      },
       {
           pretty_name: 'Detail',
           column_id: 'description',
         },
         {
           pretty_name: 'Weight(Kg)',
           column_id: 'weight',
         },
         {
           pretty_name: 'Volume',
           column_id:'volume',
         },
         {
           pretty_name: 'Status',
           column_id: 'status',
         },
         {
           pretty_name: 'NDR',
           column_id: 'delivery_failure_reason',
         },
         {
          pretty_name: 'Declared Value',
          column_id: 'declared_value',
         },
         {
          pretty_name: 'Quantity',
          column_id: 'quantity',
         },
     ];
       if(viewType==='crm'){
           return pieceDetailColumnsWithCRM;
       }

           return pieceDetailColumnsWithoutCRM;

};

export const addLBHColumn = [
  {
    pretty_name: 'Length(cm)',
    column_id: 'length',
    is_visible: true,
  },
  {
    pretty_name: 'Height(cm)',
    column_id: 'height',
    is_visible: true,
  },
  {
    pretty_name: 'Breadth(cm)',
    column_id: 'breadth',
    is_visible: true,
  },
];

export const addTripLoadingColumn = [
  {
    pretty_name: 'Loading Image',
    column_id: 'proof_of_loading',
  },
];

export const unnecessaryColumns = ['reference_number', 'bag_reference_number', 'delivery_failure_reason'];
