// tslint:disable: max-line-length

import Icon from '@ant-design/icons';
import * as React from 'react';
const StartTrip = (props: any) => (
  <svg width="29" height="32" viewBox="0 0 29 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="28.8182" height="32" rx="3" fill="#9BE478" />
    <path fillRule="evenodd" clipRule="evenodd" d="M11.3636 9.45455H19.3636V22.5455H11.3636C10.9622 22.5455 10.6364 22.8713 10.6364 23.2727C10.6364 23.6742 10.9622 24 11.3636 24H20.0909C20.4924 24 20.8182 23.6742 20.8182 23.2727V8.72727C20.8182 8.32582 20.4924 8 20.0909 8H11.3636C10.9622 8 10.6364 8.32582 10.6364 8.72727C10.6364 9.12873 10.9622 9.45455 11.3636 9.45455ZM13.9505 15.2727H7.72727C7.32582 15.2727 7 15.5985 7 16C7 16.4015 7.32582 16.7273 7.72727 16.7273H13.9505L12.9535 18.1229C12.72 18.4495 12.7964 18.904 13.1229 19.1375C13.4495 19.3709 13.904 19.2945 14.1375 18.968L15.9556 16.4225C16.136 16.1702 16.136 15.8298 15.9556 15.5775L14.1375 13.032C13.904 12.7055 13.4495 12.6291 13.1229 12.8625C12.7964 13.096 12.72 13.5505 12.9535 13.8771L13.9505 15.2727Z" fill="black" />
  </svg>
);
export const StartTripIcon = (props) => (
  <Icon component={StartTrip} {...props} />
);
