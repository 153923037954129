import * as React from 'react';
import { Modal, message } from 'antd';
import {
  startInventoryScan,
  endInventoryScan,
} from '../../../../../api/genericConsignmentView';
const { useState } = React;
const InventoryScanConfirmationModal = (props) => {
  const {
    isVisible,
    handleModalClose,
    inventoryScanAction,
    currHub,
    comments,
  } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const handleSubmit = async () => {
    setConfirmLoading(true);
    const body = {
      comments,
      hub_id: currHub?.id,
    };
    const response =
      inventoryScanAction === 'start_inventory_scan'
        ? await startInventoryScan(body)
        : await endInventoryScan(body);
    if (response.isSuccess) {
      inventoryScanAction === 'start_inventory_scan'
        ? message.success(
            `Inventory scan started successfully for ${currHub?.code}`,
          )
        : message.success(
            `Inventory scan stopped successfully for ${currHub?.code}`,
          );
    } else {
      message.error(response.errorMessage);
    }
    setConfirmLoading(false);
    handleModalClose();
  };

  return (
    <Modal
      confirmLoading={confirmLoading}
      title={'Confirmation'}
      width={500}
      visible={isVisible}
      onCancel={handleModalClose}
      onOk={handleSubmit}
      okText={'Yes'}
    >
      <p>
        {inventoryScanAction === 'start_inventory_scan'
          ? `All transactions of the hub will be paused. Are you sure you want to
         start inventory scan?`
          : `Are you sure you want to
         stop inventory scan?`}
      </p>
    </Modal>
  );
};

export default InventoryScanConfirmationModal;
