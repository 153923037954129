import React from 'react';
import withStyles, { WithStylesProps } from 'react-jss';
import { CssType, ThemeType } from 'src/theming/jssTypes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PageData } from 'src/types/page.types';
import { ViewFilterType } from 'src/types/view.types';
import { fetchViewDataObjects } from 'src/actions/genericPageActions';
import {
    defaultTokenSeperators,
} from 'src/components/pages/GenericConsignment/CustomFilters/utils';
import TagsInput from 'src/components/common/TagsInput';
import * as moment from 'moment';
import {
  Input,
  DatePicker,
  TimePicker,
  Slider,
} from 'antd';
import GenericMultiSelectSearchFilter from './GenericMultiSelectSearchFilter';

const styles = (theme: ThemeType): CssType => ({
    filterMainDiv: {
        display: 'flex',
        alignItems: 'center',
    },
    filterHeading: {
        marginRight: '5px',
        fontWeight: 600,
    },
    filterInput: {
        width: '150px',
    },
});

interface IProps extends
    WithStylesProps<ReturnType<typeof styles>>,
    ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {

    objectType: string;
    filterData: ViewFilterType;
    params: any;
}

const GenericViewFilterComponent = (props: IProps) => {
    const {
        filterData,
        classes,
        viewData,
        fetchViewDataObjects,
        params,
        globalAppliedFilters,
    } = props;

    const { pageId, viewIndex, sectionIndex, handleFilterChange, filters } = params;

    const onChange = (value) => {
        const filterObj = {
            [filterData.id]: value,
        };

        if (filterData.showOnMainDashboard) {
            viewData.applyFilters(filterObj);
            fetchViewDataObjects({
                viewData,
                viewIndex,
                sectionIndex,
                pageId,
            });
        } else if(handleFilterChange) {
            // store the new filter value as it will get applied on clicking apply button
            handleFilterChange(filterObj);
        }
    };

    const renderMultipleInput = () => {
        let tokenSeparators = Array.from(new Set([
            ...defaultTokenSeperators,
            ...(filterData.tokenSeparators || []),
        ]));

        if(filterData.ignoreDefaultSeparators) {
            tokenSeparators = filterData.tokenSeparators || [];
        }

        return (
            <TagsInput
                notFoundContent={''}
                size="small"
                placeholder={filterData.placeholder || ''}
                tokenSeparators={tokenSeparators}
                maxHeight={100}
                style={{
                  width: '150px',
                }}
                value={getFilterValue([])}
                onChange={onChange}
            />
        );
    }

    const getFilterValue = (fallback = null) => {
        //If filter is on main dashboard - data available at viewClass else it is on temporary state
        if(filterData.showOnMainDashboard){
          let value = viewData.getFilterValue(filterData) || fallback;
          return value;
        } else {
          return filters?.[filterData.id] || fallback;
        }
        
    }

    const renderSingleInput = () => {
        return <Input
            placeholder={filterData.placeholder || ''}
            defaultValue={getFilterValue()}
            onPressEnter={(e) => onChange(e.target?.value || '')}
            className={classes.filterInput}
            size="small"
            allowClear
        />;
    };

    const renderInput = () => {
        return <div className={classes.filterMainDiv} key={filterData.id}>
            <div className={classes.filterHeading}>{filterData.name}</div>
            {
                filterData.mode === 'multiple'
                ? renderMultipleInput()
                : renderSingleInput()
            }
        </div>;
    };

    const renderMultiSelectSearch = () => {
      const isSingleSelect =
        filterData.mode !== 'multiple' || filterData.type === 'radio';
      const options = filterData.options || [];
      return (
        <GenericMultiSelectSearchFilter
          filter={filterData}
          useMultiSelect
          isSingleSelect={isSingleSelect}
          selectedFilters={viewData.appliedFilters}
          globalAppliedFilters={globalAppliedFilters}
          applyFilter={onChange}
        />
      );
    };

    const getShowTime = (showTime, timeFormat) => {
      return showTime ? { format: timeFormat || 'HH:mm:ss' } : false;
    };
  
    const renderDate = () => {
      if (filterData.mode === 'range') {
        return (
          <DatePicker.RangePicker
            format={filterData.date_format || 'YYYY-MM-DD'}
            size="small"
            ranges={{
              Today: [moment(), moment()],
              'Last 7 days': [moment().subtract(6, 'day'), moment()],
              'Last 30 days': [moment().subtract(29, 'day'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
            }}
            showTime={getShowTime(filterData.show_time, filterData.time_format)}
            onChange={onChange}
          />
        );
      }
      return (
        <DatePicker
          size="small"
          format={filterData.date_format || 'YYYY-MM-DD'}
          showTime={getShowTime(filterData.show_time, filterData.time_format)}
          onChange={onChange}
        />
      );
    };
  
    const renderSlider = () => {
      return (
        <Slider
          style={{ width: '100px' }}
          range
          defaultValue={[
            (filterData.defaultMinValue as number) ?? 0,
            (filterData.defaultMaxValue as number) ?? 0,
          ]}
          min={filterData.minValue || 0}
          max={filterData.maxValue || 1}
          onAfterChange={onChange}
        />
      );
    };
  
    const renderTime = () => {
      if (filterData.mode === 'range') {
        return (
          <TimePicker.RangePicker
            format={filterData.time_format || 'HH:mm:ss'}
            size="small"
            onChange={onChange}
          />
        );
      }
      return (
        <TimePicker
          format={filterData.time_format || 'HH:mm:ss'}
          size="small"
          onChange={onChange}
        />
      );
    };

    switch (filterData.type) {
        case 'input':
            return renderInput();
        case 'date':
            return renderDate();
        case 'time':
            return renderTime();
        case 'select':
        case 'search':
        case 'radio':
        case 'checkbox':
            return renderMultiSelectSearch();
        case 'slider_range':
            return renderSlider();
        default:
            return <div>INVALID FILTER: {filterData.id}</div>;
    }
};

const mapStateToProps = ({ genericPageReducer, cachedData }, ownProps) => {
    const { params } = ownProps;
    const { pageId, viewIndex, sectionIndex } = params;
    const pageData: PageData = genericPageReducer[pageId];
    const viewData = pageData.metadata.sectionList[sectionIndex][viewIndex];
    return {
        viewData,
        globalAppliedFilters: {
          hubIdList: [ cachedData.currHub?.id ],
          timeFilters: [
            {
              ...(pageData.appliedGlobalTimeFilter || {}),
            },
          ],
        },
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchViewDataObjects,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(
    withStyles(styles, { injectTheme: true })(GenericViewFilterComponent),
);