import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LAYOUT_VIEW_TYPES } from 'src/components/common/utils/constant';

const useLayout = (reportData, dashboardId, selectedLayoutView) => {
  const [processedlayout, setProcessedLayout] = useState([]);
  const [chartData, setChartData] = useState([]);
  const reportPaneHeights = useSelector(
    (state: any) => state.landingPageDashboardGroup?.reportPaneHeights,
  );
  const dashboardReportsChartConfigs = useSelector(
    (state: any) =>
      state.landingPageDashboardGroup?.dashboardReportsChartConfigs,
  );

  useEffect(() => {
    const layoutArr: any = [];
    const chartDataArr: any = [];
    const data = reportData || [];
    let currentHeight = 0;
    const isMobilePositionSet = data.some((chart) => chart.mobilePosition);
    const desktopNewPosition = { x: 0, y: 0, w: 8, h: 10 };
    data.forEach((chart, index) => {
      let height = chart.chartType === 'number' ? 5 : 10;
      if (chart.chartType === 'number') {
        const reportChartConfig = dashboardReportsChartConfigs
          ? dashboardReportsChartConfigs[chart.reportId]
          : {};
        const labelPresent = reportChartConfig?.graphic?.label?.data ?? true;
        if (reportPaneHeights && reportPaneHeights[chart.reportId]) {
          height = labelPresent
            ? Math.max((reportPaneHeights[chart.reportId] - 1) * 2 + 5, height)
            : Math.max(
                (reportPaneHeights[chart.reportId] - 1) * 1.5 + 4,
                height,
              );
        }
        if (reportPaneHeights && reportPaneHeights[chart.reportId] === 1) {
          height = labelPresent ? 4 : 3.5;
        }
      }
      const tabletOrMobileNewPosition = isMobilePositionSet
        ? desktopNewPosition
        : { x: 0, y: currentHeight, w: 12, h: height };
      const tabletOrMobilePosition =
        chart.mobilePosition || tabletOrMobileNewPosition;
      const desktopPosition = chart.position || desktopNewPosition;
      currentHeight += height;
      layoutArr.push({
        position: desktopPosition,
        mobilePosition: tabletOrMobilePosition,
        static: true,
        i: chart.reportId,
      });
      chartDataArr.push({ ...chart, id: chart.reportId });
    });
    setProcessedLayout(layoutArr);
    setChartData(chartDataArr);
  }, [reportData.length, dashboardId]);

  return {
    chartData,
    layout: processedlayout.map(
        ({ position, mobilePosition, ...rest }: any) => {
          const layoutPosition: any =
            selectedLayoutView === LAYOUT_VIEW_TYPES.MOBILE ? mobilePosition : position;
          return {
            ...layoutPosition,
            ...rest,
          };
        },
      ),
  };
};
export default useLayout;
