import { MoreColors } from 'src/theming/moreColors';
import { CssType, ThemeType } from '../../../../../../../theming/jssTypes';

const styles = (theme: ThemeType): CssType => ({
  containerDefaultFilter: {
    padding: '8px',
    backgroundColor: theme.colors.surfaceBg,
    display: 'flex',
    justifyContent: 'space-between',
    '& .ant-select-selector': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '28px !important',
      background: `${theme.colors.surfaceBg} !important`,
      color: theme.colors.textOnLightBg,
      borderRadius: '0px !important',
    },
    '& .ant-select-arrow': {
      color: theme.colors.textOnLightBg,
    },
    '& .ant-btn > .anticon': {
      lineHeight: '0px',
    }
  },
  childDiv: {
    alignItems: 'center',
    display: 'flex',
  },
  leftElement: {
    minWidth: '200px',
    marginRight: '16px',
  },
  selectStyle: {
    minWidth: 100,
    '& .ant-select-selection': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  inputStyle: {
    minWidth: 100,
    height: '28px',
    borderRadius: '0px !important',
  },
  settingButton: {
    color: MoreColors.DarkGrey,
    margin: '5px 10px 0 15px',
  },
});

export default styles;
