import { getViewControl, getViewBuckets } from '../components/pages/GenericAnalytics/config/utils';
import { getKeyArrayFromObject } from '../utils/utils';
const pagination = {
  resultPerPage: {
    path: 'pagination',
    fn : (value) => {
      return value ? value.perPageCount : undefined;
    },
  },
  lastSortByKey: {
    path: 'pagination',
    fn : (value) => {
      return value ? value.lastSortByKey : undefined;
    },
  },
  nextOrPrev: {
    path: 'pagination',
    fn : (value) => {
      return value ? value.nextOrPrev : undefined;
    },
  },
  lastSortedColumnValue: {
    path: 'pagination',
    fn : (value) => {
      return value ? getLastSortedColumnValue(value) : undefined;
    },
  },
  lastRowId: {
    path: 'pagination',
    fn : (value) => {
      return value ? getLastRowId(value) : undefined;
    },
  },
};
const feedbacksPagination = {
  requestPerPage: {
    path: 'pagination',
    fn : (value) => {
      return value ? value.perPageCount : undefined;
    },
  },
  page: {
    path: 'pagination',
    fn : (value) => {
      return value && value.page ? value.page : 1;
    },
  },
  isNextAvailableForFeedback: {
    path: 'pagination',
    fn : (value) => {
      return value && value.nextOrPrev === 'prev' ?
      true : value.isNextAvailableForFeedback;
    },
  },
};
const getLastSortedColumnValue = (pagination) => {
  switch (pagination.nextOrPrev) {
    case 'prev':
      return pagination.lastSortedColumnValuePrev;
    case 'next':
      return pagination.lastSortedColumnValueNext;
    default:
      return null;
  }
};

const getLastRowId = (pagination) => {
  switch (pagination.nextOrPrev) {
    case 'prev':
      return pagination.lastItemIdPrev;
    case 'next':
      return pagination.lastItemIdNext;
    default:
      return null;
  }
};
const getLastRowIdPaginationParams = (pagination, nextOrPrev) => {
  switch (nextOrPrev) {
    case 'prev':
      return pagination.lastRowIdPrev;
    case 'next':
      return pagination.lastRowIdNext;
    default:
      return null;
  }
};

const requestRetailSchema = {
  statusList: 'appliedFilters.status',
  hubIdList : 'globalAppliedFilters.hubId',
  timeFilters: (ite) => {
    return getViewBuckets(ite.viewType)[ite.bucket].filters.includes('date_type') ?
    [
      {
        fromDateString: ite.appliedFilters.startDate,
        columnName: ite.appliedFilters.dateFilterType,
        toDateString: ite.appliedFilters.endDate,
      },
    ] : undefined;
  },
  chartId: 'chart',
};
const responseModelTable = {
  isNextPresent: 'isNextPresent',
  lastItemIdNext: 'lastRowIdNext',
  lastItemIdPrev: 'lastRowIdPrev',
  lastSortByKey: 'lastSortByKey',
  lastSortedColumnValueNext: 'lastSortedColumnValueNext',
  lastSortedColumnValuePrev: 'lastSortedColumnValuePrev',
  page_data: 'pageData',
  paginationParams: 'paginationParams',
};
const responseCodModel = {
  page_data: 'data',
};
const responseCodHistoryModel = {
  isNextPresent: 'isNextPresent',
  paginationParams: 'paginationParams',
  page_data: 'pageData',
};

const getHubIdList = (iteratee) => {
  return iteratee.globalAppliedFilters.hubId || [iteratee.defaultHub.id];
};

const requestOnDemandSchema = {
  dayPartFilter: 'appliedFilters.dayPartFilter',
  timeSlot: 'appliedFilters.timeSlot',
  nonRegularOrders: 'appliedFilters.nonRegularOrders',
  storeType: 'appliedFilters.storeType',
  employeeType: 'appliedFilters.employeeType',
  finalConsignmentStatus: 'appliedFilters.finalConsignmentStatus',
  hubIdList : (iteratee) => {
    return getHubIdList(iteratee);
  },
  timeFilters: [
    {
      fromDateString: 'globalAppliedFilters.startDate',
      columnName: 'globalAppliedFilters.dateFilterType',
      toDateString: 'globalAppliedFilters.endDate',
    },
  ],
  chartId: 'chart',
  download: 'download',
  headerMapping : 'headerMapping',
  ...pagination,
  daysOfWeek: 'appliedFilters.daysOfWeek',
  tentNumber: 'appliedFilters.tentNumber',
  excludeDays: 'appliedFilters.excludeDays',
  outlierOrderMinDistance: 'appliedFilters.outlierOrderMinDistance',
  outlierOrderMaxDistance: 'appliedFilters.outlierOrderMaxDistance',
  excludeOutlierOrders: 'appliedFilters.excludeOutlierOrders',
  paginationParams: 'pagination.paginationParams',
};

const feedbacksOnDemandSchema = {
  fromDate: 'globalAppliedFilters.startDate',
  toDate: 'globalAppliedFilters.endDate',
  rating : 'appliedFilters.rating',
  hubIdList: 'globalAppliedFilters.hubId',
  timeFilterKey: 'globalAppliedFilters.dateFilterType',
  bucket: 'subBucket',
  bucketType: 'bucketType',
  feedbackReasonIds: 'appliedFilters.feedbackReasons',
  workerCodes: {
    path: 'appliedFilters.riderCode',
    fn : (value) => {
      return getKeyArrayFromObject(value);
    },
  },
  orderNumberOndemands: {
    path:  'globalAppliedFilters.searchKey',
    fn : (value) => {
      return value ? [value] : [];
    },
  },
};
const responseModelFeedbacksTable = {
  lastItemIdPrev: 'lastItemIdPrev',
  isNextPresent: 'isNextPresent',
  lastItemIdNext: 'lastItemIdNext',
  lastSortedColumnValuePrev: 'lastSortedColumnValuePrev',
  lastSortedColumnValueNext: 'lastSortedColumnValueNext',
  lastSortByKey: 'lastSortByKey',
  isNextAvailableForFeedback: 'isNextAvailableForFeedback',
  nextOrPrev: 'nextOrPrev',
  page: 'page',
  page_data: 'feedback',
};
const requestOnDemandSchemaForSubBuckets = {
  filters: {
    ...feedbacksOnDemandSchema,
  },
};

const requestOnDemandSchemaForFeedbacks = {
  ...feedbacksOnDemandSchema,
  ...feedbacksPagination,
};
const requestOnDemandSchemaPunchingTable = {
  ...requestOnDemandSchema,
  paginationParams : {
    path : 'pagination',
    fn : (pagination) => {
      if (!Array.isArray(pagination.paginationParams)) {
        return [];
      }
      return pagination.paginationParams.map((x) => {
        return {
          sortByKey : x.sortByKey,
          lastRowId :  getLastRowIdPaginationParams(x, pagination.nextOrPrev),
        };
      });
    },
  },
};
export { requestRetailSchema, requestOnDemandSchema, responseModelTable,
  feedbacksOnDemandSchema, requestOnDemandSchemaForSubBuckets, requestOnDemandSchemaForFeedbacks,
  responseModelFeedbacksTable, requestOnDemandSchemaPunchingTable, responseCodModel,
  responseCodHistoryModel };
