import Icon from '@ant-design/icons';
import * as React from 'react';
const EmptyFileIconSvg = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="24" viewBox="0 0 20 24" fill="none">
    <g clip-path="url(#clip0_26_5797)">
    <path d="M18.25 24H1.75C1.28932 24 0.847201 23.8184 0.519581 23.4945C0.191961 23.1707 0.00526496 22.7307 0 22.27L0 1.73C0.00526496 1.26935 0.191961 0.829347 0.519581 0.505472C0.847201 0.181596 1.28932 -3.00827e-05 1.75 3.73728e-09H18.25C18.7107 -3.00827e-05 19.1528 0.181596 19.4804 0.505472C19.808 0.829347 19.9947 1.26935 20 1.73V22.27C19.9947 22.7307 19.808 23.1707 19.4804 23.4945C19.1528 23.8184 18.7107 24 18.25 24ZM2 22H18V2H2V22Z" fill="#666666"/>
    <path d="M9 10H5C4.73478 10 4.48043 9.89464 4.29289 9.70711C4.10536 9.51957 4 9.26522 4 9V5C4 4.73478 4.10536 4.48043 4.29289 4.29289C4.48043 4.10536 4.73478 4 5 4H9C9.26522 4 9.51957 4.10536 9.70711 4.29289C9.89464 4.48043 10 4.73478 10 5V9C10 9.26522 9.89464 9.51957 9.70711 9.70711C9.51957 9.89464 9.26522 10 9 10ZM6 8H8V6H6V8Z" fill="#666666"/>
    <path d="M16 14H4C3.73478 14 3.48043 13.8946 3.29289 13.7071C3.10536 13.5196 3 13.2652 3 13C3 12.7348 3.10536 12.4804 3.29289 12.2929C3.48043 12.1054 3.73478 12 4 12H16C16.2652 12 16.5196 12.1054 16.7071 12.2929C16.8946 12.4804 17 12.7348 17 13C17 13.2652 16.8946 13.5196 16.7071 13.7071C16.5196 13.8946 16.2652 14 16 14Z" fill="#666666"/>
    <path d="M16 17H4C3.73478 17 3.48043 16.8946 3.29289 16.7071C3.10536 16.5196 3 16.2652 3 16C3 15.7348 3.10536 15.4804 3.29289 15.2929C3.48043 15.1054 3.73478 15 4 15H16C16.2652 15 16.5196 15.1054 16.7071 15.2929C16.8946 15.4804 17 15.7348 17 16C17 16.2652 16.8946 16.5196 16.7071 16.7071C16.5196 16.8946 16.2652 17 16 17Z" fill="#666666"/>
    <path d="M11 20H4C3.73478 20 3.48043 19.8946 3.29289 19.7071C3.10536 19.5196 3 19.2652 3 19C3 18.7348 3.10536 18.4804 3.29289 18.2929C3.48043 18.1054 3.73478 18 4 18H11C11.2652 18 11.5196 18.1054 11.7071 18.2929C11.8946 18.4804 12 18.7348 12 19C12 19.2652 11.8946 19.5196 11.7071 19.7071C11.5196 19.8946 11.2652 20 11 20Z" fill="#666666"/>
    <path d="M16 7H12C11.7348 7 11.4804 6.89464 11.2929 6.70711C11.1054 6.51957 11 6.26522 11 6C11 5.73478 11.1054 5.48043 11.2929 5.29289C11.4804 5.10536 11.7348 5 12 5H16C16.2652 5 16.5196 5.10536 16.7071 5.29289C16.8946 5.48043 17 5.73478 17 6C17 6.26522 16.8946 6.51957 16.7071 6.70711C16.5196 6.89464 16.2652 7 16 7Z" fill="#666666"/>
    <path d="M16 10H12C11.7348 10 11.4804 9.89464 11.2929 9.70711C11.1054 9.51957 11 9.26522 11 9C11 8.73478 11.1054 8.48043 11.2929 8.29289C11.4804 8.10536 11.7348 8 12 8H16C16.2652 8 16.5196 8.10536 16.7071 8.29289C16.8946 8.48043 17 8.73478 17 9C17 9.26522 16.8946 9.51957 16.7071 9.70711C16.5196 9.89464 16.2652 10 16 10Z" fill="#666666"/>
    </g>
    <defs>
    <clipPath id="clip0_26_5797">
    <rect width="20" height="24" fill="white"/>
    </clipPath>
    </defs>
    </svg>
);

const EmptyFileIcon = (props: any) => (
  <Icon component={EmptyFileIconSvg} {...props} />
);

export default EmptyFileIcon;
