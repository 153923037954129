import * as React from 'react';
import * as moment from 'moment';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '../../theming/jssTypes';
import { Button } from 'antd';
import { CloseOutlined, InfoCircleFilled } from '@ant-design/icons';
const styles = (theme: ThemeType): CssType => ({
  scheduledMaintenanceBanner: {
    backgroundColor: '#E3B104',
    padding: '15px',
    flexDirection: 'row',
    display: 'flex',
  },
  icon: {
    color: 'white',
    fontSize: '18px',
    marginRight: '10px',
    height: '100%',
  },
  textDiv: {
    display: 'inline-block',
    color: '#FFFFFF',
    fontFamily: 'Open Sans',
    fontSize: '15px',
    verticalAlign: 'top',
    marginTop: '-1.5px',
  },
  closeButton: {
    border: 'none',
    backgroundColor: '#E3B104',
    color: '#FFFFFF',
    outline: 'none',
    height: '100%',
  },
});

const ScheduledMaintenanceBanner = (props: any) => {
  const { scheduledMaintenanceConfig, onBannerClose, classes } = props;

  const scheduledMaintenanceMessage = scheduledMaintenanceConfig.banner_message;

  const onCloseClick = async () => {
    onBannerClose();
  };

  return (
    <div className={classes.scheduledMaintenanceBanner}>
      <InfoCircleFilled className={classes.icon} />
      <div className={classes.textDiv}>{scheduledMaintenanceMessage}</div>
      <Button
        type="text"
        className={classes.closeButton}
        onClick={onCloseClick}
      >
        <CloseOutlined className={classes.icon} />
      </Button>
    </div>
  );
};

export default withStyles(styles, { injectTheme: true })(
  ScheduledMaintenanceBanner,
);
