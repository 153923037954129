import * as React from 'react';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '../../theming/jssTypes';
import { Modal, Table } from 'antd';
import * as moment from 'moment';
import { withTranslation } from 'react-i18next';

const styles = (theme: ThemeType): CssType => ({
  tableContainer: {
    flex: '1 1 0',
    overflow: 'auto',
    height: 300,
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
    '& .ant-table-tbody > tr > td, .ant-table-thead > tr > th': {
      padding: 9,
    },
  },
});

class EwayBillListModal extends React.Component<any, any> {
  generateDataColumns = () => {
    const { t } = this.props;
    const columnData: any = [
      {
        title: t('S.No.'),
        key: 'index',
        render: (text, record, index) => index + 1,
        width: 30,
      },
      {
        title: t('EWB No.'),
        key: 'ewbNumber',
        width: 10,
        render: (text, record) => record['ewbNumber'] ?? '',
      },
      {
        title: t('Expiry'),
        key: 'validTill',
        width: 30,
        render: (text, record) =>
          record['validTill']
            ? moment(record['validTill']).format('DD MMM YYYY')
            : '',
      },
      {
        title: t('EWB PartB Generation Time'),
        key: 'ewbPartBGenerationTime',
        width: 30,
        render: (text, record) =>
          record['ewbPartBGenerationTime']
            ? moment(record['ewbPartBGenerationTime']).format('DD MMM YYYY')
            : '',
      },
    ];
    return columnData;
  };
  render() {
    const { isVisible, classes, ewayBillList, t } = this.props;
    const header = 'E-Way Bill List';
    return (
      <Modal
        title={t(header)}
        width={500}
        visible={isVisible}
        footer={null}
        onCancel={() => this.props.handleModalClose(false)}
        destroyOnClose
      >
        <div className={classes.tableContainer}>
          <Table
            locale={{ emptyText: t('No EWBs') }}
            pagination={false}
            columns={this.generateDataColumns()}
            dataSource={ewayBillList}
          />
        </div>
      </Modal>
    );
  }
}

export default withTranslation('translation')(withStyles(styles, { injectTheme: true })(EwayBillListModal));

