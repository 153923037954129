export const FETCH_STRATEGY_DESCRIPTION = '/dashboard/routingPlayground/getStrategiesDescription';
export const FETCH_STRATEGY_MASTERDATA = '/dashboard/routingPlayground/getStrategyMetaData';
export const ALL_PARTITION = '/playground/partitions/fetch';
export const ALL_HUB = '/dashboard/routingPlayground/getHubMasterData';
export const PARTITON_UPDATE = '/playground/strategy/updateparams';
export const APPLY_STRATEGY = '/playground/strategy/sethubstrategy';
export const APPLY_STRATEGY_FOR_MULTIPLE =
  '/dashboard/routingPlayground/setHubStrategyMultiple';
export const UPDATE_PARAMS = '/dashboard/routingPlayground/updateHubStrategyData';
export const FETCH_PARAMS = '/dashboard/routingPlayground/getHubStrategyData';
export const TEST_CONFIGURATION = '/dashboard/routingPlayground/runStrategy';
export const SET_CONFIG_AS_MASTER = '/dashboard/routingPlayground/setMasterConfig';
export const FETCH_HUB_WITH_FILTER = '/dashboard/routingPlayground/getHubMasterData';
export const FETCH_OSM_POLYGONS = '/dashboard/routingPlayground/fetchOsmPolygons';
export const EXCEL_UPLOAD_ROUTING_PLAYGROUND =
  '/dashboard/routingPlayground/excelUpload';
export const GET_MASTER_CONFIG = '/dashboard/routingPlayground/getMasterConfig';
export const CLEAN_PARTITION = '/dashboard/routingPlayground/cleanPolygons';
export const MERGE_PARTITION = '/dashboard/routingPlayground/mergePolygons';
export const SIGNED_URL = '/dashboard/territoryOptimisation/getSignedUrl';
export const NEW_SIMULATION_REQUEST = '/dashboard/territoryOptimisation/addOne';
export const GET_ALL_SIMULATION_REQUEST =
  '/dashboard/territoryOptimisation/requests/fetch';
export const DELETE_SIMULATION_REQUESTS =
  '/dashboard/territoryOptimisation/deleteSimulationRequests';
export const GET_AVAILABLE_AREA =
  '/dashboard/territoryOptimisation/areaList/fetch';
export const GET_SIMULATION_DETAILS =
  '/dashboard/territoryOptimisation/getSimulationDetails';
export const GET_SIMULATION_PARTITIONS =
  '/dashboard/territoryOptimisation/getSimulationResults';
export const FETCH_HUBS = '/dashboard/territoryOptimisation/hubList/fetch';
export const FETCH_PROFILES = '/dashboard/territoryOptimisation/profiles/fetch';
export const SAVE_PROFILE = '/dashboard/territoryOptimisation/update/profile';
export const GET_POLYLINE = '/dashboard/routingPlayground/getPolyLineForTrip';
export const SAVE_GEOFENCE = '/dashboard/territoryOptimisation/save/geofence';

export const FETCH_TRIP_REQUESTS =
  '/dashboard/routingPlayground/getAllApiOptimiserRequest';
export const FETCH_TRIP_DETAILS =
  '/dashboard/routingPlayground/getRequestDetails';
export const DELETE_REQUESTS = '/dashboard/routingPlayground/deleteRequest';
export const UPDATE_REQUESTS_PARAMS =
  '/dashboard/routingPlayground/updateRequestParams';
export const FETCH_TO_INPUT_PARAMS =
  '/dashboard/territoryOptimisation/fetchParams';

export const SMOOTHEN_POLYGON = '/dashboard/routingPlayground/smoothenPolygons';
export const SIMPLIFY_POLYGON = '/dashboard/routingPlayground/simplifyPolygons';

export const RECALCULATE_METRICS =
  '/dashboard/territoryOptimisation/fetchMetrics';
export const UPDATE_REQUEST_DATA =
  '/dashboard/territoryOptimisation/updateRequestParams';
export const DELETE_SUB_SCENARIO =
  '/dashboard/territoryOptimisation/deleteSubScenario';

export const UPSERT_TAG = '/Dashboard/tag/partition/upsert';
export const FETCH_TAGS = '/Dashboard/tag/partition/fetch';

export const FETCH_PARTITIONS = '/Dashboard/partition/fetch';
export const UPDATE_PARTITIONS = '/Dashboard/partition/upsert';
// geocoding playground
export const GET_META_DATA = '/dashboard/geocodingPlayground/getMetaData';
export const GEOCODE_MULTIPLE =
  '/dashboard/geocodingPlayground/geoCodeMultiple';

// Hub optimisation
export const HUB_OPTIMISATION = '/dashboard/hubOptimisation/request';
export const HUB_OPTIMISATION_UPDATE = '/dashboard/hubOptimisation/request/update';

// reverse geocode
export const REVERSE_GEOCODE = '/dashboard/geocodingPlayground/reverseGeocodeMultiple';

// geocoding config
export const GET_GEOCODING_CONFIG = '/dashboard/geocodingPlayground/getGeocodingConfig';
export const UPDATE_GEOCODING_CONFIG = '/dashboard/geocodingPlayground/updateGeocodingConfig';
export const GET_AUDIT_LOG = '/dashboard/geocodingPlayground/getGeocodingConfigAuditLog';
