import {
  getAllConsignmentsDataSupportPortal,
} from '../../api/genericConsignmentView';
const viewControl = {
  pageTitle : 'Support Team Portal',
  buckets : [
    'retail_dc_all',
  ],
  navBar : {
    left : [],
    right : [],
  },
  fetchData : getAllConsignmentsDataSupportPortal,
};
const buckets = {
  retail_dc_all: {
    name: 'Support Team Portal',
    filters : [
    ],
    actions: [
    ],
    status_filter : [
    ],
    isSubBucket : false,
  },
};
export default { viewControl, buckets };
