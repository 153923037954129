import { morphism } from 'morphism';
import { API_BASE_URL } from '@src/globals';
import { POST, GET } from '@api/apiHandler';
import {
    FETCH_ALL_ADDRESS_REGISTER,
    DOWNLOAD_ADDRESS_REGISTER,
    SAMPLE_CSV_ADDRESS_REGISTER_CREATE,
    SAMPLE_CSV_ADDRESS_REGISTER_UPDATE,
    BULK_CREATE_ADDRESS_REGISTER,
    BULK_UPDATE_ADDRESS_REGISTER,
    CREATE_ADDRESS_REGISTER,
    UPDATE_ADDRESS_REGISTER,
    BASE_URL_ADDRESS_REGISTER,
    FETCH_ADDRESS_REGISTER_DETAILS,
} from '@api/routes';

import {
  requestModelAddressRegister,
  responseModelAddressRegister,
} from '@model/consignmentView';

export const fetchAllAddressRegisterList = async(params = {}) => {
  const body = {
    ...morphism(requestModelAddressRegister, params),
  };
  const response = await POST(`${API_BASE_URL}${FETCH_ALL_ADDRESS_REGISTER}`, body);
  return {
    ...morphism(responseModelAddressRegister, response),
  };
};

export const fetchSavedAddressesFromRegister = async(params = {}) => {
  const body = {
   ...params,
  };
  const response = await POST(`${API_BASE_URL}${FETCH_ALL_ADDRESS_REGISTER}`, body);
  return {
    ...morphism(responseModelAddressRegister, response),
  };
};

export const downloadAddressRegister = async() => {
  return GET(`${API_BASE_URL}${DOWNLOAD_ADDRESS_REGISTER}`);
};

export const downloadSampleCsvForCreate = async() => {
  return GET(`${API_BASE_URL}${SAMPLE_CSV_ADDRESS_REGISTER_CREATE}`);
};

export const downloadSampleCsvForUpdate = async() => {
  return GET(`${API_BASE_URL}${SAMPLE_CSV_ADDRESS_REGISTER_UPDATE}`);
};

export const uploadCsvForCreate = async(body) => {
  return POST(`${API_BASE_URL}${BULK_CREATE_ADDRESS_REGISTER}`, body);
};

export const uploadCsvForUpdate = async(body) => {
  return POST(`${API_BASE_URL}${BULK_UPDATE_ADDRESS_REGISTER}`, body);
};

export const createAddressRegister = async(body) => {
  return POST(`${API_BASE_URL}${CREATE_ADDRESS_REGISTER}`, body);
};

export const updateAddressRegister = async(addressId, body) => {
  return POST(`${API_BASE_URL}${BASE_URL_ADDRESS_REGISTER}/${addressId}${UPDATE_ADDRESS_REGISTER}`, body);
};

export const getAddressDetail = async(addressId) => {
  return GET(`${API_BASE_URL}${BASE_URL_ADDRESS_REGISTER}/${addressId}${FETCH_ADDRESS_REGISTER_DETAILS}`);
};
