import { BarcodeOutlined } from '@ant-design/icons';
import * as React from 'react';
export const SeriesConsumptionIcon = (props) => {
  return (
    <BarcodeOutlined
      style={{
        color: 'grey',
        fontSize: '18px',
      }}
    />
  );
};
