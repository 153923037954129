import {
  FETCH_VIEW_DATA,
  FETCH_VIEW_DATA_OBJECTS,
  SET_PAGE_FOR_EMPLOYEE,
  SET_VIEW_DATA,
  SET_VIEW_DATA_OBJECTS,
  VIEW_TAB_CHANGE,
  GLOBAL_FILTER_CHANGE,
  VIEW_SET_SELECTED_ROW_KEYS,
} from '../actions/constants';
import { PageData, PageDataResponseType } from 'src/types/page.types';

const defaultState: {
  [key: string]: PageData,
} = {};

export default function (state = defaultState, action) {
  switch (action.type) {
    case SET_PAGE_FOR_EMPLOYEE: {
      const pages: PageDataResponseType[] = action.data;
      const newState = pages.reduce<{[key: string]: PageData}>((acc, page) => {
        if (!acc[page.id]) {
          acc[page.id] = new PageData(page);
        }
        return acc;
      }, {});
      return {
        ...state,
        ...newState,
      };
    }
    case FETCH_VIEW_DATA: {
      const { sectionIndex, viewIndex, pageId } = action.data;
      const pageData = state[pageId];
      const oldViewData = pageData.metadata.sectionList[sectionIndex][viewIndex];
      const newViewData = oldViewData.clone();
      newViewData.isLoading = true;
      newViewData.error = null;
      pageData.metadata.sectionList[sectionIndex][viewIndex] = newViewData;
      return {
        ...state,
        [pageId]: pageData,
      };
    }
    case SET_VIEW_DATA: {
      const { sectionIndex, viewIndex, pageId, viewData, error } = action.data;
      const pageData = state[pageId];
      const oldViewData = pageData.metadata.sectionList[sectionIndex][viewIndex];
      const newViewData = oldViewData.clone();
      if (viewData) {
        newViewData.setViewData(viewData);
      } else {
        newViewData.error = error || 'Something went wrong';
      }
      newViewData.isLoading = false;
      pageData.metadata.sectionList[sectionIndex][viewIndex] = newViewData;
      return {
        ...state,
        [pageId]: pageData,
      };
    }
    case FETCH_VIEW_DATA_OBJECTS: {
      const { sectionIndex, viewIndex, pageId } = action.data;
      const pageData = state[pageId];
      const oldViewData = pageData.metadata.sectionList[sectionIndex][viewIndex];
      const newViewData = oldViewData.clone();
      newViewData.isFetchingData = true;
      newViewData.fetchError = null;
      pageData.metadata.sectionList[sectionIndex][viewIndex] = newViewData;
      return {
        ...state,
        [pageId]: pageData,
      };
    }
    case SET_VIEW_DATA_OBJECTS: {
      const { sectionIndex, viewIndex, pageId, data, error, tabChange } = action.data;
      const pageData = state[pageId];
      const oldViewData = pageData.metadata.sectionList[sectionIndex][viewIndex];
      const newViewData = oldViewData.clone();
      if (data) {
        newViewData.setViewDataObjects(data);
      } else {
        newViewData.fetchError = error || 'Something went wrong';
      }
      if (tabChange) {
        newViewData.shouldFetchDataOnTabChange = false;
      }
      newViewData.isFetchingData = false;
      pageData.metadata.sectionList[sectionIndex][viewIndex] = newViewData;
      return {
        ...state,
        [pageId]: pageData,
      };
    }
    case VIEW_TAB_CHANGE: {
      const { pageId, viewId, sectionIndex } = action.data;
      const pageData = state[pageId];
      const newPageData = pageData.clone();
      newPageData.activeViews[sectionIndex] = viewId;

      return {
        ...state,
        [pageId]: newPageData,
      };
    }
    case GLOBAL_FILTER_CHANGE: {
      const { pageId, timeFilter } = action.data;
      let pageData = state[pageId];
      if (timeFilter) {
        pageData = pageData.clone();
        const {
          columnName,
          fromDateString,
          toDateString,
        } = timeFilter;
        pageData.setGlobalTimeFilter(columnName, fromDateString, toDateString);
      }

      pageData.metadata.sectionList.forEach((sectionData, sectionIndex) => {
        const currentView = pageData.activeViews[sectionIndex];
        sectionData.forEach((viewData, viewIndex) => {
          if (viewData.id !== currentView) {
            const newViewData = viewData.clone();
            newViewData.shouldFetchDataOnTabChange = true;
            pageData.metadata.sectionList[sectionIndex][viewIndex] = newViewData;
          }
        });
      });

      return {
        ...state,
        [pageId]: pageData,
      };
    }

    case VIEW_SET_SELECTED_ROW_KEYS: {
      const { sectionIndex, viewIndex, pageId, selectedRowKeys } = action.data;
      const pageData = state[pageId];
      const oldViewData = pageData.metadata.sectionList[sectionIndex][viewIndex];
      const newViewData = oldViewData.clone();
      newViewData.setSelectedRowKeys(selectedRowKeys);
      pageData.metadata.sectionList[sectionIndex][viewIndex] = newViewData;

      return {
        ...state,
        [pageId]: pageData,
      };
    }

    default: return state;
  }
}
