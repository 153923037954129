import { Select } from 'antd';
import * as React from 'react';
const Option = Select.Option;
export function  generateTimeSlotsOptionsFromList(timeSlots)  {
  const optionList  = timeSlots && timeSlots.length ?
  timeSlots.map((slot) => {
    const propToSet = `${slot.start_time}_${slot.end_time}_${slot.pretty_name}`;
    return <Option key={propToSet} value={propToSet}>
    {slot.start_time}Hrs-{slot.end_time}Hrs</Option>;
  })  : [];
  return optionList;
}
