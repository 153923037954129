import * as moment from 'moment';
import {
  INVOICE_ENABLE_LOADING,
  INVOICE_DISABLE_LOADING,
  INVOICE_UPDATE_FILTER,
  INVOICE_RESET_FILTER,
  INVOICE_PAGINATION,
  INVOICE_RESET_PAGINATION,
  INVOICE_UPDATE_DATA,
  INVOICE_SET_SELECTED_DATA,
 } from '../actions/constants';

const defaultState = {
  isLoading: false,
  invoiceData: [],
  appliedFilters: {
    customerCodes: [],
    startDate: moment().subtract(7, 'd').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    isVatApplicable: false,
    vat: null,
    dateFilterType: 'updated',
    status: [],
    serviceTypeId: [],
    isFTL: false,
    generationStatus: 'All',
    invoiceNumber: '',
    invoiceSentStatus: 'All',
  },
  // More Filters component will always recive data from temp
  pagination: {
    currentPageNumber: 1,
    perPageCount: 50,
  },
  selectedRowKeys: [],
};

export default function (state: any = defaultState, action) {
  switch (action.type) {
    case INVOICE_UPDATE_DATA: {
      return {
        ...state,
        // Data of all columns will be here
        // Need to filter on the basis of columns_to_display
        invoiceData: action.data.data,
        pagination: {
          ...state.pagination,
          isNextPresent: action.data ? action.data.isNextPresent : null,
        },
      };
    }
    case INVOICE_SET_SELECTED_DATA: {
      return {
        ...state,
        selectedRowKeys: action.data,
      };
    }
    case INVOICE_ENABLE_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case INVOICE_DISABLE_LOADING: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case INVOICE_UPDATE_FILTER: {
      return {
        ...state,
        appliedFilters: {
          ...state.appliedFilters,
          ...action.data,
        },
      };
    }
    case INVOICE_RESET_FILTER: {
      return {
        ...state,
        appliedFilters: defaultState.appliedFilters,
      };
    }
    case INVOICE_PAGINATION: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.data,
        },
      };
    }
    case INVOICE_RESET_PAGINATION: {
      return {
        ...state,
        pagination: {
          ...defaultState.pagination,
          perPageCount: state.pagination.perPageCount,
        },
      };
    }
    default: return state;
  }
}
