import {
  getOidcCallbackQueryParams,
} from './utils';
import { API_BASE_URL } from '../globals';
import { POST, GET } from '@api/apiHandler';
import {
  FETCH_ORGANISATION_DETAILS, LOGIN, FORGOT_PASSWORD, SSO_OIDC_CALLBACK_URL,
  VALIDATE_OTP, UPDATE_EMPLOYEE_PASSWORD, GET_AVAILABLE_SSO_FOR_LOGIN, GET_OIDC_OAUTH_URL,
} from './routes';

export const getOrganisationDetails = async (params) => {
  const { organisationPrettyName, origin } = params;

  return GET(`${API_BASE_URL}${FETCH_ORGANISATION_DETAILS}`, {}, null, {
    'organisation-pretty-name': organisationPrettyName,
    'organisation-url': origin,
  });
};

export const login = async ( headers, body ) => {
  return POST(`${API_BASE_URL}${LOGIN}`, body, headers);
};

export const forgotPassword = async ( headers, body ) => {
  return POST(`${API_BASE_URL}${FORGOT_PASSWORD}`, body, headers);
};

export const validateOTP = async ( headers, body ) => {
  return POST(`${API_BASE_URL}${VALIDATE_OTP}`, body, headers);
};

export const updateEmployeePassword = async ( headers, body ) => {
  return POST(`${API_BASE_URL}${UPDATE_EMPLOYEE_PASSWORD}`, body, headers);
};

export const getAvailableSSOForLogin = async (params) => {
  const { organisationPrettyName, origin } = params;

  return GET(`${API_BASE_URL}${GET_AVAILABLE_SSO_FOR_LOGIN}`, {}, null, {
    'organisation-pretty-name': organisationPrettyName,
    'organisation-url': origin,
  });
};

export const getOidcOAuthUrl = async (params) => {
  const { organisationPrettyName, origin } = params;

  return GET(
    `${API_BASE_URL}${GET_OIDC_OAUTH_URL}?id=${params.id}&referrer=${params.referrer}`,
    {},
    null,
    {
      'organisation-pretty-name': organisationPrettyName,
      'organisation-url': origin,
    }
  );
};

export const callbackOidcOAuthUrl = async (params) => {
  const { issuer, organisationId } = params;

  return GET(`${API_BASE_URL}${SSO_OIDC_CALLBACK_URL}/${issuer}/${organisationId}?${getOidcCallbackQueryParams(params)}`);
};
