import {
  searchCustomerCode,
  searchHubWithrealFilter,
  searchShelfNumber,
} from '@api/dashboard';
import { tmsBagExceptionSearch } from '@api/TMSDashboard/genericTMSView';
import { searchFilteredRiderCode } from '@api/genericAnalyticsView';
import { searchZone } from '@api/genericConsignmentView';

export const filterFormItemLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};
export const filterTailLayout = {
  wrapperCol: { span: 24 },
};
export const defaultTokenSeperators = [',', ' '];

export const isInvalidFilter = (value) => {
  if (
    value === null ||
    value === undefined ||
    ((typeof value === 'string' || Array.isArray(value)) && !value.length) ||
    (value instanceof Object && !Object.keys(value).length)
  ) {
    return true;
  }
  return false;
};
export const sanitiseObject = (filters) => {
  const obj = { ...filters };
  for (const i in obj) {
    if (isInvalidFilter(obj[i])) {
      delete obj[i];
    }
  }
  return obj;
};

const customerCodeSearch = async (value) => {
  const response = await searchCustomerCode(value);
  let customerCodes = response.data;
  customerCodes = customerCodes
    ? customerCodes.map((cc: any) => ({
        key: cc.id,
        label: cc.name + ', ' + cc.code,
      }))
    : [];
  return customerCodes;
};

const shelfNumberSearch = async (value) => {
  const response = await searchShelfNumber(value);
  let shelfNumbers = response.data;
  shelfNumbers = shelfNumbers
    ? shelfNumbers.map((cc: any) => ({
        key: cc.id,
        label: cc.shelfnumber,
      }))
    : [];
  return shelfNumbers;
};

const exceptionSearch = async (value) => {
  const response = await tmsBagExceptionSearch(value);
  let exceptions = response.data;
  exceptions = exceptions
    ? exceptions.map((cc: any) => ({
        key: cc.bag_exception_id,
        label: `${cc.bag_exception_name} (${cc.bag_exception_code})`,
      }))
    : [];
  return exceptions;
};

const hubIdSearch = (strategy) => async (value) => {
  const response =
    strategy === 'store_hub_search'
      ? await searchHubWithrealFilter(true)(value, 'upcountry')
      : await searchHubWithrealFilter(true)(value);
  let hubIdList = response.data;
  hubIdList = hubIdList
    ? hubIdList.map((hub: any) => ({
        key: hub.id,
        label: `${hub.name}, ${hub.code}`,
      }))
    : [];
  return hubIdList;
};

const riderCodeSearch = async (value, hubIds?: any) => {
  const response = await searchFilteredRiderCode(value, hubIds);
  const riderCodes = response.data
    ? response.data.map((rider) => {
        return { key: rider.id, label: `${rider.name}, ${rider.code}` };
      })
    : [];
  return riderCodes;
};

const zoneCodeSearch = async (value) => {
  const params = { q: value };
  const response = await searchZone(params);
  let zoneCodes = response.data;
  zoneCodes = zoneCodes
    ? zoneCodes.map((zone: any) => ({
        key: zone.node_code,
        label: `${zone.node_name}, ${zone.node_code}`,
      }))
    : [];
  return zoneCodes;
};

const defaultFunction = (value) => {
  return [];
};

export const searchStrategy = (strategy) => {
  switch (strategy) {
    case 'hub_search':
      return hubIdSearch(strategy);
    case 'store_hub_search':
      return hubIdSearch(strategy);
    case 'exception_search':
      return exceptionSearch;
    case 'customer_code_search':
      return customerCodeSearch;
    case 'rider_code_search':
      return riderCodeSearch;
    case 'zone_code_search':
      return zoneCodeSearch;
    case 'shelf_search':
      return shelfNumberSearch;
    default:
      return defaultFunction;
  }
};
