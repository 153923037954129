import TripMapEditUnplannedTaskTab from './TripMapEditUnplannedTaskTab/TripMapEditUnplannedTaskTab.component';
import TripMapEditTripTab from './TripMapEditTripTab/TripMapEditTripTab.component';
import TripManagerDrivers from './TripMapEditTripTab/TripManagerDrivers.component';
import {
  getAllConsignmentsData,
  updateUserTripColumnList,
} from 'src/api/genericConsignmentView';
import {
  getFullVehicleMakeList,
  getFullVehiclesList,
  getFullWorkerList,
  getPlannedTripTrackingData,
  getRiderTripData,
} from 'src/api/trips';

export const MAP_EDIT_VIEW_TYPE = 'retailTripMapEdit';

export enum MAP_EDIT_BUCKETS {
  MAP_TRIPS = 'map_view_trips',
  MAP_UNPLANNED = 'map_view_unplanned',
  DRIVERS = 'drivers',
}

const viewControl = {
  pageTitle: 'Edit',
  buckets: [MAP_EDIT_BUCKETS.MAP_TRIPS, MAP_EDIT_BUCKETS.DRIVERS, MAP_EDIT_BUCKETS.MAP_UNPLANNED],
  navBar: {
    left: ['date_filter'],
    right: [],
  },
  globalActions: [],
  dateFilterOptions: [
    { key: 'created_at', val: 'Created At' },
    { key: 'last_main_event_time', val: 'Last Updated' },
  ],
};

const buckets = {
  [MAP_EDIT_BUCKETS.MAP_TRIPS]: {
    hidePagination: true,
    hidePerPageCount: true,
    name: 'Trips',
    filters: ['planning_id', 'status', 'trip_type', 'vehicleType', 'worker_id'],
    actions: [],
    isSubBucket: false,
    tabComponent: TripMapEditTripTab,
    fetchData: getPlannedTripTrackingData,
    updateUserColumnList: updateUserTripColumnList,
  },
  [MAP_EDIT_BUCKETS.DRIVERS]: {
    hidePagination: true,
    hidePerPageCount: true,
    name: 'Drivers',
    filters: [],
    actions: [],
    isSubBucket: false,
    fetchData: getRiderTripData,
    tabComponent: TripManagerDrivers,
  },
  [MAP_EDIT_BUCKETS.MAP_UNPLANNED]: {
    hidePagination: true,
    hidePerPageCount: true,
    name: 'Unplanned Tasks',
    filters: ['service_type', 'consignment_type', 'customer_code'],
    actions: [],
    isSubBucket: false,
    tabComponent: TripMapEditUnplannedTaskTab,
    fetchData: getAllConsignmentsData,
  },
};

const bucketColor = {
  map_trips: '#EB2F96',
  map_unplanned: '#722ED1',
};

export const MapEditViewControls = { viewControl, buckets, bucketColor };

export const MAP_EDIT_TRIP_TAB_CN_COLUMN_LIST = [
  {
    column_id: 'reference_number',
    pretty_name: 'CN #',
  },
  {
    pretty_name: 'Consignment Type',
    column_id: 'consignment_type',
  },
  {
    pretty_name: 'Address',
    column_id: 'receiver_address',
  },
  {
    pretty_name: 'Planned ETA',
    column_id: 'planned_eta',
  },
  {
    pretty_name: 'Service Time',
    column_id: 'service_time_mins',
  },
  {
    pretty_name: 'Departure Time',
    column_id: 'departure_time',
  },
  {
    pretty_name: 'Travel Distance',
    column_id: 'travelled_distance_in_km',
  },
  {
    pretty_name: 'Tags',
    column_id: 'constraint_tags',
  },
];

export const VEHICLE_TYPE_OPTIONS = [
  { label: 'All', value: 'all' },
  { label: 'Dedicated', value: 'DEDICATED' },
  { label: 'Market', value: 'MARKET' },
  { label: 'Dummy', value: 'DUMMY' },
];

export const TRIP_TYPE_OPTIONS = [
  { label: 'All', value: 'all' },
  { label: 'Primary', value: 'primary' },
  { label: 'Secondary', value: 'secondary' },
  { label: 'Direct', value: 'direct' },
];

export const STATUS_LIST_OPTIONS = [
  { label: 'All', value: 'all' },
  { label: 'Created', value: 'created' },
  { label: 'Assigned', value: 'assigned' },
];

export enum TRIP_OFFLINE_ACTIONS {
  UPDATE_VEHICLE_MAKE = 'UPDATE_VEHICLE_MAKE',
  UPDATE_VEHICLE = 'UPDATE_VEHICLE',
  UPDATE_WORKER = 'UPDATE_WORKER',
  DELETE_TRIP = 'DELETE_TRIP',
  REORDER_TASKS = 'REORDER_TASKS',
  REMOVE_CNS_FROM_TRIPS = 'REMOVE_CNS_FROM_TRIPS',
}

export enum UNPLANNED_CN_OFFLINE_ACTIONS {
  CREATE_TRIP = 'CREATE_TRIP',
  MOVE_TO_TRIP = 'MOVE_TO_TRIP',
}

export const ACTION_VIEW_CONFIG = {
  [TRIP_OFFLINE_ACTIONS.UPDATE_VEHICLE]: {
    fetchData: getFullVehiclesList,
    title: 'Modify Vehicle',
    searchPlaceholder: 'Search Vehicle',
    getRequestPayload: (hubId, tripData) => ({
      hubId,
      selectedTripType: tripData.type,
      vehicleMakeId: tripData.vehicle_make_id,
    }),
    responseDataMapper: (data) =>
      data.map((d) => ({ id: d.id, name: d.vehicle_number })),
  },
  [TRIP_OFFLINE_ACTIONS.UPDATE_VEHICLE_MAKE]: {
    fetchData: getFullVehicleMakeList,
    title: 'Modify Vehicle Make',
    searchPlaceholder: 'Search Vehicle Make',
    getRequestPayload: (hubId, tripData) => ({
      hubId,
    }),
  },
  [TRIP_OFFLINE_ACTIONS.UPDATE_WORKER]: {
    fetchData: getFullWorkerList,
    title: 'Modify Worker',
    searchPlaceholder: 'Search Worker',
    getRequestPayload: (hubId, tripData) => ({
      hubId,
      tripType: tripData.type,
      vehicleMakeId: tripData.vehicle_make_id,
    }),
    responseDataMapper: (data) => data.data,
  },
};

export enum METRIC_FORMAT_TYPES {
  SECONDS_TIME = 'SECONDS_TIME',
  KM_DISTANCE = 'KM_DISTANCE',
}

export enum METRIC_CALCULATION_TYPES {
  SUM = 'SUM',
  AVERAGE = 'AVERAGE',
  MAX = 'MAX',
}

export const TRIP_METRICS = [
  {
    id: 'total_consignment_count_all_buckets',
    name: 'Total CNs',
    customCalculator: (allTrips, allUnplannedCNs) => {
      return (
        allTrips.reduce(
          (acc, it) => acc + (it.tripDetails.total_consignment_count ?? 0),
          0,
        ) + (allUnplannedCNs?.length ?? 0)
      );
    },
  },
  {
    id: 'total_consignment_count',
    key: 'total_consignment_count',
    name: 'Planned CNs',
    calculationType: METRIC_CALCULATION_TYPES.SUM,
  },
  {
    id: 'assigned_drivers',
    name: 'Assigned Drivers',
    customCalculator: (allTrips, allUnplannedCNs) => {
      return new Set(allTrips.filter(x => x.workerDetails?.worker_code)
        .map((item) => item?.workerDetails?.worker_code)).size;
    },
  },
  {
    id: 'available_drivers',
    name: 'Available Drivers',
  },
  {
    id: 'total_trips',
    name: 'Total Trips',
    customCalculator: (allTrips, allUnplannedCNs) => {
      return allTrips.length;
    },
  },
  {
    id: 'average_distance_per_task',
    name: 'Average Distance per Task',
    customCalculator: (allTrips, allUnplannedCNs) => {
      const totalDistance = allTrips.reduce(
        (acc, it) => acc + (it.tripDetails.estimated_distance_metres ?? 0),
        0,
      );
      const totalDistanceKm = totalDistance ? totalDistance / 1000 : 0;
      const totalTasks = allTrips.reduce(
        (acc, it) => acc + (it.tripDetails.total_consignment_count ?? 0),
        0,
      );
      return totalTasks ? (totalDistanceKm/totalTasks).toFixed(2) : 0;
    },
  },
  {
    id: 'average_tasks_per_hour',
    name: 'Average Tasks per Hour',
    customCalculator: (allTrips, allUnplannedCNs) => {
      const totalTimeHrs = allTrips.reduce(
        (acc, it) => acc + (it.tripDetails.estimated_time_seconds/(60 * 60) ?? 0),
        0,
      );
      const totalTasks = allTrips.reduce(
        (acc, it) => acc + (it.tripDetails.total_consignment_count ?? 0),
        0,
      );
      return totalTimeHrs ? (totalTasks/totalTimeHrs).toFixed(2) : 0;
    },
  },
  {
    id: 'vehicle_weight_utilisation',
    key: 'vehicle_weight_utilisation',
    name: 'Avg Weight Utilisation %',
    calculationType: METRIC_CALCULATION_TYPES.AVERAGE,
    formatToFixed: true,
  },
  {
    id: 'vehicle_volume_utilisation',
    key: 'vehicle_volume_utilisation',
    name: 'Avg Volume Utilisation %',
    calculationType: METRIC_CALCULATION_TYPES.AVERAGE,
    formatToFixed: true,
  },
  {
    id: 'estimated_distance_metres',
    key: 'estimated_distance_metres',
    name: 'Total trip distance Km',
    calculationType: METRIC_CALCULATION_TYPES.SUM,
    formatType: METRIC_FORMAT_TYPES.KM_DISTANCE,
    formatToFixed: true,
  },
];

export const convertTripCNToUnplannedCN = (tripCN) => {
  return {
    ...tripCN,
    id: tripCN.task_id,
    destination_address_line_1: tripCN.receiver_address,
    destination_geolocation:
      tripCN.destination_geolocation ??
      (tripCN.lat && tripCN.lng ? [tripCN.lat, tripCN.lng] : []),
  };
};

export const convertUnplannedCNToTripCN = (unplannedCN) => {
  const {
    destination_address_line_1,
    destination_address_line_2,
    destination_address_line_3,
    destination_address_line_4,
  } = unplannedCN;
  return {
    ...unplannedCN,
    task_id: unplannedCN.id,
    receiver_address: [
      destination_address_line_1 || '',
      destination_address_line_2 || '',
      destination_address_line_3 || '',
      destination_address_line_4 || '',
    ].join(', '),
    lat:
      unplannedCN.lat ??
      (unplannedCN.destination_geolocation &&
        unplannedCN.destination_geolocation.length === 2
        ? unplannedCN.destination_geolocation[0]
        : undefined),
    lng:
      unplannedCN.lng ??
      (unplannedCN.destination_geolocation &&
        unplannedCN.destination_geolocation.length === 2
        ? unplannedCN.destination_geolocation[1]
        : undefined),
  };
};

export const formatDecimalMetric = (metricValue) => {
  if (!metricValue) return '-';

  const numberMetricValue = +metricValue;

  return numberMetricValue.toFixed(numberMetricValue < 1 ? 3 : 2);
};

export const shortcutKeysListData = [
  {
    fn: 'Multiselect orders',
    shortcut: 'Shift + Click pin',
  },
  {
    fn: 'Add orders to trip',
    shortcut: 'Click trip route',
  },
  {
    fn: 'Unplan orders',
    shortcut: 'Alt + map click',
  },
  {
    fn: 'Plan orders in a new trip',
    shortcut: 'Shift + Alt + map click',
  },
];
