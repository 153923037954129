import apiProvider from '../provider';
import {
  getAuthenticationHeaders,
  getErrorMessage,
} from '../utils';
import { API_BASE_URL } from '../../globals';
import {
  GET_ALL_TMS_DATA, FETCH_TMS_SUMMARY_METRICS_DATA,
  DOWNLOAD_TMS_VEHICLE_DATA,GET_ALL_VEHICLE_ATTENDANCE_DATA,
  GET_VEHICLE_LOCATION,GET_ALL_VEHICLE_ATTENDANCE_DETAILS,
  SET_MARK_ABSENT,SET_MARK_PRESENT,DOWNLOAD_ATTENDANCE,
  SHOW_WARNING_BEFORE_SET_MARK_ABSENT,
} from '../routes';

import {
  requestModelTMS, responseModelTMS, requestModelSummaryMetricsTMS,requestAttendanceModelTMS,
  responseModelTMSAttendance,
} from '../../model/tmsView';
import { morphism } from 'morphism';
import { GET,POST } from '../apiHandler';
export const getAllTmsVehicleData = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${GET_ALL_TMS_DATA}`, { ...morphism(requestModelTMS, body) },
      {
        headers: await getAuthenticationHeaders(),
      },
    );

    return {
      data: morphism(responseModelTMS, response.data.data),
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getTmsVehicleSummaryMetricsData = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${FETCH_TMS_SUMMARY_METRICS_DATA}`,
      morphism(requestModelSummaryMetricsTMS, body),
      { headers:await getAuthenticationHeaders() });
    return {
      isSuccess: true,
      data: response.data.data,
    };
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const downloadTMSVehicleData = async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${DOWNLOAD_TMS_VEHICLE_DATA}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const getVehicleAttendance = async (body) => {
  const response = await POST(
    `${API_BASE_URL}${GET_ALL_VEHICLE_ATTENDANCE_DATA}`,
    { ...morphism(requestAttendanceModelTMS, body) },
  );
  return response.isSuccess ?
    {
      data: morphism(responseModelTMSAttendance, response.data),
      isSuccess: true,
    } : response;
};
export const getVehicleAttendanceDetails = async (body) => {
  return GET(`${API_BASE_URL}${GET_ALL_VEHICLE_ATTENDANCE_DETAILS}`, body);
};
export const markPresent = async (body) => {
  return POST(`${API_BASE_URL}${SET_MARK_PRESENT}`, body);
};
export const markAbsent = async (body) => {
  return POST(`${API_BASE_URL}${SET_MARK_ABSENT}`, body);
};
export const shouldShowWarningBeforeMarkingAbsent = async (body) => {
  return POST(`${API_BASE_URL}${SHOW_WARNING_BEFORE_SET_MARK_ABSENT}`, body);
};
export const downloadTMSVehicleAttendanceData = async(body) => {
  return POST(`${API_BASE_URL}${DOWNLOAD_ATTENDANCE}`, body);
};
export const getLocation = async (body) => GET(`${API_BASE_URL}${GET_VEHICLE_LOCATION}`, body);
