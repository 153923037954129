import * as React from 'react';
import withStyles from 'react-jss';
import { createUseStyles } from 'react-jss';
import { connect } from 'react-redux';
import '@ant-design/compatible/assets/index.css';
import { styles } from './ChatComponentStyle';
import {
  Input,
  Checkbox,
  Switch,
  Button,
  Modal,
  Spin,
  message,
  Form,
} from 'antd';
import useGenericState from '@hooks/useGenericState';
import { addTripComment, fetchTripComments } from 'src/api/tripComments';
import {
  DownloadOutlined,
  PaperClipOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import SecurityIcon from '@components/common/icons/SecurityIcon';
import PdfFileIcon from '@components/common/icons/PdfFileIcon';
import DocFileIcon from '@components/common/icons/DocFileIcon';
import ExcelFileIcon from '@components/common/icons/ExcelFileIcon';
import PngFileIcon from '@components/common/icons/PngFileIcon';
import JpgFileIcon from '@components/common/icons/JpgFileIcon';
import EmptyChatIcon from '@components/common/icons/EmptyChatIcon';
import EmptyFileIcon from '@components/common/icons/EmptyFileIcon';
import { renderTime } from '@utils/utils';

import AttachFiles from './AttachFiles';
import * as moment from 'moment';

const { Search, TextArea } = Input;

const fileWrapperStyle = {
  card: {
    backgroundColor: '#F6F6F6',
    display: 'flex',
    padding: '10px 10px',
    borderRadius: '4px',
    alignItems: 'center',
    marginTop: '10px',
    width: '250px',
  },
  smallCard: {
    backgroundColor: '#F6F6F6',
    display: 'flex',
    alignItems: 'center',
    fontSize: '10px',
    width: '250px',
    maxHeight: '20px',
    marginRight: '15px',
    justifyContent: 'center',
    padding: '5px',
  },
  defaultStyle: {
    backgroundColor: 'white',
    display: 'flex',
    height: '50px',
    padding: '15px',
    borderBottom: '1px solid lightgray',
    alignItems: 'center',
  },
};

const fileStyle = (theme) => ({
  fileWrapper: ({ type }) =>
    fileWrapperStyle[type] || fileWrapperStyle.defaultStyle,
  fileMetadata: {
    display: 'flex',
    flexDirection: 'column',
    textOverflow: 'elipsis',
  },
  fileName: {
    fontSize: '12px',
    fontWeight: 600,
    textOverflow: 'elipsis',
    maxWidth: '80%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  fileProperties: {
    fontSize: '10px',
    fontWeight: 400,
    color: '#666666',
  },
  separator: {
    fontSize: '8px',
    color: '#999999',
    marginLeft: '5px',
    marginRight: '5px',
  },
  tag: {
    fontSize: '9px',
    backgroundColor: 'lightgray',
    height: '18px',
    width: '39px',
    borderRadius: '2px',
    lineHeight: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 600,
    marginLeft: '20px',
  },
});

const useStyles = createUseStyles(fileStyle);

const fileIconMap = {
  pdf: <PdfFileIcon />,
  xls: <ExcelFileIcon />,
  docx: <DocFileIcon />,
  doc: <DocFileIcon />,
  xlsx: <ExcelFileIcon />,
  png: <PngFileIcon />,
  jpg: <JpgFileIcon />,
  jpeg: <JpgFileIcon />,
  csv: <ExcelFileIcon />,
};

const File = (props: any) => {
  const { file } = props;
  const classes = useStyles(props);
  return (
    <div className={classes.fileWrapper}>
      <div
        style={
          props.type === 'smallCard'
            ? { marginRight: '10px', fontSize: '14px' }
            : { marginRight: '10px', fontSize: '18px' }
        }
      >
        {fileIconMap[file.type] || fileIconMap['pdf']}
      </div>
      <div className={classes.fileMetadata}>
        <div className={classes.fileName}>{file.name}</div>
        {props.type === 'card' || props.type === 'smallCard' ? (
          <></>
        ) : (
          <div
            style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}
          >
            <div className={classes.fileProperties}>{file.author}</div>
            <div className={classes.separator}>●</div>
            <div className={classes.fileProperties}>{file.time}</div>
          </div>
        )}
      </div>
      <div style={{ flexGrow: 1 }}>
        {!props.type && file.is_private && (
          <div className={classes.tag}>Private</div>
        )}
      </div>
      {props.type !== 'smallCard' && (
        <a
          style={{ color: 'black' }}
          href={file.url}
          target="_blank"
          rel="noreferrer"
        >
          <DownloadOutlined
            style={{ marginRight: '0px', fontSize: '18px', cursor: 'pointer' }}
          />
        </a>
      )}
      {props.type === 'smallCard' && (
        <CloseOutlined
          onClick={() => props.handleRemoveFile(file.id)}
          style={{ marginRight: '0px', fontSize: '14px', cursor: 'pointer' }}
        />
      )}
    </div>
  );
};

const Chat = (props: any) => {
  const { chat, classes } = props;

  return (
    <div className={classes.chatWrapper}>
      <div className={classes.chatMetaData}>
        <div className={classes.chatAuthor}>{chat.author}</div>
        <div className={classes.separator}>●</div>
        <div className={classes.chatTime}>{renderTime(chat.created_at)}</div>
        <div style={{ flexGrow: 1 }}></div>
        {chat.is_private && <div className={classes.tag}>Internal Note</div>}
      </div>
      <div className={classes.text}>{chat.conversation}</div>
      <div className={classes.chatAttachments}>
        {chat.attachments?.map((file) => (
          <div style={{ marginRight: '16px' }}>
            <File type={'card'} file={file} classes={props.classes} />
          </div>
        ))}
      </div>
    </div>
  );
};

const AddChat = (props: any) => {
  const { classes, setState, state, fetchChats } = props;

  const postComment = async () => {
    if (!state?.newChat?.conversation) {
      message.error('Please enter a message');
      return;
    }
    setState({ isLoading: true });
    const { newChat } = state;
    const response = await addTripComment({
      trip_id: newChat?.tripId,
      conversation: newChat?.conversation,
      attachments: newChat?.attachments || [],
      is_private: newChat?.isPrivate,
    });
    if (!response.isSuccess) {
      message.error(response.errorMessage);
    } else {
      setState({
        newChat: {
          tripId: state.newChat?.tripId,
          isPrivate: false,
          attachments: [],
        },
      });
    }
    await fetchChats();
    setState({ isLoading: false });
  };
  return (
    <div className={classes.addChat}>
      <div>
        <TextArea
          onChange={(e) => {
            setState({
              newChat: { ...state.newChat, conversation: e.target.value },
            });
          }}
          value={state?.newChat?.conversation}
          placeholder="Type here..."
          className={classes.textArea}
        />
      </div>
      <div
        style={{
          marginLeft: '10px',
          marginRight: '10px',
          borderBottom: '1px solid lightgray',
        }}
      />
      <div className={classes.privateModeWrapper}>
        <div style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
          <div
            style={{ marginRight: '12px', fontWeight: 400, color: '#666666' }}
          >
            Internal Note
          </div>
          <Switch
            onChange={(value) => {
              const newChat = { ...(state.newChat || {}), isPrivate: value };
              setState({ newChat });
            }}
            size="small"
          />
          <div
            style={{
              padding: '0 10px',
              maxWidth: '650px',
              overflowX: 'scroll',
              width: 'fit-content',
            }}
          >
            <div
              style={{ width: 'inherit', overflowX: 'scroll', display: 'flex' }}
            >
              {state?.newChat?.attachments?.map((file, index) => (
                <File
                  type="smallCard"
                  file={file}
                  classes={props.classes}
                  key={index}
                  handleRemoveFile={props.handleRemoveFile}
                />
              ))}
            </div>
          </div>
        </div>
        <PaperClipOutlined
          onClick={() => {
            if (state.newChat?.attachments?.length < 3) {
              setState({ isUploadModalVisible: true });
            }
          }}
          style={{
            color: '#666666',
            fontSize: '22px',
            cursor:
              state.newChat?.attachments?.length >= 3
                ? 'not-allowed'
                : 'pointer',
          }}
        />
        <Button
          onClick={postComment}
          type="primary"
          className={classes.commentButton}
        >
          Comment
        </Button>
      </div>
    </div>
  );
};

const ChatComponent = (props: any) => {
  const POLLING_INTERVAL = 20000;
  const pollingAgent = React.useRef({} as any);
  const isMounted = React.useRef(false);

  const { classes, tripId } = props;
  const [form] = Form.useForm();

  const initialState = {
    isLoading: false,
    isUploadModalVisible: false,
    attachFilesLoader: false,
    showPrivateFiles: false,
    newChat: {
      tripId,
      isPrivate: false,
      attachments: [],
    },
    chats: [],
    files: [],
    fileTypeOptions: [],
    filesToUse: [],
    searchText: '',
  };
  const [state, setState] = useGenericState(initialState);

  const handleFileSearch = (value) => {
    setState({ searchText: value });
  };

  const handleRemoveFile = (id) => {
    const attachments = state.newChat?.attachments?.filter(
      (file) => file.id !== id,
    );
    setState({ newChat: { ...state.newChat, attachments } });
  };
  const renderFiles = (files = []) => {
    return (
      <div className={classes.files}>
        {files.length ? (
          files.map((file) => <File file={file} classes={props.classes} />)
        ) : (
          <div className={classes.emptyFileWrapper}>
            <div style={{ fontSize: '24px' }}>
              <EmptyFileIcon />
            </div>
            <div className={classes.fileDescription}>
              Files Shared will appear here
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderChats = (chats = []) => {
    return (
      <div className={classes.chats}>
        {chats.length ? (
          chats.map((chat) => <Chat chat={chat} classes={props.classes} />)
        ) : (
          <div className={classes.emptyChatWrapper}>
            <div style={{ fontSize: '24px' }}>
              <EmptyChatIcon />
            </div>
            <div className={classes.chatDescription}>
              There aren't any comments to show
            </div>
          </div>
        )}
      </div>
    );
  };

  const fetchChats = async () => {
    if (!document.hidden) {
      const response = await fetchTripComments({ trip_id: tripId });
      if (response.isSuccess && isMounted.current) {
        const files = [];
        for (const chat of response.data || []) {
          if (Array.isArray(chat.attachments) && chat.attachments.length) {
            for (const file of chat.attachments) {
              if (chat.is_private) {
                file.is_private = true;
              }
              file.author = chat.author;
              file.time = moment
                .utc(chat.created_at)
                .local()
                .format('DD-MM-YYYY HH:mm');
              files.push(file);
            }
          }
        }
        setState({
          chats: response.data,
          files,
          fileTypeOptions: response?.response?.metadata?.documentTypes,
        });
      }
    }
    if (isMounted.current) {
      pollingAgent.current = setTimeout(fetchChats, POLLING_INTERVAL);
    }
  };

  const fetchChatsWithLoader = async () => {
    setState({ isLoading: true });
    try {
      await fetchChats();
    } catch (err) {
      message.error(err.message);
    }
    setState({ isLoading: false });
  };

  React.useEffect(() => {
    isMounted.current = true;
    fetchChatsWithLoader();
    return () => {
      isMounted.current = false;
      if (pollingAgent.current) {
        clearTimeout(pollingAgent.current);
      }
    };
  }, []);

  React.useEffect(() => {
    const filterFunction = (file) => {
      if (state.showPrivateFiles && !file.is_private) {
        return false;
      }
      if (
        state.searchText &&
        !file.name?.toLowerCase().includes(state.searchText?.toLowerCase())
      ) {
        return false;
      }
      return true;
    };
    setState({ filesToUse: state.files.filter(filterFunction) });
  }, [state.showPrivateFiles, state.searchText, state.files]);

  const attachFiles = () => {
    const chat = state.newChat;
    const attachments = state.newChat.attachments || [];
    for (const attachment of (form.getFieldsValue() || {}).attachments || []) {
      if (attachment?.file?.url) {
        attachments.push(attachment.file);
      }
    }
    chat.attachments = attachments;
    setState({ newChat: chat, isUploadModalVisible: false });
  };

  const getFileCount = () => {
    if (state.filesToUse?.length) {
      return <>({state.filesToUse.length})</>;
    }
    return null;
  };

  return (
    <div className={classes.wrapper}>
      {state.isLoading ? (
        <div className={classes.spinner}>
          <Spin />
        </div>
      ) : (
        <>
          <div className={classes.chatComponent}>
            {renderChats(state.chats)}
            {state.newChat?.isPrivate ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '10px',
                }}
              >
                <div style={{ fontSize: '20px', marginRight: '5px' }}>
                  <SecurityIcon />
                </div>
                <div
                  style={{
                    color: '#333333',
                    fontWeight: 600,
                  }}
                >
                  Internal Notes Enabled. Files and messages sent will only be
                  visible to you
                </div>
              </div>
            ) : null}
            <div className={classes.divider}></div>
            <AddChat
              state={state}
              classes={props.classes}
              setState={setState}
              fetchChats={fetchChats}
              handleRemoveFile={handleRemoveFile}
            />
          </div>
          <div className={classes.fileComponent}>
            <div className={classes.fileComponentHeader}>
              <div className={classes.fileCountWrapper}>
                Files {getFileCount()}
              </div>
              <Checkbox
                checked={state.showPrivateFiles}
                onChange={(e) =>
                  setState({ showPrivateFiles: e.target.checked })
                }
              >
                <div style={{ fontSize: '12px' }}>Private</div>
              </Checkbox>
              <Search
                allowClear
                placeholder="Search"
                onSearch={handleFileSearch}
                style={{
                  fontSize: '5px !important',
                  width: 200,
                  marginLeft: '20px',
                }}
              />
            </div>
            {renderFiles(state.filesToUse)}
          </div>
          {state.isUploadModalVisible ? (
            <Modal
              title="Attach File"
              visible={state.isUploadModalVisible}
              onCancel={() => {
                setState({ isUploadModalVisible: false });
              }}
              centered
              width={600}
              footer={[
                <Button
                  key="back"
                  onClick={() => {
                    setState({ isUploadModalVisible: false });
                  }}
                >
                  Cancel
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  loading={state.attachFilesLoader}
                  onClick={attachFiles}
                >
                  Submit
                </Button>,
              ]}
            >
              <Form form={form}>
                <AttachFiles
                  classes={props.classes}
                  fileTypeOptions={state.fileTypeOptions}
                />
              </Form>
            </Modal>
          ) : null}
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ masterData }) => {
  return {
    viewType: masterData.viewType,
  };
};

const ChatComponentStyled = withStyles(styles, { injectTheme: true })(
  ChatComponent,
);
export default connect(mapStateToProps, null)(ChatComponentStyled);
