import { getDetailsRoute } from '@routing/utils';
import {
  getAllPlanningData,
  getConsolidatedPlanningSummaryMetricsData,
  getPlanningSubBucketSummaryData,
} from '@api/genericConsignmentView';
import {
  updateConsolidatedPlanningColumnList,
  updateConsolidatedPlanningFilters,
  requestDownloadConsolidatedPlanning,
} from '@api/dashboard';
import {
  createRouteAll,
  createRoute,
  createRouteAllAddToTrip,
  createRouteAddToTrip,
  createManualTrip,
} from '@api/retailDashboard';

const TaskMarkerIcon = require('@assets/images/markerpin_pink.svg');
const HubIcon = require('@assets/images/warehouse.svg');
const ZoneIcon = require('@assets/images/zone.svg');
const LegendIcon = require('@assets/images/legend.svg');
const FilterIcon = require('@assets/images/filter.svg');

const defaultRetailStatusFilterOptions = [
  'reachedathub',
  'outfordelivery',
  'delivered',
  'attempted',
  'inscan_at_hub',
  'in_transit',
  'pickup_awaited',
  'pickup_scheduled',
  'out_for_pickup',
  'not_picked_up',
  'pickup_completed',
  'received',
  'not_received',
  'out_for_store_pickup',
  'on_hold',
  'rescheduled',
  'inscan_at_planning_hub',
];

const subBucketPrettyName = {
  _all: 'All',
  _single_consignment_pickup: 'Single Consignment Pickup',
  _pickup_request: 'Pickup Request/Task',
  _delivery: 'Delivery',
  _job_maintenance: 'Job Maintenance',
  _job_installation: 'Job Installation',
  _job_service: 'Job Service',
  _job_inspection: 'Job Inspection',
  _job_cod: 'Job COD',
};

const getSubBuckets = (masterData) => {
  const columnList = masterData?.ops_consolidated_column_list;

  if (columnList) {
    const buckets = Object.keys(columnList);
    const subBuckets = Object.keys(columnList[buckets[0]]);

    return subBuckets.map((sub) => {
      return { id: sub, name: subBucketPrettyName[sub] ?? sub };
    });
  }
  return [];
};

const viewControl = {
  pageTitle: 'Planning',
  buckets: [
    'planning_unplanned',
    'planning_planned',
    'planning_in_progress',
    'planning_failed',
    'planning_success',
  ],
  navBar: {
    left: ['hub_search_with_type', 'date_filter'],
    right: ['search_redirect', 'consignment_track'],
  },
  globalActions: [],
  dateFilterOptions: [
    { key: 'created_at', val: 'Creation Time' },
    { key: 'last_main_event_time', val: 'Last Updated' },
    { key: 'scheduled_at', val: 'scheduled_at' },
  ],
  searchFilterOptions: [{ key: 'referenceNumber', val: 'Reference Number' }],
  hubSearchOptions: [{ key: 'dc', val: 'Planning Hub' }],
  detailsRoute: getDetailsRoute,
  fetchData: getAllPlanningData,
  fetchSubBucket: getPlanningSubBucketSummaryData,
  fetchSummaryMetrics: getConsolidatedPlanningSummaryMetricsData,
  defaultHubType: 'dc',
  updateUserColumnList: updateConsolidatedPlanningColumnList,
  getSubBuckets,
  updateUserFilters: updateConsolidatedPlanningFilters,
  requestDownload: requestDownloadConsolidatedPlanning,
  widgetsConfig: {
    legends: {
      key: 'legends',
      label: 'Show Legends',
      icon: LegendIcon,
      children: [
        {
          key: 'unplannedTasks',
          label: 'Unplanned Tasks',
          icon: TaskMarkerIcon,
        },
        {
          key: 'planningHub',
          label: 'Planning Hub',
          icon: HubIcon,
        },
        {
          key: 'zonesLegends',
          label: 'Zones',
          icon: ZoneIcon,
        },
      ],
    },
    mapFilters: {
      key: 'mapFilters',
      label: 'Map Filters',
      icon: FilterIcon,
      children: [
        {
          key: 'assignedRiders',
          label: 'Show Assigned Riders',
        },
        {
          key: 'unassignedRiders',
          label: 'Show Unassigned Riders',
        },
        {
          key: 'zones',
          label: 'Show Zones',
        },
      ],
    },
  },
};

const buckets = {
  planning_planned: {
    hidePagination: false,
    hidePerPageCount: false,
    name: 'Planned',
    filters: [],
    actions: ['downloads'],
    status_filter: defaultRetailStatusFilterOptions,
    isSubBucket: true,
  },
  planning_unplanned: {
    hidePagination: false,
    hidePerPageCount: false,
    name: 'Unplanned',
    filters: [],
    actions: [
      'createCustomTrip',
      'zone_view',
      'createRoute',
      'downloads',
      'mapListViewSwitch',
    ],
    mapListView: {
      isMapView: false,
      mapWidgetsToShow: ['legends', 'mapFilters'],
    },
    actionsApiCalls: {
      create_route: {
        createRouteForAll: createRouteAll,
        createRouteForSelected: createRoute,
        bulkCreateRoute: createRoute,
      },
      add_to_existing: {
        createRouteForAll: createRouteAllAddToTrip,
        createRouteForSelected: createRouteAddToTrip,
        bulkCreateRoute: createRouteAddToTrip,
      },
      create_custom_trip: {
        createCustomTrip: createManualTrip,
      },
    },
    status_filter: defaultRetailStatusFilterOptions,
    isSubBucket: true,
  },
  planning_in_progress: {
    name: 'In Progress',
    filters: [],
    actions: ['downloads'],
    status_filter: defaultRetailStatusFilterOptions,
    isSubBucket: true,
  },
  planning_failed: {
    name: 'Failed',
    filters: [],
    actions: ['downloads'],
    status_filter: defaultRetailStatusFilterOptions,
    isSubBucket: true,
  },
  planning_success: {
    name: 'Success',
    filters: [],
    actions: ['downloads'],
    status_filter: defaultRetailStatusFilterOptions,
    isSubBucket: true,
  },
};

const bucketColor = {
  planning_planned: '#722ED1',
  planning_unplanned: '#EB2F96',
  planning_in_progress: '#2F54EB',
  planning_failed: '#FA541C',
  planning_success: '#389E0D',
};

export default { viewControl, buckets, bucketColor };
