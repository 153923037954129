import { MoreColors } from 'src/theming/moreColors';
import { NAVBAR_HEIGHT } from '../../../globals';
import { CssType, ThemeType } from '../../../theming/jssTypes';
const styles = (theme: ThemeType): CssType => ({
  pageMainDiv: {
    height: '100%',
  },
  content: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  configurations: {
    width: '25%',
    height: '100%',
    borderRight: `2px solid ${theme.colors.borderColor}`,
  },
  menuItem: {
    padding: '8px !important',
    height: 'auto !important',
    '&.ant-menu-item-selected': {
      backgroundColor: `${theme.colors.selectionOnWhiteBg} !important`,
      color: theme.colors.textOnLightBg,
    },
  },
  heading: {
    fontSize: '20px',
    fontWeight: 600,
    padding: '20px',
  },
  configContent: {
    width: '75%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: theme.colors.surfaceBg,
    padding: '15px 30px',
  },
  configHeading: {
    fontSize: '18px',
    fontWeight: 600,
    padding: '12px 0',
  },
  yellowBox: {
    padding: '8px',
    backgroundColor: theme.colors.warning,
    marginBottom: '12px',
  },
  verticalPadding12: {
    padding: '12px 0',
  },
  listItem: {
    backgroundColor: theme.colors.surfaceBg,
    padding: '25px 16px',
    borderRadius: '3px',
    boxShadow: `1px 5px 5px 2px ${MoreColors.WhitishGrey}`,
    marginBottom: '8px',
  },
  listContent: {
    display: 'flex',
  },
  itemName: {
    marginLeft: '35px',
  },
  tableContainer: {
    flex: '1 1 0',
    margin: '0 16px 16px 16px',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
    '& .ant-table-tbody td .editLink': {
      opacity: 0,
      marginLeft: 8,
      fontSize: 16,
    },
    '& .ant-table-tbody td:hover .editLink': {
      opacity: 1,
    },
  },
  filterContainer: {
    margin: '10px 16px 0px 16px',
  },
  filterRow: {
    display: 'flex',
    alignItems: 'center',
  },
  containerDefaultFilter: {
    padding: '0 16px 0 16px',
    height: '56px',
    background: theme.colors.surfaceBg,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
  },
  mainDiv: {
    background: theme.colors.surfaceBg,
    display: 'flex',
    flexDirection: 'column',
    height: `calc( 100vh - ${NAVBAR_HEIGHT}px)`,
  },
  editButton: {
    color: theme.colors.textOnLightBg,
    cursor: 'pointer',
  },
  pageTitle: {
    fontSize: 16,
    marginLeft: '16px',
    fontWeight: 600,
    color: theme.colors.textOnLightBg,
  },
  warningModal: {
    textAlign: 'center',
  },
  warningHeading: {
    fontWeight: 600,
    fontSize: '16px',
    padding: '10px',
  },
  warningFooter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  skipWarning: {
    margin: '10px',
    cursor: 'pointer',
  },
  modal: {
    '& .ant-modal-footer': {
      border: 'none',
      '& .ant-btn-primary': {
        backgroundColor: theme.colors.primaryColor,
        color: theme.colors.textOnDarkBg,
      }
    },
  },
  oidcImg: {
    width: 'auto',
    height: '72px',
  },
  deactivatedAuth: {
    background: `${MoreColors.WhitishGrey} !important`,

    '& > img': {
      filter: 'grayscale(100%)',
    },
  },
  authCard: {
    padding: '16px',
    background: theme.colors.surfaceBg,
    boxShadow: '2px 2px 8px 0 rgba(0, 0, 0, 0.09)',
    width: '225px',
    margin: '0 8px 8px 0',
    cursor: 'pointer',

    '& > h3': {
      margin: '12px 0 8px 0',
      fontSize: '14px',
      textTransform: 'capitalize',
    },
    '& > div > p': {
      margin: 0,
      fontSize: '10px',
      textTransform: 'uppercase',
    },
  },
  flexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  modalDetailsDiv: {
    wordBreak: 'break-all',

    '& > p:first-child': {
      fontSize: '12px',
      margin: 0,
      color: theme.colors.textOnLightBg,
    },
    '& > div > p, & > p:last-child': {
      fontSize: '14px',
      fontWeight: 600,
      color: theme.colors.textOnLightBg,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    '& > div': {
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& > div > span': {
      marginLeft: '8px',
      cursor: 'pointer',
    },
  },
  formLabel: {
    fontSize: '12px',
    color: theme.colors.textOnLightBg,
    margin: 0,

    '& > span': {
      display: 'inline-block',
      marginRight: '4px',
      color: theme.colors.error,
      fontSize: '14px',
      // fontFamily: 'SimSun, sans-serif',
      lineHeight: 1,
    },
  },
  singleSignOnDiv: {
    '& label': {
      fontSize: '12px',
      color: theme.colors.textOnLightBg,
      margin: 0,
    },
  },
  samlInfoGrayBG: {
    backgroundColor: MoreColors.WhitishGrey,
  }
});
export default styles;
