import { getCrateTableData } from '@api/crate';
const viewControl = {
  pageTitle: 'Crate',
  buckets: ['crate'],
  navBar: {
    left: [],
    right: [],
  },
  fetchData: getCrateTableData,
  dateFilterOptions: [
    { key: 'updated_at', val: 'Last Updated Date' },
    { key: 'created_at', val: 'Creation Date' },
  ],
};
const buckets = {
  crate: {
    name: 'Crate',
    filters: [],
    actions: [],
    status_filter: [],
    isSubBucket: false,
  },
};
export default { viewControl, buckets };
