export const allSupplyRequestColumns = [
    {
        id: 'sno',
        pretty_name: 'S.No.',
    },
    {
        id: 'supply_request_id',
        pretty_name: 'Supply Request Id',
    },
    {
        id: 'created_at',
        pretty_name: 'Creation Date',
    },
    {
        id: 'package_details',
        pretty_name: 'Package Details',
    },
    {
        id: 'status',
        pretty_name: 'Status',
    },
    {
        id: 'customer_code',
        pretty_name: 'Customer Code',
    },
    {
        id: 'actions',
        pretty_name: 'Actions',
    },
];

const supplyRequestColumnForBucket = [
    {
        id: 'sno',
        pretty_name: 'S.No.',
    },
    {
        id: 'supply_request_id',
        pretty_name: 'Supply Request Id',
    },
    {
        id: 'created_at',
        pretty_name: 'Creation Date',
    },
    {
        id: 'package_details',
        pretty_name: 'Package Details',
    },
    {
        id: 'customer_code',
        pretty_name: 'Customer Code',
    },
];

export const supplyRequestColumnByBucket =  {
    supply_request_all: allSupplyRequestColumns,
    supply_request_delivered: supplyRequestColumnForBucket,
    supply_request_undelivered: supplyRequestColumnForBucket,
    supply_request_partially_delivered: supplyRequestColumnForBucket,
};

export const statusList = [
    {key: 'created', label: 'CREATED'},
    {key: 'delivered', label: 'DELIVERED'},
    {key: 'partially_delivered', label:'PARTIALLY DELIVERED'},
    {key: 'undelivered', label:'UNDELIVERED'},
];

export const packageDetailColumns = [
    {
        column_id: 'type',
        pretty_name: 'Supply Type',
    },
    {
        column_id: 'quantity',
        pretty_name: 'Quantity',
    },
];

export const PartialDeliveryColumns = [
    {
      title: 'Supply Type',
      dataIndex: 'type',
    },
    {
      title: 'Original Quantity',
      dataIndex: 'quantity',
    },
    {
      title: 'Quantity Delivered',
      dataIndex: 'delivered_quantity',
      editable: true,
    },
];

export const SupplyRequestDetailsColumns = [
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
    },
    {
      title: 'Qty Delivered',
      dataIndex: 'delivered_quantity',
    },
    {
        title: 'Remarks',
        dataIndex: 'remarks',
    },
];
