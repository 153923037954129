// tslint:disable: max-line-length

import Icon from '@ant-design/icons';
import * as React from 'react';
const TaskFailed = (props: any) => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.99984 11.8333C5.19289 11.8333 4.43456 11.6802 3.72484 11.3739C3.01512 11.0677 2.39775 10.6521 1.87275 10.1271C1.34775 9.60207 0.932129 8.98471 0.625879 8.27499C0.319629 7.56527 0.166504 6.80693 0.166504 5.99999C0.166504 5.19305 0.319629 4.43471 0.625879 3.72499C0.932129 3.01527 1.34775 2.39791 1.87275 1.87291C2.39775 1.34791 3.01512 0.932281 3.72484 0.626031C4.43456 0.319781 5.19289 0.166656 5.99984 0.166656C6.80678 0.166656 7.56511 0.319781 8.27484 0.626031C8.98456 0.932281 9.60192 1.34791 10.1269 1.87291C10.6519 2.39791 11.0675 3.01527 11.3738 3.72499C11.68 4.43471 11.8332 5.19305 11.8332 5.99999C11.8332 6.80693 11.68 7.56527 11.3738 8.27499C11.0675 8.98471 10.6519 9.60207 10.1269 10.1271C9.60192 10.6521 8.98456 11.0677 8.27484 11.3739C7.56511 11.6802 6.80678 11.8333 5.99984 11.8333ZM5.99984 10.6667C6.52484 10.6667 7.03039 10.5816 7.5165 10.4114C8.00261 10.2413 8.44984 9.99582 8.85817 9.67499L2.32484 3.14166C2.004 3.54999 1.75852 3.99721 1.58838 4.48332C1.41824 4.96943 1.33317 5.47499 1.33317 5.99999C1.33317 7.30277 1.78525 8.40624 2.68942 9.31041C3.59359 10.2146 4.69706 10.6667 5.99984 10.6667ZM9.67484 8.85832C9.99567 8.44999 10.2412 8.00277 10.4113 7.51666C10.5814 7.03055 10.6665 6.52499 10.6665 5.99999C10.6665 4.69721 10.2144 3.59374 9.31025 2.68957C8.40609 1.78541 7.30262 1.33332 5.99984 1.33332C5.47484 1.33332 4.96928 1.41839 4.48317 1.58853C3.99706 1.75867 3.54984 2.00416 3.1415 2.32499L9.67484 8.85832Z" fill="#434343"/>
    </svg>

);
export const TaskFailedIcon = (props) => (
    <Icon component={TaskFailed} {...props} />
);
