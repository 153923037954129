import * as React from 'react';
import withStyles from 'react-jss';
import { CssType, ThemeType } from 'src/theming/jssTypes';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import {
  message,
  Button,
  Dropdown,
  Menu,
} from 'antd';
import { trackGaEvents } from 'src/utils/googleAnalyticsHelper';
import { TABLE_ACTION, gaType } from 'src/utils/gaConstants';
import { withTranslation } from 'react-i18next';
import GenericActionButton from 'src/components/common/GenericActionButton';
import { PageData } from 'src/types/page.types';

const styles = (theme: ThemeType): CssType => ({
  actionButton: {},
});

const ConsignmentActionComponent = (props: any) => {
  const history = useHistory();
  const [actionDetailsData, setActionDetailsData] = React.useState<any>(null);

  const {
    classes,
    cnRescheduleDateValidation,
    action,
    params,
    actionDetails,
    selectedRowKeys,
    asyncRequestCreation,
    date,
    bucketAppliedFilters,
    currHub,
    globalAppliedFilters,
    tableData,
    t,
  } = props;

  const getActionItem = (subAction) => {
    return (
      <Menu.Item key={subAction.id} onClick={() => onActionClick(subAction)}>
        {t(subAction.label)}
      </Menu.Item>
    );
  };

  const getActionMenu = () => {
    return actionDetails.children.map((ele) => getActionItem(ele));
  };

  const renderAction = () => {
    return actionDetails.children instanceof Array &&
      actionDetails.children.length ? (
      <Dropdown
        placement="bottomLeft"
        trigger={['click']}
        overlay={<Menu>{getActionMenu()}</Menu>}
      >
        <Button
          className={classes.actionButton}
          onClick={() => onActionClick()}
        >
          {t(actionDetails.label)}
        </Button>
      </Dropdown>
    ) : (
      <GenericActionButton
        className={classes.actionButton}
        onClick={() => onActionClick()}
      >
        {t(actionDetails.label)}
      </GenericActionButton>
    );
  };

  const onActionClick = (data?) => {
    const details = data || actionDetails;
    if (details.requireRows && !selectedRowKeys?.length) {
      showOneOrMoreMessage();
      return;
    }
    trackGaEvents({actionType: TABLE_ACTION}, { details });
    if (details.type === 'route') {
      history.push({
        pathname: details.route(),
      });
    } else {
      setActionDetailsData(details);
    }
  };

  const showOneOrMoreMessage = () => {
    message.info('Please select one or more consignment');
  };

  const onModalClose = (res) => {
    if (res && params && params.reload) {
      // reload data
      params.reload();
    }
    setActionDetailsData(null);
  };

  const onOkClick = () => {
    if (actionDetailsData.okProps) {
      setActionDetailsData({
        ...actionDetailsData,
        extraProps: {
          ...(actionDetailsData.extraProps || {}),
          ...actionDetailsData.okProps,
        },
      });
    }
  };

  const renderModal = () => {
    const modalComponent = actionDetailsData?.modalComponent;
    if (modalComponent) {
      const Component = modalComponent;
      const extraProps = actionDetailsData.extraProps || {};
      return <Component
        isVisible={true}
        handleModalClose={onModalClose}
        onModalClose={onModalClose}
        onCustomTripModalClose={onModalClose}
        currentActionConfig={actionDetailsData}
        onOkClick={onOkClick}
        consignmentList={selectedRowKeys}
        isAsync={asyncRequestCreation}
        consignments={selectedRowKeys}
        selectedHub={currHub}
        hubId={currHub.id}
        filters={globalAppliedFilters}
        timeSlots={[]}
        dateFilter={date}
        showTripStartTime={true}
        allConsignments={tableData}
        validationBeforeAddingCN={cnRescheduleDateValidation}
        params={params}
        {...extraProps}
      />;
    }
    return null;
  };

  return (
    <>
      {renderAction()}
      {renderModal()}
    </>
  );
};

const ConsignmentActionComponentStyled = withStyles(styles, { injectTheme: true })(
  ConsignmentActionComponent,
);

const mapStateToProps = (
  { masterData, cachedData, genericPageReducer },
  ownProps,
) => {
  const { params = {} } = ownProps;
  const { pageId, viewIndex, sectionIndex } = params;
  const pageData: PageData = genericPageReducer[pageId];
  const viewData = pageData.metadata.sectionList[sectionIndex][viewIndex];
  return {
    asyncRequestCreation:
      masterData.auto_allocation_asynchronous_request || false,
    cnRescheduleDateValidation: masterData.cn_rescheduled_date_validation || false,
    currHub: cachedData.currHub,
    globalAppliedFilters: {
      hubIdList: [ cachedData.currHub?.id ],
      timeFilters: [
        {
          ...(pageData.appliedGlobalTimeFilter || {}),
        },
      ]
    },
    bucketAppliedFilters: viewData.appliedFilters,
    selectedRowKeys: viewData.selectedRowKeys,
    date: {
      start: pageData.appliedGlobalTimeFilter.fromDateString,
      end: pageData.appliedGlobalTimeFilter.toDateString,
      dateFilterType: pageData.appliedGlobalTimeFilter.columnName,
    },
    tableData: viewData.dataObjects,
    isTableLoading: viewData.isFetchingData,
    currentPageNumber: viewData.pagination.pageNumber,
    perPageCount: viewData.pagination.resultPerPage,
    isNextPresent: viewData.pagination.isNextPresent,
  };
};

export default withTranslation('translation')(connect(mapStateToProps)(ConsignmentActionComponentStyled));
