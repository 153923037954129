import * as React from 'react';
import * as moment from 'moment';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '../../theming/jssTypes';
import { Button } from 'antd';
import { CloseOutlined, InfoCircleFilled } from '@ant-design/icons';
const styles = (theme: ThemeType): CssType => ({
  paymentOverdueBanner: {
    backgroundColor: '#F03E3E',
    padding: '15px',
    flexDirection: 'row',
    display: 'flex',
  },
  icon: {
    color: 'white',
    fontSize: '18px',
    marginRight: '10px',
    height: '100%',
  },
  textDiv: {
    display: 'inline-block',
    color: '#FFFFFF',
    fontFamily: 'Open Sans',
    fontSize: '15px',
    verticalAlign: 'top',
    marginTop: '-1.5px',
  },
  closeButton: {
    border: 'none',
    backgroundColor: '#F03E3E',
    color: '#FFFFFF',
    outline: 'none',
    height: '100%',
  },
});

const PaymentOverdueBanner = (props: any) => {
  const { paymentConfig, onBannerClose, classes } = props;

  const paymentBannerMessage =
    paymentConfig.banner_message ||
    'This account has an outstanding payment. Kindly make payment at your earliest convenience.';
  const passedOverdueDate = moment(
    paymentConfig.due_date,
    'YYYY-MM-DD',
  ).isBefore();

  const onCloseClick = async () => {
    onBannerClose();
  };

  return (
    <div className={classes.paymentOverdueBanner}>
      <InfoCircleFilled className={classes.icon} />
      <div className={classes.textDiv}>{paymentBannerMessage}</div>
      {!passedOverdueDate && (
        <Button
          type="text"
          className={classes.closeButton}
          onClick={onCloseClick}
        >
          <CloseOutlined className={classes.icon} />
        </Button>
      )}
    </div>
  );
};

export default withStyles(styles, { injectTheme: true })(PaymentOverdueBanner);
