import apiProvider from './provider';
import {
  getAuthenticationHeaders,
  getErrorMessage,
  getErrorMessageForBlob,
} from './utils';
import qs from 'qs';
import { ResponseType } from 'axios';

const getFileNameFromHeaders = (headers) => {
  const disposition = headers['content-disposition'];
  let filename = '';
  if (disposition) {
    const matches = disposition.match(/filename=(.*)/);
    filename =
      matches && matches.length && matches.length > 0
        ? decodeURI(matches[1])
        : '';
    if (filename && filename.startsWith('"') && filename.endsWith('"')) {
      filename = filename.substring(1, filename.length - 1);
    }
  }
  return filename;
};

export const GET = async (
  url: string,
  params?: any,
  responseType?: ResponseType,
  headers?: any,
): Promise<any> => {
  try {
    const response = await apiProvider.get(url, {
      params,
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
      headers: headers || (await getAuthenticationHeaders()),
      responseType,
    });
    return {
      fileName:
        responseType === 'blob' && getFileNameFromHeaders(response.headers),
      isSuccess: true,
      data:
        responseType === 'blob'
          ? response.data
          : response?.data?.data || response?.data,
      status: response.status,
      response: response?.data,
    };
  } catch (err) {
    const errorMessage =
      responseType === 'blob'
        ? await getErrorMessageForBlob(err)
        : getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
      status: err && err.response && err.response.status,
    };
  }
};

export const POST = async (
  url: string,
  body: any,
  headers?: any,
  isBlob = false,
): Promise<any> => {
  try {
    const meta = {
      headers: headers || (await getAuthenticationHeaders()),
    };
    if (isBlob) {
      meta['responseType'] = 'blob';
    }
    const response = await apiProvider.post(url, body, meta);
    const dataToReturn = {
      isSuccess: true,
      data: response?.data?.data || response?.data,
      response: response?.data,
      message: response?.data?.message,
    };
    if (isBlob) {
      dataToReturn['filename'] = getFileNameFromHeaders(response.headers);
      dataToReturn.data = response.data;
    }
    return dataToReturn;
  } catch (err) {
    const errorMessage = isBlob
      ? await getErrorMessageForBlob(err)
      : getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
      errFailures: err.response?.data?.error?.failures,
      errData: err.response?.data?.data?.message || 'Something went wrong',
      errCode: err.response?.data?.error?.code,
    };
  }
};

export const DELETE = async (
  url: string,
  params?: any,
  responseType?: ResponseType,
  headers?: any,
): Promise<any> => {
  try {
    const response = await apiProvider.delete(url, {
      params,
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
      headers: headers || (await getAuthenticationHeaders()),
      responseType,
    });
    return {
      fileName:
        responseType === 'blob' && getFileNameFromHeaders(response.headers),
      isSuccess: true,
      data:
        responseType === 'blob'
          ? response.data
          : response?.data?.data || response?.data,
      status: response.status,
      response: response?.data,
    };
  } catch (err) {
    const errorMessage =
      responseType === 'blob'
        ? await getErrorMessageForBlob(err)
        : getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
      status: err && err.response && err.response.status,
    };
  }
};
