import Icon from '@ant-design/icons';
import * as React from 'react';
const JpgFileIconsvg = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    dataName="Layer 1"
    viewBox="0 0 118 150"
    {...props}
  >
    <defs>
      <style>
        {'.jpg-cls-1{fill:#50bee8;}.jpg-cls-2{fill:#82dcfe;}.jpg-cls-3{fill:#fff;}'}
      </style>
    </defs>
    <path
      className="jpg-cls-1"
      d="M80.09,43.76a6,6,0,0,1-6-6V0h-59A15.08,15.08,0,0,0,0,15V135a15.08,15.08,0,0,0,15.06,15h87.88A15.08,15.08,0,0,0,118,135V43.76Z"
    />
    <path
      className="jpg-cls-2"
      d="M79.57,43.73h38.18L74,0V38.19A6,6,0,0,0,79.57,43.73Z"
    />
    <path
      className="jpg-cls-3"
      d="M24.55,117.36A3.73,3.73,0,1,1,21,121.11,3.59,3.59,0,0,1,24.55,117.36Z"
    />
    <path
      className="jpg-cls-3"
      d="M34.71,104.33h5.93v19.88c0,4.36-1.7,8-7.14,8a9.23,9.23,0,0,1-3.51-.56L31,127.32a5.18,5.18,0,0,0,1.57.28c1.58,0,2.1-1,2.1-3.22Zm-.44-6a3.13,3.13,0,0,1,3.42-3.06,3.1,3.1,0,1,1,0,6.17A3.16,3.16,0,0,1,34.27,98.28Z"
    />
    <path
      className="jpg-cls-3"
      d="M51.81,125.91v5.85H45.88V104.33h4.84l.4,2h.17A8.79,8.79,0,0,1,57,103.85c4.84,0,7.83,4,7.83,10.16,0,6.86-4.12,10.81-8.43,10.81a6.93,6.93,0,0,1-4.76-2.1ZM55,120c2,0,3.71-1.77,3.71-5.89,0-3.59-1.09-5.4-3.47-5.4a4.63,4.63,0,0,0-3.43,1.81v8.19A4.6,4.6,0,0,0,55,120Z"
    />
    <path
      className="jpg-cls-3"
      d="M70.61,123.73v-.16a3.84,3.84,0,0,1-1.86-3.39A4.84,4.84,0,0,1,71,116.43v-.16a6.4,6.4,0,0,1-2.7-5.2c0-4.8,4-7.22,8.26-7.22a9.4,9.4,0,0,1,3.11.48H87v4.32H83.79a5.24,5.24,0,0,1,.65,2.62c0,4.6-3.47,6.73-7.87,6.73a8,8,0,0,1-2.41-.4,1.69,1.69,0,0,0-.73,1.49c0,1,.81,1.5,3,1.5h3.23c4.92,0,7.62,1.49,7.62,5.08,0,4.15-4.31,7.17-11.17,7.17-4.68,0-8.39-1.53-8.39-5.08C67.74,126.11,68.75,124.78,70.61,123.73Zm6.45,5.4c2.66,0,4.6-1.08,4.6-2.46s-1.09-1.57-3.07-1.57H76.53a11.73,11.73,0,0,1-2.82-.24,2.63,2.63,0,0,0-1.09,2C72.62,128.33,74.4,129.13,77.06,129.13Zm2.26-18.06c0-2.1-1.17-3.23-2.75-3.23s-2.74,1.09-2.74,3.23,1.17,3.34,2.74,3.34S79.32,113.29,79.32,111.07Z"
    />
  </svg>
);

const JpgFileIcon = (props: any) => (
  <Icon component={JpgFileIconsvg} {...props} />
);

export default JpgFileIcon;
