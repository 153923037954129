import { CssType, ThemeType } from '../../../../../../../theming/jssTypes';

const styles = (theme: ThemeType): CssType => ({
  container: {
    padding: '16px 24px',
    backgroundColor: '#FAFAFA',
    display: 'flex',
    flexDirection: 'column',
    height: '400px',
  },
  loadingContainer: {
    height: '400px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FAFAFA',
  },
  search: {
    backgroundColor: '#FFF',
    padding: '5px 12px',
    marginBottom: '12px',
  },
  options: {
    overflow: 'scroll',
  },
});

export default styles;
