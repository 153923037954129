import withStyles from 'react-jss';
import { connect } from 'react-redux';
import styles from './styles';
import ConsignmentPriceCalculator from './container';

const mapStateToProps = ({ masterData, cachedData }) => {
  return {
    currHub: cachedData.currHub,
    currencySymbol:
      masterData?.currency_symbol || '',
    ConsignmentPriceCalculatorColumnList:
      masterData?.ops_consignment_column_list?.consignment_price_calculator || [],
    ltlOrgActiveCourierPartner:
      masterData.ltl_masterdata.LTL_ORG_ACTIVE_DISTINCT_COURIER_PARTNERS || [],
  };
};

const StyledConsignmentPriceCalculator = withStyles(styles, { injectTheme: true })(
  ConsignmentPriceCalculator,
);
export default connect(mapStateToProps)(StyledConsignmentPriceCalculator);
