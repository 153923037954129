import { getToken as getFcmToken } from 'firebase/messaging';
import { messaging } from 'src/firebase';
import { FIREBASE_VAPID_KEY, FIREBASE_CONFIG } from 'src/globals';
import { updateFcmToken, getNotificationAuthToken } from 'src/api/dashboard';
import { isInAppNotificationAllowed } from 'src/utils/utils';
import { SET_NOTIFICATION_AUTH_TOKEN } from 'src/actions/constants';

const notificationAuthTokenInitialisation = async (dispatch) => {
  const response = await getNotificationAuthToken();
  dispatch({
    type: SET_NOTIFICATION_AUTH_TOKEN,
    data: response?.data?.authToken,
  });
  return;
};

export const notificationInitialisation = (dispatch) => {
  if(isInAppNotificationAllowed()){
    notificationAuthTokenInitialisation(dispatch);
  }
  if (!FIREBASE_VAPID_KEY) {
    return;
  }
  const userAgent = navigator.userAgent;
  const requestPermission = async (removeEventListener = true) => {
    if (!('Notification' in window)) {
      return;
    }
    try {
      const result = await Notification?.requestPermission();
      if (result === 'granted' && !userAgent.includes('iPhone')) {
        let serviceWorkerRegistration;
        let scriptURL = '/crm-assets/firebase-messaging-sw.js';
        scriptURL += `?firebaseConfig=${JSON.stringify(FIREBASE_CONFIG)}`;

        if ('serviceWorker' in navigator) {
          serviceWorkerRegistration = await navigator.serviceWorker.register(
            scriptURL,
          );
        } else {
          console.log('Service workers are not supported.');
        }
        const token = await getFcmToken(messaging, {
          vapidKey: FIREBASE_VAPID_KEY,
          serviceWorkerRegistration,
        });
        await updateFcmToken({ fcm_token: token });
      }
      if (removeEventListener) {
        document.removeEventListener('click', requestPermission);
      }
    } catch (error) {
      console.error('Error requesting permission:', error);
    }
  };
  if (userAgent.includes('Chrome')) {
    requestPermission(false);
  } else {
    document.addEventListener('click', requestPermission);
  }
  return;
};
