export const dateFilterOptions = [
  { key:'created_at', val: 'Created At' },
  { key:'last_main_event_time', val: 'Last Updated' },
];

export const statusFilterOptions = [
  { key: 'delivered', val: 'Delivered' },
  { key: 'rto', val: 'RTO' },
  { key: 'rto_initiated', val: 'RTO Initiated' },
  { key: 'rto_intransit', val: 'RTO in Transit' },
  { key: 'rto_delivered', val: 'RTO Delivered' },
  { key: 'reachedathub', val: 'Reached at Hub' },
  { key: 'outfordelivery', val: 'Out For Delivery' },
  { key: 'attempted', val: 'Undelivered' },
  { key: 'inscan_at_hub', val: 'Inscan at Hub' },
  { key: 'in_transit', val: 'In transit' },
  { key: 'pickup_awaited', val: 'Pickup Awaited' },
  { key: 'pickup_scheduled', val: 'Pickup Scheduled' },
  { key: 'out_for_pickup', val: 'Out for Pickup' },
  { key: 'not_picked_up', val: 'Not Picked Up' },
  { key: 'pickup_completed', val: 'Pickup Completed' },
  { key: 'on_hold', val: 'On Hold' },
  { key: 'cancel', val: 'Cancelled' },
];

export const fixedColumnListLeftSide = [
  {
    column_id: 'reference_number',
    pretty_name: 'Reference #',
    fixed: 'left',
    width: 150,
  },
  {
    column_id: 'courier_partner_reference_number',
    pretty_name: 'Carrier AWB #',
    fixed: 'left',
    width: 150,
  },
];

export const courierTatColumnList = [
  {
    column_id: 'courier_tat_details.tat',
    pretty_name: 'Courier Tat',
  },
  {
    column_id: 'actual_tat',
    pretty_name: 'Actual Tat',
  },
];

export const fixedColumnListRightSide = [
  {
    column_id: 'courier_price_details.finalPrice',
    pretty_name: 'Courier Final Price',
    fixed: 'right',
    width: 150,
  },
  {
    column_id: 'courier_amount',
    pretty_name: 'Courier Amount',
    fixed: 'right',
    width: 120,
  },
];

export const getLastSortedColumnValue = (nextOrPrev, pagination) => {
  switch (nextOrPrev) {
    case 'prev':
      return pagination.lastSortedColumnValuePrev;
    case 'next':
      return pagination.lastSortedColumnValueNext;
    default:
      return null;
  }
};

export const getLastRowId = (nextOrPrev, pagination) => {
  switch (nextOrPrev) {
    case 'prev':
      return pagination.lastRowIdPrev;
    case 'next':
      return pagination.lastRowIdNext;
    default:
      return null;
  }
};

export const getPageNumber = (nextOrPrev, pageNumber) => {
  switch (nextOrPrev) {
    case 'prev':
      return pageNumber - 1;
    case 'next':
      return pageNumber + 1;
    default:
      return 1;
  }
};
