import { connect } from 'react-redux';
import * as React from 'react';
import { setCurrHub } from '../../actions/cachedDataActions';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Store, ViewType } from '../../types/common.types';
import { setViewType } from '../../actions/loadInitialData';
import { getEmployeeHub } from '../../api/genericConsignmentView';
import { LocalStorageKeys, LocalStorageService } from 'src/services/localStorage';

interface SwitchViewTypeProps {
  children: React.ReactNode;
  currStore: Store;
  setViewType: (viewType: ViewType) => void;
  setCurrHub: (...args) => void;
  viewType: string;
  showOndemandOptions: boolean;
  showLogisticsExpressOptions: boolean;
  [key: string]: any;
}

const SwitchViewType: React.FC<SwitchViewTypeProps> = (
  props: SwitchViewTypeProps,
) => {
  const {
    children,
    currStore,
    setCurrHub,
    setViewType,
    viewType,
    showLogisticsExpressOptions,
    showOndemandOptions,
    ...remainingProps
  } = props;

  const history = useHistory();
  const targetViewType =
    viewType === 'ondemand' ? 'logistics_express' : 'ondemand';

  const getTargetHub = async () => {
    const targetHubType = targetViewType === 'ondemand' ? 'ondemand' : 'dc';

    const response = await getEmployeeHub({
      hubType: targetHubType,
      realFilter: true,
    });
    if (response.isSuccess) {
      const hubFromAPI: Store | undefined = response.data?.[0];
      return hubFromAPI;
    }
  };

  const setTargetHub = (targetHub) => {
    setCurrHub({
      currHub: targetHub,
    });
  };

  const setTargetViewType = () => {
    setViewType(targetViewType);
    LocalStorageService.set(LocalStorageKeys.__REDIRECT_TO_VIEW_TYPE__, targetViewType);
  };

  const setTargetUrl = () => {
    const redirectionUrl =
      targetViewType === 'ondemand' ? '/od/reporting' : '/consignments';
    history.push(redirectionUrl);
  };

  const handleClick = async () => {
    const is_target_view_type_allowed =
      (targetViewType === 'logistics_express' && showLogisticsExpressOptions) ||
      (targetViewType === 'ondemand' && showOndemandOptions);
    if (is_target_view_type_allowed) {
      const targetHub = await getTargetHub();
      if (targetHub) {
        setTargetHub(targetHub);
        setTargetViewType();
        setTargetUrl();
      }
    }
  };

  const renderFallbackContent = () => {
    return <span>Switch View</span>;
  };

  return (
    <div onClick={handleClick} {...remainingProps}>
      {children || renderFallbackContent()}
    </div>
  );
};

const mapStateToProps = ({ masterData, cachedData }) => {
  return {
    currStore: cachedData.currHub,
    viewType: masterData.viewType,
    showLogisticsExpressOptions: masterData.show_logistics_express_options,
    showOndemandOptions: masterData.show_ondemand_options,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setCurrHub,
      setViewType,
    },
    dispatch,
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SwitchViewType) as React.FC<any>,
);
