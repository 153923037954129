import Icon from '@ant-design/icons';
import * as React from 'react';

const HubsSvg = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Setup_Tooltip" transform="translate(-16.000000, -170.000000)">
                <g id="Group-6" transform="translate(0.000000, 58.000000)">
                    <g id="Hubs" transform="translate(16.000000, 112.000000)">
                        <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                        <g transform="translate(1.500000, 1.500000)" fill="#666666">
                            <path d="M10.5,1.60942 C5.58987,1.60942 1.60942,5.58987 1.60942,10.5 C1.60942,15.4101 5.58987,19.3906 10.5,19.3906 C15.4101,19.3906 19.3906,15.4101 19.3906,10.5 C19.3906,5.58987 15.4101,1.60942 10.5,1.60942 Z M0,10.5 C0,4.70101 4.70101,0 10.5,0 C16.299,0 21,4.70101 21,10.5 C21,16.299 16.299,21 10.5,21 C4.70101,21 0,16.299 0,10.5 Z" id="Shape"></path>
                            <path d="M10.7744,0.32598 C11.0656,-0.00978 11.5738,-0.04594 11.9096,0.24522 C13.3789,1.51939 14.5573,3.09444 15.3649,4.86371 C16.1725,6.63297 16.5904,8.5551 16.5904,10.5 C16.5904,12.4449 16.1725,14.367 15.3649,16.1363 C14.5573,17.9056 13.3789,19.4806 11.9096,20.7548 C11.5738,21.0459 11.0656,21.0098 10.7744,20.674 C10.4832,20.3382 10.5194,19.83 10.8552,19.5389 C12.1503,18.4158 13.189,17.0275 13.9008,15.468 C14.6126,13.9085 14.981,12.2143 14.981,10.5 C14.981,8.7857 14.6126,7.09149 13.9008,5.53201 C13.189,3.97253 12.1503,2.58423 10.8552,1.46115 C10.5194,1.16998 10.4832,0.66175 10.7744,0.32598 Z" id="Path"></path>
                            <path d="M10.2257,0.32598 C10.5169,0.66175 10.4807,1.16998 10.145,1.46115 C8.8498,2.58423 7.81116,3.97253 7.09932,5.53201 C6.38748,7.09149 6.01909,8.7857 6.01909,10.5 C6.01909,12.2143 6.38748,13.9085 7.09932,15.468 C7.81116,17.0275 8.8498,18.4158 10.145,19.5389 C10.4807,19.83 10.5169,20.3382 10.2257,20.674 C9.9345,21.0098 9.4263,21.0459 9.0906,20.7548 C7.6212,19.4806 6.44281,17.9056 5.63522,16.1363 C4.82762,14.367 4.40967,12.4449 4.40967,10.5 C4.40967,8.5551 4.82762,6.63297 5.63522,4.86371 C6.44281,3.09444 7.6212,1.51939 9.0906,0.24522 C9.4263,-0.04594 9.9345,-0.00978 10.2257,0.32598 Z" id="Path"></path>
                            <path d="M10.5,0 C10.9445,0 11.3047,0.36028 11.3047,0.80471 L11.3047,20.1953 C11.3047,20.6397 10.9445,21 10.5,21 C10.0556,21 9.6953,20.6397 9.6953,20.1953 L9.6953,0.80471 C9.6953,0.36028 10.0556,0 10.5,0 Z" id="Path"></path>
                            <path d="M0.88232,6.97094 C0.88232,6.52651 1.2426,6.16623 1.68703,6.16623 L19.3131,6.16623 C19.7575,6.16623 20.1178,6.52651 20.1178,6.97094 C20.1178,7.41537 19.7575,7.77565 19.3131,7.77565 L1.68703,7.77565 C1.2426,7.77565 0.88232,7.41537 0.88232,6.97094 Z" id="Path"></path>
                            <path d="M0.88232,14.0291 C0.88232,13.5846 1.2426,13.2244 1.68703,13.2244 L19.3131,13.2244 C19.7575,13.2244 20.1178,13.5846 20.1178,14.0291 C20.1178,14.4735 19.7575,14.8338 19.3131,14.8338 L1.68703,14.8338 C1.2426,14.8338 0.88232,14.4735 0.88232,14.0291 Z" id="Path"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    );
};

export const HubsIcon = props => (
  <Icon component={HubsSvg} {...props} />
);
