import * as React from 'react';
import withStyles from 'react-jss';
import { CssType, ThemeType } from 'src/theming/jssTypes';
import { connect } from 'react-redux';
import {
  message,
} from 'antd';
import { withTranslation } from 'react-i18next';

const styles = (theme: ThemeType): CssType => ({
  actionButton: {},
});

const ConsignmentTableActionComponent = (props: any) => {
  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const {
    classes,
    params,
    actionId,
    record,
    actionDetails,
    currHub,
    t,
  } = props;

  const onModalClose = (res) => {
    if (res && params && params.reload) {
      // reload data
      params.reload();
    }
    setModalVisible(false);
  };

  const renderModal = () => {
    const modalComponent = actionDetails?.modalComponent;
    if (modalComponent && modalVisible) {
      const Component = modalComponent;
      const extraProps = actionDetails.extraProps || {};
      return <Component
        isVisible={true}
        handleModalClose={onModalClose}
        onModalClose={onModalClose}
        currHub={currHub}
        viewData={viewData}
        {...extraProps}
      />;
    }
    return null;
  };

  const handleClick = async (e) => {
    e.preventDefault();
    if (actionDetails.modalComponent) {
      setModalVisible(true);
    } else if (actionDetails.handleClick) {
      setLoading(true);
      await actionDetails.handleClick({
        record,
        message,
      });
      setLoading(false);
    }
  }

  return (
    <div
    style={{ display: 'flex', alignItems: 'center' }}
      onClick={handleClick}
    >
      {t(actionDetails.label)}
      {renderModal()}
      {
        loading ? <Loader zIndex={2000} size={18} className={classes.loader} /> : <></>
      }
    </div>
  );
};

const ConsignmentTableActionComponentStyled = withStyles(styles, { injectTheme: true })(
  ConsignmentTableActionComponent,
);

const mapStateToProps = (
  { cachedData, genericPageReducer },
  ownProps,
) => {
  const { params } = ownProps;
  const {
    pageId,
    viewIndex,
    sectionIndex,
  } = ownProps;
  const pageData: PageData = genericPageReducer[pageId];
  const viewData = pageData.metadata.sectionList[sectionIndex][viewIndex];
  return {
    currHub: cachedData.currHub,
    viewData,
  };
};

export default withTranslation('translation')(
  connect(mapStateToProps)(ConsignmentTableActionComponentStyled)
);
