import * as React from 'react';
import App from './App';
import { Switch, Route, withRouter } from 'react-router';
import withStyles from 'react-jss';
import { CssType,
  ThemeType} from './theming/jssTypes';
import { timeoutPromise } from './utils/utils';
import { IframeApps } from './components/common/persistentIframeRenderer/constants';
import PersistentIframeInjector from
  './components/common/persistentIframeRenderer/PersistentIframeInjector';
import { RENDER_PERSISTENT_IFRAME_OPS } from './globals';

const styles: CssType = {
  loaderImage: {
    width: '100px',
    height: '100px',
  },
  loaderContainer: {
    width: '100%',
    height: '100%',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  customModalwrapper: {
    /* customCRMModals are hidden by default */
    position: 'absolute',
    top: '54px',
    zIndex: 99999999,
    display: 'none',
  },
  angularAppModalOpen: {
    display: 'block',
  },
};

class Main extends React.PureComponent<any, any> {

  state: any = {
    isLoading : true,
  };

  async componentDidMount() {
    // await timeoutPromise(2000);
    this.setState({
      isLoading: false,
    });
  }

  render() {
    const { classes } = this.props;
    return (
      this.state.isLoading ?
      (
        <div className={classes.loaderContainer}>
          <div style={{ textAlign: 'center' }}>
            Loading
          </div>
        </div>
      )
      :
      (
        <React.Fragment>
          <Switch>
            <Route component={App} />
          </Switch>
          {/* {
            RENDER_PERSISTENT_IFRAME_OPS &&
            <PersistentIframeInjector appName={IframeApps.OpsDashboard} />
          } */}
        </React.Fragment>
      )
    );
  }
}
const MainStyled = withStyles(styles, { injectTheme: true })(Main);
export default withRouter(MainStyled as React.ComponentType<any>) as  React.ComponentType<any>;
