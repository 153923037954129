import React, { useState } from 'react';
import { connect } from 'react-redux';
import withStyles, { WithStylesProps } from 'react-jss';
import { CssType, ThemeType } from 'src/theming/jssTypes';
import DocumentUploadModal from 'src/components/pages/retail/dashboard/DocumentUploadModal';
import { uploadConsignmentDocuments } from 'src/api/genericConsignmentView';
import { message } from 'antd';

const styles = (theme: ThemeType): CssType => ({
  btn: {
    color: 'blue',
    cursor: 'default',
  },
});

interface IProps extends WithStylesProps<ReturnType<typeof styles>>,ReturnType<typeof mapStateToProps> {
    record: any;
    isVisible: boolean;
    handleModalClose: (refresh: boolean) => void;
}

const UploadCNDocuments = (props: IProps) => {
    const {
        record,
        classes,
        isVisible,
        handleModalClose,
        maxDocuments,
    } = props;

    const handleDocumentUpload = async (data) => {
        const payload = {
            referenceNumber: record.reference_number,
            documentDetails: data
        };
        const response = await uploadConsignmentDocuments(payload);
        if(response.isSuccess){
            message.success('Documents Uploaded Successfully');
            handleModalClose(true);
        }else{
            message.error(response.errorMessage);
        }
    };

    return <DocumentUploadModal
        record={record}
        maxCount={maxDocuments}
        documentType={'consignment_documents'}
        title={'Upload Documents'}
        isVisible={isVisible}
        onClose={handleModalClose}
        postUpload={handleDocumentUpload}
    />;
};

const mapStateToProps = (
    { masterData },
) => {
    return {
        maxDocuments: masterData?.number_of_documents_allowed_in_consignment || 1,
    };
};

export default connect(mapStateToProps)(withStyles(styles, { injectTheme: true })(UploadCNDocuments));
