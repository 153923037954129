export const getTmsOverviewRoute = () => {
  return '/tms/overview';
};
export const getTmsVehicleRoute = () => {
  return '/tms/vehicle';
};
export const getTmsISTRoute = () => {
  return '/tms/ist';
};
export const getTmsBagRoute = () => {
  return '/tms/bag';
};
export const getTmsDownloadRequestRoute = () => {
  return '/tms/download-requests';
};
export const getTMSUnloadingRoute = () => {
  return '/tms/unloading';
};
export const getTMSTripCloseRoute = () => {
  return '/tms/trip-close';
};
export const getTMSCreateTripRoute = () => {
  return '/tms/create-trip';
};
export const getTMSLoadingRoute = () => {
  return '/tms/loading';
};
export const getTMSOffloadRoute = () => {
  return '/tms/offload';
};
export const getTMSIstDetailsRoute = (tripId) => {
  return `/tms/ist/details/${tripId}`;
};
export const getTMSBagDetailsRoute = (refNo) => {
  return `/tms/bag/details/${refNo}`;
};
export const getCreateIstLoadingRoute = () => {
  return '/tms/create-trip/loading';
};
export const getTMSCreateBagRoute = () => {
  return '/tms/create-bag';
};
export const getTMSAssignThirdPartyBagRoute = () => {
  return '/tms/assign-third-party-bag';
};
export const createBagStartLoadingRoute = () => {
  return '/tms/create-bag-start-loading';
};
export const thirdPartyBagStartLoadingRoute = () => {
  return '/tms/third-party-start-loading';
};
export const getTMSDeBagRoute = () => {
  return '/tms/de-bag';
};
export const getRouteDetailsPageRoute = (routeId) => {
  return `/tms/route/details/${routeId}`;
};
export const getTMSCreatePTLTripRoute= () => {
  return '/tms/create-ptl-trip';
};
export const getTmsVehicleAttendanceRoute = () => {
  return '/tms/vehicleAttendance';
};
export const getAttendanceDetails = (id = undefined,date = undefined) => {
  return id ? `/tms/vehicleAttendance/details?tripId=${id}&date=${date}` : '/tms/vehicleAttendance/details';
};
