import * as React from 'react';
import { connect } from 'react-redux';
import { Modal, Row, Col, Select, Input } from 'antd';
import { useTheme } from 'react-jss';
import useStyles from './InventoryInscanModal.styles';
import InventoryScanConfirmationModal from './InventoryScanConfirmationModal';
const { useState } = React;
const Option = Select.Option;
const InventoryInscanModal = (props) => {
  const { isVisible, handleModalClose, currHub } = props;
  const theme = useTheme();
  const classes = useStyles({ ...props, theme });
  const [inventoryScanAction, setInventoryScanAction] = useState('');
  const [comments, setComments] = useState('');
  const [
    isInventoryScanConfirmationModalVisible,
    setInventoryScanConfirmationModalVisible,
  ] = useState(false);
  const handleSubmit = () => {
    setInventoryScanConfirmationModalVisible(true);
  };
  const onInventoryScanActionChange = (value) => {
    setInventoryScanAction(value);
  };
  const onCommentsChange = (event) => {
    setComments(event.target.value);
  };
  const onConfirmationModalClose = () => {
    setInventoryScanConfirmationModalVisible(false);
    handleModalClose();
  };
  return (
    <Modal
      title={'Inventory Inscan'}
      width={500}
      visible={isVisible}
      onCancel={handleModalClose}
      onOk={handleSubmit}
      okText={'Submit'}
      destroyOnClose
    >
      <div>
        <Row className={classes.row}>
          <Col span={6}>Inventory Scan: </Col>
          <Col span={12}>
            <Select
              placeholder="Select Inventory Scan"
              value={(inventoryScanAction && inventoryScanAction) || undefined}
              onChange={onInventoryScanActionChange}
              className={classes.selectStyle}
            >
              <Option
                key={'start_inventory_scan'}
                value={'start_inventory_scan'}
              >
                Start Inventory Scan
              </Option>
              <Option key={'stop_inventory_scan'} value={'stop_inventory_scan'}>
                Stop Inventory Scan
              </Option>
            </Select>
          </Col>
        </Row>
        <Row className={classes.row}>
          <Col span={6}>Comments: </Col>
          <Col span={12}>
            <Input
              placeholder="Write Comments"
              value={comments}
              onChange={onCommentsChange}
              className={classes.inputStyle}
            />
          </Col>
        </Row>
      </div>
      {isInventoryScanConfirmationModalVisible && (
        <InventoryScanConfirmationModal
          isVisible={isInventoryScanConfirmationModalVisible}
          currHub={currHub}
          comments={comments}
          inventoryScanAction={inventoryScanAction}
          handleModalClose={onConfirmationModalClose}
        />
      )}
    </Modal>
  );
};

const mapStateToProps = ({ cachedData }) => {
  return {
    currHub: cachedData.currHub,
  }
};

export default connect(mapStateToProps)(InventoryInscanModal);
