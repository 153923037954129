import * as React from 'react';
import withStyles from 'react-jss';
import { CssType,
  ThemeType} from '../../../../theming/jssTypes';
import { connect } from 'react-redux';
import { NAVBAR_HEIGHT } from '../../../../globals';
const styles = (theme: ThemeType): CssType => ({
  leftDiv: {
    margin: 10,
  },
  mainDiv: {
    display:'flex',
    height: NAVBAR_HEIGHT,
    width: '100%',
    backgroundColor: theme.colors.globalHeader,
    border: `1px solid ${theme.colors.borderColor}`,
  },
  filterGroupToolsLeft: {
    alignItems: 'center',
    display: 'flex',
    justifyContent:'flex-start',
    marginLeft:'20px',
  },
  filterGroupToolsRight: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    flex:1,
  },
});

class NavBar extends React.PureComponent<any, any> {

  render () {
    const { classes, navLogoURL, leftComponents, rightComponents } = this.props;
    return(
      <div className={classes.mainDiv}>
        <div className={classes.leftDiv} >
          <img style={{ maxHeight: 30 }}
                src={navLogoURL} />
        </div>

        {/* left components */}
        <div className={classes.filterGroupToolsLeft}>
        {leftComponents ?
          leftComponents.map(
            (item, i) => <div key={i}> {item} </div>,
          )
          :
          null
        }
      </div>

      {/* right components */}
      <div className={classes.filterGroupToolsRight}>
        {rightComponents ?
          rightComponents.map(
            (item, i) => <div key={i}> {item} </div>,
          )
          :
          null
        }
      </div>
  </div>
    );

  }
}

const mapStateToProps = ({ masterData }) => {
  return {
    navLogoURL: masterData.logoUrl,
  };
};
const StyledNavBar = withStyles(styles, { injectTheme: true })(NavBar);
export default connect(mapStateToProps)(StyledNavBar);