// tslint:disable: max-line-length

import Icon from '@ant-design/icons';
import * as React from 'react';
const Unloading = (props: any) => (
    <svg width="14" height="44" viewBox="0 0 14 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="6" width="2" height="32" rx="1" fill="#2D2D2D" />
        <rect y="31" width="14" height="13" rx="4" fill="#2D2D2D" />
        <path fillRule="evenodd" clipRule="evenodd" d="M7 35.5C7 34.1193 5.88072 33 4.5 33C3.11929 33 2 34.1193 2 35.5C2 36.8807 3.11929 38 4.5 38C5.88072 38 7 36.8807 7 35.5ZM3.5 35.1667C3.3159 35.1667 3.16667 35.3159 3.16667 35.5C3.16667 35.6841 3.3159 35.8333 3.5 35.8333H5.5C5.6841 35.8333 5.83333 35.6841 5.83333 35.5C5.83333 35.3159 5.6841 35.1667 5.5 35.1667H3.5Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M7.48168 35.1667C7.49378 35.2761 7.5 35.3873 7.5 35.5C7.5 37.1568 6.15685 38.5 4.5 38.5C4.02105 38.5 3.56832 38.3878 3.16667 38.1882V39.8333C3.16667 40.3856 3.61438 40.8333 4.16667 40.8333H4.38165C4.52507 41.3152 4.9715 41.6667 5.5 41.6667C6.0285 41.6667 6.47493 41.3152 6.61835 40.8333H8.21498C8.3584 41.3152 8.80483 41.6667 9.33333 41.6667C9.86183 41.6667 10.3083 41.3152 10.4517 40.8333H11C11.5523 40.8333 12 40.3856 12 39.8333V38.1726C12 38.1138 11.9844 38.056 11.9549 38.0051L11.2726 36.8309C11.0936 36.5229 10.7643 36.3333 10.408 36.3333H9.49905L8.76055 35.5024C8.57078 35.2888 8.29875 35.1667 8.01308 35.1667H7.48168ZM9.66667 37V39.1667H11.3333V38.6667H10.3333C10.1492 38.6667 10 38.5174 10 38.3333C10 38.1492 10.1492 38 10.3333 38H11.1809L10.6962 37.1659C10.6365 37.0632 10.5267 37 10.408 37H9.66667ZM5.5 40C5.22385 40 5 40.2238 5 40.5C5 40.7761 5.22385 41 5.5 41C5.77615 41 6 40.7761 6 40.5C6 40.2238 5.77615 40 5.5 40ZM9.33333 40C9.05718 40 8.83333 40.2238 8.83333 40.5C8.83333 40.7761 9.05718 41 9.33333 41C9.60948 41 9.83333 40.7761 9.83333 40.5C9.83333 40.2238 9.60948 40 9.33333 40Z" fill="white" />
    </svg>

);
export const UnloadingIcon = (props) => (
    <Icon component={Unloading} {...props} />
);
