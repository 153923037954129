import React from 'react';
import { createUseStyles } from 'react-jss';
import { isMobile } from 'react-device-detect';
import { eventTypes } from '../notificationConstants';

import { TimeAndBadgeComponent } from './common';

const useStyle = createUseStyles({
  notificationBodyTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerTextStyle: {
    color: '#111',
    fontSize: '12px',
    fontWeight: 600,
  },
  userName: {
    color: '#666',
    textAlign: 'right',
    fontSize: '12px',
    fontWeight: 400,
  },
});
const createHeaderForNotificationBody = (props) => {
  const { item, entityIdPrettyName, classes } = props;
  switch (item) {
    case 'issue_comment':
      return (
        <span>
          <span className={classes.headerTextStyle} style={{ fontWeight: 400 }}>
            {' '}
            added a{' '}
          </span>
          <span className={classes.headerTextStyle}>Comment </span>
          {entityIdPrettyName && (
            <>
              on{' '}
              <span className={classes.headerTextStyle}>
                {entityIdPrettyName}
              </span>
            </>
          )}
        </span>
      );
    case 'issue_status_changed':
    case 'issue_assignee_changed':
      return (
        <span>
          <span className={classes.headerTextStyle} style={{ fontWeight: 400 }}>
            {' '}
            changed the{' '}
          </span>
          <span className={classes.headerTextStyle}>
            {item === 'issue_status_changed' ? 'Status' : 'Assignee'}{' '}
          </span>
          {entityIdPrettyName && (
            <>
              of{' '}
              <span className={classes.headerTextStyle}>
                {entityIdPrettyName}
              </span>
            </>
          )}
        </span>
      );
    case 'issue_created':
      return (
        <span>
          <span className={classes.headerTextStyle} style={{ fontWeight: 400 }}>
            {' '}
            created the{' '}
          </span>
          <span className={classes.headerTextStyle}>Incident </span>
          <span className={classes.headerTextStyle}>{entityIdPrettyName}</span>
        </span>
      );
  }
};
export const CreateTitleForNotificationBody = (props) => {
  const classes = useStyle();
  const {
    item,
    ticketName,
    sourceName,
    notificationId,
    notificationStatus,
    setNotificationStatus,
  } = props;
  return (
    <div className={classes.notificationBodyTitle}>
      <div>
        <span className={classes.userName}>{item.actionUser.userName}</span>
        {createHeaderForNotificationBody({
          item: eventTypes?.[sourceName]?.[item?.eventType],
          entityIdPrettyName: ticketName,
          classes,
        })}
      </div>
      {!isMobile && (
        <TimeAndBadgeComponent
          item={item}
          notificationId={notificationId}
          notificationStatus={notificationStatus}
          setNotificationStatus={setNotificationStatus}
        />
      )}
    </div>
  );
};
