// tslint:disable: max-line-length

import Icon from '@ant-design/icons';
import * as React from 'react';
const PlannedLoading = (props: any) => (
    <svg width="14" height="44" viewBox="0 0 14 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="6.5" y="12.5" width="1" height="31" rx="0.5" fill="white" />
        <rect x="6.5" y="12.5" width="1" height="31" rx="0.5" stroke="#515151" />
        <rect x="0.25" y="0.25" width="13.5" height="13.5" rx="3.75" fill="white" stroke="#515151" strokeWidth="0.5" />
        <path fillRule="evenodd" clipRule="evenodd" d="M6.26776 2.7604C5.29144 1.74653 3.70854 1.74653 2.73223 2.7604C1.75592 3.77426 1.75592 5.41805 2.73223 6.43191C3.70854 7.44578 5.29145 7.44577 6.26776 6.43191C7.24407 5.41805 7.24407 3.77426 6.26776 2.7604ZM4.83333 3.55769C4.83332 3.36652 4.68409 3.21154 4.49999 3.21153C4.3159 3.21154 4.16666 3.36652 4.16665 3.55769L4.16666 4.25001L3.49999 4.24999C3.3159 4.25001 3.16667 4.40498 3.16665 4.59616C3.16667 4.78733 3.3159 4.94231 3.49999 4.94232L4.16666 4.94231L4.16665 5.63462C4.16666 5.82579 4.3159 5.98077 4.49998 5.98077C4.68409 5.98077 4.83332 5.82579 4.83332 5.63461V4.94231H5.49998C5.68409 4.94231 5.83332 4.78733 5.83332 4.59614C5.83332 4.40498 5.68409 4.25001 5.49999 4.24999L4.83332 4.25001L4.83333 3.55769Z" fill="#515151" />
        <path fillRule="evenodd" clipRule="evenodd" d="M7.4817 4.25C7.4938 4.36366 7.50001 4.47916 7.50001 4.59616C7.50001 6.31673 6.15687 7.71154 4.50002 7.71154C4.02107 7.71154 3.56834 7.59499 3.16669 7.38771V9.09615C3.16669 9.66968 3.61441 10.1346 4.16669 10.1346H4.38167C4.52509 10.6351 4.97152 11 5.50002 11C6.02852 11 6.47495 10.6351 6.61837 10.1346H8.215C8.35841 10.6351 8.80484 11 9.33334 11C9.86184 11 10.3083 10.6351 10.4517 10.1346H11C11.5523 10.1346 12 9.66968 12 9.09615V7.37156C12 7.31049 11.9844 7.25048 11.9549 7.19766L11.2726 5.9783C11.0937 5.6584 10.7643 5.46154 10.408 5.46154H9.49906L8.76056 4.59861C8.57079 4.37688 8.29876 4.25 8.0131 4.25H7.4817ZM9.66667 6.15385V8.40385H11.3333V7.88462H10.3333C10.1492 7.88462 10 7.72964 10 7.53846C10 7.34728 10.1492 7.19231 10.3333 7.19231H11.1809L10.6962 6.32609C10.6365 6.21946 10.5268 6.15385 10.408 6.15385H9.66667ZM5.50002 9.26923C5.22387 9.26923 5.00002 9.50169 5.00002 9.78846C5.00002 10.0752 5.22387 10.3077 5.50002 10.3077C5.77617 10.3077 6.00002 10.0752 6.00002 9.78846C6.00002 9.50169 5.77617 9.26923 5.50002 9.26923ZM9.33334 9.26923C9.05719 9.26923 8.83334 9.50169 8.83334 9.78846C8.83334 10.0752 9.05719 10.3077 9.33334 10.3077C9.60949 10.3077 9.83334 10.0752 9.83334 9.78846C9.83334 9.50169 9.60949 9.26923 9.33334 9.26923Z" fill="#515151" />
    </svg>

);
export const PlannedLoadingIcon = (props) => (
    <Icon component={PlannedLoading} {...props} />
);
