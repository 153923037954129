import Icon from '@ant-design/icons';
import * as React from 'react';
const DocFileIconSvg = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    dataName="Layer 1"
    viewBox="0 0 118 150"
    height="24px"
    width="24px"
    {...props}
  >
    <defs>
      <style>
        {'.doc-cls-1{fill:#0263d1;}.doc-cls-2{fill:#4e92df;}.doc-cls-3{fill:#fff;}'}
      </style>
    </defs>
    <path
      className="doc-cls-1"
      d="M80.09,43.76a6,6,0,0,1-6-6V0h-59A15.08,15.08,0,0,0,0,15V135a15.08,15.08,0,0,0,15.06,15h87.88A15.08,15.08,0,0,0,118,135V43.76Z"
    />
    <path
      className="doc-cls-2"
      d="M79.57,43.73h38.18L74,0V38.19A6,6,0,0,0,79.57,43.73Z"
    />
    <path
      className="doc-cls-3"
      d="M24.54,117.36A3.73,3.73,0,1,1,21,121.11,3.58,3.58,0,0,1,24.54,117.36Z"
    />
    <path
      className="doc-cls-3"
      d="M40.48,103.85A6.55,6.55,0,0,1,45.4,106l-.25-3.07V96.06h5.93v28.28H46.24l-.4-2h-.16a7.92,7.92,0,0,1-5.32,2.46c-5,0-8.19-3.95-8.19-10.49S36.24,103.85,40.48,103.85ZM41.89,120a3.74,3.74,0,0,0,3.26-1.81V110a4.7,4.7,0,0,0-3.3-1.29c-1.9,0-3.59,1.77-3.59,5.56C38.26,118.21,39.59,120,41.89,120Z"
    />
    <path
      className="doc-cls-3"
      d="M64.92,103.85c5,0,9.72,3.83,9.72,10.48S70,124.82,64.92,124.82s-9.76-3.83-9.76-10.49S59.84,103.85,64.92,103.85Zm0,16.17c2.42,0,3.67-2.22,3.67-5.69s-1.25-5.68-3.67-5.68-3.67,2.22-3.67,5.68S62.46,120,64.92,120Z"
    />
    <path
      className="doc-cls-3"
      d="M88,103.85a8.1,8.1,0,0,1,5.85,2.3l-2.71,3.67a4.24,4.24,0,0,0-2.78-1.17c-2.86,0-4.68,2.22-4.68,5.68s1.9,5.69,4.48,5.69a5.81,5.81,0,0,0,3.59-1.45l2.38,3.75a10.42,10.42,0,0,1-6.66,2.5c-5.56,0-9.88-3.83-9.88-10.49A10.05,10.05,0,0,1,88,103.85Z"
    />
  </svg>
);

const DocFileIcon = (props: any) => (
  <Icon component={DocFileIconSvg} {...props} />
);

export default DocFileIcon;
