import { SET_NOTIFICATION_AUTH_TOKEN } from '../actions/constants';

  const getDefaultState = () => {
    return {
      authToken: '',
    };
  };

  const defaultState = getDefaultState();
  export default function (state: any = defaultState, action) {
    switch (action.type) {
      case SET_NOTIFICATION_AUTH_TOKEN: {
        return {
          ...state,
          firebaseAuthToken: action.data,
        };
      }
      default: return state;
    }
  }
