import axios from 'axios';
import { getAuthenticationHeaders, isAuthenticationHeadersPresent, clearUserCredentialFromCache } from './utils';
import { API_BASE_URL } from '../globals';
import { getDeploymentEnvironment } from '../utils/utils';

const apiProvider = axios.create({});

apiProvider.interceptors.request.use((config) => {
  const urlBeingRequested = config.url;
  const regex = /\.shipsy\.(in|io)\/.*$/;
  const isMatch = regex.test(urlBeingRequested);
  const deploymentEnvironment = getDeploymentEnvironment();
  if (isMatch) {
    config.headers['x-forwarded-for-green'] = (deploymentEnvironment === 'green');
  }
  return config;
});

// Add 401 interceptor
apiProvider.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  if (error.response.status === 401) {
    // Dispatch action that user is being force logged out
    // await dispatch(setForceLogout());
    if (isAuthenticationHeadersPresent()) {
      clearUserCredentialFromCache();
      const errorMessage = error.response?.data?.error?.message || 'User logged out';
      window.localStorage.setItem('login_page_error_message', errorMessage);
    }
    if ( window.location.pathname !== '/login' ){
      window.location.href = '/login';
    }
  }

  return Promise.reject(error);
});

const apiProviderWithBaseUrl = axios.create({baseURL: API_BASE_URL});

apiProviderWithBaseUrl.interceptors.request.use(async (config) => {
  const {headers} = config;
  const newHeaders = await getAuthenticationHeaders();
  if (headers) {
    config.headers = {...headers, ...newHeaders};
  }
  config.headers = newHeaders;
  const urlBeingRequested = config.url;
  const regex = /\.shipsy\.(in|io)\/.*$/;
  const isMatch = regex.test(urlBeingRequested);
  const deploymentEnvironment = getDeploymentEnvironment();
  if (isMatch) {
    config.headers['x-forwarded-for-green'] = (deploymentEnvironment === 'green');
  }
  return config;
});

// Add 401 interceptor
apiProviderWithBaseUrl.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  if (error.response.status === 401) {
    // Dispatch action that user is being force logged out
    // await dispatch(setForceLogout());
    if (isAuthenticationHeadersPresent()) {
      clearUserCredentialFromCache();
      const errorMessage = error.response?.data?.error?.message || 'User logged out';
      window.localStorage.setItem('login_page_error_message', errorMessage);
    }
    if ( window.location.pathname !== '/login' ){
      window.location.href = '/login';
    }
  }

  return Promise.reject(error);
});

export  {apiProviderWithBaseUrl};

export default apiProvider;
