import { Tooltip } from 'antd';
import * as React from 'react';
import type { ResizeCallbackData } from 'react-resizable';
import withStyles, { WithStylesProps } from 'react-jss';
import { CssType, ThemeType } from 'src/theming/jssTypes';
import Draggable from 'react-draggable';

const styles = (theme: ThemeType): CssType => ({
  tooltip: {
      '& .ant-tooltip-inner > div': {
          color: '#FFFFFF !important',
      },
      '& .ant-tooltip-inner > div > div': {
          color: '#FFFFFF !important',
      },
      '& .ant-tooltip-inner > div > div > div': {
          color: '#FFFFFF !important',
      },
      '& .ant-tooltip-inner > span': {
        color: '#FFFFFF !important',
      },
  },
});

const CustomTableTitle = (
  props: React.HTMLAttributes<any> & WithStylesProps<typeof styles> & {
      onResize: (e: React.SyntheticEvent<Element>, data: ResizeCallbackData) => void;
      onResizeStop: (width: number) => void;
      width: number | undefined;
      tableHeight: number;
      resizable: boolean;
      children: React.ReactNode,
      style: React.CSSProperties | undefined,
}) => {
  const {
    onResize,
    onResizeStop,
    resizable,
    classes,
    children,
    width,
    tableHeight,
    style,
    ...restProps
  } = props;

  const childRef = React.useRef(null);

  const cellContent = <div ref={childRef} style={{
    width: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }}>{children}</div>;

  const isTitleResizable = !(!width || !resizable);

  const handleDragStop = (e, data) => {
    e.preventDefault();
    e.stopPropagation();
    const draggingSpan = document.getElementById('__dragging_span_for_width_preview__');
    if (draggingSpan) {
      draggingSpan.remove();
    }
    const newWidth =  width + data.lastX + ((data.lastX) > 0 ? 5 : -5);
    setTimeout(() => {
      onResizeStop(newWidth);
    }, 0);
  };

  const handleDrag = (e, data) => {
    e.preventDefault();
    e.stopPropagation();
    const x = e.clientX;
    const y = e.clientY;
    if (childRef?.current) {
      const draggingSpan = childRef.current.parentElement.querySelector(
        '.columm-resize-handler-dragging',
      );

      if (draggingSpan) {
        let spanElement = document.getElementById('__dragging_span_for_width_preview__');
        const tableElement = draggingSpan.closest('.ant-table-wrapper');
        const tableActualHeight = tableElement?.getBoundingClientRect().height ?? tableHeight;
        if (!spanElement) {
          spanElement = document.createElement('span');
          spanElement.style.left = `${x}px`;
          spanElement.style.top = `${draggingSpan.getBoundingClientRect().top ?? y}px`;
          spanElement.style.position = 'fixed';
          spanElement.style.width = '1px';
          spanElement.style.height = `${tableActualHeight}px`;
          spanElement.style.backgroundColor = '#333333';
          spanElement.id = '__dragging_span_for_width_preview__';
          document.body.appendChild(spanElement);
        } else {
          spanElement.style.left = `${x}px`;
        }
      }
    }
  };

  const renderResizeHandler = () => {
    return <Draggable
      defaultClassName="columm-resize-handler"
      defaultClassNameDragging="columm-resize-handler-dragging"
      axis="x"
      onDrag={handleDrag}
      onStop={handleDragStop}>
      <span onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}/>
    </Draggable>;
  };

  return (
    <th style={{
      ...(style || {}),
      width,
    }} {...restProps}>
      <Tooltip title={children}>{cellContent}</Tooltip>

      {
        isTitleResizable ? renderResizeHandler() : <></>
      }
    </th>
  );
};

export default React.memo(withStyles(styles, {
  injectTheme: true,
})(CustomTableTitle));
