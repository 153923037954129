import * as React from 'react';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '../../theming/jssTypes';
import classNames from 'classnames';

const styles = (theme: ThemeType): CssType => ({
  mainContainer: {
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    '&.ant-input-group-addon': {
      backgroundColor: theme.colors.surfaceBg,
      color: theme.colors.textOnLightBg,
      width: 'auto',
      height: '28px',
      border: `1px solid ${theme.colors.borderColor}`,
      borderRadius: '0px !important',
    },
  },
});

const SideLabel = (props) => {
  const { classes, className, style, text } = props;
  return (
    <span
      className={classNames([
        classes.mainContainer,
        'ant-input-group-addon',
        className,
      ])}
      style={style}
    >
      {text}
    </span>
  );
};
export default withStyles(styles, { injectTheme: true })(SideLabel);
