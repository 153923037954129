export const columnsToShow = [
  {
    column_id: 'reference_number',
    pretty_name: 'Consignment Number',
  },
  {
    pretty_name: 'Hub Arrival Time',
    column_id: 'hub_arrival_time',
  },
  {
    pretty_name: 'Status',
    column_id: 'status',
  },
  {
    pretty_name: 'Trip Assigned',
    column_id: 'trip_assigned',
  },
  {
    pretty_name: 'Destination Pincode',
    column_id: 'destination_pincode',
  },
  {
    pretty_name: 'Customer Name',
    column_id: 'customer_name',
  },
  {
    pretty_name: 'Destination Address',
    column_id: 'receiver_address',
  },
  {
    pretty_name: 'Last Updated',
    column_id: 'updated_at',
  },
  {
    pretty_name: 'Number of Pieces',
    column_id: 'no_of_pieces',
  },
  {
    pretty_name: 'Consignment Type',
    column_id: 'consignment_type',
  },
  {
    pretty_name: 'Weight(in Kgs)',
    column_id: 'weight',
  },
];

export const pieceDetailColumns = [
  // {
  //   pretty_name: 'Piece#',
  //   column_id: 'piece',
  // },
  {
    pretty_name: 'Detail',
    column_id: 'description',
  },
];

export const customTripColumns = [
  {
    pretty_name: 'S.N.',
    column_id: 'serial_number',
  },
  {
    pretty_name: 'Consignment',
    column_id: 'reference_number',
  },
  {
    pretty_name: 'Status',
    column_id: 'status',
  },
  {
    pretty_name: 'Actions',
    column_id: 'actions',
  },
];

export const rtoReasons = [
  'Physical damage',
  'Missing parts',
  'Incorrect product',
  'Does not match description',
  'Other',
];
export const customTripConsolidatedColumns = [
  {
    pretty_name: 'SN.',
    column_id: 'serial_number',
  },
  {
    pretty_name: 'Task Type',
    column_id: 'task_type',
  },
  {
    pretty_name: 'Task No.',
    column_id: 'reference_number',
  },
  {
    pretty_name: 'No. of CN',
    column_id: 'consignment_count',
  },
  {
    pretty_name: 'Actions',
    column_id: 'actions',
  },
];
export const columnsToShowInConsolidatedPlanning = [
  {
    pretty_name: 'Task No.',
    column_id:  'reference_number',
  },
  {
    pretty_name: 'Task Type',
    column_id: 'task_type',
  },
  {
    pretty_name: 'Creation Time',
    column_id: 'created_at',
  },
  {
    pretty_name: 'Status',
    column_id: 'status',
  },
  {
    pretty_name: 'Consignment Type',
    column_id: 'consignment_type',
  },
  {
    pretty_name: 'Number of CNs',
    column_id: 'consignment_count',
  },
  {
    pretty_name: 'Number of Pieces',
    column_id: 'num_pieces',
  },
  {
    pretty_name: 'Trip Assigned',
    column_id: 'trip_organisation_reference_number',
  },
  {
    pretty_name: 'Driver',
    column_id: 'worker_code',
  },
  {
    pretty_name: 'Vehicle',
    column_id: 'vehicle_number',
  },
  {
    pretty_name: 'Weight (in Kgs)',
    column_id: 'weight',
  },
  {
    pretty_name: 'Volume (in m3)',
    column_id: 'volume',
  },
  {
    pretty_name: 'Time Slot',
    column_id: 'delivery_time_slot',
  },
  {
    pretty_name: 'Zone',
    column_id: 'location_zone',
  },
  {
    pretty_name: 'Origin Address',
    column_id: 'sender_address',
  },
  {
    pretty_name: 'Destination Address',
    column_id: 'destination_address',
  },
  {
    pretty_name: 'Origin Pincode',
    column_id: 'sender_pincode',
  },
  {
    pretty_name: 'Destination Pincode',
    column_id: 'destination_pincode',
  },
  {
    pretty_name: 'Consignor Name',
    column_id: 'sender_name',
  },
  {
    pretty_name: 'Consignee Name',
    column_id: 'destination_name',
  },
  {
    pretty_name: 'Movement Type',
    column_id: 'movement_type',
  },
  {
    pretty_name: 'Service Time',
    column_id: 'service_time_mins',
  },
];

export const zoneViewColumns = [
  {
    pretty_name: 'Zone',
    column_id: 'zone_code',
  },
  {
    pretty_name: 'Total Tasks',
    column_id: 'task_count',
  },
  {
    pretty_name: 'Riders',
    column_id: 'rider_count',
  },
];

export const jobDetailColumns = [
  {
    column_id: 'task_reference_number',
    pretty_name: 'Task No.',
    type: 'string',
  },
  {
    column_id: 'task_type',
    pretty_name: 'Job Type',
    type: 'string',
  },
  {
    column_id: 'task_status',
    pretty_name: 'Status',
    type: 'string',
  },
];
