import * as React from 'react';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '../../../../theming/jssTypes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MultiSelectFilter from '../../../common/MultiselectFilter';
import { applyBucketFilters } from '../../../../actions/genericConsignmentActions';
import { sanitiseObject, searchStrategy } from './utils';
import SearchTagsInput from '../../../common/SearchSelectTagsInput';
import { useTranslation } from 'react-i18next';
const styles = (theme: ThemeType): CssType => ({
  multiSelectFilter: {
    marginLeft: '0px',
    '& .ant-form label': {
      fontSize: '13px',
    },
    '& .optionsWrapper': {
      maxHeight: '200px',
    },
  },
});

const MultiSelectSearchFilter = (props: any) => {
  const { t } = useTranslation();
  const {
    classes,
    filter,
    selectedFilters,
    applyBucketFilters,
    hubId,
    filterLoc,
    onSearchChange,
    useMultiSelect,
    isSingleSelect,
    globalAppliedFilters,
    bucket
  } = props;
  const [value, setValue] = React.useState([]);
  const [valueObj, setValueObj] = React.useState<any>([]);
  const [searchOptions, setSearchOptions] = React.useState<any>([]);
  const [allOptions, setAllOptions] = React.useState<any>([]);

  React.useEffect(() => {
    const options = selectedFilters[filter.id];
    const optionList =
      options !== undefined && options !== null
        ? isSingleSelect
          ? [options]
          : options
        : [];
    const filterValue =
      filter.type === 'search' ? optionList.map((ele) => ele.key) : optionList;
    setValue(filterValue);
    if (filter.type === 'search') {
      const allOptionList = optionList.filter((ele) => {
        return !allOptions.find((item) => item.key === ele.key);
      });
      setSearchOptions(optionList);
      setAllOptions(allOptions.concat(allOptionList));
      setValueObj(optionList);
    }
  }, [selectedFilters]);

  const onFilterValueChange = (filterVals) => {
    setValue(filterVals);
  };

  const handleApplyMultiSelect = (filterVals, isChanged, filterId) => {
    if (!isChanged && filter.type !== 'search') {
      return;
    }
    const filterObj = filterVals.map((item) => {
      return allOptions.filter((ele) => ele.key === item)?.[0] || {};
    });
    const filterValues = filter.type === 'search' ? filterObj : filterVals;
    const bucketFilters = {
      ...selectedFilters,
      [filterId]: isSingleSelect ? filterValues[0] : filterValues,
    };
    applyBucketFilters(sanitiseObject(bucketFilters));
  };

  const generateMultiSelectOptions = (list) => {
    const optionsList: any = [];
    if (list) {
      list.forEach((element) => {
        optionsList.push({
          key: element.value,
          value: element.value,
          label: t(element.label),
        });
      });
    }
    return optionsList;
  };

  const handleSearch = (strategy) => async (value) => {
    const queryString: string = value.trim();
    const { startDate: fromDateString, endDate: toDateString } =
      globalAppliedFilters;
    if (!queryString) {
      setSearchOptions([]);
      return [];
    }
    if (queryString.length >= (filter.threshold_length || 3)) {
      const options = await searchStrategy(strategy)(
        queryString,
        hubId,
        fromDateString,
        toDateString,
      );
      const allOptionList = options.filter((ele) => {
        return !allOptions.find((item) => item.key === ele.key);
      });
      setSearchOptions(options);
      setAllOptions(allOptions.concat(allOptionList));
      return options;
    }
    setSearchOptions([]);
    return [];
  };

  const handleSearchChange = (values) => {
    values ? setValueObj(values) : setValueObj(undefined);
    if (filterLoc === 'outside') {
      const bucketFilters = {
        ...selectedFilters,
        [filter.id]: values,
      };
      applyBucketFilters(sanitiseObject(bucketFilters));
    } else {
      onSearchChange(filter.id, values);
    }
  };

  return useMultiSelect ? (
    <MultiSelectFilter
      value={value}
      onFilterChange={onFilterValueChange}
      className={classes.multiSelectFilter}
      title={filter.label}
      buttonType="text"
      showCustomSelect
      isSingleSelect={isSingleSelect}
      allOptions={allOptions}
      filterType={filter.type}
      bucket={bucket}
      onDropdownClose={(filterVals, isChanged) =>
        handleApplyMultiSelect(filterVals, isChanged, filter.id)
      }
      onBackendSearch={
        filter.type === 'select'
          ? undefined
          : (searchValue) => handleSearch(filter.search_strategy)(searchValue)
      }
      options={
        filter.type === 'search'
          ? searchOptions
          : generateMultiSelectOptions(filter.options)
      }
    />
  ) : (
    <SearchTagsInput
      labelInValue
      placeholder={filter.placeholder || ''}
      size={'default'}
      mode={filter.mode === 'multiple' ? 'multiple' : 'single'}
      allowClear
      thresholdLength={filter.threshold_length}
      value={valueObj || undefined}
      options={searchOptions}
      onChange={handleSearchChange}
      searchFunction={(searchValue) =>
        handleSearch(filter.search_strategy)(searchValue)
      }
      className={classes.selectStyle}
    />
  );
};

const MultiSelectSearchFilterStyled = withStyles(styles, { injectTheme: true })(
  MultiSelectSearchFilter,
);

const mapStateToProps = ({ genericConsignmentReducer }, ownProps) => {
  const { viewType, bucket } = ownProps;
  const appliedFilters =
    genericConsignmentReducer[viewType].buckets[bucket].appliedFilters;
  return {
    selectedFilters: appliedFilters,
    globalAppliedFilters:
      genericConsignmentReducer[viewType].globalAppliedFilters,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { viewType, bucket } = ownProps;
  return bindActionCreators(
    {
      applyBucketFilters: applyBucketFilters(viewType)(bucket),
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MultiSelectSearchFilterStyled);
