import React from 'react';
import { ViewFilterType } from 'src/types/view.types';
import CustomerSelectFilter from './components/SelectFilter';
import { CUSTOMER_REQUEST_KEYS } from './types';

interface ParamType {
    pageId: string;
    viewIndex: number;
    sectionIndex: number;
    handleFilterChange?: (value: any) => void;
}

const filters = {
    is_active_customer: (filterData: ViewFilterType, params: ParamType) => {
        return <CustomerSelectFilter
            booleanFilter={true}
            filterData={filterData}
            options={[
                {
                    key: 'all',
                    label: 'All',
                    booleanValue: null,
                }, {
                    key: 'active',
                    label: 'Active',
                    booleanValue: true,
                }, {
                    key: 'inactive',
                    label: 'Inactive',
                    booleanValue: false,
                },
            ]}
            pageId={params.pageId}
            viewIndex={params.viewIndex}
            sectionIndex={params.sectionIndex}
            handleFilterChange={params.handleFilterChange}
        />;
    },
};

export default filters;
