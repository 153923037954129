import { fetchChartsDataRetail } from '../../../../api/genericAnalyticsView';

const viewControl = {
  buckets : [
    'live',
    'history',
  ],
  charts : [
    'retail_order_summary',
    'retail_trip_summary',
    'retail_exceptions',
    'retail_trip_progress',
    'retail_average_vehicle_volume_utilisation',
    'retail_average_completion_per_vehicle',
    'retail_city_wise_success_overall',
    'retail_city_wise_average_completion_per_vehicle',
    'retail_trip_day_profile',
    'retail_city_wise_trip_start_time',
  ],
  fetchChartData : fetchChartsDataRetail,
};
const buckets = {
  live : {
    filters : [
      // 'date_type',
      'hub_type',
    ],
    charts : [
      'retail_order_summary',
      'retail_trip_summary',
      'retail_exceptions',
      'retail_trip_progress',
    ],
  },
  history : {
    filters : [
      'date_type',
      'hub_type',
    ],
    charts : [
      'retail_average_vehicle_volume_utilisation',
      'retail_average_completion_per_vehicle',
      'retail_city_wise_success_overall',
      'retail_city_wise_average_completion_per_vehicle',
      'retail_trip_day_profile',
      'retail_city_wise_trip_start_time',
    ],
  },
};
const charts = {
  retail_order_summary : {},
  retail_trip_summary : {},
  retail_exceptions : {},
  retail_trip_progress : {},
  retail_average_vehicle_volume_utilisation : {},
  retail_average_completion_per_vehicle : {},
  retail_city_wise_success_overall : {},
  retail_city_wise_average_completion_per_vehicle : {},
  retail_trip_day_profile : {},
  retail_city_wise_trip_start_time : {},
};

export default { viewControl, buckets, charts };
