import {
    getSupplyRequestTableData,
    getSupplyRequestDetails,
} from '@api/supplyRequest';

const viewControl = {
  pageTitle: 'Supply Request',
  buckets: [
    'supply_request_all',
    'supply_request_delivered',
    'supply_request_partially_delivered',
    'supply_request_undelivered',
  ],
  navBar: {
    left: ['date_filter'],
    right: ['search_redirect'],
  },
  globalActions: [],
  dateFilterOptions: [
    { key: 'created_at', val: 'Creation Time' },
  ],
  searchFilterOptions: [{ key: 'supplyRequestId', val: 'Supply Request Id' }],
  detailsRoute: getSupplyRequestDetails,
  fetchData: getSupplyRequestTableData,
};

const buckets = {
    supply_request_all: {
        hidePagination: false,
        hidePerPageCount: false,
        showStatusList: true,
        name: 'All',
        filters: [],
        actions: [],
        isSubBucket: false,
    },
    supply_request_delivered: {
        hidePagination: false,
        hidePerPageCount: false,
        name: 'Delivered',
        filters: [],
        actions: [],
        isSubBucket: false,
    },
    supply_request_partially_delivered: {
        hidePagination: false,
        hidePerPageCount: false,
        name: 'Partially Delivered',
        filters: [],
        actions: [],
        isSubBucket: false,
    },
    supply_request_undelivered: {
        hidePagination: false,
        hidePerPageCount: false,
        name: 'Undelivered',
        filters: [],
        actions: [],
        isSubBucket: false,
    },
};

const bucketColor = {
    supply_request_all: '#722ED1',
    supply_request_delivered: '#EB2F96',
    supply_request_partially_delivered: '#2F54EB',
    supply_request_undelivered: '#FA541C',
};

export default { viewControl, buckets, bucketColor };
