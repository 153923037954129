import * as React from 'react';
import withStyles from 'react-jss';
import { CssType,
  ThemeType} from '../../../theming/jssTypes';
import { Menu, Modal } from 'antd';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getFoodDashboardDispatchScreen,
  getFoodDashboardOrderManagement,
  getFoodDashboardReporting,
  getFoodDashboardAnalytics,
  getFoodDashboardStoreConfiguration,
  getFoodDashboardNotifications,
  getFoodDashboardCODCashSettlement,
  getIntegrationMarketplaceRoute,
  getRoutingPlaygroundRoute,
  getBankDepositRoute,
  getBankTransactionsHistoryRoute,
  getTerritoryOptimizationRoute,
} from '../../../routing/utils';
import { NAVBAR_HEIGHT } from '../../../globals';
import { logoutUserAndClearCache } from '../../../api/utils';
import SwitchViewType from '../../common/SwitchViewType';

const StoreConfigurationIcon =
  require('../../../assets/images/FoodDashboard/Store_Configuration_Icon.svg');
import { API_PLAYGROUND_BASE_PATH } from '../../common/persistentIframeRenderer/constants';
import { ApiPlaygroundNavIcon } from '../../common/icons/opsDashboard/ApiPlaygroundIcon';

import { AnalyticsIcon } from '@components/common/icons/opsDashboard/AnalyticsIcon';
import { BusinessIntelligenceIcon } from '@components/common/icons/opsDashboard/BusinessIntelligenceIcon';
import { AnalyseIcon } from '@components/common/icons/opsDashboard/AnalyseIcon';
import { LogoutIcon } from '@components/common/icons/opsDashboard/LogoutIcon';
import { CODSettlementIcon } from '@components/common/icons/opsDashboard/CODSettlementIcon';
import { DispatchManagementIcon } from '@components/common/icons/opsDashboard/DispatchManagementIcon';
import { HelpdeskNotificationIcon } from 'src/components/common/icons/opsDashboard/HelpdeskNotificationIcon';
import { IcLaunchIcon } from '@components/common/icons/opsDashboard/IcLaunchIcon';
import { OrderManagementIcon } from '@components/common/icons/opsDashboard/OrderManagementIcon';
import { ReportingIcon } from '@components/common/icons/opsDashboard/ReportingIcon';
import { SetupIcon } from '@components/common/icons/opsDashboard/SetupIcon';
import { IntegrationMarketplaceIcon } from '@components/common/icons/opsDashboard/IntegrationMarketplaceIcon';
import { RoutingPlayGroundNavIcon } from '@components/common/icons/RoutingPlayGroundIcon';
import { TerritoryOptimisationNavIcon } from '@components/common/icons/TerritoryOptimisation';

const styles = (theme: ThemeType): CssType => ({
  logoContainer: {
    height: '60px !important',
    '&:hover': {
      cursor: 'unset',
    },
  },
  menuModal: {
    top: NAVBAR_HEIGHT - 10,
    '& .ant-modal-body, .ant-modal-content' : {
      backgroundColor: theme.colors.globalHeader,
      boxShadow: 'none',
      padding: 0,
    },
  },
  menuBox: {
    background: theme.colors.globalHeader,
    width: 300,
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
    padding: 10,
    borderRadius: 4,
    '& .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left': {
      border: 'none',
    },
  },
  menuItem: {
    display: 'flex',
    '&:hover span svg path': {
      fill: theme.colors.textOnDarkBg,
    },
    '&:hover': {
      background: theme.colors.HoverOnDarkBg,
      borderRight: `2px solid ${theme.colors.globalHeader}`,
    },
  },
  menuItemInner: {
    display: 'flex',
    alignItems:'center',
  },
  menuTcon : {
    marginRight: 10,
  },
  logoutIcon: {
    color: theme.colors.warning,
    fontSize: '24px !important',
  },
  toolContainer: {
    marginLeft: '10px',
  },
  oldTag: {
    display: 'flex',
    fontSize: '10px',
    fontWeight: 600,
    backgroundColor: theme.colors.globalHeader,
    borderRadius: '5px',
    padding: '10px 15px',
    height: '10px',
    alignItems: 'center',
    marginLeft: '0.5rem',
  },
  newTag: {
    display: 'flex',
    fontSize: '10px',
    fontWeight: 600,
    backgroundColor: theme.colors.globalHeader,
    color: theme.colors.primaryText,
    borderRadius: '5px',
    padding: '10px 15px',
    height: '10px',
    alignItems: 'center',
    marginLeft: '0.5rem',
  },
});

class MenuModal extends React.PureComponent<any, any> {

  state = {
    menuModalVisible: false,
  }
;
  handleBackClick = () => {
    const { history } = this.props;
    history.goBack();
  };

  render() {
    const {
      classes,
      isVisible,
      showHelpdeskNotifications,
      showWorkerCreditModule,
      showBankDepositOnDemand,
      showCodDumpOverviewAnalytics,
      ondemandStoreConfig,
      showStoreConfig,
      allowUpdateStoreConfig,
      showViewTypeSwitch,
      showQueryBuilder,
      showRoutingPlayground,
      showTerritoryOptimisation,
    } = this.props;
    return (
      <Modal
        width="100%"
        className={classes.menuModal}
        style={{ left: 20 }}
        closable={false}
        visible={isVisible}
        footer={null}
      >
        <div onClick={() => this.props.toggleVisible(false)}>
          <div className={classes.menuBox}>
            <div style={{ margin: '0 10px' }}>
              <div>
                <img style={{ maxHeight: '35px', display:'block', margin: 'auto' }}
                src={this.props.navLogoURL} />
              </div>
              <div style={{ textAlign: 'center', margin: 10 }}>
                Store : {this.props.currStore.name}
              </div>
            </div>
            <Menu
              mode="inline"
              selectable={false}
              theme="light">
              <hr></hr>
              <Menu.Item key="analytics" className={classes.menuItem}>
                <Link to={getFoodDashboardAnalytics()}>
                  <div className={classes.menuItemInner}>
                    <AnalyticsIcon style={{fontSize: '24px'}}/>
                    <span>Analytics</span>
                  </div>
                </Link>
              </Menu.Item>
              <Menu.Item key="orderManagament" className={classes.menuItem}>
                <Link to={getFoodDashboardOrderManagement()}>
                  <div className={classes.menuItemInner}>
                    <OrderManagementIcon style={{fontSize: '24px'}}/>
                    <span>Order Management</span>
                  </div>
                </Link>
              </Menu.Item>
              { showQueryBuilder ?
                <Menu.Item key="queryBuilder" className={classes.menuItem}>
                  <a href={'/ops/analytics'}
                  target="_blank"
                  >
                    <div className={classes.menuItemInner}>
                      <BusinessIntelligenceIcon style={{fontSize: '24px'}}/>
                      <span>Business Intelligence</span>
                      <IcLaunchIcon style={{ fontSize: '10px' }}/>
                      <div className={classes.newTag}>
                          NEW
                      </div>
                    </div>
                  </a>
                </Menu.Item> : null }
              {(showCodDumpOverviewAnalytics && showCodDumpOverviewAnalytics.length) ?
                <Menu.Item key="heatMap" className={classes.menuItem}>
                  <a href={'/analytics'}
                  target="_blank"
                  >
                    <div className={classes.menuItemInner}>
                      <AnalyseIcon style={{fontSize: '24px'}}/>
                      <span>Analytics</span>
                      <IcLaunchIcon style={{ fontSize: '10px' }}/>
                      <div className={classes.oldTag}>
                          OLD
                      </div>
                    </div>
                  </a>
                </Menu.Item> : null
              }
              <Menu.Item key="reporting" className={classes.menuItem}>
                <Link to={getFoodDashboardReporting()}>
                  <div className={classes.menuItemInner}>
                    <ReportingIcon style={{fontSize: '24px'}}/>
                    <span>Reporting</span>
                  </div>
                </Link>
              </Menu.Item>
              <Menu.Item key="integrationMarketplace" className={classes.menuItem}>
                <Link to={getIntegrationMarketplaceRoute()}>
                  <div className={classes.menuItemInner}>
                    <IntegrationMarketplaceIcon style={{fontSize: '24px'}}/>
                    <span>Integration Marketplace</span>
                  </div>
                </Link>
              </Menu.Item>
              <Menu.Item key="apidocs" className={classes.menuItem}>
                <Link to={API_PLAYGROUND_BASE_PATH}>
                  <div className={classes.menuItemInner}>
                    <ApiPlaygroundNavIcon style={{fontSize: '24px'}}/>
                    <span>API Playground</span>
                  </div>
                </Link>
              </Menu.Item>
              {
                showHelpdeskNotifications ?
                <Menu.Item key="notification" className={classes.menuItem}>
                  <Link to={getFoodDashboardNotifications()}>
                    <div className={classes.menuItemInner}>
                      <HelpdeskNotificationIcon style={{fontSize: '24px'}}/>
                      <span>Helpdesk Notifications</span>
                    </div>
                  </Link>
                </Menu.Item> : null
              }
              {ondemandStoreConfig?.length && (showStoreConfig || allowUpdateStoreConfig) ?
                <Menu.Item key="storeConfiguration" className={classes.menuItem}>
                  <Link to={getFoodDashboardStoreConfiguration}>
                    <div className={classes.menuItemInner}>
                      <img className={classes.menuTcon} src={StoreConfigurationIcon}/>
                      <span>Store Configuration</span>
                    </div>
                  </Link>
                </Menu.Item>
              : null }
              {showWorkerCreditModule ?
                <Menu.Item key="codSettlement" className={classes.menuItem}>
                  <Link to={getFoodDashboardCODCashSettlement()}>
                    <div className={classes.menuItemInner}>
                      <CODSettlementIcon style={{fontSize: '24px'}}/>
                      <span>COD Settlement</span>
                    </div>
                  </Link>
                </Menu.Item> : null }
              {showBankDepositOnDemand ?
                <Menu.Item key="bankDeposit" className={classes.menuItem}>
                  <Link to={getBankDepositRoute()}>
                    <div className={classes.menuItemInner}>
                      <CODSettlementIcon style={{fontSize: '24px'}}/>
                      <span>Bank Deposit</span>
                    </div>
                  </Link>
                </Menu.Item> : null }
                {showBankDepositOnDemand ?
                <Menu.Item key="bankTransaction" className={classes.menuItem}>
                  <Link to={getBankTransactionsHistoryRoute()}>
                    <div className={classes.menuItemInner}>
                      <CODSettlementIcon style={{fontSize: '24px'}}/>
                      <span>Bank Transactions History</span>
                    </div>
                  </Link>
                </Menu.Item> : null }
              {showRoutingPlayground ? <Menu.Item key="routingPlayground" className={classes.menuItem}>
                <Link to={getRoutingPlaygroundRoute()}>
                  <div className={classes.menuItemInner}>
                    <RoutingPlayGroundNavIcon style={{fontSize: '22px'}}/>
                    <span>
                      Routing Playground
                    </span>
                  </div>
                </Link>
              </Menu.Item>: null}
              {showTerritoryOptimisation ? <Menu.Item key="territoryOptimisation" className={classes.menuItem}>
                <Link to={getTerritoryOptimizationRoute()}>
                  <div className={classes.menuItemInner}>
                    <TerritoryOptimisationNavIcon style={{fontSize: '22px'}}/>
                    <span>
                      Territory Optimisation
                    </span>
                  </div>
                </Link>
              </Menu.Item>: null}
              {
                showViewTypeSwitch ?
                  <>
                    <hr />
                    <Menu.Item key="switchViewType" className={classes.menuItem}>
                      <SwitchViewType />
                    </Menu.Item>
                  </> : null
              }
              <Menu.Item key="setup" className={classes.menuItem}>
                <Link to={'/dashboard/setup'}>
                  <div className={classes.menuItemInner}>
                    <SetupIcon style={{fontSize: '24px'}}/>
                    <span>
                      Setup
                    </span>
                  </div>
                </Link>
              </Menu.Item>
              <hr></hr>
              <Menu.Item key="dispatchManagament" className={classes.menuItem}>
                <Link to={getFoodDashboardDispatchScreen()} target="_blank">
                  <div className={classes.menuItemInner}>
                    <DispatchManagementIcon style={{fontSize: '24px'}}/>
                    <span>
                      Dispatch Management
                    </span>
                    <IcLaunchIcon style={{ fontSize: '10px' }}/>
                  </div>
                </Link>
              </Menu.Item>
              <Menu.Item key="logout"
                className={classes.menuItem}
                onClick = {() => {
                  logoutUserAndClearCache();
                }}
              >
                <div className={classes.menuItemInner}>
                  <LogoutIcon style={{fontSize: '24px'}}/>
                  <span>Logout</span>
                </div>
              </Menu.Item>
            </Menu>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ masterData , cachedData }) => {
  return {
    navLogoURL: masterData.logoUrl,
    currStore: cachedData.currHub,
    showOpsV1Hubs: masterData.setup_to_show_in_opsv2 ?
      masterData.setup_to_show_in_opsv2.hubs || false : false,
    showOpsV1Employees: masterData.setup_to_show_in_opsv2 ?
      masterData.setup_to_show_in_opsv2.employees || false : false,
    showBankDepositOnDemand: masterData?.bank_deposit_read || false,
    showOpsV1CarrierPartner: masterData.setup_to_show_in_opsv2 ?
      masterData.setup_to_show_in_opsv2.carrier_partner : false,
    showConfigurations: masterData.show_allocation_setup,
    showRosterManagement: masterData.show_roster_management_ondemand,
    ondemandStoreConfig: masterData.ondemand_store_configuration,
    showStoreConfig: masterData.show_store_config,
    allowUpdateStoreConfig: masterData.allow_update_store_config,
    showHelpdeskNotifications: masterData.ops_dashboard_config.parts_to_show.show_ondemand_helpdesk,
    showWorkerCreditModule : masterData.show_worker_credit_module || false,
    showQueryBuilder: masterData?.show_query_builder_crm || false,
    showCodDumpOverviewAnalytics: masterData.ops_dashboard_config.parts_to_show.
                                  show_cod_dump_overview_analytics,
    showViewTypeSwitch: masterData.show_view_type_switch,
    showRiderPayout: masterData.rider_payout_read || false,
    showRoutingPlayground: masterData.ops_dashboard_config
      ?.parts_to_show
      ?.show_routing_playground_parts,
    showTerritoryOptimisation: masterData.ops_dashboard_config
      ?.parts_to_show
      ?.show_territory_optimisation_parts,
  };
};

const MenuModalStyled = withStyles(styles, { injectTheme: true })(MenuModal);
export default withRouter(
  connect(mapStateToProps, null)(MenuModalStyled)) as  React.ComponentType<any>;
