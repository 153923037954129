import Icon from '@ant-design/icons';
import * as React from 'react';
const SlotManagementSvg = (props: any) => (
  // tslint:disable-next-line: max-line-length
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <g clipPath="url(#clip0_3139_1971)">
  <path d="M12.9846 1.90749H10.2154V1.35365C10.2154 1.04903 9.96618 0.799805 9.66156 0.799805C9.35695 0.799805 9.10772 1.04903 9.10772 1.35365V1.90749H5.78465V1.35365C5.78465 1.04903 5.53542 0.799805 5.23081 0.799805C4.92619 0.799805 4.67696 1.04903 4.67696 1.35365V1.90749H1.90774C1.29851 1.90749 0.800049 2.40595 0.800049 3.01518V12.9844C0.800049 13.5936 1.29851 14.0921 1.90774 14.0921H7.44618C7.7508 14.0921 8.00003 13.8428 8.00003 13.5382C8.00003 13.2336 7.7508 12.9844 7.44618 12.9844H1.90774V3.01518H4.67696V3.56903C4.67696 3.87364 4.92619 4.12287 5.23081 4.12287C5.53542 4.12287 5.78465 3.87364 5.78465 3.56903V3.01518H9.10772V3.56903C9.10772 3.87364 9.35695 4.12287 9.66156 4.12287C9.96618 4.12287 10.2154 3.87364 10.2154 3.56903V3.01518H12.9846V6.8921C12.9846 7.19671 13.2339 7.44594 13.5385 7.44594C13.8431 7.44594 14.0923 7.19671 14.0923 6.8921V3.01518C14.0923 2.40595 13.5939 1.90749 12.9846 1.90749Z" fill="#EED3AB"/>
  <path d="M11.3232 5.23047H10.2155C9.90959 5.23047 9.66162 5.47843 9.66162 5.78431V6.892C9.66162 7.19788 9.90959 7.44585 10.2155 7.44585H11.3232C11.629 7.44585 11.877 7.19788 11.877 6.892V5.78431C11.877 5.47843 11.629 5.23047 11.3232 5.23047Z" fill="#EED3AB"/>
  <path d="M4.67704 5.23047H3.56935C3.26347 5.23047 3.0155 5.47843 3.0155 5.78431V6.892C3.0155 7.19788 3.26347 7.44585 3.56935 7.44585H4.67704C4.98292 7.44585 5.23088 7.19788 5.23088 6.892V5.78431C5.23088 5.47843 4.98292 5.23047 4.67704 5.23047Z" fill="#EED3AB"/>
  <path d="M4.67704 8.55371H3.56935C3.26347 8.55371 3.0155 8.80168 3.0155 9.10756V10.2152C3.0155 10.5211 3.26347 10.7691 3.56935 10.7691H4.67704C4.98292 10.7691 5.23088 10.5211 5.23088 10.2152V9.10756C5.23088 8.80168 4.98292 8.55371 4.67704 8.55371Z" fill="#EED3AB"/>
  <path d="M7.99991 5.23047H6.89222C6.58634 5.23047 6.33838 5.47843 6.33838 5.78431V6.892C6.33838 7.19788 6.58634 7.44585 6.89222 7.44585H7.99991C8.30579 7.44585 8.55376 7.19788 8.55376 6.892V5.78431C8.55376 5.47843 8.30579 5.23047 7.99991 5.23047Z" fill="#EED3AB"/>
  <path d="M7.99991 8.55371H6.89222C6.58634 8.55371 6.33838 8.80168 6.33838 9.10756V10.2152C6.33838 10.5211 6.58634 10.7691 6.89222 10.7691H7.99991C8.30579 10.7691 8.55376 10.5211 8.55376 10.2152V9.10756C8.55376 8.80168 8.30579 8.55371 7.99991 8.55371Z" fill="#EED3AB"/>
  <path d="M12.4309 15.1998C10.5976 15.1998 9.10779 13.71 9.10779 11.8768C9.10779 10.0436 10.5976 8.55371 12.4309 8.55371C14.2641 8.55371 15.7539 10.0436 15.7539 11.8768C15.7539 13.71 14.2641 15.1998 12.4309 15.1998ZM12.4309 9.6614C11.2069 9.6614 10.2155 10.6528 10.2155 11.8768C10.2155 13.1008 11.2069 14.0922 12.4309 14.0922C13.6549 14.0922 14.6462 13.1008 14.6462 11.8768C14.6462 10.6528 13.6549 9.6614 12.4309 9.6614Z" fill="#EED3AB"/>
  <path d="M12.3422 12.4302H13.5385C13.8431 12.4302 14.0923 12.181 14.0923 11.8764C14.0923 11.5718 13.8431 11.3225 13.5385 11.3225H12.9846V10.7687C12.9846 10.4641 12.7354 10.2148 12.4308 10.2148C12.1262 10.2148 11.877 10.4641 11.877 10.7687V11.965C11.877 12.2198 12.0819 12.4302 12.3422 12.4302Z" fill="#EED3AB"/>
  </g>
  <defs>
  <clipPath id="clip0_3139_1971">
  <rect width="16" height="16" fill="white"/>
  </clipPath>
  </defs>
  </svg>
);
export const SlotManagementIcon = (props) => (
  <Icon component={SlotManagementSvg} {...props} />
);
