import * as React from 'react';
import { Checkbox, Row, Col, Button, Input, Divider, Tooltip } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '../../theming/jssTypes';
import Downshift from 'downshift';
import classNames from 'classnames';
import * as _ from 'underscore';
import { trackGaEvents } from 'src/utils/googleAnalyticsHelper';
import { MULTI_FILTER, gaType } from 'src/utils/gaConstants';
import { withTranslation } from 'react-i18next';
import { MoreColors } from 'src/theming/moreColors';

const Search: any = Input.Search;
const styles = (theme: ThemeType): CssType => ({
  divider: {
    margin: '0 !important',
  },
  menuContainer: {
    border: `1px solid ${MoreColors.WhitishGrey} !important`,
    borderRadius: '4px !important',
  },
  menuItemLayoutSelected: {
    backgroundColor: 'rgba(6,84,146,0.1) !important',
  },
  menuItemLayout: {
    backgroundColor: theme.colors.surfaceBg,
    padding: '8px 16px !important',
    margin: '0px !important',
    textAlign: 'left',
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
    '& .ant-checkbox': {
      marginRight: '4px',
      top: 0,
    },
    '& .ant-checkbox+span': {
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  menuItemButtonsLayout: {
    backgroundColor: theme.colors.surfaceBg,
    '&:hover': {
      boxShadow: 'none !important',
    },
  },
  dropdownButton: {
    border: 'none !important',
    color: `${theme.colors.textOnLightBg} !important`,
    fontSize: `${theme.sizes.bodyText} !important`, //used to be 9.5px
  },
  searchLayout: {
    backgroundColor: theme.colors.surfaceBg,
    padding: '10px',
    width: '100%',
    '& .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary)': {
      color: `${theme.colors.textOnLightBg} !important`,
      backgroundColor: `${theme.colors.surfaceBg} !important`,
    },
    '& .ant-input-search .ant-input:hover, .ant-input-search .ant-input:focus': {
      border: `1px solid ${theme.colors.borderColor}`,
      boxShadow: 'none',
    },
  },
  optionsWrapper: {
    maxHeight: '400px',
    overflowY: 'auto',
  },
  compactStyles: {
    maxHeight: '200px',
    overflowY: 'auto',
  },
  filterButton: {
    backgroundColor: theme.colors.tertiaryColor,
  },
  setBorderRight: {
    borderRight: `1px solid ${theme.colors.primaryColor}`,
  },
});

class MultiSelectFilter extends React.PureComponent<any, any> {
  state: any = {
    visible: false,
    selectedValues: [] as string[],
    data: [],
    searchText: '',
    defaultHighlightedIndex: 0,
  };
  searchInputRef;
  dropdownRef;
  checkboxRefs = {};
  constructor(props) {
    super(props);
    this.dropdownRef = React.createRef();
  }

  setSearchInputRef = (ref) => {
    this.searchInputRef = ref;
    // Focus the search input
    if (this.searchInputRef) {
      this.searchInputRef.focus();
    }
  };

  handleSearch = async (searchText) => {
    const { options, onBackendSearch } = this.props;
    const searchValue =
      typeof searchText === 'string' ? searchText : searchText.target.value;
    let data: any = [];
    if (!onBackendSearch) {
      if (searchValue && searchValue.length) {
        data = options.filter((elem) => {
          const elemText = elem.label || '';
          return elemText.toLowerCase().includes(searchValue.toLowerCase());
        });
      } else {
        data = [...options];
      }
      this.setState({ data, searchText: searchValue });
    } else {
      this.setState({ searchText: searchValue });
      const filteredOptions = await onBackendSearch(searchValue);
      this.setState({ data: [...filteredOptions] });
    }
  };

  handleResetClick = () => {
    if (this.state.selectedValues && this.state.selectedValues.length) {
      this.setState(
        {
          selectedValues: [],
          searchText: '',
          data: [...this.props.options],
          defaultHighlightedIndex: null,
        },
        () => {
          this.handleApplyClick(false);
        },
      );
    }
    this.props.onResetClick && this.props.onResetClick();
  };

  checkIfFilteredValuesChanges = () => {
    const { prevSelectedFilterValues } = this.state;
    const selectedValues = this.getSelectedValues();
    if (
      (!selectedValues || !selectedValues.length) &&
      (!prevSelectedFilterValues || !prevSelectedFilterValues.length)
    ) {
      return false;
    }
    if (
      !selectedValues ||
      !selectedValues.length ||
      !prevSelectedFilterValues ||
      !prevSelectedFilterValues.length
    ) {
      return true;
    }
    if (selectedValues.length !== prevSelectedFilterValues.length) return true;
    const differentElements = _.difference(
      selectedValues,
      prevSelectedFilterValues,
    );
    if (differentElements && differentElements.length) return true;
    return false;
  };

  getSelectedValues = () => {
    const selectedValues: any =
      'value' in this.props
        ? this.props.value || []
        : this.state.selectedValues;
    return [...selectedValues];
  };

  handleApplyClick = (isDropdownClose) => {
    const { onFilterChange, onDropdownClose, title, bucket } = this.props;
    const selectedFilteredRoles = this.state.selectedValues;
    if (isDropdownClose && onDropdownClose) {
      trackGaEvents({actionType: MULTI_FILTER}, {bucket, title})
      onDropdownClose(
        selectedFilteredRoles,
        this.checkIfFilteredValuesChanges(),
      );
      return;
    }
    if (onFilterChange) {
      onFilterChange(selectedFilteredRoles);
    }
  };

  hideDropdown = () => {
    this.setState({ visible: false });
  };

  showDropdown = () => {
    this.setState({ visible: true });
  };

  handleDropdownOpen = () => {
    this.setState({
      prevSelectedFilterValues: this.getSelectedValues(),
      data: [...(this.props.options || [])],
    });
  };

  handleDropdownClose = () => {
    this.handleApplyClick(true);
    // Reset search
    this.handleSearch('');
  };

  isValidFilterEle = (filterElement) => {
    return filterElement && filterElement.length;
  };

  handleFilterValueChanged = (filterElement) => {
    const { isSingleSelect, showCustomSelect } = this.props;
    let selectedValues: any = this.getSelectedValues();
    if (
      this.isValidFilterEle(
        showCustomSelect ? filterElement.toString() : filterElement,
      )
    ) {
      const index = selectedValues.indexOf(filterElement);
      if (index !== -1) {
        selectedValues.splice(index, 1);
      } else if (isSingleSelect) {
        selectedValues = [filterElement];
      } else {
        selectedValues.push(filterElement);
      }
      this.setState({ selectedValues }, () => {
        this.handleApplyClick(false);
      });
    }
    // if (this.searchInputRef) {
    //   this.searchInputRef.focus();
    // }
  };

  componentDidMount() {
    const { options } = this.props;
    if (Array.isArray(options) && options.length) {
      this.setState({ data: [...options] });
    }
    const selectedValues = this.getSelectedValues();
    if (selectedValues && selectedValues.length) {
      this.setState({ selectedValues });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const visible = this.state.visible;
    const prevVisible = prevState.visible;
    if (visible && !prevVisible) {
      // Handle dropdown open
      this.handleDropdownOpen();
    }

    if (!visible && prevVisible) {
      // Handle dropdown close
      this.handleDropdownClose();
    }

    if (this.props.showCustomSelect) {
      const selectedValues = this.getSelectedValues();
      const prevSelectedValues = prevState.selectedValues;
      if (
        JSON.stringify(selectedValues) !== JSON.stringify(prevSelectedValues)
      ) {
        this.setState({ selectedValues });
      }
    }
  }

  handleClickOutside = (event: any) => {
    if (!this.dropdownRef.current) {
      return;
    }
    const menuDOM: any = document.getElementsByClassName('filter-menu');
    const menuExists =
      menuDOM && menuDOM.length ? menuDOM[0].contains(event.target) : false;
    const filterBtn: any = document.getElementsByClassName('select-btn');
    const filterBtnExists =
      filterBtn && filterBtn.length
        ? filterBtn[0].contains(event.target)
        : false;
    if (!menuExists && !filterBtnExists) {
      this.hideDropdown();
    }
  };

  toggleVisible = () => {
    this.setState((state) => ({ visible: !state.visible }));
  };

  downshiftStateReducer = (state, changes) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
      case Downshift.stateChangeTypes.clickButton:
        return {
          ...changes,
          isOpen: true,
          highlightedIndex: state.highlightedIndex,
        };
      default:
        return changes;
    }
  };

  getCompleteTitle = () => {
    const { options, filterType, allOptions } = this.props;
    const selectedLabels: any = [];
    const optionList = filterType === 'search' ? allOptions : options;
    this.getSelectedValues().forEach((ele) => {
      const label = optionList.filter((item) => item.key === ele)?.[0]?.label;
      label && selectedLabels.push(label);
    });
    return selectedLabels.join(', ');
  };

  getCustomTitle = () => {
    const { title } = this.props;
    let label = this.getCompleteTitle();
    label = label.length > 20 ? label.slice(0, 20).concat('...') : label;
    return `${title}${label.length ? ':' : ''} ${label}`;
  };

  getCustomFilterButton = (selectedCount, buttonStyle) => {
    const { classes, buttonType } = this.props;
    return (
      <Tooltip title={this.getCompleteTitle()}>
        <Button
          style={buttonStyle}
          className={
            selectedCount || this.state.visible
              ? classNames('select-btn', classes.filterButton)
              : 'select-btn'
          }
          onClick={this.toggleVisible}
          type={buttonType || 'default'}
        >
          {this.getCustomTitle()}
          <DownOutlined />
        </Button>
      </Tooltip>
    );
  };

  render() {
    const { classes, title, hideButtonBorder, buttonType, showCustomSelect, t } =
      this.props;
    const selectedValues = this.getSelectedValues();
    const selectedCount = selectedValues.length;
    const buttonStyle: any = {};
    if (hideButtonBorder) {
      buttonStyle.border = 'none';
      buttonStyle.boxShadow = 'none';
    }

    const menu = (
      <Downshift
        defaultIsOpen={true}
        stateReducer={this.downshiftStateReducer}
        onChange={(selectedItem, stateAndHelpers) => {
          this.handleFilterValueChanged(selectedItem);
        }}
        selectedItem={null}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          isOpen,
          inputValue,
          highlightedIndex,
          selectedItem,
          getToggleButtonProps,
        }) => {
          return (
            <div className="filter-menu">
              <div className={classes.searchLayout}>
                <Search
                  ref={this.setSearchInputRef}
                  placeholder={t('search')}
                  onSearch={this.handleSearch}
                  {...getInputProps({
                    value: this.state.searchText,
                    onChange: this.handleSearch,
                  })}
                />
              </div>
              <div
                className={
                  showCustomSelect
                    ? classes.compactStyles
                    : classes.optionsWrapper
                }
              >
                {!isOpen
                  ? ''
                  : this.state.data.map((item: any, index: any) => {
                      return (
                        <div
                          {...getItemProps({
                            index,
                            item: item.key,
                            key: item.key,
                            onClick: (ev) => {
                              ev.stopPropagation();
                              ev.preventDefault();
                            },
                          })}
                        >
                          <Checkbox
                            checked={selectedValues.indexOf(item.key) !== -1}
                            className={classNames(classes.menuItemLayout, {
                              [classes.menuItemLayoutSelected]:
                                highlightedIndex === index,
                            })}
                          >
                            {t(item.label)}
                          </Checkbox>
                        </div>
                      );
                    })}
              </div>
              <Divider className={classes.divider}></Divider>
              <Row justify="center">
                <Col span={12} className={classes.setBorderRight}>
                  <Button
                    {...getToggleButtonProps({
                      onClick: this.toggleVisible,
                    })}
                    block
                    className={classes.dropdownButton}
                  >
                    {this.props.t('apply_filter')}
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    {...getToggleButtonProps({
                      onClick: this.handleResetClick,
                    })}
                    block
                    className={classes.dropdownButton}
                  >
                    {this.props.t('reset_filter')}
                  </Button>
                </Col>
              </Row>
            </div>
          );
        }}
      </Downshift>
    );

    return (
      <div
        // onFocus={this.showDropdown}
        ref={this.dropdownRef}
        className={classNames(classes.parentContainer, this.props.className)}
      >
        <Dropdown
          isOpen={this.state.visible}
          onClose={this.toggleVisible}
          target={
            showCustomSelect ? (
              this.getCustomFilterButton(selectedCount, buttonStyle)
            ) : (
              <Button
                style={buttonStyle}
                className={'select-btn'}
                onClick={this.toggleVisible}
              >
                {selectedCount > 0 ? `${t(title)} (${selectedCount})` : t(title)}
                <DownOutlined />
              </Button>
            )
          }
          applyCompactStyles={showCustomSelect}
        >
          {menu}
        </Dropdown>
      </div>
    );
  }
}

const Menu = (props: any) => {
  const shadow = 'hsla(218, 50%, 10%, 0.1)';
  return (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: 'absolute',
        zIndex: 10,
        width: !props.applyCompactStyle && '250px',
        minWidth: props.applyCompactStyle && '150px',
        maxWidth: props.applyCompactStyle && '215px',
      }}
      {...props}
    />
  );
};
const Blanket = (props: any) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    {...props}
  />
);
const Dropdown = ({
  children,
  isOpen,
  target,
  onClose,
  applyCompactStyles,
}) => (
  <div style={{ position: 'relative' }}>
    {target}
    {isOpen ? (
      <Menu applyCompactStyle={applyCompactStyles}>{children}</Menu>
    ) : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);
const MultiSelectFilterStyled = withStyles(styles, { injectTheme: true })(
  MultiSelectFilter,
);
export default  withTranslation('translation')(MultiSelectFilterStyled);
