import * as React from 'react';
import { Input, Modal, Select, message, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { fetchPublishedLabelList } from 'src/api/labelSetup';
import { ScanOutlined } from '@ant-design/icons';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Form } from '@ant-design/compatible';
import { printConsignmentLabel } from 'src/api/genericConsignmentView';
import { getCodeFreeLabelType } from 'src/utils/utils';
import { connect } from 'react-redux';
import { getPrintLabel } from '../../../api/ltlTracking';
import useGenericState from 'src/hooks/useGenericState';

interface IProps extends FormComponentProps {
  isVisible?: boolean;
  onCancel: () => void;
  enableCodeFreeLabel?: boolean;
  defaultLabelSizeToPrint?: string;
}

interface StateProps {
  labelList: any[];
  loading: boolean;
  isFetchingLabelList: boolean;
}

const ScanAndPrintLabelModal = (props: IProps) => {
  const {
    isVisible,
    onCancel,
    form,
    enableCodeFreeLabel,
    defaultLabelSizeToPrint,
  } = props;

  const { t } = useTranslation();

  const [state, setState] = useGenericState<StateProps>({
    labelList: [],
    loading: false,
    isFetchingLabelList: false,
  }); 

  const {
    labelList,
    loading,
    isFetchingLabelList,
  } = state;

  const iframeRef = React.useRef<HTMLIFrameElement>(null);

  React.useEffect(() => {
    fetchLabelTypeList();
  }, []);

  const fetchLabelTypeList = async () => {
    setState({ isFetchingLabelList: true });
    const response = await fetchPublishedLabelList();
    let updatedLabels;
    if (response.isSuccess) {
      updatedLabels = response.data?.metadata_list?.filter((label) => label.is_active);
    } else {
      message.error(response.errorMessage);
    }
    setState({ isFetchingLabelList: false, labelList: updatedLabels || [] });
  };

  const handlePrint = () => {
    form.validateFields(async (err, values) => {
      if (err) return;
      setState({ loading: true });
      const body = {
        consignmentIds: [values.consignmentId],
        isReferenceNumber: true,
        isSmall: enableCodeFreeLabel ? getCodeFreeLabelType(values.labelType) :
          (defaultLabelSizeToPrint?.toUpperCase() === 'A6'),
      };
      const response = await getPrintLabel(body.consignmentIds, body.isSmall, enableCodeFreeLabel);
      if (response.isSuccess) {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const blobUrl = window.URL.createObjectURL(blob);

        if (iframeRef.current) {
          iframeRef.current.src = blobUrl;
          iframeRef.current.onload = () => {
            iframeRef.current?.contentWindow?.print();
            window.URL.revokeObjectURL(blobUrl);
          };
        }
        
        // Wait for the iframe to fully load the print popup before clearing the consignmentId. 
        // This ensures the consignmentId is not reset during the iframe loading process.
        setTimeout(() => {
          form.setFieldsValue({ consignmentId: undefined });
        }, 1000);
      } else {
        message.error(response.errorMessage);
      }
      setState({ loading: false });
    });
  }
  
  const renderLabelSelect = () => {
    return (
      <>
        <div>{t('label_type_colon')}</div>
        <Form.Item>
          {form.getFieldDecorator('labelType', {
            rules: [{ required: true, message: 'Required' }],
          })(
            <Select
              placeholder={t('select_label_type')}
              style={{ width: '100%' }}
              dropdownRender={(menu) => {
                if (isFetchingLabelList) {
                  return <Spin style={{ marginLeft: '45%' }} />;
                }
                return menu;
              }}
            >
              {labelList?.map((ele) => (
                <Select.Option key={ele.id} value={ele.label_category}>
                  {ele.label_category}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </>
    );
  };

  const renderConsignmentInput = () => {
    return (
      <Form.Item style={{ marginTop: '6px' }}>
        {form.getFieldDecorator('consignmentId', {
          rules: [{ required: true, message: 'Required' }],
        })(
          <Input
            addonAfter={<ScanOutlined />}
            placeholder={t('consignment_number_placeholder')}
          />
        )}
      </Form.Item>
    );
  };

  return (
    <Modal
      title={t('print_label')}
      visible={isVisible}
      onCancel={onCancel}
      width={350}
      okText={'Print'}
      onOk={handlePrint}
      confirmLoading={loading}
      destroyOnClose
    >
      {enableCodeFreeLabel && renderLabelSelect()}
      {renderConsignmentInput()}
      <iframe ref={iframeRef} style={{ display: 'none' }} />
    </Modal>
  )
}

const mapStateToProps = (
  { masterData }, {},
) => {
  return {
      enableCodeFreeLabel: masterData.ops_dashboard_config?.parts_to_show?.enable_code_free_label_generation,
      defaultLabelSizeToPrint: masterData?.default_label_size_to_print,
  };
};

export default connect(mapStateToProps)((Form.create())(ScanAndPrintLabelModal));
