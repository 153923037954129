import * as moment from 'moment';
import { getLastSortedColumnValue } from '../utils/pagination';
import { LTL_TRACKING_ENABLE_LOADING,
  LTL_TRACKING_LOADED,
  LTL_TRACKING_SET_PAGINATION,
  LTL_RESET_PAGINATION,
  LTL_RESET_FILTER,
  LTL_FILTERS_APPLIED,
  LTL_FETCH_SAVED_SENDER_ADDRESSES,
  LTL_FETCH_SAVED_RECEIVER_ADDRESSES,
  LTL_DELETE_SAVED_ADDRESS_ENABLE_LOADING,
  LTL_DELETE_SAVED_ADDRESS_LOADED, LTL_DELETE_SAVED_ADDRESS_ERROR,
  LTL_DELETE_SAVED_ADDRESS_DISABLE_LOADING, LTL_EDIT_SAVED_ADDRESS_ENABLE_LOADING,
  LTL_EDIT_SAVED_ADDRESS_DISABLE_LOADING, LTL_EDIT_SAVED_ADDRESS_ERROR,
  LTL_CREATE_SINGLE_SHIPMENT_ENABLE_LOADING,
  LTL_CREATE_SINGLE_SHIPMENT_DISABLE_LOADING, LTL_CREATE_SINGLE_SHIPMENT_LOADED,
  LTL_CREATE_SINGLE_SHIPMENT_ERROR,
  LTL_FETCH_DETAILED_ENABLE_LOADING,
  LTL_FETCH_DETAILED_DISABLE_LOADING, LTL_FETCH_DETAILED_LOADED,
  LTL_FETCH_DETAILED_ERROR,
  LTL_FETCH_BULK_UPLOAD_ENABLE_LOADING,
  LTL_FETCH_BULK_UPLOAD_DISABLE_LOADING, LTL_FETCH_BULK_UPLOAD_ERROR,
  LTL_TRACKING_ERROR, LTL_FETCH_BULK_UPLOAD_RESET, LTL_FETCH_BULK_UPLOADED,
  LTL_DOWNLOAD_REQUEST_ENABLE_LOADING, LTL_DOWNLOAD_REQUEST_ERROR,
  LTL_DOWNLOAD_REQUEST_DISABLE_LOADING, LTL_DOWNLOAD_REQUEST_LOADED,
   LTL_FETCH_SUMMARY_METRICS_VIEW, LTL_FETCH_SUMMARY_METRICS_DATA,
   LTL_TOGGLE_SELECTED_SUMMARY_METRIC, LTL_SET_COLUMNS,
   LTL_SUMMARY_METRICS_DISABLE_LOADING, LTL_UPDATE_TRACKING, LTL_REMOVE_SEARCH_FILTERS,
   } from '../actions/constants';
const defaultState = {
  isTrackingLoading: true,
  trackingData: null,
  trackingError: null,
  selectedFilters: {
    fromDate: moment().subtract(7, 'd').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
    customer: [],
    status: [],
    // origin: [],
    // destination: [],
    carrier: [],
    conditions:[],
  },
  pagination: {
    currentPageNo:1,
    resultPerPage: 10,
    nextOrPrev:'first',
    isNextPresent: null,
  },
  selectedSummaryMetrics:[],
  summaryMetricsData:[],
  summaryMetricsLoading:true,
  column_to_show :[
      { column_id:'courierPartnerName', pretty_name: 'Carrier' },
      { column_id:'awbNumber', pretty_name: 'AWB #' },
      { column_id:'orderNumber', pretty_name: 'Order #' },
      { column_id:'originDestination', pretty_name: 'Origin Destination' },
      { column_id:'bookingTime', pretty_name: 'Creation Date' },
      { column_id:'transitDays', pretty_name: 'Transit Days' },
      { column_id:'statusAndStatusTime', pretty_name: 'Status' },
      { column_id:'attemptCount', pretty_name:'Attempt Count' },
      { column_id:'receiverDetails', pretty_name:'Receiver Details' },
  ],
  full_column_list: [
      { column_id:'courierPartnerName', pretty_name: 'Carrier' },
      { column_id:'awbNumber', pretty_name: 'AWB #' },
      { column_id:'orderNumber', pretty_name: 'Order #' },
      { column_id:'customerNameAndCode', pretty_name: 'Customer' },
      { column_id:'clientReferenceNumber', pretty_name: 'Customer Reference #' },
      { column_id:'originDestination', pretty_name: 'Origin Destination' },
      { column_id:'bookingTime', pretty_name: 'Booking Date' },
      { column_id:'transitDays', pretty_name: 'Transit Days' },
      { column_id:'statusAndStatusTime', pretty_name: 'Status' },
      { column_id:'receiverDetails', pretty_name:'Receiver Details' },
      { column_id:'attemptCount', pretty_name:'Attempt Count' },
      { column_id:'price', pretty_name:'Price' },
  ],
};

export default function (state: any = defaultState, action) {
  switch (action.type) {
    case LTL_DOWNLOAD_REQUEST_ENABLE_LOADING: {
      return {
        ...state,
        isDownloadRequestLoading: true,
      };
    }
    case LTL_DOWNLOAD_REQUEST_DISABLE_LOADING: {
      return {
        ...state,
        isDownloadRequestLoading: false,
      };
    }
    case LTL_DOWNLOAD_REQUEST_LOADED: {
      return {
        ...state,
        downloadData: action.data,
      };
    }
    case LTL_DOWNLOAD_REQUEST_ERROR: {
      return {
        ...state,
        downloadRequestError: action.errorMessage,
      };
    }
    case LTL_FETCH_BULK_UPLOADED: {
      return {
        ...state,
        bulkUploadData: action.data,
      };
    }
    case LTL_FETCH_BULK_UPLOAD_ERROR: {
      return {
        ...state,
        bulkUploadError: action.errorMessage,
      };
    }
    case LTL_FETCH_BULK_UPLOAD_RESET: {
      return {
        ...state,
        bulkUploadError: null,
        isMultipleContainerDataLoading: false,
        bulkUploadData: null,
      };
    }
    case LTL_FETCH_BULK_UPLOAD_ENABLE_LOADING: {
      return {
        ...state,
        isMultipleContainerDataLoading: true,
      };
    }
    case LTL_FETCH_BULK_UPLOAD_DISABLE_LOADING: {
      return {
        ...state,
        isMultipleContainerDataLoading: false,
      };
    }
    case LTL_FETCH_DETAILED_ENABLE_LOADING: {
      return {
        ...state,
        isDetailsLoading: true,
      };
    }
    case LTL_FETCH_DETAILED_DISABLE_LOADING: {
      return {
        ...state,
        isDetailsLoading: false,
      };
    }
    case LTL_FETCH_DETAILED_ERROR: {
      return {
        ...state,
        detailsError: action.data,
        detailsData: null,
      };
    }
    case LTL_FETCH_DETAILED_LOADED: {
      return {
        ...state,
        detailsError: null,
        detailsData: action.data,
      };
    }
    case LTL_CREATE_SINGLE_SHIPMENT_ENABLE_LOADING: {
      return {
        ...state,
        isShipmentLoading: true,
      };
    }
    case LTL_CREATE_SINGLE_SHIPMENT_DISABLE_LOADING: {
      return {
        ...state,
        isShipmentLoading: false,
      };
    }
    case LTL_FETCH_SAVED_RECEIVER_ADDRESSES: {
      return {
        ...state,
        savedReceiverAddresses: action.data,
      };
    }
    case LTL_FETCH_SAVED_SENDER_ADDRESSES: {
      return {
        ...state,
        savedSenderAddresses: action.data,
      };
    }
    case LTL_EDIT_SAVED_ADDRESS_ENABLE_LOADING: {
      return {
        ...state,
        isEditLoading: true,
      };
    }
    case LTL_CREATE_SINGLE_SHIPMENT_ERROR: {
      return {
        ...state,
        shipmentError: action.data,
      };
    }
    case LTL_EDIT_SAVED_ADDRESS_DISABLE_LOADING: {
      return {
        ...state,
        isEditLoading: false,
      };
    }
    case LTL_EDIT_SAVED_ADDRESS_ERROR: {
      return {
        ...state,
        editError: action.errorMessage,
      };
    }
    case LTL_RESET_FILTER: {
      return {
        ...state,
        selectedFilters: defaultState.selectedFilters,
        pagination: defaultState.pagination,
      };
    }
    case LTL_FILTERS_APPLIED: {
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          ...action.data,
        },
      };
    }
    case LTL_TRACKING_ENABLE_LOADING: {
      return {
        ...state,
        isTrackingLoading: true,
        trackingData: null,
        trackingError: null,
      };
    }
    case LTL_RESET_PAGINATION: {
      return {
        ...state,
        pagination: defaultState.pagination,
      };
    }
    case LTL_TRACKING_LOADED: {
      return {
        ...state,
        isTrackingLoading: false,
        trackingData: action.data.data,
        trackingError: null,
        pagination: {
          ...state.pagination,
          resultPerPage: 10,
          currentPageNo: action.data.page,
          isNextPresent: action.data.isNextPresent,
          lastSortedColumnValueNext: action.data.lastSortedColumnValueNext,
          lastTrackingRequestIdNext: action.data.lastTrackingRequestIdNext,
          lastSortedColumnValuePrev: action.data.lastSortedColumnValuePrev,
          lastTrackingRequestIdPrev: action.data.lastTrackingRequestIdPrev,
          lastSortedColumnValue: getLastSortedColumnValue(action.data),
          // lastTrackingRequestId: getLastTrackingColumnId(action.data),
        },
      };
    }
    case LTL_TRACKING_ERROR: {
      return {
        ...state,
        isTrackingLoading: false,
        trackingData: null,
        trackingError: action.data,
      };
    }
    case LTL_TRACKING_SET_PAGINATION: {
      // const pagination = Object.assign({}, state.pagination, action.data);
      return {
        ...state,
        pagination: {
          ...state.pagination,
          resultPerPage: 10,
          nextOrPrev: action.data.nextOrPrev,
          // lastSortedColumnValue:
          //  getLastSortedColumnValue(pagination),
          // lastTrackingRequestId:
          // getLastTrackingColumnId(pagination),
          currentPageNo: action.data.currentPageNo,
        },
      };
    }
    case LTL_DELETE_SAVED_ADDRESS_ENABLE_LOADING: {
      return {
        ...state,
        isDeleteAddressLoading: true,
      };
    }
    case LTL_DELETE_SAVED_ADDRESS_ERROR: {
      return {
        ...state,
        deleteError: action.data,
      };
    }
    case LTL_DELETE_SAVED_ADDRESS_DISABLE_LOADING: {
      return {
        ...state,
        isDeleteAddressLoading: false,
      };
    }
    case LTL_FETCH_SUMMARY_METRICS_VIEW: {
      return{
        ...state,
        summaryMetricsView:action.data,
      };
    }
    case LTL_FETCH_SUMMARY_METRICS_DATA: {
      return{
        ...state,
        summaryMetricsData:action.data,
      };
    }
    case LTL_TOGGLE_SELECTED_SUMMARY_METRIC: {
      // const ind = state.selectedSummaryMetrics.findIndex(x => x === action.data);
      // const selectedSummaryMetrics = [...state.selectedSummaryMetrics];
      // if (ind > -1) {
      //   selectedSummaryMetrics.splice(ind, 1);
      // }else {
      //   selectedSummaryMetrics.push(action.data);
      // }
      let selectedSummaryMetrics:any = null;
      if (action.data === state.selectedSummaryMetrics[0]) {
        selectedSummaryMetrics = [];
      }else {
        selectedSummaryMetrics = [action.data];
      }
      return{
        ...state,
        selectedSummaryMetrics,
      };
    }
    case LTL_SET_COLUMNS: {
      return {
        ...state,
        column_to_show:action.data,
      };
    }
    case LTL_SUMMARY_METRICS_DISABLE_LOADING: {
      return {
        ...state,
        summaryMetricsLoading:false,
      };
    }
    case LTL_UPDATE_TRACKING: {
      return {
        ...state,
        trackingData:action.data,
      };
    }
    default: {
      return state;
    }
    case LTL_REMOVE_SEARCH_FILTERS: {
      const { searchBy, searchKey, ...x } =  state.selectedFilters;
      return{
        ...state,
        selectedFilters: x,
      };
    }
  }
}
