import {
  fetchAllLateDeliveryReasons,
  createLateDeliveryReason,
  updateLateDeliveryReason,
} from '@api/appFlowConfig';

const getKeyMappingLateDeliverySingle = (masterData) => {
  return (
    masterData.ops_dashboard_config?.config?.ltl_late_delivery_config
      ?.csv_field_list || []
  );
};

const lateDeliveryFormItemLayout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};

const viewControl = {
  pageTitle: 'Setup',
  buckets: ['lateDeliveryReasons'],
  globalActions: [],
  addOptions: [
    {
      key: 'add_single',
      title: 'Single',
      action: 'add',
      type: 'single',
    },
  ],
  id: 'reason_id',
  fetchData: fetchAllLateDeliveryReasons,
  onSingleAdd: createLateDeliveryReason,
  add: {
    sampleUrl: undefined,
    downloadSampleCsvFromApiCall: false,
    downloadSampleCsv: null,
    title: 'Add Late Delivery Reason',
  },
  edit: {
    title: 'Edit Late Delivery Reason',
    okText: 'Update',
  },
  warningMsg: 'No Late Delivery Reason to upload',
  successMsg: 'Uploaded Successfully',
  updateMsg: 'Updated Successfully',
  modalHeading: 'Upload Late Delivery Reason',
  singleUploadMapping: getKeyMappingLateDeliverySingle,
  onSingleUpdate: updateLateDeliveryReason,
  formItemLayout: lateDeliveryFormItemLayout,
};

const buckets = {
  lateDeliveryReasons: {
    name: 'Late Delivery Reasons',
    filters: [],
    actions: [],
  },
};

const bucketColor = {};

export default { viewControl, buckets, bucketColor };
