import apiProvider from './provider';
import {
  getAuthenticationHeaders,
  getErrorMessage,
} from './utils';
import { API_BASE_URL } from '../globals';
import {
  GET_CHART_DATA, GET_CHART_DATA_RETAIL, GET_CHART_DATA_ONDEMAND, SEARCH_RIDERS,
} from './routes';
import { requestRetailSchema, requestOnDemandSchema } from '../model/analytics';
import morphism from 'morphism';
const qs = require('qs');
export const fetchChartData = async (
  body,
) => {
  try {
    const response =  await apiProvider.post(
      `${API_BASE_URL}${GET_CHART_DATA}`, body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const fetchChartsDataRetail = async (
  body,
) => {
  try {
    const response =  await apiProvider.post(
      `${API_BASE_URL}${GET_CHART_DATA_RETAIL}`, { ...morphism(requestRetailSchema, body) },
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const fetchChartsDataOnDemand = async (
  body,
) => {
  try {
    const response =  await apiProvider.post(
      `${API_BASE_URL}${GET_CHART_DATA_ONDEMAND}`, { ...morphism(requestOnDemandSchema, body) },
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const searchFilteredRiderCode = async(queryString, hubId) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${SEARCH_RIDERS}`,
      {
        params: {
          hubId,
          queryStr: queryString,
        },
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
