import Icon from '@ant-design/icons';
import * as React from 'react';

const WorkFlowBuilderSVG = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
      width="1em"
      height="1em"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#EED3AB"
        stroke="#EED3AB"
      >
        <path d="M610 4831 c-88 -28 -140 -60 -201 -120 -61 -61 -97 -123 -123 -210 -24 -80 -24 -602 0 -682 26 -87 62 -149 123 -210 61 -61 123 -97 210 -123 73 -21 598 -23 676 -2 206 55 355 245 355 453 l0 63 1328 0 c1476 0 1378 5 1462 -67 24 -21 53 -60 66 -87 l24 -51 0 -435 0 -435 -24 -51 c-13 -27 -42 -66 -66 -87 -66 -56 -111 -67 -279 -67 l-145 0 87 88 c92 94 107 120 107 183 0 99 -94 172 -194 149 -32 -7 -77 -47 -275 -242 -130 -128 -244 -245 -255 -261 -12 -18 -18 -45 -18 -77 0 -32 6 -59 18 -77 11 -16 125 -133 255 -261 198 -195 243 -235 275 -242 100 -23 194 50 194 149 0 63 -15 89 -107 183 l-87 88 155 0 c93 0 180 6 220 14 207 44 391 222 444 431 22 87 22 943 0 1030 -42 164 -165 314 -316 384 -46 22 -111 44 -143 50 -41 8 -478 11 -1393 11 l-1333 0 0 63 c0 208 -149 398 -355 453 -36 10 -128 14 -340 13 -250 0 -298 -3 -345 -18z m636 -317 c19 -9 44 -31 57 -48 21 -30 22 -38 22 -306 0 -388 17 -370 -365 -370 -279 0 -300 4 -343 64 -21 30 -22 38 -22 306 0 388 -18 369 363 370 214 0 259 -3 288 -16z" />
        <path d="M2210 3231 c-88 -28 -140 -60 -201 -120 -89 -89 -139 -208 -139 -328 l0 -63 -533 0 c-340 0 -555 -4 -593 -11 -213 -39 -404 -219 -459 -434 -22 -87 -22 -943 0 -1030 43 -166 163 -313 316 -384 46 -22 111 -44 143 -50 40 -7 356 -11 960 -11 l901 0 -92 -92 c-94 -95 -113 -126 -113 -181 0 -44 38 -107 79 -131 42 -24 114 -26 156 -4 17 9 134 121 262 250 333 336 333 300 0 636 -128 129 -245 241 -262 250 -42 22 -114 20 -156 -4 -41 -24 -79 -87 -79 -131 0 -55 19 -86 113 -181 l92 -92 -896 0 c-995 0 -947 -3 -1029 67 -24 21 -53 60 -66 87 l-24 51 0 435 0 435 24 51 c13 27 42 66 66 87 79 68 73 67 662 67 l528 0 0 -63 c0 -121 50 -239 139 -328 61 -61 123 -97 210 -123 73 -21 598 -23 676 -2 164 43 289 167 339 335 24 80 24 602 0 682 -50 168 -175 292 -339 335 -36 10 -128 14 -340 13 -250 0 -298 -3 -345 -18z m636 -317 c19 -9 44 -31 57 -48 21 -30 22 -38 22 -306 0 -388 17 -370 -365 -370 -279 0 -300 4 -343 64 -21 30 -22 38 -22 306 0 388 -18 369 363 370 214 0 259 -3 288 -16z" />
        <path d="M3810 1631 c-88 -28 -140 -60 -201 -120 -61 -61 -97 -123 -123 -210 -13 -45 -16 -107 -16 -341 0 -309 4 -339 57 -444 32 -64 125 -157 189 -189 105 -53 135 -57 443 -57 207 0 300 4 336 14 164 43 289 167 339 335 24 80 24 602 0 682 -50 168 -175 292 -339 335 -36 10 -128 14 -340 13 -250 0 -298 -3 -345 -18z m636 -317 c19 -9 44 -31 57 -48 21 -30 22 -38 22 -306 0 -388 17 -370 -365 -370 -279 0 -300 4 -343 64 -21 30 -22 38 -22 306 0 388 -18 369 363 370 214 0 259 -3 288 -16z" />
      </g>
    </svg>
  );
};

export const WorkFlowBuilderIcon = (props) => (
  <Icon component={WorkFlowBuilderSVG} {...props} />
);
