import { CssType, ThemeType } from '../../../../../../../theming/jssTypes';

const styles = (theme: ThemeType): CssType => ({
  containerDefaultFilter: {
    padding: '8px',
    backgroundColor: '#fff',
  },
});

export default styles;
