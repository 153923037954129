import { BookingMastersNavIcon } from '../../../common/icons/opsDashboard/BookingMastersNavIcon';
import { HeaderMappingNavIcon } from '../../../common/icons/opsDashboard/HeaderMappingNavIcon';
import { ShelfManagementNavIcon } from '../../../common/icons/opsDashboard/ShelfManagementNavIcon';
import { PromocodeNavIcon } from '../../../common/icons/opsDashboard/PromocodeNavIcon';
import { SmsManagementNavIcon } from '../../../common/icons/opsDashboard/SmsManagementNavIcon';
import { ValidationBuilderNavIcon } from '../../../common/icons/opsDashboard/ValidationBuilderNavIcon';
import { AddressHierarchyNavIcon } from
  '../../../common/icons/opsDashboard/AddressHierarchyNavIcon';
import { PincodeMasterNavIcon } from '../../../common/icons/opsDashboard/PincodeMasterNavIcon';
import { LabelNavIcon } from '../../../common/icons/opsDashboard/LabelSetupNavIcon';
import { SeriesConsumptionIcon } from '@components/common/icons/SeriesConsumptionIcon';
import MarketPlaceIntegration from 'src/components/common/icons/MarketplaceIntegrationIcon';
import { PushNotificationNavIcon } from '@components/common/icons/opsDashboard/PushNotificationIcon';

import { CarrierPartnerIcon } from '@components/common/icons/opsDashboard/CarrierPartnerIcon';
import { ConfigurationsIcon } from '@components/common/icons/opsDashboard/ConfigurationsIcon';
import { EmployeesIcon } from '@components/common/icons/opsDashboard/EmployeesIcon';
import { HubsIcon } from '@components/common/icons/opsDashboard/HubsIcon';
import { RiderManagementIcon } from '@components/common/icons/opsDashboard/RiderManagementIcon';
import { RiderPayoutIcon } from '@components/common/icons/opsDashboard/RiderPayoutIcon';
import { RosterManagementIcon } from '@components/common/icons/opsDashboard/RosterManagementIcon';
import { TransportIcon } from '@components/common/icons/opsDashboard/TransportIcon';
import { RateMastersIcon } from '@components/common/icons/opsDashboard/RateMastersIcon';
import { PasswordPolicyIcon } from '@components/common/icons/opsDashboard/PasswordPolicyIcon';
import { CustomerManagementIcon } from '@components/common/icons/opsDashboard/CustomerManagementIcon';
import { CustomerAllocationRulesIcon } from '@components/common/icons/opsDashboard/CustomerAllocationRulesIcon';
import { AppUsersIcon } from '@components/common/icons/opsDashboard/AppUsersIcon';
import { AppFlowConfigIcon } from '@components/common/icons/opsDashboard/AppFlowConfigIcon';
import { ADMVehiclesIcon } from '@components/common/icons/opsDashboard/ADMVehiclesIcon';
import { ThirdPartyIcon } from '@components/common/icons/opsDashboard/ThirdPartyIcon';
// import { HomeIcon } from 'src/components/common/icons/opsDashboard/HomeIcon';
import { MyDTDCConfigIcon } from 'src/components/common/icons/opsDashboard/MyDTDCConfigIcon';
import { HomeIcon } from '@components/common/icons/opsDashboard/HomeIcon';
import { ApiAccessKeyManagementIcon } from '@components/common/icons/opsDashboard/ApiAccessKeyManagementIcon';
import { WorkFlowBuilderIcon } from '@components/common/icons/opsDashboard/WorkFlowBuilderIcon';
import { AttendanceIcon } from '@components/common/icons/opsDashboard/AttendanceIcon';

export const setupSideNavConfig = [
  {
    routeId: 'hubSetup',
    icon: HubsIcon,
    title: 'Hubs',
    translationKey: 'hubs',
  },
  {
    routeId: 'miscHubOpsAttendance',
    icon: AttendanceIcon,
    title: 'Attendance',
    translationKey: 'attendance',
  },
  {
    routeId: 'setupEmployees',
    icon: EmployeesIcon,
    title: 'Employees',
    translationKey: 'employees',
  },
  {
    routeId: 'appUsers',
    icon: AppUsersIcon,
    title: 'App Users',
    translationKey: 'app_users',
  },
  {
    routeId: 'customerAllocationRules',
    icon: CustomerAllocationRulesIcon,
    title: 'Customer Allocation Rules',
    translationKey: 'customer_allocation_rules',
  },
  {
    routeId: 'customerManagement',
    icon: CustomerManagementIcon,
    title: 'Customer Management',
    translationKey: 'customer_management',
  },
  {
    routeId: 'appFlowConfig',
    icon: AppFlowConfigIcon,
    title: 'App Flow Config',
    translationKey: 'app_flow_config',
  },
  {
    routeId: 'expressBookingMasters',
    icon: BookingMastersNavIcon,
    title: 'Express Booking Masters',
    translationKey: 'express_booking_masters',
  },
  {
    routeId: 'supplementaryServices',
    icon: RateMastersIcon,
    title: 'Supplementary Services',
    translationKey: 'Supplementary Services',
  },
  {
    routeId: 'bookingMasters',
    icon: BookingMastersNavIcon,
    title: 'Booking Masters',
    translationKey: 'booking_masters',
  },
  {
    routeId: 'admVehicles',
    icon: ADMVehiclesIcon,
    title: 'ADM Vehicles',
    translationKey: 'adm_vehicles',
  },
  {
    routeId: 'headerMapping',
    icon: HeaderMappingNavIcon,
    title: 'Header Mapping',
    translationKey: 'header_mapping',
  },
  {
    routeId: 'thirdParty',
    icon: ThirdPartyIcon,
    title: 'Third Party',
    translationKey: 'third_party',
  },
  {
    routeId: 'rateMasters',
    icon: RateMastersIcon,
    title: 'Rate Masters',
    translationKey: 'rate_masters',
  },
  {
    routeId: 'carrierPartner',
    icon: CarrierPartnerIcon,
    title: 'Carrier Partner',
    translationKey: 'carrier_partner',
  },
  {
    routeId: 'transport',
    icon: TransportIcon,
    title: 'Transport',
    translationKey: 'transport',
  },
  {
    routeId: 'shelfManagement',
    icon: ShelfManagementNavIcon,
    title: 'Shelf Management',
    translationKey: 'shelf_management',
  },
  {
    routeId: 'setupPromocode',
    icon: PromocodeNavIcon,
    title: 'Promocode',
    translationKey: 'promocode',
  },
  {
    routeId: 'myDTDCConfig',
    icon: MyDTDCConfigIcon,
    title: 'MyDTDC Config',
  },
  {
    routeId: 'addressHierarchy',
    icon: AddressHierarchyNavIcon,
    title: 'Address Hierarchy',
    translationKey: 'address_hierarchy',
  },
  {
    routeId: 'pincode',
    icon: PincodeMasterNavIcon,
    title: 'Pincode',
    translationKey: 'pincode',
  },
  {
    routeId: 'passwordPolicy',
    icon: PasswordPolicyIcon,
    title: 'Security Policy',
    translationKey: 'security_policy',
  },
  {
    routeId: 'smsManagement',
    icon: SmsManagementNavIcon,
    title: 'Communication Config',
    translationKey: 'communication_config',
  },
  {
    routeId: 'validationBuilder',
    icon: ValidationBuilderNavIcon,
    title: 'Validation Config',
    translationKey: 'validation_config',
  },
  {
    routeId : 'configurations',
    icon : ConfigurationsIcon,
    title : 'Configurations',
    translationKey : 'configurations',
  },
  {
    routeId: 'labelSetup',
    icon: LabelNavIcon,
    title: 'Templates Setup',
    translationKey: 'label_setup',
  },
  {
    routeId: 'seriesConsumptionRules',
    icon: SeriesConsumptionIcon,
    title: 'Series Consumption Rules',
    translationKey: 'series_consumption_rules',
  },
  {
    routeId: 'marketPlaceIntegration',
    icon: MarketPlaceIntegration,
    title: 'Market Place Integration',
    translationKey: 'integration_marketplace',
  },
  {
    routeId: 'pushNotifications',
    icon: PushNotificationNavIcon,
    title: 'Push Notifications',
    translationKey: 'push_notifications',
  },
  {
    routeId : 'rosterManagement',
    icon : RosterManagementIcon,
    title : 'Roster Management',
    translationKey : 'roster_management',
  },
  {
    routeId : 'riderManagement',
    icon : RiderManagementIcon,
    title : 'Rider Management',
    translationKey : 'rider_management',
  },
  {
    routeId: 'riderPayout',
    icon: RiderPayoutIcon,
    title: 'Rider Payout',
    translationKey: 'rider_payout',
  },
  {
    routeId : 'appUserApproval',
    icon : WorkFlowBuilderIcon,
    title : 'App User Approval',
    translationKey : 'app_user_approval',
  },
  {
    routeId: 'currencyConversion',
    icon: RateMastersIcon,
    title: 'Currency Conversion',
    translationKey: 'currency_conversion',
  },
  {
    routeId: 'addressRegister',
    icon: HomeIcon,
    title: 'Address Register',
    translationKey: 'address_register',
  },
  {
    routeId: 'apiKeyManagementConsole',
    icon: ApiAccessKeyManagementIcon,
    title: 'API Access Key Management',
    translationKey: 'api_access_key_management',
  },
  {
    routeId: 'objectBuilder',
    icon: WorkFlowBuilderIcon,
    title: 'Object Builder',
    translationKey: 'object_builder',
  },
  {
    routeId: 'viewBuilder',
    icon: WorkFlowBuilderIcon,
    title: 'View Builder',
    translationKey: 'view_builder',
  },
  {
    routeId: 'pageBuilder',
    icon: WorkFlowBuilderIcon,
    title: 'Page Builder',
    translationKey: 'page_builder',
  },
  {
    routeId: 'workflowBuilder',
    icon: WorkFlowBuilderIcon,
    title: 'Workflow Builder',
    translationKey: 'workflow_builder',
  },
];
