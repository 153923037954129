import React, { memo, useRef } from 'react';
import lodashGet from 'lodash/get';
import ChartPane from './ChartPane';
import useChartContainerHook from '../hooks/useChartContainerHook';
import { createUseStyles } from 'react-jss';
import ChartContainerTopBar from './ChartContainerTopBar';

const useStyles = createUseStyles({
  chartPaneContainer: {
    width: '100%',
    height: '100%',
    border: '0.5px solid #D9D9D9',
    backgroundColor: '#FFFFFF',
  },
  chartPane: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  chartContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
});

const getChartBorderRadius = (chartBorderRadius) => {
  let borderRadius;
  if (chartBorderRadius) {
    const topLeft = chartBorderRadius?.chartBorderRadiusTopLeft?.data || 0;
    const topRight = chartBorderRadius?.chartBorderRadiusTopRight?.data || 0;
    const bottomRight =
      chartBorderRadius?.chartBorderRadiusBottomRight?.data || 0;
    const bottomLeft = chartBorderRadius?.chartBorderRadiusBottomLeft?.data || 0;
    borderRadius = `${topLeft}px ${topRight}px ${bottomRight}px ${bottomLeft}px`;
  }
  return borderRadius;
};

const ChartContainer = (props) => {
  const classes = useStyles();
  const chartRef: any = useRef();
  const {
    reportName,
    pageSize,
    reportId,
    dashboardGroupId,
    chartBorderRadius,
    chartType,
    graphic,
  } = props;
  const chartContainerHook = useChartContainerHook(
    reportId,
    dashboardGroupId,
  );
  const {
    apiStatus,
    chartData,
    chartOptions,
    formData,
    visualizeMetaData,
    chartConfig,
    formatting,
    columnWidth,
    htmlData,
    reportType,
    lastRefreshTime,
    dbType,
  } = chartContainerHook;

  const borderRadius = getChartBorderRadius(chartBorderRadius);
  const isReportNameVisibleOnDashboard = lodashGet(
    graphic,
    ['isReportNameVisibleOnDashboard', 'data'],
    true,
  );

  const updateChartRef = (plot) => {
    if (chartRef) {
      chartRef.current = plot;
    }
  };
  const containerRef = useRef(null);
  const renderChartPane = () => {
    return (
      <ChartPane
        updateChartRef={updateChartRef}
        apiStatus={apiStatus}
        type={chartType}
        chartData={chartData}
        pageSize={pageSize}
        visualizeMetaData={visualizeMetaData}
        chartOptions={chartOptions}
        formData={formData}
        reportId={reportId}
        chartConfig={chartConfig}
        formatting={formatting}
        columnWidth={columnWidth}
        chartsFromDashboard={true}
        htmlData={htmlData}
        reportType={reportType}
      />
    );
  };

  return (
    <div
      style={{ borderRadius }}
      className={classes.chartPaneContainer}
      ref={containerRef}
    >
      <div className={classes.chartContainer}>
        {((isReportNameVisibleOnDashboard && chartType === 'html') ||
          chartType !== 'html') && (
          <ChartContainerTopBar
            reportName={reportName}
            lastRefreshTime={lastRefreshTime}
            dbType={dbType}
          />
        )}
        <div className={classes.chartPane}>{renderChartPane()}</div>
      </div>
    </div>
  );
};

export default memo(ChartContainer);
