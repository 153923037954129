import Icon from '@ant-design/icons';
import * as React from 'react';
const EvDeliverySVG = ({ width = '8', height = '8', ...props }: any) => (
  // tslint:disable-next-line: max-line-length
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 8 8" fill="none">
  <g clipPath="url(#clip0_2633_3508)">
    <path d="M3.99935 3.33533C3.81602 3.33533 3.66602 3.18527 3.66602 3.00186V2.00144C3.66602 1.81803 3.81602 1.66797 3.99935 1.66797C4.18268 1.66797 4.33268 1.81803 4.33268 2.00144V3.00186C4.33268 3.18527 4.18268 3.33533 3.99935 3.33533Z" fill="#333333"/>
    <path d="M5.33268 4.33464H2.99935C2.81602 4.33464 2.66602 4.18464 2.66602 4.0013C2.66602 3.81797 2.81602 3.66797 2.99935 3.66797H5.33268C5.51602 3.66797 5.66602 3.81797 5.66602 4.0013C5.66602 4.18464 5.51602 4.33464 5.33268 4.33464Z" fill="#333333"/>
    <path d="M3.35609 6.33606C2.71942 6.33606 2.01609 6.23936 1.26275 6.05261C1.12275 6.01926 1.02275 5.89588 1.01275 5.75249C1.00275 5.63911 0.826088 2.96799 2.38275 1.29063C3.54275 0.0367708 5.36275 -0.313375 7.73942 0.28354C7.87942 0.316888 7.97942 0.440272 7.98942 0.583665C7.99942 0.697046 8.17609 3.36816 6.61942 5.04553C5.82276 5.90255 4.72609 6.33606 3.35276 6.33606H3.35609ZM1.66609 5.46237C3.66609 5.91255 5.17276 5.6191 6.12942 4.592C7.23609 3.40151 7.33609 1.53406 7.33276 0.873786C5.33276 0.423599 3.82609 0.717054 2.86942 1.74415C1.76275 2.93465 1.66609 4.80209 1.66942 5.46237H1.66609Z" fill="#333333"/>
    <path d="M0.333983 8.00006C0.333983 8.00006 0.307316 8.00006 0.297316 8.00006C0.113983 7.98005 -0.0160173 7.81665 0.00398273 7.63657C0.0173161 7.50652 0.417316 4.37522 6.50732 1.04383C6.67065 0.953791 6.87065 1.01382 6.96065 1.17722C7.05065 1.33728 6.99065 1.5407 6.82732 1.63074C1.08732 4.76538 0.667316 7.67659 0.663983 7.7066C0.643983 7.87668 0.500649 8.00006 0.333983 8.00006Z" fill="#333333"/>
  </g>
  <defs>
    <clipPath id="clip0_2633_3508">
      <rect width="8" height="8" fill="white"/>
    </clipPath>
  </defs>
</svg>
);
export const EvDelivery = (props) => (
  <Icon component={() => <EvDeliverySVG {...props} />} {...props} />
);
