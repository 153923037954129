import {
  TMS_DASHBOARD_UPDATE_MASTERS,
  TMS_DASHBOARD_UPDATE_TRANSPORTERS,
  TMS_DASHBOARD_UPDATE_VEHICLE_MAKE,
} from '../actions/constants';

const defaultState: any = {
  tmsMasterData: {},
  tmsTransporters: [],
  vehicleMakeList: [],
};

export default function (state: any = defaultState, action) {
  switch (action.type) {

    case TMS_DASHBOARD_UPDATE_MASTERS: {
      return {
        ...state,
        tmsMasterData: action.data,
      };
    }
    case TMS_DASHBOARD_UPDATE_TRANSPORTERS: {
      return {
        ...state,
        tmsTransporters: action.data,
      };
    }
    case TMS_DASHBOARD_UPDATE_VEHICLE_MAKE: {
      return {
        ...state,
        vehicleMakeList: action.data,
      };
    }
    default: return state;
  }
}
