import Icon from '@ant-design/icons';
import * as React from 'react';

const MyDTDCConfigSvg = (props) => {
  return (
    <svg width="22px" height="21px" viewBox="0 0 22 21" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>MyDTDC Config</title>
        <defs></defs>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="FETCH-FROM-SERVER" transform="translate(-1.000000, -2.000000)">
                <g id="Group-7" transform="translate(1.000000, 2.000000)" fill-rule="nonzero" fill="#43AEE8">
                    <g id="Group" transform="translate(8.105263, 8.877193)">
                        <rect id="Rectangle-path" transform="translate(3.107036, 4.882499) rotate(-0.111317) translate(-3.107036, -4.882499) " x="2.23861355" y="0.0772267543" width="1.73684538" height="9.61054445"></rect>
                        <polygon id="Shape" points="0.154385965 6.21403509 3.0877193 9.14736842 6.05964912 6.21403509 6.05964912 8.72280702 3.12631579 11.6561404 0.154385965 8.72280702"></polygon>
                    </g>
                    <path d="M15.9789474,14.5122807 L14.0105263,14.5122807 L14.0105263,12.7754386 L15.9789474,12.7754386 C18.3719298,12.7754386 20.3403509,10.8070175 20.3403509,8.41403509 C20.3403509,6.02105263 18.3719298,4.05263158 15.9789474,4.05263158 C15.6701754,4.05263158 15.0526316,4.05263158 14.3964912,4.28421053 L14.2035088,4.32280702 C13.9719298,4.36140351 13.6245614,4.32280702 13.3157895,3.93684211 C13.2385965,3.85964912 13.122807,3.66666667 12.8526316,3.39649123 C12.0035088,2.50877193 10.922807,2.04561404 9.72631579,2.04561404 C7.33333333,2.04561404 5.36491228,4.01403509 5.36491228,6.40701754 C5.36491228,6.52280702 5.36491228,6.6 5.36491228,6.71578947 L5.40350877,7.60350877 L4.51578947,7.64210526 C3.12631579,7.71929825 2.04561404,8.83859649 2.04561404,10.1894737 C2.04561404,11.6175439 3.20350877,12.7754386 4.63157895,12.7754386 L8.22105263,12.7754386 L8.22105263,14.5122807 L4.63157895,14.5122807 C2.23859649,14.5122807 0.30877193,12.5824561 0.30877193,10.1894737 C0.30877193,8.18245614 1.69824561,6.48421053 3.58947368,6.02105263 C3.78245614,2.85614035 6.44561404,0.347368421 9.64912281,0.347368421 C11.3473684,0.347368421 12.8912281,1.00350877 14.0491228,2.23859649 C14.1649123,2.35438596 14.2421053,2.43157895 14.3192982,2.50877193 C15.0140351,2.35438596 15.5929825,2.35438596 15.8631579,2.35438596 C19.2596491,2.35438596 21.9614035,5.09473684 21.9614035,8.45263158 C21.9614035,11.8105263 19.3368421,14.5122807 15.9789474,14.5122807 Z" id="Shape"></path>
                </g>
                <rect id="Rectangle-7" x="0" y="0" width="24" height="24"></rect>
            </g>
        </g>
    </svg>
    );
};

export const MyDTDCConfigIcon = props => (
  <Icon component={MyDTDCConfigSvg} {...props} />
);
