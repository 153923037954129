import * as React from 'react';
import withStyles from 'react-jss';
import { CssType, ThemeType } from 'src/theming/jssTypes';
import MultiSelectFilter from 'src/components/common/MultiselectFilter';
import { sanitiseObject, searchStrategy } from 'src/components/pages/GenericConsignment/CustomFilters/utils';
import { useTranslation } from 'react-i18next';

const styles = (theme: ThemeType): CssType => ({
  multiSelectFilter: {
    marginLeft: '0px',
    '& .ant-form label': {
      fontSize: '13px',
    },
    '& .optionsWrapper': {
      maxHeight: '200px',
    },
  },
});

const GenericMultiSelectSearchFilter = (props: any) => {
  const { t } = useTranslation();
  const {
    classes,
    filter,
    selectedFilters,
    applyFilter,
    hubId,
    isSingleSelect,
    globalAppliedFilters,
  } = props;
  const [value, setValue] = React.useState([]);
  const [searchOptions, setSearchOptions] = React.useState<any>([]);
  const [allOptions, setAllOptions] = React.useState<any>([]);

  React.useEffect(() => {
    const options = selectedFilters[filter.id];
    const optionList =
      options !== undefined && options !== null
        ? isSingleSelect
          ? [options]
          : options
        : [];
    const filterValue =
      filter.type === 'search' ? optionList.map((ele) => ele.key) : optionList;
    setValue(filterValue);
    if (filter.type === 'search') {
      const allOptionList = optionList.filter((ele) => {
        return !allOptions.find((item) => item.key === ele.key);
      });
      setSearchOptions(optionList);
      setAllOptions(allOptions.concat(allOptionList));
    }
  }, [selectedFilters]);

  const onFilterValueChange = (filterVals) => {
    setValue(filterVals);
  };

  const handleApplyMultiSelect = (filterVals, isChanged) => {
    if (!isChanged && filter.type !== 'search') {
      return;
    }
    const filterObj = filterVals.map((item) => {
      return allOptions.filter((ele) => ele.key === item)?.[0] || {};
    });
    const filterValues = filter.type === 'search' ? filterObj : filterVals;
    applyFilter(isSingleSelect ? filterValues[0] : filterValues);
  };

  const generateMultiSelectOptions = (list) => {
    const optionsList: any = [];
    if (list) {
      list.forEach((element) => {
        optionsList.push({
          key: element.value,
          value: element.value,
          label: t(element.label),
        });
      });
    }
    return optionsList;
  };

  const handleSearch = (strategy) => async (value) => {
    const queryString: string = value.trim();
    // const { startDate: fromDateString, endDate: toDateString } =
    //   globalAppliedFilters;
    if (!queryString) {
      setSearchOptions([]);
      return [];
    }
    if (queryString.length >= (filter.threshold_length || 3)) {
      const options = await searchStrategy(strategy)(
        queryString,
        hubId,
      );
      const allOptionList = options.filter((ele) => {
        return !allOptions.find((item) => item.key === ele.key);
      });
      setSearchOptions(options);
      setAllOptions(allOptions.concat(allOptionList));
      return options;
    }
    setSearchOptions([]);
    return [];
  };

  return (
    <MultiSelectFilter
      value={value}
      onFilterChange={onFilterValueChange}
      className={classes.multiSelectFilter}
      title={filter.label}
      buttonType="text"
      showCustomSelect
      isSingleSelect={isSingleSelect}
      allOptions={allOptions}
      filterType={filter.type}
      onDropdownClose={handleApplyMultiSelect}
      onBackendSearch={
        filter.type === 'select'
          ? undefined
          : (searchValue) => handleSearch(filter.search_strategy)(searchValue)
      }
      options={
        filter.type === 'search'
          ? searchOptions
          : generateMultiSelectOptions(filter.options)
      }
    />
  );
};

export default withStyles(styles, { injectTheme: true })(
  GenericMultiSelectSearchFilter,
);
