import {
  RIDER_TRANSACTION_HISTORY_FETCH_TRANSACTIONS_INITIATED,
  RIDER_TRANSACTION_HISTORY_FETCH_TRANSACTIONS_FAILED,
  RIDER_TRANSACTION_HISTORY_FETCH_TRANSACTIONS_SUCCEEDED,
  RIDER_TRANSACTION_HISTORY_FETCH_HUB_DATA_INITIATED,
  RIDER_TRANSACTION_HISTORY_FETCH_HUB_DATA_SUCCEEDED,
  RIDER_TRANSACTION_HISTORY_FETCH_HUB_DATA_FAILED,
  RIDER_TRANSACTION_HISTORY_FETCH_RIDER_DATA_INITIATED,
  RIDER_TRANSACTION_HISTORY_FETCH_RIDER_DATA_SUCCEEDED,
  RIDER_TRANSACTION_HISTORY_FETCH_RIDER_DATA_FAILED,
  RIDER_TRANSACTION_HISTORY_DOWNLOAD_RIDER_DATA_INITIATED,
  RIDER_TRANSACTION_HISTORY_DOWNLOAD_RIDER_DATA_SUCCEEDED,
  RIDER_TRANSACTION_HISTORY_DOWNLOAD_RIDER_DATA_FAILED,
  RIDER_TRANSACTION_HISTORY_INCREMENT_DOWNLOAD_COUNTER,
  RIDER_TRANSACTION_HISTORY_RESET_DOWNLOAD_COUNTER,
} from '../actions/constants';

const genericState = {
  data: [],
  fetching: false,
  fetched: false,
  error: false,
};

const downloadData = {
  ...genericState,
  consecutiveDownloadsCount: 0,
};

const defaultState = {
  downloadData,
  transactionsData: { ...genericState },
  hubData: { ...genericState },
  riderData: { ...genericState },
  fetching: false,
  fetched: false,
  error: false,
};

export default function riderTransactionHistoryReducer(state = defaultState, action) {
  switch (action.type) {
    case RIDER_TRANSACTION_HISTORY_FETCH_TRANSACTIONS_INITIATED : {
      return {
        ...state,
        transactionsData: {
          ...state.transactionsData,
          fetching: true,
        },
      };
    }
    case RIDER_TRANSACTION_HISTORY_FETCH_TRANSACTIONS_SUCCEEDED : {
      return {
        ...state,
        transactionsData: {
          data: action.payload,
          fetching: false,
          fetched: true,
          error: false,
        },
      };
    }
    case RIDER_TRANSACTION_HISTORY_FETCH_TRANSACTIONS_FAILED : {
      return {
        ...state,
        transactionsData: {
          data: action.payload,
          fetching: false,
          fetched: true,
          error: true,
        },
      };
    }
    case RIDER_TRANSACTION_HISTORY_FETCH_HUB_DATA_INITIATED : {
      return {
        ...state,
        hubData: {
          ...state.hubData,
          fetching: true,
        },
      };
    }
    case RIDER_TRANSACTION_HISTORY_FETCH_HUB_DATA_SUCCEEDED : {
      return {
        ...state,
        hubData: {
          data: action.payload,
          fetching: false,
          fetched: true,
          error: false,
        },
      };
    }
    case RIDER_TRANSACTION_HISTORY_FETCH_HUB_DATA_FAILED : {
      return {
        ...state,
        hubData: {
          data: genericState,
          fetching: false,
          fetched: true,
          error: true,
        },
      };
    }
    case RIDER_TRANSACTION_HISTORY_FETCH_RIDER_DATA_INITIATED : {
      return {
        ...state,
        riderData: {
          ...state.riderData,
          fetching: true,
        },
      };
    }
    case RIDER_TRANSACTION_HISTORY_FETCH_RIDER_DATA_SUCCEEDED: {
      return {
        ...state,
        riderData: {
          data: action.payload.data.data.workers,
          fetching: false,
          fetched: true,
          error: false,
        },
      };
    }
    case RIDER_TRANSACTION_HISTORY_FETCH_RIDER_DATA_FAILED: {
      return {
        ...state,
        riderData: {
          data: action.payload,
          fetching: false,
          fetched: true,
          error: true,
        },
      };
    }
    case RIDER_TRANSACTION_HISTORY_DOWNLOAD_RIDER_DATA_INITIATED: {
      return {
        ...state,
        downloadData: {
          ...state.downloadData,
          fetching: true,
        },
      };
    }
    case RIDER_TRANSACTION_HISTORY_DOWNLOAD_RIDER_DATA_SUCCEEDED: {
      return {
        ...state,
        downloadData: {
          ...state.downloadData,
          data: action.payload.data.data,
          fetching: false,
          fetched: true,
          error: false,
        },
      };
    }
    case RIDER_TRANSACTION_HISTORY_DOWNLOAD_RIDER_DATA_FAILED: {
      return {
        ...state,
        downloadData: {
          ...state.downloadData,
          data: action.payload.data,
          fetching: false,
          fetched: true,
          error: true,
        },
      };
    }
    case RIDER_TRANSACTION_HISTORY_INCREMENT_DOWNLOAD_COUNTER: {
      return {
        ...state,
        downloadData: {
          ...state.downloadData,
          consecutiveDownloadsCount: state.downloadData.consecutiveDownloadsCount + 1,
        },
      };
    }
    case RIDER_TRANSACTION_HISTORY_RESET_DOWNLOAD_COUNTER: {
      return {
        ...state,
        downloadData: {
          ...state.downloadData,
          consecutiveDownloadsCount: 0,
        },
      };
    }
    default: return state;
  }
}
