import { CssType, ThemeType } from '../../../../../../../theming/jssTypes';

const styles = (theme: ThemeType): CssType => ({
  metricContainer: {
    color: '#000',
    display: 'flex',
    flexDirection: 'column',
  },
  metricHeader: {
    background: '#FAFAFA',
    fontSize: '12px',
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.85)',
    padding: '10px 13px 10px 13px',
    alignItems: 'center',
    display: 'flex',
    borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
    width: '100%',
  },
  metricBody: {
    padding: '0 12px 8px 12px',
    backgroundColor: '#FFF',
    display: 'flex',
    flexDirection: 'column',
  },
  greyMetricHeading: {
    fontSize: '10px',
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.45)',
  },
  greyMetricLabel: {
    fontSize: '10px',
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.45)',
  },
  metricContent: {
    fontSize: '12px',
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.85)',
  },
  divider: {
    color: 'rgba(0, 0, 0, 0.06)',
    margin: '8px 0 0 0',
  },
});

export default styles;
