import Icon from '@ant-design/icons';
import React from 'react';

const MarketPlaceIntegrationSvg = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      height="1em"
      width="1em"
      enableBackground="new 0 0 64 64"
      version="1.1"
      viewBox="0 0 64 64"
      xmlSpace="preserve"
    >
      <path d="M45.7 1.8c-.2-.4-.6-.6-1.1-.6h-4.2v8.7h9.7l-4.4-8.1zM32.4 18.6c3.1 0 5.6-2.5 5.6-5.6v-.6H26.8v.6c0 3.1 2.5 5.6 5.6 5.6zM10.6 1.2H6.4c-.4 0-.9.2-1 .6L.9 9.9h9.7V1.2zM26.8 1.2H38v8.7H26.8V1.2zM18.7 18.6c3.1 0 5.6-2.5 5.6-5.6v-.6H13.1v.6c0 3.1 2.5 5.6 5.6 5.6zM13.1 1.2h11.2v8.7H13.1V1.2zM40.5 12.4v.9c0 2.9 2.4 5.3 5.3 5.3s5.3-2.4 5.3-5.3v-.9H40.5zM11.7 48.5H7.5c-2.1 0-3.7-1.7-3.7-3.7V21c-1.5-.3-2.7-1-3.8-2v32c0 .7.6 1.2 1.2 1.2h13l-.2-.1c-1.3-.8-2.1-2.1-2.3-3.6zM5.3 18.6c2.9 0 5.3-2.4 5.3-5.3v-.9H0v.9c0 2.9 2.4 5.3 5.3 5.3z"></path>
      <g>
        <path d="M43.9 27.8H20.5c-1.9 0-3.7.8-5 2.1-1.3 1.3-2.1 3.2-2.1 5v10.4c0 1.9.8 3.7 2.1 5 1.3 1.3 3.2 2.1 5 2.1.7 0 1.3-.4 1.7-1 .3-.6.3-1.3 0-1.9-.3-.6-1-1-1.7-1-.9 0-1.7-.3-2.3-1-.6-.6-1-1.4-1-2.3V34.9c0-.9.3-1.7 1-2.3.6-.6 1.4-1 2.3-1h23.4c.9 0 1.7.3 2.3 1 .6.6 1 1.4 1 2.3v10.4c0 .9-.3 1.7-1 2.3-.6.6-1.4 1-2.3 1h-7.8c-.7 0-1.3.4-1.7 1-.3.6-.3 1.3 0 1.9.3.6 1 1 1.7 1h7.8c1.9 0 3.7-.8 5-2.1 1.3-1.3 2.1-3.2 2.1-5V34.9c0-1.9-.8-3.7-2.1-5-1.3-1.4-3.1-2.1-5-2.1z"></path>
        <path d="M64 55.7V45.3c0-1.9-.8-3.7-2.1-5s-3.2-2.1-5-2.1c-.7 0-1.3.4-1.7 1-.3.6-.3 1.3 0 1.9.3.6 1 1 1.7 1 .9 0 1.7.3 2.3 1 .6.6 1 1.4 1 2.3v10.4c0 .9-.3 1.7-1 2.3-.6.6-1.4 1-2.3 1H33.5c-.9 0-1.7-.3-2.3-1-.6-.6-1-1.4-1-2.3V45.3c0-.9.3-1.7 1-2.3.6-.6 1.4-1 2.3-1h7.8c.7 0 1.3-.4 1.7-1 .3-.6.3-1.3 0-1.9-.3-.6-1-1-1.7-1h-7.8c-1.9 0-3.7.8-5 2.1-1.3 1.3-2.1 3.2-2.1 5v10.4c0 1.9.8 3.7 2.1 5 1.3 1.3 3.2 2.1 5 2.1h23.4c1.9 0 3.7-.8 5-2.1 1.3-1.2 2.1-3 2.1-4.9z"></path>
      </g>
      <path d="M51.1 29.6V19c-1.1.9-2.3 1.6-3.7 1.9v5.9c1.2.3 2.3 1 3.1 2l.6.8z"></path>
    </svg>
  );
};

const MarketPlaceIntegration = props => (
  <Icon component={MarketPlaceIntegrationSvg} {...props} />
);

export default MarketPlaceIntegration;
