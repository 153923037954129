import apiProvider from './provider';
import { API_BASE_URL } from '../globals';
import { getAuthenticationHeaders, getErrorMessage } from './utils';
import { GET_MASTER_DATA } from './routes';

export const getMasterData = async () => {

  try {
    const masterData = await apiProvider.get(
      `${API_BASE_URL}${GET_MASTER_DATA}`,
      { headers: await getAuthenticationHeaders() },
    );
    return {
      masterData: masterData.data.data,
      isSuccess: true,
    };
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
