import * as React from 'react';
import withStyles from 'react-jss';
import { pdfjs, Document, Page } from 'react-pdf';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';

pdfjs.GlobalWorkerOptions.workerSrc =
  'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.9.359/pdf.worker.js';

const styles: any = (theme) => ({
  rendercontainer: {
    height: 'calc(100% - 60px)',
    overflow: 'auto',
    padding: '15px',
    backgroundColor: '#f0f0f0',
    display: 'flex',
    justifyContent: 'center',
    userSelect: 'none !important',
  },
  loader: {
    zIndex: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

interface PdfRendererProps {
  classes: any;
  url: String;
  isLoading: Boolean;
  height: any;
}

const PdfRenderer = (props: PdfRendererProps) => {
  const { classes, url, isLoading, height } = props;

  const [pageNumber, setPageNumber] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setTotalPages(numPages);
  };

  const handlePrev = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleNext = () => {
    if (pageNumber <= totalPages - 1) {
      setPageNumber(pageNumber + 1);
    }
  };

  return (
    <div className={classes.rendercontainer}>
      {isLoading && (
        <div className={classes.loader}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />} />
        </div>
      )}
      {url && !isLoading ? (
        <>
          <Document
            file={{ url }}
            style={{ height: '100%', width: '100%', overflow: 'scroll' }}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginBottom: '10px',
              }}
            >
              <b
                style={{ marginRight: '10px' }}
              >{`${pageNumber}/${totalPages}`}</b>
              <Button onClick={handlePrev}>{'<'}</Button>
              <Button onClick={handleNext}>{'>'}</Button>
            </div>
            <Page
              height={height}
              pageNumber={pageNumber}
              style={{ display: 'flex', justifyContent: 'center' }}
              wrap={true}
            ></Page>
          </Document>
        </>
      ) : null}
    </div>
  );
};

export default withStyles(styles, {
  injectTheme: true,
})(PdfRenderer);
