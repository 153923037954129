import React from 'react';
import withStyles, { WithStylesProps } from 'react-jss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PageData } from 'src/types/page.types';
import { fetchViewDataObjects } from 'src/actions/genericPageActions';
import { filterResolver } from '../GenericViewFiltersResolver';
import { Button } from 'antd';
import { MoreFilterIconIcon } from 'src/components/common/icons/MoreFilterIcon';
import GenericActionButton from 'src/components/common/GenericActionButton';

const styles = () => ({
    button: {
        border: 0,
        boxShadow: 'none',
    },
    filterContainer: {
        position: 'absolute',
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 10,
        width: '100%',
        background: 'white',
    },
    filterNodeWrapper: {
        marginBottom: '8px',
    },
    moreFilterFooter: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
});

interface IProps extends
    WithStylesProps<ReturnType<typeof styles>>,
    ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {
        pageId: string;
        viewIndex: number;
        sectionIndex: number;
}

const GenericViewMoreFilters = (props: IProps) => {
    const {
        classes,
        fetchViewDataObjects,
        viewData,
        pageId,
        viewIndex,
        sectionIndex,
    } = props;

    const [visible, setVisible] = React.useState(false);
    const [filters, setFilters] = React.useState({});

    React.useEffect(() => {
        if (visible) {
            const filterData = viewData.getMoreFilters();
            const filterStateData = {};
            filterData.forEach((f) => {
                if (viewData.appliedFilters[f.id]) {
                    filterStateData[f.id] = viewData.appliedFilters[f.id];
                }
            });
            setFilters(filterStateData);
        }
    }, [visible]);

    const handleApplyMoreFilters = () => {
        viewData.applyFilters(filters);
        fetchViewDataObjects({
            viewData,
            viewIndex,
            pageId,
            sectionIndex,
        });
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
        setFilters({});
    };

    const handleFilterChange = (filterObj) => {
        const newFilters = {...filters};
        for (const key in filterObj) {
            newFilters[key] = filterObj[key];
        }
        setFilters(newFilters);
    };

    const renderMoreFilters = () => {
        const filterData = viewData.getMoreFilters();
        return (
            <div className={classes.filterContainer}>
                {
                    filterData.map((filter) => {
                        const node = filterResolver(
                            filter,
                            viewData.object_type,
                            {
                                pageId,
                                viewIndex,
                                sectionIndex,
                                filters,
                                handleFilterChange,
                            },
                        );

                        return  <div className={classes.filterNodeWrapper} key={filter.id}>
                            {node}
                        </div>;
                    })
                }
                <div className={classes.moreFilterFooter}>
                    <GenericActionButton onClick={handleCancel} style={{
                        marginRight: '8px',
                    }}>
                        Cancel
                    </GenericActionButton>
                    <GenericActionButton onClick={handleApplyMoreFilters}>
                        Apply
                    </GenericActionButton>
                </div>
            </div>    
        );
    };

    const renderButton = () => {
        const filterData = viewData.getMoreFilters();

        if (filterData && filterData.length) {
            return <Button
                onClick={() => {
                    if (visible) {
                        setFilters({});
                    }
                    setVisible(!visible);
                }}
                className={classes.button}
            >
                <MoreFilterIconIcon style={{ fontSize:'16px' }} />
                More Filters
            </Button>;
        }

        return <></>;
    }

    

    return <>
        {renderButton()}
        {
            visible ? renderMoreFilters() : <></>
        }
    </>;
};

const mapStateToProps = ({ genericPageReducer }, ownProps) => {
    const { pageId, viewIndex, sectionIndex } = ownProps;
    const pageData: PageData = genericPageReducer[pageId];
    const viewData = pageData.metadata.sectionList[sectionIndex][viewIndex];
    return {
        viewData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchViewDataObjects,
    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(
    withStyles(styles, { injectTheme: true })(GenericViewMoreFilters),
);
