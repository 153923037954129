export const markerColorPalette = {
  success : 'green',
  delivered : 'green',
  attempted: 'red',
};
export const getMarkerColor = (taskStatus) => {
  const color = markerColorPalette[taskStatus];
  return color ? color : 'yellow';
};
export const tripColorPalette = [
  '#13C2C2',
  '#52C41A',
  '#A0D911',
  '#00474F',
  '#135200',
  '#3F6600',
  '#FAAD14',
  '#2F54EB',
  '#1890FF',
  '#874D00',
  '#061178',
  '#003A8C',
  '#FA8C16',
  '#FA541C',
  '#F5222D',
  '#873800',
  '#871400',
  '#820014',
  '#EB2F96',
  '#5F0BD5',
  '#876800',
  '#780650',
  '#2C008F',
  '#3fec3f'
    , '#000080'
    , '#808000'
    , '#800080'
    , '#008080'
    , '#C0C0C0'
    , '#808080'
    , '#9999FF'
    , '#993366'
    , '#faad14'
    , '#39afaf'
    , '#660066'
    , '#FF8080'
    , '#0066CC'
    , '#5757b5'
    , '#000080'
    , '#FF00FF'
    , '#FFFF00'
    , '#00FFFF'
    , '#800080'
    , '#800000'
    , '#008080'
    , '#0000FF'
    , '#00CCFF'
    , '#CCFFFF'
    , '#CCFFCC'
    , '#FFFF99'
    , '#99CCFF'
    , '#FF99CC'
    , '#e0770e'
    , '#FFCC99'
    , '#3366FF'
    , '#33CCCC'
    , '#99CC00'
    , '#FFCC00'
    , '#FF9900'
    , '#FF6600'
    , '#666699'
    , '#969696'
    , '#003366'
    , '#339966'
    , '#003300'
    , '#333300'
    , '#993300'
    , '#993366'
    , '#333399'
    , '#333333',
];
