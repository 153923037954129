import {
  fetchEWBThresholdMaster,
  downloadDumpGateMaster,
} from '../../api/TMSDashboard/Setup';
const viewControl = {
  pageTitle: 'Setup',
  buckets: ['tms_setup_ewb_threshold_master'],
  globalActions: [],
  fetchData: fetchEWBThresholdMaster,

  actions: [
    {
      key: 'addOptions',
      name: 'Add Options',
      isAllowed: (masterData) =>
        masterData.ops_dashboard_config?.parts_to_show?.setup_permission_obj
          ?.transport?.gate?.update,
    },
    {
      key: 'pagination',
      name: 'Pagination',
    },
    {
      key: 'perPageCountChange',
      name: 'Pagination Count',
    },
    {
      key: 'reload',
      name: 'Reload',
    },
  ],
  addOptions: [
    {
      key: 'add_single',
      title: 'Single',
      action: 'add',
      type: 'single',
    },
    {
      key: 'add_bulk',
      title: 'Bulk',
      action: 'add',
      type: 'bulk',
    },
  ],
};
const buckets = {
  tms_setup_ewb_threshold_master: {
    name: 'EWB Threshold Master',
    filters: [],
    actions: [],
  },
};
const bucketColor = {};
export default { viewControl, buckets, bucketColor };
