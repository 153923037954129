import {
  INITIALISE_DASHBOARD_GROUP,
  REMOVE_REPORT,
  SET_LAST_UPDATED_TIME,
  RESET_DASHBOARD_GROUP,
  SET_REFRESH_REPORTS_DATA,
} from '../actions/constants';
import lodashCloneDeep from 'lodash/cloneDeep';
const defaultState = {
  dashboardGroupName: '',
  dashboardGroupId: '',
  dashboardDescription: '',
  reportList: [],
  reportsData: {},
  visibleCharts: {},
  currentDashboardGroupTab: 0,
  metadata: undefined,
  autoReloadFrequency: 1314000000, // 1 year to temporarily disable auto reload
  lastUpdatedTime: 0,
};

export default function (state: any = defaultState, action) {
const copyDashboardMetaData = lodashCloneDeep(state);
  switch (action.type) {
    case INITIALISE_DASHBOARD_GROUP:
      const newObject = Object.assign(copyDashboardMetaData, action.payload);
      return newObject;
    case REMOVE_REPORT:
      return {
        ...copyDashboardMetaData,
        reportList: copyDashboardMetaData.reportList.filter((reportId) => {
          return reportId !== action.payload;
        }),
      };
    case SET_LAST_UPDATED_TIME:
      return {
        ...copyDashboardMetaData,
        lastUpdatedTime: action.payload,
      };
    case RESET_DASHBOARD_GROUP:
      return defaultState;
    case SET_REFRESH_REPORTS_DATA:
      return {
        ...copyDashboardMetaData,
        reportsData: action.payload,
      };
    default:
      return state;
  }
}
