import { CssType, ThemeType } from '@theming/jssTypes';
import { MOBILE_BREAK_POINT } from '@src/globals';

const styles = (theme: ThemeType): CssType => ({
  logoImage: {
    display: 'flex',
    height: 60,
    margin: '0 auto',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100vw',
  },
  topMainDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    flexGrow: 1,
    background: 'transparent',
  },
  loginContainer: {
    padding: '24px',
    backgroundColor: '#fff',
    boxShadow: '0px 0px 64px rgba(0, 0, 0, 0.12)',
    borderRadius: '8px',
    maxWidth: '100%',
  },
  heading: {
    display: 'flex',
    color: '#333333',
    fontFamily: 'Open Sans',
    fontSize: 24,
    fontWeight: '600',
    letterSpacing: 0,
    margin: '16px auto',
    width: 'fit-content',
  },
  passwordInputHeader: {
    display: 'flex',
    marginTop: 20,
    color: '#666666',
    fontFamily: 'Open Sans',
    fontSize: 10,
    letterSpacing: 0,
    justifyContent: 'space-between',
  },
  inputBox: {
    display: 'flex',
    marginTop: 3,
    width: '350px',
    maxWidth: '100%',
  },
  submitButtonContainer: {
    display: 'flex',
    marginTop: 15,
    width: '350px',
    maxWidth: '100%',
  },
  submitButton: {
    backgroundColor: '#006EC3',
  },
  orDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15px',

    '& > hr': {
      borderWidth: 0,
      width: '100%',
      backgroundColor: '#D9D9D9',
      height: '2px',
    },
    '& > span': {
      position: 'absolute',
      backgroundColor: '#fff',
      color: '#666666',
      padding: '0 8px',
    },
  },
  forgotPasswordBtnDiv: {
    textAlign: 'center',
    marginTop: '12px',

    '& > button': {
      border: 'none',
      color: '#006EC3',
      background: 'transparent',
      marginTop: '10px',
      fontSize: '12px',
      lineHeight: '22px',
      fontWeight: '600',
      cursor: 'pointer',
    },
  },
  footerDiv: {
    margin: 'auto auto 8px',

    '& > span': {
      color: '#666666',
      fontSize: '12px',
    },
    '& > img': {
      marginLeft: '8px',
      height: '16px',
    },
  },
  subText: {
    textAlign: 'center',
    color: '#666666',

    '& > p': {
      margin: 0,
    },
  },
  errorMessage: {
    color: '#FF0000',
  },
  roundedAndShadowBorder: {
    boxShadow: '0px 0px 64px rgba(0, 0, 0, 0.12)',
    borderRadius: '8px',
  },
  imageLimit: {
    maxHeight: '380px',
    maxWidth: '610px'
  },
  centeredImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    flexGrow: 1,
    background: '#EFF5FB',
  }
});

export default styles;
