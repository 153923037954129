import { getDetailsRoute } from '../../routing/utils';
import { getAllConsignmentsData, getSummaryMetricsData } from '../../api/genericConsignmentView';
const defaultRetailStatusFilterOptions = [
  'reachedathub',
  'outfordelivery',
  'delivered',
  'attempted',
  'inscan_at_hub',
  'in_transit',
  'pickup_awaited',
  'pickup_scheduled',
  'out_for_pickup',
  'not_picked_up',
  'pickup_completed',
  'received',
  'not_received',
  'out_for_store_pickup',
];

const viewControl = {
  buckets : [
    'retail_all',
    'retail_unplanned',
    'retail_planned',
    'retail_in_progress',
    'retail_completed',
    'retail_failed',
    'retail_inbound',
  ],
  navBar : {
    left : ['hub_search_with_type', 'date_filter'],
    right : ['search_redirect'],
  },
  globalActions:[],
  dateFilterOptions : [
    { key:'created_at', val:'Creation Time' },
    { key:'last_main_event_time', val: 'Last Updated' },
    // { key:'hub_arrival_time', val: 'Hub Arrival Time' },
  ],
  searchFilterOptions : [
    { key:'referenceNumber', val: 'Reference Number' },
    // { key:'senderPhone', val: 'Sender Phone' },
    // { key:'destinationPhone', val: 'Receiver Phone' },
  ],
  hubSearchOptions : [
    { key:'dc', val:'Planning Hub' },
    { key:'upcountry', val:'Upcountry Hub' },
  ],
  detailsRoute:getDetailsRoute,
  fetchData : getAllConsignmentsData,
  fetchSummaryMetrics : getSummaryMetricsData,
  applyDateFilterSort: true,
};
const buckets = {
  retail_all : {
    name:'All',
    filters : [
      'status',
      'attempt_count',
      'pickup_attempt_count',
      'service_type',
      'load_type',
      'customer_code',
      'consignment_type',
      'proximity',
      'orderType',
      'purchasingGroup',
      'reference_number_search',
      'customer_reference_number_search',
      'store_hub_id',
      'so_number_search',
      'destination_pincode_city',
      'delivery_type',
    ],
    actions : [
      // 'addCN',
    ],
    status_filter: defaultRetailStatusFilterOptions,

  },
  retail_planned : {
    name:'Planned',
    filters : [
      'status',
      'attempt_count',
      'pickup_attempt_count',
      'service_type',
      'load_type',
      'customer_code',
      'consignment_type',
      'proximity',
      'orderType',
      'purchasingGroup',
      'reference_number_search',
      'customer_reference_number_search',
      'store_hub_id',
      'so_number_search',
      'destination_pincode_city',
      'delivery_type',
    ],
    actions : [
      // 'createRoute',
      // 'unassign',
    ],
    status_filter: defaultRetailStatusFilterOptions,

  },
  retail_unplanned : {
    hidePagination : false,
    hidePerPageCount :false,
    name:'Unplanned',
    filters : [
      'status',
      'attempt_count',
      'pickup_attempt_count',
      'service_type',
      'load_type',
      'customer_code',
      'consignment_type',
      'proximity',
      'orderType',
      'purchasingGroup',
      'reference_number_search',
      'customer_reference_number_search',
      'store_hub_id',
      'so_number_search',
      'destination_pincode_city',
      'delivery_type',
    ],
    actions : [
      // 'createRoute',
      // 'unassign',
    ],
    status_filter: defaultRetailStatusFilterOptions,

  },
  retail_in_progress : {
    name: 'In Transit',
    filters : [
      'status',
      'attempt_count',
      'pickup_attempt_count',
      'service_type',
      'load_type',
      'customer_code',
      'consignment_type',
      'proximity',
      'orderType',
      'purchasingGroup',
      'reference_number_search',
      'customer_reference_number_search',
      'store_hub_id',
      'so_number_search',
      'destination_pincode_city',
      'delivery_type',
    ],
    actions: [
    ],
    status_filter : defaultRetailStatusFilterOptions,
  },
  retail_completed : {
    name: 'Success',
    filters : [
      'status',
      'attempt_count',
      'pickup_attempt_count',
      'service_type',
      'load_type',
      'customer_code',
      'consignment_type',
      'proximity',
      'orderType',
      'purchasingGroup',
      'reference_number_search',
      'customer_reference_number_search',
      'store_hub_id',
      'so_number_search',
      'destination_pincode_city',
      'delivery_type',
    ],
    actions: [
    ],
    status_filter : defaultRetailStatusFilterOptions,
  },
  retail_failed : {
    name: 'Failed',
    filters : [
      'status',
      'attempt_count',
      'pickup_attempt_count',
      'service_type',
      'load_type',
      'customer_code',
      'consignment_type',
      'proximity',
      'orderType',
      'purchasingGroup',
      'reference_number_search',
      'customer_reference_number_search',
      'store_hub_id',
      'so_number_search',
      'destination_pincode_city',
      'delivery_type',
    ],
    actions: [
    ],
    status_filter : defaultRetailStatusFilterOptions,
  },
  retail_inbound : {
    name: 'Inbound',
    filters : [
      'status',
      'attempt_count',
      'pickup_attempt_count',
      'service_type',
      'load_type',
      'customer_code',
      'consignment_type',
      'proximity',
      'orderType',
      'purchasingGroup',
      'reference_number_search',
      'customer_reference_number_search',
      'store_hub_id',
      'so_number_search',
      'destination_pincode_city',
      'delivery_type',
    ],
    actions: [
    ],
    status_filter : defaultRetailStatusFilterOptions,
  },
};

const bucketColor = {
  retail_all: '#EB2F96',
  retail_planned: '#722ED1',
  retail_unplanned: '#EB2F96',
  retail_in_progress: '#2F54EB',
  retail_completed: '#389E0D',
  retail_failed : '#FA541C',
};

export default { viewControl, buckets, bucketColor };
