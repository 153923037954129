export enum TripManagerMapEditActionTypes {
  TRIP_MANAGER_MAP_EDIT_MAP_VIEW_SWITCH = 'TRIP_MANAGER_MAP_EDIT_MAP_VIEW_SWITCH',
  TRIP_MANAGER_MAP_RESET_MAP_DATA = 'TRIP_MANAGER_MAP_RESET_MAP_DATA',
  TRIP_MANAGER_MAP_EDIT_UPDATE_DRAG_END = 'TRIP_MANAGER_MAP_EDIT_UPDATE_DRAG_END',
  TRIP_MANAGER_MAP_EDIT_UPDATE_DIRECTIONS = 'TRIP_MANAGER_MAP_EDIT_UPDATE_DIRECTIONS',
  TRIP_MANAGER_MAP_EDIT_SET_TRIP_UPDATE_DATA = 'TRIP_MANAGER_MAP_EDIT_SET_TRIP_UPDATE_DATA',
  TRIP_MANAGER_MAP_EDIT_SET_ALL_TRIPS = 'TRIP_MANAGER_MAP_EDIT_SET_ALL_TRIPS',
  TRIP_MANAGER_MAP_EDIT_SET_ALL_UNPLANNED_CNS = 'TRIP_MANAGER_MAP_EDIT_SET_ALL_UNPLANNED_CNS',
  TRIP_MANAGER_MAP_EDIT_ADD_ACTION = 'TRIP_MANAGER_MAP_EDIT_ADD_ACTION',
  TRIP_MANAGER_MAP_EDIT_RESET_SELECTED_MARKERS = 'TRIP_MANAGER_MAP_EDIT_RESET_SELECTED_MARKERS',
  TRIP_MANAGER_MAP_EDIT_SET_SAVE_LOADER = 'TRIP_MANAGER_MAP_EDIT_SET_SAVE_LOADER',
  TRIP_MANAGER_MAP_EDIT_SET_SAVE_FAILURE_ARRAY = 'TRIP_MANAGER_MAP_EDIT_SET_SAVE_FAILURE_ARRAY',
  TRIP_MANAGER_MAP_EDIT_SET_SELECTED_MARKERS = 'TRIP_MANAGER_MAP_EDIT_SET_SELECTED_MARKERS',
  TRIP_MANAGER_MAP_TOGGLE_PARTITIONS = 'TRIP_MANAGER_MAP_TOGGLE_PARTITIONS',
  TRIP_MANAGER_MAP_UPDATE_METRICS = 'TRIP_MANAGER_MAP_UPDATE_METRICS',
  TRIP_MANAGER_MAP_EDIT_SET_ALL_WORKERS = 'TRIP_MANAGER_MAP_EDIT_SET_ALL_WORKERS',
}

export type ActionType = {
  type: TripManagerMapEditActionTypes;
  payload: any;
};
