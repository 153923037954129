import apiProvider from './provider';
import {
  getAuthenticationHeaders,
  getErrorMessage,
} from './utils';
import { API_BASE_URL } from '../globals';
import {
  GET_ALL_RIDER_RECONCILIATION_DATA,
  FETCH_ONE_RIDER_RECONCILIATION, SETTLE_BALANCE,
  RECONCILE_CN,
  FETCH_TRANSACTIONS,
  FETCH_RIDERS,
  DOWNLOAD_RIDER_RECONCIlIATION_TRANSACTIONS,
  RIDER_SEARCH,
  SHIFT_SEARCH,
  WEEK_OFF_RIDER_SEARCH,
  DOWNLOAD_RIDER_RECONCILLATION_WORKER_LEVEL_SUMMARY,
  DOWNLOAD_RIDER_RECONCILLATION_CN_LEVEL_SUMMARY,
  GET_RIDER_WISE_DETAILS, DOWNLOAD_CN_LEVEL_SUMMARY, DOWNLOAD_WORKER_LEVEL_SUMMARY,
  GET_RIDER_RECONCILIATION_DATA, GET_TRIP_RECONCILIATION_DATA, RECONCILE_CN_RIDER,
  RECONCILE_CN_TRIP, SETTLE_CASH_RIDER, SETTLE_CASH_TRIP, DOWNLOAD_CN_SUMMARY_EXCEL,
  DOWNLOAD_CN_SUMMARY_PDF, DOWNLOAD_RIDER_CN_SUMMARY_PDF, DOWNLOAD_RIDER_CN_SUMMARY_EXCEL,
  UPDATE_CN_COD_AMOUNT_COLLECTED,
  GET_ONDEMAND_RECONCILIATION_DATA,
  GET_CONSIGNMENT_RECONCILIATION_DATA,
  RECONCILE_CONSIGNMENTS,
  SETTLE_PAYOUT_RIDER,
  MARK_CONSIGNMENT_EXCEPTION,
  ADD_WORKER_CREDIT_VARIANCE,
} from './routes';
import { requestModelRider, responseModelRider } from '../model/riderReconciliationView';
import { GET, POST } from './apiHandler';
import { morphism } from 'morphism';

export const getAllRidersData = async (hubId) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${GET_ALL_RIDER_RECONCILIATION_DATA}=${hubId}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const fetchOne = async(workerCode:string) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${FETCH_ONE_RIDER_RECONCILIATION}=${workerCode}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const settleBalance = async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${SETTLE_BALANCE}`,
        body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const reconcileCN = async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${RECONCILE_CN}`,
        body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const fetchTransactions = async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${FETCH_TRANSACTIONS}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const fetchRiders = async(hubId) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${FETCH_RIDERS(hubId)}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess:true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const downloadReconciliationData = async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${DOWNLOAD_RIDER_RECONCIlIATION_TRANSACTIONS}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response,
      isSuccess:true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const downloadCNLevelSummaryData = async (body) => {
  return POST(`${API_BASE_URL}${DOWNLOAD_RIDER_RECONCILLATION_CN_LEVEL_SUMMARY}`, body, await getAuthenticationHeaders() , true);
};
export const downloadWorkerLevelSummaryData = async (body) => {
  return POST(`${API_BASE_URL}${DOWNLOAD_RIDER_RECONCILLATION_WORKER_LEVEL_SUMMARY}`, body, await getAuthenticationHeaders() , true);
};
export const getRiderWiseDetails = async (params) => {
  const response = await GET(
    `${API_BASE_URL}${GET_RIDER_WISE_DETAILS}`,
    { ...morphism(requestModelRider, params) },
  );
  return response.isSuccess ?
    {
      data: morphism(responseModelRider, response.data),
      isSuccess: true,
    } : response;
};

export const riderSearch = async (params) => {
  return GET(`${API_BASE_URL}${RIDER_SEARCH}`, params);
};

export const shiftSearch = async (params) => {
  return GET(`${API_BASE_URL}${SHIFT_SEARCH}`, params);
};

export const weekOffRiderSearch = async (params) => {
  return GET(`${API_BASE_URL}${WEEK_OFF_RIDER_SEARCH}`, params);
};

export const downloadCNLevelSummary = async (params) => {
  return GET(`${API_BASE_URL}${DOWNLOAD_CN_LEVEL_SUMMARY}`,
    { ...morphism(requestModelRider, params) });
};

export const downloadWorkerLevelSummary = async (params) => {
  return GET(`${API_BASE_URL}${DOWNLOAD_WORKER_LEVEL_SUMMARY}`,
    { ...morphism(requestModelRider, params) });
};

export const getRiderReconciliationData = async (params) => {
  return GET(`${API_BASE_URL}${GET_RIDER_RECONCILIATION_DATA}`, params);
};

export const getTripReconciliationData = async (params) => {
  return GET(`${API_BASE_URL}${GET_TRIP_RECONCILIATION_DATA}`, params);
};

export const reconcileCNRider = async (body) => {
  return POST(`${API_BASE_URL}${RECONCILE_CN_RIDER}`, body);
};

export const reconcileCNTrip = async (body) => {
  return POST(`${API_BASE_URL}${RECONCILE_CN_TRIP}`, body);
};

export const settleCashRider = async(body) => {
  return POST(`${API_BASE_URL}${SETTLE_CASH_RIDER}`, body);
};

export const settleCashTrip = async(body) => {
  return POST(`${API_BASE_URL}${SETTLE_CASH_TRIP}`, body);
};

export const downloadCNSummaryExcel = async(params) => {
  return GET(`${API_BASE_URL}${DOWNLOAD_CN_SUMMARY_EXCEL}`, params);
};

export const downloadCNSummaryPdf = async(params) => {
  return GET(`${API_BASE_URL}${DOWNLOAD_CN_SUMMARY_PDF}`, params, 'blob');
};

export const downloadRiderCNSummaryPdf = async(params) => {
  return GET(`${API_BASE_URL}${DOWNLOAD_RIDER_CN_SUMMARY_PDF}`, params, 'blob');
};

export const downloadRiderCNSummaryExcel = async(params) => {
  return GET(`${API_BASE_URL}${DOWNLOAD_RIDER_CN_SUMMARY_EXCEL}`, params);
};

export const updateCodAmountCollected = async(body) => {
  return POST(`${API_BASE_URL}${UPDATE_CN_COD_AMOUNT_COLLECTED}`, body);
};

export const getOndemandReconciliationData = async (body) => {
  return POST(`${API_BASE_URL}${GET_ONDEMAND_RECONCILIATION_DATA}`, body);
};

export const getConsignmentReconciliationData = async (body) => {
  return POST(`${API_BASE_URL}${GET_CONSIGNMENT_RECONCILIATION_DATA}`, body);
};

export const reconcileConsignments = async (body) => {
  return POST(`${API_BASE_URL}${RECONCILE_CONSIGNMENTS}`, body);
};

export const settlePayoutRider = async(body) => {
  return POST(`${API_BASE_URL}${SETTLE_PAYOUT_RIDER}`, body);
};

export const markConsignmentException = async (body) => {
  return POST(`${API_BASE_URL}${MARK_CONSIGNMENT_EXCEPTION}`, body);
};

export const addVariance = async (body) => {
  return POST(`${API_BASE_URL}${ADD_WORKER_CREDIT_VARIANCE}`, body);
};
