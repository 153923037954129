import { getBookingMastersTableData } from '../../api/BookingMasters';
import { deleteAllocationRules } from '../../api/CarrierPartner';
const viewControl = {
  pageTitle: 'Setup',
  buckets: ['courierPricing', 'courierTat', 'courierDestinationType', 'courierHoliday', 'courierServiceability', 'courierTracking', 'courierFieldMaster', 'carrierPartnerCarriers'],
  globalActions: [],
  fetchData: getBookingMastersTableData,
  bulkDeleteMultiple: deleteAllocationRules,
};
const buckets = {
  courierPricing: {
    name: 'Rate Master',
    filters: ['customer_code', 'service_type', 'hub_id', 'charge_id'],
    actions: [
      {
        key: 'bulk_delete',
        name: 'Bulk Delete',
      },
      {
        key: 'update',
        name: 'Update',
      },
      {
        key: 'other_filters',
        name: 'Apply Filters',
      },
      {
        key: 'pagination',
        name: 'Pagination',
      },
      {
        key: 'download',
        name: 'Download',
      },
      {
        key: 'reload',
        name: 'Reload',
      },
    ],
  },
  courierTat: {
    name: 'TAT Master',
    filters: ['charge_id'],
    actions: [
      {
        key: 'bulk_delete',
        name: 'Bulk Delete',
      },
      {
        key: 'update',
        name: 'Update',
      },
      {
        key: 'pagination',
        name: 'Pagination',
      },
      {
        key: 'download',
        name: 'Download',
      },
      {
        key: 'reload',
        name: 'Reload',
      },
    ],
  },
  courierDestinationType: {
    name: 'Destination Type',
    actions: [
      {
        key: 'bulk_delete',
        name: 'Bulk Delete',
      },
      {
        key: 'update',
        name: 'Update',
      },
      {
        key: 'pagination',
        name: 'Pagination',
      },
      {
        key: 'download',
        name: 'Download',
      },
      {
        key: 'reload',
        name: 'Reload',
      },
    ],
  },
  courierServiceability: {
    name: 'Serviceability Master',
    actions: [
      {
        key: 'bulk_delete',
        name: 'Bulk Delete',
      },
      {
        key: 'update',
        name: 'Update',
      },
      {
        key: 'pagination',
        name: 'Pagination',
      },
      {
        key: 'download',
        name: 'Download',
      },
      {
        key: 'reload',
        name: 'Reload',
      },
    ],
  },
  courierHoliday: {
    name: 'Holiday Master',
    filters: ['charge_id'],
    actions: [
      {
        key: 'bulk_delete',
        name: 'Bulk Delete',
      },
      {
        key: 'update',
        name: 'Update',
      },
      {
        key: 'pagination',
        name: 'Pagination',
      },
      {
        key: 'download',
        name: 'Download',
      },
      {
        key: 'reload',
        name: 'Reload',
      },
    ],
  },
  advancedSettings: {
    name: 'Advanced Settings',
    filters: [],
    actions: [],
  },
  courierFieldMaster: {
    name: 'Carrier Field Masters',
    filters: [],
    actions: [],
  },
  courierTracking: {
    name: 'Carrier Tracking',
    filters: [],
    actions: [],
  },
  carrierPartnerCarriers: {
    name: 'Carrier Capacity',
    filters: [],
    actions: [],
  },
};

const bucketColor = {};

export default { viewControl, buckets, bucketColor };
