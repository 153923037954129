import { getAllTmsDataBag } from '../../api/TMSDashboard/Bagview';
const viewControl = {
  pageTitle : 'Bags',
  buckets : [
    'tms_bag_all',
  ],
  navBar : {
    left : [],
    right : [],
  },
  fetchData : getAllTmsDataBag,
  dateFilterOptions : [
    { key:'status_update_time', val:'Last Updated' },
    { key:'created_at', val:'Creation Date' },
  ],
  searchFilterOptions : [
    { key:'organisation_vehicle_number', val: 'Vehicle No.' },
    { key:'organisation_reference_number', val: 'Trip No.' },
  ],
};
const buckets = {
  tms_bag_all: {
    name: 'Bag',
    filters : [
      'exceptions',
      'status',
      'courier',
      'isHandedOver',
      'bagType',
    ],
    actions: [
    ],
    status_filter : [
      'sealed',
      'inscan_at_hub',
      'softdata_upload',
      'intransit_to_hub',
      'outscan_at_hub',
      'loaded',
      'created',
      'intransittohub',
      'all_consignment_received',
      'debagged',
      'added_to_ist',
      'received_against_ist',
    ],
    bag_type_filter_list: [
      'hub_to_hub',
      'address_to_hub',
      'hub_to_address',
      'address_to_address',
    ],
  },
};
export default { viewControl, buckets };
