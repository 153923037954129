import { KeyOutlined } from '@ant-design/icons';
import { Tooltip  } from 'antd';
import React from 'react';
import withStyles, { WithStylesProps } from 'react-jss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import APIKey from 'src/components/pages/OpsDashboard/Setup/CustomerManagementModule/APIKey';

const styles = () => ({});

interface IProps extends
    WithStylesProps<ReturnType<typeof styles>>,
    ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {
        customerData: any;
}

const CustomerSoftdataApiKeyTableAction = (props: IProps) => {
    const {
        classes,
        customerData,
    } = props;

    const [visible, setVisible] = React.useState(false);

    return <>
        <Tooltip title="Display Softdata API Key">
            <KeyOutlined onClick={() => {
                setVisible(true);
            }} />
        </Tooltip>
        {
            visible ? <APIKey
                customerId={customerData?.id}
                onClose={() => setVisible(false)}
            /> : <></>
        }
    </>;
};

const mapStateToProps = ({ genericPageReducer }, ownProps) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(
    withStyles(styles, { injectTheme: true })(CustomerSoftdataApiKeyTableAction),
);
