import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  limit,
  startAfter,
  getDoc,
  doc,
  setDoc,
  updateDoc,
  writeBatch,
  arrayRemove,
  arrayUnion,
} from 'firebase/firestore';
import { firestore } from 'src/firebase';
import stringify from 'json-stable-stringify';
import crypto from 'crypto';
import {USER_NOTIFICATION_COUNT_COLLECTION, NOTIFICATION_COLLECTION, USER_PREFERENCE_COLLECTION} from './notificationConstants';

const generateHash = (obj) => {
  const str = stringify(obj);
  const hash = crypto.createHash('sha1').update(str).digest('hex');
  return hash;
};

export const getNotificationData = async (params) => {
  const {
    lastNotificationId = '',
    userId,
    organisationId,
    userType = 'employee',
  } = params;
  const notificationsCollection = collection(firestore, NOTIFICATION_COLLECTION);
  const limitVal = 10;
  const userPreferenceCollection = collection(firestore, USER_PREFERENCE_COLLECTION);
  const userNotificationsQuery = query(
    userPreferenceCollection,
    where('userId', '==', userId),
    where('userType', '==', userType),
    where('organisationId', '==', organisationId),
  );
  const userPreferenceSnapshot = await getDocs(userNotificationsQuery);
  let showOnlyUnread = true;
  let allowedServiceNotifications;
  if (!userPreferenceSnapshot.empty) {
    const userPreferenceData = userPreferenceSnapshot.docs[0].data();
    showOnlyUnread = userPreferenceData.showOnlyUnread;
    allowedServiceNotifications =
      userPreferenceData.allowedServiceNotifications;
  }
  let notificationsQuery = query(
    notificationsCollection,
    where('userId', '==', userId),
    where('userType', '==', 'employee'),
    where('organisationId', '==', organisationId),
    where('isActive', '==', true),
  );
  if (allowedServiceNotifications && allowedServiceNotifications.length === 0) {
    return [];
  }
  if (showOnlyUnread) {
    notificationsQuery = query(
      notificationsQuery,
      where('isReadAll', '==', false),
    );
  }
  if (allowedServiceNotifications) {
    notificationsQuery = query(
      notificationsQuery,
      where('sourceName', 'in', allowedServiceNotifications),
    );
  }
  if (lastNotificationId) {
    const lastVisibleRef = await getDoc(
      doc(firestore, NOTIFICATION_COLLECTION, lastNotificationId),
    );
    notificationsQuery = query(
      notificationsQuery,
      orderBy('updatedAt', 'desc'),
      startAfter(lastVisibleRef),
      limit(limitVal),
    );
  } else {
    notificationsQuery = query(
      notificationsQuery,
      orderBy('updatedAt', 'desc'),
      limit(limitVal),
    );
  }
  const notifications = [];
  const notificationSnapshots = await getDocs(notificationsQuery);
  notificationSnapshots.forEach((doc) => {
    const notificationData = doc.data();
    const notification = {
      id: doc.id,
      ...notificationData,
    };
    // if show only unread notifications
    // then it must be sorted by created at
    // also remove the notification history whose id is not present in the unreadIds array of notification
    if (showOnlyUnread) {
      notification.notificationHistory =
        notification.notificationHistory.filter((history) => {
          return notification.unreadIds.includes(history.id);
        });
      notification.notificationHistory.sort(
        (a, b) => b.createdAt - a.createdAt,
      );
    } else {
      // if show all notifications
      // sort the notification history in descending order of createdAt
      notification.notificationHistory.sort(
        (a, b) => b.createdAt - a.createdAt,
      );
    }
    notifications.push(notification);
  });
  return notifications;
};

export const getUserPreference = async (params) => {
  const { userId, organisationId, userType = 'employee' } = params;
  const userPreferenceCollection = collection(firestore, USER_PREFERENCE_COLLECTION);
  const userNotificationsQuery = query(
    userPreferenceCollection,
    where('userId', '==', userId),
    where('userType', '==', userType),
    where('organisationId', '==', organisationId),
  );
  const userPreferenceSnapshot = await getDocs(userNotificationsQuery);
  if (userPreferenceSnapshot.empty) {
    return {};
  }
  const userPreference = userPreferenceSnapshot.docs[0].data();
  return userPreference;
};

export const readBellNotification = async (params) => {
  const { userId, organisationId, userType = 'employee' } = params;
  try {
    const notificationsCountCollection = collection(
      firestore,
      USER_NOTIFICATION_COUNT_COLLECTION,
    );
    const id = generateHash({ userId, userType, organisationId });
    const docRef = doc(notificationsCountCollection, id);

    const notificationsCountQuerySnapshot = await getDoc(docRef);

    if (notificationsCountQuerySnapshot.exists()) {
      // Update the count of the document to 0
      await updateDoc(docRef, { count: 0 });
    } else {
      // If there is no document with the specified ID, create a new document
      await setDoc(docRef, {
        count: 0,
        userId,
        userType,
        organisationId,
      });
    }
  } catch (error) {
    console.log('Error updating notification count: ', error);
  }
};
const validateAllowRead = (
  isRead,
  notificationData,
  userId,
  notificationHistoryId,
) => {
  if (!notificationData) {
    return {
      error: 'Invalid notificationId',
      code: 400,
    };
  }
  if (notificationData.userId !== userId) {
    return {
      error: 'Invalid userId',
      code: 400,
    };
  }
  if (
    notificationData.notificationHistory.findIndex(
      (history) => history.id === notificationHistoryId,
    ) === -1
  ) {
    return {
      error: 'Invalid notificationHistoryId',
      code: 400,
    };
  }
  if (
    isRead &&
    notificationData.unreadIds.findIndex(
      (id) => id === notificationHistoryId,
    ) === -1
  ) {
    return {
      error: 'Notification already read',
      code: 400,
    };
  }
  if (
    !isRead &&
    notificationData.unreadIds.findIndex(
      (id) => id === notificationHistoryId,
    ) !== -1
  ) {
    return {
      error: 'Notification already unread',
      code: 400,
    };
  }
  return null;
};

export const readNotification = async (props) => {
  const {
    organisationId,
    userId,
    userType='employee',
    notificationId,
    notificationHistoryId,
    isRead,
    isReadAll,
  } = props;
  try {
    if (isReadAll) {
      const batch = writeBatch(firestore);
      const notificationsCollection = collection(firestore, NOTIFICATION_COLLECTION);
      const notificationsQuery = query(
        notificationsCollection,
        where('userId', '==', userId),
        where('userType', '==', userType),
        where('organisationId', '==', organisationId),
        where('isActive', '==', true),
        where('isReadAll', '==', false),
      );
      const notificationSnapshots = await getDocs(notificationsQuery);
      notificationSnapshots.forEach((documnent) => {
        const notificationRef = doc(notificationsCollection, documnent.id);
        batch.update(notificationRef, {
          unreadIds: [],
          isReadAll: true,
        });
      });
      await batch.commit();
    } else {
      if (!notificationId || !notificationHistoryId) {
        console.log('Invalid notificationId or notificationHistoryId');
      }
      const notificationRef = doc(
        collection(firestore, NOTIFICATION_COLLECTION),
        notificationId,
      );
      const notificationSnapshot = await getDoc(notificationRef);
      const notificationData = notificationSnapshot.data();
      // validate if the notification History Id exists in the notificationHistory array
      const error = validateAllowRead(
        isRead,
        notificationData,
        userId,
        notificationHistoryId,
      );
      if (!error) {
        let isReadAllNotifications = notificationData.isReadAll;
        // if the notification is to be marked as read and the unreadIds array has only one element,
        // then the notification isReadAll should be set to true
        if (isRead && notificationData.unreadIds.length === 1) {
          isReadAllNotifications = true;
        }
        // if the notification is to be marked as unread and isReadAllNotifications is true,
        // then the notification isReadAll should be set to false
        if (!isRead && isReadAllNotifications) {
          isReadAllNotifications = false;
        }
        const unreadIds = isRead
          ? arrayRemove(notificationHistoryId)
          : arrayUnion(notificationHistoryId);
        await updateDoc(notificationRef, {
          unreadIds,
          isReadAll: isReadAllNotifications,
        });
      } else {
        console.log('error', error);
      }
    }
  } catch (error) {
    console.log('Error updating notifications: ', error);
  }
};
