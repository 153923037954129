import * as React from 'react';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '../../theming/jssTypes';
import { Modal, Table } from 'antd';
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';

const styles = (theme: ThemeType): CssType => ({
  tableContainer: {
    flex: '1 1 0',
    overflow: 'auto',
    height: 300,
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
    '& .ant-table-tbody > tr > td, .ant-table-thead > tr > th': {
      padding: 9,
    },
  },
});

const InvoiceListModal = (props) => {
  const { t } = useTranslation();
  const generateDataColumns = () => {
    const columnData: any = [
      {
        title: t('S.No.'),
        key: 'index',
        render: (text, record, index) => index + 1,
        width: 30,
      },
      {
        title: t('Invoice No.'),
        key: 'invoiceNumber',
        width: 10,
        render: (text, record) => record['invoiceNumber'] ?? '',
      },
      {
        title: t('Invoice Amount'),
        key: 'invoiceValue',
        width: 30,
        render: (text, record) => record['invoiceValue'] ?? '',
      },
      {
        title: t('Invoice Date'),
        key: 'invoiceDate',
        width: 30,
        render: (text, record) =>
          record['invoiceDate']
            ? moment(record['invoiceDate']).format('DD MMM YYYY')
            : '',
      },
      {
        title: t('EWB Number'),
        key: 'ewbNumber',
        width: 30,
        render: (text, record) => record['ewbNumber'] ?? '',
      },
    ];
    return columnData;
  };

  const { isVisible, classes, invoiceList } = props;
  const header = 'Invoice List';

  return (
    <Modal
      title={t(header)}
      width={500}
      visible={isVisible}
      footer={null}
      onCancel={() => props.handleModalClose(false)}
      destroyOnClose
    >
      <div className={classes.tableContainer}>
        <Table
          locale={{ emptyText: t('No Invoices') }}
          pagination={false}
          columns={generateDataColumns()}
          dataSource={invoiceList}
        />
      </div>
    </Modal>
  );
};

export default withStyles(styles, { injectTheme: true })(InvoiceListModal);
