import React from 'react';
import { ViewActionType } from 'src/types/view.types';
import consignmentActions from './GenericData/consignment/actionsData';
import customerActions from './GenericData/customer/actionsData';
import GenericActionComponent from './GenericData/GenericActionComponent';

const actionsDataMap = {
    consignment: consignmentActions,
    customer: customerActions,
};

export const actionResolver = (action: ViewActionType, objectType, params) => {
    const data = actionsDataMap[objectType] || {};
    const actionId = action.id;

    if (data[actionId]) {
        return <GenericActionComponent
            actionDetails={data[actionId]}
            action={action}
            params={params}
        />;
    }

    return null;
};
