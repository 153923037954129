import * as React from 'react';
import withStyles, { WithStylesProps } from 'react-jss';
import { ThemeType } from '../../theming/jssTypes';
import { RightOutlined } from '@ant-design/icons';

const styles = (theme: ThemeType) => ({
  resizer: {
    cursor: 'move',
    userSelect: 'none',
    boxShadow: '3px 0px 10px 1px #ccc',
    display: 'flex',
    alignItems: 'center',
    zIndex: 1,
  },
  resizerBox: {
    position: 'absolute',
    width: '15px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopRightRadius: '3px',
    borderBottomRightRadius: '3px',
    backgroundColor: 'white',
    boxShadow: '3px 0px 7px 3px #ccc',
  },
});

interface IProps extends WithStylesProps<typeof styles> {
  left: any;
  right: any;
  leftDefaultSize?: any;
  onDragEnd?: () => void;
}

const SplitPanes = function (props: IProps) {
  const {
    classes,
    left: LeftComponent,
    right: RightComponent,
    onDragEnd,
    leftDefaultSize,
  } = props;

  const dividerRef = React.useRef<HTMLDivElement>();
  let x = 0;
  let y = 0;
  let leftWidth = 0;

  const mouseMoveHandler = (e: MouseEvent) => {
    if (!dividerRef.current) {
      return;
    }
    const resizer = dividerRef.current;
    // How far the mouse has been moved
    const dx = e.clientX - x;
    const dy = e.clientY - y;
    const leftSide = resizer.previousElementSibling as HTMLDivElement;
    const rightSide = resizer.nextElementSibling as HTMLDivElement;
    const newLeftWidth =
      ((leftWidth + dx) * 100) /
      resizer.parentElement.getBoundingClientRect().width;
    leftSide.style.width = `${newLeftWidth}%`;
    resizer.style.cursor = 'col-resize';
    document.body.style.cursor = 'col-resize';
    leftSide.style.userSelect = 'none';
    leftSide.style.pointerEvents = 'none';
    rightSide.style.userSelect = 'none';
    rightSide.style.pointerEvents = 'none';
  };

  const mouseUpHandler = (e: MouseEvent) => {
    if (!dividerRef.current) {
      return;
    }
    const resizer = dividerRef.current;
    const leftSide = resizer.previousElementSibling as HTMLDivElement;
    const rightSide = resizer.nextElementSibling as HTMLDivElement;

    resizer.style.removeProperty('cursor');
    document.body.style.removeProperty('cursor');

    leftSide.style.removeProperty('user-select');
    leftSide.style.removeProperty('pointer-events');

    rightSide.style.removeProperty('user-select');
    rightSide.style.removeProperty('pointer-events');

    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);
    if (onDragEnd) {
      onDragEnd();
    }
  };

  const handleMouseDown: React.MouseEventHandler = (e) => {
    if (!dividerRef.current) {
      return;
    }
    const resizer = dividerRef.current;
    const leftSide = resizer.previousElementSibling;
    x = e.clientX;
    y = e.clientY;
    leftWidth = leftSide.getBoundingClientRect().width;

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };

  return (
    <div
      style={{ display: 'flex', flexDirection: 'row', width: '100%', flex: 1 }}
    >
      <div style={{ width: leftDefaultSize }}>{LeftComponent}</div>
      <div
        className={classes.resizer}
        ref={dividerRef}
        onMouseDown={handleMouseDown}
      >
        <div className={classes.resizerBox}>
          <RightOutlined size={10} />
        </div>
      </div>
      <div style={{ flex: '1 1 0%' }}>{RightComponent}</div>
    </div>
  );
};

SplitPanes.defaultProps = {
  onDragEnd: null,
  leftDefaultSize: '50%',
};

export default withStyles(styles, { injectTheme: true })(SplitPanes);
