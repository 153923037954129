import { InfoCircleFilled, WarningFilled } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React from 'react';
import withStyles from 'react-jss';
import { warningConfig } from './masterData';
import styles from './styles';

const PasswordWarning = (props: any) => {
  const { classes, type, handleSkip, handleChangePassword, isVisible } = props;
  const config = warningConfig[type];
  return (
    <Modal
      visible={isVisible}
      closable={false}
      maskClosable={false}
      className={classes.modal}
      centered={true}
      width={'400px'}
      maskStyle={{ backgroundColor: '#FFFFFF' }}
      footer={
        <div className={classes.warningFooter}>
          <Button key="submit" type="primary" onClick={handleChangePassword}>
            {type === 'firstLogin' ? "Set Password" : "Change Password"}
          </Button>
          {config.allowSkip && (
            <div className={classes.skipWarning} onClick={handleSkip}>
              Skip
            </div>
          )}
        </div>
      }
    >
      <div className={classes.warningModal}>
        {config.icon === 'info' ? (
          <InfoCircleFilled
            style={{ color: `${config.color}`, fontSize: '50px' }}
          />
        ) : (config.icon === 'warning' ? (
          <WarningFilled
            style={{ color: `${config.color}`, fontSize: '50px' }}
          />
        ) : null)}
        <div className={classes.warningHeading}>{config.heading}</div>
        <div>{config.description}</div>
      </div>
    </Modal>
  );
};
export default withStyles(styles, { injectTheme: true })(PasswordWarning);
