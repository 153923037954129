import { getAllTripsData, getSummaryMetricsData } from '../../api/trips';
const viewControl = {
  buckets : [
    'all',
    'created',
    'assigned',
    'started',
    'completed',
  ],
  fetchData : getAllTripsData,
  fetchSummaryMetrics : getSummaryMetricsData,
};

const buckets = {
  all : {
    name:'All',
    color:'#7047BC',
    filters : [],
    actions: ['downloads'],
    status_filter : [],
  },
  created :{
    name:'Created',
    color:'#7047BC',
    filters : [],
    actions: [
      'delete',
    ],
    status_filter : [],
  },
  assigned : {
    name:'Assigned',
    color:'#7CBE28',
    filters : [
      'vehicle_type',
    ],
    actions: [
      'delete',
    ],
    status_filter : [],
  },
  started : {
    name:'Started',
    color:'#4782BC',
    filters : [
      'vehicle_type',
    ],
    actions: [
    ],
    status_filter : [],
  },
  completed : {
    name:'Completed',
    color:'#47BC9C',
    filters : [
      'vehicle_type',
    ],
    actions: [],
    status_filter : [],
  },
};
const bucketColor = {
  created : '#7047BC',
  assigned : '#7CBE28',
  started : '#4782BC',
  completed : '#47BC9C',
};

export default { viewControl, bucketColor, buckets };
