import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import Unauthorized from '@components/pages/Unauthorized';
import { trackPageView } from 'src/utils/googleAnalyticsHelper';
import { userpilotIdentify, userpilotReload } from 'src/utils/userpilotHelper';

const ProtectedRoute = (props) => {
  const { isAllowed = null, masterData, path } = props;
  if (isAllowed === false) {
    return <Unauthorized />;
  }
  if(path && masterData.user_data?.username){
    trackPageView(masterData.user_data.username, path)
    userpilotIdentify(masterData.user_data)
    userpilotReload(masterData.user_data)
  }
  return <Route {...props} />;
};

const mapStateToProps = ({ masterData }) => {
  return { masterData };
};
export default connect(mapStateToProps)(ProtectedRoute);
