import Icon from '@ant-design/icons';
import * as React from 'react';
const ExcelFileIconsvg = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    dataName="Layer 1"
    viewBox="0 0 118 150"
    {...props}
  >
    <defs>
      <style>
        {'.xls-cls-1{fill:#02733a;}.xls-cls-2{fill:#4d9d76;}.xls-cls-3{fill:#fff;}'}
      </style>
    </defs>
    <path
      className="xls-cls-1"
      d="M80.09,43.76a6,6,0,0,1-6-6V0h-59A15.08,15.08,0,0,0,0,15V135a15.08,15.08,0,0,0,15.06,15h87.88A15.08,15.08,0,0,0,118,135V43.76Z"
    />
    <path
      className="xls-cls-2"
      d="M79.57,43.73h38.18L74,0V38.19A6,6,0,0,0,79.57,43.73Z"
    />
    <path
      className="xls-cls-3"
      d="M26.05,117.36a3.73,3.73,0,1,1-3.59,3.75A3.59,3.59,0,0,1,26.05,117.36Z"
    />
    <path
      className="xls-cls-3"
      d="M38.79,113.93,33,104.34h6.37l1.78,3.22c.52,1.13,1.13,2.3,1.69,3.43H43c.41-1.13.93-2.3,1.38-3.43l1.37-3.22H51.9l-5.81,10.28,6.17,9.72H45.89L44,121c-.64-1.13-1.25-2.34-1.89-3.47H41.9c-.49,1.13-1,2.3-1.54,3.47l-1.57,3.31H32.66Z"
    />
    <path
      className="xls-cls-3"
      d="M55.45,96.07h5.93v22.3c0,1.25.56,1.65,1,1.65a2.5,2.5,0,0,0,.73-.08l.73,4.4a7.84,7.84,0,0,1-3,.48c-4.07,0-5.44-2.66-5.44-6.69Z"
    />
    <path
      className="xls-cls-3"
      d="M67.87,118.33a8.65,8.65,0,0,0,5.16,2.1c1.78,0,2.54-.65,2.54-1.7,0-1.33-2-1.93-4.07-2.74-2.42-.93-5.28-2.58-5.28-5.89,0-3.71,3-6.25,7.66-6.25a11.54,11.54,0,0,1,7.06,2.54l-2.66,3.55A7.4,7.4,0,0,0,74,108.25c-1.53,0-2.26.56-2.26,1.57,0,1.29,1.86,1.78,3.91,2.54,2.54.93,5.41,2.34,5.41,6s-2.87,6.45-8.23,6.45A12.94,12.94,0,0,1,65.21,122Z"
    />
  </svg>
);

const ExcelFileIcon = (props: any) => (
  <Icon component={ExcelFileIconsvg} {...props} />
);

export default ExcelFileIcon;
