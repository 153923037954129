import { TimeFilterOption } from '../components/pages/genericTransactionsHistory/interfaces/TimeFilterOption';
import moment from 'moment';

const pagination = {
  descendingOrder: 'descendingOrder',
  nextOrPrev: 'nextOrPrev',
  pageNo: 'pageNo',
  paginationParams: 'paginationParams',
  resultsPerPage: 'resultsPerPage',
};

const requestModelBankHistory = {
  ...pagination,
  hub_id: 'hubId',
  bank_deposit_date: {
    path: 'depositDate',
    fn: (value) => {
      return getDateValues(value);
    },
  },
  transaction_date: {
    path: 'transactionDate',
    fn: (value) => {
      return getDateValues(value);
    },
  },
  type: 'type',
};

const requestModelBmDashboard = {
  ...pagination,
  hubId: 'hubId',
  status: 'transactionType',
  source: 'cashSource',
  mode: 'type',
  workerIdList: 'selectedRider',
  fromDate: 'FromDate',
  toDate: 'ToDate',
  employeeDetails: 'employeeDetails',
  consignmentNumbers: 'consignmentNumbers',
};
const requestModelRemittance = {
  ...pagination,
  timeFilters: (iteratee) => {
    return getTimeFiltersRemittance(iteratee);
  },
  customerIdList: 'selectedCustomer',
};

const requestModelCarrierHistory = {
  ...pagination,
  hubId: 'hubId',
  transaction_date: {
    path: 'transactionDate',
    fn: (value) => {
      return getDateValues(value);
    },
  },
  bank_deposit_date: {
    path: 'depositDate',
    fn: (value) => {
      return getDateValues(value);
    },
  },
  courier_account_list: 'selectedCarrierAccount',
  transaction_type: 'view_type',
};

const requestModelCarrierHistoryDownload = {
  transaction_date_start: {
    path: 'transactionDate',
    fn: (value) => {
      return getDateValuesDownload(value, 'startDate');
    },
  },
  transaction_date_end: {
    path: 'transactionDate',
    fn: (value) => {
      return getDateValuesDownload(value, 'endDate');
    },
  },
  bank_deposit_date_start: {
    path: 'depositDate',
    fn: (value) => {
      return getDateValuesDownload(value, 'startDate');
    },
  },
  bank_deposit_date_end: {
    path: 'depositDate',
    fn: (value) => {
      return getDateValuesDownload(value, 'endDate');
    },
  },
  courier_account_list: 'selectedCarrierAccount',
};
const getDateValues = (dateObj) => {
  return dateObj.allowDate ? [dateObj.startDate, dateObj.endDate] : [];
};

const getDateValuesDownload = (dateObj, type) => {
  return dateObj.allowDate
    ? moment(dateObj[type]).format('YYYY-MM-DD')
    : undefined;
};

const getDateObject = (date) => {
  return {
    fromDateString: moment(date.startDate).format('YYYY-MM-DD'),
    toDateString: moment(date.endDate).format('YYYY-MM-DD'),
    filterName: date.dateType,
  };
};

const getTimeFiltersRemittance = (iteratee) => {
  const arr: TimeFilterOption[] = [];
  if (iteratee?.depositDate?.allowDate) {
    arr.push(getDateObject(iteratee.depositDate));
  }
  if (iteratee?.transactionDate?.allowDate) {
    arr.push(getDateObject(iteratee.transactionDate));
  }
  return arr;
};

export {
  requestModelBankHistory,
  requestModelRemittance,
  requestModelBmDashboard,
  requestModelCarrierHistory,
  requestModelCarrierHistoryDownload,
};
