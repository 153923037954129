import * as React from 'react';
import withStyles from 'react-jss';
import { connect } from 'react-redux';
import '@ant-design/compatible/assets/index.css';
import { DownloadOutlined } from '@ant-design/icons';
import { handleDownloadDump } from '../../../utils/utils';
import { Row, Col, Timeline, Table, Button, message } from 'antd';
import * as moment from 'moment';
import { styles } from './TimelineWithStopsStyle';

const TimelineWithStops = (props: any) => {
  const { classes, data } = props;

  const processDataForDistance = [];
  if (data && Array.isArray(data.tripLogs) && data.tripLogs.length) {
    for (let index = 0; index < data.tripLogs.length; index++) {
      const item = data.tripLogs[index];
      if (item.event_type === 'started') {
        continue;
      }
      if (processDataForDistance.length === 0) {
        processDataForDistance.push({
          src: 'Trip Start',
          dstn: item.reference_number,
          attempt_distance: item.attempt_distance || '',
          tracking_distance: item.tracking_distance || '',
        });
      } else {
        processDataForDistance.push({
          dstn: item.reference_number ? item.reference_number :
            (item.event_type === 'completed' ? 'Trip End' : ''),
          src: data.tripLogs[index - 1].reference_number,
          attempt_distance: item.attempt_distance || '',
          tracking_distance: item.tracking_distance || '',
        });
      }
    }
  }

  const defaultColumns = [
    {
      title: 'CN Route',
      dataIndex: 'cn_route',
    },
    {
      title: 'Attempt Distance (KM)',
      dataIndex: 'attempt_distance',
    },
    {
      title: 'Tracking Distance (KM)',
      dataIndex: 'tracking_distance',
    },
  ];
  const generateColumnForTable = () => {
    const columnData: object[] = [];
    const indexList = defaultColumns;
    indexList.forEach((item) => {
      const itemToPush = {
        title: item.title,
        dataIndex: item.dataIndex,
        render: (text, record) => {
          if (item.dataIndex === 'cn_route') {
            return (
              <Timeline>
                <Timeline.Item color="blue" key={Math.random()} style={{height: '20px'}}>
                  {record['src']}
                </Timeline.Item>
                <Timeline.Item color="blue" key={Math.random()} style={{height: '20px'}}>
                  {record['dstn']}
                </Timeline.Item>
              </Timeline>
            );
          }
          return record[item.dataIndex] || '';
        },
      };

      columnData.push(itemToPush);
    });

    return columnData;
  };

  const downloadTripLogs = async () => {
    if (data && Array.isArray(data.tripLogs) && data.tripLogs.length) {
      const tripLogsData = data.tripLogs.map((item) => {
        return {
          event_type: item.event_type,
          event_time: item.event_time,
          reference_number: item.reference_number,
          lat: item.lat,
          lng: item.lng,
          attempt_distance: item.attempt_distance,
          tracking_distance: item.tracking_distance,
        };
      });
      await handleDownloadDump(tripLogsData, 'tripLogs.xlsx');
    }
    else {
      message.error('No logs found');
    }
  };

  const eventsList = data &&
    Array.isArray(data.tripLogs) &&
    data.tripLogs.length ? data.tripLogs.map((event) => {
    return (
      <Timeline.Item color="blue" key={Math.random()} style={{marginLeft: '10px'}}>
        <Row key={Math.random()}>
          <Col>
            {event.event_type === 'started' || event.event_type === 'completed' ? (
              <>
                <Row className={classes.eventTime} key={Math.random()}>
                  {moment(event.event_time).format('DD MMM, YYYY, HH:mm A')}
                </Row>
                <Row className={classes.eventName} key={Math.random()}>
                  {event.event_type === 'started' ? 'Trip Start' : 'Trip End'}
                </Row>
              </>
            ) : (
              <>
                <Row className={classes.eventTime} key={Math.random()}>
                  {moment(event.task_completion_time).format('DD MMM, YYYY, HH:mm A')}
                </Row>
                <Row className={classes.eventName} key={Math.random()}>
                  {event.reference_number} - {event.task_status}
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Timeline.Item>
    );
  }) : [];

  return (
    <div className={classes.tableContainer}>
      <div className={classes.timeline}>
        {Array.isArray(eventsList) && eventsList.length ? (<Timeline>{eventsList}</Timeline>) : 'No events found'}
      </div>
      <div className={classes.timelineWithLogs}>
        {
          Array.isArray(processDataForDistance) && processDataForDistance.length ? (
            <>
              <div className={classes.download}>
                <div className={classes.downloadText}>
                  Click to download tracking data
                  <Button
                    icon={<DownloadOutlined />}
                    type="text"
                    onClick={() => downloadTripLogs()}
                  />
                </div>
              </div>
              <div className={classes.distanceTable}>
                <Table
                  pagination={false}
                  columns={generateColumnForTable()}
                  dataSource={processDataForDistance}
                  rowKey={(record: any) => Math.random()}
                ></Table>
              </div>
            </>) : 'No logs found'}
      </div>
    </div >
  );
};

const mapStateToProps = ({ masterData }) => {
  return {
    viewType: masterData.viewType,
  };
};

const TimelineWithStopsStyled = withStyles(styles, { injectTheme: true })(
    TimelineWithStops,
);
export default connect(mapStateToProps, null)(TimelineWithStopsStyled);
