import { getKeyArrayFromObject } from '../utils/utils';
import { getRTOvalue } from './consignmentView';

const getPaymentvalue = (value) => {
  if (value) {
    if (value === 'COD') {
      return true;
    }

    return false;

  }
  return undefined;
};
const pagination = {
  resultPerPage: 'pagination.perPageCount',
  nextOrPrev: 'pagination.nextOrPrev',
  paginationParams : {
    path : 'pagination',
    fn : (pagination) => {
      if (!Array.isArray(pagination.paginationParams)) {
        return [];
      }
      return pagination.paginationParams.map((x) => {
        return {
          sortByKey : x.sortByKey,
          lastRowId :  getLastRowId(x, pagination.nextOrPrev),
        };
      });
    },
  },
};

const responseModelSummaryMetricsCN = {
  isNextPresent: 'isNextPresent',
  page_data: 'pageData',
  paginationParams: 'paginationParams',
};

const getLastRowId = (pagination, nextOrPrev) => {
  switch (nextOrPrev) {
    case 'prev':
      return pagination.lastRowIdPrev;
    case 'next':
      return pagination.lastRowIdNext;
    default:
      return null;
  }
};
const requestModelummaryMetricsCRM = {
  descendingOrder: 'appliedFilters.descendingOrder',
  statusList: {
    path: 'appliedFilters.status',
    fn : (value) => {
      return getKeyArrayFromObject(value);
    },
  },
  customerIdList: {
    path: 'appliedFilters.customerCodes',
    fn : (value) => {
      return getKeyArrayFromObject(value);
    },
  },
  hubIdList: {
    path: 'appliedFilters.hubId',
    fn : (value) => {
      return getKeyArrayFromObject(value);
    },
  },
  serviceTypeIdList: {
    path : 'appliedFilters.serviceType',
    fn : (value) => {
      return value ? value.map(x => x.key) : [];
    },
  },
  attemptCountList: {
    path : 'appliedFilters.attempts',
    fn : (value) => {
      return value ? value : [];
    },
  },
  timeFilters: [
    {
      fromDateString: 'globalAppliedFilters.startDate',
      toDateString: 'globalAppliedFilters.endDate',
      columnName: 'globalAppliedFilters.dateFilterType',
    },
  ],
  isCod:{
    path: 'appliedFilters.paymentType',
    fn : (value) => {
      return getPaymentvalue(value);
    },
  },
  isRto : {
    path : 'appliedFilters.movementType',
    fn : (value) => {
      return getRTOvalue(value);
    },
  },

};
const requestModelSummaryMetricsDataCRM = {
  ...pagination,
  ...requestModelummaryMetricsCRM,
  groupByParams: {
    path : 'globalAppliedFilters.groupByParams',
    fn : (value) => {
      return value ? value : ['hubId'];
    },
  },
};
const requestModelAggregatedSummmaryMetrics = {
  ...requestModelummaryMetricsCRM,
};

const requestModelSummmaryMetricsDownloads = {
  ...requestModelummaryMetricsCRM,
  groupByParams: {
    path : 'globalAppliedFilters.groupByParams',
    fn : (value) => {
      return value ? value : ['hubId'];
    },
  },
};

const responseModelAggregatedSummaryMetrics = {
  data : {
    path : 'data',
    fn : (data) => {
      return Object.keys(data).map((key) => {
        return { id : key, value : data[key] };
      });
    },
  },
};

export {
  responseModelSummaryMetricsCN,
  requestModelSummaryMetricsDataCRM,
  requestModelAggregatedSummmaryMetrics,
  requestModelSummmaryMetricsDownloads,
  responseModelAggregatedSummaryMetrics,
};
