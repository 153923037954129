export const getOrganisationPrettyName = (defaultOrganisationPrettyName) => {
  let organisationPrettyName = '';
  const origin = window.location.hostname;
  if (origin.indexOf('.') > 0) {
    organisationPrettyName = origin.substring(0, origin.indexOf('.'));
  } else {
    organisationPrettyName = defaultOrganisationPrettyName;
  }
  return organisationPrettyName;
};

export const getHostname = () => {
  return window.location.hostname;
};
