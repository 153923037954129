import Icon from '@ant-design/icons';
import * as React from 'react';

const IcLaunchSvg = (props) => {
  return (
        <svg version="1.1" width="1em" height="1em" viewBox="0 0 10.0 10.0" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <clipPath id="icl0">
            <path d="M269,0 L269,607 L0,607 L0,0 L269,0 Z"></path>
            </clipPath>
            <clipPath id="icl1">
            <path d="M3.75,1.77635684e-15 L3.75,0.833333333 L0.833333333,0.833333333 L0.833333333,6.66666667 L6.66666667,6.66666667 L6.66666667,3.75 L7.5,3.75 L7.5,6.66666667 C7.5,7.125 7.125,7.5 6.66666667,7.5 L0.833333333,7.5 C0.370833333,7.5 0,7.125 0,6.66666667 L0,0.833333333 C0,0.375 0.370833333,1.77635684e-15 0.833333333,1.77635684e-15 L3.75,1.77635684e-15 Z M7.5,1.77635684e-15 L7.5,2.91666667 L6.66666667,2.91666667 L6.66666667,1.42083333 L2.57083333,5.51666667 L1.98333333,4.92916667 L6.07916667,0.833333333 L4.58333333,0.833333333 L4.58333333,1.77635684e-15 L7.5,1.77635684e-15 Z"></path>
            </clipPath>
        </defs>
        <g transform="translate(-235.0 -561.0)">
            <g clip-path="url(#icl0)">
                <g transform="translate(24.0 559.0)">
                    <g transform="translate(211.0 2.0)">
                    <g transform="translate(1.25 1.25)">
                        <g clip-path="url(#icl1)">
                            <polygon points="0,1.77635684e-15 7.5,1.77635684e-15 7.5,7.5 0,7.5 0,1.77635684e-15" stroke="none" fill="#666666"></polygon>
                        </g>
                    </g>
                    </g>
                </g>
            </g>
        </g>
        </svg>
    );
};

export const IcLaunchIcon = props => (
  <Icon component={IcLaunchSvg} {...props} />
);
