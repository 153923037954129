import React, { memo } from 'react';
import { Empty } from 'antd';
import { Pie } from '@ant-design/plots';
import PieConfigs from './ConfigCharts/configPie';
import { transformPieChartData } from 'src/utils/utils';

const PieViz = (props) => {
  const {
    formData,
    chartData,
    chartConfig,
    chartsFromDashboard,
  } = props;
  const pieConfig = PieConfigs(chartConfig, formData, chartsFromDashboard);
  const finalData = transformPieChartData(
    chartData,
    pieConfig?.othersThreshold ?? 2,
  );
  const config: any = {
    data: finalData,
    innerRadius: formData.doughnut ? 0.6 : 0,
    angleField: formData.angleField,
    colorField: formData.colorField,
    ...pieConfig,
  };

  return chartData.length > 0 ? <Pie {...config} /> : <Empty />;
};

const areEqual = (prevProps, nextProps) => {
  // additional props in parent like classes and chartOptions
  // were causing a re-render
  if (
    prevProps.formData === nextProps.formData &&
    prevProps.chartData === nextProps.chartData
  ) {
    return true; // do not re-render
  }
  return false; // will re-render
};

export default memo(PieViz, areEqual);
