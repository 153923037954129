import moment from 'moment';
import {
  FETCH_ALL_LIVE_DASHBOARD_DATA,
  FETCH_ALL_LIVE_DASHBOARD_DATA_LOADING,
  FETCH_ALL_LIVE_DASHBOARD_DATA_ERROR,
  FETCH_LIVE_DASHBOARD_SUMMARY_METRICS,
  FETCH_LIVE_DASHBOARD_SUMMARY_METRICS_ERROR,
  FETCH_LIVE_DASHBOARD_SUMMARY_METRICS_LOADING,
  LIVE_DASHBOARD_APPLY_FILTERS,
  SET_TASK_DETAIL_DATA,
  FETCH_ALL_RIDER_DATA,
  FETCH_ALL_RIDER_DATA_ERROR,
  FETCH_ALL_RIDER_DATA_LOADING,
  LIVE_DASHBOARD_SET_PAGINATION,
  LIVE_DASHBOARD_RESET_PAGINATION,
  LIVE_DASHBOARD_SET_TRIP_DETAILS,
  LIVE_DASHBOARD_SET_TRIP_DETAILS_ERROR,
} from './LiveDashboard.constants';

const defaultState = {
  isTasksLoading: false,
  planningData: {},
  ridersData: [],
  isRidersLoading: false,
  fetchAllRidersErrorMessage: null,
  summaryMetricsData: {},
  isLoadingMetrics: false,
  fetchSummaryMetricsDataErrorMessage: null,
  fetchAllPlanningErrorMessage: null,
  taskDetailData: null,
  paginationParams: {
    task: {
      resultPerPage: 500,
      pageNumber: 1,
      lastSortedColumnValue: null,
      nextOrPrev: null,
      lastSortByKey: 'created_at',
      sortBy: 'created_at',
      descendingOrder: true,
      paginationRecordList: null,
    },
    rider: {
      resultPerPage: 100,
      pageNumber: 1,
      lastSortedColumnValue: null,
      nextOrPrev: null,
      lastSortByKey: 'created_at',
      sortBy: 'created_at',
      descendingOrder: true,
      paginationRecordList: null,
    }
  },
  appliedFilters: {
    bucket: 'planning_all',
    timeFilter: {
      dateFilterType: 'created_at',
      startDate: moment().subtract(6, 'days').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
    },
    taskFilters: {},
  },
};

export default function (state:any = defaultState, action) {

  switch (action.type) {

    case FETCH_ALL_LIVE_DASHBOARD_DATA: {
      return {
        ...state,
        planningData: action.data,
        fetchAllPlanningErrorMessage: null,
      };
    }

    case FETCH_ALL_LIVE_DASHBOARD_DATA_LOADING: {
      return {
        ...state,
        isTasksLoading: action.data,
      };
    }

    case FETCH_ALL_LIVE_DASHBOARD_DATA_ERROR: {
      return {
        ...state,
        planningData: {},
        fetchAllPlanningErrorMessage: action.data,
      };
    }

    case FETCH_LIVE_DASHBOARD_SUMMARY_METRICS: {
      return {
        ...state,
        summaryMetricsData: action.data,
        fetchSummaryMetricsDataErrorMessage: null,
      };
    }

    case FETCH_LIVE_DASHBOARD_SUMMARY_METRICS_LOADING: {
      return {
        ...state,
        isLoadingMetrics: action.data,
      };
    }

    case FETCH_LIVE_DASHBOARD_SUMMARY_METRICS_ERROR: {
      return {
        ...state,
        summaryMetricsData: {},
        fetchSummaryMetricsDataErrorMessage: action.data,
      };
    }

    case LIVE_DASHBOARD_APPLY_FILTERS: {
      return {
        ...state,
        appliedFilters: {
          ...(state.appliedFilters || {}),
          ...(action.data || {}),
        },
      };
    }

    case SET_TASK_DETAIL_DATA: {
      return {
        ...state,
        taskDetailData: action.data,
      };
    }

    case FETCH_ALL_RIDER_DATA: {
      return {
        ...state,
        ridersData: action.data,
        fetchAllRidersErrorMessage: null,
      };
    }

    case FETCH_ALL_RIDER_DATA_LOADING: {
      return {
        ...state,
        isRidersLoading: action.data,
      };
    }

    case FETCH_ALL_RIDER_DATA_ERROR: {
      return {
        ...state,
        ridersData: [],
        fetchAllRidersErrorMessage: action.data,
      };
    }

    case LIVE_DASHBOARD_SET_PAGINATION: {
      return {
        ...state,
        paginationParams: {
          task: {
            ...state.paginationParams.task,
            ...action.data.task,
          },
          rider: {
            ...state.paginationParams.rider,
            ...action.data.rider,
          }
        },
      };
    }

    case LIVE_DASHBOARD_RESET_PAGINATION: {
      return {
        ...state,
        paginationParams: {
          ...defaultState.paginationParams,
        },
      };
    }

    case LIVE_DASHBOARD_SET_TRIP_DETAILS: {
      return {
        ...state,
        tripDetails: {
          ...state.tripDetails,
          ...action.data,
        },
      };
    }

    case LIVE_DASHBOARD_SET_TRIP_DETAILS_ERROR: {
      return {
        ...state,
        tripDetailsErrorMessage: {
          ...state.tripDetailsErrorMessage,
          ...action.data,
        },
      };
    }

    default: return state;
  }
}
