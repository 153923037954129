import { GetChartConfigData } from '../utils';

const PieConfigs = (chartConfig, formData, chartsFromDashboard) => {
  const configObject = {
    chartConfig,
    configType: 'pie',
    formData,
    chartsFromDashboard,
  };
  const configData = GetChartConfigData(configObject);
  if (!configData) {
    return null;
  }
  const { graphicLabel: label, legend } = configData['pie'] || {};
  const { type, offset, offsetX, offsetY, labelSize } =
    configData['graphicLabel'] || {};
  const {
    location,
    flipPage,
    offsetX: legendOffsetX,
    offsetY: legendOffsetY,
  } = configData['legend'] || {};
  const { tooltip, color, radius, percentageThreshold } =
    configData['graphic'] || {};
  const pieConfig = {
    label: label?.data
      ? {
          type: type?.data,
          offset: offset?.data,
          offsetX: offsetX?.data,
          offsetY: offsetY?.data,
          content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
          style: {
            fontSize: labelSize?.data,
            textAlign: 'center',
          },
        }
      : undefined,
    legend: legend?.data
      ? {
          position: location?.data,
          flipPage: flipPage?.data,
          offsetX: legendOffsetX?.data,
          offsetY: legendOffsetY?.data,
        }
      : false,
    radius: radius?.data / 100,
    tooltip: tooltip?.data,
    color: color?.data,
    theme: {
      background: '#FFFFFF',
    },
    othersThreshold: percentageThreshold?.data,
  };
  return pieConfig;
};

export default PieConfigs;
