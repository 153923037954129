import { getSummaryMetrics, getCallsOverviewData } from '../../api/dashboard';
import { getDetailsRoute } from '../../routing/utils';

const viewControl = {
  pageTitle: 'Calls Overview',
  buckets: ['call_center_overview'],
  summary_metrics: [
    {
      key: 'assigned',
      name: 'AWBs Assigned',
      color: '#c71563',
    },
    {
      key: 'completed',
      name: 'AWBs Completed',
      color: '#59bf46',
    },
    {
      key: 'pending',
      name: 'AWBs Pending',
      color: '#ef862c',
    },
    {
      key: 'total_call_logs',
      name: 'Calls Attempted',
      color: '#1a9f8d',
    },
    {
      key: 'calls_attempted_average',
      name: 'Avg no. of calls per day',
      color: '#1a9f8d',
    },
  ],
  navBar: {
    left: ['date_filter'],
    right: ['search_redirect'],
  },
  dateFilterOptions: [
    { key: 'created_at', val: 'Created At' },
    { key: 'last_main_event_time', val: 'Last Updated At' },
  ],
  searchFilterOptions: [
    { key: 'referenceNumber', val: 'Reference Number' },
    { key: 'customerReferenceNumber', val: 'Customer Reference Number' },
  ],
  fetchData: getCallsOverviewData,
  fetchSummaryMetrics: getSummaryMetrics,
  detailsRoute: getDetailsRoute,
};
const buckets = {
  call_center_overview: {},
};
const bucketColor = {};

export default { viewControl, buckets, bucketColor };
