import { API_BASE_URL } from '@src/globals';
import { GET, POST } from './apiHandler';

import {
    GET_SUPPLY_REQUEST_TABLE_DATA,
    GET_SUPPLY_REQUEST_DETAILS,
    MARK_SUPPLY_REQUEST_AS_DELIVERED,
    MARK_SUPPLY_REQUEST_AS_UNDELIVERED,
    CREATE_SUPPLY_REQUEST,
  } from './routes';
import {
    requestModelSupplyRequest,
    responseModelSupplyRequest,
} from '@src/model/consignmentView';
import { morphism } from 'morphism';

export const getSupplyRequestTableData = async(body) => {
    const params = {
        ...morphism(requestModelSupplyRequest, body),
    };
    const res = await POST(`${API_BASE_URL}${GET_SUPPLY_REQUEST_TABLE_DATA}`, params);
    if(res.isSuccess){
        return {
            data: morphism(responseModelSupplyRequest, res.data),
            isSuccess: true,
        };
    }
    return res;
};

export const getSupplyRequestDetails = async(params) => {
    return GET(`${API_BASE_URL}${GET_SUPPLY_REQUEST_DETAILS}`, params);
};

export const markSupplyRequestAsDelivered = async(body) => {
    return POST(`${API_BASE_URL}${MARK_SUPPLY_REQUEST_AS_DELIVERED}`, body);
};

export const markSupplyRequestAsUndelivered = async(body) => {
    return POST(`${API_BASE_URL}${MARK_SUPPLY_REQUEST_AS_UNDELIVERED}`, body);
};

export const createSupplyRequest = async(body) => {
    return POST(`${API_BASE_URL}${CREATE_SUPPLY_REQUEST}`, body);
};
