import * as React from 'react';
import withStyles from 'react-jss';
import { CssType,
  ThemeType} from '../../../theming/jssTypes';
import { CloseOutlined } from '@ant-design/icons';
import { Modal, Input } from 'antd';
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc';
import { DragIcon } from '../../common/icons/DragIcon';
import CheckboxGroup from 'antd/lib/checkbox/Group';

const Search = Input.Search;

const styles = (theme: ThemeType): CssType => ({
  listItemStyle: {
    marginBottom:'10px',
    backgroundColor: '#D5E9FF',
    borderRadius: '4px',
    paddingLeft:'8px',
    paddingRight: '8px',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  mainDiv: {
    height:'350px',
    display: 'flex',
    overflow:'hidden',
    justifyContent: 'space-between',
  },
  leftDiv: {
    width: '48%',
    display: 'flex',
    flexDirection: 'column',
    '& .ant-checkbox-group-item': {
      marginBottom: '12px',
    },
    '& .ant-checkbox-group': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  searchStyle: {
  },
  rightDiv: {
    overflow:'auto',
    border: '1px solid #E8E8E8',
    padding: '12px',
    width: '48%',
    '& ul': {
      padding: 0,
      'list-style-type': 'none',
    },
  },
  checkboxDiv: {
    overflow:'auto',
    border: '1px solid #E8E8E8',
    height: '100%',
    padding: '16px',
  },
  customPrimaryButton: {
    backgroundColor: theme.colors.primaryColor,
    color: theme.colors.textOnDarkBg,
  }
});

interface Props {
  isVisible: boolean;
  fullColumnList?: {column_id:string, pretty_name:string}[];
  columnsToShow?: {column_id:string, pretty_name:string}[];
  onModalClose: any;
  setColumns: any;
  doNotshowSearch: any;
  isUploading: boolean;
}

class CustomColumnsModal extends React.PureComponent<any, any> {

  state: any = {
    items: [], // Contains value or column_id of selected or existing columns
    allOptions : [],
  };

  componentDidMount() {
    // Created mapping to render the checkboxes
    // pretty_name => label
    // column_id => value
    const allOptions = this.props.fullColumnList.map((ele) => {
      return { label: ele.pretty_name, value: ele.column_id, disabled: ele.column_id === 'serial_number' };
    });
    const existingColumns = this.props.columnsToShow.map((ele) => {
      return (ele.column_id);
    });
    this.setState({
      allOptions,
      items:existingColumns,
    });
  }

  ListItem = (record: any) => {
    const { classes } = this.props;
    return (
      <div className={classes.listItemStyle}>
        <div>
          {record?.record?.value !== 'serial_number' && <DragIcon />}
          <span style={{ marginLeft: '8px' }}>
            {this.props.fullColumnList.filter(
              x => x.column_id === record.record.value)[0].pretty_name}
          </span>
        </div>
        {record?.record?.value !== 'serial_number' && <div>
          <CloseOutlined onClick={() => this.handleCrossClick(record.record.value)} />
        </div>}
      </div>
    );
  };

  handleCrossClick = (value) => {
    this.setState({
      items: this.state.items.filter(x => x !== value),
    });
  };

  onCheckboxChange = (checkedValues) => {
    const afterSet = new Set(checkedValues);
    if (checkedValues.length > this.state.items.length) {
      const union = new Set([...this.state.items, ...checkedValues]);
      this.setState({
        items: Array.from(union),
      });
    }else {
      const intersection = new Set(
        [...this.state.items].filter(x => afterSet.has(x)));
      this.setState({
        items: Array.from(intersection),
      });
    }
  };

  SortableItem = SortableElement((props: any) => <this.ListItem record={props} />);
  SortableList = SortableContainer((props: any) => {
    return (
      <ul>
        {props.items.map((value, index) => {
          return <this.SortableItem key={`item-${index}`} index={index} value={value} disabled={value === 'serial_number'} />;
        })}
      </ul>
    );
  });

  onSortEnd = ({ oldIndex, newIndex }) => {
    if (newIndex !== 0) {
      this.setState(({ items }) => ({
        items: arrayMove(items, oldIndex, newIndex),
      }));
    }
  };

  handleModalOk = async () => {
    const columns = this.state.items.map((element) => {
      return this.props.fullColumnList.filter(x => (x.column_id === element))[0];
    });
    await this.props.setColumns(columns);
    this.handleModalClose();
  };

  handleModalClose = () => {
    this.props.onModalClose();
  };

  handleSearchChange = (e) => {
    this.setState({
      allOptions: this.props.fullColumnList
        .filter(x =>
          x.pretty_name
            .toLowerCase()
            .includes(e.target.value.toLowerCase()),
        )
        .map(ele => {
          return { label: ele.pretty_name, value: ele.column_id, disabled: ele.column_id === 'serial_number' };
        }),
    });
  };

  render() {
    const { isVisible, classes } = this.props;
    const { isUploading } = this.props;
    return (
      <Modal title="Customize Columns" visible={isVisible}
        okText="Update"
        destroyOnClose maskClosable={false}
        width={800}
        confirmLoading={isUploading}
        onOk={this.handleModalOk}
        onCancel={this.handleModalClose}
        okButtonProps={{ className: classes.customPrimaryButton }}
      >
      <div className={classes.mainDiv}>
        <div className={classes.leftDiv} >
          {!this.props.doNotshowSearch ?
            <div style={{
              padding: '12px', border: '1px solid #E8E8E8'
            }}>
              <Search
                onChange={this.handleSearchChange}
                className={classes.searchStyle}
                placeholder="Search"
              />
            </div> : null}  
          <div className={classes.checkboxDiv}>
              <CheckboxGroup options={this.state.allOptions} value={this.state.items}
                onChange={this.onCheckboxChange} />
          </div>
        </div>
        <div className={classes.rightDiv} >
          <div style={{
            fontWeight:600, fontSize:'14px',
            color:'#424242',
            marginBottom:'12px' }} >
            Existing Columns
          </div>
          <div><this.SortableList distance={1} items={this.state.items} onSortEnd={this.onSortEnd}
            helperClass="sortableHelper" />
            </div>
        </div>
      </div>
      </Modal>
    );
  }
}

const StyledCustomModal = withStyles(styles, { injectTheme: true })(CustomColumnsModal);
export default StyledCustomModal;
