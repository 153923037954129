import { morphism } from 'morphism';
import { API_BASE_URL, BUSINESS_BOOKING_API_URL } from '@src/globals';
import { POST, GET } from '@api/apiHandler';
import {
    FETCH_ALL_CUSTOMERS,
    GET_CUSTOMER_DETAIL,
    CREATE_CUSTOMER,
    UPDATE_CUSTOMER,
    DOWNLOAD_CUSTOMERS,
    BULK_CREATE_CUSTOMER_CSV,
    BULK_UPDATE_CUSTOMER_CSV,
    DOWNLOAD_CSV_FOR_CUSTOMER,
    GET_VIRTUAL_SERIES,
    ADD_VIRTUAL_SERIES,
    DELETE_VIRTUAL_SERIES,
    DELETE_VIRTUAL_SERIES_BULK,
    GET_SOFTDATA_API_KEY,
    REGENERATE_SOFTDATA_API_KEY,
    DOWNLOAD_VIRTUAL_SERIES_REQUEST,
    SEND_PASSWORD_RESET_MAIL,
    UPDATE_WEBHOOK_DETAILS,
    SEARCH_CUSTOMER,
    GET_CUSTOMER_TIMEZONES,
    FETCH_HIERARCHY_DATA,
    GET_ADDRESSES,
    GET_PICKUP_RULES,
    CREATE_PICKUP_RULE,
    DELETE_PICKUP_RULE
} from '@api/routes';
import { getAuthenticationHeaders } from './utils';

import {
    requestModelCustomerManage,
    responseModelCustomerManage,
} from '@model/consignmentView';

export const getAddresses = async (params: any) => {
    return GET(`${BUSINESS_BOOKING_API_URL}${GET_ADDRESSES}`, params);
};

export const getPickupRules = async (params: any) => {
    return GET(`${API_BASE_URL}${GET_PICKUP_RULES}`, params);
};

export const createPickupRule = async (body) => {
    return POST(`${API_BASE_URL}${CREATE_PICKUP_RULE}`, body);
}

export const deletePickupRule = async (body) => {
    return POST(`${API_BASE_URL}${DELETE_PICKUP_RULE}`, body);
}

export const fetchAllCustomers = async(params = {}) => {
    const body = {
      ...morphism(requestModelCustomerManage, params),
    };
    const response = await GET(`${API_BASE_URL}${FETCH_ALL_CUSTOMERS}`, body);
    return {
      ...morphism(responseModelCustomerManage, response),
    };
};

export const getCustomerDetail = async(params) => {
    return GET(`${API_BASE_URL}${GET_CUSTOMER_DETAIL}`,params);
};

export const createCustomer = async(body) => {
    return POST(`${API_BASE_URL}${CREATE_CUSTOMER}`,body);
};

export const updateCustomer = async(body) => {
    return POST(`${API_BASE_URL}${UPDATE_CUSTOMER}`,body);
};

export const downloadCustomer = async(body) => {
    return GET(`${API_BASE_URL}${DOWNLOAD_CUSTOMERS}`,body);
};

export const uploadCsvForCreate = async(body) => {
    return POST(`${API_BASE_URL}${BULK_CREATE_CUSTOMER_CSV}`,body);
};

export const uploadCsvForUpdate = async(body) => {
    return POST(`${API_BASE_URL}${BULK_UPDATE_CUSTOMER_CSV}`,body);
};

export const downloadCsvForCreate = async (filters) => {
    const headers = await getAuthenticationHeaders();
    return (
        `${API_BASE_URL}${DOWNLOAD_CSV_FOR_CUSTOMER}?`+
        `header-access-token=${headers['access-token']}`+
        `&header-user-id=${headers['user-id']}`+
        `&header-organisation-id=${headers['organisation-id']}`+
        `&isCreate=true`
    );
};

export const downloadCsvForUpdate = async (filters) => {
    const headers = await getAuthenticationHeaders();
    return (
        `${API_BASE_URL}${DOWNLOAD_CSV_FOR_CUSTOMER}?`+
        `header-access-token=${headers['access-token']}`+
        `&header-user-id=${headers['user-id']}`+
        `&header-organisation-id=${headers['organisation-id']}`+
        `&isCreate=false`
    );
};

export const getVirtualSeries = (customerId) => {
    return GET(`${BUSINESS_BOOKING_API_URL}${GET_VIRTUAL_SERIES}?clientId=${customerId}`);
};

export const addVirtualSeries = (body) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${ADD_VIRTUAL_SERIES}`,body);
};

export const deleteVirtualSeries = (body) => {
    return POST(`${API_BASE_URL}${DELETE_VIRTUAL_SERIES}`,body);
};

export const deleteVirtualSeriesBulk = (body) => {
    return POST(`${API_BASE_URL}${DELETE_VIRTUAL_SERIES_BULK}`, body);
};

export const downloadVirtualSeries = (customerId = '') => {
    return GET(`${BUSINESS_BOOKING_API_URL}${DOWNLOAD_VIRTUAL_SERIES_REQUEST}?clientId=${customerId}`);
};

export const getSoftdataAPIKey = (customerId) => {
    return GET(`${API_BASE_URL}${GET_SOFTDATA_API_KEY}?clientId=${customerId}`);
};

export const reGenerateAPIKey = (customerId) => {
    return GET(`${API_BASE_URL}${REGENERATE_SOFTDATA_API_KEY}?clientId=${customerId}`);
};

export const sendPasswordResetEmail = (body) => {
    return POST(`${API_BASE_URL}${SEND_PASSWORD_RESET_MAIL}`,body);
};

export const updateWebhookDetails = (body) => {
    return POST(`${API_BASE_URL}${UPDATE_WEBHOOK_DETAILS}`,body);
};

export const searchCustomers = (searchText) => {
    return GET(`${API_BASE_URL}${SEARCH_CUSTOMER}?search=${searchText}&dynamicPickupAllocation=false`);
};

export const searchCustomersWithoutDynamicPickupAllocation = (searchText) => {
    return GET(`${API_BASE_URL}${SEARCH_CUSTOMER}?search=${searchText}`);
};

export const getCustomerTimezoneList = () => {
    return GET(`${API_BASE_URL}${GET_CUSTOMER_TIMEZONES}`);
};

export const getAddressMetadataUsingHieararchyKey = (payload) => {
    return GET(`${API_BASE_URL}${FETCH_HIERARCHY_DATA}`,payload);
};
