import { CssType,
  ThemeType} from '../../../../theming/jssTypes';
const styles = (theme: ThemeType): CssType => ({
  changePasswordModal: {
  },
  changePasswordModalHeading: {
    color: theme.primaryColor,
  },
  submitBtnContainer: {
    width: '100%',
    textAlign: 'center',
  },
  postPasswordChange: {
    textAlign: 'center',
  },
  postPasswordContainer: {
    marginBottom: '40px',
  },
  postPasswordText: {
    color: 'rgba(0,0,0,.65);',
    fontWeight: 600,
    textTransform: 'none',
    position: 'relative',
    display: 'block',
    padding: '13px 16px',
    fontSize: '27px',
    lineHeight: 'normal',
    textAlign: 'center',
  },
  postPasswordHint: {
    fontSize: '16px',
    position: 'relative',
    float: 'none',
    lineHeight: 'normal',
    verticalAlign: 'top',
    textAlign: 'left',
    display: 'inline-block',
    margin: '0',
    padding: '0 10px',
    fontWeight: 400,
    color: 'rgba(0,0,0,.64)',
    maxWidth: 'calc(100% - 20px)',
    overflowWrap: 'break-word',
    boxSizing: 'border-box',
  },
  closeChangePassModalBtn: {
    backgroundColor: '#7cd1f9',
    color: '#fff',
    border: 'none',
    boxShadow: 'none',
    borderRadius: '5px',
    fontWeight: 600,
    fontSize: '14px',
    padding: '10px 24px',
    margin: '0',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#78cbf2',
    },
    lineHeight: '20px',
  },
  formError: {
    color:'red',
    width: '100%',
    textAlign: 'center',
    minHeight: '21px',
    marginBottom: '5px',
  },
  updatePassBtn: {
    minWidth: '195px',
  },
});

export default styles;
