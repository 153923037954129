// tslint:disable: max-line-length

import Icon from '@ant-design/icons';
import * as React from 'react';
const DeliveredCheck = (props: any) => (
    <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.36689 9.00001L0.566895 5.20001L1.51689 4.25001L4.36689 7.10001L10.4836 0.983337L11.4336 1.93334L4.36689 9.00001Z" fill="white"/>
    </svg>

);
export const DeliveredCheckIcon = (props) => (
    <Icon component={DeliveredCheck} {...props} />
);
