import * as React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { redirectToUrl } from '@api/utils';

import {
  getFoodDashboardDispatchScreen,
  getFoodLiveTrackingMap,
  getFoodDashboardOrderManagement,
  getFoodDashboardRiderManagement,
  getFoodDashboardRosterManagement,
  getFoodDashboardCODCashSettlement,
  getFoodDashboardAnalytics,
  getFoodDashboardReporting,
  getOpsDashboardBaseRoute,
  getBankDepositRoute,
  getSupplyRequestRoute,
  getBankDepositReconcileRoute,
  getBankTransactionsHistoryRoute,
  getCashRemittanceRoute,
  getSupplementoryServicesRoute,
  getThirdPartyViewRoute,
  getTmsDashboardBaseRoute,
  getShelfBaseRoute,
  getCrateBaseRoute,
  getExpressDashboardBaseRoute,
  getFoodDashboardStoreConfiguration,
  getV1PagesBaseRoute,
  getCRMInOpsRoute,
  getPrintLabelRequestsRoute,
  getBranchManagerDashboardRoute,
  getCustomTripRequestsRoute,
  getCustomTripBulkDeleteRequestsRoute,
  getBranchLeadRoleRoute,
  getCarrierReconciliationRoute,
  getCarrierReconcileRoute,
  getCarrierTransactionsHistoryRoute,
  getFoodDashboardConfigurations,
  getFoodDashboardNotifications,
  getBulkRequestsPageRoute,
  getCallsOverviewRoute,
  getMyCallsRoute,
  getVehicleRequestRoute,
  getVehicleRequestDetails,
  getCmsSlipUploadRoute,
  getCmsDepositRoute,
  getPayInSlipBaseRoute,
  getLabelSetupRoute,
  getLabelLayoutSelection,
  getLabelPreviewRoute,
  getLabelCreateRoute,
  getFoodDashboardPayout,
  getConsignmentUploadRequestRoute,
  getActivityLogRoute,
  getActivityLogRouteOps,
  getCodSettlementRoute,
  getSoftdataFailureHandlerRoute,
  getPriceCalculatorBaseRoute,
  getIntegrationMarketplaceRoute,
  getChannelPageRoute,
  getIntegrationFormRoute,
  getIntegrationEditFormRoute,
  getIntegrationRawFormRoute,
  getRoutingPlaygroundRoute,
  getTerritoryOptimizationRoute,
  getVendorPayoutRoute,
  getGeocodingPlaygroundRoute,
  getPageBuilderDashboardRoute,
  getAnalyticsLandingPage,
  getSlotManagementBaseRoute,
  getLiveDashboardRoute,
} from '@routing/utils';
import { checkPermission } from './components/common/utils/route-utils';

const SetupPage = React.lazy(() => import('@components/pages/setup/SetupPage'));
const DetailsPage = React.lazy(
  () => import('@components/pages/details/DetailsPage'),
);

const RetailDashboard = React.lazy(
  () => import('@components/pages/retail/dashboard/Dashboard'),
);
const RetailTripsDetail = React.lazy(
  () => import('@components/pages/retail/trip/DetailsPage'),
);
const QueryBuilder = React.lazy(
  () => import('@components/pages/queryBuilder/queryBuilderPage'),
);
const LIACoPilot = React.lazy(
  () => import('@components/pages/LIACoPilot/LIACoPilot'),
);
const PWARedirect = React.lazy(
  () => import('@components/pages/PWARedirect'),
);
const RetailTracking = React.lazy(
  () => import('@components/pages/retail/tracking/Tracking'),
);
const Remittance = React.lazy(
  () => import('@components/pages/Remittance/index'),
);
const RiderListViewResolver = React.lazy(
  () => import('@components/pages/rider-reconciliation/RiderListViewResolver'),
);
const Reconcile = React.lazy(
  () => import('@components/pages/rider-reconciliation/Reconcile'),
);
const TransactionsHistory = React.lazy(
  () => import('@components/pages/Transactions/TransactionsHistory'),
);
const SupplyRequest = React.lazy(
  () => import('@components/pages/supplyRequest/SupplyRequestView'),
);
const BankDeposit = React.lazy(
  () => import('@components/pages/bankDeposit/BankDepositView'),
);
const VehicleRequest = React.lazy(
  () => import('@components/pages/VehicleRequest'),
);
const VehicleRequestDetails = React.lazy(
  () => import('@components/pages/VehicleRequestDetails'),
);
const BankReconcile = React.lazy(
  () => import('@components/pages/bankDeposit/BankDepositReconcile'),
);
const BranchLeadRole = React.lazy(
  () => import('@components/pages/branchLeadRole/BranchLeadRole'),
);
const GenericTransactionsHistory = React.lazy(
  () =>
    import(
      '@components/pages/genericTransactionsHistory/GenericTransactionHistory'
    ),
);
const DownloadsPage = React.lazy(
  () => import('@components/pages/downloads/DownloadsPage'),
);
const TrackingDetails = React.lazy(
  () => import('@components/pages/domestic/TrackingDetails'),
);
const AddEditShipment = React.lazy(
  () => import('@components/pages/domestic/AddEditShipment'),
);
const ViewDownload = React.lazy(
  () => import('@components/pages/domestic/ViewDownload'),
);
const DetailView = React.lazy(
  () => import('@components/pages/CarrierManagement/DetailView'),
);
const CmsSlipUpload = React.lazy(
  () => import('@components/pages/cmsDeposit/CmsDepositSlipUpload'),
);
const CmsDeposit = React.lazy(
  () => import('@components/pages/cmsDeposit/CmsDepositView'),
);
const CarrierManagement = React.lazy(
  () => import('@components/pages/CarrierManagement/CarrierManagement'),
);
const InvoicePage = React.lazy(
  () => import('@components/pages/invoice/Invoice'),
);
const CustomerActivity = React.lazy(
  () => import('@components/pages/CustomerFeedback/CustomerActivity'),
);
const DownloadsPageCustomerActivity = React.lazy(
  () => import('@components/pages/CustomerFeedback/DownloadsPage'),
);
const TripManagerViewResolver = React.lazy(
  () => import('@components/pages/retail/trip/TripManagerViewResolver'),
);
const RetailTripManagerMapEditPage = React.lazy(
  () => import('src/components/pages/retail/trip/TripManagerMapEdit/TripManagerMapEdit.component'),
);
const GenericConsignmentViewResolver = React.lazy(
  () =>
    import(
      '@components/pages/GenericConsignment/GenericConsignmentViewResolver'
    ),
);
const EmployeeActivation = React.lazy(
  () => import('@components/pages/EmployeeActivation'),
);

const NotFound404 = React.lazy(() => import('@components/pages/NotFound404'));
const OrderManagement = React.lazy(
  () =>
    import(
      '@components/pages/FoodDashboard/gdm/OrderManagement/OrderManagement'
    ),
);
const HelpdeskNotifications = React.lazy(
  () =>
    import(
      '@components/pages/FoodDashboard/gdm/HelpdeskNotifications/HelpdeskNotifications'
    ),
);
const RiderManagement = React.lazy(
  () => import('@components/pages/FoodDashboard/gdm/RiderManagement'),
);
const CodCashSettlement = React.lazy(
  () =>
    import(
      '@components/pages/FoodDashboard/gdm/CodCashSettlement/CodCashSettlement'
    ),
);
const RosterManagement = React.lazy(
  () =>
    import(
      '@components/pages/FoodDashboard/gdm/RosterManagement/RosterManagement'
    ),
);
const OrderViewResolver = React.lazy(
  () =>
    import(
      '@components/pages/FoodDashboard/DispatchScreenPickily/OrderViewResolver'
    ),
);
const LiveTrackingDispatch = React.lazy(
  () =>
    import(
      'src/components/pages/FoodDashboard/gdm/LiveTrackingDispatch/LiveTrackingDispatchView'
    ),
);
const SupplementoryServices = React.lazy(
  () => import('@components/pages/SupplementoryServices'),
);
const SupplementoryServicesDetails = React.lazy(
  () => import('@components/pages/SupplementoryServicesDetails'),
);
const OverviewDashboard = React.lazy(
  () => import('@components/pages/retail/analytics/OverviewDashboard'),
);
import OptimizerRequests from '@components/pages/retail/requests/OptimizerRequests';
const Analytics = React.lazy(
  () => import('@components/pages/Analytics/Analytics'),
);
const RetailAnalytics = React.lazy(
  () => import('@components/pages/retail/analytics/Dashboard'),
);
const OnDemandAnalytics = React.lazy(
  () =>
    import(
      '@components/pages/FoodDashboard/Analytics/AnalyticsDashboardResolver'
    ),
);
const GenericAnalyticsDasboard = React.lazy(
  () => import('@components/pages/GenericAnalytics/GenericAnalyticsDasboard'),
);
const OverviewAnalytics = React.lazy(
  () => import('@components/pages/retail/analytics/OverviewAnalytics'),
);
const SummaryMetrics = React.lazy(
  () => import('@components/pages/summaryMetrics/SummaryMetrics'),
);
const RetailSupportPortal = React.lazy(
  () => import('@components/pages/retail/dashboard/RetailSupportPortal'),
);
const OpsDashboard = React.lazy(() => import('@components/pages/OpsDashboard'));
const OpsDashboardV1Pages = React.lazy(
  () => import('@components/pages/OpsDashboardV1Pages'),
);
const ERPSyncPage = React.lazy(
  () => import('@components/common/navbarModals/erpSync/ERPSyncPage'),
);
const TMSDashboard = React.lazy(() => import('@components/pages/TMSDashboard'));
const PayInSlip = React.lazy(() => import('@components/pages/PayinSlip'));
const VendorPayout = React.lazy(() => import('@components/pages/VendorPayout'));
const GenericConsignmentView = React.lazy(
  () => import('@components/pages/GenericConsignment/GenericConsignmentView'),
);
const ShelfPage = React.lazy(
  () => import('@components/pages/Shelf/ShelfPage/ShelfPage'),
);
const CratePage = React.lazy(
  () => import('@components/pages/Crates/CratesPage'),
);
const ExpressDashboard = React.lazy(
  () => import('@components/pages/ExpressDashboard'),
);
const StoreConfiguration = React.lazy(
  () => import('@components/pages/FoodDashboard/gdm/StoreConfiguration'),
);
const RetailTripReconciliation = React.lazy(
  () => import('@components/pages/retail/trip/Reconciliation/Reconciliation'),
);
const ReconcileRevamp = React.lazy(
  () =>
    import(
      '@components/pages/rider-reconciliation/RiderReconciliationRevamp/Reconcile'
    ),
);
const CrmConsignmentView = React.lazy(
  () => import('@components/pages/dashboard/Dashboard'),
);
const CarrierReconciliation = React.lazy(
  () =>
    import(
      '@components/pages/third-party-reconciliation/CarrierReconciliation'
    ),
);
const CarrierReconcile = React.lazy(
  () => import('@components/pages/third-party-reconciliation/CarrierReconcile'),
);

const CounterBookingPage = React.lazy(
  () =>
    import(
      '@components/pages/OpsDashboard/Manage/counterBooking/CounterBooking'
    ),
);

const CounterBookingPageNew = React.lazy(
  () =>
    import(
      '@components/pages/OpsDashboard/Manage/counterBooking/CounterBookingNew'
    ),
);
const ActivityLog = React.lazy(
  () => import('@components/pages/PasswordPolicy/ActivityLog'),
);
import { getOpsV1ConsignmentsListRoute } from '@components/pages/OpsDashboardV1Pages/routes';
const ConfigurationMain = React.lazy(
  () => import('@components/pages/configurations/configurationMain'),
);
import { ANALYTICS_BASE_PATH, LIA_CO_PILOT_BASE_PATH } from '@components/pages/queryBuilder/routePath';
import BulkRequestsPage from '@components/pages/FoodDashboard/dispatchScreen/BulkRequestsPage';
const CallCenterViewResolver = React.lazy(
  () => import('@components/pages/dashboard/CallCenter/CallCenterViewResolver'),
);
import { API_PLAYGROUND_BASE_PATH } from '@components/common/persistentIframeRenderer/constants';
const ApiPlayground = React.lazy(
  () => import('@components/pages/ApiPlayground/ApiPlayground'),
);
const LabelSetup = React.lazy(
  () => import('@components/pages/labelSetup/CarrierPartnerSelection'),
);
const LabelLayoutSelection = React.lazy(
  () => import('@components/pages/labelSetup/Labels'),
);
const LabelPreview = React.lazy(
  () => import('@components/pages/labelSetup/LabelPreview'),
);
const NewLabel = React.lazy(
  () => import('@components/pages/labelSetup/NewLabel'),
);
const DevTools = React.lazy(
  () => import('@components/pages/DevTools/DevTools'),
);
const IntegrationMarketplace = React.lazy(
  () => import('@components/pages/IntegrationMarketplace/HomePage'),
);
const RiderPayout = React.lazy(
  () => import('@components/pages/FoodDashboard/RiderPayout/RiderPayout'),
);
const PickupDetail = React.lazy(
  () =>
    import('./components/pages/retail/trip/PickupDetails/PickupDetailsPage'),
);
const IntegrationDetail = React.lazy(
  () => import('@components/pages/IntegrationMarketplace/IntegrationDetail'),
);
const GenericFormRouter = React.lazy(
  () => import('@components/pages/IntegrationMarketplace/form/GenericFormRouter'),
);

const SoftDataFailureHandler = React.lazy(
  () =>
    import(
      '@components/pages/dashboard/softDataFailureHandler/SoftdataFailureHandler'
    ),
);
const RoutingPlayground = React.lazy(
  () => import('@components/pages/RoutingPlaygroundV2/RoutingPlaygroundV2'),
);

const TerritoryOptimisation = React.lazy(
  () => import('@components/pages/TerritoryOptimization/index'),
);

const GeocodingPlayground = React.lazy(
  () => import('@components/pages/GeocodingPlayground/GeocodingPlayground'),
);

const LiveDashboard = React.lazy(
  () => import('@components/pages/retail/dashboard/Live/LiveDashboard'),
);

import ProtectedRoute from '@components/common/ProtectedRoute';

import { applicationType } from './globals';
import OpsIframeFullParent from './components/pages/OpsDashboard/OpsIframeFullParent';
import MiscHubOps from './components/pages/OpsDashboard/Manage/MiscHubOps';
import GenericOpsV1Page from './components/pages/StandaloneOpsV1Pages/GenericOpsV1Page';
import PriceCalculator from './components/pages/PriceCalculator';
import RawForm from './components/pages/IntegrationMarketplace/form/RawForm';
import GenericDashboard from './components/pages/GenericDashboard/GenericDashboard.component';
import AnalyticsLandingDashboard from './components/pages/AnalyticsLandingDashboard';
import SlotManagement from './components/pages/SlotManagement/Slotmanagement';

const Router = (props) => {
  const {
    masterData,
    showLtlOptions,
    showBankDeposit,
    showThirdPartyRecon,
    showPayInSlip,
    showV1RemittanceDashboard,
    viewType,
    showHybridView,
    showThirdParty,
    showRetailOptions,
    showLogisticsExpressOptions,
    showCounterBooking,
    showCallsOverview,
    showMyCalls,
    showCRM,
    showCrates,
    showVehicleRequest,
    showSupplementoryServices,
    // odchecks
    showRosterManagement,
    showQueryBuilder,
    showWorkerCreditModule,
    showHelpdeskNotifications,
    ondemandStoreConfig,
    showStoreConfig,
    allowUpdateStoreConfig,
    showConfigurations,
    show_logistics_hybrid_express_view,
    show_v1_hybrid_view,
    express_analytics_overview,
    ops_dashboard_config,
    parts_to_show,
    showRiderPayout,
    showPasswordPolicy,
    showConsolidatedPlanning,
    showSupplyRequest,
    showInvoice,
    rateMasterAccess,
    showRiderRecon,
    showRoutingPlayground,
    showTerritoryOptimisation,
    showVendorPayout,
    showV2RemittanceDashboard,
    showGeocodingPlayground,
    showLIACoPilot,
    showDashboardGroupLandingPage,
    showLiveDashboard,
  } = props;
  const applicationTypeCRM = applicationType === 'crm';
  const applicationTypeOps = applicationType === 'ops';
  const showTmsOptions = viewType === 'tms';

  const location = useLocation();

  React.useEffect(() => {
    const excludedRoutes = [
      '/', '/pwa', '/login','/forgot-password', '/login/', '/forgot-password/', '/ssoRedirect', '/ssoRedirect/',
      '/signInFromInternal/', '/signInFromInternal',
    ];
    if(!excludedRoutes.includes(location.pathname)) {
      localStorage.setItem('lastViewedPage', location.pathname);
    }
  }, [location.pathname]);

  return (
    <Switch>
      {showHybridView ? (
        <ProtectedRoute
          exact
          path="/"
          isAllowed={checkPermission(masterData, 'rootRoute')}
          render={(props) => <NotFound404 />}
        />
      ) : (
        <ProtectedRoute
          exact
          path="/"
          render={(props) => <GenericConsignmentViewResolver {...props} />}
        />
      )}
      <ProtectedRoute
        exact
        path={getPageBuilderDashboardRoute(':pageId')}
        isAllowed={true}
        render={(props) => <GenericDashboard {...props} />}
      />
      <ProtectedRoute
        exact
        path="/consignments"
        isAllowed={checkPermission(masterData, 'consignments')}
        render={(props) => <GenericConsignmentViewResolver {...props} />}
      />
      <ProtectedRoute
        exact
        path="/employeeActivation"
        render={(props) => <EmployeeActivation {...props} />}
      />
      <ProtectedRoute
        exact
        path="/pwa"
        render={(props) => <PWARedirect {...props} />}
      />
      <ProtectedRoute
        exact
        path="/retail/ConsolidatedPlanning"
        isAllowed={checkPermission(masterData, 'retailConsolidatedPlanning')}
        render={(props) => (
          <GenericConsignmentViewResolver
            viewType="consolidated_planning"
            {...props}
          />
        )}
      />
      <ProtectedRoute
        exact
        path="/pickup_details/:referenceNumber"
        isAllowed={checkPermission(
          masterData,
          'pickupDetailsWithReferenceNumber',
        )}
        render={(props) => <PickupDetail {...props} />}
      />
      <ProtectedRoute
        exact
        path="/setup"
        render={(props) => <SetupPage {...props} />}
      />
      <ProtectedRoute
        exact
        path="/details/:referenceNumber"
        render={(props) => <DetailsPage {...props} />}
      />
      <ProtectedRoute
        exact
        path={'/downloads'}
        render={(props) => <DownloadsPage {...props} viewType={viewType} />}
      />
      <ProtectedRoute
        exact
        path={'/retail/trip-manager'}
        render={(props) => <TripManagerViewResolver {...props} />}
      />
      <ProtectedRoute
        exact
        path={'/retail/trip-manager/map-edit'}
        isAllowed={checkPermission(masterData, 'allowTripUpdate')}
        render={(props) => <RetailTripManagerMapEditPage {...props} />}
      />
      <ProtectedRoute path={'/retail/tracking'} component={RetailTracking} />
      <ProtectedRoute
        path="/retail/trip/details/:tripId"
        render={(props) => <RetailTripsDetail {...props} />}
      />
      <ProtectedRoute
        exact
        path={'/retail/createroute'}
        render={(props) => <RetailDashboard {...props} />}
      />
      <ProtectedRoute
        exact
        path="/domestic/track"
        component={(props) => (
          <GenericConsignmentView {...props} viewType={'domestic'} />
        )}
      />
      <ProtectedRoute
        path={ANALYTICS_BASE_PATH}
        component={QueryBuilder}
        isAllowed={showQueryBuilder}
      />
      <ProtectedRoute
        path={API_PLAYGROUND_BASE_PATH}
        component={ApiPlayground}
      />
      <ProtectedRoute
        path={LIA_CO_PILOT_BASE_PATH}
        component={LIACoPilot}
        isAllowed={showLIACoPilot}
      />
      <ProtectedRoute
        path="/domestic/track/detail/:referenceNumber"
        component={TrackingDetails}
      />
      <ProtectedRoute
        exact
        path={'/domestic/track/edit/:referenceNumber'}
        component={AddEditShipment}
      />
      <ProtectedRoute
        exact
        path="/domestic/track/create"
        component={AddEditShipment}
      />
      <ProtectedRoute
        path="/domestic/track/download/fetch"
        component={ViewDownload}
      />
      <ProtectedRoute
        path="/domestic/carrierManagement/detail/:courierPartnerId"
        component={DetailView}
      />
      <ProtectedRoute
        path="/domestic/carrierManagement"
        component={CarrierManagement}
      />
      <ProtectedRoute
        path="/customerActivity/downloads"
        component={DownloadsPageCustomerActivity}
      />
      <ProtectedRoute path="/customerActivity" component={CustomerActivity} />
      <ProtectedRoute
        path="/reconciliation/rider-list"
        isAllowed={showRiderRecon}
        render={(props) => <RiderListViewResolver {...props} />}
      />
      <ProtectedRoute
        path="/reconciliation/reconcile/:workerCode"
        render={(props) => <Reconcile {...props} />}
      />
      <ProtectedRoute
        exact
        path="/invoice"
        isAllowed={showInvoice && rateMasterAccess}
        render={(props) => <InvoicePage {...props} />}
      />
      <ProtectedRoute
        exact
        isAllowed={viewType === 'ondemand'}
        path={getFoodDashboardDispatchScreen()}
        render={(props) => <OrderViewResolver />}
      />
      <ProtectedRoute
        exact
        isAllowed={viewType === 'ondemand'}
        path={getFoodLiveTrackingMap()}
        render={(props) => <LiveTrackingDispatch />}
      />
      <ProtectedRoute
        exact
        path={getFoodDashboardOrderManagement()}
        isAllowed={viewType === 'ondemand'}
        render={(props) => <OrderManagement />}
      />
      <ProtectedRoute
        exact
        path={getFoodDashboardRiderManagement()}
        isAllowed={viewType === 'ondemand'}
        render={(props) => <RiderManagement />}
      />
      <ProtectedRoute
        exact
        isAllowed={showRosterManagement && viewType === 'ondemand'}
        path={getFoodDashboardRosterManagement()}
        render={(props) => <RosterManagement />}
      />
      <ProtectedRoute
        exact
        path={getFoodDashboardReporting()}
        isAllowed={viewType === 'ondemand'}
        render={(props) => <GenericConsignmentViewResolver {...props} />}
      />
      <ProtectedRoute
        exact
        isAllowed={showWorkerCreditModule && viewType === 'ondemand'}
        path={getFoodDashboardCODCashSettlement()}
        render={(props) => (
          <GenericConsignmentViewResolver
            viewType={'on_demand_cod'}
            bucket={'active'}
            {...props}
          />
        )}
      />
      <ProtectedRoute
        exact
        isAllowed={viewType === 'ondemand' && showHelpdeskNotifications}
        path={getFoodDashboardNotifications()}
        component={HelpdeskNotifications}
      />
      <ProtectedRoute
        exact
        path="/ltl/analytics"
        render={(props) => <GenericAnalyticsDasboard />}
      />
      <ProtectedRoute
        exact
        path="/retail/analytics"
        component={RetailAnalytics}
      />
      <ProtectedRoute
        exact
        path="/retail/overviewDashboard"
        component={OverviewDashboard}
      />
      <ProtectedRoute
        exact
        path={getFoodDashboardAnalytics()}
        isAllowed={viewType === 'ondemand'}
        component={OnDemandAnalytics}
      />
      <ProtectedRoute
        exact
        isAllowed={
          ondemandStoreConfig?.length &&
          (showStoreConfig || allowUpdateStoreConfig) &&
          viewType === 'ondemand'
        }
        path={getFoodDashboardStoreConfiguration()}
        component={StoreConfiguration}
      />
      <ProtectedRoute
        exact
        path="/genericConsignment"
        render={(props) => <GenericConsignmentViewResolver {...props} />}
      />
      <ProtectedRoute
        exact
        path="/retail/requests"
        component={OptimizerRequests}
      />
      <ProtectedRoute
        exact
        path="/retail/overview"
        isAllowed={express_analytics_overview}
        component={OverviewAnalytics}
      />
      <ProtectedRoute
        exact
        path="/summarymetrics"
        render={(props) => (
          <SummaryMetrics viewType="metrics" bucket="crm_summary_metrics" />
        )}
      />
      <ProtectedRoute
        exact
        path="/retail/supportPortal"
        render={(props) => (
          <RetailSupportPortal
            viewType="supportPortal"
            bucket="retail_dc_all"
          />
        )}
      />
      <ProtectedRoute
        exact
        path="/retail/supportPortal/consignment/:referenceNumber"
        render={(props) => <DetailsPage {...props} />}
      />
      <ProtectedRoute
        path={getTmsDashboardBaseRoute()}
        component={TMSDashboard}
      />
      <ProtectedRoute
        path={getPayInSlipBaseRoute()}
        component={PayInSlip}
        isAllowed={showPayInSlip}
      />
      <ProtectedRoute
        path={getPriceCalculatorBaseRoute()}
        component={PriceCalculator}
      />
      <ProtectedRoute
        path={getOpsDashboardBaseRoute()}
        component={OpsDashboard}
      />
      <ProtectedRoute
        exact
        path="/retail/erp-sync-logs"
        component={ERPSyncPage}
      />
      <ProtectedRoute
        exact
        path="/transactionsHistory"
        isAllowed={showRiderRecon}
        component={TransactionsHistory}
      />
      <ProtectedRoute
        exact
        isAllowed={showBankDeposit}
        path={getBankDepositRoute()}
        component={BankDeposit}
      />
      <ProtectedRoute
        exact
        isAllowed={showSupplyRequest}
        path={getSupplyRequestRoute()}
        render={(props) => <GenericConsignmentView viewType="supply_request"/>}
      />
      <ProtectedRoute
        exact
        isAllowed={showBankDeposit}
        path={getBankDepositReconcileRoute()}
        component={BankReconcile}
      />
      <ProtectedRoute
        exact
        path={getCmsSlipUploadRoute()}
        component={CmsSlipUpload}
      />
      <ProtectedRoute
        exact
        path={getCmsDepositRoute()}
        component={CmsDeposit}
      />
      <ProtectedRoute
        exact
        path={getCodSettlementRoute()}
        render={(props) => (
          <GenericOpsV1Page
            title={'COD Settlement'}
            route={{
              to: 'manage.hubs',
              params: {
                goTo: 'cod',
              },
            }}
            showSwitchView={false}
            switchToRoute={getCodSettlementRoute()}
            showTrackingOption={false}
          />
        )}
      />
      <ProtectedRoute
        exact
        path={getBranchLeadRoleRoute()}
        component={BranchLeadRole}
      />
      <ProtectedRoute
        exact
        isAllowed={showBankDeposit}
        path={getBankTransactionsHistoryRoute()}
        render={(props) => (
          <GenericTransactionsHistory viewType="bank_transactions" />
        )}
      />
      <ProtectedRoute
        exact
        path={getBranchManagerDashboardRoute()}
        render={(props) => (
          <GenericTransactionsHistory viewType="branch_manager_dashboard" />
        )}
      />
      <ProtectedRoute
        exact
        isAllowed={showV1RemittanceDashboard || showV2RemittanceDashboard}
        path={getCashRemittanceRoute()}
        render={(props) => showV2RemittanceDashboard ? <Remittance /> : <GenericTransactionsHistory viewType="cash_remittance" />}
      />
      <ProtectedRoute
        exact
        path="/retail/store-consignments"
        render={(props) => <GenericConsignmentView viewType="retailStore" />}
      />
      <ProtectedRoute
        path={getShelfBaseRoute()}
        render={(props) => <ShelfPage viewType="shelf" bucket="shelf" />}
      />
      <ProtectedRoute
        exact
        isAllowed={showCrates}
        path={getCrateBaseRoute()}
        render={(props) => <CratePage viewType="crate" bucket="crate" />}
      />
      <ProtectedRoute
        path={getExpressDashboardBaseRoute()}
        component={ExpressDashboard}
      />
      <ProtectedRoute
        path="/retail/trip-reconciliation/:tripId"
        render={(props) => <RetailTripReconciliation {...props} />}
      />
      <ProtectedRoute
        exact
        path={getThirdPartyViewRoute()}
        isAllowed={showThirdParty}
        render={(props) => <GenericConsignmentView viewType="thirdParty" />}
      />
      <ProtectedRoute
        exact
        path={getVehicleRequestRoute()}
        component={VehicleRequest}
        isAllowed={showVehicleRequest}
      />
      <ProtectedRoute
        exact path="/vehicle-request/details/:referenceNumber"
        component={VehicleRequestDetails}
        isAllowed={showVehicleRequest}
      />
      <ProtectedRoute
        path={getV1PagesBaseRoute()}
        component={OpsDashboardV1Pages}
      />
      <ProtectedRoute
        exact
        path={getCRMInOpsRoute()}
        isAllowed={
          (showLogisticsExpressOptions ||
            showRetailOptions ||
            showLtlOptions) &&
          showCRM
        }
        render={(props) => (
          <CrmConsignmentView viewType={'crm'} bucket={'crm_default'} />
        )}
      />
      <ProtectedRoute
        exact
        path={getPrintLabelRequestsRoute()}
        render={(props) => (
          <OptimizerRequests
            {...props}
            showPrintLabelRequests={true}
            title={'Print Label Requests'}
          />
        )}
      />
      <ProtectedRoute
        exact
        path="/counter-booking"
        isAllowed={showCounterBooking}
        render={(props) =>
            <CounterBookingPageNew showNavbar={true} />
          }
      />
      <ProtectedRoute
        path="/reconciliation/recon/:Id"
        isAllowed={showRiderRecon}
        render={(props) => <ReconcileRevamp {...props} />}
      />
      <ProtectedRoute
        exact
        path={getCustomTripRequestsRoute()}
        isAllowed={
          ops_dashboard_config?.parts_to_show?.enable_manual_trip_creation ||
          ops_dashboard_config?.parts_to_show?.courier_partner_recon_config
            ?.enable_courier_partner_recon
        }
        render={(props) => (
          <OptimizerRequests
            {...props}
            showCustomTripRequests={true}
            title={'Requests'}
          />
        )}
      />
      <ProtectedRoute
        exact
        path={getSlotManagementBaseRoute()}
        isAllowed={true}
        render={(props) => (
          <SlotManagement
            {...props}
            showCustomTripRequests={true}
            title={'Slot Management'}
          />
        )}
      />
      <ProtectedRoute
        exact
        path={getCustomTripBulkDeleteRequestsRoute()}
        isAllowed={
          ops_dashboard_config?.parts_to_show?.enable_manual_trip_creation ||
          ops_dashboard_config?.parts_to_show?.courier_partner_recon_config
            ?.enable_courier_partner_recon
        }
        render={(props) => (
          <OptimizerRequests
            {...props}
            showCustomBulkDeleteTripRequests={true}
            title={'Requests'}
          />
        )}
      />
      <ProtectedRoute
        exact
        isAllowed={showConfigurations && viewType === 'ondemand'}
        path={getFoodDashboardConfigurations()}
        render={(props) => <ConfigurationMain {...props} insideOpsV2={true} />}
      />
      <ProtectedRoute
        exact
        path={getBulkRequestsPageRoute()}
        isAllowed={viewType === 'ondemand'}
        render={(props) => <BulkRequestsPage {...props} />}
      />
      <ProtectedRoute
        exact
        path={getCarrierReconciliationRoute()}
        isAllowed={
          ops_dashboard_config?.parts_to_show?.courier_partner_recon_config
            ?.enable_courier_partner_recon && showThirdPartyRecon
        }
        component={CarrierReconciliation}
      />
      <ProtectedRoute
        exact
        isAllowed={showThirdPartyRecon}
        path={getCarrierReconcileRoute()}
        component={CarrierReconcile}
      />
      <ProtectedRoute
        exact
        path={getCarrierTransactionsHistoryRoute()}
        isAllowed={
          ops_dashboard_config?.parts_to_show?.courier_partner_recon_config
            ?.enable_courier_partner_recon && showThirdPartyRecon
        }
        render={(props) => (
          <GenericTransactionsHistory viewType="carrier_transactions" />
        )}
      />
      <ProtectedRoute
        exact
        path={getCallsOverviewRoute()}
        isAllowed={showCallsOverview || parts_to_show?.show_call_summary_page}
        render={(props) => (
          <CallCenterViewResolver
            viewType="calls_overview"
            bucket="call_center_overview"
            {...props}
          />
        )}
      />
      <ProtectedRoute
        exact
        path={getSupplementoryServicesRoute()}
        component={SupplementoryServices}
        isAllowed={showSupplementoryServices}
      />
      <ProtectedRoute
        exact
        path="/supplementary-services/details/:referenceNumber"
        component={SupplementoryServicesDetails}
        isAllowed={showSupplementoryServices}
      />
      <ProtectedRoute
        exact
        path={getMyCallsRoute()}
        isAllowed={showMyCalls || parts_to_show?.show_my_calls_page}
        render={(props) => (
          <CallCenterViewResolver
            viewType="my_calls"
            bucket="call_center_employee"
            {...props}
          />
        )}
      />
      <ProtectedRoute
        exact
        path={getLabelSetupRoute()}
        render={(props) => <LabelSetup {...props} />}
      />
      {/* <ProtectedRoute
        exact
        path={getLabelLayoutSelection()}
        render={(props) => <LabelLayoutSelection {...props} />}
      />
      <ProtectedRoute
        exact
        path={getLabelPreviewRoute()}
        render={(props) => <LabelPreview {...props} />}
      /> */}
      <ProtectedRoute
        exact
        path={getSoftdataFailureHandlerRoute()}
        render={(props) => <SoftDataFailureHandler {...props} />}
      />
      <ProtectedRoute
        exact
        path={getLabelCreateRoute()}
        render={(props) => <NewLabel {...props} />}
      />
      <ProtectedRoute
        exact
        path={getIntegrationMarketplaceRoute()}
        render={(props) => <IntegrationMarketplace {...props} />}
      />
      <ProtectedRoute
        exact
        path={getChannelPageRoute()}
        render={(props) => <IntegrationDetail {...props} />}
      />
      <ProtectedRoute
        exact
        path={getIntegrationFormRoute()}
        render={(props) => <GenericFormRouter {...props} />}
      />
      <ProtectedRoute
        exact
        path={getIntegrationEditFormRoute()}
        render={(props) => <GenericFormRouter {...props} />}
      />
      <ProtectedRoute
        exact
        path={getIntegrationRawFormRoute()}
        render={(props) => <RawForm {...props} />}
      />
      <ProtectedRoute exact path="/dev-tools" component={DevTools} />
      <ProtectedRoute
        exact
        isAllowed={showRiderPayout}
        path={getFoodDashboardPayout()}
        render={(props) => (
          <RiderPayout
            {...props}
            insideOpsDashboard={true}
          />
        )}
      />
      <ProtectedRoute
        exact
        path={getConsignmentUploadRequestRoute()}
        render={(props) => (
          <OptimizerRequests
            {...props}
            title="Upload Requests"
            showCNUploadRequests={true}
          />
        )}
      />
      <ProtectedRoute
        exact
        isAllowed={showPasswordPolicy}
        path={getActivityLogRoute()}
        render={(props) => <ActivityLog />}
      />
      <ProtectedRoute
        exact
        isAllowed={showPasswordPolicy}
        path={getActivityLogRouteOps()}
        render={(props) => <ActivityLog insideOpsDashboard={true} />}
      />
      <ProtectedRoute
        exact
        isAllowed={showRoutingPlayground}
        path={getRoutingPlaygroundRoute()}
        render={(props => <RoutingPlayground {...props}/>)}
      />
      <ProtectedRoute
        exact
        isAllowed={showVendorPayout}
        path={getVendorPayoutRoute()}
        component={VendorPayout}
      />
      <ProtectedRoute
        exact
        isAllowed={showTerritoryOptimisation}
        path={getTerritoryOptimizationRoute()}
        render={(props => <TerritoryOptimisation {...props}/>)}
      />
      <ProtectedRoute
        exact
        isAllowed={showGeocodingPlayground}
        path={getGeocodingPlaygroundRoute()}
        render={(props => <GeocodingPlayground {...props}/>)}
      />
      <ProtectedRoute
        exact
        path={getAnalyticsLandingPage()}
        component={AnalyticsLandingDashboard}
        isAllowed={showDashboardGroupLandingPage}
      />
      <ProtectedRoute exact path={getLiveDashboardRoute()} component={LiveDashboard} />
      <ProtectedRoute render={(props) => <NotFound404 />} />
    </Switch>
  );
};

const mapStateToProps = ({ masterData }) => {
  return {
    masterData,
    showOptimizationRequests:
      masterData.auto_allocation_asynchronous_request || false,
    showRetailOptions: masterData.show_retail_options,
    showLtlOptions: masterData.show_ltl_options,
    showRetailOverviewDashboard: masterData.show_retail_overview_analytics,
    showMetrics: masterData.show_summary_metrics,
    showAnalytics: Boolean(
      masterData.ltl_analytics_embed_link &&
        masterData.ltl_analytics_embed_link.length > 0,
    ),
    showBankDeposit: masterData.show_bank_deposit_crm && masterData?.bank_deposit_read,
    showThirdPartyRecon: masterData?.third_party_recon_read,
    showRiderRecon: masterData?.rider_recon_read,
    showVendorPayout: masterData?.vendor_payout_read,
    showV2RemittanceDashboard: masterData?.use_remittance_module_v2 || false,
    showPayInSlip: masterData?.pis_summary_and_manage_read,
    showV1RemittanceDashboard: masterData?.use_remittance_module_v1 || false,
    showThirdParty: masterData.third_party_read,
    viewType: masterData.viewType,
    showOpsV1DeliveryTaskList: masterData.parts_for_ops_dashboard
      ? masterData.parts_for_ops_dashboard
          .show_opsV1_delivery_task_list_in_v2 || false
      : false,
    showLogisticsExpressOptions: masterData.show_logistics_express_options,
    showCRM: masterData?.ops_dashboard_config?.parts_to_show?.show_crm,
    showHybridView: masterData.show_logistics_hybrid_express_view,
    showCounterBooking:
      masterData.single_consignment_booking_config?.show_on_ops_v2,
    showCallsOverview:
      masterData.ops_dashboard_config?.parts_to_show?.show_call_summary_page,
    showMyCalls:
      masterData.ops_dashboard_config?.parts_to_show?.show_my_calls_page,
    showViewTypeSwitch: masterData.show_view_type_switch,
    showOpsV1Hubs: masterData.setup_to_show_in_opsv2?.hubs,
    showOpsV1Employees: masterData.setup_to_show_in_opsv2?.employees,
    showConfigurations: masterData.show_allocation_setup || false,
    showRosterManagement: masterData.show_roster_management_ondemand || false,
    ondemandStoreConfig: masterData.ondemand_store_configuration || false,
    showStoreConfig: masterData.show_store_config || false,
    allowUpdateStoreConfig: masterData.allow_update_store_config,
    showHelpdeskNotifications:
      masterData?.ops_dashboard_config?.parts_to_show?.show_ondemand_helpdesk ||
      false,
    showWorkerCreditModule: masterData.show_worker_credit_module || false,
    showQueryBuilder: masterData?.show_query_builder_crm || masterData?.analyticTopBar,
    showCodDumpOverviewAnalytics:
      masterData?.ops_dashboard_config?.parts_to_show
        .show_cod_dump_overview_analytics,
    show_v1_hybrid_view: masterData.show_v1_hybrid_view,
    express_analytics_overview:
      masterData.ops_dashboard_config?.parts_to_show
        ?.express_analytics_overview,
    parts_to_show: masterData.ops_dashboard_config?.parts_to_show,
    ops_dashboard_config: masterData.ops_dashboard_config,
    show_logistics_hybrid_express_view:
      masterData.show_logistics_hybrid_express_view,
    showRiderPayout: masterData.rider_payout_read || false,
    showPasswordPolicy: masterData.show_password_policy,
    showConsolidatedPlanning: masterData.show_consolidated_planning,
    showCrates: masterData.use_crate || false,
    showVehicleRequest: masterData?.allow_vehicle_request_create || masterData?.allow_vehicle_request_review || false,
    showSupplementoryServices: masterData.allow_supplementory_services_read || false,
    showSupplyRequest: masterData.show_supply_request,
    showInvoice: masterData?.ops_dashboard_config?.parts_to_show?.setup_permission_obj
    ?.rate_masters?.invoice?.read || false,
    rateMasterAccess: masterData?.ops_dashboard_config?.parts_to_show?.setup_rate_master || false,
    showRoutingPlayground: masterData
      .ops_dashboard_config
      ?.parts_to_show
      ?.show_routing_playground_parts,
    showTerritoryOptimisation: masterData
      .ops_dashboard_config
      ?.parts_to_show
      ?.show_territory_optimisation_parts,
    showGeocodingPlayground: masterData
      .ops_dashboard_config
      ?.parts_to_show
      ?.show_geocoding_playground,
    showLIACoPilot: masterData?.show_incident_management_copilot || false,
    showDashboardGroupLandingPage: masterData?.showDashboardGroupLandingPage || false,
  };
};
export default connect(mapStateToProps)(Router);
