import * as React from 'react';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '@theming/jssTypes';
import { connect } from 'react-redux';
import { getSelectStyleForSelectWithInput } from '@theming/cssSnippets/commonSnippets';
import { PaperClipOutlined } from '@ant-design/icons';
import { Button, Upload, message } from 'antd';
import {
  getVehicleDocumentImageURL,
  putImageS3,
} from '@api/TMSDashboard/Setup';
import useIsMounted from 'src/hooks/useIsMounted';
import { useTranslation } from 'react-i18next';

const styles = (theme: ThemeType): CssType => ({
  selectStyleHubType: {
    ...getSelectStyleForSelectWithInput(),
  },
  selectStyle: {
    minWidth: 160,
    maxWidth: 180,
  },
});

const ImageUpload = (props: any) => {
  const { t } = useTranslation();
  const {
    filesList,
    viewConfig,
    setFilesList,
    fieldValues,
    setFieldValues,
    documentType,
    documentId,
    docUrlsList,
    setDocUrlsList,
    hideUploadButton,
    restrictFileSize,
    fileSizeLimit,
  } = props;
  const uploadProps = viewConfig?.uploadProps || {};
  const files = filesList[documentId] || [];
  const [isUploading, setIsUploading] = React.useState(false);
  const isMounted = React.useRef(false);

  React.useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const fileValidation = (file) => {
    if (restrictFileSize && fileSizeLimit && file.size / 1024 / 1024 > fileSizeLimit) {
      return false;
    }
    return true;
  };

  const handleBeforeUpload = (file) => {
    if (!fileValidation(file)) {
      message.error(`${t('file_size_should_be_less_than')} ${fileSizeLimit} MB`);
    } else {
      setFilesList({ ...filesList, [documentId]: [file] });
    }
    return false;
  };

  const handleRemove = (file) => {
    const index = files.indexOf(file);
    const newFileList = files.slice();
    newFileList.splice(index, 1);
    console.log('remove clicked, ', index, newFileList);
    setFilesList({ ...filesList, [documentId]: newFileList });
  };

  const onChange = async (info) => {
    if (!info.fileList?.length) {
      return;
    }
    setIsUploading(true);
    const fileItem = info.fileList[0];
    if (!fileValidation(fileItem)) {
      return;
    }
    const bodyGetUrl = {
      type: documentType,
      pocContentType: fileItem.type?.split('/')?.[1],
      fileName: fileItem.name?.split('.')[0],
    };
    const resGetUrl = await getVehicleDocumentImageURL(bodyGetUrl);

    const pocSignedUrl = resGetUrl?.data?.pocSignedUrl || '';
    const pocImageUrl = resGetUrl?.data?.pocImageUrl || '';

    if (!resGetUrl.isSuccess) {
      message.error(resGetUrl.errorMessage);
      setIsUploading(false);
      return;
    }

    if (pocSignedUrl) {
      setDocUrlsList({
        ...docUrlsList,
        [documentId]: resGetUrl.data.pocImageUrl,
      });

      const file = new Blob([fileItem.originFileObj as BlobPart], {
        type: fileItem.type,
      });

      const res: any = await putImageS3(pocSignedUrl, file, fileItem.type);
      if (!res.isSuccess) {
        message.error(t('could_not_upload_file'));
        setIsUploading(false);
        return;
      }
    }

    if (isMounted.current) {
      setIsUploading(false);
    }
  };

  return (
    <Upload
      fileList={files}
      beforeUpload={handleBeforeUpload}
      onRemove={handleRemove}
      maxCount={props.maxCount || 1}
      onChange={onChange}
      disabled={uploadProps.disabled ?? isUploading}
      {...uploadProps}
    >
      {(!hideUploadButton || !files.length) && (
        <Button>
          <PaperClipOutlined /> {t('upload')}
        </Button>
      )}
    </Upload>
  );
};
const mapStateToProps = ({ masterData }, ownProps) => {
  return { masterData };
};

const ImageUploadStyled = withStyles(styles, { injectTheme: true })(
  ImageUpload,
);
export default connect(mapStateToProps, null)(ImageUploadStyled);
