import {
  TOGGLE_SIDE_MENU,
  OPEN_SIDE_MENU,
  CLOSE_SIDE_MENU,
  SET_OPEN_KEYS,
} from '../actions/constants';

const getDefaultState = () => {
  return {
    sideMenuOpen: false,
    openKeys: ['consignments'],
  };
};

const defaultState = getDefaultState();

export default function (state: any = defaultState, action) {
  switch (action.type) {
    case TOGGLE_SIDE_MENU: {
      return {
        ...state,
        sideMenuOpen: !state.sideMenuOpen,
      };
    }

    case OPEN_SIDE_MENU: {
      return {
        ...state,
        sideMenuOpen: true,
      };
    }

    case CLOSE_SIDE_MENU: {
      return {
        ...state,
        sideMenuOpen: false,
      };
    }

    case SET_OPEN_KEYS: {
      return {
        ...state,
        openKeys: action.data,
      };
    }

    default: return state;
  }
}
