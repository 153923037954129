import { CONSIGNMENT_LABEL_URL, API_BASE_URL } from '../globals';
import {
  FETCH_LABEL_LIST,
  FETCH_LABEL_SINGLE,
  RENDER_LABEL_SINGLE,
  SAVE_LABEL_DRAFT,
  PUBLISH_LABEL,
  FETCH_SAMPLE_CN_DATA,
  DELETE_DRAFT,
  FETCH_PUBLISHED_LABEL_LIST,
  UNPUBLISH_LABEL,
} from './routes';
import { POST, GET } from './apiHandler';

export const fetchLabelList = async (params?) =>
  GET(`${CONSIGNMENT_LABEL_URL}${FETCH_LABEL_LIST}`, params);

export const fetchLabelSingle = async (params) =>
  GET(`${CONSIGNMENT_LABEL_URL}${FETCH_LABEL_SINGLE}`, params);

export const renderLabelSingle = async (body) =>
  POST(`${CONSIGNMENT_LABEL_URL}${RENDER_LABEL_SINGLE}`, body, undefined, true);

export const saveLabelDraft = async (body) =>
  POST(`${CONSIGNMENT_LABEL_URL}${SAVE_LABEL_DRAFT}`, body);

export const publishLabel = async (body) =>
  POST(`${CONSIGNMENT_LABEL_URL}${PUBLISH_LABEL}`, body);

export const fetchSampleCnDataForLabel = async () =>
  GET(`${CONSIGNMENT_LABEL_URL}${FETCH_SAMPLE_CN_DATA}`);

export const deleteLabelDraft = async (body) =>
  POST(`${CONSIGNMENT_LABEL_URL}${DELETE_DRAFT}`, body);

export const fetchPublishedLabelList = async (params?) =>
GET(`${API_BASE_URL}${FETCH_PUBLISHED_LABEL_LIST}`, params);

export const unPublishLabel = async (body) =>
  POST(`${CONSIGNMENT_LABEL_URL}${UNPUBLISH_LABEL}`, body);
