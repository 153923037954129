import * as React from 'react';
import { Button } from 'antd';
import { CssType, ThemeType } from '../../theming/jssTypes';
import withStyles, { WithStylesProps } from 'react-jss';
import { connect } from 'react-redux';
import { fetchConsignments } from 'src/actions/genericConsignmentActions';
import { MAP_EDIT_VIEW_TYPE } from '../pages/retail/trip/TripManagerMapEdit/TripManagerMapEditUtils';
import { bindActionCreators } from 'redux';

const styles = (theme: ThemeType): CssType => ({
  popup: {
    backgroundClip: 'padding-box',
    backgroundColor: '#fff',
    borderRadius: '4px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
    left: '0px',
    listStyleType: 'none',
    margin: 0,
    outline: 'none',
    padding: 0,
    position: 'absolute',
    textAlign: 'left',
    top: 0,
    overflow: 'hidden',
    zIndex: 10,

    '& li': {
      clear: 'both',
      color: 'rgba(0, 0, 0, 0.65)',
      cursor: 'pointer',
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: '22px',
      margin: 0,
      padding: '5px 12px',
      transition: 'all .3s',
      whiteSpace: 'nowrap',
    },

    '& li:hover': {
      backgroundColor: '#e6f7ff',
    },
  },
});

interface IProps extends WithStylesProps<typeof styles> {
  record: any;
  visible: boolean;
  x: number;
  y: number;
  menuConfig: [
    {
      id: string,
      name: string,
      color: string,
      icon: React.ReactElement,
      handler: (record: any) => void,
    },
  ],
}

const RightClickPopup = function (props: IProps) {
  const { record, visible, x, y, classes, menuConfig } = props;

  return visible ?
    <ul className={classes.popup} style={{ left: `${x}px`, top: `${y}px` }}>
      {menuConfig?.map(config => <li key={config.id}>
        <Button
          type="text"
          style={config.color ? { color: config.color } : {}}
          onClick={() => config.handler(record)}
          icon={config.icon}>
            {config.name}
        </Button>
      </li>)}
    </ul>
  : <></>;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    fetchTrips: fetchConsignments(MAP_EDIT_VIEW_TYPE),
  }, dispatch);
};

export default connect(null, mapDispatchToProps)(
  withStyles(styles, { injectTheme: true })(RightClickPopup),
);
