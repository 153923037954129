import {
  codListAllWorkersData,
  codTransactionHistoryData,
} from '../../api/FoodDashboardApi';
import { searchHubWithrealFilter } from '../../api/dashboard';
const viewControl = {
  pageTitle: 'COD Settlement',
  applyDateFilterSort: false,
  buckets: ['active', 'history'],
  navBar: {
    left: ['single_hub_search', 'date_filter'],
    right: [],
  },
  globalActions: [],
  dateFilterOptions: [{ key: 'booking_time', val: 'Business Date' }],
  searchHubData: searchHubWithrealFilter(false),
  singleHubSearchText: 'Current Store',
  hubSearchPlaceholder: 'Select Store',
  searchText: 'Search for stores',
};
const buckets = {
  active: {
    name: 'Active Transactions',
    status_filter: [],
    iconActions: [],
    isSubBucket: false,
    fetchData: codListAllWorkersData,
  },
  history: {
    name: 'Transaction History',
    status_filter: [],
    iconActions: [],
    isSubBucket: false,
    fetchData: codTransactionHistoryData,
  },
};

const bucketColor = {
  active: '#C41990',
  history: '#C41990',
};

export default { viewControl, buckets, bucketColor };
