import * as React from 'react';
import withStyles, { WithStylesProps } from 'react-jss';
import { CssType,
  ThemeType} from 'src/theming/jssTypes';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Modal, Table, message, Tag, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getDownloadsRoute } from 'src/routing/utils';
import { getFiltersPrettyName, formatDate, getFormattedDate, getFormattedTime,
  convert24hto12h }from 'src/utils/utils';
import { getLastScanPrettyName, getTagPrettyName, getFalseDeliveryPrettyName,
   getShipperNextStepsPrettyName, getInternalNdrPrettyName,
  getAutoAllocationPrettyName }
from 'src/utils/domesticConsignment';
import { getTypeValue, isValid, getSearchAPIObject } from 'src/components/pages/GenericConsignment/utils';
import { getConsignmentStatusPrettyName } from 'src/utils/consignmentStatus';
import { useTranslation } from 'react-i18next';
import { setSelectedRowKeys } from 'src/actions/genericPageActions';
import { useHistory } from 'react-router-dom';
import { PageData } from 'src/types/page.types';
import { downloadObjectViewDataObjects } from 'src/api/objectView';

const styles = (theme: ThemeType): CssType => ({
  tableContainer: {
    flex: '1 1 0',
    overflow: 'auto',
    borderTop: '1px solid #D9D9D9',
    '& .ant-table-tbody > tr > td, .ant-table-thead > tr > th' : {
      padding: 8,
      wordBreak: 'break-word',
    },
  },
  customPrimaryButton: {
    backgroundColor: theme.colors.primaryColor,
    color: theme.colors.textOnDarkBg,
  },
});

interface IProps extends
    WithStylesProps<ReturnType<typeof styles>>,
    ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {
      isVisible: boolean;
      params: any;
      onModalClose: () => void;
      downloadHus: boolean;
}

const DownloadsModal = (props: IProps) => {
  const {
    onModalClose,
    isVisible,
    downloadHus,
    viewData,
    globalFilters,
    full_column_list,
    classes,
  } = props;

  const history = useHistory();
  const { t } = useTranslation();

  const [isSaving, setIsSaving] = React.useState(false);

  const handleModalClose = () => {
    onModalClose();
  };

  const handleModalOk = async() => {
    setIsSaving(true);
    const requestData = viewData.getFetchViewDataObjectsRequest(globalFilters);
    delete requestData.pagination;
    const response = await downloadObjectViewDataObjects(requestData);
    if (response.isSuccess) {
      message.success('Download request succesfully created');
    } else {
      message.error(response.errorMessage);
    }
    setIsSaving(false);
    handleModalClose();
    history.push(getDownloadsRoute());
  };

  const getFilterObjectDetail = (name, value: any) => {
    if (name === 'statusList') {
      return value && value.length > 0 ?
            value.map((ele) => { return getConsignmentStatusPrettyName(ele); }).join(', ') : [];
    }
    if (name === 'tagList') {
      return value && value.length > 0 ?
            value.map((ele) => { return getTagPrettyName(ele); }).join(', ') : [];
    }
    if (name === 'timeSinceLastScanList') {
      return value && value.length > 0 ?
            value.map((ele) => { return getLastScanPrettyName(ele); }).join(', ') : [];
    }
    if (name === 'isFalseDelivery') {
      return getFalseDeliveryPrettyName(value);
    }
    if (name === 'shipperNextSteps') {
      return  value && value.length > 0 ?
      value.map((ele) => { return getShipperNextStepsPrettyName(ele); }).join(', ') : [];
    }
    if (name === 'allocationSourceList') {
      return  value && value.length > 0 ?
      value.map((ele) => { return getAutoAllocationPrettyName(ele); }).join(', ') : [];
    }
    if (name === 'internalNDRList') {
      return  value && value.length > 0 ?
      value.map((ele) => { return getInternalNdrPrettyName(ele); }).join(', ') : [];
    }
    if (name === 'proximityList') {
      return value && value.length > 0 ?
          value.map((x) => { return x.toUpperCase(); }).join(', ')
        :[];
    }
    if (name === 'referenceNumberListInclusive') {
      return value.length;
    }
    if (name === 'bagReferenceNumberList') {
      return value.length;
    }
    if (name === 'referenceNumberList') {
      return (
        <span>
          {value.length}
          <Tooltip title={value.join(', ')}>
          <a ><InfoCircleOutlined style={{ marginLeft: 5 }}></InfoCircleOutlined></a>
          </Tooltip>
        </span>
      );
    }
    if (name === 'storeHubIdList') {
      return  value ? value : null;
    }
    if (
      name === 'exceptionIdList' ||
      name === 'nextHubIdList' ||
      name === 'destinationHubIdList' ||
      name === 'assignedRider'
    ) {
      return value && value.length > 0
        ? value
            .map((x) => {
              return x.label;
            })
            .join(', ')
        : [];
    }

    return getTypeValue(value);
  };

  const getColumnPrettyName = (columnId) => {
    const x = full_column_list.find(x => x.column_id === columnId);
    return x.pretty_name;
  };

  const getDataSource = () => {
    const selectedRowKeys = viewData.selectedRowKeys;
    const sortFilters = {};
    const appliedFilters = {...viewData.appliedFilters};
    const customerIdList = appliedFilters.customerIdList &&
      appliedFilters.customerIdList.map(x => x.label);
    const serviceIdList = appliedFilters.serviceIdList &&
      appliedFilters.serviceIdList.map(x => x.label);
    const courierPartnerList = appliedFilters.carrier &&
        appliedFilters.carrier.map(x => x.label);
    const timeSinceLastScanList = appliedFilters.timeSinceLastScan &&
    appliedFilters.timeSinceLastScan.map(x => getLastScanPrettyName(x));
    const tagList = appliedFilters.tag &&
    appliedFilters.tag.map(x => getTagPrettyName(x));
    const searchFilterObj = selectedRowKeys && selectedRowKeys.length ?
    { referenceNumberList: selectedRowKeys } :
    getSearchAPIObject(appliedFilters.searchType, appliedFilters.searchValue);
    const storeHubIdList = appliedFilters.storeHubId &&
    appliedFilters.storeHubId.map(x => x.key);
    const constraintTagsList = appliedFilters.deliveryType?.map(x => x.label);
    const body: any = {
      timeFilters: globalFilters.timeFilters,
      movementType: appliedFilters.movementType,
    };
    const lists = {
      statusList: appliedFilters.statusList || [],
      customerIdList: customerIdList || [],
      hubIdList: globalFilters.hubIdList || [],
      bookingServiceTypeIdList: appliedFilters.bookingServiceTypeIdList || [],
      attemptCountList: appliedFilters.attemptCountList || [],
      ratingList: appliedFilters.rating || [],
      consignmentEmailCountList: appliedFilters.consignmentEmailCountList || [],
      consigneeCodeList: appliedFilters.consigneeCodeList || [],
      shipperNextSteps: appliedFilters.shipperNextSteps || [],
      internalNDRList: appliedFilters.internalNDRList || [],
      courierPartnerList: courierPartnerList || [],
      timeSinceLastScanList: timeSinceLastScanList || [],
      tagList: tagList || [],
      orderTypeList : appliedFilters.orderTypeList || [],
      deliveryTypeList: appliedFilters.deliveryTypeList || [],
      proximityList : appliedFilters.proximityList || [],
      referenceNumberListInclusive : appliedFilters.referenceNumberListInclusive || [],
      salesOrderNumberList: appliedFilters.so_number_search || [],
      destinationPincodeCityList: appliedFilters.destinationPincodeCityList || [],
      storeHubIdList: storeHubIdList || [],
      pickupAttemptCountList: appliedFilters.pickupAttemptCountList || [],
      bagReferenceNumberList: appliedFilters.bagReferenceNumberList || [],
      constraintTagsList: appliedFilters.constraintTagsList || [],
      rescheduledReasons: appliedFilters.rescheduledReasons || [],
      customerReferenceNumberList:
        appliedFilters.customerReferenceNumberList || [],
      customerReferenceNumberListInclusive:
        appliedFilters.customerReferenceNumberListInclusive || [],
      movementTypeList: appliedFilters.movementTypeList || [],
      executionStatusList: appliedFilters.executionStatusList || [],
      workerIdList: appliedFilters.workerIdList || [],
      destinationPincodeList: appliedFilters.destinationPincodeList || [],
      destinationCityList: appliedFilters.destinationCityList || [],
      destinationStateList: appliedFilters.destinationStateList || [],
      destinationZoneList: appliedFilters.destinationZoneList || [],
      consignorCodeList: appliedFilters.consignorCodeList || [],
      legTypeList: appliedFilters.legTypeList || [],
      areaList: appliedFilters.areaList || [],
      dotFilter: appliedFilters.dotFilter || [],
      consignmentStatus: appliedFilters.consignmentStatus || [],
      allocationSourceList: appliedFilters.allocationSourceList || [],
      nonRegularOrders: appliedFilters.nonRegularOrders || [],
      workerType: appliedFilters.workerType || [],
      assignedRider: appliedFilters.assignedRider || [],
      ...searchFilterObj,
    };

    const singleValueFilters = {
      isFalseAttempt: appliedFilters.falseAttempt,
      isCod: appliedFilters.isCod,
      undeliveredFeedbackPresent: appliedFilters.undeliveredFeedbackPresent,
      hasRemarks: appliedFilters.hasRemarks,
      isFalseDelivery: appliedFilters.isFalseDelivery,
      nextSteps: appliedFilters.nextSteps,
      purchasingGroup : appliedFilters.purchasingGroup,
      consignmentType: appliedFilters.consignmentType,
      isCNVDone: appliedFilters.isCNVDone,
      isAddressUpdated: appliedFilters.isAddressUpdated,
      syncedStatus: appliedFilters.syncedStatus,
      isPaymentDone: appliedFilters.isPaymentDone,
      riderAssigned: appliedFilters.riderAssigned,
      zoneAssigned: appliedFilters.zoneAssigned,
      creationBeforeElevenAM: appliedFilters.creationBeforeElevenAM,
      tripAssigned: appliedFilters.tripAssigned,
      assignedToRider: appliedFilters.assignedToRider,
      orderBreached: appliedFilters.orderBreached,
      isPartialDelivery: appliedFilters.isPartialDelivery,
      consignmentTripReferenceId: appliedFilters.consignmentTripReferenceId,
    };
    const dataSource:any = [];
    const listKeys = Object.keys(lists);
    listKeys.length > 0 && listKeys.forEach((x) => {
      if (lists[x] && lists[x].length > 0) {
        const val = lists[x];
        dataSource.push({
          filter: x !== 'referenceNumberList' ?
                  getFiltersPrettyName(x) : 'Reference Number',
          key:x,
          details:  x !== 'referenceNumberList' ? getFilterObjectDetail(x, val) : val.length,
        });
      }
    });
    const singleValueFiltersKeys = Object.keys(singleValueFilters);
    singleValueFiltersKeys.length > 0 && singleValueFiltersKeys.forEach((x) => {

      if (isValid(singleValueFilters[x])) {
        dataSource.push({
          filter:getFiltersPrettyName(x),
          key:x,
          details: getFilterObjectDetail(x, singleValueFilters[x]),
        });
      }
    },
    );
    body.timeFilters.forEach((x) => {
      dataSource.push({
        filter: getColumnPrettyName(x.columnName),
        key:x.columnName,
        details: `${formatDate(x.fromDateString)} -
          ${formatDate(x.toDateString)}`,
      });
    });

    if (body.sortBy) {
      dataSource.push({
        filter: getFiltersPrettyName('sortBy'),
        key:'sortBy',
        details: getColumnPrettyName(body.sortBy),
      });
    }

    if (body.descendingOrder) {
      dataSource.push({
        filter: getFiltersPrettyName('descendingOrder'),
        key:'descendingOrder',
        details: String(body.descendingOrder),
      });
    }
    if (body.movementType) {
      dataSource.push({
        filter: getFiltersPrettyName('movementType'),
        key:'movementType',
        details: body.movementType,
      });
    }
    if (appliedFilters.exceptionIdList) {
      dataSource.push({
        filter: getFiltersPrettyName('exceptionIdList'),
        key: 'exceptionIdList',
        details: getFilterObjectDetail('exceptionIdList', appliedFilters.exceptionIdList),
      });
    }
    if (appliedFilters.nextHubIdList) {
      dataSource.push({
        filter: getFiltersPrettyName('nextHubIdList'),
        key: 'nextHubIdList',
        details: getFilterObjectDetail('nextHubIdList', appliedFilters.nextHubIdList),
      });
    }
    if (appliedFilters.destinationHubIdList) {
      dataSource.push({
        filter: getFiltersPrettyName('destinationHubIdList'),
        key: 'destinationHubIdList',
        details: getFilterObjectDetail('destinationHubIdList', appliedFilters.destinationHubIdList),
      });
    }
    if(appliedFilters.nextAttemptDate) {
        dataSource.push({
          filter: getFiltersPrettyName('nextAttemptDate'),
          key: 'nextAttemptDate',
          details: getFormattedDate(appliedFilters.nextAttemptDate),
        });
    }
    if(appliedFilters.deliveryTimeslot) {
      dataSource.push({
        filter: getFiltersPrettyName('deliveryTimeslot'),
        key: 'deliveryTimeslot',
        details: getFormattedTime(appliedFilters.deliveryTimeslot),
      });
  }
    if(appliedFilters.timeSlot) {
      dataSource.push({
        filter: getFiltersPrettyName('timeSlot'),
        key: 'timeSlot',
        details: appliedFilters.timeSlot
          .map((timeArr) =>
            timeArr.map((time) => convert24hto12h(time)).join(' - '),
          )
          .join(', '),
        });
    }
    if(appliedFilters.dayPartFilter) {
      dataSource.push({
        filter: getFiltersPrettyName('dayPartFilter'),
        key: 'dayPartFilter',
        details: appliedFilters.dayPartFilter
          .map((timeArr) =>
            timeArr.map((time) => convert24hto12h(time)).join(' - '),
          )
          .join(', '),
        });
    }
    return dataSource;
  };

  const generateDataColumns = () => {
    const columnData: any = [];
    const indexList = [{
      label: t('filter'),
      key: 'filter',
    }, {
      label: t('details'),
      key: 'details',
    }];
    indexList.forEach((item) => {
      const itemToPush = {};
      itemToPush['title'] = item.label;
      itemToPush['dataIndex'] = item.key;
      itemToPush['key'] = item.key;
      if (item.key === 'filter') {
        itemToPush['render'] = text => <div style={{ minWidth: 120 }}>{text}</div>;
      }
      if (item.key === 'details') {
        itemToPush['render'] = tags => {
          if(Array.isArray(tags)) {
            tags.map((tag) => {
              return (
                <Tag>{tag.label ? tag.label : tag}</Tag>
              );
            });
          }
          return (
            <Tag>{tags}</Tag>
          );
        };
      }
      columnData.push(itemToPush);
    });
    return columnData;
  };

  return (
    <Modal
      title= {downloadHus ? ' Download Child CN' : t('download_dump')}
      visible={isVisible}
      width={600}
      okText={t('download')}
      onCancel={handleModalClose}
      maskClosable={false}
      destroyOnClose
      onOk={handleModalOk}
      confirmLoading={isSaving}
      okButtonProps={{ className: classes.customPrimaryButton }}
    >
      <div className={classes.tableContainer}>
        <Table
          columns={generateDataColumns()}
          dataSource={getDataSource()}
          pagination={false}
        />
      </div>
    </Modal>
  );
}

const mapStateToProps = ({ genericPageReducer, cachedData, masterData } , ownProps) => {
  const { params } = ownProps;
  const { pageId, viewIndex, sectionIndex } = params;
  const pageData: PageData = genericPageReducer[pageId];
  const viewData = pageData.metadata.sectionList[sectionIndex][viewIndex];
  const currHub = cachedData.currHub;

  return {
    viewData,
    globalFilters: {
      hubIdList: [currHub?.id],
      timeFilters: pageData.appliedGlobalTimeFilter ? [{...pageData.appliedGlobalTimeFilter}] : null,
    },
    full_column_list: masterData.ops_full_column_list,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    setSelectedRowKeys,
  }, dispatch);
};

const DownloadsModalStyled = withStyles(styles, { injectTheme: true })(DownloadsModal);

export default connect(mapStateToProps, mapDispatchToProps)(DownloadsModalStyled);
