import * as React from 'react';
import withStyles from 'react-jss';
import { CssType,
  ThemeType} from '@theming/jssTypes';
import { bindActionCreators } from 'redux';
import { getRetailTripDetailsRoute, getRetailTripManagerRoute,
  getRetailTripManagerMapEditRoute } from '../../../../routing/utils';
import Navbar from '@components/navbar/Navbar';
import { DownloadOutlined, ReloadOutlined, LeftOutlined, AimOutlined } from '@ant-design/icons';
import { Button, message, Tooltip, DatePicker, Select } from 'antd';
import { connect } from 'react-redux';
import { customFormatDate, formatDateTime, getFiltersPrettyName }from '@utils/utils';
import DocumentTitle from 'react-document-title';
import {
  tablePaginationStyle,
} from '@theming/cssSnippets/commonSnippets';
import * as moment from 'moment';
import { getAllOptimizerRequests, retryAutoAllocate, getAllOptimizerRequestsPlanningType,
  getCustomTripRequests } from '@api/trips';
import { searchHubData } from '@api/dashboard';
import MultiSelectFilter from '@components/common/MultiselectFilter';
import { Row, Col, Table, Modal } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { getDetailsRoute, getTripTrackingLinkByPlanningId } from '@routing/utils';
import { setCurrentHubReports } from '@actions/generateReportsActions';
import { getAllBulkCNLabelPrintRequests, retryPrintManifest,
  getCNUploadRequests } from '@api/genericConsignmentView';
import FailureArrayHandler from '@components/common/FailureArrayHandler';
import { downloadColumnsPrintLabel, downloadColumns, customTripColumns,
  optimisationSummaryChildColumns, droppedCNColumns,
  getFilterParams, statusPrettyMapping, jobTypes, CNUploadRequestsColumns,
  jobType, csvRequestType, consignmentUploadTypes } from '@components/pages/retail/requests/OptimizerRequests.utils';
import FixedHeaderTable from 'src/components/common/FixedHeaderTable/FixedHeaderTable';
import { POST } from '@api/apiHandler';
import { API_BASE_URL } from '@src/globals';
import { depositModeOptions } from '@components/pages/bankDeposit/masterData';
import { downloadFileData } from '@utils/utils';
import { withTranslation } from 'react-i18next';

const styles = (theme: ThemeType): CssType => ({
  modalText: {
    fontSize: theme.sizes.bodyTextLarge,
    fontWeight: 'bold',
  },
  mainDiv: {
    '& .ant-checkbox-inner': {
      width:'15px',
      height: '15px',
    },
    '& .anticon-down': {
      fontSize: theme.sizes.bodyText,
    },
    display:'flex',
    flexDirection:'column',
    height:'100vh',
    backgroundColor: theme.colors.pageBg,
  },
  transparentButton: {
    background: theme.colors.surfaceBg,
    border: `0.5 solid ${theme.colors.borderColor}`,
    borderRadius: '0px',
    color: theme.colors.textOnLightBg,
    boxShadow: 'none',
    margin: '10px 10px',
    '&:hover': {
      background: `${theme.colors.HoverOnWhiteBg} !important`,
      color: `${theme.colors.primaryText} !important`,
      boxShadow: 'none',
    },
    '&:focus': {
      backgroundColor: theme.colors.selectionOnDarkBg,
    },
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: 10,
    '& .ant-select-selector': {
      background: `${theme.colors.surfaceBg} !important`,
      color: theme.colors.textOnLightBg,
    },
    '& .ant-select-arrow': {
      color: theme.colors.textOnLightBg,
    }
  },
  datePickerDiv: {
    margin: '10px 12px',
    width: '100%',
    display: 'flex',
    gap: '10px',
    '& .ant-picker': {
      backgroundColor: theme.colors.surfaceBg,
      color: theme.colors.textOnLightBg,
      borderRadius: '0px !important',
      '& .ant-picker-input > input': {
        color: theme.colors.textOnLightBg,
      },
      '& .ant-picker-suffix': {
        color: theme.colors.textOnLightBg,
      },
    },
    '& .anticon svg': {
      color: theme.colors.textOnLightBg,
    },
    '& .ant-select-selector': {
      borderRadius: '0px !important',
    }
  },
  multiSelectStyle: {
    margin: '10px 5px',
    display: 'flex',
    '& .ant-btn': {
      backgroundColor: theme.colors.surfaceBg,
      color: theme.colors.textOnLightBg,
      borderRadius: '0px !important',
    },
  },
  failed: {
    cursor: 'pointer',
    color: theme.colors.warning,
  },
  error: {
    wordBreak: 'break-word',
  },
  retryButton: {
    margin: '0px 5px',
    display: 'inline-block',
  },
  uploadRequestsButton: {
    color: theme.colors.hyperlink,
    marginLeft: '0.2rem',
    cursor: 'pointer',
  },
});

class RequestsPage extends React.Component<any, any> {

  state: any = {
    requests:[],
    isLoading:false,
    currentDate : moment().format('YYYY-MM-DD'),
    searchedHubs: [],
    selectedHubs: [],
    failureArray: [],
    isFailureArrayModalVisible: false,
    customTripDate: {
      start: moment().format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD'),
    },
    customTripRequests: [],
    jobType: 'ASYNC_CUSTOM_TRIP_CREATION',
    droppedCNData : [],
    showDroppedCNModal: false,
    dataDownloadURLInsideModal : '',
  };

  minimumHubSearchLength = this.props.minSearchLength || 2;

  getRequestsData = async() => {
    const { currentDate, selectedHubs , customTripDate, jobType} = this.state;
    const { showPrintLabelRequests, showCustomTripRequests, showCNUploadRequests, showCustomBulkDeleteTripRequests } = this.props;
    let planningType = '';
    if (this.props.location.state) {
      planningType = this.props.location.state.action;
    }
    const hubIds = selectedHubs && selectedHubs.length ? selectedHubs : [];
    this.setState({ isLoading : true });
    let response: any = {};
     if(showPrintLabelRequests) {
      const params = {
        date: currentDate,
        hub_ids: hubIds,
      };
      response =  await getAllBulkCNLabelPrintRequests(params);
     } else if(showCustomTripRequests || showCNUploadRequests || showCustomBulkDeleteTripRequests) {
        const params = {
          fromDate: customTripDate.start,
          toDate: customTripDate.end,
          jobType,
        };
        response = await getCustomTripRequests(params);
     }
     else if (planningType.length) {
      response = await getAllOptimizerRequestsPlanningType(currentDate, hubIds, planningType);
     } else {
      response = await getAllOptimizerRequests(currentDate, hubIds);
     }
    if (response.isSuccess) {
      showCustomTripRequests || showCNUploadRequests || showCustomBulkDeleteTripRequests ?
        this.setState({customTripRequests: response.data?.requests?.length ?
        response.data.requests : []}) : this.setState({requests: response.data});
    } else {
      message.error(response.errorMessage);
    }

    this.setState({
      isLoading :  false,
    });
  };
  async componentDidMount() {
    const { showCNUploadRequests, showCustomBulkDeleteTripRequests } = this.props;
    if(showCNUploadRequests) {
      this.setState({ jobType : 'CONSIGNMENT_BULK_REQUESTS' });
    }
    if(showCustomBulkDeleteTripRequests){
      this.setState({ jobType : 'ASYNC_BULK_DELETE_TRIPS' });
    }

    await this.setCurrentHubAsDefaultFilter();
    await this.getRequestsData();
    this.startPoller();
  }

  setCurrentHubAsDefaultFilter = async () => {
    const { currHub } = this.props;

    if (!currHub) {
      return;
    }

    const searchedHubs = [{
      key: currHub.id,
      label: `${currHub.name}, ${currHub.code}`,
    }];

    this.setState({
      searchedHubs,
      selectedHubs: [currHub.id],
    });

    this.props.setCurrentHubReports(searchedHubs);
  };

  onRefreshClick = async() => {
    await this.getRequestsData();
  };
  pollingAgent:any = null;
  pollApi = () => {
    const { requests, customTripRequests, customTripDate, jobType } = this.state;
    const { showPrintLabelRequests, showCustomTripRequests, showCNUploadRequests , showCustomBulkDeleteTripRequests} = this.props;
    const requestList = (showCustomTripRequests || showCustomBulkDeleteTripRequests) ? customTripRequests : requests;
    const shouldPoll = requestList.findIndex(x =>  x.status?.toLowerCase() === 'processing' ||
      x.status?.toLowerCase() === 'pending');
    if (shouldPoll !== -1) {
      this.pollingAgent = setTimeout(async() => {
        let planningType = '';
        if (this.props.location.state) {
          planningType = this.props.location.state.action;
        }
        const { currentDate, selectedHubs } = this.state;
        const hubIds = selectedHubs && selectedHubs.length ? selectedHubs : [];
        let response: any = {};
        if (showPrintLabelRequests) {
          const params = {
            date: currentDate,
            hub_ids: hubIds,
          };
          response = await getAllBulkCNLabelPrintRequests(params);
        } else if(showCustomTripRequests || showCNUploadRequests || showCustomBulkDeleteTripRequests) {
          const params = {
            fromDate: customTripDate.start,
            toDate: customTripDate.end,
            jobType,
          };
          response = await getCustomTripRequests(params);
        } else if (planningType.length) {
          response = await getAllOptimizerRequestsPlanningType(currentDate, hubIds, planningType);
        } else {
          response = await getAllOptimizerRequests(currentDate, hubIds);
        }
        if (response.isSuccess) {
          showCustomTripRequests || showCustomBulkDeleteTripRequests || showCNUploadRequests ? this.setState({
            customTripRequests: response.data?.requests?.length ? response.data.requests : [],
          }) : this.setState({requests: response.data});
        }
        this.poller();
      }, 5000);
    } else {
      this.stopPolling();
    }
  };
  poller = async() => {
    if (this.pollingAgent) {
      this.stopPolling();
      this.pollApi();
    }
  };
  startPoller = () => {
    if (!this.pollingAgent) {
      this.pollApi();
    }
  };
  stopPolling = () => {
    if (this.pollingAgent) {
      clearTimeout(this.pollingAgent);
      this.pollingAgent = undefined;
    }
  };
  componentWillUnmount () {
    this.stopPolling();
  }
  handleRetry = async(id) => {
    const { showPrintLabelRequests } = this.props;
    this.setState({ isLoading : true });
    const response  = showPrintLabelRequests ?
                        await retryPrintManifest({request_id: id}) : await retryAutoAllocate(id);
    if (response.isSuccess) {
      message.success('Retry request successfully created');
      await this.onRefreshClick();
      this.startPoller();
    } else {
      message.error(response.errorMessage);
      this.setState({ isLoading : false });
    }
  };

  renderFilterValues = (value) => {
    return value || null;
  };
  renderFiltersColumn = (record) => {
    const { showAutoCancel } = this.props;
    let filters = getFilterParams(record);

    if(this.state.jobType === 'ASYNC_AUTO_CANCEL_CONSIGNMENT' && showAutoCancel){
      filters = filters.filter((el => el.key !== 'hubCode'));
    } else {
      const dataFilter = ['fromDate', 'toDate'];
      filters = filters.filter((el => !dataFilter.includes(el.key)));
    }

    return filters.map((item) => {
      return (
        <div>
          <b>{getFiltersPrettyName(item.key)}: </b>
          <span>{this.renderFilterValues(item.value)}</span>
        </div>
      );
    });
  };
  handleCNUploadRequests = async (jobId, requestType) => {
    let response: any = {};
    const params = {
      job_id: jobId,
      type: csvRequestType[requestType],
      job_type: this.state.jobType,
    };
    response = await getCNUploadRequests(params);
    if (response.isSuccess) {
      message.success('Download Completed');
      downloadFileData(response.data, 'consignments_upload', '.csv');
    }else {
      message.error(response.errorMessage);
    }
  };
  openDroppedCNDataModal = (data) => {
    this.setState({
      showDroppedCNModal : true,
      droppedCNData : data.dropped_cn_data,
      dataDownloadURLInsideModal : data.url,
    });
  };
  closeDroppedCNDataModal = () => {
    this.setState({
      droppedCNData : [],
      showDroppedCNModal : false,
      dataDownloadURLInsideModal : '',
    });
  };
  generateDroppedDataColumns = () => {
    const columnData: any = [];
    const indexList = droppedCNColumns;
    indexList.forEach((item) => {
      const itemToPush = {};
      itemToPush['title'] = item.label;
      itemToPush['dataIndex'] = item.key;
      itemToPush['key'] = item.key;
      itemToPush['width'] = 80;
      if ( item.key === 'reference_number' ){
        itemToPush['render'] = (x, record) => {
          return( <Link
                    to={{pathname: getDetailsRoute(x)}}
                    target="_blank"
                  >
                      {x}
                </Link>);
        };
      }
      if ( item.key === 'drop_reason_code' ){
        itemToPush['width'] = 180;
      }
      columnData.push(itemToPush);
    });
    return columnData;
  };
  generateDataColumns = () => {
    const { classes, showPrintLabelRequests, showCustomTripRequests,
      showCNUploadRequests, showCustomBulkDeleteTripRequests, t } = this.props;
    const columnData: any = [];
    let indexList;
    if(showPrintLabelRequests){
      indexList = downloadColumnsPrintLabel;
    } else if(showCustomTripRequests || showCustomBulkDeleteTripRequests) {
      indexList = customTripColumns;
    } else if (showCNUploadRequests) {
      indexList = CNUploadRequestsColumns;
    } else {
      indexList = downloadColumns;
    }
    indexList.forEach((item) => {
      const itemToPush = {};
      itemToPush['title'] = t(item.label);
      itemToPush['dataIndex'] = item.key;
      itemToPush['key'] = item.key;
      itemToPush['width'] = 100;
      if (item.key === 'actions') {
        itemToPush['width'] = 80;
        itemToPush['render'] = (x, record) => {
          return (
            <div>
            {(record.canRetry || (showPrintLabelRequests && record.shouldRetry)) ?
            <div className={classes.retryButton}>
              <Tooltip title = {t('Retry')}>
                <ReloadOutlined
                onClick = {() => this.handleRetry(showPrintLabelRequests ? record.uuid: record.id)}
                />
                </Tooltip>
            </div>: null}

              {(record.url || record.response?.url) &&
                <a href={record.url || record.response?.url}>
                  <DownloadOutlined style={{ fontSize: '20px' }} />
                </a>
                }
              {( indexList===downloadColumns && record.planningId && record.status!=='processing' )
              ? <Link
                    to={{pathname: getTripTrackingLinkByPlanningId(record.planningId)}}
                    target="_blank"
                  >
                    <AimOutlined style={{ paddingLeft:'10px',fontSize: '20px' }} />
                </Link>
              : null}
                </div>
          );
        };
      }
      if (item.key === 'planningId'){
        itemToPush['width'] = 120;
        itemToPush['render'] = (x, record) => {
          return <div style = {{ textTransform:'capitalize' }}>{x}</div>;
        };
      }
      if (item.key === 'optimisation_summary'){
        itemToPush['width'] = 180;
        const childEle = [];
        optimisationSummaryChildColumns.forEach((item) => {
              const childDataObj = {};
              childDataObj['title'] = t(item.label);
              childDataObj['key'] = item.key;
              childDataObj['dataIndex'] = item.key;
              if (item.key === 'dropped_cn_count'){
                childDataObj['render'] = (x, record) => {
                  return( <div>
                    <a
                      onClick = {() => {this.openDroppedCNDataModal(record);}}
                    >
                      {x}
                    </a>
                  </div>);
                };
              }
              if (item.key === 'planned_trips_count'){
                childDataObj['render'] = (x, record) => {
                  if(record.planned_trips_reference_number?.length === 1){
                    return (
                      <Link to={getRetailTripDetailsRoute(
                        record.planned_trips_reference_number[0])}>
                        {record.planned_trips_reference_number[0]}
                      </Link>
                    );
                  }
                  return x;
                };
              }
              childEle.push(childDataObj);
        });
        itemToPush['children'] = childEle;
      }
      if (item.key === 'constraint_data'){
        itemToPush['width'] = 120;
        itemToPush['render'] = (x, record) => {
          return <div className={classes.error}>{x?.join(', ')}</div>;
        };
      }
      if (['createdAt', 'updatedAt', 'lastAttemptTime'].indexOf(item.key) !== -1) {
        itemToPush['width'] = 170;
        itemToPush['render'] = (x) => {
          return (formatDateTime(x));
        };
      }
      if (item.key === 'status') {
        itemToPush['render'] = (x, record) => {
          return <div style = {{ textTransform:'capitalize' }}>{statusPrettyMapping[x] || x}</div>;
        };
      }
      if (item.key === 'batchId') {
        itemToPush['render'] = (x, record) => {
          return <div style = {{ textTransform: 'uppercase' }}>
            {customFormatDate(record['createdAt'], 'DDMMMYYYY_HHmmss', '')}{x ? `_${x}` : ''}
          </div>;
        };
      }
      if (item.key === 'error') {
        itemToPush['width'] = showPrintLabelRequests ? 260 : 240;
        itemToPush['render'] = (x, record) => {
          if (!x || record.status !== 'failed') {
            return;
          }
          const err = JSON.parse(x);
          return <div className={classes.error}>{err.message}</div>;
        };
      }
      if (item.key === 'hubCode') {
        itemToPush['width'] = 90;
        itemToPush['render'] = (x, record) => {
          return <div style = {{ textTransform:'capitalize' }}>{x}</div>;
        };
      }
      if (item.key === 'failed') {
        itemToPush['width'] = 120;
        itemToPush['render'] = (x, record) => {
          if(record?.extraDetails?.failed?.length){
          return <div className={classes.failed} onClick={() =>
            this.setState({ isFailureArrayModalVisible: true,
              failureArray: record.extraDetails.failed })}>
           Failed</div>;
          }
          return;
        };
      }
      if (item.key === 'filterParams') {
        itemToPush['width'] = 250;
        itemToPush['render'] = (x, record) => {
          return this.renderFiltersColumn(record);
        };
      }
      if(item.key === 'errors'){
        itemToPush['width'] = 500;
        itemToPush['render'] = (err) => {
          return err?.length ? err.map((x) => {
            return <div className={classes.error}>{x.message}</div>;
          }) : null;
        };
      }
      if(item.key === 'labelType'){
        itemToPush['width'] = 270;
      }
      if (consignmentUploadTypes.indexOf(item.key) > -1) {
        itemToPush['render'] = (x, record) => {
          return (
            <div>
              <span>{x}</span>
              {x > 0 && (
                <Tooltip title={t("Download Excel")}>
                  <DownloadOutlined
                    onClick={() =>
                      this.handleCNUploadRequests(record.jobId, item.key)
                    }
                    className={classes.uploadRequestsButton}
                  />
                </Tooltip>
              )}
            </div>
          );
        };
      }
      columnData.push(itemToPush);
    });
    return columnData;
  };
  handleDateChange = (date) => {
    this.setState({
      currentDate : date && date.format('YYYY-MM-DD'),
    }, () => this.getRequestsData());
  };
  handleHubSearch = async (queryString: string) => {
    if (!queryString.trim()) {
      this.setState({ searchedhubs: [] });
      return [];
    }
    if (queryString.length >= this.minimumHubSearchLength) {
      const response = await searchHubData(queryString);
      let searchedHubs = response.data;
      searchedHubs = searchedHubs ? searchedHubs.map((hub: any) => ({
        key: hub.id, label: `${hub.name}, ${hub.code}`,
      })) : [],
          this.setState({ searchedHubs });
      this.props.setCurrentHubReports(searchedHubs);
      return searchedHubs;
    }
    return[];
  };
  handleApplyHubFilter = () => {
    this.getRequestsData();
  };
  getSelectedOptions = (allSearchedHubs, hubIds) => {
    const selectedHubs: any = [];
    if (allSearchedHubs && !allSearchedHubs.length) {
      return selectedHubs;
    }
    hubIds && hubIds.length ?
     hubIds.forEach((id) => {
       const obj = allSearchedHubs.find((e) => {
         return e.key === id;
       });
       selectedHubs.push(obj);
     }) : null;
    return selectedHubs;
  };

  handleDatesSelect = (dateArr) => {
    const date: any = { start: moment(), end: moment() };
    if (dateArr?.length === 2) {
      date.start = dateArr[0];
      date.end = dateArr[1];
    }
    this.setState({
      customTripDate: {
        start: date.start.format('YYYY-MM-DD'),
        end: date.end.format('YYYY-MM-DD'),
      },
    }, () => this.getRequestsData());
  };

  handleRequestTypeSelect = (val) => {
    this.setState({ jobType : val }, () => {
      this.onRefreshClick();
    });
  };

  renderDefaultFilters = () => {
    const {
      classes,
      searchedHubs,
      showCustomTripRequests,
      showCustomBulkDeleteTripRequests,
      showCNUploadRequests,
      allowTripUpdate, history, t } = this.props;
    const { selectedHubs, customTripDate, jobType } = this.state;
    const { showAutoCancel } = this.props;

    let jobsTypeArray = jobTypes;

    if(!showAutoCancel){
      jobsTypeArray = jobsTypeArray.filter(el => (el.id !== 'ASYNC_AUTO_CANCEL_CONSIGNMENT'));
    }

    return (
      <div className={classes.topBar}>
        <div style={{  display:'flex ' }}>
          <div className = {classes.datePickerDiv}>
          {(showCustomTripRequests || showCustomBulkDeleteTripRequests) &&
            <Select placeholder={t("Select Type")} value={jobType}
              onChange={this.handleRequestTypeSelect}
            >
              {jobsTypeArray.map((ele) => (
                <Select.Option key={ele.id} value={ele.id}>
                  {t(ele.name)}
                </Select.Option>
              ))}
            </Select>
          }
          {showCustomTripRequests || showCNUploadRequests || showCustomBulkDeleteTripRequests ?
            <DatePicker.RangePicker
              value={[moment(customTripDate.start), moment(customTripDate.end)]}
              format={'DD/MM/YYYY'}
              onChange={this.handleDatesSelect}
              ranges={{
                [t('Today')]: [moment(), moment()],
                [t('Last 7 Days')]: [moment().subtract(6, 'day'), moment()],
                [t('Last 30 Days')]: [moment().subtract(29, 'day'), moment()],
                [t('This Month')]: [
                  moment().startOf('month'),
                  moment().endOf('month'),
                ],
              }}
            ></DatePicker.RangePicker>
           :
            <DatePicker
              defaultValue = {moment()}
              className={classes.datePicker}
              format={'DD/MM/YYYY'}
              onChange={this.handleDateChange}
              allowClear={false}
          >
          </DatePicker>
          }
          </div>
          {!(showCustomTripRequests || showCustomBulkDeleteTripRequests) && !showCNUploadRequests &&
          <MultiSelectFilter
                className={classes.multiSelectStyle}
                onDropdownClose = {this.handleApplyHubFilter}
                onBackendSearch={this.handleHubSearch}
                placeholder={t("Select Hub")}
                title="Hub"
                onFilterChange={(selectedHubs) => {
                  this.setState({ selectedHubs });
                }}
                value={selectedHubs}
                options={this.getSelectedOptions(searchedHubs, selectedHubs)}
          />}
          </div>
          <div>
        <Button onClick={this.onRefreshClick} className={classes.transparentButton} >
          <ReloadOutlined style={{}} />
        </Button>
        <Button
            icon={<LeftOutlined />}
            className={classes.transparentButton}
            onClick = {() => history.goBack()}
          >{t('Back To Consignments View')}
          </Button>
        {
          allowTripUpdate  && !showCNUploadRequests ?
          <Button
            icon={<LeftOutlined />}
            className={classes.transparentButton}
            onClick = {() => history.push(getRetailTripManagerRoute())}
          >{t('Trip Manager Page')}
          </Button> : <></>
        }
        {!showCNUploadRequests ? <Button
            onClick = {() => history.push(getRetailTripManagerMapEditRoute())}
          >{t('Review Trips')}
        </Button> : null}
        </div>
      </div>
    );
  };
  showOneOrMoreMessage = () => {
    const { t } = this.props;
    message.info(t('Please select one or more consignment'));
  };
  getFailureArray = () => {
    const { failureArray } = this.state;
    return failureArray?.map((ele) => {
      return { reference_number: ele };
    }) || [];
  };

  failureColumns = [
    {
      title: 'Consignment Number',
      dataIndex: 'reference_number',
    },
  ];
  render() {
    const { classes, title, showCustomTripRequests, showCNUploadRequests,
      showCustomBulkDeleteTripRequests, t } = this.props;
    const { isLoading,  requests, isFailureArrayModalVisible, customTripRequests,
      showDroppedCNModal, droppedCNData, dataDownloadURLInsideModal } = this.state;
    return (
      <div className={classes.mainDiv}>
        <DocumentTitle title= {t(title) || t('Optimisation Requests')} />
        <Navbar pageTitle={title || t('Optimisation Requests')}/>
        <div>
          {this.renderDefaultFilters()}
        </div>
        <FixedHeaderTable
          loading={isLoading}
          dataSource={
            showCustomTripRequests || showCNUploadRequests || showCustomBulkDeleteTripRequests
              ? customTripRequests
              : requests
          }
          className = {classes.headerVerticalALignToMiddle}
          columns={this.generateDataColumns()}
          rowKey={(record: any) => record.createdAt}
        />
        {
          isFailureArrayModalVisible &&
          <FailureArrayHandler
          failureArray={this.getFailureArray()}
          isVisible={isFailureArrayModalVisible}
          onModalClose={() => this.setState({
            isFailureArrayModalVisible: false,
          })}
          failureColumns={this.failureColumns}
          width={300}
          >
         </FailureArrayHandler>
        }
        {
          showDroppedCNModal &&
          <Modal
          title={t('Dropped CNs')}
          visible={showDroppedCNModal}
          width={800}
          onCancel={this.closeDroppedCNDataModal}
          footer={null}
          destroyOnClose
          >
          <Row justify={'space-between'}>
            <Col span={8} className={classes.modalText}>{t('Total')} : {droppedCNData.length}</Col>
          </Row>
          <div style={{ marginTop: '20px' }} className={classes.tableContainer}>
            <Table
              scroll={{ y: 420 }}
              locale={{ emptyText: t('No CNs Dropped') }}
              pagination={false}
              dataSource={droppedCNData}
              columns={this.generateDroppedDataColumns()}
            />
          </div>
        </Modal>
        }
      </div>
    );
  }
}

const mapStateToProps = ({ masterData,  cachedData, generateReportsReducer }) => {
  return {
    columnList: masterData.ops_full_column_list,
    currHub: cachedData.currHub,
    searchedHubs: generateReportsReducer.searchedHubs,
    showAutoCancel: masterData.ops_dashboard_config?.
      parts_to_show?.auto_cancel_consignemnt_config?.enable || false,
    allowTripUpdate: masterData.ops_dashboard_config?.parts_to_show?.flm_trip_update,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setCurrentHubReports,
  }, dispatch);
};

const StyledRequestsPage = withStyles(styles, { injectTheme: true })(RequestsPage);

export default  withTranslation('translation')
      (connect(mapStateToProps, mapDispatchToProps)(StyledRequestsPage));
