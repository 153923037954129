import Icon from '@ant-design/icons';
import * as React from 'react';

const ThirdPartySvg = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Setup_Tooltip" transform="translate(-16.000000, -698.000000)">
                <g id="Group-6" transform="translate(0.000000, 58.000000)">
                    <g id="Third_Party" transform="translate(16.000000, 640.000000)">
                        <rect id="Rectangle" fillRule="nonzero" x="0" y="0" width="24" height="24"></rect>
                        <path d="M19.0511,4.79082 C18.522,4.79082 18.0931,5.21976 18.0931,5.74889 C18.0931,6.27802 18.522,6.70696 19.0511,6.70696 C19.5803,6.70696 20.0092,6.27802 20.0092,5.74889 C20.0092,5.21976 19.5803,4.79082 19.0511,4.79082 Z M16.4836,5.74889 C16.4836,4.3309 17.6331,3.1814 19.0511,3.1814 C20.4691,3.1814 21.6186,4.3309 21.6186,5.74889 C21.6186,7.16687 20.4691,8.31638 19.0511,8.31638 C17.6331,8.31638 16.4836,7.16687 16.4836,5.74889 Z" id="Shape" fill="#666666"></path>
                        <path d="M16.6123,8.59847 C17.2591,7.95168 18.1364,7.58832 19.0511,7.58832 C19.9658,7.58832 20.843,7.95168 21.4898,8.59847 C22.1366,9.24526 22.4999,10.1225 22.4999,11.0372 C22.4999,11.2506 22.4152,11.4553 22.2642,11.6062 C22.1133,11.7571 21.9086,11.8419 21.6952,11.8419 L16.4069,11.8419 C16.1935,11.8419 15.9888,11.7571 15.8379,11.6062 C15.687,11.4553 15.6022,11.2506 15.6022,11.0372 C15.6022,10.1225 15.9655,9.24526 16.6123,8.59847 Z M19.0511,9.19774 C18.5632,9.19774 18.0953,9.39154 17.7504,9.7365 C17.6044,9.88243 17.4855,10.0504 17.3969,10.2325 L20.7052,10.2325 C20.6166,10.0504 20.4977,9.88243 20.3518,9.7365 C20.0068,9.39154 19.5389,9.19774 19.0511,9.19774 Z" id="Shape" fill="#666666"></path>
                        <path d="M4.94896,4.79082 C4.41983,4.79082 3.99089,5.21976 3.99089,5.74889 C3.99089,6.27802 4.41983,6.70696 4.94896,6.70696 C5.47809,6.70696 5.90703,6.27802 5.90703,5.74889 C5.90703,5.21976 5.47809,4.79082 4.94896,4.79082 Z M2.38147,5.74889 C2.38147,4.3309 3.53097,3.1814 4.94896,3.1814 C6.36695,3.1814 7.51645,4.3309 7.51645,5.74889 C7.51645,7.16687 6.36695,8.31638 4.94896,8.31638 C3.53097,8.31638 2.38147,7.16687 2.38147,5.74889 Z" id="Shape" fill="#666666"></path>
                        <path d="M4.94888,9.19774 C4.46103,9.19774 3.99315,9.39154 3.64819,9.7365 C3.50225,9.88243 3.38338,10.0504 3.29478,10.2325 L6.60299,10.2325 C6.51439,10.0504 6.39551,9.88243 6.24958,9.7365 C5.90461,9.39154 5.43674,9.19774 4.94888,9.19774 Z M2.51015,8.59847 C3.15695,7.95168 4.03418,7.58832 4.94888,7.58832 C5.86358,7.58832 6.74082,7.95168 7.38761,8.59847 C8.0344,9.24526 8.39776,10.1225 8.39776,11.0372 C8.39776,11.2506 8.31298,11.4553 8.16207,11.6062 C8.01116,11.7571 7.80648,11.8419 7.59305,11.8419 L2.30471,11.8419 C2.09129,11.8419 1.88661,11.7571 1.73569,11.6062 C1.58478,11.4553 1.5,11.2506 1.5,11.0372 C1.5,10.1225 1.86336,9.24526 2.51015,8.59847 Z" id="Shape" fill="#666666"></path>
                        <path d="M12.0001,14.4861 C11.471,14.4861 11.042,14.9151 11.042,15.4442 C11.042,15.9733 11.471,16.4023 12.0001,16.4023 C12.5292,16.4023 12.9582,15.9733 12.9582,15.4442 C12.9582,14.9151 12.5292,14.4861 12.0001,14.4861 Z M9.43262,15.4442 C9.43262,14.0262 10.5821,12.8767 12.0001,12.8767 C13.4181,12.8767 14.5676,14.0262 14.5676,15.4442 C14.5676,16.8622 13.4181,18.0117 12.0001,18.0117 C10.5821,18.0117 9.43262,16.8622 9.43262,15.4442 Z" id="Shape" fill="#666666"></path>
                        <path d="M9.5613,18.2938 C10.2081,17.647 11.0853,17.2836 12,17.2836 C12.9147,17.2836 13.792,17.647 14.4388,18.2938 C15.0855,18.9406 15.4489,19.8178 15.4489,20.7325 C15.4489,21.1769 15.0886,21.5372 14.6442,21.5372 L9.35586,21.5372 C8.91143,21.5372 8.55115,21.1769 8.55115,20.7325 C8.55115,19.8178 8.91451,18.9406 9.5613,18.2938 Z M12,18.8931 C11.5122,18.8931 11.0443,19.0869 10.6993,19.4318 C10.5534,19.5777 10.4345,19.7457 10.3459,19.9278 L13.6541,19.9278 C13.5655,19.7457 13.4467,19.5777 13.3007,19.4318 C12.9558,19.0869 12.4879,18.8931 12,18.8931 Z" id="Shape" fill="#666666"></path>
                        <path d="M12.0001,3.90941 C11.2207,3.90941 10.4623,4.16221 9.83873,4.62986 C9.48319,4.89651 8.97879,4.82446 8.71214,4.46891 C8.44548,4.11337 8.51754,3.60898 8.87308,3.34232 C9.7752,2.66573 10.8724,2.29999 12.0001,2.29999 C13.1277,2.29999 14.225,2.66573 15.1271,3.34232 C15.4826,3.60898 15.5547,4.11337 15.288,4.46891 C15.0214,4.82446 14.517,4.89651 14.1614,4.62986 C13.5379,4.16221 12.7795,3.90941 12.0001,3.90941 Z" id="Path" fill="#666666"></path>
                        <path d="M19.3344,12.9282 C19.7504,13.0847 19.9608,13.5487 19.8043,13.9647 C19.2226,15.5111 18.2115,16.8593 16.8898,17.8507 C16.5343,18.1174 16.0299,18.0453 15.7632,17.6898 C15.4965,17.3343 15.5685,16.8299 15.9241,16.5632 C17.0006,15.7557 17.8242,14.6577 18.2979,13.3981 C18.4544,12.9821 18.9184,12.7717 19.3344,12.9282 Z" id="Path" fill="#666666"></path>
                        <path d="M4.66556,12.9282 C5.08153,12.7717 5.54559,12.9821 5.70204,13.3981 C6.17579,14.6577 6.99935,15.7557 8.07588,16.5632 C8.43141,16.8299 8.50344,17.3343 8.23677,17.6898 C7.97009,18.0453 7.4657,18.1174 7.11017,17.8507 C5.78845,16.8593 4.7773,15.5111 4.19565,13.9647 C4.0392,13.5487 4.24958,13.0847 4.66556,12.9282 Z" id="Path" fill="#666666"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    );
};

export const ThirdPartyIcon = props => (
  <Icon component={ThirdPartySvg} {...props} />
);
